import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { getAuthorizedElements } from '../../helpers/authorizationHelper'

const style = {
    container: {
        height: 64,
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: 14,
        display: 'block',
        margin: '12px 6px',
    },
}

const EXTERNAL_SUPPLY_CHAIN_ROLE = 'external-supply-chain'
const BASE_REQUIRED_PERMISSIONS = ['inventory']

class ProductActions extends React.Component {

    render() {
        return (
            <div style={style.container}>
                {getAuthorizedElements(this.props.roles, this.props.permissions,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onSyncStatusClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Sync status
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onDifferenceClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Adjustment
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onLocationsClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Locations
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onBulkUpdateCLick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Bulk update
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onImportWarehouseStocksClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Import
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onExportWarehouseStocksClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Export
                    </Button>,
                    <Button
                        style={style.button}
                        color="primary"
                        onClick={this.props.onImportVendorStocksClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                        requiredRoles={[EXTERNAL_SUPPLY_CHAIN_ROLE]}
                    >
                        Stock sync
                    </Button>,
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!this.props.changed}
                        style={style.button}
                        onClick={this.props.onSaveChangesClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    >
                        Save
                    </Button>
                )}
            </div>
        )
    }
}

ProductActions.propTypes = {
    changed: PropTypes.bool,
    onSyncStatusClick: PropTypes.func.isRequired,
    onDifferenceClick: PropTypes.func.isRequired,
    onLocationsClick: PropTypes.func.isRequired,
    onBulkUpdateCLick: PropTypes.func.isRequired,
    onSaveChangesClick: PropTypes.func.isRequired,
    onImportWarehouseStocksClick: PropTypes.func.isRequired,
    onExportWarehouseStocksClick: PropTypes.func.isRequired,
    onImportVendorStocksClick: PropTypes.func.isRequired,
}

ProductActions.defaultProps = {
    changed: false,
}

export default ProductActions
