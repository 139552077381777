// get the authorized elements based on the requiredRoles prop of the element
/**
 *
 * @param {string[]} roles The user's roles
 * @param {string[]} permissions The user's permissions
 * @param  {...any} elements The elements to filter the unathorized elements from
 * @returns The input elements filtered by the user's roles and permissions
 */
export function getAuthorizedElements(roles, permissions, ...elements) {
    roles = roles || []
    permissions = permissions || []

    const authorizedElements = elements
        .filter(e =>
            e && (
                (
                    !e.props?.requiredRoles
                    && !e.props?.requiredPermissions
                )
                || e.props?.requiredRoles?.some(p => roles.includes(p))
                || e.props?.requiredPermissions?.some(p => permissions.includes(p))
            )
        )
    return authorizedElements
}
