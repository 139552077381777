import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Button,
} from '@material-ui/core'
import _ from 'lodash'

import Dialog from 'material-ui/Dialog'

const style = {
    content: {
        overflowY: 'auto',
        maxHeight: '35vh',
    },
    button: {
        backgroundColor: '#1B6CFF',
        color: 'white',
        fontSize: '10px',
        marginTop: '25px',
    },
}

class ActionDialog extends Component {

    render() {
        const payload = this.props.messagePayload
        let content

        switch (payload.type) {
            case 'upload_inventory_info':
                content =
                    <div>
                        <ul>
                            <li><strong>Updated (lines): </strong>
                                {payload.message.updated.length ? payload.message.updated : 'none'}
                            </li>
                            <li><strong>Errors (lines):</strong>
                                <ul>
                                    {payload.message.error_lines.map(e => (
                                        <li style={{ marginBottom: 10 }}>{e}</li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                break

            default:
                content =
                    <div>
                        {payload?.message}
                    </div>
        }

        return (
            <Dialog open={this.props.isOpen}>
                <h2>{payload.title}</h2>

                <div style={{ ...style.content}}>
                    {content}
                </div>

                <Button
                    style={{ ...style.button }}
                    variant="contained"
                    onClick={this.props.onClose}
                >
                    Close
                </Button>
            </Dialog>
        )
    }
}

ActionDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    messagePayload: PropTypes.object.isRequired,
}

export default ActionDialog
