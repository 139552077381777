import { syncSocketCall } from '../socket'
import store from '../store'

/**
 * Send command to save new non_processing_date
 * @param {Array<{ from: string, to: string }>} data Array of objects with a sku and ean as keys
 * @param {object} options Options object
 * @return {object} Action
 */
export async function addClosedDate(data, { timeout = 3000 } = {}) {
    const supplier = await syncSocketCall('supplier/non_processing_dates/add', data, { timeout })
    store.dispatch({ type: 'REFRESH_SUPPLIER', supplier })
    return supplier
}

/**
 * Send command to save new non_processing_date
 * @param {Array<{ from: string, to: string }>} data Array of objects with a sku and ean as keys
 * @param {object} options Options object
 * @return {object} Action
 */
export async function deleteClosedDate(data, { timeout = 3000 } = {}) {
    const supplier =  await syncSocketCall('supplier/non_processing_dates/delete', data, { timeout })
    store.dispatch({ type: 'REFRESH_SUPPLIER', supplier })
    return supplier
}
