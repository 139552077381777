import socket from './../socket'
import listeners from '../listeners/sockets/action-dialog'

listeners(socket)

export function action_dialog(state = {}, action) {
    switch (action.type) {
        case 'SET_ACTION_DIALOG':
            return action.actionPayload

        case 'CLEAR_ACTION_DIALOG':
            return {}

        default:
            return state
    }
}
