import socket from './../socket'
import listeners from '../listeners/sockets/personalisations'

listeners(socket)

export function personalisations(state = [], action) {
    switch (action.type) {
        case 'REFRESH_PERSONALISATIONS':
            return action.personalisations

        case 'REFRESH_PERSONALISATION':
            return [...state.filter(p => p._id !== action.personalisation._id), action.personalisation]

        case 'UPDATE_PERSONALISATION_STATUS':
            socket.emit('personalisation/update-status', {
                order_id: action.typed_order.order_id,
                fulfillment_id: action.typed_order.fulfillment_id,
                personalisation_id: action.typed_order.personalisation.personalisation_id,
                status: action.status,
            })
            return state

        case 'MARK_PERSONALISATIONS_PROCESSED':
            socket.emit('personalisations/process', action.order_id)
            return state

        default:
            return state
    }
}

