import * as Colors from 'material-ui/styles/colors'

const ProfortoColors = {
    primaryColor100: 'rgba(27, 108, 255, 1)',
    primaryColor80: 'rgba(27, 108, 255, 0.8)',
    primaryColor60: 'rgba(27, 108, 255, 0.6)',
    primaryColor40: 'rgba(27, 108, 255, 0.4)',
    primaryColor20: 'rgba(27, 108, 255, 0.2)',
    secondaryColor100: 'rgba(115, 185, 95, 1)',
    secondaryColor80: 'rgba(115, 185, 95, 0.8)',
    secondaryColor60: 'rgba(115, 185, 95, 0.6)',
    secondaryColor50: 'rgba(115, 185, 95, 0.5)',
    secondaryColor40: 'rgba(115, 185, 95, 0.4)',
    secondaryColor20: 'rgba(115, 185, 95, 0.2)',
    tertiaryColor100: 'rgba(0, 175, 215, 1)',
    tertiaryColor80: 'rgba(0, 175, 215, 0.8)',
    tertiaryColor60: 'rgba(0, 175, 215, 0.6)',
    tertiaryColor40: 'rgba(0, 175, 215, 0.4)',
    tertiaryColor20: 'rgba(0, 175, 215, 0.2)',

    red100: 'rgba(211, 72, 86, 1)',
}

const allColors = { ...Colors, ...ProfortoColors }

export default allColors
