import store from '../../store'
import { push } from 'react-router-redux'
import { receiveRmaProduct } from '../../actions/rmas'

export default function initRmasSockets(socket) {
    socket.on('rmas/search-result', ({ meta, data }) => {
        store.dispatch({type: 'REFRESH_RMAS', rmas: data})
        store.dispatch({type: 'REFRESH_PAGINATION_RMAS', pagination: meta})
    })

    socket.on('rmas/order', rmas => {
        store.dispatch({type: 'REFRESH_ORDER_RMAS', rmas})
    })

    socket.on('rma/created', rma => {
        store.dispatch({type: 'REFRESH_RMA', rma})
        store.dispatch(push(`/returns/rma/${rma._id}`))
    })

    socket.on('rma/pdf', data => {
        store.dispatch({type: 'DOWNLOAD_RMA', data})
    })

    socket.on('rma', rma => {
        store.dispatch({type: 'REFRESH_RMA', rma})
    })

    socket.on('rma/tracking_code', id => {
        store.dispatch(push(`/returns/rma/${id}`))
    })

    socket.on('product/variants/sku-or-ean-result', data => {
        store.dispatch(receiveRmaProduct(data))
    })
}
