import React from 'react'
import PropTypes from 'prop-types'
import {TableRow} from 'material-ui'

class TableRowWrapper extends React.Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.updated ||
            nextProps.selected !== this.props.selected ||
            nextProps.style.backgroundColor !== this.props.style.backgroundColor
    }

    render() {
        return (
            <TableRow
                onRowClick={this.props.onRowClick}
                onCellClick={this.props.onCellClick}
                rowNumber={this.props.rowNumber}
                selectable={this.props.selectable}
                selected={this.props.selected}
                style={this.props.style}
                hovered={this.props.hovered}
                hoverable={this.props.hoverable}
            >
                {this.props.children}
            </TableRow>
        )
    }
}

TableRowWrapper.propTypes = {
    updated: PropTypes.bool,
    children: PropTypes.array,
    style: PropTypes.object,
    selected: PropTypes.bool,
    selectable: PropTypes.bool,
    hovered: PropTypes.bool,
    hoverable: PropTypes.bool,
    rowNumber: PropTypes.number,
    onRowClick: PropTypes.func,
    onCellClick: PropTypes.func,
}

TableRowWrapper.defaultProps = {
    updated: true,
    children: [],
    style: {},
}

export default TableRowWrapper
