import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import WarningIcon from '@material-ui/icons/Warning'

import _ from 'lodash'

import Colors from '../../../../styles/colors'

const style = {
    paper: {
        marginBottom: 15,
        width: '100%',
        position: 'relative',
    },
    selectedHeaderRow: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: 64,
        transition: 'all 0.25s ease',
    },
    selectedItemsText: {
        color: '#FFFFFF',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    bigColumn: {
        width: '25%',
    },
    mediumColumn: {
        width: '12%',
    },
    smallColumn: {
        width: '5%',
    },
    salesAnchor: {
        cursor: 'pointer',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
}

class LineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: false,
            selectedCount: 0,
            selectedRows: [],
        }
    }

    getSelectedActions() {
        const selection = this.props.selection
        const itemsText = selection.selectedCount > 1 ? 'items' : 'item'
        const text = `${selection.selectedCount} ${itemsText} selected`
        return (
            <TableRow
                displayBorder={false}
                style={_.merge({ backgroundColor: Colors.primaryColor40 }, style.selectedHeaderRow)}
            >
                <th style={style.selectedItemsText} colSpan={6}><span style={{ paddingLeft: '24px' }}>{text}</span></th>
                <th style={style.selectedIconActions}>
                    {this.props.actions}
                </th>
            </TableRow>
        )
    }

    getUnselectedActions() {
        const salesColumn = this.props.bundled ? null : (
            <TableCell><span>Sales order</span></TableCell>
        )
        return (
            <TableRow style={style.selectedHeaderRow}>
                <TableCell style={style.smallColumn}><span>Quantity</span></TableCell>
                <TableCell><span>Name</span></TableCell>
                <TableCell><span>SKU</span></TableCell>
                <TableCell><span>EAN</span></TableCell>
                <TableCell><span>Size</span></TableCell>
                <TableCell><span>Color</span></TableCell>
                <TableCell><span>Purchase amount</span></TableCell>
                {salesColumn}
            </TableRow>
        )
    }

    render() {
        const selection = this.props.selection
        const headerRow = selection.selected && this.props.selectable
            ? this.getSelectedActions()
            : this.getUnselectedActions()
        const line_items = this.props.line_items
        const contents = (
            <Table
                style={{ overflowY: 'scroll', tableLayout: 'auto' }}
            >
                <TableHead>
                    {headerRow}
                </TableHead>
                <TableBody>
                    {
                        line_items.map((line_item, index) => {
                            const selected = selection.selectedRows.indexOf(index) !== -1
                            const rowStyle = {}
                            if (line_item.status === 'received') {
                                rowStyle.backgroundColor = Colors.green200
                            } else if (line_item.status === 'unreceived') {
                                rowStyle.backgroundColor = Colors.red200
                            }
                            const salesColumn = this.props.bundled ? null : (
                                <TableCell style={style.mediumColumn}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    <a style={style.salesAnchor} onClick={() => {
                                        this.props.onNavigateToSalesOrderClick(line_item.mage_id)
                                    }}
                                    >
                                        {line_item.mage_id}
                                    </a>
                                </TableCell>
                            )
                            return (
                                <TableRow
                                    key={`${line_item.supplier_sku}.${index}`} style={rowStyle} selected={selected}
                                >
                                    <TableCell style={style.smallColumn}>
                                        {line_item.quantity}
                                    </TableCell>
                                    <TableCell>{line_item.name}</TableCell>
                                    <TableCell>
                                        {line_item.supplier_sku}
                                    </TableCell>
                                    <TableCell>{line_item.ean}</TableCell>
                                    <TableCell>{line_item.size}</TableCell>
                                    <TableCell>{line_item.color}</TableCell>
                                    <TableCell>
                                        {line_item.pricing && line_item.pricing.row_purchase_price
                                            ? Number(line_item.pricing.row_purchase_price).toFixed(2)
                                            : null
                                        }
                                    </TableCell>
                                    {salesColumn}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                {this.props.pricing && (
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={6}>Total purchase amount</TableCell>
                            <TableCell align="left" colSpan={2}>
                                {this.props.pricing.total_purchase_price}
                                {this.props.pricing.missing_purchase_price && (
                                    <WarningIcon style={{ ...style.icon, color: Colors.amber700 }} />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        )
        const containerStyle = { ...style.paper, ...this.props.style }
        return this.props.wrapper
            ? <Paper style={containerStyle}>{contents}</Paper>
            : <div style={containerStyle}>{contents}</div>
    }
}

LineItems.propTypes = {
    id: PropTypes.string,
    actions: PropTypes.node,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    selection: PropTypes.object,
    style: PropTypes.object,
    bundled: PropTypes.bool,
    wrapper: PropTypes.bool,
    selectable: PropTypes.bool,
    multiSelectable: PropTypes.bool,
    line_items: PropTypes.array,
    pricing: PropTypes.object,
    outbound_items: PropTypes.array,
    onRowSelection: PropTypes.func,
    onNavigateToSalesOrderClick: PropTypes.func,
}

LineItems.defaultProps = {
    id: null,
    actions: null,
    multiSelectable: true,
    bundled: false,
    wrapper: true,
    selectable: false,
    selection: {
        selectedRows: [],
        selected: false,
        selectedCount: 0,
    },
    style: {},
    line_items: [],
    outbound_items: [],
}

export default LineItems
