export function searchOutboundChecklists(filters) {
    return {type: 'SEARCH_OUTBOUND_CHECKLISTS', filters}
}

export function getOutboundChecklist(id) {
    return {type: 'GET_OUTBOUND_CHECKLIST', id}
}

export function triageItems(data, status, author) {
    return {type: 'TRIAGE_OUTBOUND_CHECKLIST_ITEMS', data, status, author}
}
