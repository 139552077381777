export const FILE_UPLOAD_ERROR = Object.freeze({
    NO_FILES: 'No files selected',
    UPLOAD_CANCELLED: 'File upload cancelled',
    INVALID_EXTENSION: 'Uploaded file .* has the wrong extension. (.*)',
    INVALID_READ_TYPE: 'Wrong upload read type selected',
})

/**
 * Trigger a file upload and read the whole file
 * @param {object} options Options object
 * @param {string[]} options.extension Valid extensions
 * @param {string} options.type Upload type (text|binaryString|dataUrl|arrayBuffer)
 * @returns {Promise<{ contents, fileName }>} Returns a Promise with the contents and the filename
 */
export function handleFileUpload({ extensions = [ 'csv' ], readType = 'text' } = {}) {
    return new Promise((resolve, reject) => {
        let isUploading = false

        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.style.display = 'none'

        function cleanup() {
            document.body.removeEventListener('focus', handleCancel) // eslint-disable-line no-use-before-define
            input.removeEventListener('change', handleInputChanged) // eslint-disable-line no-use-before-define

            if (input.parentElement) {
                document.body.removeChild(input)
            }
        }

        /**
         * @param {Error|null} error Error instance or null
         * @param {string|ArrayBuffer|Binary|undefined} contents Contents of input file
         * @param {string} fileName Name of input file
         * @returns {undefined}
         */
        function onLoad(error, contents, fileName) {
            cleanup()

            if (error) {
                error.fileName = fileName
                return reject(error)
            }

            return resolve({ contents, fileName })
        }

        function handleInputChanged() {
            isUploading = true
            document.body.removeEventListener('focus', handleCancel) // eslint-disable-line no-use-before-define

            const file = input.files[0]
            if (!file || !file.name) {
                onLoad(new Error(FILE_UPLOAD_ERROR.NO_FILES))
                return
            }

            const isValidExtension = extensions.reduce((acc, extension) => acc || file.name.endsWith(extension), false)
            if (!isValidExtension) {
                onLoad(new Error(
                    FILE_UPLOAD_ERROR.INVALID_EXTENSION.replace('.*', file.name).replace('.*', extensions.join(', ')),
                    null,
                    file.name
                ))
                return
            }

            const reader = new FileReader()

            reader.addEventListener('error', error => {
                onLoad(error, null, file.name)
            })

            reader.addEventListener('load', event => {
                const contents = event.target.result
                onLoad(null, contents, file.name)
            })

            switch (readType) {
                case 'text':
                    reader.readAsText(file)
                    break

                case 'binaryString':
                    reader.readAsBinaryString(file)
                    break

                case 'dataUrl':
                    reader.readAsDataURL(file)
                    break

                case 'arrayBuffer':
                    reader.readAsArrayBuffer(file)
                    break

                default:
                    onLoad(new Error(FILE_UPLOAD_ERROR.INVALID_READ_TYPE), null, file.name)
                    return
            }
        }

        function handleCancel() {
            // Use a timeout to make sure the input changed event has a chance to come first
            setTimeout(() => {
                cleanup()

                if (!isUploading) {
                    onLoad(new Error(FILE_UPLOAD_ERROR.UPLOAD_CANCELLED))
                }
            }, 500)
        }

        input.addEventListener('change', handleInputChanged)
        document.body.addEventListener('focus', handleCancel, true)

        document.body.appendChild(input)
        input.click()
    })
}
