import socket from './../socket'
import listeners from '../listeners/sockets/locations.js'

listeners(socket)

export function locations(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_LOCATIONS':
            return action.data

        default:
            return state
    }
}
