import socket from './../../socket'
import { getUserEmail } from '../../store'

export function searchPurchaseOrders(filters) {
    socket.emit('purchase-orders/search', filters)
    return { type: 'SEARCH_PURCHASE_ORDERS', filters }
}

export function createPurchaseOrderDraft(data) {
    socket.emit('purchase-orders/create-draft', data, getUserEmail())
    return { type: 'CREATE_PURCHASE_ORDER_DRAFT', data }
}

export function updatePurchaseOrderDraft(order_id, data) {
    socket.emit('purchase-orders/update-draft', { order_id, data }, getUserEmail())
    return { type: 'UPDATE_PURCHASE_ORDER_DRAFT', order_id, data }
}

export function updatePurchaseOrderLineItems(order_id, line_items) {
    socket.emit('purchase-orders/update-line-items', { order_id, line_items }, getUserEmail())
    return { type: 'UPDATE_PURCHASE_ORDER_LINE_ITEMS', order_id, line_items }
}

export function finalizePurchaseOrderDraft(order_id, options) {
    socket.emit('purchase-orders/finalize-draft', { order_id, options }, getUserEmail())
    return { type: 'FINALIZE_PURCHASE_ORDER_DRAFT', order_id, options }
}
