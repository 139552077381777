import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const styles = {
    dialogPaper: {
        minWidth: 640,
    },
    dialogActions: {
        margin: '0 20px 20px 20px',
    },
}

class ForceFulfillmentConfirmationDialog extends Component {

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleSubmit() {
        this.props.onSubmit()
        if (this.props.closeOnSubmit) {
            this.handleClose()
        }
    }

    handleClose() {
        this.props.onClose()
    }

    render() {
        return (
            <Dialog
                PaperProps={{style: styles.dialogPaper}}
                onClose={this.handleClose}
                aria-labelledby="confirm-force-fulfillment-title"
                open={this.props.open}
            >
                <DialogTitle id="confirm-force-fulfillment-title">
                    Weet je zeker dat je deze order in fulfillment wil brengen?
                </DialogTitle>
                <DialogActions style={styles.dialogActions}>
                    <Button onClick={this.handleClose} variant="outlined">
                        Nee
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ForceFulfillmentConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    closeOnSubmit: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
ForceFulfillmentConfirmationDialog.defaultProps = {
    open: false,
    closeOnSubmit: true,
}

export default ForceFulfillmentConfirmationDialog
