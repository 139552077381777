import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn, FontIcon } from 'material-ui'

const style = {
    fontIconStyle: { fontSize: 16 },
    iconStyle: { width: 15, height: 15 },
}

const sortIconMap = {
    '-1': 'arrow_downward',
    1: 'arrow_upward',
}

class SortableTableHeaderColumn extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sort: props.initialSort || 0,
            mouseOver: false,
        }
    }

    getValue() {
        return this.props.controlled ? (this.props.value || 0) : this.state.sort
    }

    handleClick() {
        if (!this.props.sortable) {
            return
        }
        const currentSort = this.getValue() !== 0
            ? -this.getValue() // toggle
            : this.props.initialSort || 1
        if (this.props.controlled) {
            this.props.onSortChange(currentSort, this.props.property)
        } else {
            this.setState({
                mouseOver: false,
                sort: currentSort,
            }, () => {
                if (this.props.onSortChange) {
                    this.props.onSortChange(this.state.sort, this.props.property)
                }
            })
        }
    }

    handleMouseOver() {
        this.setState({ mouseOver: !this.state.mouseOver })
    }

    getSortIcon(sort) {
        if (sort === 0) {
            return null
        }
        return (
            <FontIcon
                color="#000"
                style={style.fontIconStyle}
                iconStyle={style.iconStyle}
                className="material-icons"
            >
                {sortIconMap[sort]}
            </FontIcon>
        )
    }

    getStyle(sortable, mouseOver, sort) {
        const changedStyle = (mouseOver && sortable) || sort !== 0
        return {
            cursor: sortable ? 'pointer' : 'inherit',
            fontWeight: changedStyle ? 'bold' : 'inherit',
            color: changedStyle ? '#000' : '#9E9E9E',
        }
    }

    render() {
        const sort = this.getValue()
        return (
            <TableHeaderColumn
                style={{ ...this.getStyle(this.props.sortable, this.state.mouseOver, sort), ...this.props.style }}
                width={this.props.width}
                onMouseEnter={this.handleMouseOver.bind(this)}
                onMouseLeave={this.handleMouseOver.bind(this)}
                onClick={this.handleClick.bind(this)}
                tooltip={this.props.tooltip}
            >
                {this.getSortIcon(sort)}
                {this.props.children}
            </TableHeaderColumn>
        )
    }
}

SortableTableHeaderColumn.propTypes = {
    controlled: PropTypes.bool,
    value: PropTypes.number,
    initialSort: PropTypes.number,
    sortable: PropTypes.bool,
    children: PropTypes.node,
    width: PropTypes.string,
    style: PropTypes.object,
    property: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    onSortChange: PropTypes.func,
}
SortableTableHeaderColumn.defaultProps = {
    controlled: false,
    sortable: false,
    style: {},
}

export default SortableTableHeaderColumn
