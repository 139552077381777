import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    TextField,
    SelectField,
    MenuItem,
    IconButton,
} from 'material-ui'

const style = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        width: '95%',
        margin: 'auto',
    },
    filter: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
    },
}

class ProductBulkUpdateFilters extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    getAvailableConfigs() {
        return this.props.configs.filter(config => {
            return this.props.filters.find(f => f.key === config.key) === undefined
                && config.properties.indexOf(this.props.property) !== -1
        })
    }

    getKeySelection(key, configs, index) {
        return (
            <SelectField
                value={key}
                onChange={(e, i, val) => {
                    this.props.onFilterSelectionChange(index, val)
                }}
            >
                {
                    configs.map(c => {
                        return (
                            <MenuItem
                                key={c.key}
                                value={c.key}
                                primaryText={c.text}
                            />
                        )
                    })
                }
            </SelectField>
        )
    }

    getValueSelection(value, config, index) {
        return (
            <SelectField
                hintText="Select value.."
                style={{width: '45%'}}
                value={value}
                onChange={(e, i, val) => { this.props.onFilterChange(index, val) }}
            >
                {
                    config.menuItems.map(item => {
                        return (
                            <MenuItem
                                key={item.key}
                                value={item.value}
                                primaryText={item.primaryText}
                            />
                        )
                    })
                }
            </SelectField>
        )
    }

    getValueInput({key, value, index, config}) {
        return (
            <TextField
                hintText="Enter value.."
                style={{width: '45%'}}
                name={key}
                type={config.type}
                value={value}
                onChange={({target}) => {
                    this.props.onFilterChange(index, config.type === 'number' ? +target.value : target.value)
                }}
            />
        )
    }

    render() {
        return (
            <div style={style.container}>
                {
                    this.props.filters.map(({key, value}, index) => {
                        const configs = this.getAvailableConfigs()
                        const config = this.props.configs.find(f => f.key === key)
                        configs.unshift(config)

                        let element = null
                        const component = config.valueComponent
                        if (component === SelectField) {
                            element = this.getValueSelection(value, config, index)
                        } else if (component === TextField) {
                            element = this.getValueInput({key, value, index, config})
                        }

                        const keySelection = this.getKeySelection(key, configs, index)
                        return (
                            <div
                                key={`${key}.${index}`}
                                style={style.filter}
                            >
                                {keySelection}
                                {element}
                                <IconButton
                                    iconClassName="material-icons"
                                    onClick={() => { this.props.onDeleteFilterClick(index) }}
                                >
                                    delete
                                </IconButton>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

ProductBulkUpdateFilters.propTypes = {
    property: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    configs: PropTypes.array.isRequired,
    onFilterSelectionChange: PropTypes.func,
    onFilterChange: PropTypes.func,
    onDeleteFilterClick: PropTypes.func,
}
ProductBulkUpdateFilters.defaultProps = {
    filters: [],
    configs: [],
}

export default ProductBulkUpdateFilters
