import React from 'react'
import PropTypes from 'prop-types'

import PersonalisationLineItems from './../../../production/production-orders/PersonalisationLineItems'
import PersonalisationInstructions from './../../../production/production-orders/PersonalisationInstructions'

function Personalisations(props) {
    return (
        <div>
            {props.typed_orders.map(to => {
                return (
                    <PersonalisationLineItems
                        key={to.fulfillment_id}
                        typed_order={to}
                        personalisation={props.personalisations.find(p => p.fulfillment_id === to.fulfillment_id)}
                    />
                )
            })}
            {props.instructions && (
                <PersonalisationInstructions instructions={props.instructions} />
            )}
        </div>
    )
}

Personalisations.propTypes = {
    typed_orders: PropTypes.array,
    instructions: PropTypes.string,
    personalisations: PropTypes.array,
}
Personalisations.defaultProps = {
    typed_orders: [],
    instructions: '',
    personalisations: [],
}

export default Personalisations
