import socket from './../socket'
import listeners from '../listeners/sockets/debit_memos.js'
import moment from 'moment'

listeners(socket)

export function debit_memos(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SEARCH_DEBIT_MEMOS':
            socket.emit('debits/search', action.filters)
            return state

        case 'REFRESH_DEBIT_MEMOS':
            return action.debit_memos.map(d => {
                const total = d.grand_total.split('.')
                return {
                    _id: d._id,
                    channel: d.channel,
                    mage_id: d.mage_id,
                    grand_total: `€ ${total.shift()}.${Math.round((+total.shift()) / 100)}`,
                    invoice_id: d.invoice_id,
                    created_at: moment(d.created_at).format('DD-MM-YY HH:mm'),
                    status: d.status,
                }
            })

        default:
            return state
    }
}

export function debit_memo(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'GET_DEBIT_MEMO_BY_ORDER':
            socket.emit('debit/order/get', action.id)
            return state

        case 'REFRESH_DEBIT_MEMO':
            return action.debit_memo

        case 'SYNCHRONIZE_DEBIT_MEMO':
            socket.emit('debit/synchronize', action.id)
            return state

        default:
            return state
    }
}
