export function promo_items(state = [], action) {
    switch (action.type) {
        case 'REFRESH_PROMO_ITEMS':
            state = action.data
            break

        case 'CLEAR_PROMO_ITEMS':
            state = []
            break

        default:
            return state
    }

    return state
}
