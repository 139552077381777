import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import TextField from '@material-ui/core/TextField'

import SwitchFilter from './../../shared/SwitchFilter'
import SupplierFilter from './../../shared/SupplierFilter'
import SourceFilter from './../../shared/SourceFilter'
import DestinationFilter from '../../shared/DestinationFilter'


const style = {
    container: {
        display: 'flex',
        flexFlow: 'row',
        paddingLeft: 20,
        marginTop: 12,
    },
    searchField: {
        width: 140, marginRight: 15,
    },
    datePicker: {
        cursor: 'pointer',
        marginRight: 15,
    },
}

class TypedOrderFilters extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    handleIgnoreDeliveryChange(e) {
        this.props.onIgnoreDeliveryChange(e.value)
    }

    handleUnfulfilledChange(e) {
        this.props.onUnfulfilledChange(e.value)
    }

    handleLateDeliveryFilterChange(e) {
        this.props.onLateDeliveryChange(e.value)
    }

    handleSourceChange(e) {
        this.props.onSourceChange(e.target.value)
    }

    handleDestinationChange(e) {
        this.props.onDestinationChange(e.target.value)
    }

    getDestinationSelect(extended_view) {
        return !extended_view ? null : (
            <DestinationFilter
                allowAll={false}
                onChange={this.handleDestinationChange}
                value={this.props.filters.destination}
                destinations={['customer', 'manufacturer']}
            />
        )
    }

    getSourceSelect(extended_view) {
        return !extended_view ? null : (
            <SourceFilter
                allowAll={true}
                onChange={this.handleSourceChange}
                value={this.props.filters.source}
            />
        )
    }

    getSupplierSelect(extended_view) {
        return !extended_view ? null : (
            <SupplierFilter
                value={this.props.filters.supplier_id}
                allowAll={true}
                onChange={this.props.onSupplierChange}
            />
        )
    }

    render() {
        const extended_view = this.props.extendedFilters
        const source_select = this.getSourceSelect(extended_view)
        const destination_select = this.getDestinationSelect(extended_view)
        const supplier_select = this.getSupplierSelect(extended_view)
        return (
            <div style={style.container}>
                <TextField
                    style={style.searchField}
                    label="Fill in your search criteria."
                    value={this.props.filters.search}
                    onChange={this.props.onSearchFieldChange}
                    InputLabelProps={{ shrink: true }}
                />
                {source_select}
                {destination_select}
                {supplier_select}
                <TextField
                    id="from"
                    style={style.datePicker}
                    required={true}
                    label="Select estimated date"
                    type="date"
                    disabled={this.props.filters.ignoreDelivery}
                    InputLabelProps={{ shrink: true }}
                    value={this.props.filters.fromDate}
                    onChange={this.props.onEstimatedDateChange}
                />
                <SwitchFilter
                    style={style.switchFilter}
                    filterLabel="Ignore estimated date"
                    filterProperty="ignoreDelivery"
                    disabled={this.props.filters.lateDelivery}
                    onFilterChange={this.handleIgnoreDeliveryChange}
                    filterValue={this.props.filters.ignoreDelivery}
                />
                <SwitchFilter
                    style={style.switchFilter}
                    filterLabel="Late delivery"
                    filterProperty="lateDelivery"
                    onFilterChange={this.handleLateDeliveryFilterChange}
                    filterValue={this.props.filters.lateDelivery}
                />
                <SwitchFilter
                    style={style.switchFilter}
                    filterLabel="Unfulfilled"
                    filterProperty="unfulfilled"
                    disabled={this.props.filters.lateDelivery}
                    onFilterChange={this.handleUnfulfilledChange}
                    filterValue={this.props.filters.unfulfilled}
                />
            </div>
        )
    }
}

TypedOrderFilters.propTypes = {
    extendedFilters: PropTypes.bool,
    mage_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onSearchFieldChange: PropTypes.func.isRequired,
    onEstimatedDateChange: PropTypes.func.isRequired,
    onIgnoreDeliveryChange: PropTypes.func.isRequired,
    onUnfulfilledChange: PropTypes.func.isRequired,
    onLateDeliveryChange: PropTypes.func.isRequired,
    onDestinationChange: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func,
    onSupplierChange: PropTypes.func,
    filters: PropTypes.object.isRequired,
}

export default TypedOrderFilters
