import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    TextField,
    MenuItem,
} from '@material-ui/core'

import SearchProductAutoComplete from '../shared/SearchProductAutoComplete'
function Select(props) {
    return (
        <TextField
            select
            fullWidth
            onChange={props.onChange}
            value={props.value}
            label={props.label}
            SelectProps={{ MenuProps: { style: { zIndex: 1600, maxHeight: 800 }, disableAutoFocusItem: true } }}
            InputLabelProps={{ shrink: true }}
        >
            {(props.options ?? []).map(({ text, value }) => (
                <MenuItem
                    key={text}
                    value={value}
                >
                    {text}
                </MenuItem>
            ))}
        </TextField>
    )
}
Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
}

const LocationSourceDocumentMapping = {
    supplier: [
        { text: 'Purchase order received', value: 'po-received' },
        { text: 'RTV refused', value: 'rtv-refused' },
    ],
    customer: [
        { text: 'RMA', value: 'rma' },
        { text: 'Sales order', value: 'sales-order' },
    ],
    manufacturer: [
        { text: 'Sales order', value: 'sales-order' },
    ],
}

class AdjustmentDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            correction_type: null,
            direction: 'from',
            location: null,
            reference_source: null,
            reference: '',
            quantity: 1,
            variant: null,
        }

        autoBind(this)
    }

    handleSelectChange(property) {
        return e => {
            this.setState({ [property]: e.target.value })
        }
    }

    handleVariantSelect(variant) {
        this.setState({ variant })
    }

    handleQuantityChange(e) {
        const quantity = +e.target.value
        if (quantity >= 1) {
            this.setState({ quantity })
        }
    }

    handleClose() {
        return this.props.onClose && this.props.onClose()
    }

    handleSubmit() {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state)
        }
        this.handleClose()
    }

    canSubmit() {
        return Object.keys(this.state).every(key => !!this.state[key])
    }

    render() {
        return (
            <Dialog open={true} maxWidth="lg" fullWidth>
                <DialogTitle>Inventory adjustments</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" xs={12} justify="space-between">
                        <Grid container direction="column" xs={4} spacing={2}>
                            <Grid item>
                                <Select
                                    label="Adjustment on"
                                    options={[
                                        { text: 'Inbound', value: 'inventory-adjustment-inbound' },
                                        { text: 'Outbound', value: 'inventory-adjustment-outbound' },
                                    ]}
                                    value={this.state.correction_type}
                                    onChange={this.handleSelectChange('correction_type')}
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    label="From or to"
                                    options={[
                                        { text: 'From', value: 'from' },
                                        { text: 'To', value: 'to' },
                                    ]}
                                    value={this.state.direction}
                                    onChange={this.handleSelectChange('direction')}
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    label="Location type"
                                    options={[
                                        { text: 'Supplier', value: 'supplier' },
                                        { text: 'Customer', value: 'customer' },
                                        { text: 'Manufacturer', value: 'manufacturer' },
                                    ]}
                                    value={this.state.location}
                                    onChange={this.handleSelectChange('location')}
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    label="Source document"
                                    options={!this.state.location
                                        ? []
                                        : LocationSourceDocumentMapping[this.state.location]}
                                    value={this.state.reference_source}
                                    onChange={this.handleSelectChange('reference_source')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Source identifier"
                                    value={this.state.reference}
                                    onChange={this.handleSelectChange('reference')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="column" xs={8} spacing={2}>
                            <Grid item>
                                <SearchProductAutoComplete
                                    onSuggestionSelected={this.handleVariantSelect}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={this.state.quantity}
                                    onChange={this.handleQuantityChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!this.canSubmit()}
                        color="primary"
                        contained
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button contained onClick={this.handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

AdjustmentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default AdjustmentDialog
