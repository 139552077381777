import React from 'react'
import PropTypes from 'prop-types'
import {TableRowColumn} from 'material-ui'

class TableRowColumnWrapper extends React.Component {

    render() {
        return (
            <TableRowColumn style={this.props.style} onCellClick={this.props.onCellClick}>
                {this.props.children}
            </TableRowColumn>
        )
    }
}

TableRowColumnWrapper.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node,
    onCellClick: PropTypes.func,
}

export default TableRowColumnWrapper
