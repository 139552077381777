import store from '../../store'
import { push } from 'react-router-redux'

import { getRtvShipments } from '../../commands/sockets/shipments'

import { WithShipmentStatuses } from '../../app/types/returns/rtv'

function refreshRtv(rtv) {
    store.dispatch({ type: 'REFRESH_RTV', rtv })
}

export default function initRtvSockets(socket) {

    socket.on('rtvs/search-result', rtvs => {
        store.dispatch({ type: 'REFRESH_RTVS', rtvs })
    })
    socket.on('rtv-queue/search-result', rtv_queue => {
        store.dispatch({ type: 'REFRESH_RTV_QUEUE', rtv_queue })
    })
    socket.on('rtv', rtv => {
        refreshRtv(rtv)
        if (WithShipmentStatuses.includes(rtv.status)) {
            getRtvShipments(rtv.rtv_id)
        }
    })
    socket.on('rtv/draft-created', rtv => {
        refreshRtv(rtv)
        store.dispatch(push(`/returns/rtv/${rtv._id}`))
    })
    socket.on('rtv/requested', refreshRtv)
    socket.on('rtv/updated-vendor-ref', refreshRtv)
    socket.on('rtv/request-reverted', refreshRtv)
    socket.on('rtv/approved', refreshRtv)
    socket.on('rtv/line-items-settled', refreshRtv)
    socket.on('rtv/closed', refreshRtv)
    socket.on('rtv/pdf', pdf => {
        store.dispatch({ type: 'REFRESH_RTV_PDF', pdf })
    })
}
