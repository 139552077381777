import React, {Component} from 'react'
import PropTypes from 'prop-types'

const style = {
    container: {display: 'inline', float: 'right', marginRight: 40, marginTop: 10, width: 70},
    span: {fontSize: 12},
    svg: {float: 'right', marginTop: '4px'},
}

class PurchaseRequisitionLegenda extends Component {

    renderExplanation(text, color) {
        return (
            <span style={style.span}>
                {text}
                &nbsp;
                <svg width="12" height="12" viewBox="0 0 12 12" style={style.svg}>
                    <rect width="12" height="12" fill={color.replace('0.1')} stroke="black" fillOpacity="1"/>
                </svg>
                <br/>
            </span>
        )
    }

    render() {
        return (
            <div style={{...style.container, ...this.props.style}}>
                {this.renderExplanation('Pending', this.props.colors.pending)}
                {this.renderExplanation('Approved', this.props.colors.approved)}
                {this.renderExplanation('Revoked', this.props.colors.revoked)}
            </div>
        )
    }
}

PurchaseRequisitionLegenda.propTypes = {
    style: PropTypes.object,
    colors: PropTypes.object,
}

PurchaseRequisitionLegenda.defaultProps = {
    colors: {
        revoked: 'rgba(211, 72, 86, 0.5)',
        approved: 'rgba(117, 191, 226, 0.3)',
        pending: 'rgba(255, 204, 0, 0.3)',
    },
}

export default PurchaseRequisitionLegenda
