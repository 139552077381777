import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {searchDebitMemos} from './../../../../actions/debit_memos'

import DebitMemoTable from './DebitMemoTable'
import DebitMemoFilters from './DebitMemoFilters'

const fields = ''
const STORAGE_KEY = 'debit-memos-filter'

class DebitMemoList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            shouldRender: false,
            isUpdated: false,
            criteria: filters.criteria || '',
            status: filters.status || 'all',
            page: filters.page || 1,
            limit: 20,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return prevState.shouldRender === false ? { shouldRender: true } : null
    }

    componentDidMount() {
        this.searchDebitMemos()
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    getFiltersState() {
        return {
            criteria: this.state.criteria,
            status: this.state.status,
            fields,
            page: this.state.page,
            limit: this.state.limit,
            sorting: {created_at: -1},
        }
    }

    searchDebitMemos() {
        const state = this.getFiltersState()
        this.storeFilters(state)
        if (state.status === 'all') {
            delete state.status
        }
        this.props.searchDebitMemos(state)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchDebitMemos.bind(this), 500)
    }

    setStateWithoutRender(state, timeout) {
        this.setState({
            ...state,
            shouldRender: false,
        }, timeout ? this.setSearchTimeout.bind(this) : this.searchDebitMemos.bind(this))
    }

    handleSearchChange(criteria) {
        this.setStateWithoutRender({
            criteria,
            page: 1,
        }, true)
    }

    handleStatusChange(status) {
        this.setStateWithoutRender({
            status,
            page: 1,
        })
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            page: this.state.page + 1,
        })
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            page: this.state.page - 1,
        })
    }

    render() {
        return (
            <div>
                <DebitMemoFilters
                    filters={this.getFiltersState()}
                    onSearchChange={this.handleSearchChange.bind(this)}
                    onStatusChange={this.handleStatusChange.bind(this)}
                />
                <DebitMemoTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.page}
                    limit={this.state.limit}
                    items={this.props.debit_memos}
                    onPageIncrement={this.handlePageIncrement.bind(this)}
                    onPageDecrement={this.handlePageDecrement.bind(this)}
                />
            </div>
        )
    }
}

DebitMemoList.propTypes = {
    debit_memos: PropTypes.array.isRequired,
    filters: PropTypes.object,
    searchDebitMemos: PropTypes.func.isRequired,
}

export default connect(({debit_memos, pagination, filters}) => {
    return {
        debit_memos,
        pagination,
        filters: filters.debit_memos || {},
    }
}, dispatch => {
    return bindActionCreators({searchDebitMemos}, dispatch)
})(DebitMemoList)

