/**
 * Number to ordinal
 * @link https://www.mathsisfun.com/numbers/cardinal-ordinal-chart.html
 * @param {number} number Number to ordinalize
 * @returns {string} Ordinal representation of number
 */
export function numberToOrdinal(number) {
    // Most ordinal numbers end in "th" except for:
    // one ⇒ first (1st)
    // two ⇒ second (2nd)
    // three ⇒ third (3rd)

    const int = parseInt(number, 10)

    if (int === 1) return '1st'
    if (int === 2) return '2nd'
    if (int === 3) return '3rd'
    return `${int}th`
}
