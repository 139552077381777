export const DeliveryOrderStatuses = {
    OPEN: 'open',
    PARTIALLY_FULFILLED: 'partially_fulfilled',
    FULFILLED: 'fulfilled',
    COMPLETED: 'completed',
    REVOKED: 'revoked',
}

export const DeliveryOrderItemStatuses = {
    OPEN: 'open',
    FULFILLED: 'fulfilled',
    CHECKED_OUT: 'checked_out',
}

export const DeliveryTypes = {
    CUSTOMER: 'customer',
    MANUFACTURER: 'manufacturer',
    SUPPLIER: 'supplier',
}
