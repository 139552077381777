import React from 'react'
import PropTypes from 'prop-types'
import { SelectField, MenuItem } from 'material-ui'

const style = {
    width: 200,
    marginRight: 15,
}

function ShippingCarrier(props) {
    const items = [...props.options]
    items.unshift('All')
    return (
        <SelectField
            style={style}
            onChange={props.onChange}
            value={props.value}
            floatingLabelText="Select Carrier"
        >
            {
                items.map(option => {
                    return (
                        <MenuItem
                            key={option ? option.toLowerCase() : null}
                            value={option}
                            primaryText={option}
                        />
                    )
                })
            }
        </SelectField>
    )
}

ShippingCarrier.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
}

export default ShippingCarrier
