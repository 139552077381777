import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { downloadBase64AsPdf } from '../../../../utils/download'

import { downloadRma } from '../../../../../commands/sockets/rmas'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelActions from '@material-ui/core/CardActions'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DoneIcon from '@material-ui/icons/Done'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PaymentIcon from '@material-ui/icons/Payment'
import ErrorIcon from '@material-ui/icons/Error'
import HomeIcon from '@material-ui/icons/Home'

import Colors from '../../../../styles/colors'

const styles = {
    container: {
        width: '100%',
    },
    emptyContainer: {
        padding: 15,
    },
    avatar: {
        float: 'left',
        marginRight: 8,
    },
    expansionPanel: {
        margin: 0,
        width: '100%',
        boxShadow: 'none',
    },
    expansionPanelDetails: {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        flexFlow: 'column',
    },
    cardActions: {
        width: '100%',
        display: 'block',
        padding: '8px 0',
    },
}

const STATUSES = {
    approved: {
        icon: DoneIcon,
        color: Colors.grey600,
    },
    accepted: {
        icon: DoneIcon,
        color: Colors.green700,
    },
    partially_accepted: {
        icon: DoneAllIcon,
        color: Colors.green700,
    },
    goods_received: {
        icon: HomeIcon,
        color: Colors.green700,
    },
    rejected: {
        icon: ErrorIcon,
        color: Colors.red600,
    },
    settled: {
        icon: PaymentIcon,
        color: Colors.green700,
    },
}

class Returns extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloading: null,
        }

        this.handleDownloadClick = this.handleDownloadClick.bind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps && nextProps.rma && nextProps.rma.pdf && nextProps.rma.pdf.rma_id === prevState.downloading) {
            downloadBase64AsPdf(nextProps.rma.pdf.blob, nextProps.rma.pdf.rma_id)
            return {
                downloading: null,
            }
        }
        return null
    }

    handleDownloadClick(rma_id) {
        this.setState({
            downloading: rma_id,
        }, () => {
            this.props.downloadRma(rma_id)
        })
    }

    render() {
        const noRmas = this.props.rmas.length ? null : (
            <Card style={styles.emptyContainer}>
                <i>No returns on this order</i>
            </Card>
        )
        return (
            <div style={styles.container}>
                {
                    this.props.rmas.map(rma => {
                        const status = STATUSES[rma.status] || {}
                        const Icon = status ? status.icon : null
                        return (
                            <Card key={rma.rma_id} style={{ marginBottom: 10 }}>
                                <ExpansionPanel defaultExpanded={true} style={styles.expansionPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`rma-content-${rma._id}`}
                                        id={`rma-header-${rma._id}`}
                                    >
                                        <Avatar style={{ ...styles.avatar, backgroundColor: status.color }}>
                                            {Icon && <Icon />}
                                        </Avatar>
                                        <span>
                                            RMA: &nbsp;
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
                                            <a onClick={() => { this.context.router.push(`/returns/rma/${rma._id}`) }}>
                                                {rma.rma_id}
                                            </a><br />
                                            Current status: {rma.status}
                                        </span>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={styles.expansionPanelDetails}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component="th">Name</TableCell>
                                                    <TableCell component="th">SKU</TableCell>
                                                    <TableCell component="th">EAN</TableCell>
                                                    <TableCell component="th">Quantity</TableCell>
                                                    <TableCell component="th">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    rma.line_items.map((li, index) => (
                                                        <TableRow key={`${li.supplier_sku}.${index}`}>
                                                            <TableCell>{li.name}</TableCell>
                                                            <TableCell>{li.supplier_sku}</TableCell>
                                                            <TableCell>{li.ean}</TableCell>
                                                            <TableCell>{li.quantity}</TableCell>
                                                            <TableCell>{li.status}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelActions>
                                        <Tooltip title="Download return packing slip with possible shipping label">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => { this.handleDownloadClick(rma.rma_id) }}
                                            >
                                                <CloudDownloadIcon />
                                                &nbsp;
                                                Download
                                            </Button>
                                        </Tooltip>
                                    </ExpansionPanelActions>
                                </ExpansionPanel>
                            </Card>
                        )
                    })
                }
                {noRmas}
            </div>
        )
    }
}

Returns.propTypes = {
    rma: PropTypes.object,
    rmas: PropTypes.array,
    downloadRma: PropTypes.func.isRequired,
}

Returns.contextTypes = {
    router: PropTypes.object,
}

export default connect(({ rma }) => {
    return { rma }
}, dispatch => {
    return bindActionCreators({
        downloadRma,
    }, dispatch)
})(Returns)
