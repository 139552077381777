import socket from './../socket'
import listeners from '../listeners/sockets/inbound_receipts.js'

listeners(socket)

export function inbound_receipts(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_INBOUND_RECEIPTS':
            return action.data

        default:
            return state
    }
}
