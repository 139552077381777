import store from '../../store'
import {
    refreshProductVariant,
    refreshAutoCompleteProductVariants,
    refreshProductVariants,
    refreshProductDelta,
} from '../../actions/variants'
import { setMessage } from '../../actions/messages'
import { showNotification } from '../../actions/notifications'

export default function initVariantsSockets(socket) {
    socket.on('product/variant/phased-out', variant => {
        store.dispatch(refreshProductVariant(variant))
    })

    socket.on('product/variant/undid-phase-out', variant => {
        store.dispatch(refreshProductVariant(variant))
    })

    socket.on('product/variant', variant => {
        store.dispatch(refreshProductVariant(variant))
    })

    socket.on('product/variant/updated', variant => {
        store.dispatch(refreshProductVariant(variant))
    })

    socket.on('product/variants/autocomplete-result', variants => {
        store.dispatch(refreshAutoCompleteProductVariants(variants))
    })

    socket.on('product/variants/search-result', variants => {
        store.dispatch(refreshProductVariants(variants))
    })

    socket.on('product/variants/delta-purchaseable-result', data => {
        store.dispatch(setMessage(data))
    })

    socket.on('product/variants/update-purchaseable-result', data => {
        store.dispatch({ type: 'REFRESH' })
        store.dispatch(showNotification({
            message: `Zijn nu verkoopbaar geworden: ${data.purchaseable},
            zijn nu niet-verkoopbaar geworden: ${data.not_purchaseable},
            zijn onveranderd gebleven/niet gevonden: ${data.unchanged}`,
            duration: 7500,
        }))
    })

    socket.on('product/variants/update/ok', () => {
        store.dispatch({ type: 'REFRESH' })
    })

    socket.on('product/variants/update/delta/ok', variants => {
        store.dispatch(refreshProductDelta(variants))
    })
}
