import React from 'react'
import PropTypes from 'prop-types'
import {ListItem, IconButton} from 'material-ui'

import ListWrapper from './../../../shared/ListWrapper'

const style = {
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    innerDivStyle: {
        paddingTop: 8,
        paddingBottom: 12,
    },
}

function OrderShipping(props) {
    return (
        <ListWrapper title="Order & Shipping" style={props.style}>
            <ListItem
                innerDivStyle={style.innerDivStyle}
                primaryText={<span style={style.primaryTextListItemStyle}>Sales order</span>}
                secondaryText={props.mage_id}
                disabled={true}
                rightIconButton={
                    <IconButton
                        iconClassName="material-icons"
                        onClick={props.onNavigateToSalesOrder}
                    >
                        exit_to_app
                    </IconButton>
                }
            />
            <ListItem
                innerDivStyle={style.innerDivStyle}
                primaryText={<span style={style.primaryTextListItemStyle}>Consideration date</span>}
                secondaryText={props.consideration_date || 'Not all shipments delivered yet'}
                disabled={true}
            />
            <ListItem
                innerDivStyle={style.innerDivStyle}
                primaryText={<span style={style.primaryTextListItemStyle}>Created by</span>}
                secondaryText={props.created_by === 'proforto' ? 'One of our employees' : props.created_by}
                disabled={true}
            />
        </ListWrapper>
    )
}

OrderShipping.propTypes = {
    style: PropTypes.object,
    mage_id: PropTypes.string,
    created_by: PropTypes.string,
    consideration_date: PropTypes.string,
    onNavigateToSalesOrder: PropTypes.func.isRequired,
}
OrderShipping.defaultProps = {
    style: {},
    mage_id: '',
}

export default OrderShipping
