export const Statuses = {
    DRAFT: 'draft',
    REQUESTED: 'requested',
    APPROVED: 'approved',
    SUBMITTED: 'submitted',
    GOODS_DELIVERED: 'goods_delivered',
}

export const WithShipmentStatuses = [
    Statuses.APPROVED,
    Statuses.SUBMITTED,
    Statuses.GOODS_DELIVERED,
]

export const Types = {
    STANDARD: 'standard',
    CONSIGNMENT: 'consignment',
    OWN_STOCK: 'own_stock',
    RETENTION_OF_TITLE: 'retention_of_title',
}
