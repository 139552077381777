import socket from './../socket'
import listeners from '../listeners/sockets/shipments.js'
import _ from 'lodash'
import { getUserEmail } from './../store'

listeners(socket)

export function shipment(state = {}, action) {
    switch (action.type) {
        case 'REFRESH_SHIPMENT':
            if (action.shipment) {
                return action.shipment
            } else {
                socket.emit('shipment/get', { shipment_id: action.data }, getUserEmail())
            }
            return state

        case 'GET_INBOUND_SHIPMENT':
            socket.emit('inbound-shipment/get', { _id: action._id })
            return state

        case 'MARK_TRACKING_CODE_AS_SENT':
            socket.emit('shipment/tracking_code/send', { tracking_code: action.tracking_code }, getUserEmail())
            return state

        default:
            return state
    }
}

export function shipments(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            if (action.payload.pathname.indexOf('/logistics/inbound/check-in/') === -1) {
                state = []
            }
            return state

        case 'REFRESH_SALES_ORDER_SHIPMENTS':
            return _.groupBy(action.shipments, 'source')

        case 'REFRESH_RMA_SHIPMENTS':
        case 'REFRESH_RTV_SHIPMENTS':
        case 'REFRESH_PURCHASE_ORDER_SHIPMENTS':
            return action.shipments

        case 'SEARCH_INBOUND_SHIPMENTS':
            socket.emit('inbound-shipments/search', action.filters)
            return state

        case 'REFRESH_TYPED_ORDER_SHIPMENTS':
            return _.sortBy(action.shipments, s => s.estimated_for)

        case 'CREATE_SHIPMENT':
            socket.emit('shipment/create', { ...action }, getUserEmail())
            return state

        case 'POSTPONE_SHIPMENT':
            socket.emit('shipment/postpone', action.data, getUserEmail())
            return state

        case 'UPDATE_SHIPMENT_TRACKING':
            socket.emit('shipment/put/tracking_code', action.data, getUserEmail())
            return state

        case 'MARK_SHIPMENT_AS_DELIVERED':
            const date = new Date().toISOString()
            const s = action.shipment
            const tracking_change = {
                date,
                status: 'delivered',
                checkpoints: [{
                    status: 'delivered',
                    message: `Shipment marked as delivered by ${action.user}.`,
                    city: 'Profortool System',
                    date,
                }],
            }

            socket.emit('shipment/put/tracking_change', {
                _id: s._id,
                order_id: s.order_id,
                source: s.source,
                tracking_code: s.shipping_label.tracking_code,
                supplier_id: action.supplier_id,
                tracking_change,
            }, getUserEmail())
            return state

        default:
            return state
    }
}

export function couriers(state = [], action) {
    switch (action.type) {
        case 'GET_TRACKING_COURIERS':
            socket.emit('couriers/search', action.data)
            return state

        case 'REFRESH_TRACKING_COURIERS':
            return action.couriers

        default:
            return state
    }
}
