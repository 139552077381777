import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from '@material-ui/core'
import moment from 'moment'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {downloadBase64AsPdf} from '../../../utils/download'

import {getInvoiceByDebitMemo} from '../../../../commands/sockets/invoices'

import {getDebitMemoByOrder, synchronizeDebitMemo} from './../../../../actions/debit_memos'

import Header from './../../shared/Header'
import Info from './..//MemoInfo'
import LineItems from './debit-memo-detail/LineItems'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    date: {
        display: 'inline-block',
        textAlign: 'right',
        lineHeight: '30px',
        width: '100%',
    },
    content: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    info: {
        width: '15%',
    },
    line_items: {
        width: '83%',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'auto',
    },
    rightButtons: {
        float: 'right',
    },
}

class DebitMemoDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadedInvoice: null,
            syncDisabled: false,
        }
    }

    componentDidMount() {
        this.props.getDebitMemoByOrder(this.props.params.id)
    }

    componentDidUpdate() {
        if (this.state.downloadedInvoice &&
            this.props.invoice.id === this.state.downloadedInvoice) {
            this.downloadInvoice(this.props.invoice)
        }
    }

    downloadInvoice(invoice) {
        if (!invoice.error) {
            this.setState({
                downloadedInvoice: null,
            }, () => {
                downloadBase64AsPdf(invoice.blob, this.props.debit_memo.invoice_id)
            })
        }
    }

    getTotalPricing(debit) {
        return {
            version: debit.version,
            subtotal: debit.subtotal,
            discount: debit.discount_amount,
            fixed_discount_excl_tax: debit.fixed_discount_excl_tax || '0.0000', // allow v2 and v3 debits
            tax: debit.tax_amount,
            total: debit.grand_total,
        }
    }

    handleSynchronizeDebitMemoClick() {
        this.setState({syncDisabled: true}, () => {
            this.props.synchronizeDebitMemo(this.props.debit_memo._id)
        })
    }

    handleDownloadClick() {
        this.setState({downloadedInvoice: this.props.debit_memo._id}, () => {
            this.props.getInvoiceByDebitMemo(this.props.debit_memo._id)
        })
    }

    getRightButtons(debit) {
        const buttons = [
            <span key="date" style={style.date}>
                {moment(debit.created_at).format('DD-MM-YYYY')}
            </span>,
        ]
        if (debit.status === 'draft') {
            buttons.push(
                <div style={style.actionButtons}>
                    <Button
                        key="synchronize_to_exact"
                        disabled={this.state.syncDisabled}
                        style={{float: 'right'}}
                        color="primary"
                        variant="contained"
                        onClick={this.handleSynchronizeDebitMemoClick.bind(this)}
                    >
                        Synchronize to Exact
                    </Button>
                </div>
            )
        } else if (debit.invoice_id) {
            buttons.push(
                <div style={style.actionButtons}>
                    <Button
                        key="download_invoice"
                        style={{float: 'right'}}
                        color="primary"
                        variant="contained"
                        onClick={this.handleDownloadClick.bind(this)}
                    >
                        Download
                    </Button>
                </div>
            )
        }
        return buttons
    }

    render() {
        let nodes = []
        const debit = this.props.debit_memo
        if (debit.mage_id) {
            nodes = [
                <Header
                    key="header"
                    showSupplier={false}
                    title={`Debit Memo - Order ${debit.mage_id}`}
                    rightButtons={
                        <div style={style.rightButtons}>
                            {this.getRightButtons(debit)}
                        </div>
                    }
                    subheader={`Status: ${debit.status}`}
                />,
                <div key="content" style={style.content}>
                    <Info
                        style={style.info}
                        list={[
                            {text: 'Type', value: 'Debit Memo'},
                            {text: 'Exact Invoice Number', value: debit.invoice_id},
                            {text: 'Billing E-mail', value: debit.customer.billing_email || debit.customer.email},
                        ]}
                    />
                    <LineItems
                        showTitle={true}
                        style={style.line_items}
                        items={debit.line_items}
                        total_pricing={this.getTotalPricing(debit)}
                    />
                </div>,
            ]
        }
        return (
            <div style={style.container}>
                {nodes}
            </div>
        )
    }
}

DebitMemoDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    debit_memo: PropTypes.object.isRequired,
    invoice: PropTypes.object,
    getDebitMemoByOrder: PropTypes.func.isRequired,
    synchronizeDebitMemo: PropTypes.func.isRequired,
    getInvoiceByDebitMemo: PropTypes.func.isRequired,
}

DebitMemoDetail.defaultProps = {
    rtv: {},
    shipments: [],
}

export default connect(({debit_memo, invoice}) => {
    return {debit_memo, invoice}
}, dispatch => {
    const actions = {getDebitMemoByOrder, synchronizeDebitMemo, getInvoiceByDebitMemo}
    return bindActionCreators(actions, dispatch)
})(DebitMemoDetail)
