import store from '../../store'

export default function initPackingSlipsSockets(socket) {
    socket.on('packing-slip', packing_slip => {
        store.dispatch({type: 'DOWNLOAD_PACKING_SLIP', packing_slip})
    })

    socket.on('packing-slip/printed', data => {
        store.dispatch({type: 'PRINTED_PACKING_SLIP', data})
    })

    socket.on('packing-slip/print/error', data => {
        store.dispatch({type: 'UNPRINTABLE_PACKING_SLIP', data})
    })
}
