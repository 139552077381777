import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import _ from 'lodash'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    tablePagination: {
        fontSize: 14,
    },
}

class RmaTable extends Component {

    constructor(props) {
        super(props)

        this.handleTableRowClick = this.handleTableRowClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.rmas !== this.props.rmas
    }

    handleTableRowClick(index) {
        return this.props.onRmaClick && this.props.onRmaClick(index)
    }

    renderRmaRows(rmas) {
        if (rmas.length > 0) {
            return rmas.map((rma, index) => (
                <TableRow
                    hover={true}
                    onClick={() => {this.handleTableRowClick(index)}}
                    key={rma.rma_id}
                    style={{cursor: 'pointer'}}
                >
                    {
                        this.props.headerCols.map(column => (
                            <TableCell
                                key={`${rma.rma_id}.${column.property}`}
                                style={column.style}
                            >
                                {_.get(rma, column.property)}
                            </TableCell>
                        ))
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>No rmas found.</TableCell>
            </TableRow>
        )
    }

    handleChangePage() {
        // TablePagination - onChangePage
        // Method sometimes fires when no click on a page increment/decrement has been done
        // Paging didn't work when using the 'onChangePage' event
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    renderNavigation(rmas) {
        if (rmas.length > 0) {
            const {page, limit, headerCols, pagination} = this.props
            const from = Math.max(0, (limit * page))
            const to = Math.min(from + limit, from + rmas.length)
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={headerCols.length}>
                            <TablePagination
                                style={styles.tablePagination}
                                component="div"
                                count={rmas.length}
                                rowsPerPage={limit}
                                page={page} // 0 is first page
                                labelRowsPerPage={
                                    `Page ${pagination.current_page} of ${pagination.total_pages} , Rows: `
                                }
                                labelDisplayedRows={() => `${from + 1} - ${to}`}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    disabled: page === 0,
                                    onClick: this.handlePageDecrementClick,
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    disabled: rmas.length < limit,
                                    onClick: this.handlePageIncrementClick,
                                }}
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const rmas = this.props.rmas
        const rmaRows = this.renderRmaRows(rmas)
        const navigation = this.renderNavigation(rmas)

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            this.props.headerCols.map((column, index) => (
                                <TableCell
                                    style={{...column.style, fontWeight: 'bold'}}
                                    key={index}
                                    tooltip={column.tooltip}
                                >
                                    {column.content}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rmaRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

RmaTable.propTypes = {
    headerCols: PropTypes.array,
    pagination: PropTypes.object,
    rmas: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,
    onRmaClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

RmaTable.defaultProps = {
    headerCols: [{
        content: 'ID',
        tooltip: 'The RMA ID',
        style: {width: '20% '},
        property: 'rma_id',
    }, {
        content: 'Creation',
        tooltip: 'Creation Date',
        style: {width: '10% '},
        property: 'created_at',
    }, {
        content: 'Customer',
        tooltip: 'The customer',
        style: {width: '20% '},
        property: 'customer.name',
    }, {
        content: 'Sales order',
        tooltip: 'The sales order number',
        style: {width: '20% '},
        property: 'order_id',
    }, {
        content: 'Payment method',
        tooltip: 'Payment method',
        style: {width: '20% '},
        property: 'payment_info.method',
    }, {
        content: 'Status',
        tooltip: 'status',
        style: {width: '20%'},
        property: 'status',
    }],
}


export default RmaTable
