import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const style = {
    filtersContainer: {
        height: 60,
        paddingLeft: 20,
        paddingTop: 16,
        width: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
    },
    searchField: {
        height: 72,
        marginRight: 15,
        width: '50%',
    },
    selectField: {
        marginRight: 15,
        width: 200,
    },
}

class RtvFilters extends Component {

    constructor(props) {
        super(props)

        this.handleCriteriaChange = this.handleCriteriaChange.bind(this)
    }

    handleCriteriaChange(e) {
        this.props.onCriteriaChange(e.target.value)
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={style.searchField}
                    label="Search by rma reference, sales order or customer name."
                    value={this.props.filters.criteria}
                    onChange={this.handleCriteriaChange}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    select
                    style={style.selectField}
                    label="Select status"
                    value={this.props.filters.status}
                    onChange={this.props.onStatusChange}
                    InputLabelProps={{shrink: true}}
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    {
                        this.props.statuses.map(status => (
                            <MenuItem
                                key={status}
                                value={status}
                            >
                                {status}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    select
                    style={style.selectField}
                    onChange={this.props.onPaymentMethodChange}
                    value={this.props.filters.payment_code}
                    label="Select payment method"
                    InputLabelProps={{shrink: true}}
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    {
                        this.props.payment_methods.map(({method, code}) => (
                            <MenuItem
                                key={code}
                                value={code}
                            >
                                {method}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </div>
        )
    }
}

RtvFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    statuses: PropTypes.array,
    payment_methods: PropTypes.array,
    onCriteriaChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onPaymentMethodChange: PropTypes.func.isRequired,
}

RtvFilters.defaultProps = {
    filters: {},
    statuses: [],
    payment_methods: [],
}

export default RtvFilters
