import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListSubheader,
    IconButton,
} from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const styles = {
    dialogPaper: {
        width: 'auto',
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    list: {
        backgroundColor: '#eee',
    },
    leftList: {
        margin: '10px 10px 10px 0',
    },
    rightList: {
        margin: '10px 0px 10px 10px',
    },
}

class ChangeColumnsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentView: props.currentView,
        }

        autoBind(this)
    }

    handleChangeColumns(property, hidden) {
        const newView = this.state.currentView.map(column => {
            if (column.property !== property) {
                return column
            }
            return {
                ...column,
                hidden,
            }
        })
        this.setState({
            currentView: newView,
        })
    }

    getClickHandler(property, hidden) {
        return () => {
            this.handleChangeColumns(property, hidden)
        }
    }

    handleClose() {
        return this.props.onClose && this.props.onClose()
    }

    getVisibleColumns() {
        return this.state.currentView.filter(column => {
            const possible_column = this.props.columns.some(c => c.property === column.property)
            if (possible_column) {
                return !column.hidden
            }
            return false
        })
    }

    getInvisibleColumns() {
        return this.state.currentView.filter(column => {
            const possible_column = this.props.columns.some(c => c.property === column.property)
            if (possible_column) {
                return column.hidden
            }
            return false
        })
    }

    handleSave() {
        if (this.props.onColumnsSave) {
            this.props.onColumnsSave(this.state.currentView)
        }
        this.handleClose()
    }

    render() {
        return (
            <Dialog open={true} PaperProps={{ style: styles.dialogPaper }}>
                <DialogTitle>Change viewable columns</DialogTitle>
                <DialogContent style={styles.dialogContent}>
                    <List style={{ ...styles.list, ...styles.leftList }}>
                        <ListSubheader>Visible columns</ListSubheader>
                        {this.getVisibleColumns().map(column => (
                            <ListItem key={column.property}>
                                <ListItemText
                                    primary={column.content}
                                />
                                <ListItemIcon>
                                    <IconButton onClick={this.getClickHandler(column.property, true)}>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>
                    <List style={{ ...styles.list, ...styles.rightList }}>
                        <ListSubheader>Invisible columns</ListSubheader>
                        {this.getInvisibleColumns().map(column => (
                            <ListItem key={column.property}>
                                <ListItemIcon>
                                    <IconButton onClick={this.getClickHandler(column.property, false)}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText
                                    primary={column.content}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button contained onClick={this.handleClose}>Close</Button>
                    <Button contained color="primary" onClick={this.handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ChangeColumnsDialog.propTypes = {
    columns: PropTypes.array,
    currentView: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onColumnsSave: PropTypes.func.isRequired,
}

export default ChangeColumnsDialog
