import moment from 'moment'

/**
 * Format a javascript Date object with date and time
 *
 * @param {Date} date Date object
 * @returns {string} Formatted date string (date and time)
 */
export function formatDateTime(date) {
    return date ? moment(date).format('DD-MM-YY HH:mm') : null
}

/**
 * Format a javascript Date object with date
 *
 * @param {Date} date Date object
 * @returns {string} Formatted date string (date only)
 */
export function formatDate(date) {
    return date ? moment(date).format('DD-MM-YY') : null
}
