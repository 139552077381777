import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import autoBind from 'react-autobind'
import {GridList, FloatingActionButton, FontIcon} from 'material-ui'
import moment from 'moment'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {setMenuItems} from './../../../../../actions/menu_items'
import {searchPackingItems, getUnpackedCounts} from './../../../../../actions/packing_items'
import {printPackingItems} from './../../../../../commands/sockets/packing_items'
import Pagination from '../../../shared/Pagination'

import ShipmentPackingListFilters from './ShipmentPackingListFilters'
import ShipmentPackItem from './ShipmentPackItem'
import ShipmentPackingItemDetail from './ShipmentPackingItemDetail'
import PrintPackingSlipsDialog from './PrintPackingSlipsDialog'
import PrintSingleDialog from './PrintSingleDialog'

import Devices from './../../../../styles/devices'

import Colors from './../../../../styles/colors'

const style = {
    container: {
        width: '100%',
        marginTop: 20,
        height: window.innerHeight - 145,
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'none',
        flexWrap: 'nowrap',
    },
    gridContainer: {
        margin: '-15px',
        width: '55%',
        height: 'auto',
        overflowY: 'scroll',
        marginTop: 0,
        paddingLeft: 30,
        paddingBottom: 30,
    },
    gridList: {
        width: '100%',
        paddingTop: 0,
        gridCellPadding: 30,
        gridCellCount: 3,
    },
    packItem: {
        height: 250,
        boxShadow: '0 1px 6px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.12)',
        cursor: 'pointer',
        paddingTop: 20,
    },
    viewingItem: {
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '44%',
        maxHeight: '100%',
        marginRight: 30,
        marginTop: 0,
        marginBottom: 30,
        marginLeft: '1%',
    },
    bulkPrintFloatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        left: 20,
        bottom: 20,
    },
    singlePrintFloatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        left: 80,
        bottom: 20,
    },
}

const STORAGE_KEY = 'packing-filters'

class ShipmentPackingList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        const date = moment(filters.date)
        if (!filters.date) {
            date.add(1, 'days')
        }
        this.state = {
            selectedPackItem: {
                line_items: [],
            },
            bulkPrintDialogOpen: false,
            singlePrintDialogOpen: false,
            ignoreDate: !(filters.date),
            packing_items: [],
            pack: filters.pack || -1,
            channel: filters.channel || 'all',
            fulfillment_source: filters.fulfillment_source || 'all',
            item_type: filters.item_type || 'all',
            destination_type: filters.destination_type || 'all',
            instructions: filters.instructions || 'all',
            search: filters.value || '',
            date: date.format('YYYY-MM-DD'),
            limit: filters.limit || 9,
            page: filters.page || 1,
        }

        autoBind(this)
    }

    setMenuItems(counts) {
        delete counts.count
        this.props.setMenuItems(Object.keys(counts).map(count => {
            return {
                type: 'badge',
                tooltip: count,
                badgeContent: counts[count],
                badgeIcon: 'move_to_inbox',
            }
        }))
    }

    componentDidMount() {
        style.container = {
            ...style.container,
            height: window.innerHeight - 145,
        }
        this.searchPackingItems()
        this.props.getUnpackedCounts()
    }

    getFilterState() {
        return {
            search: this.state.search,
            pack: this.state.pack,
            date: this.state.date,
            channel: this.state.channel,
            fulfillment_source: this.state.fulfillment_source,
            item_type: this.state.item_type,
            destination_type: this.state.destination_type,
            instructions: this.state.instructions,
            limit: this.state.limit,
            page: this.state.page,
        }
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    searchPackingItems() {
        const mage_id = this.getMagentoSupplierId()
        const filters = this.getFilterState()
        const parameters = {
            value: filters.search,
            pack: filters.pack,
            date: filters.date,
            supplier: {mage_id},
            limit: +filters.limit,
            page: +filters.page,
        }
        if (filters.channel !== 'all') {
            parameters.channel = filters.channel
        }
        if (filters.fulfillment_source !== 'all') {
            parameters.fulfillment_source = filters.fulfillment_source
        }
        if (filters.destination_type !== 'all') {
            parameters.destination_type = filters.destination_type
        }
        if (filters.instructions !== 'all') {
            parameters.instructions = filters.instructions
        }
        if (parameters.fulfillment_source === 'personalisation' && filters.item_type !== 'all') {
            parameters.item_type = filters.item_type
        }
        if (parameters.instructions) {
            parameters.instructions = parameters.instructions === 'with'
        }
        if (this.state.ignoreDate) {
            delete parameters.date
        } else if (parameters.date) {
            parameters.date = moment(parameters.date).toDate()
        }
        this.storeFilters(parameters)
        this.props.searchPackingItems(parameters)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchPackingItems, 500)
    }

    getMagentoSupplierId() {
        return 6029
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let packing_item = null
        if (prevState.selectedPackItem) {
            packing_item = _.find(nextProps.packing_items, ['_id', prevState.selectedPackItem._id])
        }
        return {
            selectedPackItem: packing_item ? packing_item : _.head(nextProps.packing_items),
            shouldTableRender: true,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.unpacked_counts &&
            this.props.unpacked_counts.count &&
            this.props.unpacked_counts !== prevProps.unpacked_counts) {
            this.setMenuItems(this.props.unpacked_counts)
        }
    }

    handleSelectedPackItemChange(selectedPackItem) {
        this.setState({
            selectedPackItem,
        })
    }

    handleSearchFieldChange({target}) {
        this.setState({
            search: target.value,
            page: 1,
        }, this.setSearchTimeout)
    }

    handlePackedViewChange(e, index, value) {
        this.setState({
            pack: value,
            page: 1,
        }, this.searchPackingItems)
    }

    handleChannelChange(channel) {
        this.setState({
            channel,
            page: 1,
        }, this.searchPackingItems)
    }

    handleSourceChange(fulfillment_source) {
        this.setState({
            fulfillment_source,
            page: 1,
        }, this.searchPackingItems)
    }

    handleItemTypeChange(item_type) {
        this.setState({
            item_type,
            page: 1,
        }, this.searchPackingItems)
    }

    handleInstructionChange(instructions) {
        this.setState({
            instructions,
            page: 1,
        }, this.searchPackingItems)
    }

    handleDestinationChange(destination_type) {
        this.setState({
            destination_type,
            page: 1,
        }, this.searchPackingItems)
    }

    handleDateChange(date) {
        this.setState({
            date,
            page: 1,
        }, this.searchPackingItems)
    }

    handleIgnoreDateChange(e) {
        this.setState({
            ignoreDate: e.value,
            page: 1,
        }, this.searchPackingItems)
    }

    handlePageDecrement() {
        this.setState({
            page: this.state.page - 1,
        }, this.searchPackingItems)
    }

    handlePageIncrement() {
        this.setState({
            page: this.state.page + 1,
        }, this.searchPackingItems)
    }

    handleBulkPrintFloatingActionButtonClick() {
        this.setState({bulkPrintDialogOpen: true})
    }

    handleSinglePrintFloatingActionButtonClick() {
        this.setState({singlePrintDialogOpen: true})
    }

    handleBulkPrintDialogSubmit(filters) {
        if (filters.fulfillment_source === 'all') {
            delete filters.fulfillment_source
        }
        if (filters.channel === 'all') {
            delete filters.channel
        }
        if (filters.instructions === 'all') {
            delete filters.instructions
        } else {
            filters.instructions = filters.instructions === 'with'
        }
        this.props.printPackingItems(filters)
        this.handleDialogClose()
    }

    handleDialogClose() {
        this.setState({bulkPrintDialogOpen: false, singlePrintDialogOpen: false })
    }

    renderPagination(items) {
        if (items.length > 0) {
            return (
                <Pagination
                    page={this.state.page}
                    limit={this.state.limit}
                    onPreviousPageClick={this.handlePageDecrement}
                    onNextPageClick={this.handlePageIncrement}
                    items={items}
                />
            )
        }
        return null
    }

    getStyles(device) {
        if (device === Devices.DESKTOP || device === Devices.RETINA) {
            return style
        }
        return {
            ...style,
            container: {
                ...style.container,
                height: window.innerHeight - 175,
            },
            packItem: {
                height: 200,
            },
            gridContainer: {
                ...style.gridContainer,
                width: '51%',
            },
            gridList: {
                ...style.gridList,
                gridCellPadding: 15,
                gridCellCount: 2,
            },
            viewingItem: {
                ...style.viewingItem,
                width: '50%',
                maxHeight: '100%',
                marginRight: 15,
                marginTop: 0,
                marginBottom: 15,
            },
        }
    }

    splitPacked(acc, item) {
        const line_item = {...item}
        if (item.packed_quantity === 0) {
            acc.push({
                ...line_item,
                client_packed: false,
            })
        } else if (item.quantity !== line_item.packed_quantity) {
            acc.push({
                ...line_item,
                quantity: line_item.quantity - line_item.packed_quantity,
                packed_quantity: 0,
                client_packed: false,
            })
            acc.push({
                ...line_item,
                quantity: line_item.packed_quantity,
                client_packed: true,
            })
        } else {
            acc.push({
                ...line_item,
                client_packed: true,
            })
        }
        return acc
    }

    render() {
        const pagination = this.renderPagination(this.props.packing_items)
        const styles = this.getStyles(this.context.device)
        const selectedItemItems = this.state.selectedPackItem ? this.state.selectedPackItem.line_items : []
        const clientItems = selectedItemItems
            .reduce(this.splitPacked, [])
            .sort((a, b) => a.sku > b.sku ? 1 : -1)
            .map((i, index) => {
                i.index = index
                return i
            })
        const selectedItems = clientItems.filter(i => i.client_packed).map(i => i.index)
        return (
            <div style={{width: '100%'}}>
                <ShipmentPackingListFilters
                    device={this.context.device}
                    filters={this.getFilterState()}
                    ignoreDate={this.state.ignoreDate}
                    onSearchFieldChange={this.handleSearchFieldChange}
                    onSourceChange={this.handleSourceChange}
                    onItemTypeChange={this.handleItemTypeChange}
                    onInstructionChange={this.handleInstructionChange}
                    onDestinationChange={this.handleDestinationChange}
                    onPackedViewChange={this.handlePackedViewChange}
                    onDateChange={this.handleDateChange}
                    onChannelChange={this.handleChannelChange}
                    onIgnoreDateChange={this.handleIgnoreDateChange}
                />
                <div style={styles.container}>
                    <div style={styles.gridContainer}>
                        <GridList
                            cols={styles.gridList.gridCellCount}
                            cellHeight={styles.packItem.height}
                            padding={styles.gridList.gridCellPadding}
                            style={styles.gridList}
                        >
                            {
                                this.props.packing_items.map(item => (
                                    <ShipmentPackItem
                                        key={item._id}
                                        onClick={this.handleSelectedPackItemChange}
                                        isSelected={this.state.selectedPackItem._id === item._id}
                                        item={item}
                                        style={styles.packItem}
                                    />
                                ))
                            }
                        </GridList>
                        {pagination}
                    </div>
                    <ShipmentPackingItemDetail
                        style={this.props.packing_items.length === 0 ? {display: 'none'} : styles.viewingItem}
                        item={this.state.selectedPackItem}
                        client_items={clientItems}
                        selectedItems={selectedItems}
                    />
                </div>
                {
                    this.props.role !== 'warehouse' && this.props.role !== 'developer' ? null : (
                        <FloatingActionButton
                            onClick={this.handleBulkPrintFloatingActionButtonClick}
                            backgroundColor={Colors.primaryColor100}
                            style={style.bulkPrintFloatingActionButtonStyle}
                        >
                            <FontIcon className="material-icons">print</FontIcon>
                        </FloatingActionButton>
                    )
                }
                {
                    this.props.role !== 'warehouse' && this.props.role !== 'developer' ? null : (
                        <FloatingActionButton
                            onClick={this.handleSinglePrintFloatingActionButtonClick}
                            backgroundColor={Colors.primaryColor100}
                            style={style.singlePrintFloatingActionButtonStyle}
                        >
                            <FontIcon className="material-icons">select_all</FontIcon>
                        </FloatingActionButton>
                    )
                }
                <PrintPackingSlipsDialog
                    open={this.state.bulkPrintDialogOpen}
                    onSubmit={this.handleBulkPrintDialogSubmit}
                    onClose={this.handleDialogClose}
                />
                <PrintSingleDialog
                    open={this.state.singlePrintDialogOpen}
                    onClose={this.handleDialogClose}
                />
            </div>
        )
    }
}

ShipmentPackingList.propTypes = {
    packing_items: PropTypes.array,
    unpacked_counts: PropTypes.object.isRequired,
    filters: PropTypes.object,
    role: PropTypes.string,
    searchPackingItems: PropTypes.func.isRequired,
    printPackingItems: PropTypes.func,
    setMenuItems: PropTypes.func.isRequired,
    getUnpackedCounts: PropTypes.func.isRequired,
    supplier_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onLoaded: PropTypes.func,
}

ShipmentPackingList.defaultProps = {
    packing_items: [],
}

ShipmentPackingList.contextTypes = {
    device: PropTypes.string,
    orientation: PropTypes.string,
}

export default connect(({packing_items, filters, unpacked_counts = {}}) => {
    return {
        packing_items,
        unpacked_counts,
        filters: filters.packing_items,
    }
}, dispatch => {
    return bindActionCreators({searchPackingItems, getUnpackedCounts, printPackingItems, setMenuItems}, dispatch)
})(ShipmentPackingList)
