import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

const style = {
    width: 200,
    marginRight: 15,
}

const sourceOptions = [{
    text: 'Warehouse',
    value: 'warehouse',
}, {
    text: 'Crossdock',
    value: 'crossdock',
}, {
    text: 'Dropship',
    value: 'dropship',
}, {
    text: 'Personalisation',
    value: 'personalisation',
}]

function SourceFilter(props) {
    const options = props.filterSources instanceof Function ? sourceOptions.filter(props.filterSources) : sourceOptions
    return (
        <TextField
            select
            style={props.style || style}
            onChange={props.onChange}
            value={props.value}
            label="Select source"
            SelectProps={{ MenuProps: { style: { zIndex: 1600, maxHeight: 800 }, disableAutoFocusItem: true } }}
            InputLabelProps={{ shrink: true }}
        >
            {!props.allowAll ? null : (
                <MenuItem
                    value="all"
                >
                    All
                </MenuItem>
            )}
            {options.map(({ text, value }) => (
                <MenuItem
                    key={text}
                    value={value}
                >
                    {text}
                </MenuItem>
            ))}
        </TextField>
    )
}

SourceFilter.propTypes = {
    style: PropTypes.object,
    allowAll: PropTypes.bool,
    filterSources: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}
SourceFilter.defaultProps = {
    value: 'all',
    allowAll: false,
}

export default SourceFilter
