import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'

function ListWrapper(props) {
    return (
        <Paper style={props.style}>
            <List subheader={<ListSubheader>{props.title}</ListSubheader>}>
                {props.children}
            </List>
            {props.dialogs}
        </Paper>
    )
}

ListWrapper.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.array,
    dialogs: PropTypes.node,
}
ListWrapper.defaultProps = {
    title: '',
    children: [],
    dialogs: [],
}

export default ListWrapper
