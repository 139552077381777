import React from 'react'
import { Route, IndexRoute } from 'react-router'
import _ from 'lodash'

import App from './../components/App.js'
import Home from './../components/landing/Home.js'
import ProductList from './../components/inventory/ProductList'
import ProductDetail from './../components/inventory/ProductDetail'
import DebitMemoDetail from '../components/finance/debits/DebitMemoDetail'
import DebitMemoList from '../components/finance/debits/DebitMemoList'
import CreditMemoDetail from '../components/finance/credits/CreditMemoDetail'
import CreditMemoList from '../components/finance/credits/CreditMemoList'
import PersonalisationOrderList from '../components/production/production-orders/PersonalisationOrderList'
import PersonalisationOrderDetail from '../components/production/production-orders/PersonalisationOrderDetail'
import OrderList from '../components/sales/sales-orders/OrderList'
import OrderDetail from '../components/sales/sales-orders/OrderDetail'
import RtvDetail from '../components/returns/rtv/RtvDetail'
import RtvList from '../components/returns/rtv/RtvList'
import RmaDetail from '../components/returns/rma/RmaDetail'
import RmaList from '../components/returns/rma/RmaList'
import TypedOrderDetail from '../components/sales/typed-orders/TypedOrderDetail'
import TypedOrderList from '../components/sales/typed-orders/TypedOrderList'
import SupplierList from './../components/suppliers/SupplierList'
import SupplierDetail from './../components/suppliers/SupplierDetail'
import ShipmentPackingList from '../components/logistics/outbound/pack-by-shipment/ShipmentPackingList'
import PackFromWall from '../components/logistics/outbound/pack-from-wall/PackFromWall'
import PackSinglePiece from '../components/logistics/outbound/pack-single-pick/PackSinglePick'
import PurchaseRequisitionList from '../components/purchases/purchase-requisitions/PurchaseRequisitionList'
import PurchaseOrderList from './../components/purchases/purchase-orders/PurchaseOrderList'
import PurchaseOrderDetail from './../components/purchases/purchase-orders/PurchaseOrderDetail'
import PurchaseOutboundDetail from './../components/purchases/purchase-outbound-checklist/PurchaseOutboundDetail'
import PurchaseOutboundList from './../components/purchases/purchase-outbound-checklist/PurchaseOutboundList'
import DeliveryOrderList from '../components/logistics/outbound/delivery-orders/DeliveryOrderList'
import DeliveryOrderDetail from '../components/logistics/outbound/delivery-orders/DeliveryOrderDetail'
import PickListList from '../components/logistics/picking/pick-lists/PickListList'
import PickListDetail from '../components/logistics/picking/pick-lists/PickListDetail'
import SettingList from '../components/settings/SettingList'

const routeData = [{
    path: 'finance/debit-memos',
    component: DebitMemoList,
    permissions: ['finance'],
}, {
    path: 'finance/debit-memos/:id',
    component: DebitMemoDetail,
    permissions: ['finance'],
}, {
    path: 'finance/credit-memos',
    component: CreditMemoList,
    permissions: ['finance'],
}, {
    path: 'finance/credit-memos/:id',
    component: CreditMemoDetail,
    permissions: ['finance'],
}, {
    path: 'purchases/purchase-orders',
    component: PurchaseOrderList,
    permissions: ['purchase'],
    roles: ['external-supply-chain'],
}, {
    path: 'purchases/purchase-orders/:id',
    component: PurchaseOrderDetail,
    permissions: ['purchase'],
    roles: ['external-supply-chain'],
}, {
    path: '/purchases/requisitions/',
    component: PurchaseRequisitionList,
    permissions: ['purchase'],
}, {
    path: 'logistics/outbound/pack-by-shipment',
    component: ShipmentPackingList,
    permissions: ['packing'],
}, {
    path: 'logistics/outbound/pack-from-wall',
    component: PackFromWall,
    permissions: ['packing'],
}, {
    path: 'logistics/outbound/pack-single-pick',
    component: PackSinglePiece,
    permissions: ['packing'],
}, {
    path: 'logistics/outbound/delivery-orders',
    component: DeliveryOrderList,
    permissions: ['packing'],
}, {
    path: 'logistics/outbound/delivery-orders/:id',
    component: DeliveryOrderDetail,
    permissions: ['packing'],
}, {
    path: 'inventory',
    component: ProductList,
    permissions: ['inventory' ],
    roles: ['external-supply-chain'],
}, {
    path: 'inventory/:id',
    component: ProductDetail,
    permissions: ['inventory'],
}, {
    path: 'returns/rma',
    component: RmaList,
    permissions: ['returns'],
}, {
    path: 'returns/rma/:id',
    component: RmaDetail,
    permissions: ['returns'],
}, {
    path: 'returns/rtv',
    component: RtvList,
    permissions: ['returns'],
}, {
    path: 'returns/rtv/:id',
    component: RtvDetail,
    permissions: ['returns'],
}, {
    path: 'production/production-orders',
    component: PersonalisationOrderList,
    permissions: ['production'],
}, {
    path: 'production/production-orders/:production_order_id',
    component: PersonalisationOrderDetail,
    permissions: ['production'],
}, {
    path: 'sales/sales-orders',
    component: OrderList,
    permissions: ['sales'],
}, {
    path: 'sales/sales-orders/:id',
    component: OrderDetail,
    permissions: ['sales'],
}, {
    path: 'suppliers',
    component: SupplierList,
    permissions: ['sales'],
}, {
    path: 'suppliers/:id',
    component: SupplierDetail,
    permissions: ['sales'],
}, {
    path: 'sales/sales-orders/:order_id/:source/',
    component: TypedOrderDetail,
    permissions: ['sales'],
}, {
    path: 'sales/sales-orders/:order_id/:source/:supplier_id',
    component: TypedOrderDetail,
    permissions: ['sales'],
}, {
    path: 'sales/shipments',
    component: TypedOrderList,
    permissions: ['sales', 'supply'],
}, {
    path: 'sales/shipments/:order_id',
    component: TypedOrderDetail,
    permissions: ['supply'],
}, {
    path: 'purchases/purchase-orders/outbound',
    component: PurchaseOutboundList,
    permissions: ['sales', 'supply'],
}, {
    path: 'purchases/purchase-orders/outbound/:id',
    component: PurchaseOutboundDetail,
    permissions: ['sales', 'supply'],
    roles: ['external-supply-chain'],
}, {
    path: 'logistics/picking/pick-lists',
    component: PickListList,
    permissions: ['packing'],
}, {
    path: 'logistics/picking/pick-lists/:id',
    component: PickListDetail,
    permissions: ['packing'],
}, {
    path: 'settings',
    component: SettingList,
    permissions: ['sales'],
}]

function getFromMetaData(key) {
    try {
        const json = localStorage.getItem(key) || '[]'
        return JSON.parse(json)
    } catch (exception) {
        return undefined
    }
}

function getUserRoles() {
    return getFromMetaData('roles')
}

function getUserPermissions() {
    return getFromMetaData('permissions')
}


function isPermitted(replace, routeRoles, routePermissions, state) {
    const token = localStorage.getItem('userToken')
    const userRoles = getUserRoles()
    const userPermissions = getUserPermissions()
    localStorage.setItem('referral', state.location.pathname)

    const hasCorrectRoles = routeRoles?.some(role => userRoles?.includes(role)) ?? false
    const hasCorrectPermissions = routePermissions.some(permission =>  userPermissions?.includes(permission))
    if (!token || (!hasCorrectRoles && !hasCorrectPermissions)) {
        replace('/')
    }
}

export default (
    <Route path="/" component={App}>
        <IndexRoute component={Home} />
        {
            _.flatMap(routeData, route => {
                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        onEnter={(state, replace) => {
                            isPermitted(replace, route.roles, route.permissions, state)
                        }}
                    />
                )
            })
        }
        <Route path="*" component={Home} />
    </Route>
)
