import { syncSocketCall } from '../socket'

/**
 * Send command to get Product Variants by sku or ean
 * @param {Array<{ sku: string, ean: string }>} skus_or_eans Array of objects with a sku and ean as keys
 * @param {object} options Options object
 * @param {int} options.timeout Timeout in milliseconds
 * @return {object} Action
 */
export function getVariantsBySkuOrEan(skus_or_eans, { timeout = 3000 } = {}) {
    return syncSocketCall('product/variants/skus-or-eans', skus_or_eans, { timeout })
}
