import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    List,
    ListItem,
    FloatingActionButton,
    FontIcon,
    TextField,
    IconButton,
} from 'material-ui'
import _ from 'lodash'
import moment from 'moment'

import Colors from './../../styles/colors'

const style = {
    subheaderStyle: {
        fontFamily: 'Roboto',
    },
    floatingActionButtonStyle: {
        marginLeft: '85%',
        marginTop: -26,
    },
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    innerDivStyle: {
        paddingTop: 8,
        paddingBottom: 12,
    },
}

class Instructions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            adding: false,
            instruction: '',
        }
    }

    handleAddClick() {
        this.setState({
            adding: true,
        })
    }

    handleCloseClick() {
        this.setState({
            instruction: '',
            adding: false,
        })
    }

    handleInstructionChange(e) {
        this.setState({
            instruction: e.target.value,
        })
    }

    render() {
        const floatingButton = this.state.adding || !this.props.canAdd ? null : (
            <FloatingActionButton
                onClick={this.handleAddClick.bind(this)}
                backgroundColor={Colors.primaryColor100}
                style={this.props.floatingActionButtonStyle || style.floatingActionButtonStyle}
            >
                <FontIcon className="material-icons">add</FontIcon>
            </FloatingActionButton>
        )
        const instructionItems = _.map(this.props.instructions, (instruction, index) => {
            return (
                <ListItem
                    key={instruction.value + index}
                    innerDivStyle={style.innerDivStyle}
                    primaryText={<span style={style.primaryTextListItemStyle}>{instruction.value}</span>}
                    secondaryText={`
                        ${instruction.author}
                        (${moment(instruction.date || instruction.created_at).format('DD-MM-YY')})`
                    }
                />
            )
        })

        if (instructionItems.length === 0 && !this.state.adding) {
            instructionItems.push(
                <ListItem
                    key="no_instructions"
                    innerDivStyle={style.innerDivStyle}
                    primaryText={<i><span style={style.primaryTextListItemStyle}>No instructions yet</span></i>}
                />
            )
        }

        if (this.state.adding) {
            instructionItems.push(
                <ListItem
                    key="addInstruction"
                    disabled={true}
                    primaryText={
                        <TextField
                            onChange={this.handleInstructionChange.bind(this)}
                            multiLine={true}
                            value={this.state.instruction}
                            style={{margin: 0, height: 40}}
                            textareaStyle={{width: '80%', margin: 0}}
                            hintText="Instruction.."
                        />
                    }
                    rightIconButton={
                        <div>
                            <IconButton
                                iconClassName="material-icons"
                                onClick={e => {
                                    e.preventDefault()
                                    this.props.addInstruction(this.state.instruction)
                                    this.handleCloseClick()
                                }}
                            >
                                done
                            </IconButton>,
                            <IconButton
                                iconClassName="material-icons"
                                onClick={e => {
                                    e.preventDefault()
                                    this.handleCloseClick()
                                }}
                            >
                                close
                            </IconButton>
                        </div>
                    }
                />
            )
        }
        const header = (
            <CardHeader
                titleColor="rgba(0, 0, 0, 0.7)"
                titleStyle={{fontFamily: 'Roboto'}}
                title={this.props.title || 'Instructions'}
            />
        )
        const list = (
            <List>
                {instructionItems}
            </List>
        )
        const wrapped = this.props.wrap ? (<Card>{header}{list}</Card>)
            : (<div>{header}{list}</div>)
        return (
            <div style={_.merge({position: 'relative'}, this.props.style)}>
                {wrapped}
                {floatingButton}
            </div>
        )
    }
}

Instructions.propTypes = {
    instructions: PropTypes.array.isRequired,
    canAdd: PropTypes.bool,
    addInstruction: PropTypes.func,
    style: PropTypes.object,
    floatingActionButtonStyle: PropTypes.object,
    title: PropTypes.string,
    wrap: PropTypes.bool,
}

Instructions.defaultProps = {
    canAdd: true,
    instructions: [],
    style: {},
    floatingActionButtonStyle: null,
    title: 'Instructions',
    wrap: true,
}

export default Instructions
