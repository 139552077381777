import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const style = {
    usernameField: {
        display: 'block',
    },
    passwordField: {
        display: 'block',
    },
    centerContainer: {
        paddingTop: '50px',
        paddingLeft: '50px',
        margin: 'auto',
    },
    button: {
        width: 200,
        color: 'white',
        margin: '20px auto',
    },
}

class Home extends React.Component {
    /**
     * The component names get mangled when the production build is generated
     * To make the name identifyable a 'displayName' should be used.
     * This 'displayName' is used in the render method of profortool-app/src/client/app/components/App.js
     * @link https://exchangetuts.com/reactjs-reactchildrenforeach-can-i-get-the-child-component-name-1639947923128310
     */
    static displayName = 'Home'

    constructor(props) {
        super(props)
        this.props = props
    }

    componentDidUpdate(prevProps) {
        if (!this.isLoggedIn() && !prevProps.lock && this.props.lock) {
            this.signIn()
        }
    }

    isLoggedIn() {
        return this.props.idToken
    }

    signIn() {
        this.props.lock.show()
    }

    render() {
        return (
            <div style={style.centerContainer}>
                <h1>Welcome to Profortool</h1>
                <Button
                    onClick={this.signIn.bind(this)}
                    style={style.button}
                    color="secondary"
                    variant="contained"
                >
                    Sign in
                </Button>
            </div>
        )
    }
}

Home.propTypes = {
    idToken: PropTypes.string,
    lock: PropTypes.object,
    onSessionCreated: PropTypes.func,
}

export default Home
