import React, {Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import { DialogContent, Dialog, Button, TextField, DialogActions, DialogTitle } from '@material-ui/core'
import { green500 } from 'material-ui/styles/colors'

const style = {
    submitButton: {
        color: green500,
    },
}

class EditReferenceDialog extends Component {

    constructor(props) {
        super(props)
        this.state = this.initializeValues(props)

        autoBind(this)
    }

    initializeValues(props) {
        return {reference: props.reference}
    }

    handleChange(e) {
        this.setState({reference: e.target.value})
    }

    handleSubmitClick() {
        this.props.onSubmit(this.state.reference)
    }

    render() {
        return (
            <div>
                <Dialog
                    disableBackdropClick={true}
                    fullWidth="md"
                    onClose={this.props.onClose}
                    open={this.props.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Edit customer reference</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            onChange={this.handleChange}
                            value={this.state.reference}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={style.submitButton}
                            color="secondary"
                            onClick={this.handleSubmitClick}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

EditReferenceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    reference: PropTypes.string,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

EditReferenceDialog.defaultProps = {
    isUpdated: false,
}


export default EditReferenceDialog
