export const Statuses = {
    DRAFT: 'draft',
    PENDING: 'pending',
    SUBMITTED: 'submitted',
    CANCELLED: 'cancelled',
    CLOSED: 'closed',
}

export const Types = {
    CONSIGNMENT: 'consignment',
    RETENTION_OF_TITLE: 'retention_of_title',
    DEFAULT: 'default',
}
