import React, {Component} from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import autoBind from 'react-autobind'

import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core'

import AddPurchaseRequisitionTable from './AddPurchaseRequisitionTable'

/*
const style = {
    title: {
        height: 30, padding: 0
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block'
    },
    datePicker: {
        cursor: 'pointer'
    },
    datePickerTextField: {
        fontSize: 14
    }
};
*/

const initialState = {
    supplier_id: null,
    line_items: [],
}

class AddPurchaseRequisitionDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            line_items: [],
        }

        autoBind(this)
    }

    handleSubmit() {
        const state = this.state
        this.setState({...initialState}, () => {
            this.props.onSubmit(state.line_items.map(li => {
                return {
                    ...li,
                    business_rule: 'manual',
                }
            }))
        })
    }

    handleAddItem(item) {
        item.quantity = +item.quantity
        const update = {
            line_items: {$push: [item]},
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleRemoveItem(index) {
        const update = {
            line_items: {
                $apply: arr => {
                    return arr.filter((a, i) => i !== index)
                },
            },
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleUpdateItem(index, changed) {
        const item = {...this.state.line_items[index], ...changed}
        const update = {
            line_items: {
                $apply: arr => {
                    return arr.map((a, i) => i === index ? item : a)
                },
            },
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleChange(property, value) {
        const updateState = {}
        updateState[property] = value
        this.setState(updateState)
    }

    render() {
        const canSubmit = this.state.line_items.length > 0 && this.state.line_items.every(item => {
            return item.name && item.supplier_sku && item.quantity > 0 && item.reason
        })
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.visible}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>Add a purchase requisition</DialogTitle>
                <DialogContent>
                    <AddPurchaseRequisitionTable
                        line_items={this.state.line_items}
                        suppliers={this.props.suppliers}
                        visible={true}
                        onAddItem={this.handleAddItem}
                        onUpdateItem={this.handleUpdateItem}
                        onRemoveItem={this.handleRemoveItem}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={!canSubmit}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

AddPurchaseRequisitionDialog.propTypes = {
    visible: PropTypes.bool,
    suppliers: PropTypes.array.isRequired,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

AddPurchaseRequisitionDialog.defaultProps = {
    suppliers: [],
    isUpdated: false,
}

export default AddPurchaseRequisitionDialog
