import csv from 'papaparse'

/**
 * Parse all contents of a CSV file
 * @param {string} csvContents CSV contents as string
 * @param {object} options papaparse options
 * @returns {object[]} Array of key/value objects
 */
export function parseCsv(csvContents, options = {}) {
    const opts = { header: true, ...options }
    return csv.parse(csvContents, opts)
}
