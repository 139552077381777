import React from 'react'
import PropTypes from 'prop-types'
import {SelectField, MenuItem} from 'material-ui'

const style = {
    width: 200,
    marginRight: 15,
}

const options = [{
    text: 'All',
    value: 'all',
}, {
    text: 'With instructions',
    value: 'with',
}, {
    text: 'Without instructions',
    value: 'without',
}]

function InstructionFilter(props) {
    return (
        <SelectField
            style={style}
            onChange={props.onChange}
            value={props.value}
            floatingLabelText="Select instructions"
        >
            {
                options.map(option => {
                    return (
                        <MenuItem
                            key={option.text}
                            value={option.value}
                            primaryText={option.text}
                        />
                    )
                })
            }
        </SelectField>
    )
}

InstructionFilter.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}
InstructionFilter.defaultProps = {
    value: 'all',
}

export default InstructionFilter
