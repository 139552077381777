import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import { downloadBase64AsPdf } from '../../../utils/download'
import RmaCartDialog from './RmaCartDialog'
import Colors from '../../../styles/colors'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PaymentIcon from '@material-ui/icons/Payment'
import ListIcon from '@material-ui/icons/List'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import WarningIcon from '@material-ui/icons/Warning'

import moment from 'moment'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    addNote,
    getRma,
    authorizeRma,
    triageRmaItem,
    settleRma,
    deliverRma,
    setRmaStockCart,
    setRmaRtvCart,
    CART_TYPES,
} from './../../../../actions/rmas'
import { setRmaViewPreference } from './../../../../actions/sessions'
import { downloadRma } from './../../../../commands/sockets/rmas'
import { getSalesOrderByMageId } from './../../../../commands/sockets/sales_orders'

import OrderShipping from './rma-detail/OrderShipping'
import AcceptedItemsSorting from './rma-detail/AcceptedItemsSorting'
import Memos from './../../sales/sales-orders/order-detail/Memos'
import Header from './../../shared/Header'
import Payment from './../../shared/Payment'
import Notes from './../../shared/Notes'
import Customer from './../../sales/Customer'
import Shipments from './../../sales/typed-orders/Shipments'
import RmaLineItems from './RmaLineItems'
import RmaDetermineDialog from './rma-line-items/RmaDetermineDialog'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    button: {
        marginLeft: 20,
        color: 'white',
    },
    content: { display: 'flex', flexFlow: 'row', justifyContent: 'space-between', paddingTop: 10 },
    payment: { marginBottom: 15 },
    list: { marginTop: 0, marginBottom: 15 },
    tabs: {
        width: '78%',
        display: 'flex',
        flexFlow: 'column',
    },
    tabsContainer: {
        padding: 24,
    },
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    cartContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    cartCard: {
        width: '50%',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
    },
    cartHeader: {
        variant: 'h6',
        component: 'h6',
    },
    cartNoneWarning: { color: Colors.orange800 },
}

const line_item_cols = [{
    content: 'Name',
    tooltip: 'The product name',
    style: { width: '36% ' },
    property: 'name',
}, {
    content: 'Article number',
    tooltip: 'The product sku',
    style: { width: '24% ' },
    property: 'supplier_sku',
}]

function TabContainer(props) {
    return (
        <Typography component="div" style={style.tabContainer}>
            {props.children}
        </Typography>
    )
}

TabContainer.propTypes = { children: PropTypes.node }

const initialState = {
    cartDialog: {
        open: false,
        typeOfCart: '', // keyof CART_TYPES
    },
    determineDialog: {
        open: false,
        line_item_sku: null,
    },
}

class RmaDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            removed_indexes: [],
            tab: 1,
            addOpen: false,
            downloading: null,
        }

        autoBind(this)
    }

    componentDidMount() {
        if (!this.props.rma._id || this.props.rma._id !== this.props.params.id) {
            this.props.getRma(this.props.params.id)
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.rma && nextProps.rma.pdf && prevState.downloading === nextProps.rma.rma_id) {
            downloadBase64AsPdf(nextProps.rma.pdf.blob, nextProps.rma.rma_id)
            return {
                ...prevState,
                downloading: null,
            }
        }
        return null
    }

    mergeItems(rows, status) {
        const items = []

        this.props.rma.line_items.forEach((item, index) => {
            item = { ...item }

            if (rows.indexOf(index) !== -1) {
                item.status = status

                const alreadyPresent = items.find(i =>
                    i.status === item.status && i.supplier_sku === item.supplier_sku
                )

                if (alreadyPresent) {
                    alreadyPresent.quantity++
                } else {
                    items.push(item)
                }
            }
        })

        return items
    }

    handleTriage(line_item, location, decision) {
        this.props.triageRmaItem(this.props.params.id, line_item, location, decision)
        this.setState({ determineDialog: { ...initialState.determineDialog } })
    }

    handleAccept(line_item, location) {
        const item_with_status = { ...line_item, status: 'accepted' }
        this.handleTriage(item_with_status, location, 'accept')
    }

    handleReject(line_item, location) {
        const item_with_status = { ...line_item, status: 'rejected' }
        this.handleTriage(item_with_status, location, 'reject')
    }

    handleDownload() {
        this.setState({
            downloading: this.props.rma.rma_id,
        }, () => {
            this.props.downloadRma(this.props.rma.rma_id)
        })
    }

    handlePayment(rows) {
        this.props.settleRma(this.props.params.id, this.mergeItems(rows, 'credited'))
    }

    handleApprove() {
        this.props.authorizeRma(this.props.params.id, true)
    }

    handleDeny() {
        this.props.authorizeRma(this.props.params.id, false)
    }

    handleSettle() {
        this.props.settleRma(this.props.params.id,
            this.mergeItems(this.props.rma.line_items.map((item, index) => index), 'credited'))
    }

    handleGoodsReceived() {
        this.props.deliverRma(this.props.rma.rma_id)
    }

    handleNavigateToSalesOrder(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.getSalesOrderByMageId(this.props.rma.order_id)
    }

    handleTabChange(e, tab) {
        this.setState({ tab })
    }

    handleViewChange(e, view) {
        this.props.setRmaViewPreference(view)
    }

    handleSelectCartClick(typeOfCart = '') {
        this.setState({ cartDialog: { ...this.state.cartDialog, open: true, typeOfCart } })
    }

    handleCartDialogClose() {
        this.setState({ cartDialog: { ...initialState.cartDialog } })
    }

    handleCartDialogSubmit(cart) {
        if (cart) {
            switch (this.state.cartDialog.typeOfCart) {
                case CART_TYPES.STOCK:
                    this.props.setRmaStockCart(cart)
                    break

                case CART_TYPES.RTV:
                    this.props.setRmaRtvCart(cart)
                    break

                default:
                    break
            }
        }

        this.setState({ cartDialog: { ...initialState.cartDialog } })
    }

    handleLineItemSelect(line_item) {
        this.setState({
            determineDialog: {
                ...this.state.determineDialog,
                open: true,
                line_item,
            },
        })
    }

    handleDetermineDialogClose() {
        this.setState({ determineDialog: { ...initialState.determineDialog } })
    }

    getActions(status) {
        let children = [
            <Button
                key="download"
                variant="contained"
                onClick={this.handleDownload}
                color="primary"
                style={style.button}
            >
                <CloudDownloadIcon />&nbsp;Download
            </Button>,
        ]

        switch (status) {
            case 'pending':
                children = [
                    ...children,
                    <Button
                        key="approve"
                        variant="contained"
                        onClick={this.handleApprove}
                        color="secondary"
                        style={style.button}
                    >
                        Approve
                    </Button>,
                    <Button
                        key="deny"
                        variant="contained"
                        onClick={this.handleDeny}
                        color="error"
                        style={style.button}
                    >
                        Deny
                    </Button>,
                ]
                break

            case 'approved':
                children = [
                    ...children,
                    <Button
                        key="goods-received"
                        variant="contained"
                        onClick={this.handleGoodsReceived}
                        color="secondary"
                        style={style.button}
                    >
                        Goods received
                    </Button>,
                ]
                break

            case 'accepted':
            case 'partially_accepted':
                if (this.props.role === 'finance') {
                    children = [
                        ...children,
                        <Button
                            key="settle"
                            variant="contained"
                            onClick={this.handleSettle}
                            color="warning"
                            style={style.button}
                        >
                            Settle
                        </Button>,
                    ]
                }
                break

            default:
                break
        }

        return (
            <div style={{ float: 'right', width: 'auto' }}>
                <span style={{ display: 'inline-block', textAlign: 'right', lineHeight: '30px', width: '100%' }}>
                    {moment(this.props.rma.created_at).format('DD-MM-YY')}
                </span>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignContent: 'flex-end',
                        width: 'auto',
                    }}
                >
                    {children}
                </div>
            </div>
        )
    }

    getRejectedLineItems(items, status) {
        const cols = [...line_item_cols]
        if (items.some(item => Boolean(item.ean))) {
            cols.push({
                content: 'EAN',
                tooltip: 'The product EAN',
                style: { width: '14% ' },
                property: 'ean',
            })
        }

        items = items.map(item => {
            if (item.credited_at) {
                item.changed_at = moment(item.credited_at).format('DD-MM-YY')
            } else if (item.triaged_at) {
                item.changed_at = moment(item.triaged_at).format('DD-MM-YY')
            }
            return item
        })
        if (items.some(item => !!item.changed_at)) {
            cols.push({
                content: 'Updated At',
                tooltip: 'Date of triage',
                style: { width: '10%' },
                property: 'changed_at',
            })
        }
        if (items.some(item => item.reason_text !== null)) {
            cols.push({
                content: 'Reason of return',
                style: { width: '20%' },
                property: 'reason_text',
                transform: text => this.context.translate(text),
            })
        }
        if (items.some(item => item.disable_refund)) {
            cols.push({
                content: 'Will be refunded when accepted',
                style: { width: '20%' },
                property: 'disable_refund',
                transform: value => value ? 'No' : 'Yes',
            })
        }
        return (
            <RmaLineItems
                style={{ card: { width: '100%' } }}
                headerCols={cols}
                status={status}
                line_items={items}
                title="Rejected items"
            />
        )
    }

    groupItemsBySku(acc, i) {
        const found = acc.find(acc_i => acc_i.supplier_sku === i.supplier_sku)
        if (found) {
            found.quantity += i.quantity
        } else {
            acc.push({ ...i })
        }
        return acc
    }

    getAcceptedLineItems(items) {
        const stock_items = items.filter(i => !!i.warehouse).reduce(this.groupItemsBySku, [])
        const rtv_items = items.filter(i => !i.warehouse).reduce(this.groupItemsBySku, [])
        return (
            <AcceptedItemsSorting
                stock_items={stock_items}
                rtv_items={rtv_items}
            />
        )
    }

    getChosenCarts() {
        const noneSelected = (
            <React.Fragment>
                <WarningIcon style={style.cartNoneWarning} /> No cart selected
            </React.Fragment>
        )

        return (
            <div style={style.cartContainer}>
                {!this.state.cartDialog.open ? null :
                    <RmaCartDialog
                        {...this.state.cartDialog}
                        initialValue={this.state.cartDialog.typeOfCart === CART_TYPES.RTV
                            ? this.props.rma_carts.rtv
                            : this.props.rma_carts.stock}
                        onClose={this.handleCartDialogClose}
                        onSubmit={this.handleCartDialogSubmit}
                        rma_carts={this.props.rma_carts}
                    />
                }
                <Card style={style.cartCard} onClick={this.handleSelectCartClick.bind(this, CART_TYPES.STOCK)}>
                    <CardHeader title="Stock cart" titleTypographyProps={style.cartHeader} />
                    <CardHeader
                        title={this.props.rma_carts.stock || noneSelected}
                        titleTypographyProps={style.cartHeader}
                    />
                </Card>
                <Card style={style.cartCard} onClick={this.handleSelectCartClick.bind(this, CART_TYPES.RTV)}>
                    <CardHeader title="RTV cart" titleTypographyProps={style.cartHeader} />
                    <CardHeader
                        title={this.props.rma_carts.rtv || noneSelected}
                        titleTypographyProps={style.cartHeader}
                    />
                </Card>
            </div>
        )
    }

    getProcessableLineItems(items, status, title) {
        const cols = [...line_item_cols]
        if (items.some(item => Boolean(item.ean))) {
            cols.push({
                content: 'EAN',
                tooltip: 'The product EAN',
                style: { width: '14% ' },
                property: 'ean',
            })
        }

        items = items.map(item => {
            item.type = item.warehouse ? 'Warehouse' : 'Supplier'
            if (item.credited_at) {
                item.changed_at = moment(item.credited_at).format('DD-MM-YY')
            } else if (item.triaged_at) {
                item.changed_at = moment(item.triaged_at).format('DD-MM-YY')
            }
            return item
        })
        if (items.some(item => !!item.changed_at)) {
            cols.push({
                content: 'Updated At',
                tooltip: 'Date of triage',
                style: { width: '10%' },
                property: 'changed_at',
            })
        }
        if (items.some(item => item.reason_text !== null)) {
            cols.push({
                content: 'Reason of return',
                style: { width: '20%' },
                property: 'reason_text',
                transform: text => this.context.translate(text),
            })
        }
        if (items.some(item => item.disable_refund)) {
            cols.push({
                content: 'Will be refunded when accepted',
                style: { width: '20%' },
                property: 'disable_refund',
                transform: value => value ? 'No' : 'Yes',
            })
        }

        return (
            <RmaLineItems
                style={{ card: { width: '100%' } }}
                headerCols={cols}
                status={status}
                line_items={items}
                title={title}
                onLineItemSelect={this.handleLineItemSelect}
                useBarcodeScanner={true}
            />
        )
    }

    getSettledSummary(items) {
        if (items.every(i => i.status === 'credited')) {
            return '(accepted)'
        }
        return '(partially accepted)'
    }

    render() {
        let nodes = []
        const rma = this.props.rma
        if (rma._id) {
            const line_items = rma.line_items.map(i => {
                const dup = { ...i }
                return dup
            })
            const processable_items = line_items.filter(li => !li.status)
            const accepted_items = line_items.filter(li => li.status === 'accepted' || li.status === 'credited')
            const rejected_items = line_items.filter(li => li.status === 'rejected')
            const summary = rma.status !== 'settled' ? '' : this.getSettledSummary(rma.line_items)
            nodes = [
                <Header
                    key="header"
                    title={`RMA - ${rma.rma_id}`}
                    rightButtons={this.getActions(rma.status)}
                    subheader={`Status: ${rma.status.replace('_', ' ')} ${summary}`}
                />,
                <div
                    key="content"
                    style={style.content}
                >
                    <div style={{ display: 'flex', flexFlow: 'column', width: '20%' }}>
                        <Payment
                            payment_info={rma.payment_info}
                            style={style.payment}
                        />
                        <Customer
                            style={style.list}
                            small={true}
                            customer={rma.customer}
                        />
                        <OrderShipping
                            style={style.list}
                            mage_id={rma.order_id}
                            consideration_date={rma.consideration_date}
                            created_by={rma.trigger}
                            onNavigateToSalesOrder={this.handleNavigateToSalesOrder.bind(this)}
                        />
                        <Notes
                            onAddNote={note => {
                                this.props.addNote(rma._id, {
                                    author: this.props.email,
                                    value: note,
                                    created_at: new Date().toISOString(),
                                })
                            }}
                            notes={rma.notes}
                        />
                    </div>
                    <div style={style.tabs}>
                        <AppBar position="static" color="primary">
                            <Tabs variant="fullWidth" value={this.state.tab} onChange={this.handleTabChange}>
                                <Tab icon={<PaymentIcon />} label="Credit memo" />
                                <Tab icon={<ListIcon />} label="Items" />
                                <Tab icon={<LocalShippingIcon />} label="Shipments" />
                            </Tabs>
                        </AppBar>
                        {this.state.tab === 0 && (
                            <TabContainer>
                                {rma.disable_refund ? (
                                    <Paper style={{ padding: 24 }}>
                                        This RMA will not get an automatic credit memo,
                                        since it was created with the option <b>
                                            Disable refund
                                        </b> by one of our employees.
                                    </Paper>
                                ) : (
                                    <Memos
                                        credit_memos={this.props.credit_memos}
                                    />
                                )}
                            </TabContainer>
                        )}
                        {this.state.tab === 1 && (
                            <TabContainer>
                                {this.getChosenCarts()}
                                {this.getProcessableLineItems(processable_items, rma.status, 'Items to process')}
                                {this.getRejectedLineItems(rejected_items, rma.status)}
                                {this.getAcceptedLineItems(accepted_items, rma.status)}
                            </TabContainer>
                        )}
                        {this.state.tab === 2 && (
                            <TabContainer>
                                <Shipments
                                    style={{
                                        width: '100%',
                                        float: 'right',
                                    }}
                                    date={false}
                                    actions={false}
                                    shipments={this.props.shipments}
                                />
                            </TabContainer>
                        )}
                    </div>
                </div>,
            ]
        }
        return (
            <div style={style.container}>
                {nodes}
                {!this.state.determineDialog.open ? null : (
                    <RmaDetermineDialog
                        {...this.state.determineDialog}
                        rma_carts={this.props.rma_carts}
                        onAccept={this.handleAccept}
                        onReject={this.handleReject}
                        onClose={this.handleDetermineDialogClose}
                    />
                )}
            </div>
        )
    }
}

RmaDetail.contextTypes = {
    translate: PropTypes.func.isRequired,
}
RmaDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    preferences: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    rma: PropTypes.object.isRequired,
    rma_carts: PropTypes.object.isRequired,
    shipments: PropTypes.array,
    credit_memos: PropTypes.array.isRequired,
    addNote: PropTypes.func.isRequired,
    getRma: PropTypes.func.isRequired,
    authorizeRma: PropTypes.func.isRequired,
    getSalesOrderByMageId: PropTypes.func.isRequired,
    downloadRma: PropTypes.func.isRequired,
    settleRma: PropTypes.func.isRequired,
    deliverRma: PropTypes.func.isRequired,
    triageRmaItem: PropTypes.func.isRequired,
    setRmaViewPreference: PropTypes.func.isRequired,
    setRmaStockCart: PropTypes.func.isRequired,
    setRmaRtvCart: PropTypes.func.isRequired,
}

export default connect(({ rma, rma_carts, credit_memo = null, shipments = [], session }) => {
    return {
        rma,
        rma_carts,
        credit_memos: !credit_memo ? [] : [credit_memo],
        shipments,
        preferences: !!session.preferences && session.preferences instanceof Object ? session.preferences : {},
    }
}, dispatch => {
    const actions = {
        addNote,
        getRma,
        authorizeRma,
        getSalesOrderByMageId,
        downloadRma,
        settleRma,
        deliverRma,
        triageRmaItem,
        setRmaViewPreference,
        setRmaStockCart,
        setRmaRtvCart,
    }
    return bindActionCreators(actions, dispatch)
})(RmaDetail)
