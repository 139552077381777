export function refreshProductVariant(variant) {
    return { type: 'REFRESH_PRODUCT_VARIANT', variant }
}

export function refreshAutoCompleteProductVariants(variants) {
    return { type: 'REFRESH_AUTOCOMPLETE_PRODUCT_VARIANTS', variants }
}

export function refreshProductVariants(variants) {
    return { type: 'REFRESH_PRODUCT_VARIANTS', variants }
}

export function refreshProductDelta(variants) {
    return { type: 'REFRESH_PRODUCT_DELTA', variants }
}

export function searchProductVariants(filters) {
    return { type: 'SEARCH_PRODUCT_VARIANTS', filters }
}

export function deleteProductVariant(variant) {
    return { type: 'DELETE_PRODUCT_VARIANT', variant }
}

export function updateInventory(data, author) {
    return { type: 'UPDATE_PRODUCT_VARIANTS', data, author }
}

export function deltaBulkUpdate(data) {
    return { type: 'DELTA_BULK_UPDATE_VARIANTS', data }
}

export function bulkUpdate(data) {
    return { type: 'BULK_UPDATE_VARIANTS', data }
}

export function logFileReaderResult(data) {
    return { type: 'LOG_FILE_READER_RESULT', data }
}
