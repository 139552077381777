import socket from '../../socket'
import { getUserEmail } from '../../store'

export function getPickList(increment_id) {
    socket.emit('pick-lists/get-one', { increment_id }, getUserEmail())
    return { type: 'GET_PICK_LIST', data: increment_id }
}

export function listPickLists({ limit = 20, page = 1 }) {
    const data = { limit, page }
    socket.emit('pick-lists/list', data, getUserEmail())
    return { type: 'LIST_PICK_LISTS', data }
}

export function generateDeliveryOrderPickLists({ fulfillment_source, limit, estimations, pick_list_type }) {
    const data = { fulfillment_source, limit, estimations, pick_list_type }
    socket.emit('pick-lists/delivery-order/generate', data, getUserEmail())
    return { type: 'GENERATE_DELIVERY_ORDER_PICK_LISTS', data }
}

export function generateExcessInventoryPickLists({ supplier }) {
    const data = { supplier }
    socket.emit('pick-lists/excess-inventory/generate', data, getUserEmail())
    return { type: 'GENERATE_EXCESS_INVENTORY_PICK_LISTS', data }
}

export function searchPickLists(data = {}) {
    socket.emit('pick-lists/search', data, getUserEmail())
    return { type: 'SEARCH_PICK_LISTS', data }
}

export function createPickList(data = {}) {
    socket.emit('pick-lists/create', data, getUserEmail())
    return { type: 'CREATE_PICK_LIST', data }
}

export function addPickToPickList(increment_id, pick) {
    const picks = [pick]
    socket.emit('pick-lists/add-picks', { increment_id, picks }, getUserEmail())
    return { type: 'ADD_PICK_TO_PICK_LIST', data: { increment_id, picks } }
}

export function removePickFromPickList(increment_id, pick) {
    const picks = [pick]
    socket.emit('pick-lists/remove-picks', { increment_id, picks }, getUserEmail())
    return { type: 'REMOVE_PICK_FROM_PICK_LIST', data: { increment_id, picks } }
}

export function finalisePickList(increment_id) {
    socket.emit('pick-lists/finalise', { increment_id }, getUserEmail())
    return { type: 'FINALISE_PICK_LIST', data: { increment_id } }
}

export function revokePickList(increment_id) {
    socket.emit('pick-lists/revoke', { increment_id }, getUserEmail())
    return { type: 'REVOKE_PICK_LIST', data: { increment_id } }
}
