import * as Sentry from '@sentry/react'
import socket from './socket'
import { createStore, compose, applyMiddleware } from 'redux'
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'

import rootReducer from './reducers/index'

const permissions = localStorage.getItem('permissions') || '[]'
const roles = localStorage.getItem('roles') || '[]'

const defaultState = {
    variant: { product: {} },
    variants: [],
    stocks: [],
    packing_items: [],
    unpacked_counts: {},
    stock_queue_items: [],
    debit_memos: [],
    debit_memo: {},
    invoice: {},
    sales_orders: [],
    sales_order: {},
    rtv: {},
    rtvs: [],
    rma: {},
    rmas: [],
    rma_carts: {
        stock: null,
        rtv: null,
    },
    rma_product: { loading: false, variant: null },
    pagination: {},
    purchase_order: {},
    impression: {},
    personalisations: [],
    purchase_orders: [],
    typed_orders: [],
    typed_order: {},
    shipment: {},
    shipments: [],
    couriers: [],
    cancellations: [],
    suppliers: [],
    supplier: {},
    label: {},
    locations: [],
    work_slip: {},
    packing_slip: {},
    logs: [],
    inbound_receipts: [],
    inbound_candidates: [],
    delivery_orders: [],
    pick_list: {},
    promo_items: [],
    pick_lists: [],
    features: {},
    printers: [],
    session: {
        metadata: JSON.parse(localStorage.getItem('authorisation_metadata') || '{}'),
        permissions: permissions ? JSON.parse(permissions) : [],
        roles: roles ? JSON.parse(roles) : [],
        token: localStorage.getItem('userToken') || null,
        preferences: JSON.parse(localStorage.getItem('preferences') || '{}'),
    },
    notification: {},
    menu_items: [],
    filters: JSON.parse(localStorage.getItem('filters') || '{}'),
}

let middleware = null
const devToolsAllowed = window.location.host !== 'profortool.com'
    && window.location.host !== 'profortool.production.profortool.com'
if (window.__REDUX_DEVTOOLS_EXTENSION__ && devToolsAllowed) {
    middleware = compose(
        applyMiddleware(routerMiddleware(browserHistory)),
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
} else {
    middleware = applyMiddleware(routerMiddleware(browserHistory))
}

const store = createStore(rootReducer, defaultState, middleware)

export function getUserEmail() {
    const session = store.getState().session
    return session && session.metadata ? session.metadata.email : null
}

// Set email of current user in Sentry tag (unsets it when user is no longer logged in)
store.subscribe(() => {
    const email = getUserEmail()

    Sentry.setTag('profortool_user', email ? email : void 0)
    Sentry.setUser({ email: email ? email : void 0 })
})

export const history = syncHistoryWithStore(browserHistory, store)
history.listen(location => socket.emit('location', location))
socket.emit('location', history.getCurrentLocation())

export default store
