import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'


const style = {
    table: {
        overflowY: 'scroll', tableLayout: 'auto',
    },
    paper: {
        marginBottom: 15,
        width: '100%',
        position: 'relative',
    },
    selectedHeaderRow: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: 64,
        transition: 'all 0.25s ease',
    },
    selectedItemsText: {
        color: '#FFFFFF',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    bigColumn: {
        width: '25%',
    },
    mediumColumn: {
        width: '12%',
    },
    smallColumn: {
        width: '5%',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
}

class ReceiptLineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const receipt_items = this.props.receipt_items
        const contents = (
            <Table
                style={style.table}
            >
                <TableHead>
                    <TableRow>
                        <TableCell variant="th">Quantity</TableCell>
                        <TableCell variant="th">Name</TableCell>
                        <TableCell variant="th">SKU</TableCell>
                        <TableCell variant="th">EAN</TableCell>
                        <TableCell variant="th">Date received</TableCell>
                        <TableCell variant="th">Receipt ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        receipt_items.map(item => (
                            <TableRow
                                key={`${item.sku}.${item.receipt_increment_id}`}
                            >
                                <TableCell style={style.smallColumn}>
                                    {item.quantity}
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                    {item.sku}
                                </TableCell>
                                <TableCell>{item.ean}</TableCell>
                                <TableCell>{moment(item.receipt_created_at).format('DD-MM-YY HH:mm')}</TableCell>
                                <TableCell>{item.receipt_increment_id}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        )
        const containerStyle = { ...style.paper, ...this.props.style }
        return (
            <div style={containerStyle}>
                {contents}
            </div>
        )
    }
}

ReceiptLineItems.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    style: PropTypes.object,
    receipt_items: PropTypes.array,
}

export default ReceiptLineItems
