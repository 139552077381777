export const ReturnReasons = {
    null: 'Anders',
    'size-too-small': 'Artikel is te klein',
    'size-too-large': 'Artikel is te groot',
    'color-is-different': 'De kleur wijkt af',
    'damaged-delivery': 'Beschadigd bij levering',
    'late-delivery': 'Te laat geleverd',
    'wrong-size-color-delivery': 'Verkeerde maat of kleur geleverd',
    'wrong-product-delivery': 'Een ander product is geleverd',
    'not-expected': 'Voldoet niet aan verwachtingen',
    other: 'Anders',
}
