import gettextparser from 'gettext-parser'
import Gettext from 'node-gettext'
import sprintf from 'sprintf-js'

const gt = new Gettext()

/**
 * @param {string} language
 * @returns {void}
 */
export async function prepareTranslations(language = 'en') {
    const res = await fetch(`/translations/${language}.po`)
    const translations = await res.text()

    gt.addTranslations(language, 'profortool-app', gettextparser.po.parse(translations))
    gt.setTextDomain('profortool-app')
    gt.setLocale(language)
}

/**
 * @param {string} text
 * @param {Record<string, string>} data
 * @returns {string}
 */
export function translate(text, data) {
    const translated = gt.gettext(text)
    return sprintf.sprintf(translated, data)
}
