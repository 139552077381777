import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Divider, TextField, MenuItem} from '@material-ui/core'

const styles = {
    container: {
        display: 'flex', justifyContent: 'flex-start',
    },
    textField: {
        minWidth: 240,
        marginRight: 20,
    },
}

class IdentifierFieldSet extends Component {

    constructor(props) {
        super(props)

        this.handleColumnChange = this.handleColumnChange.bind(this)
        this.handleIdentifierTypeChange = this.handleIdentifierTypeChange.bind(this)
    }

    handleColumnChange(e) {
        return this.props.onColumnChange && this.props.onColumnChange(e)
    }

    handleIdentifierTypeChange(e) {
        return this.props.onIdentifierTypeChange && this.props.onIdentifierTypeChange(e)
    }

    render() {
        return (
            <div style={styles.container}>
                <Divider />
                <TextField
                    style={styles.textField}
                    select
                    label="Selecteer identifier kolom"
                    value={this.props.column}
                    onChange={this.handleColumnChange}
                    InputLabelProps={{shrink: true}}
                    margin="normal"
                >
                    {this.props.columns.map(h => (
                        <MenuItem key={h} value={h}>
                            {h}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    disabled={!this.props.column}
                    style={styles.textField}
                    select
                    label="Selecteer identifier type"
                    value={this.props.type}
                    onChange={this.handleIdentifierTypeChange}
                    InputLabelProps={{shrink: true}}
                    margin="normal"
                >
                    <MenuItem key="sku" value="sku">
                        Sku
                    </MenuItem>
                    <MenuItem key="ean" value="ean">
                        Ean
                    </MenuItem>
                </TextField>
            </div>
        )
    }
}

IdentifierFieldSet.contextTypes = {}
IdentifierFieldSet.propTypes = {
    column: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    labels: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    onColumnChange: PropTypes.func.isRequired,
    onIdentifierTypeChange: PropTypes.func.isRequired,
}
IdentifierFieldSet.defaultProps = {}

export default IdentifierFieldSet
