import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'


function PersonalisationItemsReadyToggle(props) {
    return (
        <FormControlLabel
            style={{ ...props.style }}
            control={
                <Switch
                    checked={props.value}
                    onChange={props.onChange}
                    color="secondary"
                    name="items-ready"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            }
            label="Items ready?"
        />

    )
}

PersonalisationItemsReadyToggle.propTypes = {
    style: PropTypes.object,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
PersonalisationItemsReadyToggle.defaultProps = {
    style: {},
    value: false,
}

export default PersonalisationItemsReadyToggle
