import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import SearchProductAutoComplete from '../../../shared/SearchProductAutoComplete'

class AddPickDialog extends React.Component {
    constructor(...args) {
        super(...args)

        this.state = {
            variant: null,
            quantity: 1,
        }

        this._resetState = this._resetState.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleAddButton = this.handleAddButton.bind(this)
        this.filterSuggestions = this.filterSuggestions.bind(this)
        this.appendDefaultFilters = this.appendDefaultFilters.bind(this)
        this.handleVariantSelected = this.handleVariantSelected.bind(this)
        this.handleQuantityChange = this.handleQuantityChange.bind(this)
    }

    _resetState() {
        this.setState({
            variant: null,
            quantity: 1,
        })
    }

    handleClose() {
        this._resetState()
        return this.props.onDialogClose && this.props.onDialogClose()
    }

    handleAddButton() {
        const { variant, quantity } = this.state
        this._resetState()
        return this.props.onNewPick && this.props.onNewPick(variant, quantity)
    }

    filterSuggestions(mapped) {
        return this.props.filterSuggestions && this.props.filterSuggestions(mapped)
    }

    // Only show warehouse variants
    appendDefaultFilters(filters) {
        return {
            ...filters,
            source: 'warehouse',
        }
    }

    handleVariantSelected(variant) {
        this.setState({variant})
    }

    handleQuantityChange(event) {
        this.setState({quantity: +event.target.value})
    }

    render() {
        const {open} = this.props
        const {variant} = this.state

        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={this.handleClose}
                aria-labelledby="add-pick-form-dialog-title"
            >
                <DialogTitle id="add-pick-form-dialog-title">Add Pick</DialogTitle>
                <DialogContent>
                    {!variant ?
                        <SearchProductAutoComplete
                            onSuggestionSelected={this.handleVariantSelected}
                            clearOnSubmit={true}
                            suggestionFilter={this.filterSuggestions}
                            appendDefaultFilters={this.appendDefaultFilters}
                        /> :
                        <List component="nav">
                            <ListItem>
                                <ListItemText primary="Sku" secondary={variant.supplier_sku} />
                                <ListItemText primary="Name" secondary={variant.name} />
                                <ListItemText primary="Color" secondary={variant.color} />
                                <ListItemText primary="Size" secondary={variant.size} />
                            </ListItem>
                        </List>
                    }
                    <TextField
                        id="qty_to_pick"
                        label="Quantity"
                        value={this.state.quantity}
                        onChange={this.handleQuantityChange}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        min="1"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={this.handleAddButton}
                        color="primary"
                        disabled={!this.state.variant || this.state.quantity < 1}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

AddPickDialog.contextTypes = {}
AddPickDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    filterSuggestions: PropTypes.func.isRequired,
    onNewPick: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}
AddPickDialog.defaultProps = {
    open: false,
}

export default AddPickDialog
