import React from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@material-ui/core'

import _ from 'lodash'

import Colors from '../../styles/colors'

const style = {
    card: {
        marginBottom: 15,
        width: '78%',
    },
    selectedHeaderRow: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: 64,
        transition: 'all 0.25s ease',
    },
    unselectedHeaderRow: {
        height: 64,
    },
    selectedItemsText: {
        color: '#FFFFFF',
        paddingLeft: '36px',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    iconButton: {
        float: 'right',
        color: '#FFFFFF',
    },
}

const initialState = {
    selected: false,
    selectedCount: 0,
    selectedRows: [],
}

class LineItems extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: false,
            selectedCount: 0,
            selectedRows: [],
        }

        autoBind(this)
    }

    getActionButtons(status) {
        return this.props.renderActionButtons(status, this.state.selectedRows, this.resetInitialState)
    }

    getSelectedActions(canBeSelected, displaySelectAll) {
        const itemsText = this.state.selectedCount > 1 ? 'items' : 'item'
        const text = `${this.state.selectedCount} ${itemsText} selected`
        const buttons = this.getActionButtons(this.props.status)
        const selectableItemsCount = this.props.line_items.filter(li => !li.status).length
        return (
            <TableRow
                displayBorder={false}
                style={{ backgroundColor: Colors.primaryColor40, ...style.selectedHeaderRow }}
            >
                {canBeSelected && displaySelectAll && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={
                                this.state.selectedCount > 0 && this.state.selectedCount < selectableItemsCount
                            }
                            checked={this.state.selectedCount === selectableItemsCount}
                            onChange={this.handleSelectAll}
                            inputProps={{ 'aria-label': 'Select all items' }}
                        />
                    </TableCell>
                )}
                <th colSpan={this.props.headerCols.length - 1} style={style.selectedItemsText}>{text}</th>
                <th style={style.selectedIconActions}>
                    {buttons}
                </th>
            </TableRow>
        )
    }

    resetInitialState() {
        this.setState(initialState)
    }

    getUnselectedActions(canBeSelected, displaySelectAll) {
        const selectableItemsCount = this.props.line_items.filter(li => !li.status).length
        return (
            <TableRow style={style.unselectedHeaderRow}>
                {canBeSelected && !displaySelectAll && <TableCell padding="checkbox" />}
                {canBeSelected && displaySelectAll && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={
                                this.state.selectedCount > 0 && this.state.selectedCount < selectableItemsCount
                            }
                            checked={this.state.selectedCount === selectableItemsCount}
                            onChange={this.handleSelectAll}
                            inputProps={{ 'aria-label': 'Select all items' }}
                        />
                    </TableCell>
                )}
                {
                    this.props.headerCols.map(c => (
                        <TableCell
                            key={c.tooltip}
                            tooltip={c.tooltip}
                        >
                            {c.content}
                        </TableCell>
                    ))
                }
            </TableRow>
        )
    }

    handleSelectAll() {
        const selectableItems = this.props.line_items.filter(li => !li.status)
        if (this.state.selectedCount === selectableItems.length) {
            this.setState({
                selectedRows: [],
                selected: false,
                selectedCount: 0,
            })
        } else {
            const indexes = this.props.line_items.reduce((acc, li, index) => {
                if (!li.status) {
                    acc.push(index)
                }
                return acc
            }, [])
            this.setState({
                selectedRows: indexes,
                selected: indexes.length > 0,
                selectedCount: indexes.length,
            })
        }
    }

    handleRowSelection(event, id) {
        const selected = this.state.selectedRows
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }
        this.setState({
            selectedRows: newSelected,
            selected: newSelected.length > 0,
            selectedCount: newSelected.length,
        })
    }

    render() {
        if (this.props.line_items.length === 0) {
            return null
        }
        const selectable = this.props.selectable
        const headerRow = selectable && this.state.selected
            ? this.getSelectedActions(selectable, this.props.displaySelectAll)
            : this.getUnselectedActions(selectable, this.props.displaySelectAll)
        const footer = this.props.footer || null
        const card_style = { ...style.card, ..._.get(this.props, 'style.card', {}) }
        const content = (
            <Table
                style={{ maxHeight: window.innerHeight - (this.props.margin) }}
            >
                <TableHead>
                    {headerRow}
                </TableHead>
                <TableBody>
                    {
                        this.props.line_items.map((line_item, index) => {
                            const selected = this.state.selectedRows.indexOf(index) !== -1
                            const rowStyle = {}
                            let canBeSelected = selectable && line_item.selectable
                            if (line_item.status === 'rejected') {
                                rowStyle.backgroundColor = Colors.red200
                                canBeSelected = false
                            } else if (line_item.status === 'accepted') {
                                rowStyle.backgroundColor = Colors.green200
                                canBeSelected = this.props.status === 'accepted' ||
                                    this.props.status === 'partially_accepted'
                            } else if (line_item.status === 'credited') {
                                rowStyle.backgroundColor = Colors.yellow700
                                canBeSelected = false
                            }
                            return (
                                <TableRow
                                    key={`${line_item.supplier_sku}-${index}`}
                                    style={rowStyle}
                                    onClick={canBeSelected ? event => this.handleRowSelection(event, index) : null}
                                    role={selectable ? 'checkbox' : 'tr'}
                                    selectable={canBeSelected}
                                    selected={selected}
                                >
                                    {selectable && (
                                        <TableCell padding="checkbox">
                                            {canBeSelected && <Checkbox checked={selected} />}
                                        </TableCell>
                                    )}
                                    {
                                        this.props.headerCols.map(c => (
                                            <TableCell
                                                key={c.property}
                                            >
                                                {_.get(line_item, c.property, '')}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                {footer}
            </Table>
        )
        return !this.props.showPaper ? <div style={card_style}>{content}</div> : (
            <Card style={card_style}>
                <CardHeader
                    title={this.props.title}
                    titleTypographyProps={{
                        variant: 'h6',
                        component: 'h6',
                    }}
                />
                {content}
            </Card>
        )
    }
}

LineItems.propTypes = {
    type: PropTypes.oneOf(['rtv', 'rma']),
    noWrapper: PropTypes.bool,
    title: PropTypes.string,
    status: PropTypes.string,
    selectable: PropTypes.bool,
    displaySelectAll: PropTypes.bool,
    headerCols: PropTypes.array,
    footer: PropTypes.node,
    margin: PropTypes.number,
    showPaper: PropTypes.bool,
    line_items: PropTypes.array,
    onDoneClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    renderActionButtons: PropTypes.func.isRequired,
}

LineItems.defaultProps = {
    title: 'Line items',
    showPaper: true,
    selectable: false,
    margin: 300,
}

export default LineItems
