import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import moment from 'moment'

import PurchaseOrderFilters from './PurchaseOrderFilters'
import PurchaseOrderTable from './PurchaseOrderTable'
import PurchaseOrderCreateDialog from './PurchaseOrderCreateDialog'
import PurchaseOrderPostponeViaCsvDialog from './PurchaseOrderPostponeViaCsvDialog'

import { Zoom, Tooltip, Fab } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    searchPurchaseOrders,
    createPurchaseOrderDraft,
} from './../../../../commands/sockets/purchase_orders'
import { searchSuppliers } from './../../../../commands/sockets/suppliers'
import { syncSocketCall } from '../../../../socket'

const STORAGE_KEY = 'purchase-filters'

const styles = {
    container: {
        width: '100%',
    },
    addButton: {
        position: 'fixed',
        zIndex: 5,
        left: 20,
        bottom: 20,
    },
}

class PurchaseOrderList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            shouldFiltersRender: true,
            filters: {
                fields: 'order_id,created_at,supplier,status,pricing',
                value: filters.value || '',
                supplier_id: this.getDefaultSupplierFilter(filters.supplier_id),
                status: filters.status || 'open',
                from_date: '',
                till_date: '',
                limit: filters.limit || 20,
                page: filters.page || 1,
                sorting: { created_at: -1 },
            },
        }

        autoBind(this)
    }

    getDefaultSupplierFilter(supplier_id) {
        if (supplier_id && supplier_id !== 'undefined' && supplier_id !== 'null') {
            return `${supplier_id}`
        }
        return 'all'
    }

    componentDidMount() {
        this.searchPurchaseOrders()
        this.props.searchSuppliers({})
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    searchPurchaseOrders() {
        const filters = this.getFilterState()
        this.storeFilters(filters)
        if (filters.supplier_id === 'all') {
            delete filters.supplier_id
        }
        if (filters.status === 'all') {
            delete filters.status
        }

        if (filters.status === 'open') {
            filters.open = true
            delete filters.status
        }
        if (filters.from_date) {
            filters.from_date = moment(filters.from_date).toISOString()
        }
        if (filters.till_date) {
            filters.till_date = moment(filters.till_date).toISOString()
        }
        if (this.props.roles?.includes('external-supply-chain')) {
            filters.excludingStatuses = 'draft'
        }

        this.props.searchPurchaseOrders(filters)
    }

    getFilterState() {
        return { ...this.state.filters }
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchPurchaseOrders, 500)
    }

    setFilterStateWithoutRender(filters, timeout) {
        const newState = reactUpdate(this.state, {
            filters,
            shouldFiltersRender: { $set: true },
        })
        this.setState(newState, timeout ? this.setSearchTimeout : this.searchPurchaseOrders)
    }

    handleSearchChange(value) {
        this.setFilterStateWithoutRender({
            value: { $set: value },
            page: { $set: 1 },
        }, true)
    }

    handleSupplierChange(supplier_id) {
        this.setFilterStateWithoutRender({
            supplier_id: { $set: supplier_id },
            page: { $set: 1 },
        }, false)
    }

    handleStatusChange(status) {
        this.setFilterStateWithoutRender({
            status: { $set: status },
            page: { $set: 1 },
        }, false)
    }

    handleFromDateChange(from_date) {
        this.setFilterStateWithoutRender({
            from_date: { $set: from_date },
            page: { $set: 1 },
        }, false)
    }

    handleTillDateChange(till_date) {
        this.setFilterStateWithoutRender({
            till_date: { $set: till_date },
            page: { $set: 1 },
        }, false)
    }

    handleLimitChange(limit) {
        this.setFilterStateWithoutRender({
            limit: { $set: limit },
        }, false)
    }

    handlePageDecrement() {
        this.setFilterStateWithoutRender({
            page: { $apply: v => v - 1 },
        }, false)
    }

    handlePageIncrement() {
        this.setFilterStateWithoutRender({
            page: { $apply: v => v + 1 },
        }, false)
    }

    handleTableRowClick(index) {
        const order = this.props.purchase_orders[index]
        const id = order._id
        this.context.router.push(`/purchases/purchase-orders/${id}`)
    }

    handleAddClick() {
        this.setState({
            createDialogOpen: true,
        })
    }

    handlePostponeViaCsvClick() {
        this.setState({
            postponeDialogOpen: true,
        })
    }

    getAddButton() {
        return (
            <Zoom in={true} timeout={200} unmountOnExit>
                <Tooltip style={styles.addButton} title="CREATE PURCHASE ORDER">
                    <Fab
                        color="primary"
                        onClick={this.handleAddClick}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Zoom>
        )
    }

    handleCreateDialogClose() {
        this.setState({ createDialogOpen: false })
    }

    handleCreateDialogSubmit(data) {
        this.props.createPurchaseOrderDraft(data)
        this.handleCreateDialogClose()
    }

    handlePostponeDialogClose() {
        this.setState({ postponeDialogOpen: false })
    }

    async handlePostponeDialogSubmit(data) {
        this.setState({ isFetchingCsvResult: true})
        const csvResult = await syncSocketCall('purchase-orders/postpone-via-csv', data, { timeout: 30000 })
        this.setState({ postponeViaCsvResult: csvResult, isFetchingCsvResult: false })
    }

    render() {
        return (
            <div style={styles.container}>
                <PurchaseOrderFilters
                    shouldRender={this.state.shouldFiltersRender}
                    filters={this.getFilterState()}
                    suppliers={this.props.suppliers}
                    onSearchChange={this.handleSearchChange}
                    onSupplierChange={this.handleSupplierChange}
                    onStatusChange={this.handleStatusChange}
                    onFromDateChange={this.handleFromDateChange}
                    onTillDateChange={this.handleTillDateChange}
                    onPostponeViaCsvClick={this.handlePostponeViaCsvClick}
                />
                <PurchaseOrderTable
                    purchase_orders={this.props.purchase_orders}
                    page={this.state.filters.page - 1}
                    limit={this.state.filters.limit}
                    onTableRowClick={this.handleTableRowClick}
                    onLimitChange={this.handleLimitChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                {this.props.permissions?.includes('purchase') && this.getAddButton()}
                <PurchaseOrderCreateDialog
                    suppliers={this.props.suppliers}
                    onClose={this.handleCreateDialogClose}
                    onSubmit={this.handleCreateDialogSubmit}
                    open={this.state.createDialogOpen}
                />
                <PurchaseOrderPostponeViaCsvDialog
                    onClose={this.handlePostponeDialogClose}
                    onSubmit={this.handlePostponeDialogSubmit}
                    open={this.state.postponeDialogOpen}
                    csvResult={this.state.postponeViaCsvResult}
                    isFetchingCsvResult={this.state.isFetchingCsvResult}
                />
            </div>
        )
    }
}

PurchaseOrderList.propTypes = {
    role: PropTypes.string,
    supplier_id: PropTypes.string,
    filters: PropTypes.object,
    suppliers: PropTypes.array,
    purchase_orders: PropTypes.array,
    searchSuppliers: PropTypes.func.isRequired,
    searchPurchaseOrders: PropTypes.func.isRequired,
    createPurchaseOrderDraft: PropTypes.func.isRequired,
}

PurchaseOrderList.contextTypes = {
    router: PropTypes.object,
}

export default connect(({ purchase_orders, suppliers, filters }) => {
    return {
        purchase_orders,
        suppliers,
        filters: filters.purchase_orders || {},
    }
}, dispatch => {
    const actions = { searchSuppliers, searchPurchaseOrders, createPurchaseOrderDraft }
    return bindActionCreators(actions, dispatch)
})(PurchaseOrderList)
