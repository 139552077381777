import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import {
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableRow,
    TableCell,
    TextField,
    Checkbox,
    IconButton,
    Zoom,
    Tooltip,
    Fab,
} from '@material-ui/core'

import SearchProductAutoComplete from '../../../shared/SearchProductAutoComplete'

import WarningIcon from '@material-ui/icons/Warning'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import Colors from '../../../../styles/colors'

const style = {
    table: { overflowY: 'scroll', tableLayout: 'auto' },
    paper: {
        marginBottom: 15,
        width: '100%',
        position: 'relative',
    },
    selectedHeaderRow: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: 64,
        transition: 'all 0.25s ease',
    },
    selectedItemsText: {
        color: '#FFFFFF',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    bigColumn: {
        width: '25%',
    },
    mediumColumn: {
        width: '12%',
    },
    smallColumn: {
        width: '5%',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
    addButton: {
        position: 'absolute',
        zIndex: 5,
        left: 20,
        bottom: -65,
    },
    uploadButton: {
        position: 'absolute',
        zIndex: 5,
        left: 80,
        bottom: -65,
    },
    buttonCell: {
        whiteSpace: 'pre',
    },
}

class DraftLineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addingRow: false,
            addingMultiple: false,
            editingRow: false,
            editingItem: false,
            selected: false,
            selectedCount: 0,
            selectedRows: [],
        }

        autoBind(this)
    }

    handleAddClick() {
        this.setState({
            addingRow: true,
        })
    }

    handleMultipleAddClick() {
        this.setState({
            addingMultiple: true,
        })
    }

    handleStopAddClick() {
        this.setState({
            addingRow: false,
        })
    }

    handleSuggestionSelected(variant) {
        const item = {
            name: variant.name,
            supplier_sku: variant.supplier_sku,
            ean: variant.ean || '',
            color: variant.color || '',
            size: variant.size || '',
            lead_times: variant.lead_times,
            pricing: variant.pricing,
            type: variant.fulfillment_source,
            quantity: 1,
            reason: '',
            mage_id: '',
            personalised: false,
            supplier: variant.supplier,
        }
        this.setState({
            addingRow: false,
            editingItem: item,
            editingRow: this.props.line_items.length,
        }, () => {
            this.props.onAddLineItem(item)
        })
    }

    handleChange(property, type) {
        return e => {
            const value = type === 'boolean' ? e.target.checked : e.target.value
            this.setState({
                editingItem: {
                    ...this.state.editingItem,
                    [property]: type === 'number' ? +value : value,
                },
            })
        }
    }

    handleEditClick(index) {
        return () => {
            this.setState({
                editingItem: { ...this.props.line_items[index] },
                editingRow: index,
            })
        }
    }

    handleDeleteClick(index) {
        return () => {
            this.props.onDeleteLineItem(index)
        }
    }

    handleSaveItemClick() {
        const item = this.state.editingItem
        const editingRow = this.state.editingRow
        this.setState({
            editingItem: null,
            editingRow: null,
        }, () => {
            this.props.onUpdateLineItem(item, editingRow)
        })
    }

    renderAddButton() {
        return !this.state.addingRow && !this.state.addingMultiple && !this.state.editingItem && (
            <Zoom in={true} timeout={200} unmountOnExit>
                <Tooltip style={style.addButton} title="ADD ITEM">
                    <Fab
                        color="primary"
                        onClick={this.handleAddClick}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Zoom>
        )
    }

    renderMultipleAddButton() {
        return !this.state.addingRow && !this.state.addingMultiple && !this.state.editingItem && (
            <Zoom in={true} timeout={200} unmountOnExit>
                <Tooltip style={style.uploadButton} title="ADD MULTIPLE ITEMS">
                    <Fab
                        color="primary"
                        disabled={true}
                        onClick={this.handleMultipleAddClick}
                    >
                        <PlaylistAddIcon />
                    </Fab>
                </Tooltip>
            </Zoom>
        )
    }

    renderStopAddButton() {
        return this.state.addingRow && (
            <Zoom in={true} timeout={200} unmountOnExit>
                <Tooltip style={style.addButton} title="STOP SEARCHING">
                    <Fab
                        color="primary"
                        onClick={this.handleStopAddClick}
                    >
                        <CloseIcon />
                    </Fab>
                </Tooltip>
            </Zoom>
        )
    }

    renderTableHeaderRow() {
        const salesColumn = this.props.bundled ? null : (
            <TableCell><span>Sales order</span></TableCell>
        )
        return (
            <TableRow style={style.selectedHeaderRow}>
                <TableCell size="small"><span>Quantity</span></TableCell>
                <TableCell size="medium"><span>Name</span></TableCell>
                <TableCell size="medium"><span>SKU</span></TableCell>
                <TableCell size="small"><span>EAN</span></TableCell>
                <TableCell size="small"><span>Specs</span></TableCell>
                <TableCell size="small"><span>Personalised</span></TableCell>
                <TableCell size="small"><span>Purchase amount</span></TableCell>
                {salesColumn}
                <TableCell />
            </TableRow>
        )
    }

    renderEditingRow(line_item) {
        return (
            <TableRow
                key="editing-row"
            >
                <TableCell size="small">
                    <TextField
                        type="number"
                        value={line_item.quantity}
                        onChange={this.handleChange('quantity', 'number')}
                    />
                </TableCell>
                <TableCell>
                    {line_item.name}
                </TableCell>
                <TableCell>
                    {line_item.supplier_sku}
                </TableCell>
                <TableCell>
                    {line_item.ean}
                </TableCell>
                <TableCell>
                    {line_item.size}<br />
                    {line_item.color}
                </TableCell>
                <TableCell>
                    <Checkbox
                        checked={line_item.personalised}
                        onChange={this.handleChange('personalised', 'boolean')}
                    />
                </TableCell>
                <TableCell>
                    {line_item.pricing && line_item.pricing.row_purchase_price
                        ? Number(line_item.pricing.row_purchase_price).toFixed(2)
                        : null
                    }
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        type="text"
                        value={line_item.mage_id}
                        onChange={this.handleChange('mage_id', 'string')}
                    />
                </TableCell>
                <TableCell size="medium">
                    <IconButton onClick={this.handleSaveItemClick}>
                        <DoneIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const line_items = this.props.line_items
        const contents = (
            <Table
                style={style.table}
            >
                <TableHead>
                    {this.renderTableHeaderRow()}
                </TableHead>
                <TableBody>
                    {
                        line_items.map((line_item, index) => {
                            if (this.state.editingRow === index) {
                                return this.renderEditingRow(this.state.editingItem, index)
                            }
                            const salesColumn = this.props.bundled ? null : (
                                <TableCell size="medium">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    <a style={{ cursor: 'pointer' }} onClick={() => {
                                        this.props.onNavigateToSalesOrderClick(line_item.mage_id)
                                    }}
                                    >
                                        {line_item.mage_id}
                                    </a>
                                </TableCell>
                            )
                            const actionsColumn = !this.state.editingItem && !this.state.addingRow && (
                                <TableCell size="medium" style={style.buttonCell}>
                                    <IconButton onClick={this.handleEditClick(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={this.handleDeleteClick(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            )
                            return (
                                <TableRow
                                    key={`${line_item.supplier_sku}.${index}`}
                                >
                                    <TableCell size="small">
                                        {line_item.quantity}
                                    </TableCell>
                                    <TableCell size="medium">{line_item.name}</TableCell>
                                    <TableCell size="medium">
                                        {line_item.supplier_sku}
                                    </TableCell>
                                    <TableCell size="medium">{line_item.ean}</TableCell>
                                    <TableCell size="small">{line_item.size}<br />{line_item.color}</TableCell>
                                    <TableCell size="small">{line_item.personalised ? 'Yes' : 'No'}</TableCell>
                                    <TableCell size="small">
                                        {line_item.pricing && line_item.pricing.row_purchase_price
                                            ? Number(line_item.pricing.row_purchase_price).toFixed(2)
                                            : null
                                        }
                                    </TableCell>
                                    {salesColumn}
                                    {actionsColumn}
                                </TableRow>
                            )
                        })
                    }
                    {this.state.addingRow && (
                        <TableRow>
                            <TableCell colSpan={8}>
                                <SearchProductAutoComplete
                                    supplierFilter={this.props.supplier.mage_id}
                                    onSuggestionSelected={this.handleSuggestionSelected}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {this.props.pricing && (
                    <TableFooter>
                        <TableRow>
                            <TableCell>{this.props.line_items.reduce((acc, i) => acc + i.quantity, 0)}</TableCell>
                            <TableCell align="right" colSpan={5}>Total purchase amount</TableCell>
                            <TableCell>
                                {this.props.pricing.total_purchase_price}
                                {this.props.pricing.missing_purchase_price && (
                                    <WarningIcon style={{ ...style.icon, color: Colors.amber700 }} />
                                )}
                            </TableCell>
                            <TableCell colSpan={2} />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        )
        const containerStyle = { ...style.paper, ...this.props.style }
        return (
            <div style={containerStyle}>
                {contents}
                {this.renderAddButton()}
                {this.renderMultipleAddButton()}
                {this.renderStopAddButton()}
            </div>
        )
    }
}

DraftLineItems.propTypes = {
    supplier: PropTypes.object,
    selection: PropTypes.object,
    style: PropTypes.object,
    bundled: PropTypes.bool,
    line_items: PropTypes.array,
    pricing: PropTypes.object,
    outbound_items: PropTypes.array,
    onRowSelection: PropTypes.func,
    onNavigateToSalesOrderClick: PropTypes.func,
    onAddLineItem: PropTypes.func.isRequired,
    onUpdateLineItem: PropTypes.func.isRequired,
    onDeleteLineItem: PropTypes.func.isRequired,
}

DraftLineItems.defaultProps = {
    id: null,
    actions: null,
    multiSelectable: true,
    bundled: false,
    wrapper: true,
    selectable: false,
    selection: {
        selectedRows: [],
        selected: false,
        selectedCount: 0,
    },
    style: {},
    line_items: [],
    outbound_items: [],
}

export default DraftLineItems
