import store from '../../store'

export default function initWallSockets(socket) {
    socket.on('compartments', compartments => {
        store.dispatch({
            type: 'REFRESH_COMPARTMENTS',
            data: compartments,
        })
    })

    socket.on('wall', wall => {
        store.dispatch({
            type: 'REFRESH_COMPARTMENTS',
            data: wall,
        })
    })
}
