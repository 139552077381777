import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Switch from '@material-ui/core/Switch'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getAvailablePrinters } from './../../../../../commands/sockets/printers'
import { printLabel } from '../../../../../commands/sockets/labels'
import { printPackingSlip } from '../../../../../commands/sockets/packing_slips'
import { getShipmentByTrackingCode } from './../../../../../commands/sockets/shipments'

import { PrinterPageSizeType } from '../../../../types/logistics/outbound'

import SelectPrinter from '../SelectPrinter'

import ClearStockLocationDialog from './ClearStockLocationsDialog'

const initialState = {
    packingSlipPrinter: null,
    labelPrinter: null,
    barcode: '',
    labelOnly: false,
    combinedPrint: false,
    clearLocationStockDialogOpen: false,
}


class PrintSingleDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }
        this.timeout = 0

        autoBind(this)
    }

    componentDidMount() {
        this.props.getAvailablePrinters([
            PrinterPageSizeType.A4,
            PrinterPageSizeType.LABEL,
        ])
    }

    searchShipmentByBarcode() {
        if (this.state.barcode) {
            this.props.getShipmentByTrackingCode(this.state.barcode)
        }
    }

    setSearchShipmentTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchShipmentByBarcode, 500)
    }

    handlePrinterChange(property) {
        return value => {
            this.setState({
                [property]: value,
            })
        }
    }

    handleTextFieldChange(property) {
        return e => {
            this.setState({
                [property]: e.target.value.trim(),
            }, this.setSearchShipmentTimeout)
        }
    }

    handleCombinedPrintSwitchChange(e) {
        this.setState({ combinedPrint: e.target.checked })
    }

    handleLabelOnlySwitchChange(e) {
        this.setState({ labelOnly: e.target.checked })
    }

    openClearStockLocationDialog() {
        this.setState({ clearLocationStockDialogOpen: true })
    }

    handleCloseClearStockLocationDialog() {
        this.setState({ clearLocationStockDialogOpen: false })
        this.props.onClose()
    }

    handleSubmit() {
        this.setState({
            barcode: '',
        }, () => {
            const packingSlipPrinter = this.props.printers.find(p => p.id === this.state.packingSlipPrinter)
            if (!this.state.combinedPrint) {
                if (!this.state.labelOnly) {
                    this.props.printPackingSlip(
                        this.props.shipment._id,
                        packingSlipPrinter,
                        {
                            with_label: false,
                            fulfillment_source: this.props.shipment.source,
                            store_slug: this.props.shipment.channel.slug,
                        }
                    )
                }
                const labelPrinter = this.props.printers.find(p => p.id === this.state.labelPrinter)
                this.props.printLabel(this.props.shipment._id, labelPrinter)
            } else {
                this.props.printPackingSlip(
                    this.props.shipment._id,
                    packingSlipPrinter,
                    {
                        with_label: true,
                        fulfillment_source: this.props.shipment.source,
                        store_slug: this.props.shipment.channel.slug,
                    }
                )
            }

            const hasSourcePersonalisation = this.props.shipment.source === 'personalisation'
            if (hasSourcePersonalisation) {
                this.openClearStockLocationDialog()
            } else {
                this.props.onClose()
            }
        })
    }

    isMatchingShipmentWithStateBarcode() {
        return this.props.shipment
            && this.props.shipment._id
            && this.props.shipment.shipping_label.tracking_code === this.state.barcode
    }

    render() {
        const isMatchingShipment = this.isMatchingShipmentWithStateBarcode()
        const canSubmit = !this.state.packingSlipPrinter
            || !this.state.labelPrinter
            || !this.state.barcode
            || !isMatchingShipment

        if (this.state.clearLocationStockDialogOpen) {
            return (
                <ClearStockLocationDialog
                    open={true}
                    onClose={this.handleCloseClearStockLocationDialog}
                />
            )
        }

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>Print single shipping label and/or packing slip</DialogTitle>
                <DialogContent>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label="Enter shipping label barcode"
                            value={this.state.barcode}
                            color={isMatchingShipment ? 'secondary' : 'primary'}
                            onChange={this.handleTextFieldChange('barcode')}
                            InputProps={isMatchingShipment ? {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CheckCircleIcon color="secondary" />
                                    </InputAdornment>
                                ),
                            } : {}}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <SelectPrinter
                            label="Select packing slip printer"
                            value={this.state.packingSlipPrinter}
                            printers={this.props.printers.filter(printer =>
                                printer.page_size_type === PrinterPageSizeType.A4)}
                            onChange={this.handlePrinterChange('packingSlipPrinter')}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <SelectPrinter
                            label="Select shipping label printer"
                            value={this.state.labelPrinter}
                            printers={this.props.printers.filter(printer =>
                                printer.page_size_type === PrinterPageSizeType.LABEL)}
                            onChange={this.handlePrinterChange('labelPrinter')}
                            fullWidth
                        />
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                value={this.state.combinedPrint}
                                onChange={this.handleCombinedPrintSwitchChange}
                            />
                        }
                        label={'Print label on packing slip'}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                value={this.state.labelOnly}
                                onChange={this.handleLabelOnlySwitchChange}
                            />
                        }
                        label={'Print label only'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={canSubmit}
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PrintSingleDialog.propTypes = {
    printers: PropTypes.array,
    shipment: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getShipmentByTrackingCode: PropTypes.func,
    getAvailablePrinters: PropTypes.func,
    printPackingSlip: PropTypes.func,
    printLabel: PropTypes.func,
}

PrintSingleDialog.defaultProps = {
    printers: [],
    locations: [],
    open: false,
}

export default connect(({ printers, shipment }) => {
    return { printers, shipment }
}, dispatch => {
    return bindActionCreators({
        printLabel,
        printPackingSlip,
        getAvailablePrinters,
        getShipmentByTrackingCode,
    }, dispatch)
})(PrintSingleDialog)
