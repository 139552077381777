import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField, MenuItem} from '@material-ui/core'

import SwitchFilter from './../../shared/SwitchFilter'
import ChannelFilter from './../../shared/ChannelFilter'

const style = {
    filtersContainer: {
        height: 60,
        width: '98%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        margin: 'auto',
        marginTop: 12,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
}

const statuses = ['unprocessed', 'unfulfilled', 'partially fulfilled', 'fulfilled', 'completed', 'unfulfillable']

class OrderFilters extends Component {

    constructor(props) {
        super(props)

        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this)
        this.handleChannelChange = this.handleChannelChange.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.handleOrderChange = this.handleOrderChange.bind(this)
        this.handleItemChange = this.handleItemChange.bind(this)
        this.handlePendingCancellationsFilterChange = this.handlePendingCancellationsFilterChange.bind(this)
        this.handlePersonalisedFilterChange = this.handlePersonalisedFilterChange.bind(this)
    }

    handleSearchFieldChange(e) {
        this.props.onSearchFieldChange(e.target.value)
    }

    handleChannelChange(e) {
        this.props.onChannelChange(e.target.value)
    }

    handleOrderChange(e) {
        this.props.onOrderChange(e.target.value)
    }

    handleItemChange(e) {
        this.props.onItemChange(e.target.value)
    }

    handleStatusChange(e) {
        this.props.onStatusChange(e.target.value)
    }

    handlePendingCancellationsFilterChange(e) {
        this.props.onPendingCancellationsChange(e.value)
    }

    handlePersonalisedFilterChange(e) {
        this.props.onPersonalisedChange(e.value)
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={{width: '12%'}}
                    label="Search by order id."
                    value={this.props.order}
                    onChange={this.handleOrderChange}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    style={{width: '15%'}}
                    label="Search for zipcode, email, name or customer ref."
                    value={this.props.search}
                    onChange={this.handleSearchFieldChange}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    style={{width: '12%'}}
                    label="Search by exact product ean or sku."
                    value={this.props.item}
                    onChange={this.handleItemChange}
                    InputLabelProps={{shrink: true}}
                />
                <ChannelFilter
                    onChange={this.handleChannelChange}
                    channel={this.props.channel}
                />
                <TextField
                    select
                    style={{width: 200, marginRight: 15}}
                    onChange={this.handleStatusChange}
                    value={this.props.status}
                    label="Select status"
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    {
                        statuses.map(status => (
                            <MenuItem style={style.menuItem} key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <SwitchFilter
                    filterLabel="Has personalisations"
                    filterProperty="personalised"
                    onFilterChange={this.handlePersonalisedFilterChange}
                    filterValue={this.props.personalised}
                />
                <SwitchFilter
                    filterLabel="Pending cancellations"
                    filterProperty="pendingCancellations"
                    onFilterChange={this.handlePendingCancellationsFilterChange}
                    filterValue={this.props.pendingCancellations}
                />
            </div>
        )
    }
}

OrderFilters.propTypes = {
    search: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    personalised: PropTypes.bool.isRequired,
    pendingCancellations: PropTypes.bool.isRequired,

    onSearchFieldChange: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onItemChange: PropTypes.func.isRequired,
    onChannelChange: PropTypes.func.isRequired,
    onPendingCancellationsChange: PropTypes.func.isRequired,
    onPersonalisedChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
}

export default OrderFilters
