import socket from '../../socket'
import {getUserEmail} from '../../store'

export function searchLocations(params) {
    socket.emit('locations/search', params, getUserEmail())
    return {type: 'SEARCH_LOCATIONS', params}
}

export function getLocation(params) {
    socket.emit('locations/get-location-stock', params, getUserEmail())
    return {type: 'REFRESH_STOCK_LOCATION', params}
}

export function downloadFreeLocations() {
    socket.emit('locations/free/get', {}, getUserEmail())
    return {type: 'DOWNLOAD_FREE_LOCATIONS'}
}

export function clearLocation(params) {
    socket.emit('location/clear', params, getUserEmail())
    return { type: 'NOTHING' }
}
