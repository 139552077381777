import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardTitle, Dialog, FlatButton} from 'material-ui'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block',
    },
    datePicker: {
        cursor: 'pointer',
    },
    datePickerTextField: {
        fontSize: 14,
    },
}

class CreateShipmentDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            estimatedFor: null,
        }
    }

    handleSubmit() {
        const state = this.state
        this.setState({
            estimatedFor: null,
        }, () => {
            this.props.onSubmit(moment(state.estimatedFor).toISOString(), this.props.reference)
        })
    }

    handleDatePickerChange({target}) {
        this.setState({
            estimatedFor: target.value,
        })
    }

    render() {
        const cannotSubmit = !this.state.estimatedFor
        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={this.props.open}
                actions={[
                    <FlatButton
                        key="create_shipment_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit.bind(this)}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="create_shipment_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle style={style.title} subtitleStyle={style.subtitleStyle} subtitle="Create manual shipment"/>
                <TextField
                    label="Select delivery date"
                    textFieldStyle={style.datePickerTextField}
                    margin="normal"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    style={style.datePicker}
                    value={this.state.estimatedFor}
                    onChange={this.handleDatePickerChange.bind(this)}
                />
            </Dialog>
        )
    }
}

CreateShipmentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    reference: PropTypes.object,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

CreateShipmentDialog.defaultProps = {
    isUpdated: false,
}

export default CreateShipmentDialog
