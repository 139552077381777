import React from 'react'
import PropTypes from 'prop-types'
import { Paper, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core'

const style = { width: '32%' }

function ProductSpecifications({ product }) {
    return (
        <Paper style={style}>
            <List>
                <ListSubheader>Product specifications</ListSubheader>
                <ListItem>
                    <ListItemText
                        primary="Brand"
                        secondary={product.brand}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Category"
                        secondary={product.category}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Description"
                        secondary={product.description}
                    />
                </ListItem>
            </List>
        </Paper>
    )
}

ProductSpecifications.propTypes = {
    product: PropTypes.object,
}

ProductSpecifications.defaultProps = {
    product: {
        name: '',
        brand: '',
        supplier: { name: 'Unknown' },
    },
}

export default ProductSpecifications
