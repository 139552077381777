import socket from './../socket'
import listeners from '../listeners/sockets/packing_stations.js'

listeners(socket)

export function packing_station(state = -1, action) {
    switch (action.type) {
        case 'REFRESH_PACKING_STATION':
            return action.data

        default:
            return state
    }
}
