import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { ImpressionStatus } from '../../../types/production/impressions'

const style = {
    width: 200,
}

const statusOptions = Object.values(ImpressionStatus).map(status => {
    return {
        value: status,
        text: status.replace(/_/g, ' ').replace(/([a-z])/, '$1'.toUpperCase()),
    }
})

function PersonalisationImpressionStatusSelect(props) {
    const statuses = props.statuses instanceof Array && props.statuses.length
        ? statusOptions.filter(d => props.statuses.includes(d.value))
        : statusOptions
    return (
        <TextField
            select
            style={{ ...style, ...props.style }}
            onChange={props.onChange}
            value={props.value}
            label="Select impression status"
            SelectProps={{ MenuProps: { style: { zIndex: 1600, maxHeight: 800 }, disableAutoFocusItem: true } }}
            InputLabelProps={{ shrink: true }}
        >
            {!props.allowAll ? null : (
                <MenuItem
                    value="all"
                >
                    All
                </MenuItem>
            )}
            {statuses.map(({ text, value }) => (
                <MenuItem
                    key={text}
                    value={value}
                >
                    {text}
                </MenuItem>
            ))}
        </TextField>
    )
}

PersonalisationImpressionStatusSelect.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string,
    allowAll: PropTypes.bool,
    statuses: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}
PersonalisationImpressionStatusSelect.defaultProps = {
    style: {},
    statuses: [],
    allowAll: false,
    value: '',
}

export default PersonalisationImpressionStatusSelect
