import React from 'react'
import PropTypes from 'prop-types'
import {Avatar, FontIcon, ListItem, Divider} from 'material-ui'
import moment from 'moment'

const styles = {
    primaryTextListItemStyle: {
        fontSize: 15,
        fontFamily: 'Roboto',
    },
}

function PurchaseReceipt(props, context) {
    const purchase_receipt = props.purchase_receipt
    return (
        <ListItem
            initiallyOpen={true}
            innerDivStyle={{marginLeft: 0}}
            leftAvatar={
                <Avatar backgroundColor={context.colors.green700}>
                    <FontIcon color="white" className="material-icons">done</FontIcon>
                </Avatar>
            }
            primaryText={
                <div style={styles.primaryTextListItemStyle}>
                    Purchase order number: &nbsp;
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                        onClick={() => {
                            context.router.push(`/purchases/purchase-orders/${purchase_receipt.purchase_id}`)
                        }}
                    >
                        {purchase_receipt.purchase_id}
                    </a>
                </div>
            }
            secondaryText={
                <div style={styles.primaryTextListItemStyle}>
                    Supplier: {purchase_receipt.supplier.name}
                </div>
            }
            nestedItems={
                [
                    <Divider key="divider.0"/>,
                    <table
                        key="table.1"
                        style={{marginLeft: 20, borderCollapse: 'separate', borderSpacing: 5}}
                        width="97%"
                    >
                        <thead>
                            <tr>
                                <th width="35%">Name</th>
                                <th width="15%">SKU</th>
                                <th width="15%">EAN</th>
                                <th width="10%">Quantity</th>
                                <th width="10%">Receipt</th>
                                <th width="15%">Receipt date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchase_receipt.line_items.map((li, index) => {
                                    return (
                                        <tr key={`${li.sku}-${index}`}>
                                            <td>{li.name}</td>
                                            <td>{li.sku}</td>
                                            <td>{li.ean}</td>
                                            <td>{li.quantity}</td>
                                            <td>{li.receipt_increment_id}</td>
                                            <td>{moment(li.receipt_created_at).format('DD-MM-YY HH:mm')}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>,
                ]
            }
        />
    )
}

PurchaseReceipt.contextTypes = {
    colors: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}
PurchaseReceipt.propTypes = {
    purchase_receipt: PropTypes.object,
}
PurchaseReceipt.defaultProps = {}

export default PurchaseReceipt
