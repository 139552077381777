import store from '../../store'

export default function initPackingItemsSockets(socket) {
    socket.on('packing-items/to-pack', counts => {
        store.dispatch({type: 'REFRESH_UNPACKED_COUNTS', counts})
    })

    socket.on('packing-items/search-result', packing_items => {
        store.dispatch({type: 'REFRESH_PACKING_ITEMS', packing_items})
    })

    socket.on('packing-item/line-items/packed/ok', packing_item => {
        store.dispatch({type: 'REFRESH_PACKING_ITEM', packing_item})
    })

    socket.on('packing-items/print', ({url}) => {
        if (url) {
            window.open(url)
        }
    })
}
