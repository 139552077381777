export function message(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'SET_MESSAGE':
            return action.message

        case 'UNSET_MESSAGE':
            return {}

        default:
            return state
    }
}
