let data = {}
const STORAGE_KEY = 'pagination'
const storage = localStorage.getItem(STORAGE_KEY)
if (storage && storage !== 'undefined' && storage !== 'null') {
    try {
        data = JSON.parse(storage)
    } catch (e) {
        data = {}
    }
}

export function pagination(state = data, action) {
    switch (action.type) {
        case 'REFRESH_PAGINATION_DEBIT_MEMOS':
            state.debit_memos = action.pagination
            break

        case 'REFRESH_PAGINATION_RMAS':
            state.rmas = action.pagination
            break

        case 'REFRESH_PAGINATION_PURCHASE_REQUISITIONS':
            state.purchase_requisitions = action.pagination
            break

        default:
            localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
            return state
    }

    return state
}
