import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardTitle, Dialog, FlatButton, TextField} from 'material-ui'
import _ from 'lodash'

import LineItemSelect from '../shared/forms/LineItemSelect'
import QuantityField from '../shared/forms/QuantityField'

import Colors from './../../styles/colors'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block',
    },
    textField: {
        width: '90%',
        maxHeight: 400,
        overflowY: 'scroll',
        fontSize: 14,
    },
    textFieldFloatingLabel: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
    textFieldUnderlineFocus: {
        borderColor: Colors.primaryColor100,
    },
}


class CancelDialog extends Component {


    constructor(props) {
        super(props)
        this.state = {
            lineItem: null,
            quantity: 1,
            reason: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleLineItemChange(lineItem) {
        this.setState({
            lineItem,
        })
    }

    handleQuantityChange(quantity) {
        this.setState({
            quantity,
        })
    }

    handleReasonChange(e) {
        this.setState({
            reason: e.target.value,
        })
    }

    handleSubmit() {
        const shipment = this.props.shipment || CancelDialog.defaultProps.shipment
        const state = this.state
        this.setState({
            lineItem: null,
            quantity: 1,
            reason: '',
        }, () => {
            this.props.onCancelSubmit(shipment, state)
        })
    }

    render() {
        const shipment = this.props.shipment || CancelDialog.defaultProps.shipment
        const cannotSubmit = this.state.lineItem === null
            || this.state.reason === ''
            || this.props.isUpdated

        let quantityField = null
        if (_.get(this.state, 'lineItem.product.name', 'All') !== 'All') {
            quantityField = (
                <QuantityField
                    onFilterChange={this.handleQuantityChange.bind(this)}
                    quantity={_.get(this.state, 'lineItem.quantity', '1')}
                    filterValue={_.get(this.state, 'quantity', '1')}
                    label="Quantity"
                />
            )
        }
        return (
            <Dialog
                onRequestClose={this.props.onCloseCancelDialog}
                open={this.props.open}
                actions={[
                    <FlatButton
                        key="cancel_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="cancel_dialog.submit"
                        onClick={this.props.onCloseCancelDialog}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle style={style.title} subtitleStyle={style.subtitleStyle} subtitle="Cancel"/>
                <LineItemSelect
                    onFilterChange={this.handleLineItemChange.bind(this)}
                    filterValue={this.state.lineItem}
                    lineItems={shipment.line_items}
                />
                {quantityField}
                <TextField
                    style={style.textField}
                    floatingLabelText="Reason for cancellation:"
                    hintText="Enter reason for cancellation.."
                    floatingLabelStyle={style.textFieldFloatingLabel}
                    underlineFocusStyle={style.textFieldUnderlineFocus}
                    value={this.state.reason}
                    onChange={this.handleReasonChange.bind(this)}
                    multiLine={true}
                />
            </Dialog>
        )
    }
}

CancelDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    shipment: PropTypes.object,
    isUpdated: PropTypes.bool,
    onCloseCancelDialog: PropTypes.func.isRequired,
    onCancelSubmit: PropTypes.func.isRequired,
}

CancelDialog.defaultProps = {
    shipment: {
        line_items: [],
    },
    isUpdated: false,
}

export default CancelDialog
