import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getFeatures } from '../../../commands/sockets/settings'
import SettingDepartment from './SettingDepartment'

import FormGroup from '@material-ui/core/FormGroup'
import { Alert, AlertTitle} from '@material-ui/lab'

const style = {
    container: {
        width: '100%',
        position: 'relative',
        textAlign: 'center-left',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'capitalize',
        flexDirection: 'column',
    },
    setting_display: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        minWidth: '800px',
    },
    alert: {
        marginTop: '50px',
    },
}


function SettingList(props) {
    useState({
        feature_toggles: [],
        roles: [],
        feature_authorization: {},
    })

    useEffect(() => {
        getFeatures()
    }, [])

    /**
    * Gets the features access levels for the users roles
    * Checks whether a feature has different permissions for the user's multiple roles
    * and chooses the highest access from the roles
    * @param {*} roles all roles of the user
    * @param {*} features all features
    * @return the available features per role
    */

    function getFeaturesByRole(roles, features) {
        if (!(roles instanceof Array) ||
        roles.length === 0 ||
        typeof features === 'undefined' ||
        Object.keys(features).length === 0) {
            return null
        }
        const features_access = {}
        roles.forEach(role => {
            if (role in features) {
                Object.keys(features[role]).forEach(key => {
                    if (!features_access.hasOwnProperty(key) ||
                    features_access[key] !== 'readwrite') {
                        features_access[key] = features[role][key]
                    }
                })
            }
        })
        return features_access
    }

    function getDepartments() {
        const departments = props.feature_toggles
        // Filter features that have an undefined department
            .filter(s => s.department)
            .map(s => s.department)

        return [ ...new Set(departments) ]
    }

    function renderAllSettings() {
        if (!props.feature_toggles || !props.feature_toggles.length) {
            return 'No settings retrieved'
        }
        const departments = getDepartments()

        return departments.map(department => {
            if (!props.roles.includes(department)) {
                return null
            }

            const features = getFeaturesByRole(props.roles, props.feature_authorization)
            // Checks if you have authorization for any of the retrieved features of the department
            if (!props.feature_toggles.some(element => Object.keys(features).includes(element.name))) {
                return null
            }
            return (
                <SettingDepartment
                    key={department}
                    featuresByRole={features}
                    department={department}
                    feature_toggles={props.feature_toggles}
                />
            )
        })
    }

    function determineRenderedValue(settings) {
        if (settings === 'No settings retrieved') {
            return ''
        } else if (Array.isArray(settings) && settings.every(v => !v)) {
            return (
                <Alert
                    style={style.alert}
                    severity="warning"
                >
                    <AlertTitle>Warning</AlertTitle>
                You are not authorized to view any settings
                </Alert>
            )
        }
        return settings
    }
    return (
        <div style={style.container}>
            <h1>Settings</h1>
            <FormGroup style={style.formGroup} column="true">
                {
                    determineRenderedValue(renderAllSettings())
                }
            </FormGroup>
        </div>
    )
}

SettingList.defaultProps = {
    feature_toggles: [],
    roles: [],
    feature_authorization: {},
}

SettingList.propTypes = {
    feature_toggles: PropTypes.array,
    roles: PropTypes.array,
    feature_authorization: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        feature_toggles: state.features,
        roles: state.session.roles,
        feature_authorization: state.session.metadata.features,
    }
}

export default connect(mapStateToProps)(SettingList)
