import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    TextField,
} from 'material-ui'
import {
    IconButton, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TableSortLabel,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'

import _ from 'lodash'

import Pagination from './../shared/Pagination'

const style = {
    container: {
        width: '120%',
    },
    tableWrapper: {
        marginTop: 64,
    },
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    stockField: {
        width: '100%',
        fontSize: 13,
    },
    tableCell: {
        whiteSpace: 'nowrap',
        maxWidth: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingTop: 2,
        paddingBottom: 2,
    },
    activeSortLabel: {
        fontWeight: 'bold',
    },
    tableCellInput: {
        whiteSpace: 'nowrap',
        paddingRight: 14,
        paddingTop: 2,
        paddingBottom: 2,
    },
}

class HighPerformanceTableRow extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.updated
    }
    render() {
        return (
            <TableRow onClick={this.props.onClick} style={this.props.style}>{this.props.children}</TableRow>
        )
    }
}

HighPerformanceTableRow.propTypes = {
    updated: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
}

class ProductTable extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    handleTableRowClick(event, row) {
        if (event.target.tagName.toLowerCase() === 'td') {
            const doc_id = this.props.product_variants[row]._id
            this.context.router.push(`/inventory/${doc_id}`)
        }
    }

    getBooleanReadableValue(val) {
        return val ? 'Yes' : 'No'
    }

    renderProductRows(variants) {
        if (variants.length > 0) {
            return variants.map((variant, index) => {
                const updated = this.props.updatedRow === 'all' || this.props.updatedRow === index
                return (
                    <HighPerformanceTableRow
                        key={`${variant.supplier_sku}`}
                        style={{ cursor: 'pointer' }}
                        updated={updated}
                        onClick={e => { this.handleTableRowClick(e, index) }}
                    >
                        {
                            this.props.headerCols.map(column => {
                                if (column.editable) {
                                    return (
                                        <TableCell
                                            key={column.property}
                                            size={column.size}
                                            style={style.tableCellInput}
                                        >
                                            <TextField
                                                id={`${variant.supplier_sku}${index}${column.property}`}
                                                style={style.stockField}
                                                type={column.type}
                                                value={_.get(variant, column.property, '')}
                                                onChange={({ target }) => {
                                                    this.props.onRowEdit(index, target.value, column.property)
                                                }}
                                            />
                                        </TableCell>
                                    )
                                }
                                const value = _.get(variant, column.property, '')
                                return (
                                    <TableCell key={column.property} size={column.size} style={style.tableCell}>
                                        {typeof value === 'boolean' ? this.getBooleanReadableValue(value) : value}
                                        {column.icon ? column.icon(variant, variant.stocks) : null}
                                    </TableCell>
                                )
                            })
                        }
                        <TableCell style={{ padding: 0 }} width={'5%'}>
                            <IconButton onClick={() => { this.props.onDeleteVariant(variant, index) }}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </HighPerformanceTableRow>
                )
            })
        }
        return (
            <TableRow style={style.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length + 1}>No products found.</TableCell>
            </TableRow>
        )
    }

    renderNavigation(variants) {
        if (variants.length > 0) {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={this.props.headerCols.length + 1}>
                            <Pagination
                                page={this.props.page}
                                limit={this.props.limit}
                                onPreviousPageClick={this.props.onPageDecrement}
                                onNextPageClick={this.props.onPageIncrement}
                                items={variants}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    createSortHandler(property) {
        return () => {
            this.props.onSortChange(property)
        }

    }

    render() {
        const variants = this.props.product_variants

        const productRows = this.renderProductRows(variants)
        const navigation = this.renderNavigation(variants)
        return (
            <Table
                style={style.container}
                stickyHeader
            >
                <TableHead >
                    <TableRow>
                        {
                            this.props.headerCols.map(column => {
                                let activeSort = false
                                switch (this.props.sorting[column.property]) {
                                    case 1: activeSort = 'asc'; break
                                    case -1: activeSort = 'desc'; break
                                    default: activeSort = false
                                }
                                const active = typeof activeSort === 'string'
                                const sortLabelProps = {}
                                if (active) {
                                    sortLabelProps.direction = activeSort
                                    sortLabelProps.style = style.activeSortLabel
                                }
                                return (
                                    <TableCell
                                        key={column.property}
                                        sortDirection={activeSort}
                                        size={column.size}
                                    >
                                        {column.sortable
                                            ? (
                                                <TableSortLabel
                                                    active={active}
                                                    onClick={this.createSortHandler(column.property)}
                                                    {...sortLabelProps}
                                                >
                                                    {column.content}
                                                </TableSortLabel>
                                            )
                                            : column.content
                                        }
                                    </TableCell>
                                )
                            })
                        }
                        <TableCell
                            width={'5%'}
                            style={{ padding: 0 }}
                        >
                            <IconButton onClick={this.props.onChangeColumnsClick}>
                                <ViewColumnIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

ProductTable.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    sorting: PropTypes.object,
    stocks: PropTypes.array,
    product_variants: PropTypes.array,
    headerCols: PropTypes.array,
    shouldRender: PropTypes.bool,
    updatedRow: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onDeleteVariant: PropTypes.func.isRequired,
    onRowEdit: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onChangeColumnsClick: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

ProductTable.contextTypes = {
    router: PropTypes.object,
}

export default ProductTable
