import socket from './../socket'
import listeners from '../listeners/sockets/outbound_checklists.js'

listeners(socket)

export function outbound_checklist(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_OUTBOUND_CHECKLIST':
            if (!action.outbound_checklist || !action.outbound_checklist.triage_items) {
                return state
            }
            action.outbound_checklist.inbound_items = action.outbound_checklist.triage_items
                .filter(({ shipping }) => shipping.type === 'inbound')
            action.outbound_checklist.outbound_items = action.outbound_checklist.triage_items
                .filter(({ shipping }) => shipping.type === 'outbound')

            const inbound_items = action.outbound_checklist.inbound_items
            action.outbound_checklist.triage_items = action.outbound_checklist.outbound_items
                .filter(({ _id, origin_id, status }) => {
                    const found = action.outbound_checklist.outbound_items.find(item =>
                        !!item.origin_id &&
                        (item.origin_id === _id || item.origin_id === origin_id) &&
                        (item.status === 'approved' || item.status === 'rejected'))
                    return !(found && status === 'postponed')
                }).sort((a, b) => {
                    const skuA = a.supplier_sku.toUpperCase()
                    const skuB = b.supplier_sku.toUpperCase()
                    if (skuA > skuB) { return 1 }
                    if (skuB > skuA) { return -1 }
                    return 0
                }).reduce((acc, curr) => {
                    curr.date = curr.postponed_to || curr.estimated_for
                    if (curr.status === 'approved' && curr.closed_at) {
                        const inbound_item = inbound_items.find(item => {
                            return curr.supplier_sku === item.supplier_sku
                        })
                        if (inbound_item) {
                            curr.inbound_item = inbound_item
                            inbound_items.splice(inbound_items.indexOf(inbound_item), 1)
                        }
                    }
                    const found = acc.find(i => i.status === curr.status)
                    if (found) {
                        found.triage_items.push(curr)
                    } else {
                        acc.push({
                            triage_items: [curr],
                            status: curr.status,
                        })
                    }
                    return acc
                }, [])
            return action.outbound_checklist

        case 'TRIAGE_OUTBOUND_CHECKLIST_ITEMS':
            socket.emit('outbound-checklist/triage', {
                data: action.data,
                status: action.status,
                author: action.author,
            })
            return state

        default:
            return state
    }
}

export function outbound_checklists(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SEARCH_OUTBOUND_CHECKLISTS':
            socket.emit('outbound-checklists/search', action.filters)
            return state

        case 'REFRESH_OUTBOUND_CHECKLISTS':
            return action.outbound_checklists.map(ocl => {
                ocl.order_id = ocl._id
                return ocl
            }).sort((a, b) => {
                if (a._id < b._id) { return 1 }
                if (a._id > b._id) { return -1 }
                return 0
            })

        default:
            return state
    }
}
