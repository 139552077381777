import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'

import WarningIcon from '@material-ui/icons/Warning'

const style = {
    table: {
        width: '100%',
    },
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    tableFooter: {
        height: 60,
    },
    contentWithIcon: {
        width: 'auto',
        float: 'left',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
}

class PurchaseOrderTable extends Component {

    constructor(props) {
        super(props)

        this.handleTableRowClick = this.handleTableRowClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.purchase_orders !== this.props.purchase_orders
            || nextProps.page !== this.props.page
            || nextProps.limit !== this.props.limit
    }

    handleTableRowClick(index) {
        return this.props.onTableRowClick && this.props.onTableRowClick(index)
    }

    handleChangePage() {
        // TablePagination - onChangePage
        // Method sometimes fires when no click on a page increment/decrement has been done
        // Paging didn't work when using the 'onChangePage' event
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    getTotalPricing(pricing) {
        if (!pricing) {
            return null
        }
        if (pricing.missing_purchase_price) {
            return (
                <span>
                    <div style={style.contentWithIcon}>
                        {pricing.total_purchase_price}&nbsp;
                    </div>
                    <WarningIcon style={{ ...style.icon, color: this.context.colors.amber700 }} />
                </span>
            )
        } else {
            return pricing.total_purchase_price
        }
    }

    renderTableBodyRows() {
        const purchase_orders = this.props.purchase_orders
        if (purchase_orders.length > 0) {
            return purchase_orders.map((purchase_order, index) => {
                return (
                    <TableRow
                        hover={true}
                        key={purchase_order.order_id}
                        onClick={() => this.handleTableRowClick(index)}
                    >
                        <TableCell>{purchase_order.order_id}</TableCell>
                        <TableCell>{moment(purchase_order.created_at).format('DD-MM-YY')}</TableCell>
                        <TableCell>{purchase_order.status}</TableCell>
                        <TableCell>
                            {purchase_order.supplier.free_of_payment_treshold}
                        </TableCell>
                        <TableCell>
                            {this.getTotalPricing(purchase_order.pricing)}
                        </TableCell>
                        <TableCell>{purchase_order.supplier.name}</TableCell>
                    </TableRow>
                )
            })
        }
        return (
            <TableRow style={style.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>No results found.</TableCell>
            </TableRow>
        )
    }

    renderTableFooter() {
        const { page, limit, purchase_orders, headerCols } = this.props
        const from = Math.max(0, (limit * page))
        const to = Math.min(from + limit, from + purchase_orders.length)
        return (
            <TableFooter style={style.tableFooter}>
                <TableRow>
                    <TableCell colSpan={headerCols.length}>
                        <TablePagination
                            component="div"
                            count={purchase_orders.length}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={() => `${from + 1} - ${to}`}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                                disabled: page === 0,
                                onClick: this.handlePageDecrementClick,
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                disabled: purchase_orders.length < limit,
                                onClick: this.handlePageIncrementClick,
                            }}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        )
    }

    renderHeaderCells() {
        const cols = this.props.headerCols
        return cols.map(column => (
            <TableCell key={column.content} tooltip={column.tooltip}>
                {column.content}
            </TableCell>
        ))
    }

    render() {
        return (
            <Table style={style.table}>
                <TableHead>
                    <TableRow>
                        {this.renderHeaderCells()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderTableBodyRows()}
                </TableBody>
                {this.renderTableFooter()}
            </Table>
        )
    }
}

PurchaseOrderTable.contextTypes = {
    colors: PropTypes.object.isRequired,
}
PurchaseOrderTable.propTypes = {
    purchase_orders: PropTypes.array,
    headerCols: PropTypes.array,
    onTableRowClick: PropTypes.func,
    onLimitChange: PropTypes.func,
    onPageIncrement: PropTypes.func,
    onPageDecrement: PropTypes.func,
    page: PropTypes.number,
    limit: PropTypes.number,
}

PurchaseOrderTable.defaultProps = {
    headerCols: [{
        content: 'Order Number',
        tooltip: 'The order number',
    }, {
        content: 'Created at',
        tooltip: 'Creation date',
    }, {
        content: 'Status',
        tooltip: 'The current state of the order',
    }, {
        content: 'Free of payment treshold',
        tooltip: 'Treshold for paying no shipping costs',
    }, {
        content: 'Total purchase amount',
        tooltip: 'The sum of the purchase amounts of all items',
    }, {
        content: 'Supplier',
        tooltip: 'The supplier name',
    }],
}

export default PurchaseOrderTable
