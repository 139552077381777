import socket from './../socket'
import listeners from '../listeners/sockets/delivery_orders'

listeners(socket)

export function delivery_orders(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_DELIVERY_ORDERS':
            return action.data.map(order => {
                return {
                    ...order,
                    total_quantity: order.line_items.reduce((acc, item) => acc + item.quantity, 0),
                    total_quantity_fulfilled: order.line_items.reduce((acc, item) => acc + item.fulfilled_quantity, 0),
                    total_quantity_checked_out: order.line_items.reduce((acc, item) => {
                        return acc + item.checked_out_quantity
                    }, 0),
                }
            })

        default:
            return state
    }
}

export function delivery_order(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_DELIVERY_ORDER':
            return { ...action.data, locations: [] }

        case 'REFRESH_DELIVERY_ORDER_LOCATIONS':
            return !state.increment_id ? state : {
                ...state,
                locations: action.data,
            }

        default:
            return state
    }
}
