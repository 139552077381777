import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Paper, List, ListItem, ListItemText, Checkbox, ListSubheader, Divider} from '@material-ui/core'

const WEEKDAY_TO_HUMAN = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}

class OrderDays extends React.Component {

    renderListItems(days) {
        return (
            <List>
                <ListSubheader>Order days</ListSubheader>
                <Divider />
                <ListItem>
                    {this.renderCheckbox(1, days)}
                    <ListItemText
                        primary={WEEKDAY_TO_HUMAN[1]}
                    />
                </ListItem>
                <ListItem>
                    {this.renderCheckbox(2, days)}
                    <ListItemText
                        primary={WEEKDAY_TO_HUMAN[2]}
                    />
                </ListItem>
                <ListItem>
                    {this.renderCheckbox(3, days)}
                    <ListItemText
                        primary={WEEKDAY_TO_HUMAN[3]}
                    />
                </ListItem>
                <ListItem>
                    {this.renderCheckbox(4, days)}
                    <ListItemText
                        primary={WEEKDAY_TO_HUMAN[4]}
                    />
                </ListItem>
                <ListItem>
                    {this.renderCheckbox(5, days)}
                    <ListItemText
                        primary={WEEKDAY_TO_HUMAN[5]}
                    />
                </ListItem>
            </List>
        )
    }

    renderCheckbox(day_number, days) {
        const callback = _.get(this.props, 'features.canEditSupplier', false)
            ? event => { this.props.onOrderDayChange(day_number, event.target.checked) }
            : () => {}
        const disabled = !_.get(this.props, 'features.canEditSupplier', false)
        const active = days.indexOf(day_number) > -1
        return (
            <Checkbox
                color="primary"
                value={`${day_number}`}
                checked={active}
                disabled={disabled}
                onChange={callback}
            />
        )
    }

    render() {
        const supplier = this.props.supplier
        const days = _.get(supplier, 'order_days', [])
        const listItems = this.renderListItems(days)
        return (
            <Paper>
                {listItems}
            </Paper>
        )
    }
}

OrderDays.propTypes = {
    supplier: PropTypes.object,
    onOrderDayChange: PropTypes.func,
    features: PropTypes.object,
}

export default OrderDays
