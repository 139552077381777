import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import * as Sentry from '@sentry/react'

import {
    Button,
    Fab,
    FormControlLabel,
    Switch,
    Zoom,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
} from '@material-ui/core'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import Tooltip from '@material-ui/core/Tooltip'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { downloadBase64AsPdf } from '../../../../utils/download'

import { printLabel } from '../../../../../commands/sockets/labels'
import { printPackingSlip, getPackingSlip } from '../../../../../commands/sockets/packing_slips'
import { checkoutShipment } from '../../../../../commands/sockets/shipments'

import { updateLineItemPackedQuantity, startProcessingPackingItem } from '../../../../../actions/packing_items'
import { showNotification } from '../../../../../actions/notifications'
import {
    registerPackingStation,
    unregisterPackingStation,
    doResetPackingStations,
} from '../../../../../commands/sockets/packing_stations'
import { getAvailablePrinters } from '../../../../../commands/sockets/printers'
import { subscribeToWall, unsubscribeFromWall } from '../../../../../commands/sockets/wall'

import Header from './../../../shared/Header'
// import SelectWall from './SelectWall';
import SelectPrinter from '../SelectPrinter'
import WallDialog from './WallDialog'
import PackFromCompartment from './PackFromCompartment'

import { PrinterPageSizeType } from '../../../../types/logistics/outbound'
import { barcodeScanner, BarcodeScanner } from '../../../../utils/barcodescanner'

import { createLogger } from '../../../../utils/logger'

const styles = {
    container: {
        width: '95%',
        paddingTop: 12,
        margin: 'auto',
    },
    header: {
        marginBottom: 6,
    },
    headerNode: {
        float: 'right',
    },
    select: {
        marginRight: 12,
    },
    floatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        right: 20,
        bottom: 20,
    },
    resetStationsButtonStyle: {
        backgroundColor: 'orange',
        position: 'fixed',
        zIndex: 5,
        right: 90,
        bottom: 20,
    },
    resetStationsIconStyle: {
        color: 'white',
    },
}

const sendLog = createLogger('packFromWall')

class PackFromWall extends Component {

    constructor(props) {
        super(props)
        this.state = {
            wall: 'M01',
            label_printer: null,
            packing_slip_printer: null,
            startedActivity: props.packing_station > 0,
            viewWall: false,
            autoPrint: false,
            combinedPrint: false,
            lastPrinted: null,
            openResetPackingStationsDialog: false,
            barcodeScannerId: BarcodeScanner.getRef(),
            promo_packing_items: [], // This keeps track of the promo items that are packed
        }

        autoBind(this)
    }

    /**
     * @param {string} scan
     */
    handleBarcodeScan(scan) {
        const callee_name = 'handleBarcodeScan'

        if (!this.props.compartment || !this.props.packing_item) {
            return console.log(
                'handleBarcodeScan', 'Exiting early',
                this.props.compartment, this.props.packing_item
            )
        }

        console.info(
            'handleBarcodeScan',
            'tracking code:', this.props?.shipment?.shipping_label?.tracking_code,
            'this.isReadyForCheckout():', this.isReadyForCheckout()
        )
        console.info(
            'handleBarcodeScan',
            'skus and eans:', this.props?.packing_item?.line_items
                ?.map(l => `ean:${l.ean},sku:${l.sku},qty:${l.quantity},pqty:${l.packed_quantity}`).join('\n')
            , this.props?.promo_items?.map(
                l => `ean:${l.ean},sku:${l.sku},qty:${l.quantity}`).join('\n')
        )

        sendLog(`[${callee_name}] New scan: ${scan}. ` +
            `Packing items: ${JSON.stringify(this.props?.packing_item?.line_items)} ` +
            `Promo items (props): ${JSON.stringify(this.props?.promo_items)} ` +
            `Promo items (state): ${JSON.stringify(this.state.promo_packing_items)} ` +
            `IsReadyForCheckout: ${this.isReadyForCheckout() ? 'true' : 'false'}`
        )

        if (this.isReadyForCheckout() && scan === this.props?.shipment?.shipping_label?.tracking_code) {
            sendLog(`[${callee_name}] Processing scan as shipping label: ${scan}`)
            console.log('handleBarcodeScan', 'Checking out shipment')
            this.props.checkoutShipment(this.props.shipment)
        } else {
            const item = this.props.packing_item.line_items.find((pli, index) => {
                pli._index = index
                return (pli.sku === scan || pli.ean === scan) && pli.quantity > pli.packed_quantity
            })

            const promo_item = (this.state.promo_packing_items || []).find((promo_item, index) => {
                promo_item._index = index
                return (promo_item.sku === scan || promo_item.ean === scan)
                    && promo_item.quantity > promo_item.packed_quantity
            })

            if (item) {
                sendLog(`[${callee_name}] Processing scan as ean: ${scan}`)

                console.log('handleBarcodeScan', 'Found item', JSON.stringify(item))

                this.props.updateLineItemPackedQuantity(
                    item._index,
                    item.packed_quantity + 1,
                    this.props.compartment
                )
            } else if (promo_item) {
                sendLog(`[${callee_name}] Processing scan as promo item: ${scan}`)

                console.log('handleBarcodeScan', 'Found promo item', promo_item)

                this.handlePromoLineItemPackedQuantityChange(promo_item.packed_quantity+1, promo_item._index)
            }
        }
    }

    componentDidMount() {
        barcodeScanner.addListener(this.state.barcodeScannerId, this.handleBarcodeScan)

        this.props.subscribeToWall()
        this.props.getAvailablePrinters([PrinterPageSizeType.A4, PrinterPageSizeType.LABEL])
    }

    componentWillUnmount() {
        barcodeScanner.removeListener(this.state.barcodeScannerId)

        this.props.unsubscribeFromWall()
    }

    static getDerivedStateFromProps({ packing_item, packing_slip, printers }, prevState) {
        const newState = {}

        if (!prevState.printer && printers.length > 0) {
            newState.printer = printers[0].id
        }

        if (
            prevState.downloadedPackingSlip &&
            packing_slip.shipment_id &&
            prevState.downloadedPackingSlip === packing_slip.shipment_id &&
            !packing_slip.error
        ) {
            newState.downloadedPackingSlip = null
            downloadBase64AsPdf(packing_slip.blob, packing_item.mage_id)
        }

        return Object.keys(newState).length > 0 ? newState : null
    }

    componentDidUpdate() {
        if (this.props.promo_items?.length !== this.state.promo_packing_items?.length) {
            this.processPromoItems()
        }
        if (
            this.props.packing_item &&
            !this.props.packing_item.processing &&
            this.props.compartment &&
            this.props.compartment !== this.state.lastPrinted &&
            this.state.autoPrint
        ) {
            // setState is allowed in componentDidUpdate, if nested in an if-statement:
            // https://reactjs.org/docs/react-component.html#componentdidupdate
            // eslint-disable-next-line react/no-did-update-set-state
            this.processPackingItem()
        }
    }

    /**
     * Check if all packingitems and promo items are fully packed
     * @returns {boolean}
     */
    isReadyForCheckout() {
        const packing_items_packed = Boolean(this?.props?.packing_item?.line_items?.every(item => {
            return item.quantity === item.packed_quantity
        }))

        const optional_promo_items_packed = !this.state.promo_packing_items?.length
            ? true
            : Boolean(this.state.promo_packing_items.every(item => {
                return item.quantity === item.packed_quantity
            }))

        return packing_items_packed && optional_promo_items_packed
    }

    handleWallChange(wall) {
        this.setState({ wall })
    }

    handleLabelPrinterChange(label_printer) {
        this.setState({ label_printer })
    }

    handlePackingSlipPrinterChange(packing_slip_printer) {
        this.setState({ packing_slip_printer })
    }

    handleToggleViewWallClick() {
        this.setState({ viewWall: !this.state.viewWall })
    }

    handleToggleActivityClick() {
        this.setState({
            startedActivity: !this.state.startedActivity,
            autoPrint: false,
            lastPrinted: null,
        }, () => {
            if (this.state.startedActivity) {
                this.props.registerPackingStation()
            } else {
                this.unregisterPackingStation()
            }
        })
    }

    handleStartPackFromWall1to8() {
        this.setState({
            startedActivity: true,
            autoPrint: false,
            lastPrinted: null,
        }, () => {
            this.props.registerPackingStation('1-to-8')
        })
    }

    handleStartPackFromWall9to16() {
        this.setState({
            startedActivity: true,
            autoPrint: false,
            lastPrinted: null,
        }, () => {
            this.props.registerPackingStation('9-to-16')
        })
    }

    handleStopPackFromWallClick() {
        this.setState({
            startedActivity: false,
            autoPrint: false,
            lastPrinted: null,
            promo_packing_items: [],
        }, () => {
            this.props.unregisterPackingStation(this.props.packing_station)
        })
    }

    unregisterPackingStation() {
        this.props.unregisterPackingStation(this.props.packing_station)
    }

    handleDownloadPackingSlipClick(e) {
        this.setState({ downloadedPackingSlip: this.props.packing_item.shipment_id }, () => {
            this.props.getPackingSlip(this.props.packing_item.shipment_id, this.props.packing_item.fulfillment_source)
            return e && e.target && e.target.blur()
        })
    }

    handlePrintPackingSlipClick() {
        const packing_slip_printer = this.props.printers.find(p => p.id === this.state.packing_slip_printer)
        if (!packing_slip_printer) {
            return this.props.showNotification({
                message: 'Pakbon printer is niet geselecteerd.',
            })
        }
        return this.props.printPackingSlip(
            this.props.packing_item.shipment_id,
            packing_slip_printer,
            {
                with_label: this.state.combinedPrint,
                fulfillment_source: this.props.packing_item.fulfillment_source,
                store_slug: this.props.packing_item.channel.slug,
            }
        )
    }

    handlePrintLabelClick() {
        const label_printer = this.props.printers.find(p => p.id === this.state.label_printer)
        if (!label_printer) {
            return this.props.showNotification({
                message: 'Label printer is niet geselecteerd.',
            })
        }
        return this.props.printLabel(this.props.packing_item.shipment_id, label_printer)
    }

    processPromoItems() {
        sendLog('[processPromoItems] Processing promo items')
        // Add 'packed_quantity' to each item for tracking purposes
        const mapped_promo_items = this.props.promo_items?.map(item => ({
            ...item,
            packed_quantity: 0,
        }))
        this.setState({
            promo_packing_items: mapped_promo_items,
        })
    }

    processPackingItem() {
        sendLog('[processPackingItem] Processing packing item')

        this.setState({
            lastPrinted: this.props.compartment,
        }, () => {
            this.props.startProcessingPackingItem()
            const packing_slip_printer = this.props.printers.find(p => p.id === this.state.packing_slip_printer)

            if (!this.state.combinedPrint) {
                this.props.printPackingSlip(
                    this.props.packing_item.shipment_id,
                    packing_slip_printer,
                    {
                        with_label: false,
                        fulfillment_source: this.props.packing_item.fulfillment_source,
                        store_slug: this.props.packing_item.channel.slug,
                    }
                )
                const label_printer = this.props.printers.find(p => p.id === this.state.label_printer)
                this.props.printLabel(this.props.packing_item.shipment_id, label_printer)
            } else {
                this.props.printPackingSlip(
                    this.props.packing_item.shipment_id,
                    packing_slip_printer,
                    {
                        with_label: true,
                        fulfillment_source: this.props.packing_item.fulfillment_source,
                        store_slug: this.props.packing_item.channel.slug,
                    }
                )
            }
        })
    }

    handleStartProcessingPackingItemClick() {
        this.processPromoItems()
        this.processPackingItem()
    }

    handleLineItemPackedQuantityChange(value, index) {
        this.props.updateLineItemPackedQuantity(index, value, this.props.compartment)
    }

    handlePromoLineItemPackedQuantityChange(value, index) {
        const promo_packing_items = [...this.state.promo_packing_items ]
        promo_packing_items[index].packed_quantity = value

        Sentry.captureMessage(`handlePromoLineItemPackedQuantityChange (${this.props?.shipment._id}):`
            + JSON.stringify(promo_packing_items)
        )

        this.setState({ promo_packing_items })
    }

    handleWallDialogClose() {
        this.handleToggleViewWallClick()
    }

    handleAutoPrintSwitchChange(e) {
        this.setState({ autoPrint: e.target.checked })
    }

    handleCombinedPrintSwitchChange(e) {
        this.setState({ combinedPrint: e.target.checked })
    }

    handleOpenResetPackingStationsDialog() {
        this.setState({ openResetPackingStationsDialog: true })
    }

    handleCloseResetPackingStationsDialog() {
        this.setState({ openResetPackingStationsDialog: false })
    }

    handleDoResetPackingStations() {
        this.props.doResetPackingStations()
        this.handleCloseResetPackingStationsDialog()
    }

    render() {
        const translate = this.context.translate
        const station = this.props.packing_station < 0 ? null : `Station ${this.props.packing_station}`

        return (
            <div style={styles.container}>
                <Header
                    title={translate('To pack')}
                    subheader={station}
                    textTransform="inherit"
                    style={styles.header}
                    rightButtons={!this.state.startedActivity ? null : [
                        <Button
                            key="button"
                            style={styles.headerNode}
                            color="primary"
                            variant="contained"
                            onClick={this.handleStopPackFromWallClick}
                        >
                            {translate('Stop packing activity')}
                        </Button>,
                        <FormControlLabel
                            key="auto-printing-switch"
                            style={styles.headerNode}
                            control={
                                <Switch
                                    value={this.state.autoPrint}
                                    onChange={this.handleAutoPrintSwitchChange}
                                />
                            }
                            label="Auto-print"
                        />,
                        <FormControlLabel
                            key="combined-printing-switch"
                            style={styles.headerNode}
                            control={
                                <Switch
                                    value={this.state.combinedPrint}
                                    onChange={this.handleCombinedPrintSwitchChange}
                                />
                            }
                            label={translate('Print label on packing slip')}
                        />,
                        // <SelectWall
                        //     key="select-wall"
                        //     style={{ ...styles.headerNode, ...styles.select }}
                        //     value={this.state.wall}
                        //     onChange={this.handleWallChange}
                        // />,
                        <SelectPrinter
                            key="select-label-printer"
                            style={{ ...styles.headerNode, ...styles.select }}
                            value={this.state.label_printer}
                            printers={this.props.printers.filter(printer => {
                                return printer.page_size_type === PrinterPageSizeType.LABEL
                                    && !printer.name.toLowerCase().includes('gk420d')
                            })}
                            onChange={this.handleLabelPrinterChange}
                            label="Selecteer label printer"
                        />,
                        <SelectPrinter
                            key="select-packing-slip-printer"
                            style={{ ...styles.headerNode, ...styles.select }}
                            value={this.state.packing_slip_printer}
                            printers={this.props.printers.filter(printer => {
                                return printer.page_size_type === PrinterPageSizeType.A4
                            })}
                            onChange={this.handlePackingSlipPrinterChange}
                            label="Selecteer pakbon printer"
                        />,
                    ]}
                />
                {this.state.startedActivity ? null : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleStartPackFromWall1to8}
                            style={{ margin: '10px' }}
                        >
                            {translate('Start packing activity')} 1 - 8
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleStartPackFromWall9to16}
                            style={{ margin: '10px' }}
                        >
                            {translate('Start packing activity')} 9 - 16
                        </Button>
                    </div>
                )}
                {!(!this.props.compartment && this.state.startedActivity) ? null : (
                    <i>
                        Momenteel zijn er geen inpakbare muurvakken. <br />
                        Zodra er een muurvak inpakbaar is en niet wordt afgehandeld door een ander inpakstation,
                        verschijnt deze in beeld.
                    </i>
                )}
                {!this.props.compartment || !this.state.startedActivity ? null : (
                    <PackFromCompartment
                        compartment={this.props.compartment}
                        packing_item={this.props.packing_item}
                        promo_items={this.state.promo_packing_items}
                        shipment={this.props.shipment}
                        ready={this.isReadyForCheckout()}
                        onDownloadPackingSlipClick={this.handleDownloadPackingSlipClick}
                        onStartProcessingClick={this.handleStartProcessingPackingItemClick}
                        onPrintPackingSlipClick={this.handlePrintPackingSlipClick}
                        onPrintLabelClick={this.handlePrintLabelClick}
                        onLineItemPackedQuantityChange={this.handleLineItemPackedQuantityChange}
                        onPromoLineItemPackedQuantityChange={this.handlePromoLineItemPackedQuantityChange}
                    />
                )}
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Fab
                        style={styles.floatingActionButtonStyle}
                        color="primary"
                        onClick={this.handleToggleViewWallClick}
                    >
                        <ViewModuleIcon />
                    </Fab>
                </Zoom>
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip key="reset-packing-stations" title="Inpakstations resetten">
                        <Fab
                            style={styles.resetStationsButtonStyle}
                            size="small"
                            onClick={this.handleOpenResetPackingStationsDialog}
                        >
                            <DeleteSweepIcon style={styles.resetStationsIconStyle} />
                        </Fab>
                    </Tooltip>
                </Zoom>
                <WallDialog
                    open={this.state.viewWall}
                    wall={this.state.wall}
                    compartments={this.props.compartments.filter(c => c.name.indexOf(this.state.wall) !== -1)}
                    onClose={this.handleWallDialogClose}
                />
                <Dialog
                    open={this.state.openResetPackingStationsDialog}
                >
                    <DialogTitle>Inpakstations resetten</DialogTitle>
                    <DialogContent>
                        <b>Let op:</b> Beëindig eerst alle inpakactiviteiten!<br />
                        Weet je zeker dat je alle inpakstations wil resetten?
                        <DialogActions>
                            <Button onClick={this.handleCloseResetPackingStationsDialog}>Annuleren</Button>
                            <Button onClick={this.handleDoResetPackingStations}>Doorgaan</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

PackFromWall.contextTypes = {
    colors: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
}
PackFromWall.propTypes = {
    packing_slip: PropTypes.object,
    packing_station: PropTypes.number,
    printers: PropTypes.array,
    compartments: PropTypes.array,
    compartment: PropTypes.string,
    shipment: PropTypes.object,
    packing_item: PropTypes.object,
    promo_items: PropTypes.array,
    registerPackingStation: PropTypes.func,
    unregisterPackingStation: PropTypes.func,
    getAvailablePrinters: PropTypes.func,
    getPackingSlip: PropTypes.func,
    printPackingSlip: PropTypes.func,
    printLabel: PropTypes.func,
    checkoutShipment: PropTypes.func,
    subscribeToWall: PropTypes.func,
    startProcessingPackingItem: PropTypes.func,
    unsubscribeFromWall: PropTypes.func,
    updateLineItemPackedQuantity: PropTypes.func,
    showNotification: PropTypes.func,
    doResetPackingStations: PropTypes.func,
}
PackFromWall.defaultProps = {
    compartments: [],
}

export default connect(
    ({ printers, packing_station, packing_item, promo_items, packing_slip, shipment, compartment, compartments }) => {
        return {
            printers,
            packing_station,
            packing_item,
            promo_items,
            packing_slip,
            shipment,
            compartment,
            compartments,
        }
    },
    dispatch => {
        return bindActionCreators({
            registerPackingStation,
            unregisterPackingStation,
            getPackingSlip,
            printPackingSlip,
            printLabel,
            getAvailablePrinters,
            subscribeToWall,
            unsubscribeFromWall,
            checkoutShipment,
            startProcessingPackingItem,
            updateLineItemPackedQuantity,
            showNotification,
            doResetPackingStations,
        }, dispatch)
    }
)(PackFromWall)
