import { Component } from 'react'
import PropTypes from 'prop-types'
import { CardTitle, Dialog, FlatButton, SelectField, MenuItem, TextField, CardText } from 'material-ui'

import _ from 'lodash'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '75%',
        display: 'inline-block',
    },
    datePicker: {
        cursor: 'pointer',
    },
    datePickerTextField: {
        fontSize: 14,
    },
}

const initialState = {
    reason: null,
    quantity: 1,
}

class RevokePurchaseRequisitionDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reason: null,
            quantity: props.items.length === 1 ? props.items[0].quantity : 1,
        }
    }

    // TODO: this looks like it never worked (qty update for single requisition)
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.items.length === 1 && nextProps.items[0].quantity !== prevState.quantity) {
            return {
                quantity: nextProps.items[0].quantity,
            }
        }
        return null
    }

    handleSubmit() {
        const state = this.state
        this.setState({ ...initialState }, () => {
            let data = this.props.items.length === 1
                ? [{
                    ...this.props.items[0],
                    quantity: state.quantity,
                }]
                : this.props.items

            this.props.onSubmit(data, state.reason)
        })
    }

    handleTextFieldChange({ target }) {
        this.setState({ quantity: +target.value })
    }

    handleSelectFieldChange(_e, _index, reason) {
        this.setState({ reason })
    }

    getSplitMessage(remaining) {
        if (!this.state.reason) {
            return null
        }
        const message = remaining === 0
            ? 'After submission, the purchase requisition will be marked as revoked'
            : `After submission, the purchase requisition will be updated to a quantity of
${remaining} and the revoked quantity will be saved as a revoked purchase requisition`
        return (
            <span style={{ fontWeight: 'light', fontStyle: 'italic' }}>
                {message}
            </span>
        )
    }

    handleClose() {
        this.setState({ initialState })
        this.props.onClose()
    }

    render() {
        const cannotSubmit = !this.state.reason
        return (
            <Dialog
                contentStyle={{ width: 400, maxWidth: 'none' }}
                onRequestClose={this.handleClose}
                open={this.props.visible}
                actions={[
                    <FlatButton
                        key="purchase_requisitions_submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit.bind(this)}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="purchase_requisitions_close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle
                    style={style.title}
                    subtitleStyle={style.subtitleStyle}
                    subtitle="Revoke a purchase requisition"
                />
                {(
                    this.props.items.length === 1 ? (
                        <TextField
                            floatingLabelText="How many should be revoked?"
                            type="number"
                            value={this.state.quantity}
                            onChange={this.handleTextFieldChange.bind(this)}
                        />
                    ) : (
                        <>
                            <CardText>
                                Amount of requisition lines: {this.props.items.length}
                                <br />
                                Amount of requisitions: {this.props.items.map(
                                    item => item.quantity)
                                    .reduce((a, b) => a + b, 0)
                                }
                            </CardText>
                        </>
                    )
                )}
                <SelectField
                    style={{ width: 350, marginRight: 15, overflow: 'hidden' }}
                    onChange={this.handleSelectFieldChange.bind(this)}
                    value={this.state.reason}
                    floatingLabelText="What is the reason of revoking?"
                >
                    {
                        this.props.reasons.map(reason => {
                            return (
                                <MenuItem
                                    key={reason.value}
                                    value={reason.value}
                                    primaryText={reason.text}
                                />
                            )
                        })
                    }
                </SelectField>
                {this.getSplitMessage(_.get(this.props, 'item.quantity', 1) - this.state.quantity)}
            </Dialog>
        )
    }
}

RevokePurchaseRequisitionDialog.propTypes = {
    visible: PropTypes.bool,
    reasons: PropTypes.array,
    items: PropTypes.array,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

RevokePurchaseRequisitionDialog.defaultProps = {
    item: { quantity: 1 },
    suppliers: [],
    isUpdated: false,
    reasons: [{
        text: 'Is duplicate',
        value: 'is-duplicate',
    }, {
        text: 'Invalid requisition',
        value: 'invalid-requisition',
    }, {
        text: 'Cancelled by customer',
        value: 'customer-cancelled',
    }, {
        text: 'Other',
        value: 'other',
    }],
}

export default RevokePurchaseRequisitionDialog
