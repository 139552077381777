import socket from './../socket'
import listeners from '../listeners/sockets/work_slips'

listeners(socket)

export function work_slip(state = [], action) {
    switch (action.type) {
        case 'GET_WORK_SLIP':
            socket.emit('work-slip/get', {production_order_id: action.production_order_id})
            return state

        case 'DOWNLOAD_WORK_SLIP':
            return action.work_slip

        default:
            return state
    }
}

