import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import { stringifyAddress } from '../../utils/stringify'

import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    updateBillingAddress,
    updateShippingAddress,
    updateCustomerOrderReference,
    updateSalesRepresentative,
    updateEmail,
} from './../../../commands/sockets/sales_orders'

import ListWrapper from './../shared/ListWrapper'

import EditAddressDialog from './EditAddressDialog'
import EditEmailDialog from './EditEmailDialog'
import EditReferenceDialog from './EditReferenceDialog'
import EditSalesRepresentativeDialog from './EditSalesRepresentativeDialog'

const style = {
    container: {
        marginTop: 10,
        marginBottom: 20,
    },
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    innerDivStyle: {
        paddingTop: 8,
        paddingBottom: 12,
    },
}

class Customer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editBillingOpen: false,
            editShippingOpen: false,
            editEmailOpen: false,
            editCustomerRefOpen: false,
            editSalesRepOpen: false,
        }

        autoBind(this)
    }

    /* eslint-disable complexity */
    getListItems(small, customer, editable) {
        let shipping_address = null
        if (!small) {
            if (customer.shipping_address.service_point && this.props.status !== 'unprocessed') {
                const service_point = customer.shipping_address.service_point
                shipping_address = (
                    <ListItem key="shipping_address">
                        <ListItemText
                            primary="Shipping address"
                            secondary={
                                <span
                                    style={{ display: 'block' }}
                                >
                                Service point: {service_point.code}<br />
                                    {stringifyAddress(customer.shipping_address)}
                                </span>
                            }
                        />
                        {editable && (
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => { this.setState({ editShippingOpen: true }) }}>
                                    <EditIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                )
            } else {
                shipping_address = (
                    <ListItem key="customer.shipping_address">
                        <ListItemText
                            primary="Shipping address"
                            secondary={stringifyAddress(customer.shipping_address)}
                        />
                        {editable && (
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => { this.setState({ editShippingOpen: true }) }}>
                                    <EditIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                )
            }
        }

        const billing_email = customer.billing_email || customer.email

        return [
            <ListItem key="customer.name">
                <ListItemText
                    primary="Name"
                    secondary={customer.name}
                />
            </ListItem>,
            customer.billing_address && customer.billing_address.company && (
                <ListItem key="customer.billing_address.company">
                    <ListItemText
                        primary="Company"
                        secondary={customer.billing_address.company}
                    />
                </ListItem>
            ),
            <ListItem key="customer.mage_id">
                <ListItemText
                    primary="Customer ID"
                    secondary={customer.mage_id}
                />
            </ListItem>,
            !small && this.props.salesRepresentative && (
                <ListItem key="sales_representative">
                    <ListItemText
                        primary="Sales representative"
                        secondary={this.props.salesRepresentative}
                    />
                    {editable && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => { this.setState({ editSalesRepOpen: true }) }}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ),
            !small && (
                <ListItem key="customer.reference">
                    <ListItemText
                        primary="Customer Reference"
                        secondary={customer.ref}
                    />
                    {editable && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => { this.setState({ editCustomerRefOpen: true }) }}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ),
            !small && (
                <ListItem key="customer.email">
                    <ListItemText
                        primary="Customer email"
                        secondary={<a href={`mailto:${customer.email}`}>{customer.email}</a>}
                    />
                    {editable && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => { this.setState({ editEmailOpen: true }) }}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ),
            !small && (
                <ListItem key="customer.billing_email">
                    <ListItemText
                        primary="Customer billing email"
                        secondary={<a href={`mailto:${billing_email}`}>{billing_email}</a>}
                    />
                </ListItem>
            ),
            small || !customer.phone_numbers ? null : (
                <ListItem key="customer.phone_numbers">
                    <ListItemText
                        primary="Phone number"
                        secondary={<a href={`tel:${customer.phone_numbers}`}>{customer.phone_numbers}</a>}
                    />
                </ListItem>
            ),
            !small && (
                <ListItem key="customer.billing_address">
                    <ListItemText
                        primary="Billing address"
                        secondary={stringifyAddress(customer.billing_address)}
                    />
                    {editable && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => { this.setState({ editBillingOpen: true }) }}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ),
            !small && (
                shipping_address
            ),
        ]
    }

    handleEditDialogClose() {
        this.setState({
            editCustomerRefOpen: false,
            editShippingOpen: false,
            editBillingOpen: false,
            editEmailOpen: false,
            editSalesRepOpen: false,
        })
    }

    handleEditBillingAddressDialogSubmit(address) {
        this.props.updateBillingAddress(this.props.order_id, address)
        this.handleEditDialogClose()
    }

    handleEditShippingAddressDialogSubmit(address) {
        this.props.updateShippingAddress(this.props.order_id, address)
        this.handleEditDialogClose()
    }

    handleEditCustomerRefDialogSubmit(reference) {
        this.props.updateCustomerOrderReference(this.props.order_id, reference)
        this.handleEditDialogClose()
    }

    handleEditSalesRepresentativeSubmit(sales_representative) {
        this.props.updateSalesRepresentative(this.props.order_id, sales_representative)
        this.handleEditDialogClose()
    }

    handleEditEmailDialogSubmit(email) {
        this.props.updateEmail(this.props.order_id, email)
        this.handleEditDialogClose()
    }

    render() {
        const small = this.props.small
        const editable = this.props.editable
        const customer = this.props.customer
        const editBillingAddressDialog = !this.props.small && (
            <EditAddressDialog
                key="billing_address_dialog"
                open={this.state.editBillingOpen}
                onClose={this.handleEditDialogClose}
                onSubmit={this.handleEditBillingAddressDialogSubmit}
                address={customer.billing_address}
                subtitle="Edit billing address"
                type="billing"
            />
        )

        const editShippingAddressDialog = !this.props.small && (
            <EditAddressDialog
                key="shipping_address_dialog"
                open={this.state.editShippingOpen}
                onClose={this.handleEditDialogClose}
                onSubmit={this.handleEditShippingAddressDialogSubmit}
                address={customer.shipping_address}
                subtitle="Edit shipping address"
                type="shipping"
            />
        )
        const editEmailOpen = !this.props.small && (
            <EditEmailDialog
                key="email_dialog"
                open={this.state.editEmailOpen}
                onClose={this.handleEditDialogClose}
                onSubmit={this.handleEditEmailDialogSubmit}
                status={this.props.status}
                email={customer.email}
            />
        )
        const editCustomerRefDialog = !this.props.small && (
            <EditReferenceDialog
                key="reference_dialog"
                open={this.state.editCustomerRefOpen}
                onClose={this.handleEditDialogClose}
                onSubmit={this.handleEditCustomerRefDialogSubmit}
                status={this.props.status}
                reference={customer.ref}
            />
        )
        const editSalesRepresentativeDialog = !this.props.small && (
            <EditSalesRepresentativeDialog
                key="sales_rep_dialog"
                open={this.state.editSalesRepOpen}
                onClose={this.handleEditDialogClose}
                onSubmit={this.handleEditSalesRepresentativeSubmit}
                sales_representative={this.props.salesRepresentative}
            />
        )
        return (
            <ListWrapper
                title="Customer"
                style={this.props.style || style.container}
                dialogs={[
                    editBillingAddressDialog,
                    editShippingAddressDialog,
                    editCustomerRefDialog,
                    editSalesRepresentativeDialog,
                    editEmailOpen,
                ]}
            >
                {
                    this.getListItems(small, customer, editable)
                }
            </ListWrapper>
        )
    }
}

Customer.propTypes = {
    order_id: PropTypes.string,
    status: PropTypes.string,
    customer: PropTypes.object.isRequired,
    salesRepresentative: PropTypes.string,
    style: PropTypes.object,
    editable: PropTypes.bool,
    small: PropTypes.bool,
    onNavigateToSalesOrder: PropTypes.func,
    updateEmail: PropTypes.func,
    updateShippingAddress: PropTypes.func,
    updateBillingAddress: PropTypes.func,
    updateCustomerOrderReference: PropTypes.func,
    updateSalesRepresentative: PropTypes.func,
}


Customer.defaultProps = {
    order_id: '',
    customer: {
        name: '',
        mage_id: '',
        phone_numbers: '',
        email: '',
        billing_address: {
            street: '',
            house_number: '',
            house_number_extension: '',
            city: '',
            postal_code: '',
            country: '',
        },
        shipping_address: {
            street: '',
            house_number: '',
            house_number_extension: '',
            city: '',
            postal_code: '',
            country: '',
        },
    },
    editable: false,
    small: false,
}

export default connect(() => {
    return {}
}, dispatch => {
    return bindActionCreators({
        updateBillingAddress,
        updateShippingAddress,
        updateCustomerOrderReference,
        updateSalesRepresentative,
        updateEmail,
    }, dispatch)
})(Customer)
