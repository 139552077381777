import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableHeaderColumn,
    TableRowColumn,
    TextField,
    IconButton,
} from 'material-ui'

const initialState = {
    name: '',
    sku: '',
    base_price: '',
    tax_percentage: '',
    quantity: 1,
}

const style = {
    table: {
        tableLayout: 'auto',
        overflowY: 'scroll',
        padding: 0,
    },
    tableBody: {height: 300},
    textFieldStyle: {fontSize: 13, width: '100%'},
    tableColumn: {paddingLeft: 12, paddingRight: 12},
}

class AddCreditMemoTable extends Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    handleChange(property, value) {
        const update = {}
        update[property] = value
        this.setState(update)
    }

    /**
     * Replaces all comma's with dots and checks if the last part of the price
     * is the length of cents. See below examples of conversion:
     * '0.90'     => '0.90'
     * '1,00'     => '1.00'
     * '00.90'    => '00.90'
     * '00,90'    => '00.90'
     * '1000.00'  => '1000.00'
     * '1000,00'  => '1000.00'
     * '1.000,00' => '1000.00'
     * '1.000.00' => '1000.00'
     * '1,000.00' => '1000.00'
     * '1,000,00' => '1000.00'
     * '10.00,00' => '1000.00'
     * '10,00,00' => '1000.00'
     * @param {string} price The valuta entered by the user
     * @returns {string} Correct valuta in US format
     * @private
     */
    getCorrectValuta(price) {
        const clean_price = price.replace(/,/g, '.').replace(/^[^0-9.]+$/g, '')
        const parts = clean_price.split('.')
        const last_part = parts.pop()
        if (last_part && last_part.length === 2) {
            return `${parts.join('') }.${ last_part}`
        }
        return parts.concat(last_part).join('')
    }

    handleSaveClick() {
        const state = this.state
        this.setState(initialState, () => {
            state.sku = state.sku.trim()
            state.base_price = this.getCorrectValuta(state.base_price.trim())
            state.tax_percentage = state.tax_percentage.trim()
            state.quantity = +state.quantity
            this.props.onAddItem(state)
        })
    }

    render() {
        return (
            <Table
                style={{
                    ...style.table,
                    display: this.props.visible ? 'block' : 'none',
                }}
                bodyStyle={style.tableBody}
                selectable={false}
            >
                <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn width="17%">Name*</TableHeaderColumn>
                        <TableHeaderColumn width="12%">SKU*</TableHeaderColumn>
                        <TableHeaderColumn width="9%">Base price*</TableHeaderColumn>
                        <TableHeaderColumn width="9%">TAX (%)*</TableHeaderColumn>
                        <TableHeaderColumn width="8%">Quantity*</TableHeaderColumn>
                        <TableHeaderColumn width="3%"/>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {
                        this.props.line_items.map((line_item, index) => {
                            return (
                                <TableRow key={`${line_item.sku}-${index}`} selectable={false}>
                                    <TableRowColumn>{line_item.name}</TableRowColumn>
                                    <TableRowColumn>{line_item.sku}</TableRowColumn>
                                    <TableRowColumn>{line_item.base_price}</TableRowColumn>
                                    <TableRowColumn>{line_item.tax_percentage}</TableRowColumn>
                                    <TableRowColumn>{line_item.quantity}</TableRowColumn>
                                    <TableRowColumn>
                                        <IconButton
                                            onClick={() => { this.props.onRemoveItem(index) }}
                                            iconClassName="material-icons"
                                        >
                                            delete
                                        </IconButton>
                                    </TableRowColumn>
                                </TableRow>
                            )
                        })
                    }
                    <TableRow selectable={false} style={{height: 72}}>
                        <TableRowColumn width="17%">
                            <TextField
                                value={this.state.name}
                                hintText={'Name'}
                                style={style.textFieldStyle}
                                onChange={e => {this.handleChange('name', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="12%">
                            <TextField
                                value={this.state.sku}
                                hintText={'SKU'}
                                style={style.textFieldStyle}
                                onChange={e => {this.handleChange('sku', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="9%">
                            <TextField
                                value={this.state.base_price}
                                hintText={'0.00'}
                                style={style.textFieldStyle}
                                onChange={e => {this.handleChange('base_price', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="9%">
                            <TextField
                                value={this.state.tax_percentage}
                                hintText={'21%'}
                                style={style.textFieldStyle}
                                onChange={e => {this.handleChange('tax_percentage', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="8%">
                            <TextField
                                value={this.state.quantity}
                                min={1}
                                type="number"
                                style={style.textFieldStyle}
                                onChange={e => {this.handleChange('quantity', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="3%">
                            <IconButton
                                onClick={this.handleSaveClick.bind(this)}
                                iconClassName="material-icons"
                                disabled={!this.state.name
                                || !this.state.sku
                                || !this.state.base_price
                                || !this.state.tax_percentage
                                || !this.state.quantity}
                            >
                                done
                            </IconButton>
                        </TableRowColumn>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

AddCreditMemoTable.propTypes = {
    visible: PropTypes.bool,
    line_items: PropTypes.array,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
}

AddCreditMemoTable.defaultProps = {
    visible: false,
    line_items: [],
}

export default AddCreditMemoTable
