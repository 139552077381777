import socket from './../socket'
import listeners from '../listeners/sockets/notifications.js'

listeners(socket)

export function notification(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'NOTIFICATION_ERROR':
            return action.notification

        case 'NOTIFICATION_OK':
            return action.notification

        case 'SHOW_NOTIFICATION':
            return action.notification

        case 'HIDE_NOTIFICATION':
            return {}

        default:
            return state
    }
}
