import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, SelectField, TextField } from 'material-ui'

import SourceFilter from './../shared/SourceFilter'
import SupplierFilter from './../shared/SupplierFilter'

const style = {
    container: {
        display: 'flex',
        flexFlow: 'row',
        float: 'left',
        paddingLeft: 20,
    },
    textField: {
        marginRight: 20,
        minWidth: 120,
    },
    supplierFilter: {
        marginTop: 15,
        marginRight: 15,
    },
    sourceFilter: {
        marginTop: 15,
        marginRight: 15,
    },
    textFieldHintText: {
        lineHeight: '20px',
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectField: {
        width: 150,
        marginRight: 15,
    },
}

const stockOptions = [{
    text: 'All',
    value: 0,
}, {
    text: 'In stock',
    value: 1,
}, {
    text: 'Out of stock',
    value: -1,
}]

function filterSources({ value }) {
    return value === 'crossdock' || value === 'warehouse'
}

function ProductFilters(props) {
    const stockSelection = props.filters.source !== 'warehouse' ? null : (
        <SelectField
            style={style.selectField}
            onChange={props.onStockViewChange}
            value={props.filters.stock}
            floatingLabelText="Show stock"
        >
            {
                stockOptions.map(option => {
                    return (
                        <MenuItem
                            key={option.text}
                            value={option.value}
                            primaryText={option.text}
                        />
                    )
                })
            }
        </SelectField>
    )

    return (
        <div style={{ ...style.container, minWidth: props.filters.source === 'warehouse' ? 550 : 350 }}>
            <TextField
                style={style.textField}
                hintStyle={style.textFieldHintText}
                value={props.filters.value}
                floatingLabelText="Search by criteria"
                onChange={props.onSearchFieldChange}
            />
            <SupplierFilter
                style={style.supplierFilter}
                allowAll={true}
                value={props.filters.supplier_id}
                onChange={props.onSupplierChange}
            />
            <SourceFilter
                allowAll={true}
                style={style.sourceFilter}
                filterSources={filterSources}
                onChange={props.onSourceChange}
                value={props.filters.source}
            />
            {stockSelection}
        </div>
    )
}

ProductFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onSearchFieldChange: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onSupplierChange: PropTypes.func.isRequired,
    onStockViewChange: PropTypes.func.isRequired,
}

export default ProductFilters
