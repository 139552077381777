import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import money from 'money-math'

import {
    Checkbox,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    ListItemSecondaryAction,
    Zoom,
    Fab,
    MenuItem,
    TextField,
    Divider,
    InputLabel,
    Select,
} from '@material-ui/core'

import EmailIcon from '@material-ui/icons/Email'
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol'
import PhoneIcon from '@material-ui/icons/Phone'
import ClockIcon from '@material-ui/icons/AccessTime'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import { showErrorNotification } from '../../../../actions/notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const style = {
    container: {
        width: '30%',
        position: 'relative',
        minHeight: '487.50px',
    },
    paper: {
        minHeight: '100%',
    },
    singleFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
    firstFab: {
        position: 'absolute',
        bottom: -26,
        right: 90,
    },
    secondFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
}

function getBooleanText(val) {
    return val ? 'Yes' : 'No'
}

class SupplierInformation extends Component {

    constructor(props) {
        super(props)
        this.state = { editing: false, data: {} }

        autoBind(this)
    }

    handlePurchaseEmailChange(e) {
        const data = this.state.data
        this.setState({
            data: {
                ...data,
                purchase_email: e.target.value,
            },
        })
    }

    handleIntakeTimeChange(e) {
        const data = this.state.data
        this.setState({
            data: {
                ...data,
                intake_time: e.target.value,
            },
        })
    }

    handleFreeOfPaymentTresholdChange(e) {
        const data = this.state.data
        this.setState({
            data: {
                ...data,
                free_of_payment_treshold: money.floatToAmount(e.target.value),
            },
        })
    }

    handleShippingCostsChange(e) {
        const data = this.state.data

        this.setState({
            data: {
                ...data,
                shipping_costs: money.floatToAmount(e.target.value),
            },
        })
    }

    validatePurchaseShortcomingsEmailsChangeDebounced = _.debounce(this.validatePurchaseShortcomingsEmailsChange, 1000)
    validatePurchaseShortcomingsEmailsChange(value) {
        if (!value || value.trim().length === 0) {
            return null
        }

        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        const allEmails = value.split(',\n')
        const invalidEmails = allEmails.filter(email => !emailRegex.test(email.trim()))
        const validEmails = allEmails.filter(email => emailRegex.test(email.trim()))

        if (invalidEmails.lenght > 0) {
            this.props.showErrorNotification({
                message: `The email(s) '${invalidEmails.join(', ')}' are not valid`,
                duration: 3000,
            })
        }

        return validEmails.join(',\n')
    }

    handlePurchaseShortcomingsEmailChange(e) {
        const data = this.state.data
        const emails = e.target.value
            .split(',\n')
            .map(segment => segment.split(','))
            .flat()
            .filter(segment => e.nativeEvent.inputType === 'deleteContentBackward' ? segment : true)

        this.setState({
            data: {
                ...data,
                notify_purchase_shortcomings_emails: emails.join(',\n'),
            },
        })

        this.validatePurchaseShortcomingsEmailsChangeDebounced(e.target.value)
    }

    hasPurchaseEmail(supplier) {
        return supplier.contact && supplier.contact.purchase && supplier.contact.purchase.to.length > 0
    }

    getPurchaseContact(supplier) {
        if (supplier.integration && supplier.integration.action !== 'SMTP') { // XML can go via ftp or http
            return null
        }
        if (this.hasPurchaseEmail(supplier)) {
            const email = supplier.contact.purchase.to[0].email
            return (
                <ListItem>
                    <ListItemIcon><EmailIcon /></ListItemIcon>
                    {this.state.editing ? (
                        <ListItemText>
                            <TextField
                                id="purchase_email"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handlePurchaseEmailChange}
                                value={this.state.data.purchase_email}
                                label="Purchase order mail"
                            />
                        </ListItemText>
                    ) : (
                        <ListItemText primary="Purchase order mail" secondary={email} />
                    )}
                </ListItem>
            )
        }
        return null
    }


    handleCloseClick() {
        this.setState({ editing: false, data: {} })
    }


    handleSaveClick() {
        const data = this.state.data
        const supplier = this.props.supplier
        const notify_purchase_shortcomings_emails =
            this.validatePurchaseShortcomingsEmailsChange(data.notify_purchase_shortcomings_emails)

        const update = {
            intake_time: data.intake_time,
            rtv_approval: data.rtv_approval,
            notify_purchase_shortcomings_emails: notify_purchase_shortcomings_emails,
            rtv_ref_required: data.rtv_ref_required,
            free_of_payment_treshold: data.free_of_payment_treshold,
            shipping_costs: data.shipping_costs,
            shipping_carrier: data.shipping_carrier,
        }
        if (data.purchase_email) {
            update.contact = {
                purchase: {
                    ...supplier.contact.purchase,
                    to: [{
                        name: supplier.contact.purchase.to[0].name,
                        email: data.purchase_email,
                    }],
                },
            }
        }
        this.handleCloseClick()
        this.props.onUpdateSupplier(update)
    }

    handleEditClick() {
        const supplier = this.props.supplier
        this.setState({
            editing: true,
            data: {
                intake_time: supplier.intake_time,
                purchase_email: this.hasPurchaseEmail(supplier) ? supplier.contact.purchase.to[0].email : null,
                rtv_approval: supplier.rtv_approval,
                notify_purchase_shortcomings_emails: supplier.notify_purchase_shortcomings_emails,
                rtv_ref_required: supplier.rtv_ref_required,
                free_of_payment_treshold: supplier.free_of_payment_treshold,
                shipping_costs: supplier.shipping_costs,
                shipping_carrier: supplier.shipping_carrier,
            },
        })
    }

    handleCheckboxChange(prop) {
        return event => {
            const data = this.state.data
            this.setState({
                data: {
                    ...data,
                    [prop]: event.target.checked,
                },
            })
        }
    }

    handleShippingSupplierChange(event) {
        this.setState({
            data: {
                ...this.state.data,
                shipping_carrier: event.target.value,
            },
        })
    }

    generateShipmentCarrierList() {
        if (this.props.feature_toggles.value) {
            const options = this.props.feature_toggles.value.split('|')
            return options.filter(carrier => carrier)
        }
        return []
    }

    render() {
        const supplier = this.props.supplier
        const editing = this.state.editing
        return (
            <div style={style.container}>
                <Paper style={style.paper}>
                    <List>
                        <ListSubheader>Contact information</ListSubheader>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Contact Email"
                                secondary={supplier.email}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Contact Phone"
                                secondary={supplier.phone}
                            />
                        </ListItem>
                        {!supplier.btw ? null : (
                            <ListItem>
                                <ListItemText
                                    primary="VAT Number"
                                    secondary={supplier.btw}
                                />
                            </ListItem>
                        )}
                        {!supplier.kvk ? null : (
                            <ListItem>
                                <ListItemText
                                    primary="KVK Number"
                                    secondary={supplier.kvk}
                                />
                            </ListItem>
                        )}
                        <Divider />
                        <ListSubheader>Procurement information</ListSubheader>
                        {this.getPurchaseContact(supplier)}
                        <ListItem>
                            <ListItemIcon><ClockIcon /></ListItemIcon>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        id="intake_time"
                                        fullWidth
                                        type="time"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            step: 900, // 15 min
                                        }}
                                        onChange={this.handleIntakeTimeChange}
                                        value={this.state.data.intake_time}
                                        label="Purchase intake time"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText primary="Purchase intake time" secondary={supplier.intake_time} />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><EuroSymbolIcon /></ListItemIcon>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        id="free_of_payment_treshold"
                                        fullWidth
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            min: 0.01,
                                            step: 0.01,
                                        }}
                                        onChange={this.handleFreeOfPaymentTresholdChange}
                                        value={+this.state.data.free_of_payment_treshold}
                                        label="Purchase free of payment treshold"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Purchase free of payment treshold"
                                    secondary={supplier.free_of_payment_treshold}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><EuroSymbolIcon /></ListItemIcon>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        id="shipping_costs"
                                        fullWidth
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            min: 0.01,
                                            step: 0.01,
                                        }}
                                        onChange={this.handleShippingCostsChange}
                                        value={+this.state.data.shipping_costs}
                                        label="Shipping costs (if below free of payment treshold)"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Shipping costs (if below free of payment treshold)"
                                    secondary={supplier.shipping_costs}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><LocalShippingIcon /></ListItemIcon>
                            {editing ? (
                                <ListItemText>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >
                                        Change Shipping Carrier
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        displayEmpty
                                        value={this.state.data.shipping_carrier}
                                        label="Change Shipping Carrier"
                                        onChange={this.handleShippingSupplierChange}
                                    >
                                        <MenuItem value="" key="none">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.generateShipmentCarrierList().map(shipping_carrier => (
                                            <MenuItem value={shipping_carrier} key={shipping_carrier}>
                                                {shipping_carrier}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Shipping Carrier"
                                    secondary={supplier.shipping_carrier ? supplier.shipping_carrier : 'Not Defined'}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><NotificationImportantIcon /></ListItemIcon>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        id="notify_purchase_shortcomings_emails"
                                        multiline
                                        fullWidth
                                        type="string"
                                        onChange={this.handlePurchaseShortcomingsEmailChange}
                                        value={this.state.data.notify_purchase_shortcomings_emails ?? ''}
                                        label="Manco Mails"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    multiline
                                    primary="Manco Mails"
                                    secondary={supplier.notify_purchase_shortcomings_emails ?? 'Not Defined'}
                                    secondaryTypographyProps={{
                                        style: {
                                            overflow: 'auto',
                                            whiteSpace: 'pre-line',
                                        },
                                    }}
                                />
                            )}
                        </ListItem>
                        <Divider />
                        <ListSubheader>Return information</ListSubheader>
                        <ListItem>
                            <ListItemIcon>
                                <ThumbUpIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="RTV approval required"
                                secondary={editing ? null : getBooleanText(supplier.rtv_approval)}
                            />
                            {editing && (
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        disabled={!editing}
                                        onChange={this.handleCheckboxChange('rtv_approval')}
                                        checked={this.state.data.rtv_approval}
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SpellcheckIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="RTV reference required"
                                secondary={editing ? null : getBooleanText(supplier.rtv_ref_required)}
                            />
                            {editing && (
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        disabled={!editing}
                                        onChange={this.handleCheckboxChange('rtv_ref_required')}
                                        checked={this.state.data.rtv_ref_required}
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    </List>
                </Paper>
                {editing ? [
                    <Zoom key="close" in={true} timeout={200} unmountOnExit>
                        <Fab
                            aria-label="Close"
                            onClick={this.handleCloseClick}
                            style={style.firstFab}
                        >
                            <CloseIcon />
                        </Fab>
                    </Zoom>,
                    <Zoom key="save" in={true} timeout={200} unmountOnExit>
                        <Fab
                            color="primary"
                            aria-label="Done"
                            onClick={this.handleSaveClick}
                            style={style.secondFab}
                        >
                            <DoneIcon />
                        </Fab>
                    </Zoom>,
                ] : (
                    <Zoom in={true} timeout={200} unmountOnExit>
                        <Fab
                            color="primary"
                            disabled={!_.get(this.props, 'features.canEditSupplier', false)}
                            aria-label="Edit"
                            onClick={this.handleEditClick}
                            style={style.singleFab}
                        >
                            <EditIcon />
                        </Fab>
                    </Zoom>
                )}
            </div>
        )
    }
}

SupplierInformation.propTypes = {
    supplier: PropTypes.object,
    features: PropTypes.object,
    feature_toggles: PropTypes.object,
    onUpdateSupplier: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
}

export default connect(
    () => ({ }),
    dispatch => {
        return bindActionCreators({
            showErrorNotification,
        }, dispatch)
    }
)(SupplierInformation)
