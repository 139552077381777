import React, {Component} from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import {
    CardTitle,
    Dialog,
    FlatButton,
    TextField,
} from 'material-ui'

import AddCreditMemoTable from './AddCreditMemoTable.js'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block',
    },
}

const initialState = {
    mage_id: null,
    rma_id: null,
    line_items: [],
}

class AddCreditMemoDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mage_id: null,
            rma_id: null,
            line_items: [],
        }
    }

    handleSubmit() {
        const state = this.state
        this.setState({...initialState}, () => {
            this.props.onSubmit(state)
        })
    }

    handleAddItem(item) {
        const update = {
            line_items: {$push: [item]},
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleRemoveItem(index) {
        const update = {
            line_items: {
                $apply: arr => {
                    return arr.filter((a, i) => i !== index)
                },
            },
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleChange(property, value) {
        const updateState = {}
        updateState[property] = value
        this.setState(updateState)
    }

    canSubmit(state) {
        return state.supplier_id
            || state.line_items.length > 0
    }

    render() {
        const cannotSubmit = !this.canSubmit(this.state)
        return (
            <Dialog
                contentStyle={{width: 1366, maxWidth: 'none'}}
                onRequestClose={this.props.onClose}
                open={this.props.visible}
                actions={[
                    <FlatButton
                        key="credit_memo_submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit.bind(this)}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="credit_memo_close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle
                    style={style.title}
                    subtitleStyle={style.subtitleStyle}
                    subtitle="Add a credit memo"
                />
                <TextField
                    value={this.state.mage_id}
                    hintText={'Magento Order ID'}
                    style={style.textFieldStyle}
                    onChange={e => {this.handleChange('mage_id', e.target.value)}}
                />
                <br/>
                <TextField
                    value={this.state.rma_id}
                    hintText={'RMA ID (if applicable)'}
                    style={style.textFieldStyle}
                    onChange={e => {this.handleChange('rma_id', e.target.value)}}
                />
                <AddCreditMemoTable
                    line_items={this.state.line_items}
                    visible={Boolean(this.state.mage_id)}
                    onAddItem={this.handleAddItem.bind(this)}
                    onRemoveItem={this.handleRemoveItem.bind(this)}
                />
            </Dialog>
        )
    }
}

AddCreditMemoDialog.propTypes = {
    visible: PropTypes.bool,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

AddCreditMemoDialog.defaultProps = {
    isUpdated: false,
}

export default AddCreditMemoDialog
