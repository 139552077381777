import socket from '../socket'
import listeners from '../listeners/sockets/packing_slips'

listeners(socket)

export function packing_slip(state = [], action) {
    switch (action.type) {
        case 'DOWNLOAD_PACKING_SLIP':
            return action.packing_slip

        default:
            return state
    }
}
