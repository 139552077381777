export function submitCancellation(data) {
    return {type: 'SUBMIT_CANCELLATION', data}
}

export function getDropshipOrderCancellations(id) {
    return {type: 'GET_DROPSHIP_ORDER_CANCELLATIONS', id}
}

export function getShipmentCancellations(id) {
    return {type: 'GET_SHIPMENT_CANCELLATIONS', id}
}

export function refreshDropshipOrderCancellations(cancellations) {
    return {type: 'REFRESH_DROPSHIP_ORDER_CANCELLATIONS', cancellations}
}

export function handleCancellation(choice, params) {
    return {type: 'HANDLE_CANCELLATION', choice, params}
}
