import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    FlatButton,
    IconButton,
    Divider,
    TextField,
    Dialog,
} from 'material-ui'
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    List,
    ListSubheader,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'

import orange from '@material-ui/core/colors/orange'
const style = {
    paper: { width: '32%', height: 'auto' },
    dialog_header: { fontWeight: 'bold', padding: 0 },
    warningIcon: { color: 'red' },
    infoIcon: { color: orange[500] },
}

class StockLevels extends Component {

    constructor(props) {
        super(props)
        this.state = {
            minimum: {
                value: _.get(props.stocks, 'warehouse.minimum', 'Unknown'),
                editing: false,
            },
            consignment: {
                value: _.get(props.stocks, 'warehouse.consignment', 'Unknown'),
                editing: false,
            },
            retention_of_title: {
                value: _.get(props.stocks, 'warehouse.retention_of_title', 'Unknown'),
                editing: false,
            },
            own_stock: {
                value: _.get(props.stocks, 'warehouse.own_stock', 'Unknown'),
                editing: false,
            },
        }
    }

    static setStockValueIfUnknown(val, state) {
        if (state.value === 'Unknown' && typeof val === 'number') {
            return { value: val, editing: false }
        }
        return {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const warehouse_stock = nextProps.stocks.warehouse
        if (!warehouse_stock) {
            return null
        }
        const newState = {
            ...StockLevels.setStockValueIfUnknown(warehouse_stock.minimum, prevState.minimum),
            ...StockLevels.setStockValueIfUnknown(warehouse_stock.consignment, prevState.consignment),
            ...StockLevels.setStockValueIfUnknown(warehouse_stock.retention_of_title, prevState.retention_of_title),
            ...StockLevels.setStockValueIfUnknown(warehouse_stock.own_stock, prevState.own_stock),
        }
        if (Object.keys(newState).length > 0) {
            return newState
        }
        return null
    }

    updateState(state_prop, property, value) {
        const state = Object.assign({}, this.state)
        state[state_prop][property] = value
        this.setState(state)
    }

    handleTextFieldChange(property, value) {
        this.updateState(property, 'value', +value)
    }

    handleEditClick(property, current_value) {
        this.setState({
            [property]: {
                value: current_value,
                editing: true,
            },
        })
    }

    handleSubmitClick(property) {
        const update = {
            ...this.props.stocks.warehouse,
        }
        update[property] = this.state[property].value
        this.props.onStockUpdate(update)
        this.handleCloseClick()
    }

    handleCloseClick() {
        const state = Object.assign({}, this.state)
        state.minimum.editing = false
        state.consignment.editing = false
        state.retention_of_title.editing = false
        state.own_stock.editing = false
        this.setState(state)
    }

    getNumericValueOrUnknown(val) {
        return typeof val === 'number' ? `${val}` : 'Unknown'
    }

    getDialog({ editing, value }, property, header) {
        const subheader = !header ? null : (
            <ListSubheader style={style.dialog_header}>{header}</ListSubheader>
        )
        return (
            <Dialog
                open={editing}
                actions={[
                    <FlatButton
                        key="submit"
                        onClick={() => this.handleSubmitClick(property)}
                        primary={true}
                        label="Submit"
                    />,
                    <FlatButton
                        key="close"
                        onClick={this.handleCloseClick.bind(this)}
                        label="Close"
                    />,
                ]}
            >
                {subheader}
                <TextField
                    type="number"
                    value={typeof value === 'number' ? value : 0}
                    onChange={({ target }) => this.handleTextFieldChange(property, target.value)}
                />
            </Dialog>
        )
    }

    render() {
        const variant = this.props.variant
        const stocks = this.props.stocks
        const warehouse_stock = stocks && stocks.warehouse ? stocks.warehouse : {}
        const minimum_dialog = this.getDialog(this.state.minimum, 'minimum', 'Update minimal stock level')
        const consignment_dialog = this.getDialog(this.state.consignment, 'consignment', 'Update consignment amount')
        const retention_dialog = this.getDialog(
            this.state.retention_of_title, 'retention_of_title', 'Update retention of title amount'
        )
        const own_stock_dialog = this.getDialog(this.state.own_stock, 'own_stock', 'Update own stock amount')

        return (
            <Paper style={style.paper}>
                <List>
                    <ListSubheader>Stock levels</ListSubheader>


                </List>
                <ListItem>
                    <ListItemText
                        primary="Locations"
                        secondary={`${_.get(stocks, 'warehouse.locations_joined', '')}`}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Currently on-hand"
                        secondary={`${_.get(warehouse_stock, 'on_hand_quantity', 'Unknown')}`}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Reserved"
                        secondary={`${_.get(warehouse_stock, 'reserved_quantity', 'Unknown')}`}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="In purchase"
                        secondary={`${_.get(variant, 'in_purchase_quantity', 0)}`}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="In requisition"
                        secondary={`${_.get(variant, 'in_requisition_quantity', 0)}`}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Minimum on stock"
                        secondary={this.getNumericValueOrUnknown(warehouse_stock.minimum)}
                        rightIconButton={
                            <IconButton
                                iconClassName="material-icons"
                                onClick={() => this.handleEditClick('minimum', warehouse_stock.minimum)}
                            >
                                edit
                            </IconButton>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Consignment"
                        secondary={this.getNumericValueOrUnknown(warehouse_stock.consignment)}
                        rightIconButton={
                            <IconButton
                                iconClassName="material-icons"
                                onClick={() => this.handleEditClick('consignment', warehouse_stock.consignment)}
                            >
                                edit
                            </IconButton>
                        }
                    />
                </ListItem>
                <Divider />

                <ListItem>
                    <ListItemText
                        primary="Retention of title"
                        secondary={this.getNumericValueOrUnknown(warehouse_stock.retention_of_title)}
                        rightIconButton={
                            <IconButton
                                iconClassName="material-icons"
                                onClick={() => {
                                    this.handleEditClick('retention_of_title', warehouse_stock.retention_of_title)
                                }}
                            >
                                edit
                            </IconButton>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Own stock"
                        secondary={this.getNumericValueOrUnknown(warehouse_stock.own_stock)}
                        rightIconButton={
                            <IconButton
                                iconClassName="material-icons"
                                onClick={() => this.handleEditClick('own_stock', warehouse_stock.own_stock)}
                            >
                                edit
                            </IconButton>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Vendor stock"
                        secondary={`${_.get(stocks, 'vendor.quantity', 'Unknown')}`}
                    />
                </ListItem>
                <Divider />
                {minimum_dialog}
                {consignment_dialog}
                {retention_dialog}
                {own_stock_dialog}

                {!variant.end_of_life ? null : (
                    <ListItem>
                        <ListItemIcon style={style.warningIcon}><WarningIcon /></ListItemIcon>
                        <ListItemText
                            primary={warehouse_stock.on_hand_quantity - warehouse_stock.reserved_quantity > 0
                                ? 'Bij voorraad 0 is dit artikel niet meer bestelbaar door een klant'
                                : 'Dit artikel is uitgefaseerd'
                            }
                            secondary={
                                'Dit artikel kan niet meer bij een '
                            + (warehouse_stock.on_hand_quantity - warehouse_stock.reserved_quantity > 0
                                ? 'leverancier'
                                : 'leverancier of door een klant')
                            + ' besteld worden'
                            }
                        />
                    </ListItem>
                )}
                {!variant.migrating_to_crossdock ? null : (
                    <ListItem>
                        <ListItemIcon style={style.infoIcon}><InfoIcon /></ListItemIcon>
                        <ListItemText
                            primary={warehouse_stock.on_hand_quantity - warehouse_stock.reserved_quantity > 0
                                ? 'Bij voorraad 0 is de migratie naar crossdock compleet'
                                : 'Dit artikel is uit ons magazijn'}
                            secondary={'Nieuwe artikelen zullen onder crossdock besteld worden'}
                        />
                    </ListItem>
                )}
            </Paper>
        )
    }
}

StockLevels.propTypes = {
    variant: PropTypes.object,
    stocks: PropTypes.array,
    onStockUpdate: PropTypes.func.isRequired,
}
StockLevels.defaultProps = {
    stocks: {},
}

export default StockLevels
