import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableHeader, TableBody, TableRow, TableHeaderColumn, TableRowColumn } from 'material-ui'
import _ from 'lodash'

const style = {
    width: '100%',
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
}

class SupplierTable extends React.Component {

    navigateToSupplier(index) {
        if (typeof this.props.suppliers[index] === 'object') {
            const mage_id = this.props.suppliers[index].mage_id
            this.context.router.push(`/suppliers/${mage_id}`)
        }
    }

    handleCellClick(rowNumber, columnId) {
        if (columnId !== -1) {
            this.navigateToSupplier(rowNumber)
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    renderSupplierRows(suppliers) {
        if (suppliers.length > 0) {
            return _.map(this.props.suppliers, (supplier, index) => (
                <TableRow key={index} style={{cursor: 'pointer'}}>
                    <TableRowColumn>{supplier.mage_id}</TableRowColumn>
                    <TableRowColumn>{supplier.name}</TableRowColumn>
                    <TableRowColumn>{supplier.email}</TableRowColumn>
                    <TableRowColumn>{supplier.phone}</TableRowColumn>
                    <TableRowColumn>{supplier.shipping_carrier}</TableRowColumn>
                </TableRow>
            ))
        }
        return (
            <TableRow style={style.blankListStateMessage}>
                <TableRowColumn>No suppliers found.</TableRowColumn>
            </TableRow>
        )
    }

    render() {
        const supplierRows = this.renderSupplierRows(this.props.suppliers)
        return (
            <Table style={style} onCellClick={this.handleCellClick.bind(this)}>
                <TableHeader enableSelectAll={true}>
                    <TableRow>
                        {
                            _.map(this.props.headerCols, (column, index) => {
                                return (
                                    <TableHeaderColumn key={index} tooltip={column.tooltip}>
                                        {column.content}
                                    </TableHeaderColumn>
                                )
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody showRowHover={true}>
                    {supplierRows}
                </TableBody>
            </Table>
        )
    }
}

SupplierTable.propTypes = {
    headerCols: PropTypes.array,
    suppliers: PropTypes.array,
    shouldRender: PropTypes.bool,
}

SupplierTable.contextTypes = {
    router: PropTypes.object,
}

SupplierTable.defaultProps = {
    headerCols: [{
        content: 'ID',
        tooltip: 'ID',
    }, {
        content: 'Name',
        tooltip: 'Name',
    }, {
        content: 'E-mail',
        tooltip: 'E-mail',
    }, {
        content: 'Phone',
        tooltip: 'Phone number',
    }, {
        content: 'Shipping Carrier',
        tooltip: 'Shipping Carrier',
    }],
}

export default SupplierTable
