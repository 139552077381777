import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import UndoIcon from '@material-ui/icons/Undo'

import AddPickDialog from './AddPickDialog'
import PickListPicks from './PickListPicks'
import Header from './../../../shared/Header'


import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {
    getPickList,
    addPickToPickList,
    removePickFromPickList,
    finalisePickList,
    revokePickList,
} from '../../../../../commands/sockets/pick_lists'

import {formatDateTime} from '../../../../utils/format'

const styles = {
    container: {width: '95%', margin: 'auto'},
    contents: {display: 'flex', marginTop: 12},
    info: {
        width: '100%',
        maxWidth: '200px',
        marginRight: '10px',
    },
    table: {},
    items: {width: 'calc(100% - 210px)'},
    button: {
        marginLeft: '10px',
    },
    iconRight: {
        marginLeft: '5px',
    },
}

class PickListDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            add_dialog_open: false,
        }

        this.handlePickRemoveClick = this.handlePickRemoveClick.bind(this)
        this.handleDialogOpenClick = this.handleDialogOpenClick.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleNewPick = this.handleNewPick.bind(this)
        this.filterDialogSuggestions = this.filterDialogSuggestions.bind(this)
        this.handleRevokePickList = this.handleRevokePickList.bind(this)
        this.handleFinaliseDraftClick = this.handleFinaliseDraftClick.bind(this)
    }

    componentDidMount() {
        this.props.getPickList(+this.props.params.id)
    }

    handlePickRemoveClick(pick) {
        const {increment_id} = this.props.pick_list
        removePickFromPickList(increment_id, pick)
    }

    handleDialogOpenClick() {
        this.setState({add_dialog_open: true})
    }

    handleDialogClose() {
        this.setState({add_dialog_open: false})
    }

    handleNewPick(variant, quantity) {
        this.setState({add_dialog_open: false})

        const {increment_id} = this.props.pick_list

        const pick = {
            sku: variant.supplier_sku,
            ean: variant.ean || '',
            barcode: variant.barcode || variant.ean || '',
            qty_to_pick: quantity,
            qty_picked: 0,
            name: variant.name || '',
            size: variant.size || '',
            location: '', // variant.stocks.warehouse.locations[0],
            color: variant.color || '',
        }

        return this.props.addPickToPickList(increment_id, pick)
    }

    filterDialogSuggestions(mapped) {
        return this.props.pick_list.picks.every(pick => mapped.variant.supplier_sku !== pick.sku)
    }

    handleFinaliseDraftClick() {
        const {increment_id} = this.props.pick_list
        return this.props.finalisePickList && this.props.finalisePickList(+increment_id)
    }

    handleRevokePickList() {
        const {increment_id} = this.props.pick_list
        return this.props.revokePickList && this.props.revokePickList(+increment_id)
    }

    render() {
        const {pick_list} = this.props

        if (!pick_list || !pick_list.increment_id) {
            return null
        }

        return (
            <div style={styles.container}>
                <Header
                    title={`PickList: ${pick_list.increment_id}`}
                    subheader={`Status: ${pick_list.status}`}
                />
                <div style={styles.contents}>
                    <Paper style={styles.info}>
                        <List component="div">
                            <ListItem>
                                <ListItemText primary="Creation" secondary={formatDateTime(pick_list.created_at)} />
                            </ListItem>
                            {pick_list.status === 'completed' ? (
                                <ListItem>
                                    <ListItemText
                                        primary="Completion"
                                        secondary={formatDateTime(pick_list.completed_at)}
                                    />
                                </ListItem>
                            ) : null}
                        </List>
                    </Paper>

                    <Card style={styles.items}>
                        <CardContent>
                            <PickListPicks
                                picks={pick_list.picks}
                                status={pick_list.status}
                                onPickRemoveClick={this.handlePickRemoveClick}
                            />
                        </CardContent>
                        {pick_list.status === 'draft' ? (
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleDialogOpenClick}
                                    style={styles.button}
                                >
                                    Add Pick
                                    <AddCircleOutlineIcon style={styles.iconRight} />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleFinaliseDraftClick}
                                    style={{...styles.button, color: 'white'}}
                                    disabled={!pick_list.picks.length}
                                >
                                    Finalise Draft
                                    <CheckIcon style={styles.iconRight} />
                                </Button>
                                <AddPickDialog
                                    open={this.state.add_dialog_open}
                                    onDialogClose={this.handleDialogClose}
                                    onNewPick={this.handleNewPick}
                                    filterSuggestions={this.filterDialogSuggestions}
                                />
                            </CardActions>
                        ) : null}
                        {pick_list.status !== 'completed' && pick_list.status !== 'revoked' ? (
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleRevokePickList}
                                    style={styles.button}
                                >
                                    Revoke pick list
                                    <UndoIcon style={styles.iconRight} />
                                </Button>
                            </CardActions>
                        ) : null}
                    </Card>
                </div>
            </div>
        )
    }
}

PickListDetail.contextTypes = {}
PickListDetail.propTypes = {
    params: PropTypes.object.isRequired,
    pick_list: PropTypes.object.isRequired,
    getPickList: PropTypes.func.isRequired,
    addPickToPickList: PropTypes.func.isRequired,
    removePickFromPickList: PropTypes.func.isRequired,
    finalisePickList: PropTypes.func.isRequired,
    revokePickList: PropTypes.func.isRequired,
}
PickListDetail.defaultProps = {
    pick_list: {},
}

export default connect(({pick_list = {}}) => {
    return {
        pick_list,
    }
}, dispatch => {
    return bindActionCreators({
        getPickList,
        addPickToPickList,
        removePickFromPickList,
        finalisePickList,
        revokePickList,
    }, dispatch)
})(PickListDetail)
