import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {getSupplier, updateSupplier} from './../../../commands/sockets/suppliers'
import {getFeatures} from './../../../commands/sockets/settings'

import Title from './../shared/Title'
import AddressList from './supplier-detail/AddressList'

import SupplierInformation from './supplier-detail/SupplierInformation'
import OrderDays from './supplier-detail/OrderDays'
import ClosedDates from './supplier-detail/ClosedDates'

const style = {
    container: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
    },
    address: {
        width: '30%',
    },
    outerPaper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: '95%',
        margin: '0px auto',
        marginBottom: '30px',
    },
    innerPaper: {},
    middleColumn: {
        width: '30%',
    },
    details: {
        width: '100%',
        padding: '0 15px',
        display: 'flex',
        flexflow: 'row',
        justifyContent: 'space-between',
    },
}

const FEATURE_KEY = 'shippingCarrier'
class SupplierDetail extends React.Component {

    constructor(props) {
        super(props)

        this.handleOrderDayChange = this.handleOrderDayChange.bind(this)
        this.handleUpdateSupplier = this.handleUpdateSupplier.bind(this)

        getFeatures()
    }

    componentDidMount() {
        this.props.getSupplier(+this.props.params.id)
    }

    handleOrderDayChange(day, checked) {
        const days = _.cloneDeep(this.props.supplier.order_days)
        const index = days.indexOf(day)
        if (checked) {
            days.push(day)
        } else {
            days.splice(index, 1)
        }
        this.handleUpdateSupplier({order_days: days})
    }

    handleUpdateSupplier(data) {
        this.props.updateSupplier(this.props.supplier._id, data)
    }

    render() {
        const supplier = this.props.supplier
        if (!supplier || !supplier.mage_id) { return null }

        return (
            <div style={style.container}>
                <Title
                    title={supplier.name}
                    subtitle={supplier.mage_id.toString()}
                />
                <div style={style.details}>
                    <SupplierInformation
                        supplier={supplier}
                        features={this.props.features}
                        onUpdateSupplier={this.handleUpdateSupplier}
                        feature_toggles={this.props.feature_toggles}
                    />
                    <div style={style.middleColumn}>
                        <OrderDays
                            onOrderDayChange={this.handleOrderDayChange}
                            supplier={supplier}
                            features={this.props.features}
                        />
                        <ClosedDates
                            onOrderDayChange={this.handleOrderDayChange}
                            supplier={supplier}
                            features={this.props.features}
                        />
                    </div>
                    <AddressList
                        style={style.address}
                        supplier={supplier}
                        onAddressChange={this.handleUpdateSupplier}
                        features={this.props.features}
                    />
                </div>
            </div>
        )
    }
}

SupplierDetail.propTypes = {
    supplier: PropTypes.object,
    params: PropTypes.object,
    role: PropTypes.string,
    features: PropTypes.object,

    getSupplier: PropTypes.func.isRequired,
    updateSupplier: PropTypes.func.isRequired,
    feature_toggles: PropTypes.array,
}

export default connect(({supplier, features}) => {
    const feature = features.find(f => f.name === FEATURE_KEY)
    return {
        supplier,
        feature_toggles: feature ? feature : [],
    }
}, dispatch => {
    const actions = {getSupplier, updateSupplier, getFeatures}
    return bindActionCreators(actions, dispatch)
})(SupplierDetail)
