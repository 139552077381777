import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import red from '@material-ui/core/colors/red'

import WatchLaterIcon from '@material-ui/icons/WatchLater'

const styles = {
    table: {borderSpacing: 5, borderCollapse: 'separate', width: '100%'},
    expansionPanel: { marginTop: 0},
    candidateCancelled: { backgroundColor: red[200]},
}

function PurchaseCandidate(props, context) {
    const purchase_candidate = props.purchase_candidate
    return (
        <ExpansionPanel
            expanded={true}
            style={styles.expansionPanel}
        >
            <ExpansionPanelSummary>
                <Avatar backgroundColor={context.colors.orange700}>
                    <WatchLaterIcon />
                </Avatar>
                &nbsp;
                <div>
                    Purchase order number: &nbsp;
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                        onClick={() => {
                            context.router.push(`/purchases/purchase-orders/${purchase_candidate.purchase_id}`)
                        }}
                    >
                        {purchase_candidate.purchase_id}
                    </a><br/>
                    Supplier: &nbsp;
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                        onClick={() => {
                            context.router.push(`/suppliers/${purchase_candidate.supplier.mage_id}`)
                        }}
                    >
                        {purchase_candidate.supplier.name}
                    </a>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th width="35%">Name</th>
                            <th width="20%">SKU</th>
                            <th width="15%">EAN</th>
                            <th width="5%">Quantity</th>
                            <th width="25%">Expected delivery date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            purchase_candidate.line_items.map((li, index) => (
                                <tr key={`${li.sku}-${index}`}
                                    style={li.status === 'cancelled' ? styles.candidateCancelled : null}
                                >
                                    <td>{li.name}</td>
                                    <td>{li.sku}</td>
                                    <td>{li.ean}</td>
                                    <td>{li.quantity}</td>
                                    <td>{li.estimated_for
                                        ? moment(li.estimated_for).format('DD-MM-YY')
                                        : '?'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

PurchaseCandidate.contextTypes = {
    colors: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}
PurchaseCandidate.propTypes = {
    purchase_candidate: PropTypes.object,
}
PurchaseCandidate.defaultProps = {}

export default PurchaseCandidate
