import socket from './../socket'
import listeners from '../listeners/sockets/pick_lists'

listeners(socket)

export function pick_lists(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_PICK_LISTS':
            return action.data

        default:
            return state
    }
}

export function pick_list(state = {}, action) {
    switch (action.type) {
        case 'REFRESH_PICK_LIST':
            return action.data
        default:
            return state
    }
}
