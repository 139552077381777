import React, { Component } from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import autoBind from 'react-autobind'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'

import orange from '@material-ui/core/colors/orange'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    searchSuppliers,
} from './../../../commands/sockets/suppliers'
import {
    downloadFreeLocations,
} from './../../../commands/sockets/locations'
import {
    logFileReaderResult,
    searchProductVariants,
    updateInventory,
    deleteProductVariant,
    deltaBulkUpdate,
    bulkUpdate,
} from './../../../actions/variants'

import { updatePurchaseable, deltaPurchaseable } from './../../../commands/sockets/variants'
import {
    uploadPhaseOutCsv,
    uploadExistingWarehouseCsv,
    uploadNewWarehouseCsv,
    uploadConvertFromCrossdockCsv,
    adjustInventoryInbound,
    adjustInventoryOutbound,
} from './../../../commands/sockets/inventory'

import ProductFilters from './ProductFilters'
import ProductActions from './ProductActions'
import ProductTable from './ProductTable'
import AdjustmentDialog from './AdjustmentDialog'
import LocationsDialog from './LocationsDialog'
import SyncStatusDialog from './SyncStatusDialog'
import ImportWarehouseStocksDialog from './ImportWarehouseStocksDialog'
import ExportWarehouseStocksDialog from './ExportWarehouseStocksDialog'
import ImportVendorStocksDialog from './ImportVendorStocksDialog'
import ProductBulkUpdateDialog from './ProductBulkUpdateDialog'
import ChangeColumnsDialog from './ChangeColumnsDialog'
import { getAuthorizedElements } from '../../helpers/authorizationHelper'

const fields = 'name,supplier_sku,size,color,is_purchaseable,processing_time,stocks,stock'


const style = {
    top: {
        height: 64,
        position: 'fixed',
        width: '100%',
        minWidth: 1200,
        left: 0,
        backgroundColor: '#FFFFFF',
        zIndex: 10,
    },
    table: {
        marginTop: 64,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: 'red',
    },
    infoIcon: {
        verticalAlign: 'bottom',
        color: orange[500],
    },
}
const headerCols = Object.freeze({
    crossdock: [{
        content: 'Name',
        tooltip: 'Name',
        property: 'name',
        size: 'medium',
        sortable: true,
    }, {
        content: 'Supplier SKU',
        tooltip: 'SKU of the supplier',
        property: 'supplier_sku',
        icon: variant => {
            if (variant.end_of_life) {
                return (<WarningIcon style={style.warningIcon} />)
            } else if (variant.migrating_to_crossdock) {
                return (<InfoIcon style={style.infoIcon} />)
            }
            return null
        },
        size: 'medium',
        sortable: true,
    }, {
        content: 'EAN',
        tooltip: 'The International Article Number',
        property: 'ean',
        size: 'medium',
        editable: true,
        type: 'text',
    }, {
        content: 'Size',
        tooltip: 'Size of the product',
        property: 'size',
        size: 'small',
        editable: true,
        type: 'text',
        sortable: true,
    }, {
        content: 'Color',
        tooltip: 'Color of the product',
        property: 'color',
        size: 'small',
        editable: true,
        type: 'text',
        sortable: true,
    }, {
        content: 'Source',
        tooltip: 'The fulfillment source',
        property: 'fulfillment_source',
        size: 'small',
        sortable: true,
    }, {
        content: 'In requisition',
        tooltip: 'Quantity currently in requisition',
        property: 'in_requisition_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'In purchase',
        tooltip: 'Quantity currently in purchase',
        property: 'in_purchase_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Purchaseable',
        tooltip: 'Indicates if item can be purchased',
        property: 'is_purchaseable',
        size: 'small',
        sortable: true,
    }, {
        content: 'Purchase scale qty',
        tooltip: 'The amount the item must be purchased in on purchase orders for discount cost price',
        property: 'purchasing_scale_quantity',
        size: 'small',
        sortable: true,
    }, {
        content: 'Supplier(s)',
        tooltip: 'Supplier(s) of the product',
        property: 'supplier.name',
        size: 'medium',
        sortable: true,
    }],
    warehouse: [{
        content: 'Name',
        tooltip: 'Name',
        property: 'name',
        size: 'medium',
        sortable: true,
    }, {
        content: 'Supplier SKU',
        tooltip: 'SKU of the supplier',
        property: 'supplier_sku',
        icon: variant => {
            if (variant.end_of_life) {
                return (<WarningIcon style={style.warningIcon} />)
            } else if (variant.migrating_to_crossdock) {
                return (<InfoIcon style={style.infoIcon} />)
            }
            return null
        },
        size: 'medium',
        sortable: true,
    }, {
        content: 'EAN',
        tooltip: 'The International Article Number',
        property: 'ean',
        size: 'medium',
        editable: true,
        type: 'text',
    }, {
        content: 'Size',
        tooltip: 'Size of the product',
        property: 'size',
        size: 'small',
        editable: true,
        type: 'text',
        sortable: true,
    }, {
        content: 'Color',
        tooltip: 'Color of the product',
        property: 'color',
        size: 'small',
        editable: true,
        type: 'text',
        sortable: true,
    }, {
        content: 'Available Stock',
        tooltip: 'The stock level of the product in the vendor warehouse',
        property: 'stocks.warehouse.available_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'On-hand Stock',
        tooltip: 'The on-hand stock level of the product in the Proforto warehouse',
        property: 'stocks.warehouse.on_hand_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Reserved',
        tooltip: 'The reserved quantity',
        property: 'stocks.warehouse.reserved_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'In requisition',
        tooltip: 'Quantity currently in requisition',
        property: 'in_requisition_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'In purchase',
        tooltip: 'Quantity currently in purchase',
        property: 'in_purchase_quantity',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Minimum',
        tooltip: 'Minimum stock level',
        property: 'stocks.warehouse.minimum',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Consignment',
        tooltip: 'The consignment level',
        property: 'stocks.warehouse.consignment',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Retention of title',
        tooltip: 'The retention of title level',
        property: 'stocks.warehouse.retention_of_title',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Own stock',
        tooltip: 'The own stock level',
        property: 'stocks.warehouse.own_stock',
        size: 'small',
        editable: false,
        type: 'number',
    }, {
        content: 'Locations',
        tooltip: 'The stock locations assigned in the warehouse',
        property: 'stocks.warehouse.locations_joined',
        size: 'small',
        editable: false,
    }, {
        content: 'Purchaseable',
        tooltip: 'Indicates if item can be purchased',
        property: 'is_purchaseable',
        size: 'small',
        sortable: true,
    }, {
        content: 'Purchase scale qty',
        tooltip: 'The amount the item must be purchased in on purchase orders for discount cost price',
        property: 'purchasing_scale_quantity',
        size: 'small',
        sortable: true,
    }, {
        content: 'Source',
        tooltip: 'The fulfillment source',
        property: 'fulfillment_source',
        size: 'small',
        sortable: true,
    }, {
        content: 'Supplier',
        tooltip: 'Supplier of the product',
        property: 'supplier.name',
        size: 'medium',
        sortable: true,
    }],
})

const STORAGE_KEY = 'product-filters'


const EXTERNAL_SUPPLY_CHAIN_ROLE = 'external-supply-chain'
const BASE_REQUIRED_PERMISSIONS = ['inventory']

class ProductList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            editable_variants: [],
            value: filters.value || '',
            source: filters.source || 'warehouse',
            stock: filters.stock || 0,
            supplier_id: filters.supplier_id || 'all',
            page: filters.page || 1,
            limit: filters.limit || 20,
            sorting: {},
            updatedRow: 'all',
            isAdjustmentOpen: false,
            isLocationsOpen: false,
            isImportWarehouseOpen: false,
            isExportWarehouseOpen: false,
            isColumnsDialogOpen: false,
            isImportVendorOpen: false,
            isBulkUpdateOpen: false,
            isConfirmDeleteOpen: false,
            isSyncStatusOpen: false,
            hasChanges: false,
            headerColumns: { ...headerCols },
            shouldTableRender: false,
        }

        autoBind(this)
    }

    componentDidMount() {
        this.setPersonalHeaderColumns()
        this.searchProductVariants()
        this.handleSearchSuppliers({})
    }

    setPersonalHeaderColumns() {
        const view_preferences = this.props.preferences.inventory_view || {}
        const headerColumns = ['all', 'warehouse', 'crossdock'].reduce((acc, source) => {
            if (view_preferences[source]) {
                acc[source] = [
                    ...view_preferences[source],
                    ...(headerCols[source] || []).filter(c => {
                        return view_preferences[source].every(vc => vc.property !== c.property)
                    }),
                ]
            } else {
                acc[source] = source === 'all' ? headerCols.warehouse : headerCols[source]
            }
            return acc
        }, {})
        this.setState({ headerColumns })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.variants !== this.props.variants) {
            /* eslint-disable-next-line react/no-did-update-set-state */
            this.setState({
                shouldTableRender: true,
                hasChanges: false,
                editable_variants: this.props.variants,
            })
        }
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    getFilterState() {
        const filterState = {
            value: this.state.value,
            source: this.state.source,
            limit: this.state.limit,
            supplier_id: +this.state.supplier_id,
            sorting: Object.keys(this.state.sorting).length === 0 ? null : this.state.sorting,
            page: this.state.page,
        }
        if (filterState.source === 'warehouse') {
            filterState.stock = this.state.stock
        }
        if (filterState.source === 'all') {
            delete filterState.source
        }
        if (isNaN(filterState.supplier_id)) {
            delete filterState.supplier_id
        }
        return filterState
    }

    searchProductVariants() {
        const filters = this.getFilterState()
        this.storeFilters(filters)

        // Add selected columns to search filters
        // So only selected fields have to be fetched from the backend
        const headers = this.getPersonalHeaderColumns()
        const filters_with_properties = {
            ...filters,
            properties: headers.map(({ property }) => property),
        }

        this.props.searchProductVariants(filters_with_properties)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchProductVariants, 500)
    }

    handleSearchFieldChange(e) {
        this.setState({
            fields,
            value: e.target.value,
            page: 1,
            shouldTableRender: false,
        }, this.setSearchTimeout)
    }

    handleStockViewChange(e, index, value) {
        this.setState({
            fields,
            stock: value,
            page: 1,
            shouldTableRender: false,
        }, this.searchProductVariants)
    }

    handleSupplierChange(e) {
        this.setState({
            fields,
            supplier_id: e.target.value,
            page: 1,
            shouldTableRender: false,
        }, this.searchProductVariants)
    }

    handleSourceChange(e) {
        this.setState({
            fields,
            source: e.target.value,
            page: 1,
            shouldTableRender: false,
        }, this.searchProductVariants)
    }

    handlePageDecrement() {
        this.setState({
            page: this.state.page - 1,
            shouldTableRender: false,
        }, this.searchProductVariants)
    }

    handlePageIncrement() {
        this.setState({
            page: this.state.page + 1,
            shouldTableRender: false,
        }, this.searchProductVariants)
    }

    handleSaveChangesClick() {
        this.props.updateInventory(this.state.editable_variants, this.props.email)
    }

    handleSyncStatusClick() {
        this.setState({
            isSyncStatusOpen: true,
        })
    }

    handleDifferenceClick() {
        this.setState({
            isAdjustmentOpen: true,
        })
    }

    handleLocationsClick() {
        this.setState({
            isLocationsOpen: true,
        })
    }

    handleBulkUpdateClick() {
        this.setState({
            isBulkUpdateOpen: true,
        })
    }

    handleImportWarehouseStocksClick() {
        this.setState({
            isImportWarehouseOpen: true,
        })
    }

    handleExportWarehouseStocksClick() {
        this.setState({
            isExportWarehouseOpen: true,
        })
    }

    handleRowEdit(index, value, property) {
        const update = {}
        update[index] = {}
        update[index][property] = { $set: value }
        const editable_variants = reactUpdate(this.state.editable_variants, update)
        this.setState({
            editable_variants,
            hasChanges: true,
            updatedRow: index,
        })
    }

    handleCloseDialogs() {
        this.setState({
            isAdjustmentOpen: false,
            isLocationsOpen: false,
            isImportWarehouseOpen: false,
            isExportWarehouseOpen: false,
            isImportVendorOpen: false,
            isBulkUpdateOpen: false,
            isConfirmDeleteOpen: false,
            isColumnsDialogOpen: false,
            pendingDeleteVariant: null,
            isSyncStatusOpen: false,
        })
    }

    handleProductImportNewSubmit(content) {
        this.props.uploadNewWarehouseCsv({ content })
    }

    handleProductImportConvertFromCrossdockSubmit(content) {
        this.props.uploadConvertFromCrossdockCsv({ content })
    }

    handleProductImportExistingSubmit(content) {
        this.props.uploadExistingWarehouseCsv({ content })
    }

    handleProductImportPhaseOutSubmit(content) {
        this.props.uploadPhaseOutCsv({ content })
    }

    handleBulkUpdateSubmit(data) {
        this.props.bulkUpdate(data)
        this.handleCloseDialogs()
        setTimeout(this.searchProductVariants, 500)
    }

    handleDeleteVariant(variant) {
        this.setState({
            isConfirmDeleteOpen: true,
            pendingDeleteVariant: variant,
        })
    }

    handleConfirmDeleteDialogSubmit() {
        const variant = this.state.pendingDeleteVariant
        this.handleCloseDialogs()
        this.props.deleteProductVariant(variant)
    }

    handleSearchSuppliers(params) {
        this.props.searchSuppliers(params)
    }

    handleDownloadFreeLocationsClick() {
        this.props.downloadFreeLocations()
    }

    handleAdjustmentDialogSubmit(data) {
        const variant = {
            supplier_sku: data.variant.supplier_sku,
            ean: data.variant.ean,
            name: data.variant.name,
        }
        if (data.correction_type === 'inventory-adjustment-inbound') {
            this.props.adjustInventoryInbound({
                from: data.direction === 'to' ? 'warehouse' : data.location,
                to: data.direction === 'from' ? 'warehouse' : data.location,
                reference_source: data.reference_source,
                reference: data.reference,
                variant,
                quantity: data.quantity,
            })
        } else if (data.correction_type === 'inventory-adjustment-outbound') {
            this.props.adjustInventoryOutbound({
                from: data.direction === 'to' ? 'warehouse' : data.location,
                to: data.direction === 'from' ? 'warehouse' : data.location,
                reference_source: data.reference_source,
                reference: data.reference,
                variant,
                quantity: data.quantity,
            })
        }
    }

    handleBulkUpdateDeltaSubmit(data) {
        this.props.deltaBulkUpdate(data)
    }

    handleImportVendorStocksClick() {
        this.setState({ isImportVendorOpen: true })
    }

    handleImportVendorStocksDialogPreview(data) {
        this.props.deltaPurchaseable(data)
    }

    handleImportVendorStocksDialogSubmit(data) {
        this.props.updatePurchaseable(data)
    }

    handleSortChange(property) {
        const oldSorting = this.state.sorting
        const newSorting = {}
        newSorting[property] = oldSorting[property] ? -oldSorting[property] : 1
        this.setState({
            page: 1,
            sorting: {
                ...oldSorting,
                ...newSorting,
            },
        }, this.searchProductVariants)
    }

    handleFileReaderResult(result) {
        this.props.logFileReaderResult(result)
    }

    handleChangeColumnsClick() {
        this.setState({
            isColumnsDialogOpen: true,
            shouldTableRender: false,
        })
    }

    handleColumnsSave(columns) {
        const new_view = {
            ...this.state.headerColumns,
            [this.state.source]: columns,
        }
        this.setState({
            headerColumns: new_view,
            shouldTableRender: true,
        }, () => {
            localStorage.setItem('preferences', JSON.stringify({
                ...this.props.preferences,
                inventory_view: new_view,
            }))
        })
    }

    getBaseColumns() {
        if (this.state.source === 'all') {
            return [...headerCols.warehouse]
        }
        return [...headerCols[this.state.source]]
    }

    getPersonalHeaderColumns() {
        return this.state.headerColumns[this.state.source].filter(c => !c.hidden)
    }

    render() {
        const bulk_edit_dialog = (
            <ProductBulkUpdateDialog
                open={this.state.isBulkUpdateOpen}
                delta={this.props.delta}
                suppliers={this.props.suppliers.map(({ name, mage_id }) => { return { name, mage_id } })}
                onClose={this.handleCloseDialogs}
                onSubmit={this.handleBulkUpdateSubmit}
                onSearchSuppliers={this.handleSearchSuppliers}
                onBulkUpdateDeltaSubmit={this.handleBulkUpdateDeltaSubmit}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )

        const differences_dialog = this.state.isAdjustmentOpen && (
            <AdjustmentDialog
                open={this.state.isAdjustmentOpen}
                onClose={this.handleCloseDialogs}
                onSubmit={this.handleAdjustmentDialogSubmit}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )

        const locations_dialog = this.state.isLocationsOpen && (
            <LocationsDialog
                open={this.state.isLocationsOpen}
                locations={this.props.locations}
                onClose={this.handleCloseDialogs}
                onDownloadFreeLocationsClick={this.handleDownloadFreeLocationsClick}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )

        const import_warehouse_products_dialog = this.state.isImportWarehouseOpen && (
            <ImportWarehouseStocksDialog
                suppliers={this.props.suppliers}
                closeOnSubmit={true}
                onSearchSuppliers={this.handleSearchSuppliers}
                onClose={this.handleCloseDialogs}
                onProductImportNewSubmit={this.handleProductImportNewSubmit}
                onProductImportConvertFromCrossdockSubmit={this.handleProductImportConvertFromCrossdockSubmit}
                onProductImportExistingSubmit={this.handleProductImportExistingSubmit}
                onFileReaderResult={this.handleFileReaderResult}
                onProductImportPhaseOutSubmit={this.handleProductImportPhaseOutSubmit}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )
        const export_warehouse_products_dialog = this.state.isExportWarehouseOpen && (
            <ExportWarehouseStocksDialog
                closeOnSubmit={true}
                onClose={this.handleCloseDialogs}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )

        const import_vendor_stocks_dialog = this.state.isImportVendorOpen && (
            <ImportVendorStocksDialog
                message={this.props.message}
                onClose={this.handleCloseDialogs}
                onPreview={this.handleImportVendorStocksDialogPreview}
                onSubmit={this.handleImportVendorStocksDialogSubmit}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                requiredRoles={[EXTERNAL_SUPPLY_CHAIN_ROLE]}
            />
        )

        const change_columns_dialog = this.state.isColumnsDialogOpen && (
            <ChangeColumnsDialog
                columns={this.getBaseColumns()}
                currentView={this.state.headerColumns[this.state.source]}
                onColumnsSave={this.handleColumnsSave}
                onClose={this.handleCloseDialogs}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            />
        )

        const confirm_delete_dialog = (
            <Dialog
                onClose={this.handleCloseDialogs}
                open={this.state.isConfirmDeleteOpen}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this product variant? This cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        key="yes"
                        color="primary"
                        onClick={this.handleConfirmDeleteDialogSubmit}
                    >
                        Yes
                    </Button>,
                    <Button
                        key="no"
                        onClick={this.handleConfirmDeleteDialogClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        )

        const sync_status_dialog = (
            <SyncStatusDialog
                onClose={this.handleCloseDialogs}
                isOpen={this.state.isSyncStatusOpen}
                requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                requiredRoles={[EXTERNAL_SUPPLY_CHAIN_ROLE]}
            />
        )

        const header = (
            <div style={style.top}>
                {getAuthorizedElements(this.props.roles, this.props.permissions,
                    <ProductFilters
                        filters={this.getFilterState()}
                        onSearchFieldChange={this.handleSearchFieldChange}
                        onSourceChange={this.handleSourceChange}
                        onSupplierChange={this.handleSupplierChange}
                        onStockViewChange={this.handleStockViewChange}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                    />,
                    <ProductActions
                        roles={this.props.roles}
                        permissions={this.props.permissions}
                        changed={this.state.hasChanges}
                        onSyncStatusClick={this.handleSyncStatusClick}
                        onDifferenceClick={this.handleDifferenceClick}
                        onLocationsClick={this.handleLocationsClick}
                        onBulkUpdateCLick={this.handleBulkUpdateClick}
                        onSaveChangesClick={this.handleSaveChangesClick}
                        onImportWarehouseStocksClick={this.handleImportWarehouseStocksClick}
                        onExportWarehouseStocksClick={this.handleExportWarehouseStocksClick}
                        onImportVendorStocksClick={this.handleImportVendorStocksClick}
                        requiredPermissions={BASE_REQUIRED_PERMISSIONS}
                        requiredRoles={[EXTERNAL_SUPPLY_CHAIN_ROLE]}
                    />
                )}
            </div>)

        const table = (
            <div style={style.table} requiredPermissions={BASE_REQUIRED_PERMISSIONS}>
                <ProductTable
                    shouldRender={this.state.shouldTableRender}
                    page={this.state.page}
                    limit={this.state.limit}
                    sorting={this.state.sorting}
                    headerCols={this.getPersonalHeaderColumns()}
                    product_variants={this.state.editable_variants}
                    stocks={this.props.stocks}
                    updatedRow={this.state.updatedRow}
                    onDeleteVariant={this.handleDeleteVariant}
                    onRowEdit={this.handleRowEdit}
                    onSortChange={this.handleSortChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                    onChangeColumnsClick={this.handleChangeColumnsClick}
                />
            </div>)

        return (
            <div>
                {getAuthorizedElements(this.props.roles, this.props.permissions,
                    header,
                    table,
                    differences_dialog,
                    change_columns_dialog,
                    locations_dialog,
                    bulk_edit_dialog,
                    import_warehouse_products_dialog,
                    export_warehouse_products_dialog,
                    confirm_delete_dialog,
                    import_vendor_stocks_dialog,
                    sync_status_dialog
                )}
            </div>
        )
    }
}

ProductList.propTypes = {
    email: PropTypes.string,
    preferences: PropTypes.object,
    permissions: PropTypes.array,
    variants: PropTypes.array,
    locations: PropTypes.array,
    delta: PropTypes.array,
    message: PropTypes.object,
    suppliers: PropTypes.array,
    stocks: PropTypes.array,
    filters: PropTypes.object,
    logFileReaderResult: PropTypes.func.isRequired,
    searchSuppliers: PropTypes.func.isRequired,
    searchProductVariants: PropTypes.func.isRequired,
    phaseOutProductVariant: PropTypes.func.isRequired,
    uploadNewWarehouseCsv: PropTypes.func.isRequired,
    uploadConvertFromCrossdockCsv: PropTypes.func.isRequired,
    uploadExistingWarehouseCsv: PropTypes.func.isRequired,
    uploadPhaseOutCsv: PropTypes.func.isRequired,
    deltaPurchaseable: PropTypes.func.isRequired,
    updatePurchaseable: PropTypes.func.isRequired,
    updateInventory: PropTypes.func.isRequired,
    deleteProductVariant: PropTypes.func.isRequired,
    deltaBulkUpdate: PropTypes.func.isRequired,
    downloadFreeLocations: PropTypes.func.isRequired,
    adjustInventoryInbound: PropTypes.func.isRequired,
    adjustInventoryOutbound: PropTypes.func.isRequired,
    bulkUpdate: PropTypes.func.isRequired,
}

export default connect(({ variants, suppliers, delta, locations, message, stocks, filters, session }) => {
    return {
        variants,
        stocks,
        delta,
        message,
        locations,
        suppliers,
        filters: filters.variants,
        preferences: !!session.preferences && session.preferences instanceof Object ? session.preferences : {},
    }
}, dispatch => {
    const actions = {
        searchSuppliers,
        searchProductVariants,
        uploadPhaseOutCsv,
        uploadNewWarehouseCsv,
        uploadConvertFromCrossdockCsv,
        uploadExistingWarehouseCsv,
        deltaPurchaseable,
        updatePurchaseable,
        updateInventory,
        deleteProductVariant,
        deltaBulkUpdate,
        downloadFreeLocations,
        adjustInventoryInbound,
        adjustInventoryOutbound,
        bulkUpdate,
        logFileReaderResult,
    }
    return bindActionCreators(actions, dispatch)
})(ProductList)
