let initialFilters = {}
const storageFilters = localStorage.getItem('filters')
if (storageFilters && storageFilters !== 'undefined' && storageFilters !== 'null') {
    try {
        const value = JSON.parse(storageFilters)
        initialFilters = value
    } catch (e) {
        initialFilters = {}
    }
}

export function filters(state = initialFilters, action) {
    switch (action.type) {
        case 'SEARCH_SALES_ORDERS':
            state.sales_orders = action.filters
            break

        case 'SEARCH_RTVS':
            state.rtvs = action.filters
            break

        case 'SEARCH_DEBIT_MEMOS':
            state.debit_memos = action.filters
            break

        case 'SEARCH_TYPED_ORDERS':
            state.typed_orders = action.filters
            state.suppliers = {}
            break

        case 'SEARCH_PURCHASE_REQUISITIONS':
            state.purchase_requisitions = action.filters
            state.suppliers = {}
            break

        case 'SEARCH_SUPPLIERS':
            state.suppliers = action.filters
            break

        case 'SEARCH_PACKING_ITEMS':
            state.packing_item = action.filters // missing s (plural)
            break

        case 'SEARCH_PURCHASE_ORDERS':
            state.purchase_orders = action.filters
            state.suppliers = {}
            break

        case 'SEARCH_PRODUCT_VARIANTS':
            state.variants = action.filters
            break

        default:
            localStorage.setItem('filters', JSON.stringify(state))
            return state
    }

    return state
}

const mapping = {
    DOWNLOAD_PACKING_SLIP: false,
    DOWNLOAD_INVOICE: false,
    DOWNLOAD_WORK_SLIP: false,
    NOTIFICATION_ERROR: false,
    HANDLE_CANCELLATION: true,
    SUBMIT_CANCELLATION: true,
    POSTPONE_SHIPMENT: true,
    MARK_SHIPMENT_AS_SENT: true,
    UPDATE_SHIPMENT_TRACKING: true,
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'REFRESH_SALES_ORDER':
            if ((action.sales_order.fulfillments || []).length === 0) {
                return false
            }
            break

        case 'UPDATE_LINE_ITEM_PACKED_QUANTITY':
        case 'GET_SHIPMENT_CANCELLATIONS':
        case 'CREATE_SESSION':
            return false

        default:
            break
    }

    if (
        action.type.indexOf('SEARCH') !== -1 ||
        action.type.indexOf('ADD') !== -1 ||
        action.type.indexOf('CREATE') !== -1 ||
        action.type.indexOf('UPDATE') !== -1 ||
        action.type.indexOf('GET') !== -1 ||
        action.type.indexOf('GENERATE') !== -1
    ) {
        return true
    }

    if (
        action.type.indexOf('REFRESH') !== -1 ||
        action.type.indexOf('NOTIFICATION_OK') !== -1
    ) {
        return false
    }

    if (mapping.hasOwnProperty(action.type)) {
        return mapping[action.type]
    }

    return state
}
