import store from '../../store'
import _ from 'lodash'
import { getRtvShipments } from '../../commands/sockets/shipments'

function isViewingRtv(rtv_id) {
    const state = store.getState()
    return state.rtv && state.rtv.rtv_id === rtv_id
}

export default function initShipmentSockets(socket) {
    socket.on('inbound-shipment', shipment => {
        store.dispatch({ type: 'REFRESH_INBOUND_SHIPMENT', shipment })
    })

    socket.on('inbound-shipments/search-result', shipments => {
        store.dispatch({ type: 'REFRESH_INBOUND_SHIPMENTS', shipments })
    })

    socket.on('shipment', shipment => {
        store.dispatch({ type: 'REFRESH_SHIPMENT', shipment })
    })

    socket.on('shipment/shippable', shipment => {
        if (shipment.source === 'rtv' && isViewingRtv(shipment.rtv_id)) {
            getRtvShipments(shipment.rtv_id)
        }
    })

    socket.on('shipments/order', shipments => {
        store.dispatch({ type: 'REFRESH_SALES_ORDER_SHIPMENTS', shipments })
    })

    socket.on('shipments/purchase-order', shipments => {
        store.dispatch({ type: 'REFRESH_PURCHASE_ORDER_SHIPMENTS', shipments })
    })

    socket.on('shipments/rma', shipments => {
        store.dispatch({ type: 'REFRESH_RMA_SHIPMENTS', shipments })
    })

    socket.on('shipments/rtv', shipments => {
        store.dispatch({ type: 'REFRESH_RTV_SHIPMENTS', shipments })
    })

    socket.on('shipments/typed/order', shipments => {
        const pendingCancellations = _.some(shipments, s => {
            return _.some(s.cancellations, ['status', 'pending'])
        })
        if (pendingCancellations) {
            store.dispatch({ type: 'GET_TYPED_ORDER_CANCELLATIONS', id: _.sample(shipments).fulfillment_id })
        }
        store.dispatch({ type: 'REFRESH_TYPED_ORDER_SHIPMENTS', shipments })
    })

    socket.on('couriers/search-result', couriers => {
        store.dispatch({ type: 'REFRESH_TRACKING_COURIERS', couriers })
    })
}
