export const Processes = {
    DELIVERY_ORDER: 'delivery-order',
    EXCESS_INVENTORY: 'excess-inventory',
}

export const PickListType = Object.freeze({
    SINGLE_ITEM: 'single',
    MULTI_ITEM: 'multi',
    MIXED: 'mixed',
    COMPLETE_SHIPMENTS: 'complete_shipments',
})
