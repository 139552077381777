export function markShipmentAsDelivered(shipment, user, supplier_id) {
    return { type: 'MARK_SHIPMENT_AS_DELIVERED', shipment, user, supplier_id }
}

export function markTrackingCodeAsSent(tracking_code) {
    return { type: 'MARK_TRACKING_CODE_AS_SENT', tracking_code }
}

export function createShipment(data, author) {
    return { type: 'CREATE_SHIPMENT', data, author }
}

export function postponeShipment(data) {
    return { type: 'POSTPONE_SHIPMENT', data }
}

export function getCouriers(tracking_code, postal_code) {
    return { type: 'GET_TRACKING_COURIERS', data: { tracking_code, postal_code } }
}

export function updateTracking(data) {
    return { type: 'UPDATE_SHIPMENT_TRACKING', data }
}

export function getShipment(data) {
    return { type: 'REFRESH_SHIPMENT', data }
}
