import React from 'react'
import PropTypes from 'prop-types'
import Colors from '../../../../styles/colors'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import DialogContentText from '@material-ui/core/DialogContentText'

import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'

import { setRmaStockCart, setRmaRtvCart, clearRmaProduct, CART_TYPES } from '../../../../../actions/rmas'
import { getRmaProduct } from '../../../../../commands/sockets/rmas'
import RmaCartDialog from '../RmaCartDialog'

const RMA_REJECT_LOCATION = 'RMA-INVESTIGATION'

const VARIANT_FULFILLMENT_SOURCES = Object.freeze({
    WAREHOUSE: 'warehouse',
    CROSSDOCK: 'crossdock',
})

// eslint-disable-next-line no-unused-vars
const style = {}

// Component
// Page to show variant is being fetched
function RmaDetermineDialogPage1(props) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Loading variant...
            </DialogTitle>
            <DialogActions>
                <IconButton
                    key="close"
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}
RmaDetermineDialogPage1.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

// Component
// Page to select rtv/stock cart if not picked before
const RmaDetermineDialogPage2 = RmaCartDialog

// Component
// Page to accept or reject the product
function RmaDetermineDialogPage3(props) {
    const { line_item } = props

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Accept / reject product
            </DialogTitle>
            <DialogContent>
                <h2>{line_item.name}</h2>
                <p>SKU: {line_item.supplier_sku || '-'}</p>
                <p>EAN: {line_item.ean || '-'}</p>

                <DialogContentText id="rma-determine-dialog-page-2-question">
                    {!props.isManualRmaItem
                        ? 'Do you want to accept this product?'
                        : 'Do you want to reject this manually added product?'
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.isManualRmaItem ? null :
                    <IconButton
                        color="secondary"
                        key="accept"
                        onClick={props.onAccept}
                    >Accept <DoneIcon /></IconButton>
                }
                <IconButton
                    key="reject"
                    onClick={props.onReject}
                    style={{ color: Colors.red500 }}
                >
                    Reject <CloseIcon />
                </IconButton>
                <IconButton
                    key="close"
                    onClick={props.onClose}
                    style={{ marginLeft: 'auto' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}
RmaDetermineDialogPage3.propTypes = {
    line_item: PropTypes.object.isRequired,
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isManualRmaItem: PropTypes.bool.isRequired,
}


// Component
// Page to show the instruction where to put the RMA product away in
function RmaDetermineDialogPage4(props) {
    const { line_item_status, line_item } = props

    const isRejected = line_item_status === 'rejected'
    const locationToPutIn = isRejected
        ? `in the location ${RMA_REJECT_LOCATION}`
        : `on the cart ${props.rma_carts[props.typeOfCart]}`

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Put product in cart
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="rma-determine-dialog-page-2-question">
                    Put product {line_item.name} ({line_item.supplier_sku}) {locationToPutIn}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <IconButton
                    color="primary"
                    key="execute"
                    onClick={props.onExecute}
                >
                    Apply <DoneIcon />
                </IconButton>
                <IconButton
                    key="close"
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}
RmaDetermineDialogPage4.propTypes = {
    typeOfCart: PropTypes.string.isRequired,
    line_item: PropTypes.object.isRequired,
    rma_carts: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    line_item_status: PropTypes.string.isRequired,
    onExecute: PropTypes.func.isRequired,
}

// Component
class RmaDetermineDialog extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = { line_item_status: '' }
    }

    componentDidMount() {
        const isManualRmaItem = this.isManualRmaItem()

        if (!isManualRmaItem) {
            this.props.getRmaProduct([
                this.props.line_item.supplier_sku,
                this.props.line_item.ean,
            ])
        }
    }

    componentWillUnmount() {
        this.props.clearRmaProduct()
    }

    handleCartSubmit(cart, typeOfCart) {
        if (cart) {
            switch (typeOfCart) { // eslint-disable-line default-case
                case CART_TYPES.STOCK:
                    this.props.setRmaStockCart(cart)
                    break

                case CART_TYPES.RTV:
                    this.props.setRmaRtvCart(cart)
                    break
            }
        }
    }

    handleClose() {
        if (typeof this.props.onClose === 'function') this.props.onClose()
    }

    handleAcceptCheck() {
        this.setState({ line_item_status: 'accepted' })
    }

    handleRejectCheck() {
        this.setState({ line_item_status: 'rejected' })
    }

    handleExecute() {
        const isManualRmaItem = this.isManualRmaItem()
        const typeOfCart = this.getTypeOfCart()

        const line_item = {
            ...this.props.line_item,
            ...(!isManualRmaItem && { warehouse: typeOfCart === 'stock' }),
        }

        if (this.state.line_item_status === 'accepted' && typeOfCart) {
            this.props.onAccept(line_item, this.props.rma_carts[typeOfCart])
            return
        }

        if (this.state.line_item_status === 'rejected') {
            this.props.onReject(line_item, RMA_REJECT_LOCATION)
            return
        }
    }

    /**
     * Determine the cart type by looking at the fulfillment source of the variant
     * @returns {string} Cart type (rtv|stock)
     */
    getTypeOfCart() {
        const { rma_product } = this.props

        if (rma_product && rma_product.variant && rma_product.variant.fulfillment_source) {
            const isWarehouse = rma_product.variant.fulfillment_source === VARIANT_FULFILLMENT_SOURCES.WAREHOUSE
            return isWarehouse ? CART_TYPES.STOCK : CART_TYPES.RTV
        }

        return CART_TYPES.RTV
    }

    /**
     * In the return-app a customer can manually add products to return.
     * These products don't have a SKU or an EAN.
     * @returns {boolean} Whether the current props.line_item has been manually added to the RMA
     */
    isManualRmaItem() {
        return !this.props.line_item.supplier_sku && !this.props.line_item.ean
    }

    render() {
        const props = this.props

        const isManualRmaItem = this.isManualRmaItem()

        const isProductFetched = Boolean(props.rma_product && props.rma_product.variant)
        if (!isProductFetched && !isManualRmaItem) {
            return <RmaDetermineDialogPage1 {...props} onClose={this.handleClose} />
        }

        const typeOfCart = this.getTypeOfCart()
        const isCartSelected = Boolean(typeOfCart && props.rma_carts && props.rma_carts[typeOfCart])

        const isAcceptedOrRejected = props.line_item &&
            props.line_item.status &&
            props.line_item.status.includes([
                'accepted',
                'rejected',
            ])

        const childProps = {
            ...props,
            typeOfCart,
            onClose: this.handleClose,
        }

        if (isAcceptedOrRejected || this.state.line_item_status) {
            return (
                <RmaDetermineDialogPage4
                    {...childProps}
                    line_item_status={this.state.line_item_status || props.line_item.status}
                    onExecute={this.handleExecute}
                />
            )
        }

        if (!isCartSelected && !isManualRmaItem) {
            return <RmaDetermineDialogPage2 {...childProps} onSubmit={this.handleCartSubmit} />
        }

        return (
            <RmaDetermineDialogPage3
                {...childProps}
                onAccept={this.handleAcceptCheck}
                onReject={this.handleRejectCheck}
                line_item_status={this.state.line_item_status}
                isManualRmaItem={isManualRmaItem}
            />
        )
    }
}

RmaDetermineDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    line_item: PropTypes.object,
    rma_carts: PropTypes.object.isRequired,
    rma_product: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    setRmaStockCart: PropTypes.func.isRequired,
    setRmaRtvCart: PropTypes.func.isRequired,
    getRmaProduct: PropTypes.func.isRequired,
    clearRmaProduct: PropTypes.func.isRequired,
}

RmaDetermineDialog.defaultProps = {
    open: false,
}

export default connect(({ rma_carts, rma_product }) => {
    return { rma_carts, rma_product }
}, dispatch => {
    const actions = {
        setRmaStockCart,
        setRmaRtvCart,
        clearRmaProduct,
        getRmaProduct,
    }
    return bindActionCreators(actions, dispatch)
})(RmaDetermineDialog)
