import store from './../store'
import socket from './../socket'
import listeners from '../listeners/sockets/impressions'

listeners(socket)

export function impression(state = {}, action) {
    switch (action.type) {
        case 'UPLOAD_PERSONALISATION_IMPRESSION':
            const count = store.getState().personalisations.reduce((acc, p) => acc.concat(p.attachments), []).length
            socket.emit('personalisation/upload-impression', {
                ...action.data,
                name: `${action.data.name}_${count + 1}`,
            })
            return state

        case 'GET_PERSONALISATION_IMPRESSION':
            socket.emit('personalisation/download-impression', {filename: action.filename})
            return state

        case 'DOWNLOAD_PERSONALISATION_IMPRESSION':
            return action.data

        default:
            return state
    }
}

