import React from 'react'
import PropTypes from 'prop-types'
import Auth0Lock from 'auth0-lock'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'qs'
import _ from 'lodash'

import { prepareTranslations, translate } from '../utils/translate'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles' // v1.x
import V0MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import ProfortoThemeV0 from './../styles/themes/proforto-base-theme-v0'
import ProfortoThemeV1 from './../styles/themes/proforto-base-theme-v1'
import { QueryClient, QueryClientProvider } from 'react-query'

import Dialog from 'material-ui/Dialog'

import ActionBar from './ActionBar.js'
import ActionDialog from './shared/ActionDialog.js'

import Devices from './../styles/devices'
import Orientations from './../styles/orientations'
import Colors from './../styles/colors'

import * as pricing from './../utils/pricing'
import { createSession, destroySession } from './../../actions/sessions'
import { clearActionDialog } from './../../actions/action_dialog'

const style = {
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        position: 'relative',
        minWidth: '1024px',
    },
    sideBarContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        maxWidth: '100%',
        marginTop: 64,
    },
    contentContainer: {
        borderLeft: '1px solid #E0E0E0',
        display: 'flex',
        flex: '1 1 auto',
        alignSelf: 'flex-start',
        flexFlow: 'row',
        width: '100%',
    },
}

prepareTranslations('nl') // TODO: Language from localStorage value as 'user setting'

/**
 * React query QueryClient
 */
const queryClient = new QueryClient()

class App extends React.Component {

    constructor(props) {
        super(props)
        this.orientationCheck = window.matchMedia('(orientation: landscape)')
        this.desktopCheck = window.matchMedia('(min-width: 1224px)')
        this.tabletCheck = window.matchMedia('(max-width: 1024px)')
        this.orientationCheck.addListener(this.handleLandscapeMatch.bind(this))
        this.desktopCheck.addListener(this.handleDesktopMatch.bind(this))
        this.tabletCheck.addListener(this.handleTabletMatch.bind(this))
        this.state = {
            device: this.tabletCheck.matches ? Devices.TABLET : Devices.DESKTOP,
            orientation: Orientations.LANDSCAPE,
            accessToken: this.getAccessToken(),
            role: localStorage.getItem('authorisation_role'),
            showNotification: false,
            showActionDialog: false,
            render: true,
        }
        this.notification = null
    }
    componentDidMount() {
        const location = this.props.location
        let queryParams = {}
        if (location.pathname === '/') {
            const hash = location.hash || ''
            queryParams = qs.parse(hash.replace('#', ''))
            if (queryParams.access_token) {
                localStorage.setItem('userToken', queryParams.access_token)
            }
        }
        this.lock = new Auth0Lock('UUxeIV2gAnQYeGr8RvmoQvYEzFuKytv1', 'proforto.eu.auth0.com', {
            theme: { logo: '/Avatar-blauw_wit.png', primaryColor: Colors.primaryColor100 },
            disableSignupAction: true,
            rememberLastLogin: true,
            languageDictionary: {
                title: 'Authenticate with Profortool',
            },
            auth: {
                redirect: false,
                /**
                 * Supply a redirectUrl even when redirect=false
                 * Because auth0 uses it to authenticate with the origins
                 * @link https://github.com/auth0/lock/issues/1294
                 */
                redirectUrl: window.location.origin + '/',
                responseType: 'token',
            },
            allowedConnections: ['Username-Password-Authentication', 'google-oauth2'],
        })
        this.lock.on('authenticated', this.initializeSession.bind(this))
        this.initializeSession({ accessToken: this.getAccessToken() })
    }

    initializeSession({ accessToken }) {
        this.setState({
            accessToken,
        }, () => {
            try {
                this.lock.getUserInfo(this.state.accessToken, (error, profile) => {
                    if (error) {
                        console.error(error)
                    }
                    if (error && error.description === 'Too Many Requests') {
                        this.setState({
                            showRateLimitError: true,
                            rateLimitCounter: 30,
                        })
                        setInterval(() => {
                            this.setState({
                                rateLimitCounter: this.state.rateLimitCounter - 1,
                            }, () => {
                                if (this.state.rateLimitCounter === 0) {
                                    window.location.reload()
                                }
                            })
                        }, 1000)
                    }
                    this.props.createSession(error, profile, this.state.accessToken)
                })
            } catch (e) {
                this.handleSessionDestroyed(this.props)
            }
        })
    }

    handleLandscapeMatch(mediaQueryList) {
        if (mediaQueryList.matches && this.state.orientation !== Orientations.LANDSCAPE) {
            this.setState({
                orientation: Orientations.LANDSCAPE,
            })
        } else if (!mediaQueryList.matches && this.state.orientation === Orientations.LANDSCAPE) {
            this.setState({
                orientation: Orientations.PORTRAIT,
            })
        }
    }

    handleDesktopMatch(mediaQueryList) {
        if (mediaQueryList.matches && this.state.device !== Devices.DESKTOP) {
            this.setState({
                device: Devices.DESKTOP,
            })
        }
    }

    handleTabletMatch(mediaQueryList) {
        if (mediaQueryList.matches && this.state.device !== Devices.TABLET) {
            this.setState({
                device: Devices.TABLET,
            })
        }
    }

    componentWillUnmount() {
        this.orientationCheck.removeListener(this.handleLandscapeMatch.bind(this))
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {}
        if (nextProps.notification !== prevState.notification) {
            if (nextProps.notification.message === '') {
                this.notification = null

                newState = {
                    ...newState,
                    showNotification: false,
                }
            } else {
                newState = {
                    ...newState,
                    showNotification: true,
                }
            }
        }
        if (!_.isEqual(nextProps.action_dialog, prevState.action_dialog)) {
            if (nextProps.action_dialog.title) {
                newState = {
                    ...newState,
                    showActionDialog: true,
                }
            }
        }

        return newState
    }

    componentDidUpdate(prevProps) {
        if (!this.props.session.token && this.props.location.pathname !== '/') {
            this.handleSessionDestroyed(this.props)
        }
        if (prevProps.session !== this.props.session) {
            this.redirect(this.props.session)
        }
    }

    handleSessionDestroyed(props) {
        if (props.session.token) {
            this.props.destroySession()
        }
        this.setState({
            role: null, accessToken: null,
        }, () => {
            this.context.router.push('/')
        })
    }

    handleActionDialogClose() {
        this.props.clearActionDialog()

        this.setState({
            showActionDialog: false,
        })
    }


    getAccessToken() {
        return localStorage.getItem('userToken')
    }

    getChildContext() {
        return {
            colors: Colors,
            email: this.props.session.metadata.email,
            // FIXME: #1487 Refactor app to not use role anymore
            role: this.props.session.metadata.role,
            roles: this.props.session.metadata.roles,
            orientation: this.state.orientation,
            device: this.state.device,
            features: this.props.session.features || {},
            translate,
            utils: {
                pricing,
            },
        }
    }

    redirect(ses) {
        if (ses.token) {
            this.lock.hide()
            const referral = localStorage.getItem('referral')
            if (referral) {
                if (referral !== window.location.pathname) {
                    this.context.router.push(referral)
                }
                localStorage.removeItem('referral')
            } else if (this.props.location.pathname === '/' || !this.props.location.pathname) {
                let value = '/sales/sales-orders'

                switch (ses.role) {
                    case 'supplier':
                        value = '/sales/shipments/'
                        break
                    case 'manufacturer':
                        value = '/sales/sales-orders/personalisation'
                        break
                    case 'purchase':
                        value = '/purchases/purchase-orders'
                        break
                    default:
                        break
                }

                this.context.router.push(value)
            }
        }
    }

    showNotification() {
        this.setState({
            showNotification: true,
        })
    }

    hideNotification() {
        this.setState({
            showNotification: false,
        }, () => {
            this.notification = null
        })
    }


    render() {
        if (!this.state.render) {
            return null
        }

        let handler = null
        if (this.props.children) {
            const componentName = this.props.children.type.displayName

            handler = React.cloneElement(this.props.children, {
                onSessionCreated: componentName === 'Home' ? this.props.createSession : null,
                // FIXME: #1487 Refactor app to not use role anymore
                role: this.props.session.metadata.role,
                roles: this.props.session.metadata.roles,
                permissions: this.props.session.permissions,
                features: this.props.session.features || {},
                lock: componentName === 'Home' ? this.lock : null,
                accessToken: this.state.accessToken ? this.state.accessToken : null,
                supplier_id: this.props.session.metadata.mage_id || null,
                email: this.props.session.metadata.email,
            })
        }

        return (
            <MuiThemeProvider theme={createMuiTheme(ProfortoThemeV1)}>
                <V0MuiThemeProvider muiTheme={getMuiTheme(ProfortoThemeV0)}>
                    <QueryClientProvider client={queryClient}>
                        <div id="profortool" style={style.rootContainer}>
                            <ActionBar
                                authenticated={!!this.props.session.token || this.props.session.permissions.length > 0}
                                location={this.props.location}
                                // FIXME: #1487 Refactor app to not use role anymore
                                role={this.props.session.metadata.role}
                                roles={this.props.session.metadata.roles}
                                onSessionDestroy={this.handleSessionDestroyed.bind(this)}
                                permissions={this.props.session.permissions}
                                features={this.props.session.metadata.features || {}}
                            />
                            <div style={style.sideBarContentContainer}>
                                <div style={style.contentContainer}>
                                    {handler}
                                    {this.notification}
                                </div>
                            </div>
                            <Dialog
                                open={this.state.showRateLimitError}
                                title="Too many requests"
                            >
                                De login module heeft te veel requests ontvangen. De pagina zal
                                over {this.state.rateLimitCounter} seconden automatisch herladen.
                            </Dialog>

                            <ActionDialog
                                isOpen={this.state.showActionDialog}
                                onClose={this.handleActionDialogClose.bind(this)}
                                messagePayload={this.props.action_dialog}
                            />
                        </div>
                    </QueryClientProvider>
                </V0MuiThemeProvider>
            </MuiThemeProvider>
        )
    }
}

App.propTypes = {
    action_dialog: PropTypes.object,
    location: PropTypes.object,
    notification: PropTypes.object,
    children: PropTypes.object,
    session: PropTypes.object,
    createSession: PropTypes.func,
    destroySession: PropTypes.func,
    hideNotification: PropTypes.func,
    clearActionDialog: PropTypes.func,
}
App.childContextTypes = {
    colors: PropTypes.object,
    translate: PropTypes.func,
    email: PropTypes.string,
    // FIXME: #1487 Refactor app to not use role anymore
    role: PropTypes.string,
    roles: PropTypes.array,
    features: PropTypes.object,
    muiTheme: PropTypes.object,
    utils: PropTypes.object,
    orientation: PropTypes.oneOf([Orientations.LANDSCAPE, Orientations.PORTRAIT]),
    device: PropTypes.oneOf([Devices.RETINA, Devices.DESKTOP, Devices.TABLET, Devices.PHONE]),
    router: PropTypes.object,
}
App.contextTypes = { router: PropTypes.object }

export default connect(({ action_dialog, notification, session }) => {
    return {
        action_dialog,
        notification,
        session,
    }
}, dispatch => {
    const actions = {
        createSession, destroySession, clearActionDialog,
    }
    return bindActionCreators(actions, dispatch)
})(App)
