import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'

const avatarDarkening = 800
const style = {
    lists: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    list: {
        width: '50%',
    },
    restockAvatar: { backgroundColor: blue[avatarDarkening]},
    rtvAvatar: { backgroundColor: green[avatarDarkening]},
}

function SortingList(props) {
    return (
        <List style={style.list}>
            <ListSubheader disableSticky={true} disableGutters={true}>
                {props.header}
            </ListSubheader>
            {props.items.map((li, index) => (
                <ListItem key={`${li.supplier_sku}.${index}`} disableGutters={true}>
                    <ListItemAvatar>
                        <Avatar style={props.avatarStyle}>
                            {li.quantity}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={li.name}
                        secondary={
                            <span>
                                {li.supplier_sku}<br/>
                                {li.ean}
                            </span>
                        }
                    />
                </ListItem>
            ))}
        </List>
    )
}
SortingList.propTypes = {
    header: PropTypes.string.isRequired,
    avatarStyle: PropTypes.object.isRequired,
    items: PropTypes.array,
}
SortingList.defaultProps = {
    items: [],
}


function AcceptedItemsSorting(props) {
    if (props.stock_items.length === 0 && props.rtv_items.length === 0) {
        return null
    }
    return (
        <Card>
            <CardContent>
                <Typography component="h6" variant="h6">Accepted items</Typography>
                <div style={style.lists}>
                    <SortingList
                        header="Restock"
                        items={props.stock_items}
                        avatarStyle={style.restockAvatar}
                    />
                    <SortingList
                        header="RTV"
                        items={props.rtv_items}
                        avatarStyle={style.rtvAvatar}
                    />
                </div>
            </CardContent>
        </Card>
    )
}

AcceptedItemsSorting.propTypes = {
    style: PropTypes.object,
    stock_items: PropTypes.array,
    rtv_items: PropTypes.array,

}
AcceptedItemsSorting.defaultProps = {
    stock_items: [],
    rtv_items: [],
}

export default AcceptedItemsSorting
