import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    CardHeader,
    Table,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableBody,
    TableRowColumn,
    TableFooter,
    Paper,
} from 'material-ui'

const style = {
    footerRowText: {
        textAlign: 'right',
        fontWeight: 'bold',
    },
}

class LineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {display} = this.context.utils.pricing
        const Parent = this.props.parent || Paper
        const content = (
            <Table
                bodyStyle={{maxHeight: window.innerHeight}}
                selectable={false}
            >
                <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn width="30%">Name</TableHeaderColumn>
                        <TableHeaderColumn width="15%">Article Number</TableHeaderColumn>
                        <TableHeaderColumn width="10%">EAN</TableHeaderColumn>
                        <TableHeaderColumn width="10%">Base Price</TableHeaderColumn>
                        <TableHeaderColumn width="10%">Discount</TableHeaderColumn>
                        <TableHeaderColumn width="5%">Quantity</TableHeaderColumn>
                        <TableHeaderColumn width="10%">Row Subtotal</TableHeaderColumn>
                        <TableHeaderColumn width="10%">Row Total</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {
                        this.props.items.map((line_item, index) => {
                            return (
                                <TableRow key={`${line_item.sku}.${index}`}>
                                    <TableRowColumn width="30%">{line_item.name}</TableRowColumn>
                                    <TableRowColumn width="15%">{line_item.sku}</TableRowColumn>
                                    <TableRowColumn width="10%">{line_item.ean}</TableRowColumn>
                                    <TableRowColumn width="10%">
                                        {display(line_item.base_price)}
                                    </TableRowColumn>
                                    <TableRowColumn width="10%">
                                        {display(line_item.base_discount)}
                                    </TableRowColumn>
                                    <TableRowColumn width="5%">{line_item.quantity}</TableRowColumn>
                                    <TableRowColumn width="10%">{display(line_item.row_total_excl_tax)}</TableRowColumn>
                                    <TableRowColumn width="10%">{display(line_item.row_total_incl_tax)}</TableRowColumn>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                <TableFooter adjustForCheckbox={false}>
                    <TableRow>
                        <TableRowColumn width="90%" colSpan="6" style={style.footerRowText}>
                            Subtotal
                        </TableRowColumn>
                        <TableRowColumn width="10%">
                            {display(this.props.total_pricing.subtotal)}
                        </TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn width="90%" colSpan="6" style={style.footerRowText}>
                            Tax
                        </TableRowColumn>
                        <TableRowColumn width="10%">
                            {display(this.props.total_pricing.tax)}
                        </TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn width="90%" colSpan="6" style={style.footerRowText}>
                            Totaal
                        </TableRowColumn>
                        <TableRowColumn width="10%">
                            {display(this.props.total_pricing.total)}
                        </TableRowColumn>
                    </TableRow>
                </TableFooter>
            </Table>
        )
        return (
            <Parent style={this.props.style}>
                {!this.props.showTitle ? null : (
                    <CardHeader
                        titleColor="rgba(0, 0, 0, 0.7)"
                        titleStyle={{fontFamily: 'Roboto'}}
                        title="Line items"
                    />
                )}
                {content}
            </Parent>
        )
    }
}

LineItems.propTypes = {
    showTitle: PropTypes.bool,
    parent: PropTypes.node,
    style: PropTypes.object,
    items: PropTypes.array,
    total_pricing: PropTypes.object,
}

LineItems.defaultProps = {
    showTitle: false,
    items: [],
}

LineItems.contextTypes = {
    utils: PropTypes.object,
}

export default LineItems
