import React from 'react'
import PropTypes from 'prop-types'

const style = {
    header: {
        width: '90%',
        display: 'block',
        margin: 30,
    },
    title: {
        textTransform: 'capitalize',
    },
}

class Title extends React.Component {

    render() {
        return (
            <header style={this.props.style || style.header}>
                <h2 style={style.title}>{this.props.title}</h2>
                <h4 style={style.title}>{this.props.subtitle}</h4>
            </header>
        )
    }
}

Title.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
}

export default Title
