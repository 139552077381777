import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { searchDeliveryOrders } from '../../../../../commands/sockets/delivery_orders'

import DeliveryOrderFilters from './DeliveryOrderFilters'
import DeliveryOrderTable from './DeliveryOrderTable'

import { DeliveryOrderStatuses, DeliveryTypes } from '../../../../types/logistics/delivery-orders'
import { FulfillmentSources } from '../../../../types/logistics/fulfillment'

const styles = {
    container: {
        width: '100%',
    },
}

class DeliveryOrderList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            shouldRender: false,
            order_ref: '',
            statuses: [
                DeliveryOrderStatuses.OPEN, DeliveryOrderStatuses.PARTIALLY_FULFILLED, DeliveryOrderStatuses.FULFILLED,
            ],
            fulfillment_sources: [FulfillmentSources.PERSONALISATION],
            delivery_to: [DeliveryTypes.MANUFACTURER],
            page: 1,
            limit: 20,
        }
        this.timeout = 0

        autoBind(this)
    }

    componentDidMount() {
        this.handleSearchDeliveryOrders()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.shouldRender === false && nextProps.delivery_orders !== prevState.delivery_orders) {
            return {
                shouldRender: true,
            }
        }
        return null
    }

    getFilters() {
        const { order_ref, increment_id, sku, statuses, fulfillment_sources, delivery_to, limit, page } = this.state
        const filters = {
            order_ref,
            sku,
            limit,
            page,
            statuses,
            fulfillment_sources,
            delivery_to,
        }
        if (increment_id) {
            filters.increment_id = +increment_id
        }
        return filters
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.handleSearchDeliveryOrders, 500)
    }

    handleSearchDeliveryOrders() {
        const filters = this.getFilters()
        this.props.searchDeliveryOrders(filters)
    }

    handlePageDecrement() {
        this.setState({
            shouldRender: false,
            page: this.state.page - 1,
        }, this.handleSearchDeliveryOrders)
    }

    handlePageIncrement() {
        this.setState({
            shouldRender: false,
            page: this.state.page + 1,
        }, this.handleSearchDeliveryOrders)
    }

    handleLimitChange(limit) {
        this.setState({
            shouldRender: false,
            limit,
        }, this.handleSearchDeliveryOrders)
    }

    handleTextFieldChange(value, prop) {
        const state = { shouldRender: false }
        state[prop] = value
        this.setState(state, this.setSearchTimeout)
    }

    handleSelectChange(value, prop) {
        const state = { shouldRender: false }
        state[prop] = value
        this.setState(state, this.handleSearchDeliveryOrders)
    }

    handleDeliveryOrderClick(delivery_order) {
        this.context.router.push(`/logistics/outbound/delivery-orders/${delivery_order.increment_id}`)
    }

    render() {
        return (
            <div style={styles.container}>
                <DeliveryOrderFilters
                    filters={this.getFilters()}
                    onTextFieldChange={this.handleTextFieldChange}
                    onSelectChange={this.handleSelectChange}
                />
                <DeliveryOrderTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.page - 1}
                    limit={this.state.limit}
                    delivery_orders={this.props.delivery_orders}
                    onDeliveryOrderClick={this.handleDeliveryOrderClick}
                    onLimitChange={this.handleLimitChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
            </div>
        )
    }
}

DeliveryOrderList.contextTypes = {
    router: PropTypes.object.isRequired,
}
DeliveryOrderList.propTypes = {
    delivery_orders: PropTypes.array.isRequired,
    searchDeliveryOrders: PropTypes.func.isRequired,
}

DeliveryOrderList.defaultProps = {
    delivery_orders: [],
}

export default connect(({ delivery_orders }) => {
    return {
        delivery_orders,
    }
}, dispatch => {
    return bindActionCreators({ searchDeliveryOrders }, dispatch)
})(DeliveryOrderList)

