import socket from './../socket'
import listeners from '../listeners/sockets/typed_orders.js'
import _ from 'lodash'

listeners(socket)

export function typed_orders(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            state = []
            return state

        case 'SEARCH_TYPED_ORDERS':
            socket.emit('orders/typed/supplier/search', action.filters)
            return state

        case 'REFRESH_TYPED_ORDERS':
            return action.typed_orders

        default:
            return state
    }
}

export function typed_order(state = {}, action) {
    switch (action.type) {
        case 'GET_TYPED_ORDER':
            socket.emit('order/typed/get', {
                order_id: action.order_id,
                source: action.source,
                supplier_id: action.supplier_id,
            })
            return state

        case 'REFRESH_TYPED_ORDER':
            const order = action.typed_order
            return {
                _id: order._id,
                mage_id: order.mage_id,
                customer: _.merge(order.customer, {
                    billing_address: order.billing_address,
                    shipping_address: _.last(order.shipping_address),
                }),
            }

        default:
            return state
    }
}
