export function searchPurchaseOrders(filters) {
    return {type: 'SEARCH_PURCHASE_ORDERS', filters}
}

export function refreshPurchaseOrders(purchase_orders) {
    return {type: 'REFRESH_PURCHASE_ORDERS', purchase_orders}
}

export function getPurchaseOrder(_id) {
    return {type: 'GET_PURCHASE_ORDER', _id}
}

export function addNote(_id, note) {
    return {type: 'ADD_PURCHASE_ORDER_NOTE', data: {_id, note}}
}

export function createPurchaseOrder(purchase_order) {
    return {type: 'CREATE_PURCHASE_ORDER', purchase_order}
}

export function refreshPurchaseOrder(purchase_order) {
    return {type: 'REFRESH_PURCHASE_ORDER', purchase_order}
}

export function markAsSubmitted(_id) {
    return {type: 'MARK_PURCHASE_ORDER_AS_SUBMITTED', _id}
}

export function getPurchaseOrderAsPdf(_id) {
    return {type: 'GET_PURCHASE_ORDER_AS_PDF', _id}
}

export function addItem(_id, line_item) {
    return {type: 'ADD_PURCHASE_ORDER_ITEM', data: {_id, line_item}}
}

export function removeItems(_id, line_items) {
    return {type: 'REMOVE_PURCHASE_ORDER_ITEMS', data: {_id, line_items}}
}

export function updateItems(_id, line_items) {
    return {type: 'UPDATE_PURCHASE_ORDER_ITEMS', data: {_id, line_items}}
}
