import socket from './../../socket'
import {getUserEmail} from '../../store'

export function getSalesOrder(id) {
    socket.emit('order/get', { order_id: id })
    return {type: 'GET_SALES_ORDER', id}
}

export function getSalesOrderByMageId(id) {
    socket.emit('order/get', { mage_id: id })
    return {type: 'GET_SALES_ORDER_BY_MAGE_ID', id}
}

export function updateCustomerOrderReference(order_id, reference) {
    socket.emit('order/update/customer_reference', { _id: order_id, reference})
    return {type: 'UPDATE_CUSTOMER_ORDER_REFERENCE', order_id, reference}
}

export function updateSalesRepresentative(order_id, sales_representative) {
    socket.emit('order/update/sales_rep', { _id: order_id, sales_representative})
    return {type: 'UPDATE_SALES_REPRESENTATIVE', order_id, sales_representative}
}

export function updateEmail(order_id, email) {
    socket.emit('order/update/email', { _id: order_id, email})
    return {type: 'UPDATE_EMAIL', order_id, email}
}

export function addNote(order_id, note) {
    socket.emit('note/new', { _id: order_id, note})
    return {type: 'ADD_SALES_ORDER_NOTE', order_id, note}
}

export function updateNote(order_id, note, index) {
    socket.emit('note/update', { _id: order_id, note, index})
    return {type: 'UPDATE_SALES_ORDER_NOTE', order_id, note, index}
}

export function archiveNote(order_id, note, index) {
    socket.emit('note/archive', { _id: order_id, note, index})
    return {type: 'ARCHIVE_SALES_ORDER_NOTE', order_id, note, index}
}

export function addInstruction(order_id, mage_id, instruction) {
    socket.emit('instruction/new', {_id: order_id, mage_id, instruction})
    return {type: 'ADD_SALES_ORDER_INSTRUCTION', order_id, mage_id, instruction}
}

export function updateBillingAddress(order_id, address) {
    socket.emit('order/put/billing_address', { _id: order_id, billing_address: address })
    return {type: 'UPDATE_SALES_ORDER_BILLING_ADDRESS', order_id, address}
}

export function updateShippingAddress(order_id, address) {
    socket.emit('order/put/shipping_address', { _id: order_id, shipping_address: address })
    return {type: 'UPDATE_SALES_ORDER_SHIPPING_ADDRESS', order_id, address}
}

export function refreshSalesOrder(sales_order) {
    return {type: 'REFRESH_SALES_ORDER', sales_order}
}

export function markAsCancelled(order_id) {
    socket.emit('order/unmanaged/cancel', { order_id })
    return {type: 'MARK_ORDER_AS_CANCELLED', order_id}
}

export function markAsCompleted(order_id) {
    socket.emit('order/unmanaged/complete', { order_id })
    return {type: 'MARK_ORDER_AS_COMPLETED', order_id}
}

export function forceFulfillment(id) {
    socket.emit('order/unmanaged/force-fulfillment', id, getUserEmail())
    return {type: 'START_LOADING'}
}
