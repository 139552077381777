import React, {Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import {get} from 'lodash'

import {Divider, Paper, Button} from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'

import ShippingInfo from './pack-from-compartment/ShippingInfo'
import PackingLineItems from './PackingLineItems'
import Instructions from './../../../shared/Instructions'
import ChannelAvatar from './../../../shared/ChannelAvatar'

const styles = {
    subheader: {
        fontSize: 24,
    },
    heading: {
        display: 'flex',
        width: '25%',
        padding: '10px',
        margin: 'auto',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    channel: {
        display: 'inline-block',
    },
    content: {
        display: 'flex', flexFlow: 'row', width: '100%', borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc',
    },
    table: {
        width: '55%',
        margin: '16px 0 16px 16px',
        paddingRight: 16,
        borderRight: '1px solid #ccc',
        overflowY: 'scroll',
        maxHeight: window.innerHeight * 0.6,
    },
    compartment: {
        width: '25%', display: 'table',
    },
    compartmentText: {
        fontSize: 64,
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'table-cell',
    },
    info: {
        width: '20%', margin: '16px 0px 16px 0px', paddingRight: 16, borderRight: '1px solid #ccc',
    },
    instructions: {
        borderBottom: '1px solid #ccc',
    },
    actions: {
        padding: 12,
    },
    packingInfoWrapper: {
        paddingLeft: 8,
        height: 32,
    },
    packingInfoIcon: {
        float: 'left',
        display: 'block',
    },
    packingInfo: {
        width: '95%',
        marginLeft: 20,
        float: 'left',
        fontStyle: 'italic',
        marginTop: 2,
    },
}

const table_row_height = 40
const base_height = 370
// If amount of table body rows gets bigger than 5, the content needs to grow in height
const row_calculation_offset = -5

class PackFromCompartment extends Component {

    constructor(props) {
        super(props)
        this.state = {}

        autoBind(this)
    }

    handleRaisedButtonClick() {
        return this.props.onStartProcessingClick && this.props.onStartProcessingClick()
    }

    handleDownloadPackingSlipClick(e) {
        if (e && e.target) {
            e.target.blur()
        }
        return this.props.onDownloadPackingSlipClick && this.props.onDownloadPackingSlipClick()
    }

    handlePrintPackingSlipClick(e) {
        if (e && e.target) {
            e.target.blur()
        }
        return this.props.onPrintPackingSlipClick && this.props.onPrintPackingSlipClick()
    }

    handlePrintLabelClick(e) {
        if (e && e.target) {
            e.target.blur()
        }
        return this.props.onPrintLabelClick && this.props.onPrintLabelClick()
    }

    handleLineItemPackedQuantityChange(value, index) {
        return this.props.onLineItemPackedQuantityChange && this.props.onLineItemPackedQuantityChange(value, index)
    }

    handlePromoLineItemPackedQuantityChange(value, index) {
        return this.props.onPromoLineItemPackedQuantityChange
            && this.props.onPromoLineItemPackedQuantityChange(value, index)
    }

    render() {
        const translate = this.context.translate
        const line_items = get(this.props, 'packing_item.line_items', [])
        const height = base_height + (Math.max(row_calculation_offset + line_items.length, 0) * table_row_height)

        return (
            <Paper>
                <div style={styles.heading}>
                    <ChannelAvatar
                        style={styles.channel}
                        slug={get(this.props, 'packing_item.channel.slug', '')}
                        size="large"
                    />
                    <h1>
                        {get(this.props, 'packing_item.mage_id', '')}
                    </h1>
                </div>
                <div style={{...styles.content, height}}>
                    <ShippingInfo
                        style={styles.info}
                        headerStyle={styles.subheader}
                        packing_item={this.props.packing_item}
                        shipment={this.props.shipment}
                    />
                    <PackingLineItems
                        style={styles.table}
                        line_items={line_items}
                        promo_items={this.props.promo_items}
                        onEditableQuantityChange={this.handleLineItemPackedQuantityChange}
                        onEditablePromoQuantityChange={this.handlePromoLineItemPackedQuantityChange}
                        editable={{
                            label: 'Packed',
                            property: 'packed_quantity',
                        }}
                    />
                    <div style={{...styles.compartment, height}}>
                        <div style={styles.compartmentText}>
                            {translate('Compartment')}:<br/>
                            {this.props.compartment || '-'}
                        </div>
                    </div>
                </div>
                {
                    get(this.props, 'packing_item.instructions', []).length === 0 ? null : (
                        <Instructions
                            style={styles.instructions}
                            instructions={this.props.packing_item.instructions}
                            canAdd={false}
                            title={translate('Packing instructions')}
                            wrap={false}
                        />
                    )
                }
                <Divider />
                <div style={{...styles.actions, height: this.props.packing_item.processing ? 'auto' : 'inherit'}}>
                    {this.props.packing_item.processing ? (
                        <div style={styles.packingInfoWrapper}>
                            <InfoIcon style={styles.packingInfoIcon} color="primary" />
                            <div style={styles.packingInfo}>
                                <ol>
                                    <li>
                                        {translate('Get the correct packaging for the items ' +
                                            '(pay attention to what kind of packaging you have to pack).')}
                                    </li>
                                    <li>
                                        {translate('Scan the articles one by one (or change the packed quantity ' +
                                            'per line item manually in the view above if the article is unscannable) ' +
                                            'and put them in the package.')}
                                    </li>
                                    <li>
                                        {translate('Get the packing slip from the printer, put the shipping label on ' +
                                            'the package and put the packing slip inside the package (In case the ' +
                                            'packing slip did not print automatically, you can do it manually via' +
                                            ' \'Download packing slip\').')}
                                    </li>
                                    <li>
                                        {translate('Scan the shipping label on the ' +
                                            'shipment to checkout this shipment.')}
                                    </li>

                                </ol>
                            </div>
                        </div>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleRaisedButtonClick}
                        >
                            {translate('Start packing process of this shipment')}
                        </Button>
                    )}
                    {!this.props.packing_item.processing ? null : (
                        [
                            <Button
                                key="download"
                                color="primary"
                                title={this.props.packing_item.printed
                                    ? translate('The packing slip has already been printed')
                                    : ''}
                                disabled={this.props.packing_item.printed}
                                onClick={this.handleDownloadPackingSlipClick}
                            >
                                {translate('Download packing slip')}
                            </Button>,
                            <Button
                                key="print-packing-slip"
                                color="primary"
                                onClick={this.handlePrintPackingSlipClick}
                            >
                                {translate('Print packing slip')}
                            </Button>,
                            <Button
                                key="print-label"
                                color="primary"
                                onClick={this.handlePrintLabelClick}
                            >
                                {translate('Print label')}
                            </Button>,
                        ]
                    )}
                </div>
            </Paper>
        )
    }
}

PackFromCompartment.contextTypes = {
    colors: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
}
PackFromCompartment.propTypes = {
    ready: PropTypes.bool,
    compartment: PropTypes.string,
    packing_item: PropTypes.object,
    promo_items: PropTypes.array,
    shipment: PropTypes.object,
    onStartProcessingClick: PropTypes.func,
    onDownloadPackingSlipClick: PropTypes.func,
    onPrintLabelClick: PropTypes.func,
    onPrintPackingSlipClick: PropTypes.func,
    onLineItemPackedQuantityChange: PropTypes.func,
    onPromoLineItemPackedQuantityChange: PropTypes.func,
}

export default PackFromCompartment
