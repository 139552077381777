import socket from '../../socket'
import { getUserEmail } from '../../store'

export function getFeatureByName(featureName) {
    socket.emit('settings/get-feature', featureName)
    return { type: 'GET_FEATURE_BY_NAME', featureName }
}

export function getFeatures() {
    socket.emit('settings/get-features', '')
    return { type: 'GET_FEATURES' }
}

export function changeFeatureStatus(name, newValue) {
    socket.emit('settings/change-feature', { name, newValue }, getUserEmail())
    return { type: 'CHANGE_FEATURE', name, newValue }
}
