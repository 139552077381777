import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    getSalesOrder,
    addNote,
    updateNote,
    archiveNote,
    addInstruction,
    markAsCancelled,
    markAsCompleted,
    forceFulfillment,
} from './../../../../commands/sockets/sales_orders'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import DomainIcon from '@material-ui/icons/Domain'
import ListIcon from '@material-ui/icons/List'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PaymentIcon from '@material-ui/icons/Payment'
import PhotoIcon from '@material-ui/icons/Photo'
import UndoIcon from '@material-ui/icons/Undo'
import {
    Card,
    CardHeader,
    CardContent,
} from '@material-ui/core'

import Header from '../../shared/Header'
import Customer from './../Customer'
import ChannelAvatar from './../../shared/ChannelAvatar'
import Notes from '../../shared/Notes'
import Instructions from './../../shared/Instructions'
import Payment from './../../shared/Payment'
import Outbound from './order-detail/Outbound'
import Personalisations from './order-detail/Personalisations'
import Returns from './order-detail/Returns'
import Inbound from './order-detail/Inbound'
import Memos from './order-detail/Memos'
import LineItems from './order-detail/LineItems'
import ActionLogDialog from './../ActionLogDialog'
import ForceFulfillmentConfirmationDialog from './order-detail/ForceFulfillmentConfirmationDialog'

import Colors from './../../../styles/colors'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    content: {
        display: 'flex',
        flexFlow: 'row',
    },
    left: {
        width: '25%',
        minWidth: 350,
        marginRight: 12,
    },
    instructions: {
        marginTop: 36,
        clear: 'both',
    },
    information: {
        margin: '36px 0px 36px 0px',
        clear: 'both',
    },
    tabs: {
        width: '75%',
        display: 'flex',
        flexFlow: 'column',
        marginTop: 12,
        justifyContent: 'flex-start',
    },
    rightButtonMargin: {
        float: 'right',
        marginRight: 20,
    },
    rightButtonMarginLabel: {
        color: 'white',
    },
}

function TabContainer(props) {
    return (
        <Typography component="div" style={style.tabContainer}>
            {props.children}
        </Typography>
    )
}

TabContainer.propTypes = { children: PropTypes.node }

class OrderDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logsOpen: false,
            forceFulfillmentConfirmOpen: false,
            activeTab: 'outbound',
        }

        this.handleForceFulfillmentConfirmationDialogClose =
            this.handleForceFulfillmentConfirmationDialogClose.bind(this)
        this.handleForceFulfillmentConfirmationDialogSubmit =
            this.handleForceFulfillmentConfirmationDialogSubmit.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
    }

    componentDidMount() {
        this.props.getSalesOrder(this.props.params.id)
    }

    handleViewLogClick() {
        this.setState({ logsOpen: true })
    }

    handleCancelClick() {
        this.props.markAsCancelled(this.props.sales_order._id)
    }

    handleCompleteClick() {
        this.props.markAsCompleted(this.props.sales_order._id)
    }

    handleForceFulfillmentClick() {
        this.setState({
            forceFulfillmentConfirmOpen: true,
        })
    }

    handleForceFulfillmentConfirmationDialogClose() {
        this.setState({
            forceFulfillmentConfirmOpen: false,
        })
    }

    handleForceFulfillmentConfirmationDialogSubmit() {
        this.props.forceFulfillment(this.props.sales_order._id)
    }


    handleTabChange(e, activeTab) {
        this.setState({ activeTab })
    }

    handleAddNote(note) {
        this.props.addNote(this.props.sales_order._id, {
            author: this.props.email,
            value: note,
            date: new Date().toISOString(),
        })
    }

    handleUpdateNote(note, index) {
        this.props.updateNote(this.props.sales_order._id, {
            ...note,
            updated_at: new Date().toISOString(),
        }, index)
    }

    handleArchiveNote(note, index) {
        this.props.archiveNote(this.props.sales_order._id, note, index)
    }

    isInFulfillment(order) {
        return order.status !== 'pending' && order.status !== 'unprocessed'
    }

    getHeaderButtons(order) {
        const rightButtons = [
            <Button
                key="view_log"
                style={{ float: 'right' }}
                color="primary"
                variant="contained"
                onClick={this.handleViewLogClick.bind(this)}
            >
                View action log
            </Button>,
        ]

        if (!this.isInFulfillment(order)) {
            const features = this.props.features || {}
            if (features.canForceFulfillment) {
                rightButtons.push(
                    <Button
                        key="force_fulfillment"
                        style={{ ...style.rightButtonMargin }}
                        color="primary"
                        variant="contained"
                        onClick={this.handleForceFulfillmentClick.bind(this)}
                    >
                        Force fulfillment
                    </Button>
                )
            }
            rightButtons.push(
                <Button
                    key="cancel_order"
                    style={{ ...style.rightButtonMargin, backgroundColor: Colors.red600, color: 'white' }}
                    variant="contained"
                    onClick={this.handleCancelClick.bind(this)}
                >
                    Cancel order
                </Button>
            )
        }
        if (order.status === 'unprocessed') {
            rightButtons.push(
                <Button
                    key="mark_as_complete"
                    style={{ ...style.rightButtonMargin }}
                    color="secondary"
                    variant="contained"
                    onClick={this.handleCompleteClick.bind(this)}
                >
                    Mark as complete
                </Button>
            )
        }
        return rightButtons
    }

    getPersonalisationTab(personalised, instructions) {
        const typed_orders = this.props.typed_orders.filter(to => to.type === 'personalisation')
        return !personalised ? null : (
            <Tab
                icon={<PhotoIcon />}
                label={`Personalisations (${typed_orders.length})`}
                value="personalisations"
            >
                <Personalisations
                    typed_orders={typed_orders}
                    instructions={instructions}
                    personalisations={this.props.personalisations}
                />
            </Tab>
        )
    }

    getTabContainerContent(order) {
        const currentShippingAddress = order && order.customer
            ? order.customer.shipping_address
            : {}

        switch (this.state.activeTab) {
            case 'finance': return (
                <Memos
                    debit_memos={this.props.debit_memos}
                    credit_memos={this.props.credit_memos}
                />
            )

            case 'personalisations': return (
                <Personalisations
                    typed_orders={this.props.typed_orders.filter(to => to.type === 'personalisation')}
                    instructions={order.personalisation && order.personalisation.instructions}
                    personalisations={this.props.personalisations}
                />
            )

            case 'inbound': return (
                <Inbound
                    order_number={order.mage_id}
                    inbound_receipts={this.props.inbound_receipts}
                    inbound_candidates={this.props.inbound_candidates}
                />
            )

            case 'outbound': return (
                <Outbound
                    mage_id={order.mage_id}
                    email={this.props.email}
                    shipments={this.props.shipments}
                    currentShippingAddress={currentShippingAddress}
                />
            )

            case 'returns': return (
                <Returns
                    rmas={this.props.rmas}
                />
            )

            default:
                return null
        }
    }

    renderTabs(order) {
        const in_fulfillment = this.isInFulfillment(order)
        const shipment_count = Object.keys(this.props.shipments).length

        return !in_fulfillment
            ? (
                <div style={style.tabs}>
                    <AppBar position="static" color="primary">
                        <Tabs variant="fullWidth" value={this.state.activeTab} onChange={this.handleTabChange}>
                            <Tab icon={<ListIcon />} label="Line items" />
                        </Tabs>
                    </AppBar>
                    <TabContainer>
                        <LineItems
                            status={order.status}
                            line_items={order.line_items}
                        />
                    </TabContainer>
                </div>
            )
            : (
                <div style={style.tabs}>
                    <AppBar position="" color="primary" style={{zIndex: 1}}>
                        <Tabs variant="fullWidth" value={this.state.activeTab} onChange={this.handleTabChange}>
                            <Tab icon={<PaymentIcon />} label="Finance memo's" value="finance" />
                            {order.personalised && (
                                <Tab
                                    icon={<PhotoIcon />}
                                    label={`Personalisations (${this.props.personalisations.length})`}
                                    value="personalisations"
                                />
                            )}
                            <Tab icon={<DomainIcon />} label="Inbound" value="inbound" />
                            <Tab icon={<LocalShippingIcon />} label={`Outbound (${shipment_count})`} value="outbound" />
                            <Tab icon={<UndoIcon />} label="Returns" value="returns" />
                        </Tabs>
                    </AppBar>
                    <TabContainer>
                        {this.getTabContainerContent(order)}
                    </TabContainer>
                </div>
            )
    }

    render() {
        const order = this.props.sales_order
        if (!order._id) {
            return (
                <div />
            )
        }
        const logs = !this.state.logsOpen ? null : (
            <ActionLogDialog
                logs={this.props.logs}
                onClose={() => {
                    this.setState({ logsOpen: false })
                }}
            />
        )
        const headerButtons = this.getHeaderButtons(order)
        const date = moment(order.created_at).format('DD-MM-YYYY HH:mm')
        return (
            <div style={style.container}>
                <Header
                    title={`${order.mage_id} (${date})`}
                    avatar={
                        <ChannelAvatar
                            style={{ marginTop: -5, marginRight: 10, height: 30, width: 30 }}
                            slug={order.channel.slug}
                        />
                    }
                    showSupplier={false}
                    subheader={`Status: ${order.status}`}
                    rightButtons={headerButtons}
                />
                <div style={style.content}>
                    <div style={style.left}>
                        <Payment
                            style={{ marginTop: 12, marginBottom: 12 }}
                            payment_info={order.payment_info}
                            bol_ref={order.refs.bol_ref}
                        />
                        <Customer
                            editable={order.status !== 'completed' && order.status !== 'unfulfillable'}
                            order_id={order._id}
                            status={order.status}
                            customer={order.customer}
                            salesRepresentative={order.sales_representative}
                        />
                        <Notes
                            notes={order.notes}
                            canAdd={true}
                            canUpdate={true}
                            canArchive={true}
                            onAddNote={this.handleAddNote.bind(this)}
                            onUpdateNote={this.handleUpdateNote.bind(this)}
                            onArchiveNote={this.handleArchiveNote.bind(this)}
                        />
                        <Instructions
                            style={style.instructions}
                            instructions={order.instructions}
                            addInstruction={instruction => {
                                this.props.addInstruction(order._id, order.mage_id, {
                                    author: this.props.email,
                                    value: instruction,
                                    date: new Date().toISOString(),
                                })
                            }}
                        />

                        <Card
                            style={style.information}
                        >
                            <CardHeader
                                titleColor="rgba(0, 0, 0, 0.7)"
                                titleStyle={{ fontFamily: 'Roboto' }}
                                title="Extra Information"
                            />
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    component="h2"
                                >
                                    {
                                        this.props.sales_order?.personalisation?.instructions ||
                                        'Order has no extra information'
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    {this.renderTabs(order)}
                </div>
                {logs}
                <ForceFulfillmentConfirmationDialog
                    open={this.state.forceFulfillmentConfirmOpen}
                    onSubmit={this.handleForceFulfillmentConfirmationDialogSubmit}
                    onClose={this.handleForceFulfillmentConfirmationDialogClose}
                    closeOnSubmit={true}
                />
            </div>
        )
    }
}

OrderDetail.propTypes = {
    params: PropTypes.object.isRequired,
    email: PropTypes.string,
    features: PropTypes.object,
    sales_order: PropTypes.object.isRequired,
    inbound_receipts: PropTypes.array,
    inbound_candidates: PropTypes.array,
    debit_memos: PropTypes.array,
    credit_memos: PropTypes.array,
    typed_orders: PropTypes.array,
    personalisations: PropTypes.array,
    rmas: PropTypes.array,
    shipments: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    logs: PropTypes.array,
    getSalesOrder: PropTypes.func.isRequired,
    addInstruction: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    updateNote: PropTypes.func.isRequired,
    archiveNote: PropTypes.func.isRequired,
    forceFulfillment: PropTypes.func.isRequired,
    markAsCancelled: PropTypes.func.isRequired,
    markAsCompleted: PropTypes.func.isRequired,
}

export default connect(({
    sales_order,
    debit_memo = {},
    credit_memos = [],
    typed_orders = [],
    personalisations = [],
    inbound_receipts = [],
    inbound_candidates = [],
    shipments = {},
    rmas = [],
    logs = [],
}) => {
    return {
        sales_order,
        typed_orders,
        inbound_receipts,
        inbound_candidates,
        personalisations,
        debit_memos: debit_memo.hasOwnProperty('_id') ? [debit_memo] : [],
        credit_memos,
        rmas,
        shipments,
        logs,
    }
}, dispatch => {
    return bindActionCreators({
        getSalesOrder,
        addNote,
        updateNote,
        archiveNote,
        addInstruction,
        forceFulfillment,
        markAsCancelled,
        markAsCompleted,
    }, dispatch)
})(OrderDetail)
