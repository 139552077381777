import store from '../../store'

import { refreshProductionOrder, refreshProductionOrders } from '../../actions/production_orders'

import { getSalesOrder } from '../../commands/sockets/sales_orders'

export default function initProductionOrdersSockets(socket) {
    socket.on('production-orders/search-result', production_orders => {
        store.dispatch(refreshProductionOrders(production_orders))
    })

    socket.on('production-order', production_order => {
        store.dispatch(refreshProductionOrder(production_order))
        getSalesOrder(production_order.refs.order_id_ref)
    })

    socket.on('production-order/updated', production_order => {
        store.dispatch(refreshProductionOrder(production_order))
    })
}
