import socket from '../../socket'
import { getUserEmail } from '../../store'

import { DeliveryOrderStatuses } from '../../app/types/logistics/delivery-orders'

export function getDeliveryOrder(increment_id) {
    socket.emit('delivery-orders/get-one', { increment_id }, getUserEmail())
    return { type: 'GET_DELIVERY_ORDER', data: increment_id }
}

export function getDeliveryOrderByShipmentRef(shipment_ref) {
    socket.emit('delivery-orders/get-one-by-shipment-ref', {
        shipment_ref,
        statuses: [
            DeliveryOrderStatuses.OPEN,
            DeliveryOrderStatuses.PARTIALLY_FULFILLED,
            DeliveryOrderStatuses.FULFILLED,
            DeliveryOrderStatuses.COMPLETED,
        ],
    }, getUserEmail())
    return { type: 'GET_DELIVERY_ORDER_BY_SHIPMENT_REF', data: shipment_ref }
}

export function searchDeliveryOrders(data) {
    socket.emit('delivery-orders/search', data, getUserEmail())
    return { type: 'SEARCH_DELIVERY_ORDERS', data }
}

export function getDeliveryOrderLocations(location_refs) {
    socket.emit('delivery-orders/get-locations', location_refs, getUserEmail())
    return { type: 'GET_DELIVERY_ORDER_LOCATIONS', data: location_refs }
}
