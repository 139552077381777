export function getUnpackedCounts() {
    return { type: 'GET_UNPACKED_COUNTS' }
}

export function searchPackingItems(filters) {
    return { type: 'SEARCH_PACKING_ITEMS', filters }
}

export function printPackingItems(filters) {
    return { type: 'PRINT_PACKING_ITEMS', filters }
}

export function refreshPackingItems(packing_items) {
    return { type: 'REFRESH_PACKING_ITEMS', packing_items }
}

export function submitPackedLineItems(_id, { line_items, packed }) {
    return { type: 'SUBMIT_PACKING_ITEM_PACKED_LINE_ITEMS', data: { _id, line_items, packed } }
}

export function updatePackingLineItems(packing_item) {
    return { type: 'UPDATE_PACKING_ITEM_LINE_ITEMS', packing_item }
}

export function updateLineItemPackedQuantity(item_index, packed_quantity) {
    return { type: 'UPDATE_LINE_ITEM_PACKED_QUANTITY', item_index, packed_quantity }
}

export function startProcessingPackingItem() {
    return { type: 'START_PROCESSING_PACKING_ITEM' }
}

export function doResetPackingStations() {
    return {type: 'DO_RESET_PACKING_STATIONS'}
}
