export function getLabel(shipment_id, pdf = false) {
    return {type: 'GET_LABEL', shipment_id, pdf}
}

export function createLabels(quantity, purchase_id, supplier_id) {
    return {type: 'CREATE_LABELS', quantity, purchase_id, supplier_id}
}

export function updateLabels(labels) {
    return {type: 'REFRESH_LABELS', labels}
}
