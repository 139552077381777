import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { downloadBase64AsPdf } from '../../../../utils/download'

import { printLabel } from '../../../../../commands/sockets/labels'
import { printPackingSlip, getPackingSlip } from '../../../../../commands/sockets/packing_slips'
import { checkoutShipment } from '../../../../../commands/sockets/shipments'
import { getPromoItemsByMageId } from '../../../../../commands-sync/order'

import {
    updateLineItemPackedQuantity,
    startProcessingPackingItem,
    submitPackedLineItems,
    updatePackingLineItems,
} from '../../../../../actions/packing_items'

import { getShipment } from '../../../../../actions/shipments'
import { showNotification } from '../../../../../actions/notifications'
import { registerPackingStation, unregisterPackingStation } from '../../../../../commands/sockets/packing_stations'
import { getAvailablePrinters } from '../../../../../commands/sockets/printers'
import { subscribeToWall, unsubscribeFromWall } from '../../../../../commands/sockets/wall'
import { showErrorNotification } from '../../../../../actions/notifications'

import Header from './../../../shared/Header'
import SelectPrinter from '../SelectPrinter'
import PackFromCompartment from './PackFromCompartment'
import { searchPackingItems } from './../../../../../actions/packing_items'

import { PrinterPageSizeType } from '../../../../types/logistics/outbound'
import { createLogger } from '../../../../utils/logger'

import _ from 'lodash'

const styles = {
    container: {
        width: '95%',
        paddingTop: 12,
        margin: 'auto',
    },
    header: {
        marginBottom: 6,
    },
    headerNode: {
        float: 'right',
    },
    select: {
        marginRight: 12,
    },
    floatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        right: 20,
        bottom: 20,
    },
    searchPaper: {
        marginRight: '30px',
        padding: '10px',
    },
    searchInput: {
        marginRight: '10px',
        width: '200px',
    },
    searchButton: {
        height: '44px',
    },
}

const STORAGE_KEY = 'packing-single-pick-filters'

const sendLog = createLogger('packSinglePick')

class PackSinglePick extends Component {

    constructor(props) {
        super(props)
        this.state = {
            label_printer: null,
            packing_slip_printer: null,
            autoPrint: false,
            combinedPrint: false,
            lastPrinted: null,
            ignoreDate: true,
            packing_items: [],
            promo_packing_items: [], // This keeps track of the promo items that are packed
            pack: -1,
            channel: 'all',
            fulfillment_source: 'all',
            item_type: 'all',
            destination_type: 'all',
            instructions: 'all',
            search: '',
            limit: 1,
            page: 1,
            singlePieceDialogOpen: false,
            singlePieceSearchLocation: '',
            singlePieceOnly: true,
        }

        this.searchInputRef = React.createRef()

        autoBind(this)
    }

    handlePackedAtSaveClick() {
        const packing_item = this.props.packing_items[0]
        const line_items = _.map(packing_item.line_items, item => {
            item.packed_at = new Date().toISOString()
            return item
        })
        sendLog(`[handlePackedAtSaveClick] Submitting packed lineItems for order ${packing_item.mage_id}`)
        this.props.submitPackedLineItems(packing_item._id, {line_items, packed: true})
    }

    /* eslint-disable no-console */
    /* Logging below is enabled in case hand-scanning issues happen */
    handleKeyPress(event) {
        const callee_name = 'handleKeyPress'
        // Skip keyboard input targeted at the search input
        if (event.target === this.searchInputRef.current) return false

        if (!this.mounted) {
            console.log(
                `${callee_name}: Exiting early mounted: ${this.mounted}, ` +
                `compartment: ${this.props.compartment}, packing_item: ${this.props.packing_item}`
            )
            return false
        }

        clearTimeout(this.timeout)

        if (this.scanTimer === 0) {
            console.log(`${callee_name}: Resetting scan timer`)
            this.scanTimer = Date.now()
        }

        const is_enter = event.keyCode === 13 || event.which === 13
        const pressedKey = String.fromCharCode(event.keyCode || event.which)

        if (!is_enter) {
            this.scan += pressedKey
            console.log(`${callee_name}: Current scan so far: ${this.scan}`)
        } else {
            const scan = this.scan
            const scanTimer = this.scanTimer

            console.log(
                `${callee_name}: Key is enter scan: ${scan}, scanlength: ${scan.length},`,
                'scanTimer:', new Date(scanTimer).toISOString(),
                'now:', new Date().toISOString(),
                'within 500ms?', Date.now() - scanTimer < 500
            )

            this.scanTimer = 0
            this.scan = ''

            this.handleScan(scan)
        }

        this.timeout = setTimeout(() => {
            this.scan = ''
            this.scanTimer = 0
        }, 1000)

        return true
    }

    /**
     * Check if all packing items and promo items are fully packed
     * @returns {boolean}
     */
    isReadyForCheckout() {
        const optional_promo_items_packed = !this.state.promo_packing_items?.length
            ? true
            : this.state.promo_packing_items.every(item => {
                return item.quantity === item.packed_quantity
            })

        return Boolean(this.props.packing_items?.length && optional_promo_items_packed)
    }

    /**
     * A scan has been identified, do something with it
     * It checks ean or tracking-code values
     * @param {string} scan Scanned value
     * @returns {void} undefined
     */
    handleScan(scan) { // eslint-disable-line consistent-return
        const callee_name = 'handleScan'

        sendLog(`[${callee_name}] New scan: ${scan}. ` +
            `Promo items (props): ${JSON.stringify(this.props?.promo_items)} ` +
            `Promo items (state): ${JSON.stringify(this.state.promo_packing_items)} ` +
            `isReadyForCheckout: ${this.isReadyForCheckout() ? 'true' : 'false'}`
        )

        const showError = message => this.props.showErrorNotification({ message })

        if (scan.length <= 3) {
            sendLog(`[${callee_name}] Scan was too short`)
            return showError('Scan should be more than 3 characters long')
        }

        console.log(`${callee_name}: Scanning finished, proceeding with ${scan}`)

        if (this.isReadyForCheckout()
            && scan === this.props?.shipment?.shipping_label?.tracking_code
        ) {

            sendLog(`[${callee_name}]: Processing scan as shipping label`)
            console.log(`${callee_name}: Checking out shipment`)

            sendLog(`[${callee_name}] Checking out shipment ${this.props.shipment._id}`)
            this.props.checkoutShipment(this.props.shipment)
            console.log('Ready to checkout')

            sendLog(`[${callee_name}] Update packingLineItems for order ${this.props.packing_items[0].mage_id}`)
            this.props.packing_items[0].line_items[0].packed_quantity = 1

            this.handlePackedAtSaveClick()

            this.searchPackingItems('---') // invalid EAN, this will clear the screen
        }
        else if ((this.state.promo_packing_items || []).length) {
            // Find promo item to pack
            const promo_item = this.state.promo_packing_items.find((promo_item, index) => {
                promo_item._index = index
                return (promo_item.sku === scan || promo_item.ean === scan)
                    && promo_item.quantity > promo_item.packed_quantity
            })
            if (promo_item) {
                sendLog(`[${callee_name}] Processing scan as promo item: ${scan}`)
                this.handlePromoLineItemPackedQuantityChange(promo_item.packed_quantity+1, promo_item._index)
            }
        }
        else if (this.props.packing_items?.length) {
            return this.props.showNotification({
                message: 'Tracking code is niet correct',
            })
        }
        else {
            sendLog(`[${callee_name}] Processing scan as ean: ${scan}`)
            console.log(`${callee_name} Searching packing item ${scan}`)

            this.searchPackingItems(scan)
        }
    }

    /* eslint-enable no-console */
    componentDidMount() {
        this.mounted = true
        this.scan = ''
        this.scanTimer = 0
        document.addEventListener('keypress', this.handleKeyPress)

        this.props.subscribeToWall()
        this.props.getAvailablePrinters([PrinterPageSizeType.A4, PrinterPageSizeType.LABEL])
    }

    static getDerivedStateFromProps({ packing_items, packing_slip, printers }, prevState) {
        const newState = {}

        if (!prevState.printer && printers.length > 0) {
            newState.printer = printers[0].id
        }

        if (prevState.downloadedPackingSlip &&
            packing_slip.shipment_id &&
            prevState.downloadedPackingSlip === packing_slip.shipment_id &&
            !packing_slip.error) {
            newState.downloadedPackingSlip = null
            downloadBase64AsPdf(packing_slip.blob, packing_items[0].mage_id)
        }

        return Object.keys(newState).length > 0 ? newState : null
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.packing_item &&
            !this.props.packing_item.processing &&
            this.props.compartment &&
            this.props.compartment !== this.state.lastPrinted &&
            this.state.autoPrint
        ) {
            sendLog('[componentDidUpdate] Processing packing item')
            this.processPackingItem()
        }

        if (prevProps.packing_items !== this.props.packing_items && this.props.packing_items.length) {
            sendLog(
                `[componentDidUpdate] Packing item found: ${JSON.stringify(this.props.packing_items[0])}. ` +
                `Started search for shipment with id ${this.props.packing_items[0].shipment_id}`
            )
            this.props.getShipment(this.props.packing_items[0].shipment_id)
            this.fetchPromoItems()
        }

        if (prevProps.shipment._id !== this.props.shipment._id && !!this.props.packing_items.length) {
            sendLog(`[componentDidUpdate] Shipment has been found: ${JSON.stringify(this.props.shipment)}`)
            this.handlePrintPackingSlipClick()
            this.handlePrintLabelClick()
        }

        if (prevProps.promo_items !== this.props.promo_items) {
            const mapped_promo_items = this.props.promo_items?.map(item => ({
                ...item,
                packed_quantity: 0,
            }))

            this.setState({
                promo_packing_items: mapped_promo_items,
            })
        }
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    /**
     * Find packing items by ean
     * @param {string} scan Scanned value (EAN)
     * @returns {void} Nothing
     */
    searchPackingItems(scan = '') {
        if (!scan) return

        const mage_id = this.getMagentoSupplierId()
        const filters = this.getFilters()

        const parameters = {
            value: filters.search,
            pack: filters.pack,
            date: filters.date,
            supplier: { mage_id },
            limit: +filters.limit,
            page: 1,
            single_line_items: true,
            containing_ean: '',
            packed: false,
        }

        if (filters.channel !== 'all') {
            parameters.channel = filters.channel
        }

        if (filters.fulfillment_source !== 'all') {
            parameters.fulfillment_source = filters.fulfillment_source
        }

        if (filters.destination_type !== 'all') {
            parameters.destination_type = filters.destination_type
        }

        if (filters.instructions !== 'all') {
            parameters.instructions = filters.instructions
        }

        if (parameters.fulfillment_source === 'personalisation' && filters.item_type !== 'all') {
            parameters.item_type = filters.item_type
        }

        if (parameters.instructions) {
            parameters.instructions = parameters.instructions === 'with'
        }

        if (scan) {
            parameters.containing_ean = scan
            parameters.limit = 1
        }

        if (this.state.ignoreDate) {
            delete parameters.date
        } else if (parameters.date) {
            parameters.date = moment(parameters.date).toDate()
        }

        sendLog(`[searchPackingItems] Searching with parameters ${JSON.stringify(parameters)}`)

        this.props.searchPackingItems(parameters)
    }

    getMagentoSupplierId() {
        return 6029 // Proforto B.V.
    }

    handleWallChange(wall) {
        this.setState({ wall })
    }

    handleLabelPrinterChange(label_printer) {
        this.setState({ label_printer })
    }

    handlePackingSlipPrinterChange(packing_slip_printer) {
        this.setState({ packing_slip_printer })
    }

    handleToggleViewWallClick() {
        this.setState({ viewWall: !this.state.viewWall })
    }

    handleToggleActivityClick() {
        this.setState(
            {
                startedActivity: !this.state.startedActivity,
                autoPrint: false,
                lastPrinted: null,
            },
            () => {
                if (this.state.startedActivity) {
                    this.props.registerPackingStation()
                } else {
                    this.unregisterPackingStation()
                }
            }
        )
    }

    unregisterPackingStation() {
        this.props.unregisterPackingStation(this.props.packing_station)
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress)
        this.mounted = false
        this.props.unsubscribeFromWall()
    }

    handleDownloadPackingSlipClick(e) {
        this.setState({ downloadedPackingSlip: this.props.packing_item.shipment_id }, () => {
            this.props.getPackingSlip(
                this.props.packing_items[0].shipment_id,
                this.props.packing_items[0].fulfillment_source)
            return e && e.target && e.target.blur()
        })
    }

    handlePrintPackingSlipClick() {
        const packing_slip_printer = this.props.printers.find(p => p.id === this.state.packing_slip_printer)

        if (!packing_slip_printer) {
            return this.props.showNotification({
                message: 'Pakbon printer is niet geselecteerd.',
            })
        }

        return this.props.printPackingSlip(
            this.props.packing_items[0].shipment_id,
            packing_slip_printer,
            {
                with_label: this.state.combinedPrint,
                fulfillment_source: this.props.packing_items[0].fulfillment_source,
                store_slug: this.props.packing_items[0].channel.slug,
            }
        )
    }

    handlePrintLabelClick() {
        const label_printer = this.props.printers.find(p => p.id === this.state.label_printer)

        if (!label_printer) {
            return this.props.showNotification({
                message: 'Label printer is niet geselecteerd.',
            })
        }

        sendLog(`[handlePrintLabelClick] Printing label for shipment ${this.props.packing_items[0].shipment_id}`)
        return this.props.printLabel(this.props.packing_items[0].shipment_id, label_printer)
    }

    processPackingItem() {
        this.setState({ lastPrinted: this.props.compartment }, () => {
            this.props.startProcessingPackingItem()
            this.fetchPromoItems()
            const packing_slip_printer = this.props.printers.find(p => p.id === this.state.packing_slip_printer)

            sendLog(`[processPackingItem] Printing packing slip for shipment ${this.props.packing_item.shipment_id}`)
            if (!this.state.combinedPrint) {
                this.props.printPackingSlip(
                    this.props.packing_item.shipment_id,
                    packing_slip_printer,
                    {
                        with_label: false,
                        fulfillment_source: this.props.packing_item.fulfillment_source,
                        store_slug: this.props.packing_item.channel.slug,
                    }
                )
                const label_printer = this.props.printers.find(p => p.id === this.state.label_printer)
                sendLog(`[processPackingItem] Printing label for shipment ${this.props.packing_item.shipment_id}`)
                this.props.printLabel(this.props.packing_item.shipment_id, label_printer)
            } else {
                this.props.printPackingSlip(
                    this.props.packing_item.shipment_id,
                    packing_slip_printer,
                    {
                        with_label: true,
                        fulfillment_source: this.props.packing_item.fulfillment_source,
                        store_slug: this.props.packing_item.channel.slug,
                    }
                )
            }
        })
    }

    handleStartProcessingPackingItemClick() {
        this.processPackingItem()
    }

    handleLineItemPackedQuantityChange(value, index) {
        this.props.updateLineItemPackedQuantity(index, value, this.props.compartment)
    }

    handleWallDialogClose() {
        this.handleToggleViewWallClick()
    }

    handleAutoPrintSwitchChange(e) {
        this.setState({ autoPrint: e.target.checked })
    }

    handleCombinedPrintSwitchChange(e) {
        this.setState({ combinedPrint: e.target.checked })
    }

    fetchPromoItems() {
        getPromoItemsByMageId(this.props.packing_items[0].mage_id)
            .catch(e => {
                this.props.showErrorNotification({
                    message: 'Couldn\'t retrieve promo items' + e.message,
                })
            })
    }

    handlePromoLineItemPackedQuantityChange(value, index) {
        const promo_packing_items = [...this.state.promo_packing_items ]
        promo_packing_items[index].packed_quantity = value

        this.setState({ promo_packing_items })
    }

    /**
     * Click handler for the search input
     * The search input is to manually input eans
     * @returns {void} undefined
     */
    handleSearchButtonClick() { // eslint-disable-line consistent-return
        const showError = message => this.props.showErrorNotification({ message })

        const input = this.searchInputRef.current
        if (!input) return showError('Cannot find input element')

        const value = String(input.value || '').trim()
        if (value.length < 3) return showError('Scan input should be longer as 3 characters')

        this.handleScan(value)

        input.value = ''
    }

    render() {
        return (
            <div style={styles.container}>
                <Header
                    title="Single Piece Items"
                    subheader="Scan een item uit je kar/bin om te beginnen."
                    textTransform="inherit"
                    style={styles.header}
                    rightButtons={[
                        <SelectPrinter
                            key="select-label-printer"
                            style={{ ...styles.headerNode, ...styles.select }}
                            value={this.state.label_printer}
                            printers={this.props.printers.filter(printer => {
                                return printer.page_size_type === PrinterPageSizeType.LABEL
                                    && !printer.name.toLowerCase().includes('gk420d')
                            })}
                            onChange={this.handleLabelPrinterChange}
                            label="Selecteer label printer"
                        />,
                        <SelectPrinter
                            key="select-packing-slip-printer"
                            style={{ ...styles.headerNode, ...styles.select }}
                            value={this.state.packing_slip_printer}
                            printers={this.props.printers.filter(printer => {
                                return printer.page_size_type === PrinterPageSizeType.A4
                            })}
                            onChange={this.handlePackingSlipPrinterChange}
                            label="Selecteer pakbon printer"
                        />,
                        <Paper key="manual-search-input" style={{ ...styles.headerNode, ...styles.searchPaper }}>
                            <TextField
                                label="Manual search"
                                style={styles.searchInput}
                                inputRef={this.searchInputRef}
                            />
                            <Button
                                style={styles.searchButton}
                                title="Manual search"
                                color="primary"
                                variant="contained"
                                onClick={this.handleSearchButtonClick}
                            >
                                Submit scan
                            </Button>
                        </Paper>,
                    ]}
                />
                { this.props.packing_items?.length ?
                    <PackFromCompartment
                        shipment={this.props.shipment}
                        packing_item={this.props.packing_items[0]}
                        onDownloadPackingSlipClick={this.handleDownloadPackingSlipClick}
                        onPrintLabelClick={this.handlePrintLabelClick}
                        onPrintPackingSlipClick={this.handlePrintPackingSlipClick}
                        onLineItemPackedQuantityChange={this.handleLineItemPackedQuantityChange}
                        onPromoLineItemPackedQuantityChange={this.handlePromoLineItemPackedQuantityChange}
                        promo_packing_items={this.state.promo_packing_items}
                        showErrorNotification={this.props.showErrorNotification}
                    /> : <p>Scan een artikel om het inpakproces te beginnen</p>
                }
            </div>
        )
    }
}

PackSinglePick.contextTypes = {
    colors: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
}
PackSinglePick.propTypes = {
    packing_slip: PropTypes.object,
    packing_station: PropTypes.number,
    printers: PropTypes.array,
    compartments: PropTypes.array,
    compartment: PropTypes.string,
    shipment: PropTypes.object,
    packing_item: PropTypes.object,
    registerPackingStation: PropTypes.func,
    unregisterPackingStation: PropTypes.func,
    getAvailablePrinters: PropTypes.func,
    getPackingSlip: PropTypes.func,
    printPackingSlip: PropTypes.func,
    printLabel: PropTypes.func,
    checkoutShipment: PropTypes.func,
    subscribeToWall: PropTypes.func,
    startProcessingPackingItem: PropTypes.func,
    unsubscribeFromWall: PropTypes.func,
    updateLineItemPackedQuantity: PropTypes.func,
    showNotification: PropTypes.func,
    packing_items: PropTypes.array,
    filters: PropTypes.array,
    getShipment: PropTypes.func,
    searchPackingItems: PropTypes.func,
    submitPackedLineItems: PropTypes.func.isRequired,
    updatePackingLineItems: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,

}
PackSinglePick.defaultProps = {
    compartments: [],
}

export default connect(
    ({
        printers,
        packing_station,
        packing_item,
        packing_slip,
        shipment,
        compartment,
        compartments,
        packing_items,
        promo_items,
    }) => {
        return {
            printers,
            packing_station,
            packing_item,
            packing_slip,
            shipment,
            compartment,
            compartments,
            packing_items,
            promo_items,
        }
    },
    dispatch => {
        return bindActionCreators({
            registerPackingStation,
            unregisterPackingStation,
            getPackingSlip,
            printPackingSlip,
            printLabel,
            getAvailablePrinters,
            subscribeToWall,
            unsubscribeFromWall,
            checkoutShipment,
            startProcessingPackingItem,
            updateLineItemPackedQuantity,
            showNotification,
            searchPackingItems,
            getShipment,
            submitPackedLineItems,
            updatePackingLineItems,
            showErrorNotification,
        }, dispatch)
    }
)(PackSinglePick)
