import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
import { randomString } from './app/utils/string'

const uuidToken = uuidv4()
const timestamp = Date.now()

/**
 * Get the url to the profortool-gateway
 */
export function getGatewayUrl() {
    let url = window.location.origin

    switch (url) {
        case 'http://profortool.production.profortool.com':
        case 'http://profortool.com':
            url = 'http://profortool-gateway.production.profortool.com'
            break
        case 'http://profortool.staging.profortool.com':
        case 'http://staging.profortool.com':
            url = 'http://profortool-gateway.staging.profortool.com'
            break
        case 'https://profortool.production.profortool.com':
        case 'https://profortool.com':
            url = 'https://profortool-gateway.production.profortool.com'
            break
        case 'https://profortool.staging.profortool.com':
        case 'https://staging.profortool.com':
            url = 'https://profortool-gateway.staging.profortool.com'
            break
        case 'http://profortool.sandbox.profortool.com':
            url = 'http://profortool-gateway.sandbox.profortool.com'
            break

        // GCP Kubernetes urls
        // Use dokku gateway for now
        case 'http://profortool.prod.profortool.com':
            url = 'http://profortool-gateway.production.profortool.com'
            break
        case 'https://profortool.prod.profortool.com':
            url = 'https://profortool-gateway.production.profortool.com'
            break
        case 'http://profortool.stag.profortool.com':
            url = 'http://profortool-gateway.staging.profortool.com'
            break
        case 'https://profortool.stag.profortool.com':
            url = 'https://profortool-gateway.staging.profortool.com'
            break

        default:
            url = 'http://localhost:37101'
            break
    }

    return url
}

const socket = io(getGatewayUrl(), {
    query: {
        token: `${uuidToken};${timestamp}`,
        accessToken: localStorage.getItem('userToken'),
    },
})

socket.reconnect = () => {
    socket.io.opts.query = {
        token: `${uuidToken};${timestamp}`,
        accessToken: localStorage.getItem('userToken'),
    }
    socket.connect()
}

socket._queued = []
socket.on('token', token => {
    const had_token = !!socket.token
    socket.token = token
    if (!had_token && socket.connected) {
        socket._queued.forEach(({channel, params, user}) => {
            socket.emit(channel, params, user)
        })
    }
})

const emit = socket.emit.bind(socket)
socket.emit = function (channel, params, user, metadata) {
    if (!socket.token) {
        socket._queued.push({
            channel,
            params,
            user,
            metadata: { version: window.PROFORTOOL_APP_VERSION, ...metadata },
        })
    } else {
        emit(
            channel,
            params,
            socket.token,
            user,
            { version: window.PROFORTOOL_APP_VERSION, ...metadata }
        )
    }
}

export default socket

/**
 * Currently running reply event names
 * This object makes sure a reply event name is not used twice at the same time
 */
const replyEvents = {
    _queues: [],

    get(prefix = 'replyEvent') {
        let queue_name = null

        do {
            queue_name = this._generateReplyEvent(prefix)
        } while (this._queues.includes(queue_name))

        this._queues.push(queue_name)

        return queue_name
    },

    remove(queue_name) {
        const index = this._queues.indexOf(queue_name)
        if (index < 0) return false

        this._queues.splice(index, 1)
        return true
    },

    _generateReplyEvent(prefix = 'replyEvent') {
        return `${prefix}/${randomString(10)}`
    },
}

/**
 * Do an synchronous call to the gateway
 * The gateway event handler should return a message object { data: any, errorMessage: string } to the 'reply_to' event
 *
 * @param {string} event Name of the event to emit to
 * @param {object} payload Data to send (An 'reply_to' key will get added to this object)
 * @param {object} options Options object
 * @param {int} options.timeout Timeout in milliseconds after which the request is deemed as failed
 * @returns {Promise<any>} Returns Promise which resolves when it got a reply with the reply_to event,
 *                         otherwise it rejects with a timeout error
 */
export function syncSocketCall(event, data, { timeout = 30000 } = {}) {
    return new Promise((resolve, reject) => {
        const reply_to = replyEvents.get(`${event}${event.endsWith('/') ? '' : '/'}reply`)
        let timeout_handle = null

        const onReply = ({ data, errorMessage }) => { // eslint-disable-line func-style
            clearTimeout(timeout_handle)
            replyEvents.remove(reply_to)
            return errorMessage ? reject(new Error(`${event} - ${errorMessage}`)) : resolve(data)
        }
        socket.once(reply_to, onReply)

        timeout_handle = setTimeout(() => {
            socket.off(reply_to, onReply)
            replyEvents.remove(reply_to)
            return reject(new Error(`${event} - timeout of ${timeout}ms exceeded`))
        }, timeout)

        socket.emit(event, { data, reply_to })
    })
}
