import socket from './../../socket'
import { getUserEmail } from '../../store'
import { loadRmaProduct } from '../../actions/rmas'

export function getRmaByTrackingCode(tracking_code) {
    socket.emit('rma/tracking_code/get', { tracking_code }, getUserEmail())
    return { type: 'START_LOADING' }
}

export function createRma(rma) {
    const user = getUserEmail()
    socket.emit('rma/authorize', { rma, user }, user)
    return { type: 'CREATE_RMA', rma }
}

export function searchRmas(filters) {
    socket.emit('rmas/search', filters, getUserEmail())
    return { type: 'START_LOADING', filters }
}

export function downloadRma(rma_id) {
    socket.emit('rma/download', rma_id, getUserEmail())
    return { type: 'START_LOADING' }
}

export function getRmaProduct(skuOrEan = []) {
    socket.emit('product/variants/sku-or-ean', skuOrEan)
    return loadRmaProduct(skuOrEan)
}
