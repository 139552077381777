import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField} from 'material-ui'


const style = {
    width: 100,
    fontSize: 14,
}

class QuantityField extends Component {

    handleFilterChange(e) {
        let value = +e.target.value
        if (value > this.props.quantity) {
            value = this.props.quantity
        } else if (value < 1) {
            value = 1
        }
        this.props.onFilterChange(value)
    }

    render() {
        return (
            <TextField
                style={style}
                type="number"
                min={1}
                hintText="Quantity"
                max={this.props.quantity}
                value={this.props.filterValue}
                onChange={this.handleFilterChange.bind(this)}
                floatingLabelText={this.props.label}
            />
        )
    }
}

QuantityField.propTypes = {
    quantity: PropTypes.number.isRequired,
    filterValue: PropTypes.number.isRequired,
    label: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
}

export default QuantityField
