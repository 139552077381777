import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { RaisedButton } from 'material-ui'

import {
    getCreditMemo,
    markAsRejected,
    markAsAccepted,
    markAsToRefund,
    markAsRefunded,
    markAsOnHold,
    addNote,
    synchronizeCreditMemo,
} from './../../../../actions/credit_memos'
import Colors from './../../../styles/colors'

import Header from './../../shared/Header'
import Notes from './../../shared/Notes'
import Info from './../MemoInfo'
import LineItems from './credit-memo-detail/LineItems'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    date: {
        display: 'inline-block',
        textAlign: 'right',
        lineHeight: '30px',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        paddingTop: 10,
    },
    info: {
        width: '100%',
    },
    line_items: {
        width: '83%',
    },
    rightButtonMargin: {
        float: 'right',
        marginRight: 20,
    },
    rightButtonMarginLabel: {
        color: 'white',
    },
}

class CreditMemoDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            syncDisabled: false,
            refundButtonClicked: false,
        }
    }

    componentDidMount() {
        this.props.getCreditMemo(this.props.params.id)
    }

    getTotalPricing(credit) {
        return {
            subtotal: credit.subtotal,
            tax: credit.tax_amount,
            total: credit.grand_total,
        }
    }

    getHeaderTitle(credit) {
        if (credit.rma_id) {
            return `Credit Memo - RMA ${credit.rma_id}`
        }
        if (credit.mage_id) {
            return `Credit Memo - Order ${credit.mage_id}`
        }
        return 'Credit Memo'
    }

    handleRejectClick() {
        this.props.markAsRejected(this.props.credit_memo._id)
    }

    handleAcceptClick() {
        this.props.markAsAccepted(this.props.credit_memo._id)
    }

    handleRefundClick() {
        this.setState({ refundButtonClicked: true }, () => {
            this.props.markAsToRefund(this.props.credit_memo._id)
        })
    }

    handleRefundedClick() {
        this.props.markAsRefunded(this.props.credit_memo._id)
    }

    handleOnHoldClick() {
        this.props.markAsOnHold(this.props.credit_memo._id)
    }

    handleSynchronizeCreditMemoClick() {
        this.setState({ syncDisabled: true }, () => {
            this.props.synchronizeCreditMemo(this.props.credit_memo._id)
        })
    }

    getActionButtons(credit) {
        const buttons = []
        if (credit.status === 'draft' || credit.status === 'on hold') {
            buttons.push(
                <RaisedButton
                    key="reject"
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.red100}
                    label="Reject"
                    onClick={this.handleRejectClick.bind(this)}
                />,
                <RaisedButton
                    key="accept"
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.secondaryColor100}
                    label="Accept"
                    onClick={this.handleAcceptClick.bind(this)}
                />
            )
        }
        if (credit.status === 'draft') {
            buttons.push(
                <RaisedButton
                    key="on hold"
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.primaryColor100}
                    label="Put on hold"
                    onClick={this.handleOnHoldClick.bind(this)}
                />
            )
        }
        if (credit.status === 'accepted') {
            buttons.push(
                <RaisedButton
                    key="synchronize"
                    disabled={this.state.syncDisabled}
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.primaryColor100}
                    label="Synchronize to Exact"
                    onClick={this.handleSynchronizeCreditMemoClick.bind(this)}
                />
            )
        }
        // in status 'prepared' (synchronized to Exact Online, but not finalized yet): display no buttons

        if (credit.status === 'invoiced' &&
            credit.trigger !== 'manual' &&
            (credit.payment_info.code.startsWith('msp_') || credit.payment_info.code === 'billink')
        ) {
            buttons.push(
                <RaisedButton disabled={this.state.refundButtonClicked}
                    key="refund"
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.secondaryColor100}
                    label="Refund"
                    onClick={this.handleRefundClick.bind(this)}
                />
            )
        } else if (credit.status === 'invoiced' || credit.status === 'refunding') {
            buttons.push(
                <RaisedButton
                    key="refunded"
                    style={style.rightButtonMargin}
                    labelStyle={style.rightButtonMarginLabel}
                    backgroundColor={Colors.orange800}
                    label="Refunded"
                    onClick={this.handleRefundedClick.bind(this)}
                />
            )
        }

        return buttons.length ? buttons : null
    }

    render() {
        let nodes = []
        const credit = this.props.credit_memo

        if (credit && credit.mage_id) {
            const title = this.getHeaderTitle(credit)
            const buttons = this.getActionButtons(credit)
            const vat_number = credit.customer.vat_number || '-'
            nodes = [
                <Header
                    key="header"
                    showSupplier={false}
                    title={title}
                    subheader={`Status: ${credit.status}`}
                    rightButtons={buttons}
                />,
                <div key="content" style={style.content}>
                    <div style={{ display: 'flex', flexFlow: 'column', width: '30%', paddingRight: '16px' }}>
                        <Info
                            style={style.info}
                            list={[
                                { text: 'Type', value: 'Credit memo' },
                                { text: 'Prior action', value: credit.prior_action },
                                { text: 'Exact Invoice Number', value: credit.invoice_id },
                                { text: 'Debit Invoice Number', value: credit.debit_invoice_id },
                                { text: 'Magento Order Number', value: credit.mage_id },
                                { text: 'Payment Method', value: credit.payment_info.method },
                                { text: 'VAT number', value: vat_number },
                                { text: 'Customer Name', value: credit.customer.name },
                                { text: 'Customer ID', value: credit.customer.mage_id },
                                {
                                    text: 'Billing E-mail',
                                    value: credit.customer.billing_email
                                        || credit.customer.email,
                                },
                                { text: 'Phone', value: credit.customer.phone_numbers },
                            ]}
                        />
                        <Notes
                            onAddNote={note => {
                                this.props.addNote(credit._id, {
                                    author: this.props.email,
                                    value: note,
                                    date: new Date().toISOString(),
                                })
                            }}
                            notes={credit.notes}
                        />
                    </div>
                    <LineItems
                        showTitle={true}
                        style={style.line_items}
                        items={credit.line_items}
                        total_pricing={this.getTotalPricing(credit)}
                    />
                </div>,
            ]
        }
        return (
            <div style={style.container}>
                {nodes}
            </div>
        )
    }
}

CreditMemoDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    credit_memo: PropTypes.object.isRequired,
    getCreditMemo: PropTypes.func.isRequired,
    markAsAccepted: PropTypes.func.isRequired,
    markAsRejected: PropTypes.func.isRequired,
    markAsToRefund: PropTypes.func.isRequired,
    markAsRefunded: PropTypes.func.isRequired,
    markAsOnHold: PropTypes.func.isRequired,
    synchronizeCreditMemo: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
}

CreditMemoDetail.defaultProps = {
    rtv: {},
    shipments: [],
}

export default connect(({ credit_memo = {} }) => {
    return { credit_memo }
}, dispatch => {
    const actions = {
        getCreditMemo,
        markAsRejected,
        markAsAccepted,
        markAsToRefund,
        markAsRefunded,
        markAsOnHold,
        synchronizeCreditMemo,
        addNote,
    }
    return bindActionCreators(actions, dispatch)
})(CreditMemoDetail)
