import socket from './../socket'
import listeners from '../listeners/sockets/rtvs.js'
import _ from 'lodash'
import moment from 'moment'

listeners(socket)

export function rtv(state = {}, action) {
    state.pdf = undefined
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_RTV_PDF':
            return { ...state, pdf: action.pdf }

        case 'REFRESH_RTV':
            if (action.rtv.submitted_at) {
                action.rtv.submitted_at = moment(action.rtv.submitted_at).format('DD-MM-YY')
            }
            return action.rtv

        default:
            return state
    }
}

export function rtvs(state = [], action) {
    switch (action.type) {
        case 'REFRESH_RTVS':
            return _.map(_.orderBy(action.rtvs, ['created_at'], ['desc']), r => {
                r.created_at = moment(r.created_at).format('DD-MM-YYYY')
                r.status = r.status.replace(/_/g, ' ').replace(/\w/, c => c.toUpperCase())
                r.type = r.type.replace(/-/g, ' ').replace(/\w/, c => c.toUpperCase())
                return r
            })

        default:
            return state
    }
}
