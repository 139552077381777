import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const initialState = {
    fulfillment_source: 'warehouse',
    email: null,
    estimations_from: null,
    estimations_to: null,
}

const styles = {
    dialogPaper: {
        minWidth: 640,
    },
    dialogActions: {
        margin: '0 20px 20px 20px',
    },
    outlinedInputLabel: {
        width: 'auto',
    },
    dateFields: {
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'row',
    },
    dateFieldFrom: {
        width: '100%',
        marginRight: 10,
    },
    dateFieldTo: {
        width: '100%',
        marginLeft: 10,
    },
}

class MailUnpickableItemsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {...initialState}

        this.handleFulfillmentSourceChange = this.handleFulfillmentSourceChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleEstimationsFromChange = this.handleEstimationsFromChange.bind(this)
        this.handleEstimationsToChange = this.handleEstimationsToChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleFulfillmentSourceChange({target}) {
        this.setState({fulfillment_source: target.value})
    }

    handleEmailChange({target}) {
        this.setState({email: target.value})
    }

    handleEstimationsFromChange({target}) {
        this.setState({estimations_from: target.value})
    }

    handleEstimationsToChange({target}) {
        this.setState({estimations_to: target.value})
    }

    handleSubmit() {
        const state = this.state
        this.setState({...initialState}, () => {
            this.props.onSubmit(state)
            if (this.props.closeOnSubmit) {
                this.handleClose()
            }
        })
    }

    handleClose() {
        this.props.onClose()
    }

    render() {
        return (
            <Dialog
                PaperProps={{style: styles.dialogPaper}}
                onClose={this.handleClose}
                aria-labelledby="mail-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="mail-dialog-title">Mail unpickable items</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select fulfillment source*</DialogContentText>
                    <FormControl variant="outlined" margin="normal">
                        <Select
                            required={true}
                            value={this.state.fulfillment_source}
                            onChange={this.handleFulfillmentSourceChange}
                            input={
                                <OutlinedInput
                                    labelWidth={styles.outlinedInputLabel.width}
                                    name="Fulfillment source"
                                    id="fulfillment_source"
                                />
                            }
                            margin="normal"
                        >
                            <MenuItem value="warehouse">
                                Warehouse
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <DialogContentText>
                        Select from / to estimation date*
                    </DialogContentText>
                    <FormControl variant="outlined" style={styles.dateFields}>
                        <TextField
                            style={styles.dateFieldFrom}
                            required={true}
                            label="From"
                            type="date"
                            InputLabelProps={{shrink: true}}
                            value={this.state.estimations_from}
                            onChange={this.handleEstimationsFromChange}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            style={styles.dateFieldTo}
                            required={true}
                            label="To"
                            type="date"
                            InputLabelProps={{shrink: true}}
                            value={this.state.estimations_to}
                            onChange={this.handleEstimationsToChange}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>
                    <DialogContentText>
                        Add email to mail to*
                    </DialogContentText>
                    <FormControl variant="outlined">
                        <TextField
                            required={true}
                            type="email"
                            InputLabelProps={{shrink: true}}
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions style={styles.dialogActions}>
                    <Button onClick={this.handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        Generate
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

MailUnpickableItemsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    closeOnSubmit: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
MailUnpickableItemsDialog.defaultProps = {
    open: false,
    closeOnSubmit: true,
}

export default MailUnpickableItemsDialog
