import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
    Paper,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Divider,
    ListItemSecondaryAction,
    IconButton,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import LoadingIcon from '@material-ui/icons/HourglassEmpty'
import moment from 'moment-timezone'
import { showErrorNotification, showOkNotification } from '../../../../actions/notifications'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import autoBind from 'react-autobind'
import { addClosedDate, deleteClosedDate } from '../../../../commands-sync/supplier'

const style = {
    container: {
        marginTop: '20px',
    },
    fromInput: {
        marginRight: '10px',
    },
    saveButton: {
        marginTop: '8px',
    },
    date: {
        cursor: 'pointer',
    },
}

class OrderDays extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            from: '',
            to: '',
            saving: false,
            deleting: false,
            showDatesAsTimestamps: false,
        }
        autoBind(this)
    }

    handleFromChange (event) {
        const newValue = event.target.value
        if (this.state.to && moment(newValue).startOf('day').isAfter(moment(this.state.to))) {
            this.props.showErrorNotification({
                message: '\'From\' date cannot be after \'to\' date',
            })
            this.setState({
                from: '',
            })
        } else if (moment(newValue).startOf('day').isBefore(moment())) {
            this.props.showErrorNotification({
                message: '\'From\' date cannot be before today',
            })
            this.setState({
                from: '',
            })
        } else {
            this.setState({
                from: newValue,
            })
        }
    }

    handleToChange (event) {
        const newValue = event.target.value
        if (this.state.from && moment(newValue).startOf('day').isBefore(moment(this.state.from))) {
            this.props.showErrorNotification({
                message: '\'to\' date cannot be before \'from\' date',
            })
            this.setState({
                to: '',
            })
        } else if (moment(newValue).startOf('day').isBefore(moment())) {
            this.props.showErrorNotification({
                message: '\'To\' date cannot be before today',
            })
            this.setState({
                to: '',
            })
        } else {
            this.setState({
                to: newValue,
            })
        }
    }

    handleSave() {
        if (this.state.saving) {
            return
        }
        if (!this.state.from) {
            this.props.showErrorNotification({
                message: '\'From\' date cannot be empty',
            })
            return
        }
        if (!this.state.to) {
            this.props.showErrorNotification({
                message: '\'To\' date cannot be empty',
            })
            return
        }
        this.setState({
            saving: true,
        })
        addClosedDate({
            from: this.state.from,
            to: this.state.to,
            supplier_id: this.props.supplier._id,
        })
            .then(() => {
                this.props.showOkNotification({
                    message: 'Date has been added',
                })
                this.setState({
                    saving: false,
                })
            })
            .catch(() => {
                this.props.showErrorNotification({
                    message: 'Couldn\'t add date',
                })
                this.setState({
                    saving: false,
                })
            })
    }

    handleDelete(id) {
        this.setState({
            deleting: true,
        })
        deleteClosedDate({
            supplier_id: this.props.supplier._id,
            id,
        })
            .then(() => {
                this.props.showOkNotification({
                    message: 'Date has been deleted',
                })
                this.setState({
                    deleting: false,
                })
            })
            .catch(() => {
                this.props.showErrorNotification({
                    message: 'Couldn\'t delete date',
                })
                this.setState({
                    deleting: false,
                })
            })
    }

    handleDateClicked() {
        this.setState({
            showDatesAsTimestamps: true,
        })
        setTimeout(() => {
            this.setState({
                showDatesAsTimestamps: false,
            })
        }, 3000)
    }


    renderListItems(dates) {
        return (
            <List>
                <ListSubheader>Closed dates</ListSubheader>
                <Divider />
                {dates.map(date => 
                    <ListItem key={date._id}>
                        <ListItemText>
                            <div style={style.date} onClick={this.handleDateClicked}>
                                From:{' '}
                                {this.state.showDatesAsTimestamps
                                    ? date.from
                                    : moment(date.from).utcOffset('+02:00').format('ll')
                                }
                            </div>
                            <div style={style.date} onClick={this.handleDateClicked}>
                                To:{' '}
                                {this.state.showDatesAsTimestamps
                                    ? date.from
                                    : moment(date.to).utcOffset('+02:00').subtract(1, 'minute').format('ll')
                                }
                            </div>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                id={date._id}
                                onClick={() => this.handleDelete(date._id)}
                            >
                                {this.state.deleting ? <LoadingIcon /> : <DeleteIcon style={{color: '#D34856'}} />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <Divider />
                <ListItem>
                    <ListItemText>
                        <TextField
                            style={style.fromInput}
                            value={this.state.from}
                            onChange={this.handleFromChange}
                            label="From"
                            type="date"
                        />
                        <TextField
                            value={this.state.to}
                            onChange={this.handleToChange}
                            label="To"
                            type="date"
                        />
                    </ListItemText>
                    <ListItemSecondaryAction style={style.saveButton}>
                        <IconButton edge="end" aria-label="delete" onClick={this.handleSave}>
                            {this.state.saving ? <LoadingIcon /> : <SaveIcon color="primary" />}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        )
    }

    render() {
        const supplier = this.props.supplier
        const days = _.get(supplier, 'non_processing_dates', [])
        const listItems = this.renderListItems(days)
        return (
            <Paper style={style.container}>
                {listItems}
            </Paper>
        )
    }
}

OrderDays.propTypes = {
    supplier: PropTypes.object,
    onOrderDayChange: PropTypes.func,
    features: PropTypes.object,
    showErrorNotification: PropTypes.func,
    showOkNotification: PropTypes.func,
}

export default connect(
    () => ({ }),
    dispatch => {
        return bindActionCreators({
            showErrorNotification,
            showOkNotification,
        }, dispatch)
    }
)(OrderDays)
