import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import {get} from 'lodash'

import ChannelAvatar from './../../shared/ChannelAvatar'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    channelColumn: {
        width: '3%',
    },
    channelAvatar: {
        width: 20, height: 20,
    },
    warningIcon: {
        fontSize: 14,
    },
    tableFooter: {
        height: 60,
    },
    tablePagination: {
        fontSize: 14,
    },
}

class OrderTable extends Component {

    constructor(props) {
        super(props)

        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    isLateForDelivery(order) {
        const today = new Date()
        today.setHours(23, 59, 59, 0)
        if (order.status === 'fulfilled' ||
            order.status === 'completed' ||
            order.status === 'unfulfillable') {
            return false
        }
        let pendingFulfillments = (order.fulfillments || []).filter(f => {
            return f.status !== 'fulfilled' &&
                f.status !== 'completed' &&
                f.status !== 'unfulfillable'
        })
        pendingFulfillments = pendingFulfillments.filter(f => f.needs_label)
        pendingFulfillments = pendingFulfillments.filter(f => f.estimated_for <= today.toISOString())
        return pendingFulfillments.length > 0
    }

    handleTableRowClick(rowNumber) {
        return () => { this.props.onTableRowClick(rowNumber) }
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    handleChangeRowsPerPage(e, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    getIndicationColorForOrder(order) {
        if (order.status === 'completed') {
            return 'rgba(39, 255, 0, 0.05)'
        }
        if (order.status === 'unfulfillable') {
            return 'rgba(255, 75, 75, 0.05)'
        }
        if (order.hasPendingCancellations) {
            return 'rgba(117, 191, 226, 0.05)'
        }
        if (order.isLateForDelivery) {
            return 'rgba(255, 204, 0, 0.05)'
        }
        return ''
    }

    renderOrderRows(orders) {
        if (orders.length > 0) {
            return orders.map((order, index) => {
                order.isLateForDelivery = this.isLateForDelivery(order)
                return (
                    <TableRow
                        hover
                        key={index}
                        onClick={this.handleTableRowClick(index)}
                        style={{cursor: 'pointer', backgroundColor: this.getIndicationColorForOrder(order)}}
                    >
                        <TableCell style={styles.channelColumn}>
                            <ChannelAvatar style={styles.channelAvatar} slug={get(order, 'channel.slug', '')}/>
                        </TableCell>
                        {
                            this.props.headerCols.map((column, i) => {
                                let icon = null
                                if (column.property === 'full_address' && order.shipping_address.invalid === true) {
                                    icon = (
                                        <WarningIcon color="red" style={styles.warningIcon} />
                                    )
                                }
                                return (
                                    <TableCell
                                        style={icon ? {...column.style, color: 'red'} : column.style}
                                        key={i}
                                    >
                                        {order[column.property]}&nbsp;{icon}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                )
            })
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length + 1}>No orders found.</TableCell>
            </TableRow>
        )
    }

    renderNavigation(orders) {
        const {page, limit, headerCols} = this.props
        const from = Math.max(0, (limit * page))
        const to = Math.min(from + limit, from + orders.length)
        return (
            <TableFooter style={styles.tableFooter}>
                <TableRow>
                    <TableCell colSpan={headerCols.length + 1}>
                        <TablePagination
                            component="div"
                            style={styles.tablePagination}
                            count={orders.length}
                            rowsPerPage={limit}
                            page={page} // 0 is first page
                            labelRowsPerPage={<span style={styles.tablePagination}>Number of orders per page</span>}
                            labelDisplayedRows={() => {
                                return (<span style={styles.tablePagination}>{`${from + 1} - ${to}`}</span>)
                            }}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                                disabled: page === 0,
                                onClick: this.handlePageDecrementClick,
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                disabled: orders.length < limit,
                                onClick: this.handlePageIncrementClick,
                            }}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onPageChange={() => {}}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        )
    }

    render() {
        const orders = this.props.sales_orders.map(this.props.mapOrder)
        const orderRows = this.renderOrderRows(orders)
        const navigation = this.renderNavigation(orders)

        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.channelColumn} />
                        {
                            this.props.headerCols.map((column, index) => {
                                return (
                                    <TableCell
                                        variant="head"
                                        style={{...column.style, fontWeight: 'bold'}}
                                        key={index}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

OrderTable.propTypes = {
    headerCols: PropTypes.array,
    sales_orders: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,

    mapOrder: PropTypes.func.isRequired,
    onTableRowClick: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
}


OrderTable.defaultProps = {
    headerCols: [{
        content: 'Order ID',
        tooltip: 'The Order ID',
        style: {width: '10% '},
        property: 'mage_id',
    }, {
        content: 'Date',
        tooltip: 'Order date',
        style: {width: '10% '},
        property: 'created_at',
    }, {
        content: 'Name',
        tooltip: 'Name',
        style: {width: '15% '},
        property: 'name',
    }, {
        content: 'Shipping address',
        tooltip: 'Shipping address',
        style: {width: '30% '},
        property: 'full_address',
    }, {
        content: 'Orderlines',
        tooltip: 'Orderline amount',
        style: {width: '10% '},
        property: 'order_lines_amount',
    }, {
        content: 'Total amount (in tax)',
        tooltip: 'Total amount of the order (incl. tax)',
        style: {width: '10% '},
        property: 'total_amount',
    }, {
        content: 'Status',
        tooltip: 'Status of the order',
        style: {width: '15% '},
        property: 'status',
    }],
}


export default OrderTable
