import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import PersonalisationConfigImage from './PersonalisationConfigImage'

const style = {
    container: {
        display: 'flex',
        flexFlow: 'column',
    },
    images: {
        display: 'flex',
        flexFlow: 'row',
    },
}

function PersonalisationOptions(props) {
    return (
        <div style={{...style.container, ...props.style}}>
            <div style={style.images}>
                <PersonalisationConfigImage src={props.type}/>
                <PersonalisationConfigImage src={props.position}/>
                <PersonalisationConfigImage src={props.colors}/>
            </div>
            {
                !props.logo ? null : (
                    <a href={props.logo}>
                        <Button color="primary">
                            <ArrowDownwardIcon />
                            Download logo
                        </Button>
                    </a>
                )
            }
        </div>
    )
}

PersonalisationOptions.propTypes = {
    style: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    colors: PropTypes.string.isRequired,
    logo: PropTypes.string,
}
PersonalisationOptions.defaultProps = {
    logo: null,
}

export default PersonalisationOptions
