import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    listPickLists,
    createPickList,
    generateDeliveryOrderPickLists,
    generateExcessInventoryPickLists,
} from './../../../../../commands/sockets/pick_lists'
import { mailUndeliverableItems } from '../../../../../commands/sockets/mails'
import { searchSuppliers } from '../../../../../commands/sockets/suppliers'

import moment from 'moment'

import AddIcon from '@material-ui/icons/Add'
import Repeat from '@material-ui/icons/Repeat'
import RepeatOne from '@material-ui/icons/RepeatOne'
import WarningIcon from '@material-ui/icons/Warning'

import Zoom from '@material-ui/core/Zoom'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import PickListTable from './PickListTable'
import GeneratePickListsDialog from './GeneratePickListsDialog'
import GenerateSinglePiecePickListsDialog from './GenerateSinglePiecePickListsDialog'
import MailUnpickableItemsDialog from './MailUnpickableItemsDialog'

import { Processes, PickListType } from '../../../../types/logistics/picking'

const styles = {
    container: {
        width: '100%',
        position: 'relative',
    },
    addButton: {
        outline: 'none',
        position: 'fixed',
        bottom: 12,
        left: 12,
    },
    generateButton: {
        outline: 'none',
        position: 'fixed',
        bottom: 12,
        left: 76,
    },
    generateSinglePieceButton: {
        outline: 'none',
        position: 'fixed',
        bottom: 12,
        left: 140,
    },
    mailButton: {
        outline: 'none',
        position: 'fixed',
        bottom: 12,
        left: 205,
    },
}

class PickListList extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            page: 1, // 1 is first page
            limit: 20,
            shouldRender: true,
            generateDialogOpen: false,
            generateSinglePieceDialogOpen: false,
            mailDialogOpen: false,
        }

        autoBind(this)
    }

    updateShouldRenderIfNeeded(prevProps) {
        if (this.state.shouldRender === false && prevProps.pick_lists !== this.props.pick_lists) {
            this.setState({
                shouldRender: true,
            })
        }
    }

    componentDidMount() {
        this.handleListPickLists()
    }

    componentDidUpdate(prevProps) {
        this.updateShouldRenderIfNeeded(prevProps)
    }

    getFilters() {
        const { limit, page } = this.state
        return { limit, page }
    }

    handleListPickLists() {
        const filters = this.getFilters()
        this.props.listPickLists(filters)
    }

    handlePickListClick(pick_list) {
        this.context.router.push(`/logistics/picking/pick-lists/${pick_list.increment_id}`)
    }

    handleLimitChange(limit) {
        this.setState({
            shouldRender: false,
            limit,
        }, this.handleListPickLists)
    }

    handlePageIncrement() {
        this.setState({
            shouldRender: false,
            page: this.state.page + 1,
        }, this.handleListPickLists)
    }

    handlePageDecrement() {
        this.setState(state => ({
            shouldRender: false,
            page: state.page - 1,
        }), this.handleListPickLists)
    }

    handleButtonClick() {
        this.props.createPickList()
    }

    handleGeneratePickListsButtonClick() {
        this.setState({
            generateDialogOpen: true,
        }, () => {
            this.props.searchSuppliers({})
        })
    }

    handleGenerateSinglePiecePickListsDialogButtonClick() {
        this.setState({
            generateSinglePieceDialogOpen: true,
        }, () => {
            this.props.searchSuppliers({})
        })
    }

    handleMailUnpickableItemsDialogClick() {
        this.setState({
            mailDialogOpen: true,
        })
    }

    handleGeneratePickListsDialogSubmit(form) {
        if (form.process === Processes.DELIVERY_ORDER) {
            const { fulfillment_source, limit, estimations_from, estimations_to, pick_list_type } = form

            this.props.generateDeliveryOrderPickLists({
                fulfillment_source,
                limit: +limit,
                pick_list_type,
                estimations: {
                    from: estimations_from ? moment(estimations_from).toISOString() : '',
                    to: estimations_to ? moment(estimations_to).toISOString() : '',
                },
            })
        } else if (form.process === Processes.EXCESS_INVENTORY) {
            const { supplier_id } = form
            const { mage_id, name } = this.props.suppliers.find(s => s.mage_id === +supplier_id)
            this.props.generateExcessInventoryPickLists({
                supplier: {
                    mage_id,
                    name,
                },
            })
        }
    }


    handleGenerateSinglePiecePickListsDialogSubmit(form) {
        if (form.process === Processes.DELIVERY_ORDER) {
            const { fulfillment_source, limit, estimations_from, estimations_to } = form

            this.props.generateDeliveryOrderPickLists({
                fulfillment_source,
                limit: +limit,
                estimations: {
                    from: estimations_from ? moment(estimations_from).toISOString() : '',
                    to: estimations_to ? moment(estimations_to).toISOString() : '',
                },
                pick_list_type: PickListType.SINGLE_ITEM,
            })
        }
    }

    handleGeneratePickListsDialogClose() {
        this.setState({
            generateDialogOpen: false,
        })
    }

    handleGenerateSinglePiecePickListsDialogClose() {
        this.setState({
            generateSinglePieceDialogOpen: false,
        })
    }

    handleMailUnpickableItemsDialogSubmit(form) {
        const { fulfillment_source, email, estimations_from, estimations_to } = form
        this.props.mailUndeliverableItems({
            fulfillment_source,
            email,
            estimations: {
                from: estimations_from ? moment(estimations_from).toISOString() : '',
                to: estimations_to ? moment(estimations_to).toISOString() : '',
            },
        })
    }

    handleMailUnpickableItemsDialogClose() {
        this.setState({
            mailDialogOpen: false,
        })
    }

    render() {
        return (
            <div style={styles.container}>
                <PickListTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.page - 1}
                    limit={this.state.limit}
                    pick_lists={this.props.pick_lists}
                    onPickListClick={this.handlePickListClick}
                    onLimitChange={this.handleLimitChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip
                        style={styles.addButton}
                        title="CREATE NEW PICK LIST"
                    >
                        <Fab
                            color="primary"
                            onClick={this.handleButtonClick}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Zoom>
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip
                        style={styles.generateButton}
                        title="GENERATE PICK LISTS FROM SHIPMENTS"
                    >
                        <Fab
                            color="primary"
                            onClick={this.handleGeneratePickListsButtonClick}
                        >
                            <Repeat />
                        </Fab>
                    </Tooltip>
                </Zoom>
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip
                        style={styles.mailButton}
                        title="MAIL UNPICKABLE ITEMS"
                    >
                        <Fab
                            color="primary"
                            onClick={this.handleMailUnpickableItemsDialogClick}
                        >
                            <WarningIcon />
                        </Fab>
                    </Tooltip>
                </Zoom>
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip
                        style={styles.generateSinglePieceButton}
                        title="GENERATE SINGLE-PIECE PICK LISTS FROM SHIPMENTS"
                    >
                        <Fab
                            color="primary"
                            onClick={this.handleGenerateSinglePiecePickListsDialogButtonClick}
                        >
                            <RepeatOne />
                        </Fab>
                    </Tooltip>
                </Zoom>
                <GeneratePickListsDialog
                    open={this.state.generateDialogOpen}
                    onSubmit={this.handleGeneratePickListsDialogSubmit}
                    onClose={this.handleGeneratePickListsDialogClose}
                    closeOnSubmit={true}
                />
                <GenerateSinglePiecePickListsDialog
                    open={this.state.generateSinglePieceDialogOpen}
                    onSubmit={this.handleGenerateSinglePiecePickListsDialogSubmit}
                    onClose={this.handleGenerateSinglePiecePickListsDialogClose}
                    closeOnSubmit={true}
                />
                <MailUnpickableItemsDialog
                    open={this.state.mailDialogOpen}
                    onSubmit={this.handleMailUnpickableItemsDialogSubmit}
                    onClose={this.handleMailUnpickableItemsDialogClose}
                    closeOnSubmit={true}
                />
            </div>
        )
    }
}

PickListList.contextTypes = {
    router: PropTypes.object.isRequired,
}

PickListList.propTypes = {
    pick_lists: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
    searchSuppliers: PropTypes.func.isRequired,
    listPickLists: PropTypes.func.isRequired,
    createPickList: PropTypes.func.isRequired,
    generateDeliveryOrderPickLists: PropTypes.func.isRequired,
    generateExcessInventoryPickLists: PropTypes.func.isRequired,
    mailUndeliverableItems: PropTypes.func.isRequired,
}

PickListList.defaultProps = {
    pick_lists: [],
}

export default connect(({ pick_lists, suppliers }) => {
    return {
        pick_lists,
        suppliers,
    }
}, dispatch => {
    return bindActionCreators({
        searchSuppliers,
        listPickLists,
        createPickList,
        generateDeliveryOrderPickLists,
        generateExcessInventoryPickLists,
        mailUndeliverableItems,
    }, dispatch)
})(PickListList)
