import socket from './../socket'
import listeners from '../listeners/sockets/settings'

listeners(socket)

export function features(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_FEATURE':
            const isInState = state.some(feature => feature.name === action.data.name)
            if (isInState) {
                return state.map(feature => feature.name === action.data.name ? action.data : feature)
            }
            return [ ...state, action.data ]

        case 'REFRESH_FEATURES':
            return action.data ?? []

        default:
            return state
    }
}
