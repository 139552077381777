import socket from './../socket'
import listeners from '../listeners/sockets/suppliers'

listeners(socket)

export function suppliers(state = [], action) {
    switch (action.type) {
        case 'REFRESH_SUPPLIERS':
            return action.suppliers

        default:
            return state
    }
}

export function supplier(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_SUPPLIER':
            return action.supplier

        default:
            return state
    }
}
