import React, {Component} from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import _ from 'lodash'

import PurchaseOrderFilters from './../purchase-orders/PurchaseOrderFilters'
import PurchaseOrderTable from './../purchase-orders/PurchaseOrderTable'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {searchOutboundChecklists} from './../../../../actions/outbound_checklists'
import {searchSuppliers} from './../../../../commands/sockets/suppliers'

const STORAGE_KEY = 'outbound-checklist-filters'

class PurchaseOutboundList extends Component {

    constructor(props) {
        super(props)
        const date = new Date()
        const filters = this.getFilters()
        this.state = {
            shouldTableRender: false,
            shouldFiltersRender: true,
            addPurchaseOpen: false,
            filters: {
                fields: 'order_id,created_at,supplier,status',
                value: filters.value || '',
                supplier_id: this.getDefaultSupplierFilter(props.supplier_id || filters.supplier_id),
                status: filters.status || 'open',
                from_date: filters.from_date || new Date(date.getFullYear(), 0, 1),
                till_date: filters.till_date || new Date(date.getFullYear(), 11, 31),
                limit: 20,
                page: filters.page || 1,
                sorting: {created_at: -1},
            },
        }
    }

    getDefaultSupplierFilter(supplier_id) {
        return this.isSupplier() ? `${this.props.supplier_id}` : `${supplier_id}` || 'all'
    }

    componentDidMount() {
        this.searchOutboundChecklists()
        if (!this.isSupplier()) {
            this.props.searchSuppliers({})
        }
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    searchOutboundChecklists() {
        const filters = this.getFilterState()
        this.storeFilters(filters)
        if (filters.supplier_id === 'all') {
            delete filters.supplier_id
        }
        if (filters.status === 'all') {
            delete filters.status
        }
        if (filters.status === 'open') {
            filters.open = true
            delete filters.status
        }
        filters.type = 'outbound'
        filters.group_by = 'purchase_id'
        filters.sorting = {purchase_id: -1}
        this.setState({
            shouldTableRender: true,
        }, () => {
            this.props.searchOutboundChecklists(filters)
        })
    }

    getFilterState() {
        return _.cloneDeep(this.state.filters)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchOutboundChecklists.bind(this), 500)
    }

    setFilterStateWithoutRender(filters, timeout) {
        const newState = reactUpdate(this.state, {
            filters,
            shouldTableRender: {$set: false},
            shouldFiltersRender: {$set: true},
        })
        this.setState(newState, timeout ? this.setSearchTimeout.bind(this) : this.searchOutboundChecklists.bind(this))
    }

    handleSearchChange(value) {
        this.setFilterStateWithoutRender({
            value: {$set: value},
            page: {$set: 1},
        }, true)
    }

    handleSupplierChange(supplier_id) {
        this.setFilterStateWithoutRender({
            supplier_id: {$set: supplier_id},
            page: {$set: 1},
        }, false)
    }

    handleStatusChange(status) {
        this.setFilterStateWithoutRender({
            status: {$set: status},
            page: {$set: 1},
        }, false)
    }

    handleFromDateChange(from_date) {
        this.setFilterStateWithoutRender({
            from_date: {$set: from_date},
            page: {$set: 1},
        }, false)
    }

    handleTillDateChange(till_date) {
        this.setFilterStateWithoutRender({
            till_date: {$set: till_date},
            page: {$set: 1},
        }, false)
    }

    handlePageDecrement() {
        this.setFilterStateWithoutRender({
            page: {$apply: v => v - 1},
        }, false)
    }

    handlePageIncrement() {
        this.setFilterStateWithoutRender({
            page: {$apply: v => v + 1},
        }, false)
    }

    handleNavigate(index) {
        const item = this.props.outbound_checklists[index]
        this.context.router.push(`/purchases/purchase-orders/outbound/${item._id}`)
    }

    isSupplier() {
        return this.props.role === 'supplier'
    }

    render() {
        return (
            <div>
                <PurchaseOrderFilters
                    extended={!this.isSupplier()}
                    shouldRender={this.state.shouldFiltersRender}
                    filters={this.getFilterState()}
                    suppliers={this.props.suppliers}
                    onSearchChange={this.handleSearchChange.bind(this)}
                    onSupplierChange={this.handleSupplierChange.bind(this)}
                    onStatusChange={this.handleStatusChange.bind(this)}
                    onFromDateChange={this.handleFromDateChange.bind(this)}
                    onTillDateChange={this.handleTillDateChange.bind(this)}
                />
                <PurchaseOrderTable
                    extended={!this.isSupplier()}
                    items={this.props.outbound_checklists}
                    shouldRender={this.state.shouldTableRender}
                    page={this.state.filters.page}
                    limit={this.state.filters.limit}
                    onNavigate={this.handleNavigate.bind(this)}
                    onPageIncrement={this.handlePageIncrement.bind(this)}
                    onPageDecrement={this.handlePageDecrement.bind(this)}
                />
            </div>
        )
    }
}

PurchaseOutboundList.propTypes = {
    role: PropTypes.string,
    supplier_id: PropTypes.string,
    filters: PropTypes.object,
    suppliers: PropTypes.array,
    outbound_checklists: PropTypes.array,
    searchSuppliers: PropTypes.func,
    searchOutboundChecklists: PropTypes.func.isRequired,
}

PurchaseOutboundList.contextTypes = {
    router: PropTypes.object,
}

export default connect(({outbound_checklists, suppliers, filters}) => {
    return {
        outbound_checklists,
        suppliers,
        filters: filters.outbound_checklists || {},
    }
}, dispatch => {
    const actions = {searchOutboundChecklists, searchSuppliers}
    return bindActionCreators(actions, dispatch)
})(PurchaseOutboundList)
