import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import get from 'lodash/get'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    row: { cursor: 'pointer' },
}

class DataTable extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    handleTableRowClick(index) {
        return this.props.onTableRowClick && this.props.onTableRowClick(index)
    }

    renderRows(rows) {
        if (rows.length > 0) {
            return rows.map((row, index) => {
                row = typeof this.props.mapRow === 'function' ? this.props.mapRow(row) : row
                return (
                    <TableRow
                        onClick={() => this.handleTableRowClick(index)}
                        key={row[this.props.rowKeyField]}
                        style={styles.row}
                    >
                        {
                            this.props.headerCols.map(column => {
                                const value = get(row, column.property)
                                return (
                                    <TableCell
                                        key={column.property}
                                        style={column.style}
                                    >
                                        {column.format
                                            ? column.format(value)
                                            : value}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                )
            })
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>
                    {this.props.emptyListMessage}
                </TableCell>
            </TableRow>
        )
    }

    handleNextPageClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    handlePreviousPageClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    renderNavigation(rows) {
        const { page, limit, headerCols } = this.props
        const from = Math.max(0, (limit * page))
        const to = Math.min(from + limit, from + rows.length)
        return (
            <TableFooter style={{ height: 60 }}>
                <TableRow>
                    <TableCell colSpan={headerCols.length}>
                        <TablePagination
                            component="div"
                            count={rows.length}
                            rowsPerPage={limit}
                            page={page}

                            labelDisplayedRows={() => `${from + 1} - ${to}`}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                                onClick: this.handlePreviousPageClick,
                                disabled: page === 0,
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                onClick: this.handleNextPageClick,
                                disabled: rows.length < limit,
                            }}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={() => { }}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        )
    }

    render() {
        if (!this.props.headerCols || this.props.headerCols.length === 0) {
            return null
        }
        const rows = this.props.rows
        const navigation = this.renderNavigation(rows)
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            this.props.headerCols.map(column => {
                                return (
                                    <TableCell
                                        key={column.property}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderRows(rows)}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

DataTable.propTypes = {
    headerCols: PropTypes.array,
    emptyListMessage: PropTypes.string,
    rowKeyField: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,

    mapRow: PropTypes.func,
    onTableRowClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

export default DataTable
