import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableFooter,
    Paper,
    Typography,
} from '@material-ui/core'

const style = {
    title: {
        padding: 16,
    },
    footerRowText: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '1.3333333rem',
    },
}

class LineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { display } = this.context.utils.pricing
        const Parent = this.props.parent || Paper
        const content = (
            <Table
                size="small"
                style={{ maxHeight: window.innerHeight }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Article Number</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Base Price</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Row Subtotal</TableCell>
                        <TableCell>Row Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.items.map(line_item => {
                            return (
                                <TableRow key={line_item.sku}>
                                    <TableCell>{line_item.name}</TableCell>
                                    <TableCell>{line_item.sku}</TableCell>
                                    <TableCell>{line_item.ean}</TableCell>
                                    <TableCell>
                                        {display(line_item.base_price)}
                                    </TableCell>
                                    <TableCell>
                                        {display(line_item.base_discount)}
                                    </TableCell>
                                    <TableCell>{line_item.quantity}</TableCell>
                                    <TableCell>{display(line_item.row_total_excl_tax)}</TableCell>
                                    <TableCell>{display(line_item.row_total_incl_tax)}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                <TableFooter adjustForCheckbox={false}>
                    {this.props.total_pricing.discount === '0.0000' ? [] : (
                        <TableRow>
                            <TableCell colSpan="6" style={style.footerRowText}>
                                {this.props.total_pricing.version === 3 ? 'Percentage Discount' : 'Discount'}
                            </TableCell>
                            <TableCell>
                                {display(this.props.total_pricing.discount)}
                            </TableCell>
                            <TableCell>
                                {' '}
                            </TableCell>
                        </TableRow>
                    )}
                    {this.props.total_pricing.fixed_discount_excl_tax === '0.0000' ? [] : (
                        <TableRow>
                            <TableCell colSpan="6" style={style.footerRowText}>
                                Fixed Discount
                            </TableCell>
                            <TableCell>
                                {display(this.props.total_pricing.fixed_discount_excl_tax)}
                            </TableCell>
                            <TableCell>
                                {' '}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan="6" style={style.footerRowText}>
                            Subtotal
                        </TableCell>
                        <TableCell>
                            {display(this.props.total_pricing.subtotal)}
                        </TableCell>
                        <TableCell>
                            {' '}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan="7" style={style.footerRowText}>
                            Tax
                        </TableCell>
                        <TableCell>
                            {display(this.props.total_pricing.tax)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan="7" style={style.footerRowText}>
                            Total
                        </TableCell>
                        <TableCell>
                            {display(this.props.total_pricing.total)}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        )
        return !this.props.showTitle
            ? content
            : (
                <Parent style={this.props.style}>
                    <Typography variant="h6" style={style.title}>Line items</Typography>
                    {content}
                </Parent>
            )
    }
}

LineItems.propTypes = {
    showTitle: PropTypes.bool,
    parent: PropTypes.node,
    style: PropTypes.object,
    items: PropTypes.array,
    total_pricing: PropTypes.object,
}

LineItems.defaultProps = {
    showTitle: false,
}

LineItems.contextTypes = {
    utils: PropTypes.object,
}

export default LineItems
