import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { FontIcon } from 'material-ui'
import Button from '@material-ui/core/Button'

import SupplierFilter from './../../shared/SupplierFilter'

import { Statuses } from '../../../types/purchase/purchase_order'

const style = {
    filtersContainer: {
        height: 60,
        width: '95%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
        paddingTop: 12,
    },
    searchField: {
        marginRight: 20,
        width: '50%',
    },
    supplierFilter: {
        width: 400,
        marginRight: 15,
    },
    statusFilter: {
        width: 200,
        marginRight: 15,
    },
    datePicker: {
        cursor: 'pointer',
        marginRight: 15,
    },
    postponeButton: {
        paddingLeft: 36,
        paddingRight: 36,
    },
}

const statuses = [...Object.values(Statuses)]

class PurchaseOrderFilters extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    handleSearchChange(e) {
        this.props.onSearchChange(e.target.value)
    }

    handleSupplierChange(e) {
        this.props.onSupplierChange(e.target.value)
    }

    handleStatusChange(e) {
        this.props.onStatusChange(e.target.value)
    }

    handleFromDateChange({ target }) {
        this.props.onFromDateChange(target.value)
    }

    handleTillDateChange({ target }) {
        this.props.onTillDateChange(target.value)
    }

    handlePostponeViaCsvClick() {
        this.props.onPostponeViaCsvClick()
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={style.searchField}
                    label="Search by purchase order number"
                    value={this.props.filters.value}
                    onChange={this.handleSearchChange}
                    InputLabelProps={{ shrink: true }}
                />
                <SupplierFilter
                    key="supplier_filter"
                    style={style.supplierFilter}
                    onChange={this.handleSupplierChange}
                    value={this.props.filters.supplier_id}
                    allowAll={true}
                />
                <TextField
                    select
                    key="status_filter"
                    style={style.statusFilter}
                    onChange={this.handleStatusChange}
                    value={this.props.filters.status}
                    label="Select status"
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    <MenuItem
                        value="open"
                    >
                        Open
                    </MenuItem>
                    <Divider />
                    {
                        statuses.map(status => (
                            <MenuItem
                                key={status}
                                value={status.toLowerCase()}
                            >
                                {status.replace(/_/g, ' ').replace(/\w/, c => c.toUpperCase())}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    id="from"
                    style={style.datePicker}
                    label="Show orders since"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={this.props.filters.from_date}
                    onChange={this.handleFromDateChange}
                />
                <TextField
                    id="to"
                    style={style.datePicker}
                    label="Show orders till"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={this.props.filters.till_date}
                    onChange={this.handleTillDateChange}
                />
                <Button
                    key="postpone-via-csv"
                    style={style.postponeButton}
                    variant="contained"
                    color="primary"
                    icon={<FontIcon className="material-icons">upload_file</FontIcon>}
                    onClick={this.handlePostponeViaCsvClick}
                >
                    Postpone&nbsp;via&nbsp;CSV
                </Button>
            </div>
        )
    }
}

PurchaseOrderFilters.propTypes = {
    shouldRender: PropTypes.bool,
    filters: PropTypes.object.isRequired,
    suppliers: PropTypes.array.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onSupplierChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onFromDateChange: PropTypes.func.isRequired,
    onTillDateChange: PropTypes.func.isRequired,
    onPostponeViaCsvClick: PropTypes.func.isRequired,
}

PurchaseOrderFilters.defaultProps = {
    extended: false,
}


export default PurchaseOrderFilters
