import React, {Component} from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

class SwitchFilter extends Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        this.props.onFilterChange({
            property: this.props.filterProperty,
            value: e.target.checked,
        })
    }

    render() {
        return (
            <FormControlLabel
                style={this.props.style}
                control={(
                    <Switch
                        color="primary"
                        onChange={this.handleChange}
                        disabled={this.props.disabled}
                        checked={this.props.filterValue}
                    />
                )}
                label={this.props.filterLabel}
            />
        )
    }
}

SwitchFilter.propTypes = {
    style: PropTypes.object,
    filterProperty: PropTypes.string.isRequired,
    filterLabel: PropTypes.string,
    filterValue: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
}

export default SwitchFilter
