import socket from './../socket'
import listeners from '../listeners/sockets/packing_items.js'

listeners(socket)

function reduceItemsBySku(line_items) {
    return line_items.reduce((acc, item) => {
        const found = acc.find(i => i.sku === item.sku)
        if (found) {
            found.quantity += item.quantity
            found.packed_quantity += item.packed_quantity
        } else {
            acc.push({...item})
        }
        return acc
    }, [])
}

export function packing_item(state = {}, action) {
    switch (action.type) {
        case 'START_PROCESSING_PACKING_ITEM':
            return {
                ...state,
                processing: true,
            }

        case 'PRINTED_PACKING_SLIP':
            return {
                ...state,
                printed: state.shipment_id === action.data.shipment_id ? true : state.printed,
            }

        case 'UNPRINTABLE_PACKING_SLIP':
            return {
                ...state,
                printed: state.shipment_id === action.data.shipment_id ? false : state.printed,
            }

        case 'REFRESH_PACKING_ITEM':
            return {
                ...action.data,
                printed: null,
            }

        case 'UPDATE_LINE_ITEM_PACKED_QUANTITY':
            return {
                ...state,
                line_items: state.line_items.map((item, index) => {
                    return (action.item_index !== index)
                        ? item
                        : { ...item, packed_quantity: action.packed_quantity }
                }),
            }

        case 'UNREGISTER_PACKING_STATION':
            return {}

        default:
            return state
    }
}

export function unpacked_counts(state = {count: 0, warehouse: 0, crossdock: 0, personalisation: 0}, action) {
    switch (action.type) {
        case 'GET_UNPACKED_COUNTS':
            socket.emit('packing-items/unpacked', {})
            return state

        case 'REFRESH_UNPACKED_COUNTS':
            return action.counts

        default:
            return state
    }
}

export function packing_items(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SEARCH_PACKING_ITEMS':
            socket.emit('packing-items/search', action.filters)
            return state

        case 'REFRESH_PACKING_ITEMS':
            return action.packing_items.map(pitem => {
                pitem.line_items = pitem.line_items.map(li => {
                    if (li.packed_quantity === undefined) {
                        li.packed_quantity = 0
                    }
                    return li
                })
                return pitem
            })

        case 'REFRESH_PACKING_ITEM':
            return state.map(p => {
                if (p.shipment_id !== action?.packing_item?.shipment_id) {
                    return p
                }
                return action.packing_item
            })

        case 'SUBMIT_PACKING_ITEM_PACKED_LINE_ITEMS':
            action.data.line_items = reduceItemsBySku(action.data.line_items)
            socket.emit('packing-item/line-items/packed', action.data)
            return state

        case 'UPDATE_PACKING_ITEM_LINE_ITEMS':
            return state.map(p => {
                if (p.shipment_id !== action.packing_item.shipment_id) {
                    return p
                }
                action.packing_item.changed = true
                action.packing_item.line_items = reduceItemsBySku(action.packing_item.line_items)
                return action.packing_item
            })

        default:
            return state
    }
}
