import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHeader,
    TableRow,
    TableHeaderColumn,
    TableBody,
    TableRowColumn,
    TextField,
} from 'material-ui'
import { Tooltip } from '@material-ui/core'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import LocationOffOutlinedIcon from '@material-ui/icons/LocationOffOutlined'

const styles = {
    tableNameColumn: {
        paddingLeft: 0, whiteSpace: 'pre-wrap',
    },
    tableEanColumn: {
        width: 142,
    },
    tableQtyColumn: {
        width: 72, whiteSpace: 'normal',
    },
    textField: {
        width: 50,
    },
    stockAvailableColumn: {
        width: 10, color: 'orange',
    },
    stockAvailableTooltip: {
        fontSize: 18,
    },
    stockAvailableTooltipText: {
        fontSize: 12,
        lineHeight: '18px',
    },
    tableRowPromo: {
        backgroundColor: '#f5f5f5',
    },
    icon: {
        verticalAlign: 'middle',
        marginRight: 5,
    },
    promoTip: {
        color: '#8f8d8d',
    },
}

class PackingLineItems extends Component {

    handleTextFieldChange(event, index, item) {
        let value = Math.max(0, +event.target.value)
        value = Math.min(value, item.quantity)
        event.target.blur()
        return this.props.onEditableQuantityChange && this.props.onEditableQuantityChange(value, index)
    }

    render() {
        const translate = this.context.translate
        return (
            <div style={this.props.style}>
                <Table selectable={false}>
                    <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn style={styles.tableQtyColumn}>
                                {translate('Quantity')}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={styles.tableNameColumn}>
                                {translate('Name')}
                            </TableHeaderColumn>
                            <TableHeaderColumn>{translate('SKU')}</TableHeaderColumn>
                            <TableHeaderColumn
                                style={styles.tableEanColumn}
                            >{translate('EAN')}</TableHeaderColumn>
                            {!this.props.editable ? null : (
                                <TableHeaderColumn style={styles.tableQtyColumn}>
                                    {translate(this.props.editable.label)}
                                </TableHeaderColumn>
                            )}
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {this.props.line_items.map((item, index) => (
                            <TableRow key={`${item.sku}.${index}`}>
                                <TableRowColumn style={styles.tableQtyColumn}>{item.quantity}</TableRowColumn>
                                <TableRowColumn style={styles.tableNameColumn}>{item.name}</TableRowColumn>
                                <TableRowColumn>{item.sku}</TableRowColumn>
                                <TableRowColumn style={styles.tableEanColumn}>{item.ean}</TableRowColumn>
                                <TableRowColumn style={styles.stockAvailableColumn}>
                                    {item.missingStock &&
                                        <Tooltip style={styles.stockAvailableTooltip}
                                            title={
                                                <div style={styles.stockAvailableTooltipText}>
                                                    Deze sku ligt niet meer op een voorraadschap.
                                                    Let op! Deze kan nog wel op een kar liggen.
                                                </div>
                                            }>
                                            <LocationOffOutlinedIcon />
                                        </Tooltip>}
                                </TableRowColumn>
                                <TableRowColumn style={styles.tableQtyColumn}>
                                    <TextField
                                        name={`${item.sku}.${index}`}
                                        value={item[this.props.editable.property]}
                                        type="number"
                                        style={styles.textField}
                                        onChange={e => this.handleTextFieldChange(e, index, item)}
                                        readonly={true}
                                    />
                                </TableRowColumn>
                            </TableRow>
                        ))}

                        { this.props.promo_items?.length && (
                            <>
                                <TableRow style={styles.tableRowPromo}>
                                    <TableRowColumn style={styles.tableQtyColumn}></TableRowColumn>
                                    <TableRowColumn style={styles.tableNameColumn}>
                                        <b>Giveaway Items</b>
                                    </TableRowColumn>
                                    <TableRowColumn />
                                    <TableRowColumn />
                                    <TableRowColumn />
                                    <TableRowColumn />
                                </TableRow>

                                {this.props.promo_items.map((item, index) => (
                                    <TableRow style={styles.tableRowPromo} key={`${item.sku}.${index}`}>
                                        <TableRowColumn style={styles.tableQtyColumn}>1</TableRowColumn>
                                        <TableRowColumn style={styles.tableNameColumn}>
                                            <Tooltip title="Giveaway item" style={styles.icon}>
                                                <MoneyOffIcon color="secondary" />
                                            </Tooltip>
                                            <b>{item.name}</b>
                                        </TableRowColumn>
                                        <TableRowColumn>{item.sku}</TableRowColumn>
                                        <TableRowColumn style={styles.tableEanColumn}>{item.ean}</TableRowColumn>
                                        <TableRowColumn style={styles.stockAvailableColumn} />
                                        <TableRowColumn style={styles.tableQtyColumn}>
                                            <TextField
                                                name={`${item.sku}.${index}`}
                                                value={item.packed_quantity}
                                                type="number"
                                                style={styles.textField}
                                                readonly={true}
                                            />
                                        </TableRowColumn>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

PackingLineItems.contextTypes = {
    translate: PropTypes.func.isRequired,
}
PackingLineItems.propTypes = {
    editable: PropTypes.object,
    style: PropTypes.object,
    line_items: PropTypes.array,
    promo_items: PropTypes.array,
    onEditableQuantityChange: PropTypes.func,
    onEditablePromoQuantityChange: PropTypes.func,
}
PackingLineItems.defaultProps = {
    line_items: [],
}

export default PackingLineItems
