import { syncSocketCall } from '../socket'

/**
 * Send command to split the compartment into two shipments/packing-items
 * @param {{ packing_item_id }} data object containing the packingItem ID
 * @param {object} options Options object
 * @return {object} Action
 */
export function splitPackingItem(data, { timeout = 3000 } = {}) {
    return syncSocketCall('packing-item/split', data, { timeout })
}

/**
 * Sends command to check if compartment is splittable
 * A compartment is splittable if one or more line items are not pickable
 * @param {{ packing_item_id }} data object containing the packingItem ID
 * @param {object} options Options object
 * @returns {object} Action
 */
export function getPickablePackingLineItems(data, { timeout = 3000 } = {}) {
    return syncSocketCall('packing-item/line-items/stock-available', data, { timeout })
}
