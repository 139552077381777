export function searchDebitMemos(filters) {
    return { type: 'SEARCH_DEBIT_MEMOS', filters }
}

export function getDebitMemoByOrder(id) {
    return { type: 'GET_DEBIT_MEMO_BY_ORDER', id }
}

export function synchronizeDebitMemo(id) {
    return { type: 'SYNCHRONIZE_DEBIT_MEMO', id }
}
