import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import { ProductionOrderPress } from '../../../types/production/production-orders'

const style = {
    minWidth: 200,
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const pressOptions = Object.values(ProductionOrderPress).map(press => {
    return {
        value: press,
        text: press,
    }
})

function PersonalisationPressSelect(props) {
    const presses = props.presses instanceof Array && props.presses.length
        ? pressOptions.filter(d => props.presses.includes(d.value))
        : pressOptions
    return (
        <FormControl style={props.style || {}} margin={props.margin || 'none'}>
            <InputLabel id="used-press-label">Used press</InputLabel>
            <Select
                style={style}
                labelId="used-press-label"
                id="used-press"
                multiple
                value={props.value}
                onChange={props.onChange}
                renderValue={selected => (
                    <div>
                        {selected.map(value => (
                            <Chip size="small" key={value} label={value} />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {presses.map(({value, text}) => (
                    <MenuItem key={value} value={value}>
                        {text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

PersonalisationPressSelect.propTypes = {
    margin: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.array,
    allowAll: PropTypes.bool,
    presses: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}
PersonalisationPressSelect.defaultProps = {
    style: {},
    presses: [],
    allowAll: false,
    value: '',
}

export default PersonalisationPressSelect
