import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {getFeatureByName} from '../../../../commands/sockets/settings'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const FEATURE_KEY = 'managePressWorkers'

const style = {
    minWidth: 200,
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function PersonalisationPressWorkerSelect(props) {
    const pressWorkerOptions = props.press_workers.map(press => {
        return {
            value: press,
            text: press,
        }
    })

    useEffect(() => {
        getFeatureByName(FEATURE_KEY)
    }, [])

    return (
        <FormControl style={props.style || {}} margin={props.margin || 'none'}>
            <InputLabel id="press-worker-label">Press worker</InputLabel>
            <Select
                style={style}
                labelId="press-worker-label"
                id="press-worker"
                multiple
                value={props.value}
                onChange={props.onChange}
                renderValue={selected => (
                    <div>
                        {selected.map(value => (
                            <Chip size="small" key={value} label={value} />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {pressWorkerOptions.map(({value, text}) => (
                    <MenuItem key={value} value={value}>
                        {text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

PersonalisationPressWorkerSelect.propTypes = {
    margin: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.array,
    press_workers: PropTypes.array,
    allowAll: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
PersonalisationPressWorkerSelect.defaultProps = {
    style: {},
    press_workers: [],
    allowAll: false,
    value: '',
}


export default connect(({ features = [] }) => {
    const feature = features.find(f => f.name === FEATURE_KEY)

    return {
        press_workers: feature ? feature.value.split('|') : [],
    }
}, dispatch => {
    return bindActionCreators({ getFeatureByName }, dispatch)
})(PersonalisationPressWorkerSelect)
