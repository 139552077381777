import store from '../../store'

export default function initNotificationsSockets(socket) {
    socket.on('notification/error', notification => {
        store.dispatch({type: 'NOTIFICATION_ERROR', notification})
    })

    socket.on('notification/ok', notification => {
        store.dispatch({type: 'NOTIFICATION_OK', notification})
    })
}
