import socket from '../socket'

function destroySession() {
    localStorage.removeItem('userToken')
    localStorage.removeItem('authorisation_metadata')
    localStorage.removeItem('authorisation_role')
    localStorage.removeItem('permissions')
    return { metadata: {}, permissions: [] }
}

export function session(state = {}, action) {
    switch (action.type) {
        case 'DESTROY_SESSION':
            return destroySession()

        case 'CREATE_SESSION':
            if (action.session.err) {
                return destroySession()
            }

            const metadata = action.session.profile.app_metadata
            if (!metadata.email) {
                metadata.email = action.session.profile.email
            }

            const token = action.session.token || localStorage.getItem('userToken')
            localStorage.setItem('userToken', token)
            localStorage.setItem('authorisation_role', metadata.role)
            localStorage.setItem('roles', JSON.stringify(metadata.roles ? metadata.roles : []))
            localStorage.setItem('permissions',
                JSON.stringify(metadata.tool_permissions ? metadata.tool_permissions : []))

            localStorage.setItem('authorisation_metadata', JSON.stringify(metadata))

            socket.reconnect()

            return {
                metadata,
                role: metadata.role,
                roles: metadata.roles,
                permissions: metadata.tool_permissions,
                features: metadata.features,
                token,
                preferences: { rma_view: 'normal', ...state.preferences },
            }

        case 'SET_RMA_VIEW_PREFERENCE':
            const preferences = { ...state.preferences }
            preferences.rma_view = action.preference
            localStorage.setItem('preferences', JSON.stringify(preferences)) // move to command? or component?
            return { ...state, preferences }

        default:
            return state
    }
}
