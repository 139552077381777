import { isObject } from './type'
import { toStringArray } from './string'

export function downloadBase64AsPdf(base64, name) {
    const binary = atob(base64)
    const array_buffer = new ArrayBuffer(binary.length)
    const view = new Uint8Array(array_buffer)
    for (let i = 0; i < binary.length; i++) {
        view[i] = binary.charCodeAt(i)
    }
    const blob = new Blob([view], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('download', `${name}.pdf`)
    anchor.setAttribute('href', url)
    anchor.style.display = 'none'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(url)
}

export function downloadBase64AsPng(base64, name) {
    const binary = atob(base64)
    const array_buffer = new ArrayBuffer(binary.length)
    const view = new Uint8Array(array_buffer)
    for (let i = 0; i < binary.length; i++) {
        view[i] = binary.charCodeAt(i)
    }
    const blob = new Blob([view], { type: 'image/png' })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('download', `${name}.png`)
    anchor.setAttribute('href', url)
    anchor.style.display = 'none'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(url)
}

/**
 * @param {array|object} obj_or_arr Array of objects, or an object with an array of headers and an array of rows
 *
 * @example Object with an array of headers and an array of rows
 * {
 *     headers: [ 'sku', 'quantity' ],
 *     rows: [
 *         [ 'sku-352d358', 5 ],
 *         [ 'some-skugsdg325', 3 ],
 *     ]
 * }
 *
 * @example Array of objects
 * [
 *   { sku: 'sku-352d358', quantity: 5 },
 *   { sku: 'some-skugsdg325', quantity: 3 }
 * ]
 * @param {string} basename Name of CSV file
 * @returns {undefined}
 */
export function downloadAsCsv(obj_or_arr, basename = 'csv-export', { line_endings = '\r\n', separator = ';' } = {}) {
    let headers = [] // string[]
    let rows = [] // string[][]

    if (Array.isArray(obj_or_arr)) {
        headers = obj_or_arr.reduce((acc, row) => [ ...acc, ...Object.keys(row) ], [])
        rows = obj_or_arr.map(row => headers.map(header => row[header]))
    } else if (isObject(obj_or_arr)) {
        // Check headers is an array of strings
        headers = toStringArray(obj_or_arr.headers)

        // Checks rows is an array of arrays of strings
        rows = Array.isArray(obj_or_arr.rows)
            ? obj_or_arr.rows.map(r => toStringArray(r))
            : [ toStringArray(obj_or_arr.rows) ]
    } else {
        throw new Error('[downloadAsCsv] obj_or_arr should be an array or an object')
    }

    const headerRow = headers && headers.length ? `${headers.join(separator)}${line_endings}` : ''
    const dataRows = `${rows.map(row => row.join(separator)).join(line_endings)}`

    const file_contents = encodeURI(headerRow + dataRows)

    const file_name = `${basename}.csv`

    const anchor = document.createElement('a')
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('download', file_name)
    anchor.setAttribute('href', `data:text/csv;charset=utf-8,${file_contents}`)
    anchor.style.display = 'none'

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
}

/**
 * Download csv with a single column
 * @param {string[]} arr Array of string values
 * @param {string} field Column header
 * @param {string} name Name of CSV file
 * @returns {undefined}
 */
// eslint-disable-next-line max-params
export function downloadStringArrayAsCsv(arr, field, name, { line_endings = '\r\n', separator = ';' } = {}) {
    return downloadAsCsv({ headers: [ field ], rows: arr.map(e => [ e ]) }, name, { line_endings, separator })
}
