import React, { Component } from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import autoBind from 'react-autobind'

import {
    TextField,
    SelectField,
} from 'material-ui'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

import ProductBulkUpdateFilters from './product-bulk-update/ProductBulkUpdateFilters'
import ProductBulkUpdateDelta from './product-bulk-update/ProductBulkUpdateDelta'

const initialState = {
    stepIndex: 0,
    finished: false,
    filters: [],
    changes: [],
    configs: [{
        key: 'name',
        text: 'Name',
        model: 'variant',
        valueComponent: TextField,
        type: 'string',
        properties: ['filters', 'changes'],
    }, {
        key: 'brand',
        text: 'Brand',
        model: 'product',
        valueComponent: TextField,
        type: 'string',
        properties: ['filters', 'changes'],
    }, {
        key: 'supplier',
        text: 'Supplier',
        model: 'product',
        valueComponent: SelectField,
        menuItems: [],
        properties: ['filters', 'changes'],
    }, {
        key: 'fulfillment_source',
        text: 'Source',
        model: 'variant',
        valueComponent: SelectField,
        menuItems: [{
            key: 'warehouse',
            value: 'warehouse',
            primaryText: 'Warehouse',
        }, {
            key: 'crossdock',
            value: 'crossdock',
            primaryText: 'Crossdock',
        }, {
            key: 'dropship',
            value: 'dropship',
            primaryText: 'Dropship',
        }],
        properties: ['filters', 'changes'],
    }, {
        key: 'supplier_sku',
        text: 'Supplier SKU',
        model: 'variant',
        valueComponent: TextField,
        type: 'string',
        properties: ['filters'],
    }, {
        key: 'lead_times.vendor',
        text: 'Vendor lead time',
        model: 'variant',
        type: 'number',
        valueComponent: TextField,
        properties: ['changes'],
    }, {
        key: 'purchasing_scale_quantity',
        text: 'Purchase amount',
        model: 'variant',
        type: 'number',
        valueComponent: TextField,
        properties: ['changes'],
    }],
}

class ProductBulkUpdateDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }

        autoBind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const config = prevState.configs.find(c => c.key === 'supplier')
        if (nextProps.suppliers.length > 0 && config.menuItems.length === 0) {
            const update = { configs: {} }
            update.configs[prevState.configs.indexOf(config)] = {
                menuItems: {
                    $push: nextProps.suppliers.map(({ name, mage_id }) => {
                        return {
                            key: mage_id,
                            value: mage_id,
                            primaryText: name,
                        }
                    }),
                },
            }
            const newState = reactUpdate(prevState, update)
            return newState
        }

        return null
    }

    componentDidMount() {
        this.props.onSearchSuppliers({})
    }

    handleNext() {
        const { stepIndex } = this.state
        this.setState({
            stepIndex: stepIndex + 1,
            finished: stepIndex + 1 === 2,
        }, () => {
            if (this.state.finished) {
                this.getUpdateDelta(this.state.configs, this.state.filters, this.state.changes)
            }
        })
    }

    handlePrev() {
        const { stepIndex } = this.state
        if (stepIndex > 0) {
            this.setState({ stepIndex: stepIndex - 1, finished: false })
        }
    }

    handleSubmit() {
        const data = this.buildSubmitData(this.state.configs, this.state.filters, this.state.changes)
        this.setState(initialState, () => {
            this.props.onSubmit(data)
        })
    }

    handleFilterSelectionChange(index, value) {
        const property = this.getStepProperty(this.state.stepIndex)
        const update = {}
        update[property] = {}
        update[property][index] = {
            $set: {
                key: value,
                value: '',
            },
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleAddFilterClick() {
        const property = this.getStepProperty(this.state.stepIndex)
        const update = {}
        const configs = this.state.configs.filter(({ properties }) => {
            return properties.indexOf(property) !== -1
        })
        for (const filter of configs) {
            if (this.state[property].find(({ key }) => key === filter.key) === undefined) {
                update[property] = {
                    $push: [{
                        key: filter.key,
                        value: '',
                    }],
                }
                break
            }
        }

        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleFilterChange(index, value) {
        const property = this.getStepProperty(this.state.stepIndex)
        const update = {}
        update[property] = {}
        update[property][index] = {}
        update[property][index].value = { $set: value }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleDeleteFilterClick(index) {
        const property = this.getStepProperty(this.state.stepIndex)
        const update = {}
        update[property] = {
            $apply: value => {
                return value.filter((v, i) => i !== index)
            },
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleExited() {
        this.setState({ stepIndex: 0, changes: [], filters: [] })
    }

    buildSubmitData(configs, filters, changes) {
        const data = {
            product: { filters: [], changes: [] },
            variant: { filters: [], changes: [] },
        }
        for (const filter of filters) {
            const fconfig = configs.find(({ key }) => key === filter.key)
            if (fconfig.key === 'supplier') {
                data[fconfig.model].filters.push({
                    key: 'supplier.mage_id',
                    value: filter.value,
                })
            } else {
                data[fconfig.model].filters.push(filter)
            }
        }
        for (const change of changes) {
            const cconfig = configs.find(({ key }) => key === change.key)
            if (cconfig.key === 'supplier') {
                data[cconfig.model].changes.push({
                    key: 'supplier',
                    value: this.props.suppliers.find(({ mage_id }) => +mage_id === +change.value),
                })
            } else {
                data[cconfig.model].changes.push(change)
            }
        }
        return data
    }

    getUpdateDelta(configs, filters, changes) {
        const data = this.buildSubmitData(configs, filters, changes)
        this.props.onBulkUpdateDeltaSubmit(data)
    }

    getStepProperty(stepIndex) {
        switch (stepIndex) {
            case 0:
                return 'filters'
            case 1:
                return 'changes'
            default:
                return 'filters'
        }
    }

    getContentMaxWidth(stepIndex) {
        switch (stepIndex) {
            case 2:
                return 'xl'
            default:
                return 'md'
        }
    }

    canAddFilter(configs, stepIndex) {
        const property = this.getStepProperty(stepIndex)
        return configs.filter(config => {
            return this.state[property].find(f => f.key === config.key)
        }).length === configs.length
    }

    canProceed(stepIndex) {
        switch (stepIndex) {
            case 0:
                return this.state.filters.length > 0
            case 1:
                return this.state.changes.length > 0
            case 2:
                return this.props.delta.length > 0
            default:
                return false
        }
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <ProductBulkUpdateFilters
                        property="filters"
                        filters={this.state.filters}
                        configs={this.state.configs}
                        onFilterSelectionChange={this.handleFilterSelectionChange.bind(this)}
                        onFilterChange={this.handleFilterChange.bind(this)}
                        onDeleteFilterClick={this.handleDeleteFilterClick.bind(this)}
                    />
                )
            case 1:
                return (
                    <ProductBulkUpdateFilters
                        property="changes"
                        filters={this.state.changes}
                        configs={this.state.configs}
                        onFilterSelectionChange={this.handleFilterSelectionChange.bind(this)}
                        onFilterChange={this.handleFilterChange.bind(this)}
                        onDeleteFilterClick={this.handleDeleteFilterClick.bind(this)}
                    />
                )
            case 2:
                return (
                    <ProductBulkUpdateDelta
                        delta={this.props.delta}
                    />
                )
            default:
                return null
        }
    }

    getStepActions(finished, configs, stepIndex) {
        const nextLabel = finished ? 'Finish' : 'Next'
        const nextClickHandler = finished ? this.handleSubmit.bind(this) : this.handleNext.bind(this)
        return (
            <DialogActions>
                <Button
                    style={{ alignSelf: 'flex-start' }}
                    onClick={this.handleAddFilterClick.bind(this)}
                    disabled={this.canAddFilter(configs, stepIndex)}
                    color="primary"
                >
                    <AddIcon />
                    {stepIndex === 0 ? 'Add filter' : 'Add change'}
                </Button>
                <Button
                    onClick={this.handlePrev.bind(this)}
                >
                    Back
                </Button>
                <Button
                    style={{ marginLeft: 20, color: 'white' }}
                    color="primary"
                    variant="contained"
                    disabled={!this.canProceed(this.state.stepIndex)}
                    onClick={nextClickHandler}
                >
                    {nextLabel}
                </Button>
            </DialogActions>
        )
    }

    render() {
        const { stepIndex, configs, finished } = this.state
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                maxWidth={this.getContentMaxWidth(stepIndex)}
                onExited={this.handleExited}
            >
                <DialogTitle>
                    Bulk update product variants
                    <IconButton
                        style={{ float: 'right' }}
                        onClick={this.props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={stepIndex}>
                        <Step>
                            <StepLabel>
                                Add product filters
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                Add changes
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                View delta of changes
                            </StepLabel>
                        </Step>
                    </Stepper>
                    {this.getStepContent(stepIndex)}
                </DialogContent>
                {this.getStepActions(finished, configs, stepIndex)}
            </Dialog>
        )
    }
}

ProductBulkUpdateDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    visible: PropTypes.bool,
    delta: PropTypes.array,
    suppliers: PropTypes.array,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onBulkUpdateDeltaSubmit: PropTypes.func.isRequired,
    onSearchSuppliers: PropTypes.func.isRequired,
}

ProductBulkUpdateDialog.defaultProps = {
    isUpdated: false,
    suppliers: [],
    delta: [],
}

export default ProductBulkUpdateDialog
