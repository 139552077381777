import socket from './../socket'
import listeners from '../listeners/sockets/rmas.js'
import _ from 'lodash'
import moment from 'moment'

import { ReturnReasons } from '../app/utils/returns'

listeners(socket)

function updateItems(updated_items, line_items) {
    return _.map(line_items, li => {
        const updated_li = _.find(updated_items, l => {
            return l.supplier_sku === li.supplier_sku &&
                l.ean === li.ean && ((!li.status || li.status === 'accepted') && l.status === 'credited')
        })

        if (updated_li) {
            updated_items = _.without(updated_items, updated_li)
        }

        return updated_li || li
    })
}

function mapRmaItems(items) {
    const result = items.reduce((flat_acc, item) => {
        while (item.quantity > 0) {
            flat_acc.push({
                ...item,
                reason_text: item.return_reason && !item.reason_text
                    ? ReturnReasons[item.return_reason]
                    : item.reason_text,
                quantity: 1,
            })
            item.quantity--
        }
        return flat_acc
    }, [])

    return result
}

function setDates(r) {
    r.created_at = moment(r.created_at).format('DD-MM-YY')
    r.requested_at = r.requested_at ? moment(r.requested_at).format('DD-MM-YY') : ''
}

export function rma(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'CREATE_RMA':
            return { ...action.rma, status: 'approved' }

        case 'ADD_RMA_NOTE':
            socket.emit('rma/note/create', { _id: action.id, note: action.note })
            return state

        case 'GET_RMA':
            socket.emit('rma/get', { _id: action.id })
            return state

        case 'SETTLE_RMA':
            socket.emit('rma/settle', { _id: action.id, line_items: action.line_items })
            const line_items = updateItems(mapRmaItems(action.line_items), state.line_items)
            const status = _.some(line_items, li => li.status === 'accepted') ? state.status : 'settled'
            return _.merge({}, state, { status, line_items })

        case 'DELIVER_RMA':
            socket.emit('rma/delivered', { rma_id: action.id })
            return _.merge({}, state, { status: 'goods_received' })

        case 'DOWNLOAD_RMA':
            return { ...state, pdf: action.data }

        case 'AUTHORIZE_RMA':
            socket.emit('rma/authorize', { rma: { _id: action.id }, decision: action.authorized ? 'approve' : 'deny' })
            return _.merge({}, state, { status: action.authorized ? 'approved' : 'denied' })

        case 'TRIAGE_RMA_ITEM':
            socket.emit('rma/triage', {
                _id: action.data.rma_id,
                line_items: [action.data.line_item],
                location: action.data.location,
                decision: action.data.decision,
            })
            return state

        case 'REFRESH_RMA':
            if (action.rma.line_items instanceof Array) {
                action.rma.line_items = mapRmaItems(action.rma.line_items)
            }
            action.rma.consideration_date = action.rma.consideration_date
                ? moment(action.rma.consideration_date).format('DD-MM-YY')
                : ''
            return action.rma

        default:
            return state
    }
}

export function rmas(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_RMAS':
            return _.map(_.orderBy(action.rmas, ['created_at'], ['desc']), r => {
                setDates(r)
                return r
            })

        case 'REFRESH_ORDER_RMAS':
            return _.map(_.orderBy(action.rmas, ['created_at'], ['desc']), r => {
                setDates(r)
                const items = []
                r.line_items.forEach(item => {
                    const match = items.find(i => i.supplier_sku === item.supplier_sku && i.status === item.status)
                    if (match) {
                        match.quantity += item.quantity
                        match.price = {
                            in_tax: match.in_tax + match.in_tax / match.quantity * item.quantity,
                            ex_tax: match.ex_tax + match.ex_tax / match.quantity * item.quantity,
                        }
                    } else {
                        item = {
                            price: {
                                in_tax: _.get(item, 'price.in_tax_min_discount', 0) * item.quantity,
                                ex_tax: _.get(item, 'price.ex_tax_min_discount', 0) * item.quantity,
                            },
                            supplier_sku: item.supplier_sku,
                            status: item.status,
                            quantity: item.quantity,
                            ean: item.ean,
                            name: item.name,
                            type: item.type,
                        }
                        items.push(item)
                    }
                })
                r.line_items = items.map(li => {
                    li.price.in_tax = +((li.price.in_tax / 100).toFixed(2))
                    li.price.ex_tax = +((li.price.ex_tax / 100).toFixed(2))
                    return li
                })
                return r
            })

        default:
            return state
    }
}

export function rma_carts(state = {}, action) {
    switch (action.type) {
        case 'SET_RMA_STOCK_CART':
            return { ...state, stock: action.cart }

        case 'SET_RMA_RTV_CART':
            return { ...state, rtv: action.cart }

        default:
            return state
    }
}

export function rma_product(state = { loading: false, variant: null }, action) {
    switch (action.type) {
        case 'LOADING_RMA_PRODUCT':
            return { ...state, loading: true }

        case 'FETCHED_RMA_PRODUCT':
            return { ...state, loading: false, variant: action.data }

        case 'CLEAR_RMA_PRODUCT':
            return { loading: false, variant: null }

        default:
            return state
    }
}
