export function refreshDeliveryOrder(data) {
    return { type: 'REFRESH_DELIVERY_ORDER', data }
}

export function refreshDeliveryOrderLocations(data) {
    return { type: 'REFRESH_DELIVERY_ORDER_LOCATIONS', data }
}

export function refreshDeliveryOrders(data) {
    return { type: 'REFRESH_DELIVERY_ORDERS', data }
}
