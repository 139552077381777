export function showErrorNotification(notification) {
    return { type: 'NOTIFICATION_ERROR', notification }
}

export function showOkNotification(notification) {
    return { type: 'NOTIFICATION_OK', notification }
}

export function showNotification(notification) {
    return { type: 'SHOW_NOTIFICATION', notification }
}

export function hideNotification() {
    return { type: 'HIDE_NOTIFICATION' }
}
