import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { debit_memos, debit_memo } from './debit_memos'
import { credit_memos, credit_memo } from './credit_memos'
import { invoice } from './invoices'
import { session } from './session'
import { shipment, shipments, couriers } from './shipments'
import { sales_order, sales_orders } from './sales_orders'
import { rtv, rtvs } from './rtvs'
import { rma, rmas, rma_carts, rma_product } from './rmas'
import { packing_slip } from './packing_slips'
import { work_slip } from './work_slips'
import { label, labels } from './labels'
import { impression } from './impressions'
import { personalisations } from './personalisations'
import { production_orders, production_order } from './production_orders'
import { cancellations } from './cancellations'
import { locations } from './locations'
import { compartment, compartments } from './wall'
import { logs } from './logs'
import { filters, loading } from './filters'
import { purchase_orders, purchase_order } from './purchase_orders'
import { outbound_checklists, outbound_checklist } from './outbound_checklists'
import { typed_orders, typed_order } from './typed_orders'
import { stock_queue_items } from './stock_queue_items'
import { purchase_requisitions } from './purchase_requisitions'
import { inbound_candidates } from './inbound_candidates'
import { inbound_receipts } from './inbound_receipts'
import { packing_station } from './packing_station'
import { packing_items, unpacked_counts, packing_item } from './packing_items'
import { stocks } from './inventory'
import { variant, variants, autocomplete_variants, delta } from './variants'
import { suppliers, supplier } from './suppliers'
import { notification } from './notifications'
import { message } from './messages'
import { pagination } from './pagination'
import { menu_items } from './menu_items'
import { delivery_order, delivery_orders } from './delivery_orders'
import { pick_list, pick_lists } from './pick_lists'
import { features } from './settings'
import { candidates } from './candidates'
import { printers } from './printers'
import { stock_location } from './stock_location'
import { promo_items } from './promo_items'
import { action_dialog } from './action_dialog'

const rootReducer = combineReducers({
    action_dialog,
    autocomplete_variants,
    session,
    shipment,
    shipments,
    couriers,
    packing_slip,
    candidates,
    work_slip,
    label,
    labels,
    sales_order,
    sales_orders,
    debit_memos,
    debit_memo,
    credit_memos,
    credit_memo,
    invoice,
    rtv,
    rtvs,
    rma,
    rmas,
    rma_carts,
    rma_product,
    locations,
    compartment,
    compartments,
    purchase_order,
    purchase_orders,
    outbound_checklists,
    outbound_checklist,
    inbound_receipts,
    inbound_candidates,
    delivery_orders,
    delivery_order,
    pick_lists,
    pick_list,
    features,
    printers,
    typed_order,
    typed_orders,
    stock_queue_items,
    purchase_requisitions,
    packing_station,
    packing_item,
    packing_items,
    promo_items,
    unpacked_counts,
    stocks,
    variant,
    variants,
    delta,
    impression,
    personalisations,
    production_orders,
    production_order,
    cancellations,
    filters,
    loading,
    logs,
    supplier,
    suppliers,
    notification,
    message,
    routing: routerReducer,
    pagination,
    menu_items,
    stock_location,
})

export default rootReducer
