import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Avatar, Card, Divider, FontIcon, ListItem} from 'material-ui'
import Tooltip from '@material-ui/core/Tooltip'

import Colors from './../../../../../styles/colors'
import LineItems from './../../../../finance/credits/credit-memo-detail/LineItems'

const styles = {
    card: {
        marginBottom: 10,
    },
    listItemInner: {
        marginLeft: 0,
    },
    status: {
        textTransform: 'capitalize',
        fontStyle: 'italic',
    },
}

const STATUSES = {
    draft: {
        icon: 'sync_problem',
        color: Colors.amber700,
        title: 'Nog niet geaccepteerd',
    },
    accepted: {
        icon: 'sync',
        color: Colors.blue700,
        title: 'Geaccepteerd, maar nog niet in Exact Online',
    },
    rejected: {
        icon: 'not_interested',
        color: Colors.red700,
        title: 'Geweigerd',
    },
    prepared: {
        icon: 'print',
        color: Colors.teal300,
        title: 'In Exact Online, maar nog niet geprint',
    },
    invoiced: {
        icon: 'done',
        color: Colors.green700,
        title: 'Definitief in Exact Online',
    },
}

class CreditMemo extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    shouldComponentUpdate() {
        return true
    }

    getTotalPricing(credit) {
        return {
            subtotal: credit.subtotal,
            tax: credit.tax_amount,
            total: credit.grand_total,
        }
    }

    render() {
        const memo = this.props.memo
        const avatar = STATUSES[memo.status] || STATUSES.draft
        return (
            <Card style={styles.card}>
                <ListItem
                    leftAvatar={
                        <Tooltip key={`credit-memo-status-${memo._id}`} title={avatar.title}>
                            <Avatar
                                backgroundColor={avatar.color}
                                icon={<FontIcon className="material-icons">{avatar.icon}</FontIcon>}
                            />
                        </Tooltip>
                    }
                    initiallyOpen={true}
                    innerDivStyle={styles.listItemInner}
                    key={memo._id}
                    primaryText={
                        <div style={styles.primaryTextListItemStyle}>
                            Credit Memo
                            <span style={styles.status}>&nbsp;({memo.status})&nbsp;</span>
                            <span>
                                &nbsp;
                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                    onClick={() => {
                                        this.context.router.push(`/finance/credit-memos/${memo._id}`)
                                    }}
                                >
                                    {memo.invoice_id}
                                </a>
                            </span>

                        </div>
                    }
                    secondaryText={
                        <div style={styles.primaryTextListItemStyle}>Prior action: {memo.prior_action}</div>
                    }
                    nestedItems={
                        [
                            <Divider key="divider.0"/>,
                            <LineItems
                                key="credit_memo.line_items.1"
                                showTitle={false}
                                parent={props => (<div style={props.style}>{props.children}</div>)}
                                items={memo.line_items}
                                total_pricing={this.getTotalPricing(memo)}
                            />,
                        ]
                    }
                />
            </Card>
        )
    }
}

CreditMemo.propTypes = {
    memo: PropTypes.object,
}
CreditMemo.defaultProps = {}
CreditMemo.contextTypes = {
    router: PropTypes.object.isRequired,
}

export default CreditMemo
