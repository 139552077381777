import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardHeader, List, ListItem, Divider} from 'material-ui'
import _ from 'lodash'

const style = {
    container: {
        width: '100%',
    },
    primaryTextListItemStyle: {
        fontSize: 14,
        fontFamily: 'Roboto',
    },
    primaryTextSubListItemStyle: {
        fontSize: 12,
    },
    title: {
        height: 30, padding: 6,
    },
    titleStyle: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Roboto',
    },
    cardAction: {
        marginRight: 30,
        paddingLeft: 8,
        paddingRight: 8,
    },
}


function LineItems(props) {
    return props.line_items.length === 0 || props.shipment_count > 0 ? null : (
        <div style={style.container}>
            <Card>
                <CardHeader
                    titleColor="rgba(0, 0, 0, 0.7)"
                    titleStyle={{fontFamily: 'Roboto'}}
                    title="Line items"
                />
                <List>
                    {
                        _.concat([<Divider key="divider.0"/>], _.map(props.line_items, line_item => {
                            return [
                                <ListItem
                                    innerDivStyle={{marginLeft: 0}}
                                    key={line_item.product.sku}
                                    primaryText={
                                        <div style={style.primaryTextSubListItemStyle}>
                                            {line_item.product.name}
                                        </div>
                                    }
                                    secondaryTextLines={2}
                                    secondaryText={
                                        <div>
                                            <div style={style.primaryTextSubListItemStyle}>
                                                {`SKU: ${line_item.product.sku}`}
                                            </div>
                                            <div style={style.primaryTextSubListItemStyle}>
                                                {`Quantity: ${line_item.quantity}`}
                                            </div>
                                        </div>}
                                />,
                                <Divider key="divider.2"/>,
                            ]
                        }))
                    }
                </List>
            </Card>
        </div>
    )
}

LineItems.propTypes = {
    line_items: PropTypes.array,
    shipment_count: PropTypes.number,
}
LineItems.defaultProps = {
    line_items: [],
    shipment_count: 0,
}

export default LineItems
