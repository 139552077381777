import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { uploadPersonalisationImpression, getPersonalisationImpression } from '../../../../actions/impressions'

import PersonalisationConfigOptions from './PersonalisationConfigOptions'
import PersonalisationImpressionDialog from './PersonalisationImpressionDialog'

import ChannelAvatar from '../../shared/ChannelAvatar'

const style = {
    container: {
        padding: '12px 12px',
        width: '50%',
        minWidth: 400,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '1px solid #ccc',
    },
    personalisation: {
        display: 'flex',
        padding: '6px 0',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    column: {
        margin: '0 6px',
    },
}

class PersonalisationConfiguration extends Component {

    constructor(props) {
        super(props)
        this.state = { position: null }
    }

    getPositionType(id) {
        if (id.startsWith('leg')) {
            return 'leg'
        }
        return 'upper'
    }

    componentDidUpdate(prevProps) {
        const impression = prevProps.impression
        if (impression && impression.blob && impression.filename && !impression.error) {
            this.downloadImpression(impression)
        }
    }

    downloadImpression(impression) {
        const anchor = document.createElement('a')
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('download', impression.filename)
        anchor.setAttribute('href', `data:${impression.type};base64,${impression.blob}`)
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }

    determineImages(personalisation) {
        const config = personalisation.printing || personalisation.embroidery
        const type = config.sku
        return config.positions.map(p => {
            const position = p.id.replace(`${type}_position_`, '')
            const position_type = this.getPositionType(p.id.replace(`${type}_position_`, ''))
            return {
                id: p.id,
                type,
                position,
                position_type,
                colors: (p.colors || '').charAt(0),
                image: p.image,
            }
        })
    }

    handleDialogClose() {
        this.setState({ position: null })
    }

    handleDialogSubmit({ name, data, type }, position) {
        this.handleDialogClose()
        this.props.uploadPersonalisationImpression({
            personalisation_id: this.props.personalisation._id,
            name: `${this.props.order_number}_${name.substring(0, name.lastIndexOf('.'))}`,
            data: data.split('base64,')[1],
            position,
            type,
        })
    }

    handleUploadClick(id) {
        this.setState({ position: id })
    }

    handleLinkClick(filename) {
        this.props.getPersonalisationImpression(filename)
    }

    render() {
        const personalisation = this.props.personalisation
        const channel = this.props.channel
        if (channel.slug === 'xerox') {
            return (
                <div style={style.container}>
                    <ChannelAvatar
                        slug={channel.slug}
                    />
                    Xerox Personalisation
                </div>
            )
        }
        if (!personalisation) {
            return null
        }
        const impressions = personalisation.attachments.filter(a => a.type === 'impression')
        const images = this.determineImages(personalisation)
        return (
            <div style={style.container}>
                {
                    images.map(({ type, position, position_type, colors, image }, index) => {
                        const prefix = `/personalisations/${position_type}`
                        const borderBottom = index !== images.length - 1 ? '1px solid #ccc' : ''
                        return [
                            <div key={`image.${index}`} style={{ ...style.personalisation, borderBottom }}>
                                <PersonalisationConfigOptions
                                    style={style.column}
                                    logo={image}
                                    type={`${prefix}/types/${type}.png`}
                                    position={`${prefix}/positions/${position}.png`}
                                    colors={colors ? `${prefix}/colors/${colors}.png` : '' }
                                />
                            </div>,
                        ]
                    })
                }
                <PersonalisationImpressionDialog
                    open={!!this.state.position}
                    position={this.state.position}
                    impressions={impressions.filter(i => i.position === this.state.position)}
                    personalisation_id={this.props.personalisation.personalisation_id}
                    onClose={this.handleDialogClose.bind(this)}
                    onSubmit={this.handleDialogSubmit.bind(this)}
                />
            </div>
        )
    }
}

PersonalisationConfiguration.propTypes = {
    impression: PropTypes.object,
    channel: PropTypes.object,
    order_number: PropTypes.string,
    personalisation: PropTypes.object.isRequired,
    uploadPersonalisationImpression: PropTypes.func.isRequired,
    getPersonalisationImpression: PropTypes.func.isRequired,
}

export default connect(({ impression }) => {
    return { impression }
}, dispatch => {
    return bindActionCreators({ uploadPersonalisationImpression, getPersonalisationImpression }, dispatch)
})(PersonalisationConfiguration)

