import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import moment from 'moment'

import {searchSalesOrders} from './../../../../actions/sales_orders'

import OrderTable from './OrderTable'
import OrderFilters from './OrderFilters'

const fields = 'mage_id,channel,created_at,customer.name,shipping_address,line_items,status,fulfillments,' +
    'cancellations,pricing'
const STORAGE_KEY = 'sales-filter'

class OrderList extends Component {
    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            shouldRender: false,
            isUpdated: false,
            pendingCancellations: filters.pendingCancellations || false,
            personalised: filters.personalised || false,
            search: filters.value || '',
            order: filters.order || '',
            item: filters.item || '',
            channel: filters.channel || 'all',
            status: filters.status || 'all',
            page: filters.page || 1,
            limit: filters.limit || 20,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return prevState.shouldRender === false ? { shouldRender: true } : null
    }

    componentDidMount() {
        this.searchOrders()
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    getFiltersState() {
        return {
            value: this.state.search,
            order: this.state.order,
            item: this.state.item,
            pendingCancellations: this.state.pendingCancellations,
            personalised: this.state.personalised,
            fields,
            status: this.state.status === 'all' ? null : this.state.status,
            channel: this.state.channel === 'all' ? null : this.state.channel,
            page: this.state.page,
            limit: this.state.limit,
            sorting: {created_at: -1},
        }
    }

    searchOrders() {
        const state = this.getFiltersState()
        this.storeFilters(state)
        if (state.personalised === false) {
            delete state.personalised
        }
        this.props.searchSalesOrders(state)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchOrders.bind(this), 500)
    }

    setStateWithoutRender(state, timeout) {
        this.setState({
            ...state,
            shouldRender: false,
        }, timeout ? this.setSearchTimeout.bind(this) : this.searchOrders.bind(this))
    }

    handleSearchFieldChange(search) {
        this.setStateWithoutRender({
            search,
            page: 1,
        }, true)
    }

    handleOrderChange(order) {
        this.setStateWithoutRender({
            order,
            page: 1,
        }, true)
    }

    handleItemChange(item) {
        this.setStateWithoutRender({
            item,
            page: 1,
        }, true)
    }

    handleStatusChange(status) {
        this.setStateWithoutRender({
            status,
            page: 1,
        }, false)
    }

    handleChannelChange(channel) {
        this.setStateWithoutRender({
            channel,
            page: 1,
        }, false)
    }

    handlePendingCancellationsFilterChange(pendingCancellations) {
        this.setStateWithoutRender({
            pendingCancellations,
            page: 1,
        }, false)
    }

    handlePersonalisedFilterChange(personalised) {
        this.setStateWithoutRender({
            personalised,
            page: 1,
        }, false)
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            page: this.state.page - 1,
        }, false)
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            page: this.state.page + 1,
        }, false)
    }

    handleLimitChange(limit) {
        this.setStateWithoutRender({
            limit,
        }, false)
    }

    handleTableRowClick(rowNumber) {
        if (this.props.sales_orders[rowNumber]) {
            const id = this.props.sales_orders[rowNumber]._id
            this.context.router.push(`/sales/sales-orders/${id}`)
        }
    }

    mapOrder(order) {
        if (!order) {
            return {}
        }
        const shipping_address = order.shipping_address[order.shipping_address.length - 1] || {}
        const full_address = (
            `${shipping_address.street} ${shipping_address.house_number}
            ${(shipping_address.house_number_extension || '')}
            ${shipping_address.postal_code} ${shipping_address.city}`
        ).toUpperCase()
        const grand_total = parseFloat(order.pricing.grand_total).toFixed(2)
        return {
            channel: order.channel,
            created_at: moment(order.created_at).format('DD-MM-YYYY'),
            name: (order.customer.name || '').toUpperCase(),
            mage_id: order.mage_id,
            shipping_address,
            full_address,
            order_lines_amount: order.line_items.length,
            total_amount: `€ ${grand_total}`,
            status: order.status,
            hasPendingCancellations: order.cancellations.some(c => c.status === 'pending'),
        }
    }

    render() {
        return (
            <div>
                <OrderFilters
                    onStatusChange={this.handleStatusChange.bind(this)}
                    onChannelChange={this.handleChannelChange.bind(this)}
                    onOrderChange={this.handleOrderChange.bind(this)}
                    onItemChange={this.handleItemChange.bind(this)}
                    onSearchFieldChange={this.handleSearchFieldChange.bind(this)}
                    onPendingCancellationsChange={this.handlePendingCancellationsFilterChange.bind(this)}
                    onPersonalisedChange={this.handlePersonalisedFilterChange.bind(this)}
                    orders={this.props.sales_orders}
                    search={this.state.search}
                    order={this.state.order}
                    item={this.state.item}
                    status={this.state.status}
                    channel={this.state.channel}
                    personalised={this.state.personalised}
                    pendingCancellations={this.state.pendingCancellations}
                />
                <OrderTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.page - 1}
                    limit={this.state.limit}
                    sales_orders={this.props.sales_orders}
                    mapOrder={this.mapOrder}
                    onTableRowClick={this.handleTableRowClick.bind(this)}
                    onPageIncrement={this.handlePageIncrement.bind(this)}
                    onPageDecrement={this.handlePageDecrement.bind(this)}
                    onLimitChange={this.handleLimitChange.bind(this)}
                />
            </div>
        )
    }
}

OrderList.propTypes = {
    sales_orders: PropTypes.array.isRequired,
    filters: PropTypes.object,
    searchSalesOrders: PropTypes.func.isRequired,
}
OrderList.defaultProps = {
    sales_orders: [],
}
OrderList.contextTypes = {
    router: PropTypes.object,
}
export default connect(({sales_orders, filters}) => {
    return {
        sales_orders,
        filters: filters.sales_orders || {},
    }
}, dispatch => {
    return bindActionCreators({searchSalesOrders}, dispatch)
})(OrderList)
