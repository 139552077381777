import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem, Divider } from '@material-ui/core'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const style = {
    textField: { width: 280, marginRight: 15 },
    selectMenu: { zIndex: 1600, maxHeight: 800 },
}
const selectProps = {
    MenuProps: {
        style: {
            zIndex: 1600,
            maxHeight: 800,
        },
        disableAutoFocusItem: true,
    },
}

function SupplierFilter(props) {
    const value = props.value ? props.value : ''
    const variant = props.variant || 'standard'
    return (
        <TextField
            select
            variant={variant}
            style={props.style || style.textField}
            onChange={props.onChange}
            value={value}
            placeholder="Select supplier.."
            label={!props.showLabel ? '' : 'Select supplier'}
            SelectProps={selectProps}
            InputLabelProps={{ shrink: true }}
        >
            {!props.allowAll ? null : (
                <MenuItem
                    value="all"
                >
                    All
                </MenuItem>
            )}
            <Divider />
            {props.suppliers.map(({ mage_id, name }) => (
                <MenuItem
                    key={mage_id}
                    value={`${mage_id}`}

                >
                    {name}
                </MenuItem>
            ))}
        </TextField>
    )
}

SupplierFilter.propTypes = {
    style: PropTypes.object,
    variant: PropTypes.string,
    value: PropTypes.string,
    allowAll: PropTypes.bool,
    showLabel: PropTypes.bool,
    suppliers: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}
SupplierFilter.defaultProps = {
    suppliers: [],
    allowAll: true,
    showLabel: true,
    channel: '',
}


export default connect(({ suppliers }) => {
    return {
        suppliers,
    }
}, dispatch => {
    return bindActionCreators({}, dispatch)
})(SupplierFilter)
