import React, { Component } from 'react'
import autoBind from 'react-autobind'

import PropTypes from 'prop-types'
import { AppBar, Tabs, Tab, Button } from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import TransformIcon from '@material-ui/icons/Transform'
import HistoryIcon from '@material-ui/icons/History'
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    getProductVariant, updateProductVariant, phaseOutProductVariant, undoPhaseOutProductVariant,
} from './../../../commands/sockets/variants'
import { updateStock } from './../../../actions/stocks'

import Header from './../shared/Header'
import ProductSpecifications from './product-detail/ProductSpecifications'
import VariantSpecifications from './product-detail/VariantSpecifications'
import StockLevels from './product-detail/StockLevels'
import ProductInboundCandidates from './product-detail/ProductInboundCandidates'
import ProductPhaseOutDialog from './product-detail/ProductPhaseOutDialog'

const style = {
    tab: {
        color: 'white',
        width: '33%',
    },
    container: {
        width: '95%',
        margin: 'auto',
    },
    tabTemplate: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rightButtons: {
        float: 'right',
    },
}

class ProductDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tab: 'specifications',
            phaseOutVariant: null,
        }
        autoBind(this)
    }

    componentDidMount() {
        this.props.getProductVariant(this.props.params.id)
    }

    getTabTemplate() {
        const variant = this.props.variant

        switch (this.state.tab) {
            case 'specifications': return (
                <div style={style.tabTemplate}>
                    <ProductSpecifications
                        product={variant.product}
                    />
                    <VariantSpecifications
                        variant={variant}
                        onVariantUpdate={this.handleVariantUpdate}
                        undoPhaseOutProductVariant={this.handleUndoPhaseOut}
                        features={this.context.features}
                    />
                    <StockLevels
                        variant={this.props.variant}
                        stocks={this.props.stocks}
                        onStockUpdate={this.handleStockUpdate}
                    />
                </div>
            )
            case 'inbound': return (
                <div style={style.tabTemplate}>
                    <ProductInboundCandidates
                        variant={variant}
                    />
                </div>
            )
            default: return (
                <div style={style.tabTemplate}>
                    <i>Not yet implemented.</i>
                </div>
            )
        }
    }

    handleTabChange(event, tab) {
        this.setState({ tab })
    }

    handleVariantUpdate(variant) {
        this.props.updateProductVariant(variant)
    }

    handleStockUpdate(stock) {
        this.props.updateStock(stock)
    }

    handlePhaseOutClick() {
        this.setState({ phaseOutVariant: this.props.variant })
    }

    getActions() {
        const features = this.context.features
        if (!features ||
            !features.canPhaseOutVariants ||
            (this.props.variant && (
                this.props.variant.end_of_life ||
                this.props.variant.migrating_to_crossdock
            ))) {
            return null
        }

        return (
            <div style={style.rightButtons}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handlePhaseOutClick}
                >
                    Uitfaseren
                </Button>

            </div>
        )
    }

    handlePhaseOutDialogClose() {
        this.setState({ phaseOutVariant: null })
    }

    handlePhaseOutDialogSubmit({ option, all_variants }) {
        this.props.phaseOutProductVariant(this.props.variant, { option, all_variants })
    }

    handleUndoPhaseOut() {
        this.props.undoPhaseOutProductVariant(this.props.variant)
    }

    render() {
        const variant = _.cloneDeep(this.props.variant)
        if (!variant) { return null }
        return (
            <div style={style.container}>
                <Header
                    title={`${variant.name} - ${variant.supplier_sku}`}
                    rightButtons={this.getActions()}
                />
                <AppBar color="primary" position="relative">
                    <Tabs
                        onChange={this.handleTabChange}
                        value={this.state.tab}
                        centered={true}
                    >
                        <Tab
                            style={style.tab}
                            icon={<ListIcon />}
                            label={'Specifications'}
                            value="specifications"
                        />
                        <Tab
                            style={style.tab}
                            icon={<TransformIcon />}
                            label={'Remaining inbound'}
                            value="inbound"
                        />
                        <Tab
                            style={style.tab}
                            icon={<HistoryIcon />}
                            label={'Stock history'}
                            value="stock_history"
                        />
                    </Tabs>
                </AppBar>
                {this.getTabTemplate()}
                {!this.state.phaseOutVariant || variant.end_of_life ? null : (
                    <ProductPhaseOutDialog
                        open={!!this.state.phaseOutVariant}
                        variant={this.state.phaseOutVariant}
                        onClose={this.handlePhaseOutDialogClose}
                        onSubmit={this.handlePhaseOutDialogSubmit}
                    />
                )}
            </div>
        )
    }
}

ProductDetail.contextTypes = {
    features: PropTypes.object.isRequired,
}
ProductDetail.propTypes = {
    params: PropTypes.object.isRequired,
    variant: PropTypes.object,
    stocks: PropTypes.array,
    getProductVariant: PropTypes.func.isRequired,
    updateProductVariant: PropTypes.func.isRequired,
    phaseOutProductVariant: PropTypes.func.isRequired,
    undoPhaseOutProductVariant: PropTypes.func.isRequired,
    updateStock: PropTypes.func.isRequired,
}
ProductDetail.defaultProps = {}

export default connect(({ variant, stocks }) => {
    return {
        variant,
        stocks,
    }
}, dispatch => {
    const actions = {
        getProductVariant, updateStock, updateProductVariant, phaseOutProductVariant, undoPhaseOutProductVariant,
    }
    return bindActionCreators(actions, dispatch)
})(ProductDetail)
