import socket from './../socket'
import listeners from '../listeners/sockets/production_orders.js'

listeners(socket)

export function production_orders(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            if (action.pathname && action.pathname.indexOf('/production/production-orders') !== -1) {
                return state
            }
            return []

        case 'REFRESH_PRODUCTION_ORDERS':
            return action.data

        default:
            return state
    }
}

export function production_order(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_PRODUCTION_ORDER':
            return action.data

        default:
            return state
    }
}
