import socket from './../../socket'
import {getUserEmail} from '../../store'

export function unsubscribeFromWall() {
    socket.emit('wall/unsubscribe', {}, getUserEmail())
    return {type: 'NOTHING'}
}

export function subscribeToWall() {
    socket.emit('wall/subscribe', {}, getUserEmail())
    return {type: 'NOTHING'}
}

export function updateWallQuantity(compartment, item, quantity) {
    socket.emit('wall/compartment/update-wall-quantity', {compartment, item, quantity}, getUserEmail())
    return {type: 'NOTHING'}
}

export function unassignWallCompartment(data) {
    socket.emit('wall/compartment/un-assign', data, getUserEmail())
    return {type: 'NOTHING'}
}
