import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import TextField from '@material-ui/core/TextField'

import PackedFilter from './filters/PackedFilter'
import SourceFilter from './filters/SourceFilter'
import SwitchFilter from './../../../shared/SwitchFilter'
import ChannelFilter from './../../../shared/ChannelFilter'
import InstructionFilter from './../../../shared/InstructionFilter'
import DestinationFilter from './../../../shared/DestinationFilter'

import Devices from './../../../../styles/devices'

const style = {
    container: {
        display: 'flex',
        flexFlow: 'row',
        paddingLeft: 30,
        width: '95%',
    },
    textField: {
        marginRight: 15,
        width: '12%',
    },
    channelFilter: {
        marginTop: 16,
        width: 200,
        marginRight: 15,
    },
    topMargin: {
        marginTop: 16,
    },
    selectField: {
        marginRight: 15,
        width: '12%',
    },
    datePicker: {
        cursor: 'pointer',
        marginRight: 15,
        marginTop: 16,
        width: '12%',
    },
}

class ShipmentPackingListFilters extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    handleDateChange({ target }) {
        this.props.onDateChange(target.value)
    }

    handleChannelChange(e) {
        this.props.onChannelChange(e.target.value)
    }

    handleSourceChange(e, index, value) {
        this.props.onSourceChange(value)
    }

    handleItemTypeChange(e, index, value) {
        this.props.onItemTypeChange(value)
    }

    handleDestinationChange(e) {
        this.props.onDestinationChange(e.target.value)
    }

    handleInstructionChange(e, index, value) {
        this.props.onInstructionChange(value)
    }

    getStyles(device) {
        if (device === Devices.DESKTOP || device === Devices.RETINA) {
            return style
        }
        return {
            ...style,
            container: {
                paddingLeft: 15,
            },
            textField: {
                marginRight: 15,
                width: '25%',
            },
            selectField: {
                marginRight: 15,
                width: '20%',
            },
        }
    }

    render() {
        const styles = this.getStyles(this.props.device)
        return (
            <div style={styles.container}>
                <TextField
                    value={this.props.filters.search}
                    style={{...styles.textField, ...styles.topMargin}}
                    label="Search for package"
                    onChange={this.props.onSearchFieldChange}
                />
                <ChannelFilter
                    style={styles.channelFilter}
                    onChange={this.handleChannelChange}
                    channel={this.props.filters.channel}
                />
                <PackedFilter
                    style={styles.selectField}
                    onChange={this.props.onPackedViewChange}
                    value={this.props.filters.pack}
                />
                <SourceFilter
                    style={styles.selectField}
                    sources={['warehouse', 'crossdock', 'personalisation']}
                    onChange={this.handleSourceChange}
                    value={this.props.filters.fulfillment_source}
                />
                {
                    this.props.filters.fulfillment_source !== 'personalisation' ? null : (
                        <SourceFilter
                            style={styles.selectField}
                            sources={['warehouse', 'crossdock']}
                            label="Select item type"
                            onChange={this.handleItemTypeChange}
                            value={this.props.filters.item_type}
                        />
                    )
                }
                <InstructionFilter
                    style={styles.selectField}
                    types={['with', 'without']}
                    onChange={this.handleInstructionChange}
                    value={this.props.filters.instructions}
                />
                <DestinationFilter
                    style={{ ...styles.selectField, ...styles.topMargin }}
                    destinations={['customer', 'manufacturer']}
                    value={this.props.filters.destination_type}
                    onChange={this.handleDestinationChange}
                />
                <TextField
                    style={styles.datePicker}
                    disabled={this.props.ignoreDate}
                    value={this.props.filters.date}
                    label="Select estimated date"
                    type="date"
                    onChange={this.handleDateChange}
                />
                <SwitchFilter
                    style={style.topMargin}
                    filterLabel="Ignore"
                    filterProperty="ignoreDate"
                    onFilterChange={this.props.onIgnoreDateChange}
                    filterValue={this.props.ignoreDate}
                />
            </div>
        )
    }
}

ShipmentPackingListFilters.propTypes = {
    device: PropTypes.string,
    ignoreDate: PropTypes.bool,
    filters: PropTypes.object,
    onPackedViewChange: PropTypes.func.isRequired,
    onSearchFieldChange: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onItemTypeChange: PropTypes.func.isRequired,
    onInstructionChange: PropTypes.func.isRequired,
    onDestinationChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onChannelChange: PropTypes.func.isRequired,
    onIgnoreDateChange: PropTypes.func.isRequired,
}

export default ShipmentPackingListFilters
