import socket from './../socket'
import listeners from '../listeners/sockets/variants.js'
import _ from 'lodash'

listeners(socket)

export function variant(state = {}, action) {
    switch (action.type) {
        case 'REFRESH_PRODUCT_VARIANT':
            return {
                product: state.product,
                stocks: state.stocks,
                ...action.variant,
            }

        default:
            return state
    }
}

export function autocomplete_variants(state = [], action) {
    switch (action.type) {
        case 'REFRESH_AUTOCOMPLETE_PRODUCT_VARIANTS':
            return action.variants.map(v => {
                v.stocks = v.stocks.reduce((acc, stock) => {
                    acc[stock.type] = stock
                    return acc
                }, {})
                return v
            })

        default:
            return state
    }
}

export function variants(state = [], action) {
    switch (action.type) {
        case 'LOG_FILE_READER_RESULT':
            socket.emit('products/import/reader-result', action.data)
            return state

        case 'DELTA_BULK_UPDATE_VARIANTS':
            socket.emit('products/variants/update/delta', action.data)
            return state

        case 'BULK_UPDATE_VARIANTS':
            socket.emit('products/variants/update/bulk', action.data)
            return state

        case 'DELETE_PRODUCT_VARIANT':
            socket.emit('product/variant/delete', action.variant)
            return state.filter(v => v.supplier_sku !== action.variant.supplier_sku)

        case 'UPDATE_PRODUCT_VARIANTS':
            socket.emit('product/variants/update', action.data.map(v => {
                return {
                    id: v._id,
                    ean: v.ean,
                    supplier_sku: v.supplier_sku,
                    size: v.size,
                    color: v.color,
                    stocks: {
                        warehouse: {
                            quantity: v['stocks.warehouse.quantity'] || _.get(v, 'stocks.warehouse.quantity', 0),
                        },
                        vendor: { quantity: v['stocks.vendor.quantity'] || _.get(v, 'stocks.vendor.quantity', 0) },
                    },
                    author: action.author,
                }
            }))
            return action.data

        case 'SEARCH_PRODUCT_VARIANTS':
            socket.emit('product/variants/search', action.filters)
            return state

        case 'REFRESH_PRODUCT_VARIANTS':
            return action.variants.map(v => {
                v.stocks = v.stocks.reduce((acc, stock) => {
                    acc[stock.type] = stock
                    return acc
                }, {})
                return v
            })
        default:
            return state
    }
}

export function delta(state = [], action) {
    switch (action.type) {
        case 'DELTA_BULK_UPDATE_VARIANTS':
            return []

        case 'BULK_UPDATE_VARIANTS':
            return []

        case 'REFRESH_PRODUCT_DELTA':
            return action.variants

        default:
            return state
    }
}
