import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton, FontIcon } from 'material-ui'
import moment from 'moment'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { triageItems } from './../../../../actions/outbound_checklists'
import { createLabels, updateLabels } from './../../../../actions/labels'

import { getOutboundChecklist } from './../../../../commands/sockets/outbound_checklists'

import Header from './../../shared/Header'
import ItemStatusGroup from './ItemStatusGroup'
import TriageDialog from './TriageDialog'
import RequestLabelsDialog from './RequestLabelsDialog'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    contents: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
        minHeight: 600,
    },
    column: {
        width: '33%',
    },
}

class PurchaseOutboundDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dialogItems: [],
            requestLabels: false,
            selectedDateGroup: { date: null, status: null },
        }
    }

    componentDidUpdate() {
        if (this.props.labels.length > 0) {
            this.downloadLabels(this.props.labels)
        }
    }

    componentDidMount() {
        if (
            !this.context.features.purchaseOrderOutboundTriage
            && !this.props.roles?.includes('external-supply-chain')
        ) {
            return this.context.router.push('/')
        }
        return this.props.getOutboundChecklist(this.props.params.id)
    }

    static formatDate(date) {
        return date ? moment(date).format('DD-MM-YY') : null
    }

    groupItemsByDate(acc, curr) {
        curr.triage_items.forEach(item => {
            const d = PurchaseOutboundDetail.formatDate(item.date)
            const found = acc.find(({ date }) => PurchaseOutboundDetail.formatDate(date) === d)
            if (found) {
                found.triage_items.push({ ...item, quantity: 1 })
            } else {
                acc.push({
                    date: item.date,
                    status: item.status,
                    triage_items: [{ ...item, quantity: 1 }],
                })
            }
        })
        return acc
    }

    mergeItems(data) {
        return Object.keys(data).reduce((acc, key) => {
            return data[key] !== '' ? acc.concat(data[key]) : acc
        }, [])
    }

    handleApproveItems(data) {
        this.setState({ dialogItems: this.mergeItems(data), dialogStatus: 'approve' })
    }

    handleRejectItems(data) {
        this.setState({ dialogItems: this.mergeItems(data), dialogStatus: 'reject' })
    }

    handlePostponeItems(data) {
        this.setState({ dialogItems: this.mergeItems(data), dialogStatus: 'postpone' })
    }

    handleCloseDialog() {
        this.setState({ dialogItems: [], requestLabels: false })
    }

    handleSubmitTriageDialog(action, items, data) {
        this.props.triageItems(items.map(i => {
            const item = { ...i }
            if (action === 'postpone') {
                item.postponed_to = data.date.toISOString()
            }
            if (action === 'reject') {
                item.triage_reason = data.reason
            }
            return item
        }), action, this.props.email)
        this.handleCloseDialog()
    }

    handleSubmitRequestLabelsDialog(quantity) {
        this.props.createLabels(quantity, this.props.params.id, this.props.supplier_id)
        this.handleCloseDialog()
    }

    handleSelectItems(status, date) {
        this.setState({
            selectedDateGroup: { status, date },
        })
    }

    handleRaisedButtonClick() {
        this.setState({ requestLabels: true })
    }

    downloadLabels(labels) {
        labels.forEach((label, index) => {
            const anchor = document.createElement('a')
            anchor.setAttribute('target', '_blank')
            anchor.setAttribute('download',
                `${this.props.params.id}_${index}.png`)
            anchor.setAttribute('href', `data:application/png;base64,${label.blob}`)
            anchor.style.display = 'none'
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        })
        this.props.updateLabels([])
    }

    render() {
        if (!this.props.outbound_checklist._id) {
            return null
        }
        const checklist = this.props.outbound_checklist
        return (
            <div style={style.container}>
                <Header
                    key="header"
                    title={`Purchase order ${checklist._id}`}
                    showSupplier={this.context.role !== 'supplier'}
                    supplier={checklist.supplier}
                    rightButtons={
                        <div style={{ float: 'right', display: 'flex', flexFlow: 'row' }}>
                            <RaisedButton
                                primary={true}
                                onClick={this.handleRaisedButtonClick.bind(this)}
                                icon={<FontIcon className="material-icons">local_shipping</FontIcon>}
                                label="Get shipping labels"
                                style={{ margin: '0 10px' }}
                            />
                            <span style={{ display: 'inline-block', textAlign: 'right', marginBottom: -5 }}>
                                {moment(checklist.created_at).format('DD-MM-YY')}
                            </span>
                        </div>
                    }
                />
                <div key="contents" style={style.contents}>
                    <ItemStatusGroup
                        title="Approved for delivery"
                        types={[]}
                        status="approved"
                        style={style.column}
                        triage_items={checklist.triage_items
                            .filter(gi => gi.status === 'approved')
                            .reduce(this.groupItemsByDate, [])}
                        selectedDateGroup={this.state.selectedDateGroup}
                        onApproveItems={this.handleApproveItems.bind(this)}
                        onSelectItems={this.handleSelectItems.bind(this)}
                    />
                    <ItemStatusGroup
                        title="Postponed delivery"
                        types={['approved']}
                        style={style.column}
                        status="postponed"
                        triage_items={checklist.triage_items
                            .filter(gi => gi.status === 'postponed')
                            .reduce(this.groupItemsByDate, [])}
                        selectedDateGroup={this.state.selectedDateGroup}
                        onPostponeItems={this.handlePostponeItems.bind(this)}
                        onSelectItems={this.handleSelectItems.bind(this)}
                    />
                    <ItemStatusGroup
                        title="Undeliverable"
                        types={['approved', 'postponed']}
                        style={style.column}
                        status="rejected"
                        triage_items={checklist.triage_items
                            .filter(gi => gi.status === 'rejected')
                            .reduce(this.groupItemsByDate, [])}
                        selectedDateGroup={this.state.selectedDateGroup}
                        onRejectItems={this.handleRejectItems.bind(this)}
                        onSelectItems={this.handleSelectItems.bind(this)}
                    />
                </div>
                <TriageDialog
                    open={this.state.dialogItems.length > 0}
                    items={this.state.dialogItems}
                    status={this.state.dialogStatus}
                    onClose={this.handleCloseDialog.bind(this)}
                    onSubmit={this.handleSubmitTriageDialog.bind(this)}
                />
                <RequestLabelsDialog
                    open={this.state.requestLabels}
                    onClose={this.handleCloseDialog.bind(this)}
                    onSubmit={this.handleSubmitRequestLabelsDialog.bind(this)}
                />
            </div>
        )
    }
}

PurchaseOutboundDetail.propTypes = {
    email: PropTypes.string,
    supplier_id: PropTypes.number,
    params: PropTypes.object.isRequired,
    outbound_checklist: PropTypes.object,
    labels: PropTypes.array,
    updateLabels: PropTypes.func,
    createLabels: PropTypes.func,
    getOutboundChecklist: PropTypes.func,
    triageItems: PropTypes.func,
}
PurchaseOutboundDetail.defaultProps = {
    outbound_checklist: {},
    labels: [],
}
PurchaseOutboundDetail.contextTypes = {
    role: PropTypes.string,
    features: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}

export default connect(({ outbound_checklist, labels }) => {
    return {
        outbound_checklist,
        labels,
    }
}, dispatch => {
    const actions = { getOutboundChecklist, triageItems, createLabels, updateLabels }
    return bindActionCreators(actions, dispatch)
})(PurchaseOutboundDetail)
