import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Divider, TextField, MenuItem} from '@material-ui/core'

const styles = {
    container: {
        display: 'flex', justifyContent: 'flex-start',
    },
    textField: {
        minWidth: 240,
        marginRight: 20,
    },
}

class StockFieldSet extends Component {

    constructor(props) {
        super(props)

        this.handleColumnChange = this.handleColumnChange.bind(this)
        this.handleComparisonChange = this.handleComparisonChange.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this)
    }

    handleColumnChange(e) {
        return this.props.onColumnChange && this.props.onColumnChange(e)
    }

    handleComparisonChange(e) {
        return this.props.onComparisonChange && this.props.onComparisonChange(e)
    }

    handleValueChange(type = 'string') {
        return e => {
            return this.props.onValueChange && this.props.onValueChange(e, type)
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <Divider />
                <TextField
                    style={styles.textField}
                    select
                    label={this.props.labels.column}
                    value={this.props.column}
                    onChange={this.handleColumnChange}
                    InputLabelProps={{shrink: true}}
                    margin="normal"
                >
                    {this.props.columns.map(h => (
                        <MenuItem key={h} value={h}>
                            {h}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    disabled={!this.props.column}
                    style={styles.textField}
                    select
                    label={this.props.labels.comparison}
                    value={this.props.comparison}
                    onChange={this.handleComparisonChange}
                    InputLabelProps={{shrink: true}}
                    margin="normal"
                >
                    <MenuItem key="lte" value="lte">
                        Kleiner of gelijk aan
                    </MenuItem>
                    <MenuItem key="ean" value="eq">
                        Gelijk aan
                    </MenuItem>
                    <MenuItem key="gte" value="gte">
                        Groter of gelijk aan
                    </MenuItem>
                </TextField>
                {this.props.comparison === 'eq'
                    ? (
                        <TextField
                            disabled={!this.props.comparison}
                            style={styles.textField}
                            select
                            label={this.props.labels.value}
                            value={this.props.value}
                            helperText={this.props.helperText}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                            onChange={this.handleValueChange('string')}
                        >
                            {!this.props.column ? [] : this.props.valueMenuItems}
                        </TextField>
                    )
                    : (
                        <TextField
                            disabled={!this.props.comparison}
                            style={styles.textField}
                            type="number"
                            label={this.props.labels.value}
                            helperText={this.props.helperText}
                            value={this.props.value}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                            onChange={this.handleValueChange('number')}
                        />
                    )
                }
            </div>
        )
    }
}

StockFieldSet.contextTypes = {}
StockFieldSet.propTypes = {
    column: PropTypes.string.isRequired,
    comparison: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    labels: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    valueMenuItems: PropTypes.arrayOf(PropTypes.node),

    helperText: PropTypes.string,
    onColumnChange: PropTypes.func.isRequired,
    onComparisonChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
}
StockFieldSet.defaultProps = {}

export default StockFieldSet
