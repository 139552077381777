import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    DialogContent, DialogActions, DialogTitle, Button, Dialog, TextField, MenuItem,
} from '@material-ui/core'
import { green500 } from 'material-ui/styles/colors'
import _ from 'lodash'

const NL_ZIPCODE_REGEX = /^[1-9][0-9]{3}(?!sa|sd|ss)[a-z]{2}$/i
const BE_ZIPCODE_REGEX = /^[1-9]{1}[0-9]{3}$/i
const LU_ZIPCODE_REGEX = /^[1-9]{1}[0-9]{3}$/i
const FIVE_DIGIT_ZIPCODE_REGEX = /^[0-9]{5}$/i
const GB_ZIPCODE_REGEX = new RegExp(['^(?:(?:[A-PR-UWYZ][0-9]{1,2}|[A-PR-UWYZ][A-HK-Y][0-9]{1,2}|[A-PR-UWYZ][0-9]',
    '[A-HJKSTUW]|[A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRV-Y])[0-9][ABD-HJLNP-UW-Z]{2}|GIR 0AA)'].join(''))
const style = {
    submitButton: {
        color: green500,
    },
    textField: {
        company: {
            marginLeft: 15,
        },
        house_number: {
            marginLeft: 15,
            width: 100,
        },
        house_number_extension: {
            marginLeft: 15,
            width: 50,
        },
        city: {
            marginLeft: 15,
        },
        country: {
            fontSize: 15,
            marginLeft: 15,
        },
        select: {
            fontSize: 15,
            lineHeight: '15px',
        },
    },
}

const AddressTypes = {
    SHIPPING: 'shipping',
    BILLING: 'billing',
}

function initializeValues(props) {
    let address = _.cloneDeep(props.address) || {}
    const invalid_address = {
        name: false,
        street: false,
        house_number: false,
        house_number_extension: false,
        postal_code: false,
        city: false,
    }

    if (props.type === AddressTypes.SHIPPING) {
        address.name = props.address.name || ''
        address.company = props.address.company || ''
        invalid_address.name = false
        invalid_address.company = false
    }
    return {
        addressType: address.service_point && props.status !== 'unprocessed' ? 'service_point' : 'normal_address',
        address,
        invalid_address,
    }
}
class EditAddressDialog extends Component {

    constructor(props) {
        super(props)
        this.state = initializeValues(props)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.address !== prevState.address) {
            return initializeValues(prevState)
        }
        return null
    }

    handleChange(property, value, optional) {
        const address = {...this.state.address}
        const invalid_address = {...this.state.invalid_address}

        address[property] = value
        invalid_address[property] = !optional && typeof value === 'string' && value.length <= 1

        this.setState({address, invalid_address}, this.validatePostalCode.bind(this))
    }

    handleAddressTypeChange(value) {
        let address
        let invalid_address
        if (value === 'normal_address') {
            address = {
                name: '',
                postal_code: '',
                house_number_extension: '',
                house_number: 0,
                street: '',
                city: '',
                country: 'NL',
            }
            invalid_address = {
                name: true,
                street: true,
                house_number: true,
                postal_code: true,
                city: true,
                country: false,
                company: false,
                house_number_extension: false,
            }
        } else {
            address = this.props.address
            invalid_address = {}
        }
        const addressType = value
        this.setState({addressType, address, invalid_address})
    }

    handlePostalCodeChange({ target }) {
        if (target && typeof target.value === 'string') {
            const address = {...this.state.address}
            address.postal_code = target.value
            this.setState({address}, this.validatePostalCode.bind(this))
        }
    }

    validatePostalCode() {
        const country = this.state.address.country.toLowerCase()
        const postal_code = this.state.address.postal_code.replace(/[^a-z0-9]/gi, '')

        let invalid = false
        switch (country) {
            case 'nl':
                invalid = !NL_ZIPCODE_REGEX.test(postal_code)
                break
            case 'be':
                invalid = !BE_ZIPCODE_REGEX.test(postal_code)
                break
            case 'lu':
                invalid = !LU_ZIPCODE_REGEX.test(postal_code)
                break
            case 'de':
            case 'fr':
            case 'es':
                invalid = !FIVE_DIGIT_ZIPCODE_REGEX.test(postal_code)
                break
            case 'gb':
                invalid = !GB_ZIPCODE_REGEX.test(postal_code)
                break
            default:
                break
        }
        const invalid_address = {...this.state.invalid_address}
        invalid_address.postal_code = invalid
        this.setState({invalid_address})
    }

    handleSubmitClick() {
        if (_.every(_.valuesIn(this.state.invalid_address), val => val === false)) {
            this.props.onSubmit(this.state.address)
        }
    }

    getHintTextByCountry() {
        switch (this.state.address.country) {
            case 'NL':
                return '1234AB'
            case 'BE':
            case 'LU':
                return '1234'
            case 'DE':
            case 'FR':
                return '12345'
            case 'GB':
                return 'AB1 2CD'
            case 'ES':
                return '01234'
            default:
                return '1234AB'
        }
    }

    getForm() {
        const numericPostalCode = this.state.address.country.toLowerCase() === 'be'
        const textFields = []
        if (this.props.type === AddressTypes.SHIPPING) {
            textFields.push(
                <TextField
                    key="name"
                    label="Name"
                    error={this.state.invalid_address.name ? true : undefined}
                    onChange={({ target }) => { this.handleChange('name', target.value) }}
                    placeholder="John Doe"
                    value={this.state.address.name}
                />,
                <TextField
                    key="company"
                    label="Company"
                    onChange={({ target }) => { this.handleChange('company', target.value, true) }}
                    value= {this.state.address.company ? this.state.address.company : ''}
                    placeholder="Proforto"
                    style={style.textField.company}
                />
            )
        }
        textFields.push(
            <TextField
                key="street"
                label="Street"
                error={this.state.invalid_address.street ? true : undefined}
                onChange={({ target }) => { this.handleChange('street', target.value) }}
                placeholder="Stationsplein"
                value={this.state.address.street}
            />,
            <TextField
                key="house_number"
                label="House number"
                error={this.state.invalid_address.house_number ? true : undefined}
                type="number"
                min={1}
                onChange={({ target }) => { this.handleChange('house_number', +target.value) }}
                style={style.textField.house_number}
                placeholder="13"
                value={this.state.address.house_number}
            />,
            <TextField
                key="house_number_extension"
                label="Extension"
                error={this.state.invalid_address.house_number_extension ? true : undefined}
                onChange={({ target }) => {
                    this.handleChange('house_number_extension', target.value, true)
                }}
                type="text"
                style={style.textField.house_number_extension}
                placeholder="A"
                value={this.state.address.house_number_extension ? this.state.address.house_number_extension : ''}
            />,
            <br key="break_line" />,
            <TextField
                key="postal_code"
                label="Postal code"
                error={this.state.invalid_address.postal_code ? true : undefined}
                onChange={this.handlePostalCodeChange.bind(this)}
                placeholder={this.getHintTextByCountry()}
                value={this.state.address.postal_code}
                min={numericPostalCode ? 1001 : 0}
                max={numericPostalCode ? 9999 : undefined}
                type={numericPostalCode ? 'number' : 'text'}
            />,
            <TextField
                key="city"
                label="City"
                error={this.state.invalid_address.city ? true : undefined}
                type="text"
                onChange={({ target }) => { this.handleChange('city', target.value) }}
                placeholder="Nijmegen"
                style={style.textField.city}
                value={this.state.address.city}
            />,
            <TextField
                key="country"
                label="Country"
                select
                onChange={({ target }) => { this.handleChange('country', target.value) }}
                style={style.textField.country}
                value={this.state.address.country}
            >
                <MenuItem value="NL">The Netherlands</MenuItem>
                <MenuItem value="BE">Belgium</MenuItem>
                <MenuItem value="DE">Germany</MenuItem>
                <MenuItem value="LU">Luxembourg</MenuItem>
            </TextField>
        )
        return textFields
    }

    render() {
        const cannotSubmit = !_.every(_.valuesIn(this.state.invalid_address), val => val === false)
        const addresTypeSelection = !this.props.address.service_point || this.props.status === 'unprocessed' ? null : (
            <TextField
                select
                label="Method"
                onChange={({ target }) => { this.handleAddressTypeChange(target.value) }}
                style={style.select}
                value={this.state.addressType}
            >
                <MenuItem value="service_point">Servicepunt</MenuItem>
                <MenuItem value="normal_address">Normal address</MenuItem>
            </TextField>
        )
        return (
            <Dialog
                disableBackdropClick={true}
                onClose={this.props.onClose}
                open={this.props.open}
            >
                <DialogTitle>{this.props.subtitle}</DialogTitle>
                <DialogContent>
                    {this.getForm(this.state.address)}
                    {addresTypeSelection}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={style.submitButton}
                        key="edit_address_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmitClick.bind(this)}
                        color="secondary"
                    >
                        Submit
                    </Button>
                    <Button
                        key="edit_address_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

EditAddressDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    status: PropTypes.string,
    address: PropTypes.object.isRequired,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    subtitle: PropTypes.string,
    type: PropTypes.string,
}

EditAddressDialog.defaultProps = {
    isUpdated: false,
}

export default EditAddressDialog
