import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'
import routes from './client/app/routing/routes'
import store, {history} from './client/store'

import packageJson from '../package.json'
import buildInfo from './buildinfo.json'

// Import styles that used to be imported via webpack
import './style/reset.css'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './style/main.css'

// Import node polyfills
// In Webpack 4 these where automatically bundled
// In Webpack 5 this isn't the default anymore,
// But we can't modify the webpack config in create-react-app
// That is why we import them as soon as possible in the entrypoint of the app
import { Buffer } from 'buffer'
import 'util'
import { BarcodeScanner } from './client/app/utils/barcodescanner'
window.Buffer = Buffer

const environment = buildInfo?.environment ?? process.env.NODE_ENV
window.PROFORTOOL_APP_ENVIRONMENT = environment

if (window && packageJson) {
    window.PROFORTOOL_APP_VERSION = packageJson.version
}

if (window && buildInfo) {
    window.PROFORTOOL_APP_GIT_BRANCH = buildInfo.gitBranch
    window.PROFORTOOL_APP_GIT_COMMIT_HASH = buildInfo.gitCommitHash
}

/**
 * @link https://docs.sentry.io/platforms/javascript/configuration/options/
 */
Sentry.init({
    dsn: 'https://43c0edd0cd76457e95d4822310342b62@glitchtip.profortool.com/2',
    release: buildInfo.gitCommitHash,
    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Only enable Sentry on staging and production environments
    enabled: [ 'staging', 'production' ].includes(environment),

    // Glitchtip doesn't support session events
    autoSessionTracking: false,
})
if (window.PROFORTOOL_APP_VERSION && environment === 'production') {
    Sentry.setTag('profortool_version', window.PROFORTOOL_APP_VERSION)
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

const App = () => (
    <div>
        <Provider store={store}>
            <Router history={history} routes={routes} />
        </Provider>
    </div>
)

root.render(
//   <React.StrictMode>
    <App />
//   </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

window._keyboardScan = window._scan = BarcodeScanner.simulate
