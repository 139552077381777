import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, OutlinedInput, MenuItem,
} from '@material-ui/core'
import { capitalize } from '../../../utils/string'

import SupplierFilter from '../../shared/SupplierFilter'

import { Types } from '../../../types/returns/rtv'

const style = {
    summaryStyle: { card: { width: '100%' } },
    actionsStyle: { justifyContent: 'space-between', margin: 8 },
    outlinedInputLabel: {
        width: 'auto',
    },
}

const initialState = {
    supplier_id: null,
    type: Types.STANDARD,
}

class CreateRtvDialog extends Component {

    constructor(props) {
        super(props)
        this.state = initialState

        autoBind(this)
    }

    handleSubmit() {
        const state = this.state
        this.setState(initialState, () => {
            this.props.onSubmit(state)
        })
    }

    handleSupplierChange(e) {
        this.setState({
            supplier_id: e.target.value,
        })
    }

    handleTypeChange(e) {
        this.setState({ type: e.target.value })
    }

    getSupplier(mage_id) {
        return this.props.suppliers.find(s => +s.mage_id === +mage_id)
    }

    getDialogActions() {
        return [
            <Button
                key="close"
                onClick={this.props.onClose}
            >
                Close
            </Button>,
            <div key="primary-actions">
                <Button
                    onClick={this.handlePrev}
                >
                    Back
                </Button>
                <Button
                    style={{ marginLeft: 20 }}
                    color="primary"
                    variant="contained"
                    disabled={!this.state.supplier_id}
                    onClick={this.handleSubmit}
                >
                    Create
                </Button>
            </div>,
        ]
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this.props.onClose}
                aria-labelledby="add-rtv-form-dialog-title"
            >
                <DialogTitle id="add-rtv-form-dialog-title">
                    Create new draft RTV by supplier
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select supplier
                    </DialogContentText>
                    <SupplierFilter
                        onChange={this.handleSupplierChange}
                        value={this.state.supplier_id}
                        allowAll={false}
                        variant="outlined"
                        showLabel={false}
                    />
                    <DialogContentText>
                        Select type
                    </DialogContentText>
                    <Select
                        required={true}
                        margin="normal"
                        label="Select type"
                        value={this.state.type}
                        onChange={this.handleTypeChange}
                        input={
                            <OutlinedInput
                                labelWidth={style.outlinedInputLabel.width}
                                name="Type"
                                id="type"
                            />
                        }
                    >
                        {Object.keys(Types).map(key => (
                            <MenuItem key={key} value={Types[key]}>
                                {capitalize(key).replace(/_/g, ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions style={style.actionsStyle}>
                    {this.getDialogActions()}
                </DialogActions>
            </Dialog>
        )
    }
}

CreateRtvDialog.propTypes = {
    rtv_queue: PropTypes.array,
    suppliers: PropTypes.array,
    visible: PropTypes.bool,
    isUpdated: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSearchRtvQueue: PropTypes.func.isRequired,
}

CreateRtvDialog.defaultProps = {
    isUpdated: false,
    sales_orders: [],
}


export default CreateRtvDialog
