import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DoneIcon from '@material-ui/icons/Done'

import Colors from '../../../../../styles/colors'

class DeliveryOrderLocation extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    getBackgroundColor() {
        if (this.props.checked_out) {
            return Colors.green200
        }
        return null
    }

    render() {
        const location = this.props.location
        const color = this.getBackgroundColor()
        if (!location._id) {
            return null
        }
        return (
            <ExpansionPanel defaultExpanded={false} style={color ? { backgroundColor: color } : null}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`location-content-${location._id}`}
                    id={`location-header-${location._id}`}
                >
                    {this.props.checked_out && (
                        <DoneIcon />
                    )}
                    <strong>{this.props.location_ref}</strong>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {location.stocks.map(line_item => (
                                <TableRow key={line_item.supplier_sku}>
                                    <TableCell>{line_item.supplier_sku}</TableCell>
                                    <TableCell>{line_item.quantity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}

DeliveryOrderLocation.propTypes = {
    checked_out: PropTypes.bool,
    location_ref: PropTypes.string,
    location: PropTypes.object,
}
DeliveryOrderLocation.defaultProps = {
    location: {},
    location_ref: '',
}

export default DeliveryOrderLocation
