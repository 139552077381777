import React from 'react'
import PropTypes from 'prop-types'
import {TextField, MenuItem} from '@material-ui/core'

const style = {
    width: 240,
}

function SelectPrinter(props, context) {
    const printers = props.printers
    const combinedStyle = {...style, ...props.style}
    if (props.fullWidth) {
        delete combinedStyle.width
    }
    return (
        <TextField
            select
            style={combinedStyle}
            onChange={e => props.onChange && props.onChange(e.target.value)}
            value={props.value}
            label={context.translate(props.label)}
            fullWidth={props.fullWidth}
        >
            {
                printers.map(printer => (
                    <MenuItem key={printer.id} value={printer.id}>
                        {printer.name}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}

SelectPrinter.contextTypes = {
    translate: PropTypes.func.isRequired,
}
SelectPrinter.propTypes = {
    fullWidth: PropTypes.bool,
    type: PropTypes.oneOf(['label', 'a4']),
    label: PropTypes.string,
    printers: PropTypes.array,
    style: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
}
SelectPrinter.defaultProps = {
    style: {},
    label: 'Select printer',
}

export default SelectPrinter
