
const styles = {
    loadingIcon: {
        animation: 'loadingAnimation 2s infinite',
    },
}

export default function Loading ({ size = '1.5em' }) {
    return (
        <svg
            style={{...styles.loadingIcon, height: size }}
            focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="HourglassTopIcon"
        >
            <path d="m6 2 .01 6L10 12l-3.99 4.01L6 22h12v-6l-4-4 4-3.99V2H6zm10 14.5V20H8v-3.5l4-4 4 4z"></path>
        </svg>
    )
}
