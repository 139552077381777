import React from 'react'
import PropTypes from 'prop-types'

function PersonalisationLineItem({line_item}) {
    return (
        <tr>
            <td>{line_item.product.name}</td>
            <td>{line_item.product.sku}</td>
            <td>{line_item.quantity}</td>
        </tr>
    )
}

PersonalisationLineItem.propTypes = {line_item: PropTypes.object}
export default PersonalisationLineItem
