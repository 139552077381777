import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AutoSuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { refreshAutoCompleteProductVariants } from '../../../actions/variants'
import { searchAutoCompleteProductVariants } from '../../../commands/sockets/variants'

const styles = {
    input: {
        width: 400,
    },
    fullWidthInput: {
        width: '100%',
    },
    suggestion: {
        height: 48,
        fontSize: 14,
        padding: 12,
    },
}

function renderInputComponent(inputProps) {
    const { inputRef = () => { }, ref, ...other } = inputProps

    return (
        <TextField
            style={inputProps.fullWidth ? styles.fullWidthInput : styles.input}
            fullWidth={false}
            InputProps={{
                inputRef: node => {
                    ref(node)
                    inputRef(node)
                },
            }}
            {...other}
        />
    )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
        <div style={{ ...styles.suggestion, backgroundColor: isHighlighted ? '#ccc' : '#fff' }}>
            {parts.map((part, index) => {
                return part.highlight ? (
                    <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                    </span>
                ) : (
                    <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                    </strong>
                )
            })}
        </div>
    )
}

function getSuggestionValue(suggestion) {
    return suggestion.label
}

class SearchProductAutoComplete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            single: '',
            popper: '',
        }
        this.timeout = 0
    }

    componentDidMount() {
        this.props.refreshAutoCompleteProductVariants([])
    }

    handleSuggestionsFetchRequested({ value }) {
        let filters = {
            value,
            limit: 5,
            page: 1,
        }

        if (typeof this.props.appendDefaultFilters === 'function') {
            filters = this.props.appendDefaultFilters(filters)
        }

        if (this.props.supplierFilter) {
            filters.supplier_id = this.props.supplierFilter
        }

        if (this.props.sourceFilter) {
            filters.source = this.props.sourceFilter
        }

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.props.searchAutoCompleteProductVariants(filters)
        }, 500)
    }

    handleSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        })
    }

    handleChange(name) {
        return (event, { newValue }) => {
            this.setState({
                [name]: newValue,
            })
        }
    }

    mapVariant(variant) {
        return {
            label: `${variant.name} - ${variant.supplier_sku}${variant.ean ? ` - ${variant.ean}` : ''}`,
            variant,
        }
    }

    getSuggestions() {
        if (!this.state.single) {
            return []
        }

        const mapped = this.props.variants.map(this.mapVariant)
        if (this.props.suggestionFilter instanceof Function) {
            return mapped.filter(this.props.suggestionFilter)
        }
        return mapped
    }

    handleSuggestionSelected(_, { suggestion }) {
        const variant = this.props.variants.find(v => v.supplier_sku === suggestion.variant.supplier_sku)

        if (this.props.clearOnSubmit) {
            this.setState({ single: '' })
        }

        return this.props.onSuggestionSelected && this.props.onSuggestionSelected(variant)
    }

    render() {
        const suggestions = this.getSuggestions()
        const autoSuggestProps = {
            renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested.bind(this),
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested.bind(this),
            getSuggestionValue,
            renderSuggestion,
            onSuggestionSelected: this.handleSuggestionSelected.bind(this),
        }

        return (
            <AutoSuggest
                {...autoSuggestProps}
                inputProps={{
                    fullWidth: this.props.fullWidth,
                    label: 'Search a product by sku, ean, barcode or name',
                    value: this.state.single,
                    onChange: this.handleChange('single'),
                }}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square component="div">
                        {options.children}
                    </Paper>
                )}
            />
        )
    }
}

SearchProductAutoComplete.propTypes = {
    fullWidth: PropTypes.bool,
    variants: PropTypes.array.isRequired,
    suggestionFilter: PropTypes.func,
    supplierFilter: PropTypes.number,
    sourceFilter: PropTypes.string,
    onSuggestionSelected: PropTypes.func,
    clearOnSubmit: PropTypes.bool,
    refreshAutoCompleteProductVariants: PropTypes.func.isRequired,
    searchAutoCompleteProductVariants: PropTypes.func.isRequired,
    appendDefaultFilters: PropTypes.func,
}
SearchProductAutoComplete.defaultProps = {
    fullWidth: false,
    clearOnSubmit: false,
}

export default connect(({ autocomplete_variants = [] }) => {
    return { variants: autocomplete_variants }
}, dispatch => {
    const actions = { searchAutoCompleteProductVariants, refreshAutoCompleteProductVariants }
    return bindActionCreators(actions, dispatch)
})(SearchProductAutoComplete)
