import socket from './../socket'
import listeners from '../listeners/sockets/printers'

listeners(socket)

export function printers(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_PRINTERS':
            return action.data instanceof Array ? action.data : state

        default:
            return state
    }
}
