import autoBind from 'react-autobind'
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
    Divider,
    Fab,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Zoom,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'

const style = {
    list: { width: '100%' },

    listItem: {
        flexGrow: 1,
        flexBasis: 0,
    },

    singleFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
    firstFab: {
        position: 'absolute',
        bottom: -26,
        right: 90,
    },
    secondFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
}

class AddressList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditing: false,
            data: {},
        }

        autoBind(this)
    }

    getAddressKey(addressId) {
        // e.g. can be 'address_street' or 'billing_address_street'
        const addressTarget = addressId.split('_')[0]

        return addressTarget === 'address' ? 'address' : 'billing_address'
    }

    handleEditClick() {
        const supplier = this.props.supplier

        this.setState({
            isEditing: true,
            data: {
                address: supplier.address,
                billing_address: supplier.billing_address,
            },
        })
    }

    handleCloseClick() {
        this.setState({ isEditing: false, data: {} })
    }

    handleSaveClick() {
        const data = this.state.data
        const update = {
            address: data.address,
            billing_address: data.billing_address,
        }

        this.handleCloseClick()
        this.props.onAddressChange(update)
    }

    handleAddressStreetChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    street: e.target.value,
                },
            },
        })
    }

    handleAddressHouseNumberChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    house_number: e.target.value,
                },
            },
        })
    }

    handleAddressHouseNumberExtensionChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    house_number_extension: e.target.value.toUpperCase(),
                },
            },
        })
    }

    handleAddressCityChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    city: e.target.value,
                },
            },
        })
    }

    handleAddressPostalCodeChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    postal_code: e.target.value.toUpperCase(),
                },
            },
        })
    }

    handleAddressCountryCodeChange(e) {
        const data = this.state.data
        const addressTarget = this.getAddressKey(e.target.id)

        this.setState({
            data: {
                ...data,
                [addressTarget]: {
                    ...data[addressTarget],
                    country: e.target.value.toUpperCase(),
                },
            },
        })
    }

    render() {
        const { address, billing_address } = this.props.supplier
        const isEditing = this.state.isEditing

        return (
            <>
                <Paper style={this.props.style}>
                    <List style={style.list}>
                        <ListSubheader>Address</ListSubheader>
                        <Divider />
                        <ListItem>
                            {isEditing ? (
                                <ListItemText>
                                    <TextField
                                        id="address_street"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressStreetChange}
                                        value={this.state.data.address.street}
                                        label="Street"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Street"
                                    secondary={address.street}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (<>
                                <ListItemText>
                                    <TextField
                                        id="address_house_number"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressHouseNumberChange}
                                        value={this.state.data.address.house_number}
                                        label="House number"
                                    />
                                </ListItemText>
                                <ListItemText>
                                    <TextField
                                        id="address_house_number_extension"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressHouseNumberExtensionChange}
                                        value={this.state.data.address.house_number_extension}
                                        label="House number extension"
                                    />
                                </ListItemText>
                            </>) : (<>
                                <ListItemText
                                    style={style.listItem}
                                    primary="House number"
                                    secondary={address.house_number}
                                />
                                <ListItemText
                                    primary="House number extension"
                                    secondary={address.house_number_extension || '-'}
                                    style={style.listItem}
                                />
                            </>)}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (<>
                                <ListItemText>
                                    <TextField
                                        id="address_postal_code"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressPostalCodeChange}
                                        value={this.state.data.address.postal_code}
                                        label="Postal code"
                                    />
                                </ListItemText>
                                <ListItemText>
                                    <TextField
                                        id="address_city"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressCityChange}
                                        value={this.state.data.address.city}
                                        label="City"
                                    />
                                </ListItemText>
                            </>) : (<>
                                <ListItemText
                                    primary="Postal code"
                                    secondary={address.postal_code}
                                    style={style.listItem}
                                />
                                <ListItemText
                                    primary="City"
                                    secondary={address.city}
                                    style={style.listItem}
                                />
                            </>)}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (
                                <ListItemText>
                                    <TextField
                                        id="address_country"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressCountryCodeChange}
                                        value={this.state.data.address.country}
                                        label="Country"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Country"
                                    secondary={address.country}
                                />
                            )}
                        </ListItem>

                        <Divider />

                        <ListSubheader>Billing address</ListSubheader>
                        <ListItem>
                            {isEditing ? (
                                <ListItemText>
                                    <TextField
                                        id="billing_address_street"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressStreetChange}
                                        value={this.state.data.billing_address.street}
                                        label="Street"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Street"
                                    secondary={billing_address.street}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (<>
                                <ListItemText>
                                    <TextField
                                        id="billing_address_house_number"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressHouseNumberChange}
                                        value={this.state.data.billing_address.house_number}
                                        label="House number"
                                    />
                                </ListItemText>
                                <ListItemText>
                                    <TextField
                                        id="billing_address_house_number_extension"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressHouseNumberExtensionChange}
                                        value={this.state.data.billing_address.house_number_extension}
                                        label="House number extension"
                                    />
                                </ListItemText>
                            </>) : (<>
                                <ListItemText
                                    style={style.listItem}
                                    primary="House number"
                                    secondary={billing_address.house_number}
                                />
                                <ListItemText
                                    primary="House number extension"
                                    secondary={billing_address.house_number_extension || '-'}
                                    style={style.listItem}
                                />
                            </>)}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (<>
                                <ListItemText>
                                    <TextField
                                        id="billing_address_postal_code"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressPostalCodeChange}
                                        value={this.state.data.billing_address.postal_code}
                                        label="Postal code"
                                    />
                                </ListItemText>
                                <ListItemText>
                                    <TextField
                                        id="billing_address_city"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressCityChange}
                                        value={this.state.data.billing_address.city}
                                        label="City"
                                    />
                                </ListItemText>
                            </>) : (<>
                                <ListItemText
                                    primary="Postal code"
                                    secondary={billing_address.postal_code}
                                    style={style.listItem}
                                />
                                <ListItemText
                                    primary="City"
                                    secondary={billing_address.city}
                                    style={style.listItem}
                                />
                            </>)}
                        </ListItem>
                        <ListItem>
                            {isEditing ? (
                                <ListItemText>
                                    <TextField
                                        id="billing_address_country"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleAddressCountryCodeChange}
                                        value={this.state.data.billing_address.country}
                                        label="Country"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Country"
                                    secondary={billing_address.country}
                                />
                            )}
                        </ListItem>
                    </List>
                    {isEditing ? [
                        <Zoom key="close" in={true} timeout={200} unmountOnExit>
                            <Fab
                                aria-label="Close"
                                onClick={this.handleCloseClick}
                                style={style.firstFab}
                            >
                                <CloseIcon />
                            </Fab>
                        </Zoom>,
                        <Zoom key="save" in={true} timeout={200} unmountOnExit>
                            <Fab
                                color="primary"
                                aria-label="Done"
                                onClick={this.handleSaveClick}
                                style={style.secondFab}
                            >
                                <DoneIcon />
                            </Fab>
                        </Zoom>,
                    ] : (
                        <Zoom in={true} timeout={200} unmountOnExit>
                            <Fab
                                color="primary"
                                disabled={!_.get(this.props, 'features.canEditSupplier', false)}
                                aria-label="Edit"
                                onClick={this.handleEditClick}
                                style={style.singleFab}
                            >
                                <EditIcon />
                            </Fab>
                        </Zoom>
                    )}
                </Paper>
            </>
        )
    }
}

AddressList.propTypes = {
    feature_toggles: PropTypes.array,
    supplier: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    onAddressChange: PropTypes.func.isRequired,
}

export default AddressList
