import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getTypedOrder} from './../../../../actions/typed_orders'

import Header from '../../shared/Header'
import Customer from './../Customer'
import Shipments from './Shipments'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    left: {
        paddingTop: 30,
        width: '25%',
        float: 'left',
    },
}

class TypedOrderDetail extends Component {

    componentDidMount() {
        const source = this.props.supplier_id ? 'dropship' : this.props.params.source
        this.props.getTypedOrder(this.props.params.order_id, source, this.getSupplierId())
    }

    getSupplierId() {
        return this.props.supplier_id || this.props.params.supplier_id
    }

    render() {
        const order = this.props.typed_order
        const supplier = this.props.params.source !== 'dropship'
            ? {name: this.props.params.source}
            : (this.props.shipments[0] || {}).supplier
        return (
            <div style={style.container}>
                <Header
                    title={order.mage_id}
                    showSupplier={this.props.role === 'servicedesk' || this.props.role === 'finance'}
                    supplier={supplier}
                />
                <div style={style.left}>
                    <Customer
                        small={true}
                        customer={order.customer}
                    />
                </div>
                <Shipments
                    typed_order={order}
                    actions={true}
                    date={true}
                    email={this.props.email}
                    cancellations={this.props.cancellations}
                    shipments={this.props.shipments}
                />
            </div>
        )
    }
}

TypedOrderDetail.propTypes = {
    typed_order: PropTypes.object.isRequired,
    shipments: PropTypes.array,
    cancellations: PropTypes.array,
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    supplier_id: PropTypes.number,
    params: PropTypes.object.isRequired,
    getTypedOrder: PropTypes.func.isRequired,
}

export default connect(({typed_order, shipments, cancellations}) => {
    return {
        typed_order,
        shipments,
        cancellations,
    }
}, dispatch => {
    return bindActionCreators({getTypedOrder}, dispatch)
})(TypedOrderDetail)
