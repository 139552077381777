import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import {
    Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Grid, IconButton,
} from '@material-ui/core'
import ExitIcon from '@material-ui/icons/ExitToApp'

import { WAREHOUSE_PREFIX } from '../../../../types/logistics/warehouses/prefixes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getSalesOrderByMageId } from '../../../../../commands/sockets/sales_orders'
import { getDeliveryOrder } from '../../../../../commands/sockets/delivery_orders'

import Header from './../../../shared/Header'

import DeliveryOrderLocation from './delivery-order-detail/DeliveryOrderLocation'
import DeliveryOrderLineItems from './delivery-order-detail/DeliveryOrderLineItems'

const styles = {
    container: { width: '95%', margin: 'auto' },
    contents: { display: 'flex', marginTop: 12 },
    info: { width: 300, marginRight: 12 },
    items: { width: 'calc(100% - 312px)', height: 400, overflow: 'scroll' },
}

class DeliveryOrderDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {}

        autoBind(this)
    }

    componentDidMount() {
        this.props.getDeliveryOrder(+this.props.params.id)
    }

    handleNavigateToSalesOrder() {
        this.props.getSalesOrderByMageId(this.props.delivery_order.refs.order_ref)
    }

    render() {
        const { delivery_order } = this.props
        if (!delivery_order || !delivery_order.increment_id) {
            return null
        }
        return (
            <div style={styles.container}>
                <Header
                    title={`Delivery order: ${delivery_order.increment_id}`}
                    subheader={`Status: ${delivery_order.status}`}
                />
                <div style={styles.contents}>
                    <Paper style={styles.info}>
                        <List component="nav">
                            <ListItem button>
                                <ListItemText primary="Sales order" secondary={delivery_order.refs.order_ref} />
                                <ListItemSecondaryAction onClick={this.handleNavigateToSalesOrder}>
                                    <IconButton><ExitIcon /></IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem button>
                                <ListItemText
                                    primary="Fulfillment source"
                                    secondary={delivery_order.fulfillment_source}
                                />
                            </ListItem>
                            <ListItem button>
                                <ListItemText
                                    primary="Delivery to"
                                    secondary={delivery_order.delivery_to}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                    <Paper style={styles.items}>
                        <DeliveryOrderLineItems
                            line_items={delivery_order.line_items}
                        />
                    </Paper>
                </div>
                <div style={styles.contents}>
                    <Grid container spacing={3}>
                        {delivery_order.refs.location_refs.map(ref => {
                            const location = delivery_order.locations.find(l => {
                                return l.location.replace(WAREHOUSE_PREFIX, '') === ref
                            })
                            const checked_out = delivery_order.refs.checked_out_refs.includes(ref)
                            return (
                                <Grid item xs={4} key={ref}>
                                    <DeliveryOrderLocation
                                        location_ref={ref}
                                        location={location}
                                        checked_out={checked_out}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </div>
        )
    }
}

DeliveryOrderDetail.contextTypes = {}
DeliveryOrderDetail.propTypes = {
    params: PropTypes.object.isRequired,
    delivery_order: PropTypes.object.isRequired,
    getDeliveryOrder: PropTypes.func.isRequired,
    getSalesOrderByMageId: PropTypes.func.isRequired,
}
DeliveryOrderDetail.defaultProps = {
    delivery_order: {},
}

export default connect(({ delivery_order = {} }) => {
    return {
        delivery_order,
    }
}, dispatch => {
    return bindActionCreators({ getDeliveryOrder, getSalesOrderByMageId }, dispatch)
})(DeliveryOrderDetail)
