/**
 *  Invokes the action to show an dialog
 *  @param {Object} actionPayload - { title, message, type = 'info' }
 * @returns
 */
export function setActionDialog(actionPayload) {
    return { type: 'SET_ACTION_DIALOG', actionPayload }
}

export function clearActionDialog() {
    return { type: 'CLEAR_ACTION_DIALOG' }
}
