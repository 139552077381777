import socket from './../socket'
import listeners from '../listeners/sockets/stock_queue_items.js'

listeners(socket)

export function stock_queue_items(state = [], action) {
    switch (action.type) {
        case 'SEARCH_STOCK_QUEUE_ITEMS':
            socket.emit('stock-queue-items/get', action.filters)
            return state

        case 'REFRESH_STOCK_QUEUE_ITEMS':
            return action.stock_queue_items

        default:
            return state
    }
}
