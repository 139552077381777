import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableRow,
    TableCell,
    TablePagination,
} from '@material-ui/core'
import _ from 'lodash'

import WarningIcon from '@material-ui/icons/Warning'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    contentWithIcon: {
        width: 'auto',
        float: 'left',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
}

class RtvTable extends Component {

    constructor(props) {
        super(props)

        this.handleTableRowClick = this.handleTableRowClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.rtvs !== this.props.rtvs
            || nextProps.page !== this.props.page
            || nextProps.limit !== this.props.limit
    }

    handleTableRowClick(index) {
        return this.props.onRtvClick && this.props.onRtvClick(index)
    }

    handleChangePage() {
        // TablePagination - onChangePage
        // Method sometimes fires when no click on a page increment/decrement has been done
        // Paging didn't work when using the 'onChangePage' event
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    generateWarningIcon({ pricing }, colors) {
        if (pricing && pricing.missing_purchase_price) {
            return (<WarningIcon style={{ ...styles.icon, color: colors.amber700 }} />)
        }
        return null
    }

    renderRtvRows(rtvs) {
        if (rtvs.length > 0) {
            return rtvs.map((rtv, index) => (
                <TableRow
                    hover={true}
                    onClick={() => this.handleTableRowClick(index)}
                    key={rtv.rtv_id}
                    style={{ cursor: 'pointer' }}
                >
                    {
                        this.props.headerCols.map(column => (
                            <TableCell
                                key={`${rtv.rtv_id}.${column.property}`}
                            >
                                {!column.icon
                                    ? _.get(rtv, column.property, '')
                                    : (
                                        <div style={styles.contentWithIcon}>
                                            {_.get(rtv, column.property, '')}&nbsp;
                                        </div>
                                    )
                                }
                                {column.icon ? this.generateWarningIcon(rtv, this.context.colors) : ''}
                            </TableCell>
                        ))
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>No rtvs found.</TableCell>
            </TableRow>
        )
    }

    renderPagination(rtvs) {
        if (rtvs.length > 0) {
            const { page, limit, headerCols } = this.props
            const from = Math.max(0, (limit * page))
            const to = Math.min(from + limit, from + rtvs.length)
            return (
                <TableFooter style={{ height: 60 }}>
                    <TableRow>
                        <TableCell colSpan={headerCols.length}>
                            <TablePagination
                                component="div"
                                count={rtvs.length}
                                rowsPerPage={limit}
                                page={page}
                                labelDisplayedRows={() => `${from + 1} - ${to}`}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    disabled: page === 0,
                                    onClick: this.handlePageDecrementClick,
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    disabled: rtvs.length < limit,
                                    onClick: this.handlePageIncrementClick,
                                }}
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const rtvs = this.props.rtvs
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            this.props.headerCols.map(column => (
                                <TableCell
                                    style={{ fontWeight: 'bold' }}
                                    key={column.content}
                                    tooltip={column.tooltip}
                                >
                                    {column.content}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderRtvRows(rtvs)}
                </TableBody>
                {this.renderPagination(rtvs)}
            </Table>
        )
    }
}

RtvTable.contextTypes = {
    colors: PropTypes.object,
}

RtvTable.propTypes = {
    headerCols: PropTypes.array,
    pricing: PropTypes.node,
    rtvs: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,
    onRtvClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

RtvTable.defaultProps = {
    headerCols: [{
        content: 'ID',
        tooltip: 'The RTV ID',
        property: 'rtv_id',
    }, {
        content: 'Vendor reference',
        tooltip: 'The vendor reference',
        property: 'vendor_ref',
    }, {
        content: 'Supplier',
        tooltip: 'The Supplier',
        property: 'supplier.name',
    }, {
        content: 'Status',
        tooltip: 'status',
        property: 'status',
    }, {
        content: 'Type',
        tooltip: 'type',
        property: 'type',
    }, {
        content: 'Total value',
        tooltip: 'Value in purchase price',
        property: 'pricing.total_purchase_price',
        icon: true,
    }, {
        content: 'Date',
        tooltip: 'Order date',
        property: 'created_at',
    }],
}

export default RtvTable
