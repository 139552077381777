/**
 * Capitalize a string
 * @param {string} str String to capitalize
 * @returns {string} Capitalized string
 */
export function capitalize(str) {
    if (typeof str !== 'string' || !str.length) return ''
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/**
 * Cast a string value to a boolean
 * @param {string} str String value
 * @returns {boolean} Boolean value
 */
export function stringToBoolean(str) {
    const s = `${str}`.toLowerCase().trim()

    const falseValues = [
        'undefined',
        'null',
        'nan',
        '0',
        '',
        'no',
        'nee',
        'off',
        'uit',
        'disabled',
    ]

    return !falseValues.includes(s)
}

/**
 * Generate a random string
 * @param {int} length Number of characters
 * @returns {string} Random string
 */
export function randomString(length = 10) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let str = ''
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return str
}

/**
 * Pluralize a word
 * @param {string} word Word to pluralize
 * @param {number | array} number Number to use as count or an array to get count from
 * @returns {string} Pluralized word if count !== 1
 */
export function pluralize(word, number) {
    const count = Array.isArray(number) ? number.length : number
    return `${word}${count === 1 ? '' : 's'}`
}

/**
 * Make sure a variable is a string array
 * @param {any} val Variable to cast to string array
 * @returns {string[]} String array
 */
export function toStringArray(val) {
    if (val === undefined || val === null || Number.isNaN(val)) return []
    return Array.isArray(val) ? val.map(e => String(e)) : [ String(val) ]
}
