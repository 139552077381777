import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardTitle, Dialog, FlatButton, SelectField, MenuItem, TextField} from 'material-ui'
import _ from 'lodash'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getCouriers} from './../../../actions/shipments'

const style = {
    subheaderStyle: {
        fontFamily: 'Roboto',
    },
    title: {
        height: 30, padding: 6,
    },
    floatingActionButtonStyle: {
        position: 'absolute',
        bottom: -28,
        right: 20,
    },
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    innerDivStyle: {
        paddingTop: 8,
        paddingBottom: 12,
    },
}

class TrackingDialog extends Component {
    specialActions = {
        Nothing: 0,
        RemoveTracking: 1,
        GenerateNewTracking: 2,
    }

    constructor(props) {
        super(props)
        const shipment = props.shipment
        this.state = {
            tracking_code: _.get(shipment, 'shipping_label.tracking_code') || '',
            selected_courier: _.get(shipment, 'shipping_label.carrier_slug') || '',
            special_action: null,
        }
    }

    handleTrackingCodeChange({target}) {
        this.setState({
            tracking_code: (target.value || '').trim(),
            selected_courier: null,
        }, () => {
            if (this.state.tracking_code) {
                this.props.getCouriers(this.state.tracking_code, this.props.shipment.shipping_address.postal_code)
            }
        })
    }

    handleCourierSelectChange(e, index, selected_courier) {
        this.setState({
            selected_courier,
        })
    }

    handleSpecialActions(e, index, special_action) {
        this.setState({
            special_action,
        })
    }

    componentDidMount() {
        if (this.state.tracking_code) {
            this.props.getCouriers(this.state.tracking_code, this.props.shipment.shipping_address.postal_code)
        }
    }

    render() {
        const cannotSubmit = (!this.state.tracking_code || !this.state.selected_courier) && !this.state.special_action
        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={this.props.open}
                actions={[
                    <FlatButton
                        key="tracking_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={() => {
                            this.props.onTrackingSubmit(
                                this.props.shipment,
                                this.state.special_action === this.specialActions.RemoveTracking
                                    ? null
                                    : this.state.tracking_code,
                                this.state.selected_courier,
                                this.state.special_action === this.specialActions.GenerateNewTracking
                            )
                        }}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="tracking_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle style={style.title} subtitleStyle={style.subheaderStyle} subtitle="Update tracking"/>
                <TextField
                    hintText="Enter tracking code"
                    onChange={this.handleTrackingCodeChange.bind(this)}
                    value={this.state.tracking_code}
                />
                <br/>
                <SelectField
                    onChange={this.handleCourierSelectChange.bind(this)}
                    hintText="Select courier.."
                    value={this.state.selected_courier}
                >
                    {
                        _.map(this.props.couriers, ({slug, name}) => {
                            return (
                                <MenuItem
                                    value={slug}
                                    primaryText={name}
                                />
                            )
                        })
                    }
                </SelectField>
                <br />
                <SelectField
                    hintText="Select action to execute"
                    onChange={this.handleSpecialActions.bind(this)}
                    value={this.state.special_action}
                    style={{marginTop: 20}}
                >
                    <MenuItem value={this.specialActions.Nothing} primaryText="Do Nothing"/>
                    <MenuItem value={this.specialActions.RemoveTracking} primaryText="Remove Tracking Code"/>
                    <MenuItem value={this.specialActions.GenerateNewTracking} primaryText="Generate New Tracking Code"/>
                </SelectField>
            </Dialog>
        )
    }
}

TrackingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    couriers: PropTypes.array,
    shipment: PropTypes.object,
    onClose: PropTypes.func,
    onTrackingSubmit: PropTypes.func,
    getCouriers: PropTypes.func,
}

TrackingDialog.defaultProps = {
    shipment: {
        shipping_label: {},
        shipping_address: {},
    },
    couriers: [],
}

export default connect(({couriers}) => {
    return {couriers}
}, dispatch => {
    return bindActionCreators({getCouriers}, dispatch)
})(TrackingDialog)
