import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined'
import { showErrorNotification } from '../../../actions/notifications'

import { getGatewayUrl } from '../../../socket'

const style = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    fileButton: {
        marginTop: 20,
    },
    loadingIcon: {
        marginLeft: 20,
        marginTop: 18,
        animation: 'spin 1s infinite',
    },
}


class ExportWarehouseStocksDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            is_loading: false,
        }

        autoBind(this)
    }

    async handleDownloadCsvClick() {

        this.setState({
            is_loading: true,
        })

        try {
            const download_response = await fetch(`${getGatewayUrl()}/products/warehouse-inventory-export`, {
                method: 'GET',
                cache: 'default',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                credentials: 'omit',
                mode: 'cors',
                headers: {
                    'Authorization': this.props.access_token,
                },
                timeout: 6_000,
            })

            if (!download_response.ok) {
                this.props.showErrorNotification({
                    message: 'Couldn\'t download data: ' + download_response.statusText,
                })
                return
            }

            const blob_data = await download_response.blob()

            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob_data)
            link.download = 'warehouse-inventory-export.csv'
            link.click()

        } catch(error) {
            this.props.showErrorNotification({
                message: 'Couldn\'t download data: ' + JSON.stringify(error),
            })
        } finally {
            this.setState({
                is_loading: false,
            })
        }
    }

    render() {

        return (
            <Dialog
                open={true}
            >
                <DialogTitle>Export</DialogTitle>
                <DialogContent>
                    <p>It exports all warehouse items to a CSV file.</p>
                    <p>Downloading may take a while.</p>

                    <div style={style.container}>
                        <Button
                            key="download"
                            style={style.fileButton}
                            variant="contained"
                            onClick={this.handleDownloadCsvClick}
                            disabled={this.state.is_loading}
                        >
                            Download CSV export
                        </Button>

                        {this.state.is_loading && (
                            <CachedOutlinedIcon style={style.loadingIcon} />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        key="import_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ExportWarehouseStocksDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
}

ExportWarehouseStocksDialog.defaultProps = {}

function mapStateToProps(state) {
    return {
        access_token: state.session.token,
    }
}

export default connect(
    mapStateToProps,
    dispatch => {
        return bindActionCreators({
            showErrorNotification,
        }, dispatch)
    }
)(ExportWarehouseStocksDialog)
