import Colors from './../colors'

/*
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
const profortoBaseThemev1 = {
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        htmlFontSize: 12,
        useNextVariants: true,
    },
    palette: {
        primary: { main: Colors.primaryColor100 },
        secondary: { main: Colors.secondaryColor100 },
        white: { main: Colors.white },
        warning: { main: Colors.yellow700 },
        error: { main: Colors.red600 },
        accept: { main: Colors.green600 },
    },
    props: {
        MuiSelect: {
            SelectDisplayProps: { style: { background: 'transparent' } },
        },
        MuiTableCell: {
            style: {
                fontSize: '1.3333333333333333rem',
            },
        },
        /**
         * Looks like overriding MuiTextField this is broken at the moment
         * @see https://github.com/mui-org/material-ui/issues/14107
         */
        MuiTextField: {
            InputLabelProps: { shrink: true },
        },
    },
}

export default profortoBaseThemev1
