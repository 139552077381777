import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {SelectField, MenuItem} from 'material-ui'

const packedOptions = [{
    text: 'All',
    value: 0,
}, {
    text: 'Packed',
    value: 1,
}, {
    text: 'Unpacked',
    value: -1,
}]

class PackedFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <SelectField
                style={this.props.style}
                onChange={this.props.onChange}
                value={this.props.value}
                floatingLabelText="Select packed state"
            >
                {
                    packedOptions.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            primaryText={option.text}
                        />
                    ))
                }
            </SelectField>
        )
    }
}

PackedFilter.propTypes = {
    value: PropTypes.number.isRequired,
    style: PropTypes.object,
    onChange: PropTypes.func.isRequired,
}
PackedFilter.defaultProps = {
    style: {},
}

export default PackedFilter
