import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, SelectField, MenuItem } from 'material-ui'

const style = {
    textField: { height: 72, marginRight: 20, width: '35%' },
    textFieldInput: { height: 30, lineHeight: '14px', bottom: 12, position: 'absolute' },
    textFieldHint: { lineHeight: '20px', fontSize: 14, fontWeight: 'bold' },
    selectField: { width: 200, marginRight: 15 },
    filtersContainer: {
        height: 60,
        width: '95%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
    },
}

const statuses = [{
    value: 'draft',
    text: 'Draft',
}, {
    value: 'on hold',
    text: 'On hold',
}, {
    value: 'accepted',
    text: 'Accepted',
}, {
    value: 'rejected',
    text: 'Rejected',
}, {
    value: 'prepared',
    text: 'Prepared',
}, {
    value: 'invoiced',
    text: 'Invoiced',
}, {
    value: 'refunding',
    text: 'Refunding',
}, {
    value: 'refunded',
    text: 'Refunded',
}]

const actions = [{
    value: 'manual',
    text: 'Manual',
}, {
    value: 'discount',
    text: 'Discount',
}, {
    value: 'rma',
    text: 'RMA',
}, {
    value: 'cancellation',
    text: 'Cancellation',
}]

const methods = [{
    text: 'Achteraf betalen (0,99)',
    value: 'billink',
}, {
    text: 'iDeal',
    value: 'msp_ideal',
}, {
    text: 'Purchase order',
    value: 'purchaseorder',
}, {
    text: 'Mistercash',
    value: 'msp_mistercash',
}, {
    text: 'Mastercard',
    value: 'msp_mastercard',
}, {
    text: 'Bank transfer',
    value: 'msp_banktransfer',
}, {
    text: 'PayPal',
    value: 'msp_paypal',
}, {
    text: 'Visa',
    value: 'msp_visa',
}, {
    text: 'EffectConnect payment',
    value: 'effectconnect_payment',
}]

class CreditMemoFilters extends Component {

    handleSearchFieldChange(e) {
        this.props.onSearchChange(e.target.value)
    }

    handleStatusChange(e, index, value) {
        this.props.onStatusChange(value)
    }

    handleActionChange(e, index, value) {
        this.props.onActionChange(value)
    }

    handlePaymentMethodChange(e, index, value) {
        this.props.onPaymentMethodChange(value)
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={style.textField}
                    inputStyle={style.textFieldInput}
                    hintStyle={style.textFieldHint}
                    floatingLabelText="Search by order, RMA or invoice."
                    value={this.props.filters.criteria}
                    onChange={this.handleSearchFieldChange.bind(this)}
                />
                <SelectField
                    style={style.selectField}
                    onChange={this.handleStatusChange.bind(this)}
                    value={this.props.filters.status}
                    floatingLabelText="Select status"
                >
                    <MenuItem
                        value="all"
                        primaryText="All"
                    />
                    {
                        statuses.map(status => (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                                primaryText={status.text}
                            />
                        ))
                    }
                </SelectField>
                <SelectField
                    style={style.selectField}
                    onChange={this.handleActionChange.bind(this)}
                    value={this.props.filters.prior_action}
                    floatingLabelText="Select action"
                >
                    <MenuItem
                        value="all"
                        primaryText="All"
                    />
                    {
                        actions.map(status => (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                                primaryText={status.text}
                            />
                        ))
                    }
                </SelectField>
                <SelectField
                    style={style.selectField}
                    onChange={this.handlePaymentMethodChange.bind(this)}
                    value={this.props.filters.payment_method}
                    floatingLabelText="Select payment method"
                >
                    <MenuItem
                        value="all"
                        primaryText="All"
                    />
                    {
                        methods.map(status => (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                                primaryText={status.text}
                            />
                        ))
                    }
                </SelectField>
            </div>
        )
    }
}

CreditMemoFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onActionChange: PropTypes.func.isRequired,
    onPaymentMethodChange: PropTypes.func.isRequired,
}

export default CreditMemoFilters
