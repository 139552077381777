import store from '../../store'
import { push } from 'react-router-redux'

import { getPurchaseInboundReceipts } from '../../commands/sockets/inbound_receipts'
import { getOutboundChecklist } from '../../commands/sockets/outbound_checklists'

import { Statuses } from '../../app/types/purchase/purchase_order'

function refreshPurchaseOrder(purchase_order) {
    store.dispatch({ type: 'REFRESH_PURCHASE_ORDER', purchase_order })
}
export default function initPurchaseOrdersSockets(socket) {
    socket.on('purchase-orders/search-result', purchase_orders => {
        store.dispatch({ type: 'REFRESH_PURCHASE_ORDERS', purchase_orders })
    })

    socket.on('purchase-order', purchase_order => {
        refreshPurchaseOrder(purchase_order)
        if (![Statuses.DRAFT, Statuses.PENDING].includes(purchase_order.status)) {
            getOutboundChecklist(purchase_order.order_id)
            getPurchaseInboundReceipts(purchase_order.order_id)
        }
    })

    socket.on('purchase-order/draft/updated', refreshPurchaseOrder)
    socket.on('purchase-order/line-items-updated', refreshPurchaseOrder)
    socket.on('purchase-order/finalized-draft', refreshPurchaseOrder)

    socket.on('purchase-order/created', purchase_order => {
        store.dispatch(push(`/purchases/purchase-orders/${purchase_order._id}`))
    })

    socket.on('purchase-order/line-item/triaged', _id => {
        store.dispatch({ type: 'GET_PURCHASE_ORDER', _id })
    })

    socket.on('purchase-order/pdf', pdf => {
        store.dispatch({ type: 'REFRESH_PURCHASE_ORDER_PDF', pdf })
    })
}
