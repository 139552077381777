import CachedIcon from '@material-ui/icons/Cached'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/Info'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import NearMeIcon from '@material-ui/icons/NearMe'
import RefreshIcon from '@material-ui/icons/Refresh'
import TimerIcon from '@material-ui/icons/Timer'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import WarningIcon from '@material-ui/icons/Warning'

import Colors from '../styles/colors'

export const ShipmentStatus = {
    'cancellation requested': {
        color: Colors.amber800,
        icon: WarningIcon,
    },
    cancelled: {
        color: Colors.red600,
        icon: CloseIcon,
    },
    requested: {
        color: Colors.brown400,
        icon: TimerIcon,
    },
    pending: {
        color: Colors.blueGrey400,
        icon: RefreshIcon,
    },
    info_received: {
        color: Colors.grey800,
        icon: InfoIcon,
    },
    being_processed: {
        color: Colors.grey800,
        icon: CachedIcon,
    },
    pre_transit: {
        color: Colors.tertiaryColor100,
        icon: TurnedInIcon,
    },
    in_transit: {
        color: Colors.primaryColor100,
        icon: LocalShippingIcon,
    },
    out_for_delivery: {
        color: Colors.secondaryColor100,
        icon: NearMeIcon,
    },
    delivered: {
        color: Colors.green600,
        icon: HomeIcon,
    },
    attempt_fail: {
        color: Colors.purple400,
        icon: FlashOnIcon,
    },
    exception: {
        color: Colors.red700,
        icon: ErrorIcon,
    },
}
