import socket from '../../socket'
import { getUserEmail } from '../../store'

export function uploadNewWarehouseCsv(data) {
    socket.emit('stocks/warehouse/upload-new', data, getUserEmail())
    return { type: 'IMPORT_NEW_WAREHOUSE_CSV', data }
}

export function uploadConvertFromCrossdockCsv(data) {
    socket.emit('stocks/warehouse/upload-convert-from-crossdock', data, getUserEmail())
    return { type: 'IMPORT_CONVERT_FROM_CROSSDOCK_CSV', data }
}

export function uploadExistingWarehouseCsv(data) {
    socket.emit('stocks/warehouse/upload-existing', data, getUserEmail())
    return { type: 'IMPORT_EXISTING_WAREHOUSE_CSV', data }
}

export function uploadPhaseOutCsv(data) {
    socket.emit('stocks/warehouse/phase-out', data, getUserEmail())
    return { type: 'IMPORT_PHASE_OUT_CSV', data }
}

export function adjustInventoryInbound(data) {
    socket.emit('stocks/adjust-inbound', data, getUserEmail())
    return { type: 'ADJUST_INVENTORY_INBOUND', data }
}

export function adjustInventoryOutbound(data) {
    socket.emit('stocks/adjust-outbound', data, getUserEmail())
    return { type: 'ADJUST_INVENTORY_OUTBOUND', data }
}
