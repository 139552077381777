import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'

const style = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    tableFooter: {
        height: 60,
    },
    tablePagination: {
        fontSize: 14,
    },
}

class TypedOrderTable extends Component {

    constructor(props) {
        super(props)

        this.handleTableRowClick = this.handleTableRowClick.bind(this)
        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    }

    handleTableRowClick(rowNumber) {
        return () => { this.props.onTableRowClick(rowNumber) }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.typed_orders !== this.props.typed_orders
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    handleChangeRowsPerPage(e, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    renderTypedOrderRows(typed_orders) {
        if (typed_orders.length > 0) {
            return typed_orders.map((typed_order, index) => {
                let suppliers = null
                if (typed_order.type === 'crossdock' || typed_order.type === 'warehouse') {
                    suppliers = Object.values(typed_order.shipments.reduce((acc, s) => {
                        s.line_items.forEach(li => {
                            if (!acc[li.product.supplier.mage_id]) {
                                acc[li.product.supplier.mage_id] = li.product.supplier.name
                            }
                        })
                        return acc
                    }, {})).join(', ')
                } else if (typed_order.type === 'dropship') {
                    suppliers = typed_order.supplier.name
                }
                return (
                    <TableRow
                        onClick={this.handleTableRowClick(index)}
                        key={typed_order.fulfillment_id}
                        hover={true}
                        style={{cursor: 'pointer'}}
                    >
                        {
                            !this.props.extendedTable ? null : [
                                <TableCell
                                    key={`${typed_order.fulfillment_id}.${typed_order.type}`}
                                    style={{textTransform: 'capitalize'}}
                                >
                                    {typed_order.type}
                                </TableCell>,
                                <TableCell
                                    key={`${typed_order.fulfillment_id}.${suppliers}`}
                                    style={{textTransform: 'capitalize', width: '25%'}}
                                >
                                    {suppliers}
                                </TableCell>,
                            ]
                        }
                        <TableCell>
                            {typed_order.mage_id || (typed_order.fulfillment_id || '').split('_')[0]}
                        </TableCell>
                        <TableCell>{typed_order.status}</TableCell>
                        <TableCell>{typed_order.shipments.map(s => {
                            return moment(s.estimated_for).format('DD-MM-YYYY')
                        }).join(', ')}</TableCell>
                    </TableRow>
                )
            })
        }
        return (
            <TableRow colSpan={this.props.headerCols.length} tyle={style.blankListStateMessage}>
                <TableCell>No orders found.</TableCell>
            </TableRow>
        )
    }

    renderTableFooter(typed_orders) {
        const {page, limit, headerCols} = this.props
        const from = Math.max(0, (limit * page))
        const to = Math.min(from + limit, from + typed_orders.length)
        return (
            <TableFooter style={style.tableFooter}>
                <TableRow>
                    <TableCell colSpan={!this.props.extendedTable ? headerCols.length : headerCols.length + 2 }>
                        <TablePagination
                            component="div"
                            style={style.tablePagination}
                            count={typed_orders.length}
                            rowsPerPage={limit}
                            page={page} // 0 is first page
                            labelRowsPerPage={<span style={style.tablePagination}>Number of orders per page</span>}
                            labelDisplayedRows={() => {
                                return (<span style={style.tablePagination}>{`${from + 1} - ${to}`}</span>)
                            }}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                                disabled: page === 0,
                                onClick: this.handlePageDecrementClick,
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                disabled: typed_orders.length < limit,
                                onClick: this.handlePageIncrementClick,
                            }}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={() => {}}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        )
    }

    render() {
        const typedOrderRows = this.renderTypedOrderRows(this.props.typed_orders)
        const navigation = this.renderTableFooter(this.props.typed_orders)
        const extendedColumns = !this.props.extendedTable ? null : [
            <TableCell key="source">Source</TableCell>,
            <TableCell
                style={{width: '25%'}}
                key="suppliers"
            >
                Supplier(s)
            </TableCell>,
        ]

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {extendedColumns}
                            {
                                this.props.headerCols.map(column => (
                                    <TableCell variant="head" key={column.content} tooltip={column.tooltip}>
                                        {column.content}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {typedOrderRows}
                    </TableBody>
                    {navigation}
                </Table>
            </div>
        )
    }
}

TypedOrderTable.propTypes = {
    typed_orders: PropTypes.array,
    headerCols: PropTypes.array,
    onTableRowClick: PropTypes.func,
    onPageIncrement: PropTypes.func,
    onPageDecrement: PropTypes.func,
    onLimitChange: PropTypes.func,
    extendedTable: PropTypes.bool,
    page: PropTypes.number,
    limit: PropTypes.number,
}


TypedOrderTable.defaultProps = {
    headerCols: [{
        content: 'Order number',
        tooltip: 'The sales order number',
    }, {
        content: 'State',
        tooltip: 'The current state of the order',
    }, {
        content: 'Ship before',
        tooltip: 'Estimated delivery dates for the shipments of this order',
    }],
}

export default TypedOrderTable
