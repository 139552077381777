import { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { FloatingActionButton, FontIcon } from 'material-ui'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { searchSuppliers } from './../../../../commands/sockets/suppliers'
import {
    addPurchaseRequisitions,
    searchPurchaseRequisitions,
} from './../../../../commands/sockets/purchase_requisitions'

import Colors from './../../../styles/colors'

import AddPurchaseRequisitionDialog from './AddPurchaseRequisitionDialog'
import PurchaseRequisitionTable from './PurchaseRequisitionTable'
import PurchaseRequisitionLegenda from './PurchaseRequisitionLegenda'
import PurchaseRequisitionFilters from './PurchaseRequisitionFilters'

const STORAGE_KEY = 'purchase-requisition-filters'

const style = {
    floatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        right: 20,
        bottom: 20,
    },
    statusColors: {
        pending: 'rgba(255, 204, 0, 0.1)',
        approved: 'rgba(117, 191, 226, 0.1)',
        revoked: 'rgba(255, 75, 75, 0.1)',
    },
}

class PurchaseRequisitionList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            criteria: filters.criteria || '',
            supplier_id: filters.supplier_id || 'all',
            limit: filters.limit || 20,
            page: filters.page || 1,
            shouldRender: false,
            addDialogOpen: false,
        }

        autoBind(this)
    }

    updateShouldRenderIfNeeded(prevProps) {
        if (this.props.purchase_requisitions !== prevProps.purchase_requisitions) {
            this.setState({
                shouldRender: true,
            })
        }
    }

    componentDidUpdate(prevProps) {
        this.updateShouldRenderIfNeeded(prevProps)
    }

    componentDidMount() {
        this.searchPurchaseRequisitions()
        this.props.searchSuppliers({})
    }

    getFilterState() {
        return {
            criteria: this.state.criteria,
            supplier_id: this.state.supplier_id,
            limit: this.state.limit,
            page: this.state.page,
        }
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchPurchaseRequisitions, 500)
    }

    searchPurchaseRequisitions() {
        const filters = this.getFilterState()
        this.storeFilters(filters)
        if (filters.supplier_id === 'all') {
            delete filters.supplier_id
        }
        this.props.searchPurchaseRequisitions(filters)
    }

    handlePageDecrement() {
        this.setState({
            page: this.state.page - 1,
            shouldRender: false,
        }, this.searchPurchaseRequisitions)
    }

    handlePageIncrement() {
        this.setState({
            page: this.state.page + 1,
            shouldRender: false,
        }, this.searchPurchaseRequisitions)
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    handleCriteriaChange(criteria) {
        this.setState({
            criteria,
            page: 1,
        }, this.setSearchTimeout)
    }

    handleSupplierChange(supplier_id) {
        this.setState({
            supplier_id,
            page: 1,
        }, this.searchPurchaseRequisitions)
    }

    handleAddDialogSubmit(items) {
        this.handleCloseDialogClick()
        this.props.addPurchaseRequisitions(items)
    }

    handleFloatingActionButtonClick() {
        this.setState({
            addDialogOpen: true,
        })
    }

    handleCloseDialogClick() {
        this.setState({
            addDialogOpen: false,
        })
    }

    render() {
        return (
            <div>
                <PurchaseRequisitionFilters
                    filters={this.getFilterState()}
                    suppliers={this.props.suppliers}
                    onCriteriaChange={this.handleCriteriaChange}
                    onSupplierChange={this.handleSupplierChange}
                />
                <PurchaseRequisitionLegenda />
                <PurchaseRequisitionTable
                    items={this.props.purchase_requisitions}
                    statusColors={style.statusColors}
                    shouldRender={this.state.shouldRender}
                    limit={this.state.limit}
                    page={this.state.page}
                    pagination={this.props.pagination}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                <FloatingActionButton
                    onClick={this.handleFloatingActionButtonClick}
                    backgroundColor={Colors.primaryColor100}
                    style={style.floatingActionButtonStyle}
                >
                    <FontIcon className="material-icons">add</FontIcon>
                </FloatingActionButton>
                <AddPurchaseRequisitionDialog
                    suppliers={this.props.suppliers}
                    visible={this.state.addDialogOpen}
                    onClose={this.handleCloseDialogClick}
                    onSubmit={this.handleAddDialogSubmit}
                />
            </div>
        )
    }
}

PurchaseRequisitionList.propTypes = {
    email: PropTypes.string,
    filters: PropTypes.object,
    suppliers: PropTypes.array,
    purchase_requisitions: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    searchPurchaseRequisitions: PropTypes.func.isRequired,
    addPurchaseRequisitions: PropTypes.func,
    searchSuppliers: PropTypes.func,
}

PurchaseRequisitionList.defaultProps = {
    suppliers: [],
    purchase_requisitions: [],
}

export default connect(({ purchase_requisitions, suppliers, filters, pagination }) => {
    return {
        purchase_requisitions,
        suppliers,
        filters: filters.purchase_requisitions,
        pagination: pagination.purchase_requisitions || { current_page: 1 },
    }
}, dispatch => {
    const actions = {
        addPurchaseRequisitions,
        searchPurchaseRequisitions,
        searchSuppliers,
    }
    return bindActionCreators(actions, dispatch)
})(PurchaseRequisitionList)

