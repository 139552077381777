import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table, TableHeader, TableBody, TableFooter, TableRow, TableHeaderColumn, TableRowColumn} from 'material-ui'
import _ from 'lodash'

import Pagination from './../../shared/Pagination'
import ChannelAvatar from './../../shared/ChannelAvatar'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    tableRow: {cursor: 'pointer'},
}

class DebitMemoTable extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    navigate(rowNumber) {
        if (this.props.items[rowNumber]) {
            const id = this.props.items[rowNumber].mage_id
            this.context.router.push(`/finance/debit-memos/${id}`)
        }
    }

    handleCellClick(rowNumber, columnId) {
        if (columnId !== -1) {
            this.navigate(rowNumber)
        }
    }

    renderTableRows(items) {
        if (items.length > 0) {
            return items.map((memo, index) => (
                <TableRow
                    key={index}
                    style={styles.tableRow}
                >
                    <TableRowColumn style={{paddingRight: 0}} width="3%">
                        <ChannelAvatar style={{width: 20, height: 20}} slug={_.get(memo, 'channel.slug', '')}/>
                    </TableRowColumn>
                    {
                        _.map(this.props.headerCols, (column, i) => {
                            return (
                                <TableRowColumn
                                    style={column.style}
                                    key={i}
                                >
                                    {memo[column.property]}
                                </TableRowColumn>
                            )
                        })
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableRowColumn>No items found.</TableRowColumn>
            </TableRow>
        )
    }

    renderNavigation(items) {
        if (items.length > 0) {
            return (
                <TableFooter style={{height: 60}}>
                    <TableRow>
                        <TableRowColumn>
                            <Pagination
                                page={this.props.page}
                                limit={this.props.limit}
                                onPreviousPageClick={this.props.onPageDecrement}
                                onNextPageClick={this.props.onPageIncrement}
                                items={this.props.items}
                            />
                        </TableRowColumn>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const items = this.props.items
        const tableRows = this.renderTableRows(items)
        const navigation = this.renderNavigation(items)

        return (
            <Table
                onCellClick={this.handleCellClick.bind(this)}
                multiSelectable={true}
            >
                <TableHeader displaySelectAll={false}>
                    <TableRow>
                        {
                            _.map(this.props.headerCols, (column, index) => {
                                return (
                                    <TableHeaderColumn
                                        style={_.merge(_.clone(column.style), {fontWeight: 'bold'})}
                                        key={index}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </TableHeaderColumn>
                                )
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} showRowHover={true}>
                    {tableRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

DebitMemoTable.propTypes = {
    headerCols: PropTypes.array,
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

DebitMemoTable.contextTypes = {
    router: PropTypes.object,
}


DebitMemoTable.defaultProps = {
    headerCols: [{
        content: 'Sales order number',
        tooltip: 'The sales order number',
        style: {width: '20% '},
        property: 'mage_id',
    }, {
        content: 'Exact invoice number',
        tooltip: 'Invoice number in Exact',
        style: {width: '20% '},
        property: 'invoice_id',
    }, {
        content: 'Date',
        tooltip: 'Order date',
        style: {width: '20% '},
        property: 'created_at',
    }, {
        content: 'Grand Total',
        tooltip: 'Total value of the debit memo',
        style: {width: '20% '},
        property: 'grand_total',
    }, {
        content: 'Status',
        tooltip: 'Status of the memo',
        style: {width: '20% '},
        property: 'status',
    }],
}


export default DebitMemoTable
