import socket from '../../socket'
import {getUserEmail} from '../../store'
export function getPackingSlip(shipment_id, source) {
    socket.emit('packing-slip/get', {shipment_id, source}, getUserEmail())
    return {type: 'GET_PACKING_SLIP', shipment_id, source}
}

export function printPackingSlip(shipment_id, printer, { with_label = true, fulfillment_source, store_slug }) {
    socket.emit(
        'packing-slip/print',
        { shipment_id, printer, with_label, fulfillment_source, add_return_page: store_slug !== 'proforto-bol' },
        getUserEmail()
    )
    return {type: 'NOTHING'}
}
