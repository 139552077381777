import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import SupplierFilter from '../../shared/SupplierFilter'

const styles = {
    actionsStyle: { justifyContent: 'space-between', margin: 8 },
    supplierFilter: { width: 400, marginRight: 15, marginLeft: 15, overflow: 'hidden' },
}

const initialState = {
    supplier: {
        mage_id: 0,
        name: '',
    },
}

class PurchaseOrderCreateDialog extends Component {

    constructor(props) {
        super(props)
        this.state = initialState

        autoBind(this)
    }

    handleSubmit() {
        const state = this.state
        this.setState(initialState, () => {
            const purchase_order = {
                supplier: state.supplier,
            }
            this.props.onSubmit(purchase_order)
        })
    }

    handleSupplierChange(e) {
        this.setState({
            supplier: this.getSupplier(e.target.value),
        })
    }

    handleClose() {
        this.setState(initialState, () => {
            return this.props.onClose && this.props.onClose()
        })
    }

    getSupplier(mage_id) {
        return this.props.suppliers.find(s => +s.mage_id === +mage_id)
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={this.props.open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this.handleClose}
                aria-labelledby="add-po-form-dialog-title"
            >
                <DialogTitle id="add-po-form-dialog-title">
                    Create new draft purchase order
                </DialogTitle>
                <DialogContent>
                    <SupplierFilter
                        style={styles.supplierFilter}
                        onChange={this.handleSupplierChange}
                        value={this.state.supplier.mage_id}
                        allowAll={false}
                    />
                </DialogContent>
                <DialogActions style={styles.actionsStyle}>
                    <Button onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ marginLeft: 20 }}
                        color="primary"
                        variant="contained"
                        disabled={this.state.supplier.mage_id === 0}
                        onClick={this.handleSubmit}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PurchaseOrderCreateDialog.propTypes = {
    open: PropTypes.bool,
    suppliers: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default PurchaseOrderCreateDialog
