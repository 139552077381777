import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@material-ui/core'

import SupplierFilter from './../shared/SupplierFilter'

import Colors from './../../styles/colors'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block',
    },
    fileButton: {
        marginRight: 20,
    },
    supplierFilter: {
        width: 400,
        marginBottom: 15,
    },
    commaSeparatedHint: {
        marginTop: 15,
    },
}

/* eslint-disable max-len */
const new_example = encodeURI(
    'Naam,Merk,Artikelnummer,EAN,Kleur,Maat,Minimum,Consignatie,Eigendomsvoorbehoud,Eigen-voorraad,Inkoophoeveelheid,Locatie,Doorlooptijd,Source,Channels\n' +
    'De Berkel Livia zorgjas,De Berkel,2565326-XL-wit,8717068132957,wit,XL,4,3,0,1,4,A01-01A,3,warehouse,proforto-web-nl;proforto-bol\n' +
    'De Berkel Livia zorgjas,De Berkel,3322326-L-wit,8717068004735,wit,L,4,2,1,0,1,A01-01B;A01-01C,2,crossdock,proforto-web-nl;proforto-web-be'
)
const convert_from_crossdock_example = encodeURI(
    'Artikelnummer,Minimum,Consignatie,Eigendomsvoorbehoud,Eigen-voorraad,Inkoophoeveelheid,Locatie,Source\n' +
    '2565326-XL-wit,4,3,0,1,4,A01-01A,warehouse\n' +
    '3322326-L-wit,4,2,1,0,1,A01-01B;A01-01C,warehouse'
)
const update_example = encodeURI(
    'Artikelnummer,Consignatie,Eigendomsvoorbehoud,Eigen-voorraad,Nieuwe-locaties\n' +
    '2565326-XL-wit,3,0,1,A01-01A;A01-01B\n' +
    '3322326-L-wit,3,0,1'
)
const add_locations_example = encodeURI(
    'Artikelnummer,Nieuwe-locaties\n' +
    '2565326-XL-wit,A01-01A;A01-01B\n'
)
const update_minimums_example = encodeURI(
    'Artikelnummer,Minimum\n' +
    '2565326-XL-wit,3\n' +
    '3322326-L-wit,3'
)
const phase_out_example = encodeURI(
    'Artikelnummer,Uitfaseren\n' +
    '2565326-M-wit,negeren\n' +
    '2565326-L-wit,crossdock\n' +
    '3322326-XL-wit,end-of-life'
)
/* eslint-enable max-len */

const ImportTypes = {
    IMPORT_NEW: 'import-warehouse',
    CONVERT_FROM_CROSSDOCK: 'convert-from-crossdock',
    UPDATE_EXISTING: 'update-existing',
    UPDATE_MINIMUMS: 'update-minimums',
    PHASE_OUT: 'phase-out',
}

const RequiredColumns = {
    [ImportTypes.IMPORT_NEW]: [
        'naam',
        'merk',
        'artikelnummer',
        'ean',
        'kleur',
        'consignatie',
        'eigendomsvoorbehoud',
        'eigen_voorraad',
        'maat',
        'locatie',
        'inkoophoeveelheid',
        'doorlooptijd',
        'source',
        'channels',
    ],
    [ImportTypes.CONVERT_FROM_CROSSDOCK]: [
        'artikelnummer',
        'minimum',
        'consignatie',
        'eigendomsvoorbehoud',
        'eigen_voorraad',
        'inkoophoeveelheid',
        'locatie',
    ],
    [ImportTypes.UPDATE_EXISTING]: [
        'artikelnummer',
    ],
    [ImportTypes.UPDATE_MINIMUMS]: [
        'artikelnummer',
    ],
    [ImportTypes.PHASE_OUT]: [
        'artikelnummer',
        'uitfaseren',
    ],
}

const ValidPhaseOutValues = [
    'negeren',
    'crossdock',
    'end-of-life',
    'no',
]

class ImportWarehouseStocksDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            import_type: ImportTypes.IMPORT_NEW,
            supplier: {
                name: 'Select supplier..',
                mage_id: 0,
            },
            file_content: '',
            file_name: '',
        }

        autoBind(this)
    }

    componentDidMount() {
        this.props.onSearchSuppliers({})
    }

    handleSubmit() {
        switch (this.state.import_type) { // eslint-disable-line default-case
            case ImportTypes.IMPORT_NEW: this.props.onProductImportNewSubmit(this.state.file_content); break
            case ImportTypes.CONVERT_FROM_CROSSDOCK:
                this.props.onProductImportConvertFromCrossdockSubmit(this.state.file_content); break
            case ImportTypes.UPDATE_EXISTING: this.props.onProductImportExistingSubmit(this.state.file_content); break
            case ImportTypes.UPDATE_MINIMUMS: this.props.onProductImportExistingSubmit(this.state.file_content); break
            case ImportTypes.PHASE_OUT: this.props.onProductImportPhaseOutSubmit(this.state.file_content); break
        }

        return this.props.closeOnSubmit && this.props.onClose()
    }

    handleImportChange(e) {
        this.setState({
            import_type: e.target.value,
        })
    }

    areRequiredColumnsPresent(columns) {
        return RequiredColumns[this.state.import_type].every(c => {
            return columns.includes(c)
        })
    }

    validatePhaseOutValues(data) {
        const firstRow = data[0].split(',')
        const indexOfPhaseOutColumn = firstRow.map(v => v.toLowerCase()).indexOf('uitfaseren')
        const invalidValues = []
        data.slice(1).forEach(row => {
            const rowData = row.split(',')
            const phaseOutValue = rowData[indexOfPhaseOutColumn].toLowerCase()
            if (ValidPhaseOutValues.indexOf(phaseOutValue) === -1) {
                invalidValues.push(phaseOutValue)
            }
        })

        if (invalidValues.length) {
            this.setState({
                file_content: '',
                error: (
                    <div>
                        <p>Your file contains invalid values for "Uitfaseren":</p>
                        <ul style={{ paddingLeft: 20 }}>
                            {[...new Set(invalidValues)].map(value => (
                                <li key={value}>{value}</li>
                            ))}
                        </ul>
                    </div>
                ),
            })
        }
        return !invalidValues.length
    }

    setMissingColumnsError() {
        this.setState({
            file_content: '',
            error: `One of the following columns is not present in the CSV (case insensitive):
                    ${RequiredColumns[this.state.import_type].join(', ')}

                    Download the template to match the criteria`,
        })
    }

    getImportNewReaderOnLoad(input) {
        return e => {
            const supplier = this.state.supplier
            const file_content = e.target.result
                .replace(/\r/g, '')

            this.props.onFileReaderResult(file_content)
            const splitted = file_content.split('\n')
                .filter(row => row.trim() !== '')
                .map((row, index) => {
                    if (index !== 0) {
                        return `${row},${supplier.mage_id},${supplier.name}`
                    }
                    return `${row},supplier_id,supplier_name`
                })

            const first_row = splitted[0]
            const columnsValid = this.areRequiredColumnsPresent(
                first_row.trim().toLowerCase().replace('-', '_').split(',')
            )

            if (columnsValid) {
                if (!(this.state.import_type === ImportTypes.PHASE_OUT && !this.validatePhaseOutValues(splitted))) {
                    splitted[0] = first_row.trim().toLowerCase().replace('-', '_')
                    this.setState({
                        file_content: splitted.join('\n'),
                        error: '',
                    })
                }
            } else {
                this.setMissingColumnsError()
            }

            document.body.removeChild(input)
        }
    }

    getUpdateExistingReaderOnLoad(input) {
        return e => {
            const file_content = e.target.result
                .replace(/\r/g, '')
                .replace(/"/g, '')
            this.props.onFileReaderResult(file_content)
            const splitted = file_content.split('\n')
                .filter(row => row.trim() !== '')
            const first_row = splitted[0]
            const columnsValid = this.areRequiredColumnsPresent(
                first_row.trim().toLowerCase().replaceAll('-', '_').split(',')
            )
            if (columnsValid) {
                if (!(this.state.import_type === ImportTypes.PHASE_OUT && !this.validatePhaseOutValues(splitted))) {
                    splitted[0] = first_row.trim().toLowerCase().replaceAll('-', '_')
                    this.setState({
                        file_content: splitted.join('\n'),
                        error: '',
                    })
                }
            } else {
                this.setMissingColumnsError()
            }
            document.body.removeChild(input)
        }
    }

    handleSupplierChange(e) {
        this.setState({
            supplier: this.props.suppliers.find(({ mage_id }) => mage_id === +e.target.value),
        })
    }

    handleUploadCsvClick() {
        const input = document.createElement('input')
        const reader = new FileReader()
        reader.onload = this.state.import_type === ImportTypes.IMPORT_NEW
            ? this.getImportNewReaderOnLoad(input)
            : this.getUpdateExistingReaderOnLoad(input)
        input.setAttribute('type', 'file')
        input.style.display = 'none'
        input.onchange = () => {
            const file = input.files[0]
            if (file && !file.name.endsWith('.csv')) {
                this.setState({
                    error: 'Only csv is supported as file format',
                }, () => {
                    document.body.removeChild(input)
                })
            } else {
                this.setState({
                    error: null,
                    file_name: file.name,
                }, () => {
                    reader.readAsText(file)
                })
            }
        }
        document.body.appendChild(input)
        input.click()
    }

    createDownloadFileAnchor(file_name, file_content) {
        const anchor = document.createElement('a')
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('download', `${file_name}.csv`)
        anchor.setAttribute('href', `data:text/csv;charset=utf-8,${file_content}`)
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }

    handleDownloadCsvLocationTemplateClick() {
        let example

        if (this.state.import_type === ImportTypes.UPDATE_EXISTING) {
            example = add_locations_example
            this.createDownloadFileAnchor('expand-locations', example)
        }
    }

    handleDownloadCsvTemplateClick() {
        let example
        if (this.state.import_type === ImportTypes.IMPORT_NEW) {
            example = new_example
        } else if (this.state.import_type === ImportTypes.CONVERT_FROM_CROSSDOCK) {
            example = convert_from_crossdock_example
        } else if (this.state.import_type === ImportTypes.UPDATE_EXISTING) {
            example = update_example
        } else if (this.state.import_type === ImportTypes.UPDATE_MINIMUMS) {
            example = update_minimums_example
        } else {
            example = phase_out_example
        }

        this.createDownloadFileAnchor(this.state.import_type, example)
    }

    getImportDialogContents() {
        const contents = this.state.supplier.mage_id !== 0 && [
            <Button
                key="download"
                style={style.fileButton}
                variant="contained"
                onClick={this.handleDownloadCsvTemplateClick}
            >
                Download CSV template
            </Button>,
            <Button
                key="upload"
                variant="contained"
                style={style.fileButton}
                color="primary"
                onClick={this.handleUploadCsvClick}
            >
                Upload CSV
            </Button>,
            <span key="file_name">{this.state.file_name}</span>,
            <p style={style.commaSeparatedHint} key="comma_separated_hint">
                <b>Hint:</b> The CSV should be comma separated
            </p>,
            <div key="instructions">
                <br />
                <ul style={{ paddingLeft: 20 }}>
                    <li>
                        Name, brand, article number, quantity, lead time, source and channels are required fields.
                        Also one of color and size is required
                    </li>
                    <li>
                        Product names should not contain color and size specifics.
                        Products should have their catalog name.
                        So "Blåkläder 1091 schildersbroek-52" should be "Blåkläder 1091 schildersbroek"
                    </li>
                    <li>Products that already exist will not be imported</li>
                    <li>Possible channels: <br />
                        * proforto-web-nl (Available via proforto.nl)<br />
                        * proforto-web-be (Available via proforto.be)<br />
                        * proforto-bol (Available via bol.com)<br />
                        * xerox (Available via xerox.proforto.nl)
                    </li>
                </ul>
                <span style={{ color: Colors.red100, fontWeight: 'bold' }}>
                    {this.state.error}
                </span>
            </div>,
        ]
        return (
            <React.Fragment>
                <SupplierFilter
                    style={style.supplierFilter}
                    onChange={this.handleSupplierChange}
                    value={this.state.supplier.mage_id}
                    allowAll={false}
                />
                <br />
                {contents}
            </React.Fragment>
        )
    }

    getConvertFromCrossdockDialogContents() {
        return (
            <React.Fragment>
                <Button
                    key="download"
                    style={style.fileButton}
                    variant="contained"
                    onClick={this.handleDownloadCsvTemplateClick}
                >
                    Download CSV template
                </Button>
                <Button
                    key="upload"
                    variant="contained"
                    style={style.fileButton}
                    color="primary"
                    onClick={this.handleUploadCsvClick}
                >
                    Upload CSV
                </Button>
                <span key="file_name">{this.state.file_name}</span>
                <p style={style.commaSeparatedHint} key="comma_separated_hint">
                    <b>Hint:</b> The CSV should be comma separated
                </p>
                <div key="instructions">
                    Required fields:
                    <ul style={{ paddingLeft: 20 }}>
                        <li>Artikelnummer</li>
                    </ul>
                    <span style={{ color: Colors.red100, fontWeight: 'bold' }}>
                        {this.state.error}
                    </span>
                </div>
            </React.Fragment>
        )
    }

    getPhaseOutDialogContents() {
        return (
            <React.Fragment>
                <Button
                    key="download"
                    style={style.fileButton}
                    variant="contained"
                    onClick={this.handleDownloadCsvTemplateClick}
                >
                    Download CSV template
                </Button>
                <Button
                    key="upload"
                    variant="contained"
                    style={style.fileButton}
                    color="primary"
                    onClick={this.handleUploadCsvClick}
                >
                    Upload CSV
                </Button>
                <span key="file_name">{this.state.file_name}</span>
                <p style={style.commaSeparatedHint}><b>Hint:</b> The CSV should be comma separated</p>
                <div key="instructions">
                    <br />
                    Required fields:
                    <ul style={{ paddingLeft: 20 }}>
                        <li>Artikelnummer</li>
                        <li>Uitfaseren</li>
                    </ul>
                    <br />
                    "Uitfaseren" can have the following values:
                    <ul style={{ paddingLeft: 20 }}>
                        <li><i>"negeren"</i>: Product state will not be changed</li>
                        <li>
                            <i>"crossdock"</i>: Warehouse stock will not be repleted
                            and product will be marked as crossdock when the warehouse stock has run out
                        </li>
                        <li>
                            <i>"end-of-life"</i>: Warehouse stock will not be repleted
                            and product will be disabled when the warehouse stock has run out
                        </li>
                        <li>
                            <i>"no"</i>: Will undo previous phase out actions
                        </li>
                    </ul>
                    <br />
                    <p><b>Note:</b> This process may not be reversible!</p>
                    <span style={{ color: Colors.red100, fontWeight: 'bold' }}>
                        {this.state.error}
                    </span>
                </div>
            </React.Fragment>
        )
    }


    getUpdateStocksDialogContents() {
        return (
            <React.Fragment>
                <Button
                    key="download"
                    style={{ marginRight: 20 }}
                    variant="contained"
                    onClick={this.handleDownloadCsvTemplateClick}
                >
                    Download CSV template
                </Button>
                { this.state.import_type === ImportTypes.UPDATE_EXISTING ? (
                    <Button
                        key="download-csvlocation-template"
                        style={style.fileButton}
                        variant="contained"
                        onClick={this.handleDownloadCsvLocationTemplateClick}
                    >
                        Download CSV new locations template
                    </Button>
                ) : null}
                <Button
                    key="upload"
                    variant="contained"
                    style={{ marginRight: 20, marginTop: 15 }}
                    color="primary"
                    onClick={this.handleUploadCsvClick}
                >
                    Upload CSV
                </Button>
                <span key="file_name">{this.state.file_name}</span>
                <p style={style.commaSeparatedHint}><b>Hint:</b> The CSV should be comma separated</p>
                <div key="instructions">
                    <br />
                    <ul style={{ paddingLeft: 20 }}>
                        <li>
                            Article number is a required field
                        </li>
                        <li>Only stocks will be updated. If one does not exist, nothing will happen.</li>
                        <li>
                            Only include fields mandated in the example. Otherwise, other attributes
                            (such as consignment or minimums) will be updated as well.
                        </li>
                    </ul>
                    <span style={{ color: Colors.red100, fontWeight: 'bold' }}>
                        {this.state.error}
                    </span>
                </div>
            </React.Fragment>
        )
    }

    cannotSubmit() {
        if (this.state.import_type === ImportTypes.IMPORT_NEW) {
            return this.state.file_content === '' || this.state.supplier.mage_id === 0
        }
        return this.state.file_content === ''
    }

    render() {
        const cannotSubmit = this.cannotSubmit()

        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={true}
            >
                <DialogTitle>Import</DialogTitle>
                <DialogContent>
                    <Select
                        label="Select import type"
                        onChange={this.handleImportChange}
                        value={this.state.import_type}
                        margin="normal"
                        fullWidth
                    >
                        <MenuItem value={ImportTypes.IMPORT_NEW}>Import new</MenuItem>
                        <MenuItem value={ImportTypes.CONVERT_FROM_CROSSDOCK}>Convert from crossdock</MenuItem>
                        <MenuItem value={ImportTypes.UPDATE_EXISTING}>
                            Update static values / Add new locations
                        </MenuItem>
                        <MenuItem value={ImportTypes.UPDATE_MINIMUMS}>Update stock minimums</MenuItem>
                        <MenuItem value={ImportTypes.PHASE_OUT}>Phase out products</MenuItem>
                    </Select>
                    <br />
                    <br />
                    {this.state.import_type === ImportTypes.IMPORT_NEW && this.getImportDialogContents()}
                    {this.state.import_type === ImportTypes.CONVERT_FROM_CROSSDOCK &&
                        this.getConvertFromCrossdockDialogContents()}
                    {this.state.import_type === ImportTypes.UPDATE_EXISTING && this.getUpdateStocksDialogContents()}
                    {this.state.import_type === ImportTypes.UPDATE_MINIMUMS && this.getUpdateStocksDialogContents()}
                    {this.state.import_type === ImportTypes.PHASE_OUT && this.getPhaseOutDialogContents()}
                </DialogContent>
                <DialogActions>
                    <Button
                        key="import_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit}
                        color="secondary"
                    >
                        Submit
                    </Button>
                    <Button
                        key="import_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ImportWarehouseStocksDialog.propTypes = {
    suppliers: PropTypes.array.isRequired,
    closeOnSubmit: PropTypes.bool,
    onSearchSuppliers: PropTypes.func.isRequired,
    onFileReaderResult: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onProductImportNewSubmit: PropTypes.func.isRequired,
    onProductImportConvertFromCrossdockSubmit: PropTypes.func.isRequired,
    onProductImportExistingSubmit: PropTypes.func.isRequired,
    onProductImportPhaseOutSubmit: PropTypes.func.isRequired,
    updateProductVariant: PropTypes.func.isRequired,
}

ImportWarehouseStocksDialog.defaultProps = {
    closeOnSubmit: true,
    suppliers: [],
}

export default ImportWarehouseStocksDialog
