import store from '../../store'

export default function initDebitMemosSockets(socket) {
    socket.on('debits/search-result', data => {
        store.dispatch({type: 'REFRESH_DEBIT_MEMOS', debit_memos: data})
        // store.dispatch({type: 'REFRESH_PAGINATION_DEBIT_MEMOS', pagination: meta});
    })

    socket.on('debit', debit_memo => {
        store.dispatch({type: 'REFRESH_DEBIT_MEMO', debit_memo})
    })
}
