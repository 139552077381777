import React, { Component, Fragment } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Processes } from '../../../../types/logistics/picking'

const initialState = {
    process: '',
    supplier_id: '',
    fulfillment_source: 'warehouse',
    limit: 1,
    estimations_from: null,
    estimations_to: null,
}

const styles = {
    dialogPaper: {
        minWidth: 640,
    },
    dialogActions: {
        margin: '0 20px 20px 20px',
    },
    outlinedInputLabel: {
        width: 'auto',
    },
    dateFields: {
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'row',
    },
    dateFieldFrom: {
        width: '100%',
        marginRight: 10,
    },
    dateFieldTo: {
        width: '100%',
        marginLeft: 10,
    },
}


class GenerateSinglePiecePickListsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }

        autoBind(this)
    }

    handleSupplierChange({ target }) {
        this.setState({ supplier_id: target.value })
    }

    handleFulfillmentSourceChange({ target }) {
        this.setState({ fulfillment_source: target.value })
    }

    handleLimitChange({ target }) {
        const limit = +target.value > 0 ? +target.value : 1
        this.setState({ limit })
    }

    handleEstimationsFromChange({ target }) {
        this.setState({ estimations_from: target.value })
    }

    handleEstimationsToChange({ target }) {
        this.setState({ estimations_to: target.value })
    }

    handleSubmit() {
        const state = this.state
        this.setState({ ...initialState }, () => {
            this.props.onSubmit(state)
            if (this.props.closeOnSubmit) {
                this.handleClose()
            }
        })
    }

    handleProcessChange(e) {
        this.setState({ process: e.target.value })
    }

    handleClose() {
        this.props.onClose()
    }

    renderDeliveryOrderContent() {
        return (
            <Fragment>
                <DialogContentText>Select fulfillment source*</DialogContentText>
                <FormControl variant="outlined">
                    <Select
                        required={true}
                        value={this.state.fulfillment_source}
                        onChange={this.handleFulfillmentSourceChange}
                        input={
                            <OutlinedInput
                                labelWidth={styles.outlinedInputLabel.width}
                                name="Fulfillment source"
                                id="fulfillment_source"
                            />
                        }
                    >
                        <MenuItem value="warehouse">
                            Warehouse
                        </MenuItem>
                    </Select>
                </FormControl>
                <DialogContentText>
                    Select amount of items, this is equal to the amount of shipments*
                </DialogContentText>
                <FormControl variant="outlined">
                    <TextField
                        required={true}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.limit}
                        onChange={this.handleLimitChange}
                        variant="outlined"
                    />
                </FormControl>
                <DialogContentText>
                    Select from / to estimation date
                </DialogContentText>
                <FormControl variant="outlined" style={styles.dateFields}>
                    <TextField
                        style={styles.dateFieldFrom}
                        required={false}
                        label="From"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.estimations_from}
                        onChange={this.handleEstimationsFromChange}
                        variant="outlined"
                    />
                    <TextField
                        style={styles.dateFieldTo}
                        required={false}
                        label="To"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.estimations_to}
                        onChange={this.handleEstimationsToChange}
                        variant="outlined"
                    />
                </FormControl>
            </Fragment>
        )
    }

    render() {
        return (
            <Dialog
                PaperProps={{ style: styles.dialogPaper }}
                onClose={this.handleClose}
                aria-labelledby="generate-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="generate-dialog-title">Generate Single Piece Pick Lists</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select process*</DialogContentText>
                    <Select
                        required={true}
                        value={this.state.process}
                        fullWidth={true}
                        onChange={this.handleProcessChange}
                        input={
                            <OutlinedInput
                                labelWidth={styles.outlinedInputLabel.width}
                                name="Process"
                                id="process"
                            />
                        }
                    >
                        <MenuItem value={Processes.DELIVERY_ORDER}>
                            Delivery to customer
                        </MenuItem>
                    </Select>
                    {this.state.process === Processes.DELIVERY_ORDER && this.renderDeliveryOrderContent()}
                </DialogContent>
                <DialogActions style={styles.dialogActions}>
                    <Button onClick={this.handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        Generate
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

GenerateSinglePiecePickListsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    closeOnSubmit: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
GenerateSinglePiecePickListsDialog.defaultProps = {
    open: false,
    closeOnSubmit: true,
}

export default GenerateSinglePiecePickListsDialog
