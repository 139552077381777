import store from '../../store'

export default function initInboundReceiptsSockets(socket) {
    socket.on('inbound-receipts/order', data => {
        store.dispatch({ type: 'REFRESH_INBOUND_RECEIPTS', data })
    })

    socket.on('inbound-receipts/purchase-order', data => {
        store.dispatch({ type: 'REFRESH_INBOUND_RECEIPTS', data })
    })
}
