import socket from '../../socket'
import {getUserEmail} from '../../store'
export function searchSuppliers(filters) {
    socket.emit('suppliers/search', filters, getUserEmail())
    return {type: 'SEARCH_SUPPLIERS', filters}
}

export function getSupplier(mage_id) {
    socket.emit('supplier/get', {mage_id}, getUserEmail())
    return {type: 'GET_SUPPLIER', mage_id}
}

export function updateSupplier(_id, data) {
    socket.emit('supplier/update', {_id, ...data}, getUserEmail())
    return {type: 'UPDATE_SUPPLIER', _id}
}
