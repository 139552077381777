import socket from './../socket'
import listeners from '../listeners/sockets/logs.js'

listeners(socket)

export function logs(state = [], action) {
    switch (action.type) {
        case 'REFRESH_SALES_ORDER_LOGS':
            return action.logs

        default:
            return state
    }
}
