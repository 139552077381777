import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField, SelectField, MenuItem} from 'material-ui'

const style = {
    filtersContainer: {
        height: 70,
        width: '80%',
        display: 'inline-block',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        marginLeft: 50,
    },
    searchField: {
        height: 72,
        marginRight: 20,
        width: '50%',
    },
    searchFieldInput: {
        height: 30,
        lineHeight: '14px',
        bottom: 12,
        position: 'absolute',
    },
    searchFieldHintText: {
        lineHeight: '20px',
        fontSize: 14,
        fontWeight: 'bold',
    },
}

class PurchaseRequisitionFilters extends Component {

    handleCriteriaChange(e) {
        this.props.onCriteriaChange(e.target.value)
    }

    handleSupplierChange(e, index, value) {
        this.props.onSupplierChange(value)
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={style.searchField}
                    inputStyle={style.searchFieldInput}
                    hintStyle={style.searchFieldHintText}
                    floatingLabelText="Search for purchase requisition"
                    value={this.props.filters.criteria}
                    onChange={this.handleCriteriaChange.bind(this)}
                />
                <SelectField
                    style={{width: 400, marginRight: 15, overflow: 'hidden'}}
                    onChange={this.handleSupplierChange.bind(this)}
                    value={this.props.filters.supplier_id}
                    floatingLabelText="Select supplier"
                >
                    <MenuItem
                        value="all"
                        primaryText="All"
                    />
                    {
                        this.props.suppliers.map(({name, mage_id}) => {
                            return (
                                <MenuItem
                                    key={mage_id}
                                    value={`${mage_id}`}
                                    primaryText={name}
                                />
                            )
                        })
                    }
                </SelectField>
            </div>
        )
    }
}

PurchaseRequisitionFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    suppliers: PropTypes.array.isRequired,
    onCriteriaChange: PropTypes.func.isRequired,
    onSupplierChange: PropTypes.func.isRequired,
}

PurchaseRequisitionFilters.defaultProps = {
    suppliers: [],
}


export default PurchaseRequisitionFilters
