import store from '../../store'

import { getDeliveryOrderLocations } from '../../commands/sockets/delivery_orders'
import {
    refreshDeliveryOrder,
    refreshDeliveryOrderLocations,
    refreshDeliveryOrders,
} from '../../actions/delivery_orders'

export default function initDeliveryOrdersSockets(socket) {
    socket.on('delivery-order', data => {
        store.dispatch(refreshDeliveryOrder(data))
        if (data && data.refs && data.refs.location_refs && data.refs.location_refs.length > 0) {
            getDeliveryOrderLocations(data.refs.location_refs)
        }
    })

    socket.on('delivery-order/locations', data => {
        store.dispatch(refreshDeliveryOrderLocations(data))
    })

    socket.on('delivery-orders', data => {
        store.dispatch(refreshDeliveryOrders(data))
    })
}
