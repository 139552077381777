import store from '../../store'

export default function initTypedOrdersSockets(socket) {
    socket.on('fulfillments/order', typed_orders => {
        store.dispatch({type: 'REFRESH_TYPED_ORDERS', typed_orders})
    })

    socket.on('orders/typed/supplier/search-result', typed_orders => {
        store.dispatch({type: 'REFRESH_TYPED_ORDERS', typed_orders})
    })

    socket.on('order/typed', typed_order => {
        store.dispatch({type: 'REFRESH_TYPED_ORDER', typed_order})
    })
}
