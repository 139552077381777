import React from 'react'
import * as onscan from 'onscan.js'

const VALID_KEY_CODES = '\r0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    .split('').map(c => c.charCodeAt(0))

export function keyCodeMapper(event: KeyboardEvent) {
    const keyCode = event.which || event.keyCode || event.charCode
    if (!VALID_KEY_CODES.includes(keyCode)) return ''

    return String.fromCharCode(keyCode)
}

/**
 * Barcodescanner configurations for different scanners
 * @link https://a.kabachnik.info/onscan-js.html#quickstart
 */
export const barcodeScannerConfigurations: { [key: string]: Partial<onscan.Options> } = {
    'opticon': {
        avgTimeByChar: 40,
        suffixKeyCodes: [ 13 ], // Enter
        timeBeforeScanTest: 200,
        minLength: 3,
        keyCodeMapper,
    },
}

export type ScanRef = any // eslint-disable-line @typescript-eslint/no-explicit-any
export type ScanHandler = (scan: string) => void

interface ContextMapValue {
    /**
     * Callback function handling the barcode scans
     */
    onBarcodeScan: ScanHandler

    /**
     * Is the component context aware?
     * If yes, the component only gets the scan if it's the last context aware one listening
     * If no, the component always gets the scan
     */
    isContextAware: boolean

    /**
     * Whether scanning is currently enabled
     */
    isEnabled: boolean
}

export class BarcodeScanner {
    private onScanOptions: Partial<onscan.Options>
    private contexts: Map<ScanRef, ContextMapValue>
    private domElement: onscan.DomElement

    constructor(onScanOptions: Partial<onscan.Options>) {
        this.onScanOptions = onScanOptions
        this.contexts = new Map<ScanRef, ContextMapValue>()
        this.domElement = document
    }

    /**
     * Generate an unique identifier, usable as ScanRef
     */
    public static getRef(): ScanRef {
        return btoa(Math.random().toString())
    }

    /**
     * Simulate a barcode scan
     * Debugging tool. Use this function from the browser console
     * (available as window._keyboardScan and window._scan) to simulate rapid
     * keypresses as made by a (usb) barcode scanner.
     */
    public static simulate(message: string, options?: { delay?: number }) {
        const delay = options?.delay ?? 15

        const timer = setInterval(() => {
            let keyCode
            if (message.length) {
                keyCode = message.charCodeAt(0)
                message = message.substr(1)
            } else {
                keyCode = 13
                clearInterval(timer)
            }

            /**
             * @link https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent#events
             */
            document.dispatchEvent(new KeyboardEvent('keydown', { keyCode }))
            document.dispatchEvent(new KeyboardEvent('keypress', { keyCode }))
            document.dispatchEvent(new KeyboardEvent('keyup', { keyCode }))
        }, delay)
    }

    /**
     * Add a component to listen to a barcodescanner scan
     * @param ref Unique ref for the component
     * @param onBarcodeScan Function to call on barcodescanner scan
     */
    public addListener(ref: ScanRef, onBarcodeScan: ScanHandler, isContextAware: boolean = true) {
        if (!this.contexts.has(ref)) {
            this.contexts.set(ref, { onBarcodeScan, isContextAware, isEnabled: true })
            this.listen()
        }
    }

    /**
     * Stop listening for a component
     * @param ref Unique id for the component
     */
    public removeListener(ref: ScanRef) {
        if (this.contexts.has(ref)) {
            this.contexts.delete(ref)
            this.listen()
        }
    }

    public enable(ref: ScanRef) {
        const context = this.contexts.get(ref)
        if (context) {
            context.isEnabled = true
        }
    }

    public disable(ref: ScanRef) {
        const context = this.contexts.get(ref)
        if (context) {
            context.isEnabled = false
        }
    }

    /**
     * Returns true if this class is listening for keydown events
     */
    public isListening() {
        return onscan.isAttachedTo(this.domElement)
    }

    /**
     * @private
     * Attach / detach event listeners
     * @returns {void}
     */
    private listen() {
        if (this.contexts.size && !this.isListening()) {
            onscan.attachTo(this.domElement, { ...this.onScanOptions, onScan: this.onScan })
        } else if (!this.contexts.size) {
            onscan.detachFrom(this.domElement)
        }
    }

    /**
     * @private
     * Handle a barcodescanner scan
     * @param scan Scanned value
     * @param quantity
     */
    private onScan = (scan: string, quantity: number) => {
        if (!this.contexts.size) {
            return this.listen()
        }

        const values = Array.from(this.contexts.values())

        const contextAware = values.filter(c => c.isContextAware)
        const lastContext = contextAware[contextAware.length - 1]

        if (lastContext.isEnabled) {
            lastContext.onBarcodeScan(scan)
        }

        const alwaysRunFns = values.filter(c => !c.isContextAware && c.isEnabled).map(c => c.onBarcodeScan)
        alwaysRunFns.forEach(fn => fn(scan))

        if (this.onScanOptions.onScan) {
            this.onScanOptions.onScan(scan, quantity)
        }
    }
}

/**
 * BarcodeScanner instance
 *
 * @usage in class component
 *  constructor(props) {
 *      super(props)
 *
 *      this.state = {
 *          barcodeScannerId: BarcodeScanner.getRef()
 *      }
 *  }
 *
 *  componentDidMount() {
 *      barcodeScanner.addListener(this.state.barcodeScannerId, this.handleBarcodeScan)
 *  }
 *
 *  componentWillUnmount() {
 *      barcodeScanner.removeListener(this.state.barcodeScannerId)
 *  }
 *
 *  handleBarcodeScan(value) {
 *    console.log('do something with the scanned value', value)
 *  }
 */
export const barcodeScanner = new BarcodeScanner(barcodeScannerConfigurations.opticon)

/**
 * Barcode listing hook
 * @example
 * function MyComponent {
 *    const handleScan = (code: string) {
 *        ...
 *    }
 *
 *    const lastScan = useBarcode(handleCode)
 * }
 */
export function useBarcode(onBarcodeScan: ScanHandler, isContextAware: boolean = true) {
    const ref = React.useRef(BarcodeScanner.getRef())
    const [ lastScan, setLastScan ] = React.useState('')

    React.useEffect(() => {
        barcodeScanner.addListener(ref, onBarcodeScan, isContextAware)
        barcodeScanner.addListener(ref + 'always', setLastScan, false)

        return () => {
            barcodeScanner.removeListener(ref)
            barcodeScanner.removeListener(ref)
        }
    }, [ onBarcodeScan, isContextAware ])

    return lastScan
}
