import socket from './../socket'
import listeners from '../listeners/sockets/purchase_requisitions.js'

listeners(socket)

export function purchase_requisitions(state = [], action) {
    switch (action.type) {
        case 'REFRESH_PURCHASE_REQUISITIONS':
            return action.purchase_requisitions

        default:
            return state
    }
}
