import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardHeader, Divider, ListItem} from 'material-ui'

import PurchaseReceipt from './inbound/PurchaseReceipt'
import PurchaseCandidate from './inbound/PurchaseCandidate'

const styles = {
    container: {
        width: '100%',
    },
    primaryTextListItemStyle: {
        fontSize: 15,
        fontFamily: 'Roboto',
    },
    titleStyle: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Roboto',
        fontWeight: 'bolder',
        textTransform: 'capitalize',
    },
}

function Inbound(props) {
    const noInbound = props.inbound_candidates.length !== 0 || props.inbound_receipts.length !== 0 ? null : (
        <Card>
            <ListItem
                primaryText={
                    <i>
                        Nothing incoming for inbound at the moment (may change after purchase order submissions).
                    </i>
                }
            />
        </Card>
    )
    const purchase_candidates = props.inbound_candidates.reduce((acc, candidate) => {
        const purchase = acc.find(i => i.purchase_id === candidate.purchase_id)
        if (!purchase) {
            acc.push({
                purchase_id: candidate.purchase_id,
                supplier: candidate.supplier,
                line_items: [{...candidate, quantity: 1}],
            })
        } else {
            const item = purchase.line_items.find(i => i.sku === candidate.sku)
            if (item) {
                item.quantity++
            } else {
                purchase.line_items.push({...candidate, quantity: 1})
            }
        }
        return acc
    }, [])
    const purchase_receipts = props.inbound_receipts.reduce((acc, receipt) => {
        receipt.line_items.forEach(receipt_item => {
            if (receipt_item.type !== 'purchase') {
                return
            }
            const purchase = acc.find(i => i.purchase_id === receipt_item.purchase_id)
            if (!purchase) {
                acc.push({
                    purchase_id: receipt_item.purchase_id,
                    supplier: receipt_item.supplier,
                    line_items: [{
                        ...receipt_item,
                        quantity: 1,
                        receipt_increment_id: receipt.increment_id,
                        receipt_created_at: receipt.created_at,
                    }],
                })
            } else {
                const item = purchase.line_items.find(i => i.sku === receipt_item.sku
                    && i.receipt_increment_id === receipt.increment_id)
                if (item) {
                    item.quantity++
                } else {
                    purchase.line_items.push({
                        ...receipt_item,
                        quantity: 1,
                        receipt_increment_id: receipt.increment_id,
                        receipt_created_at: receipt.created_at,
                    })
                }
            }
        })
        return acc
    }, [])
    return (
        <div style={styles.container}>
            {
                purchase_candidates.length === 0 ? null : (
                    <Card style={{marginBottom: 10}}>
                        <CardHeader title="Not yet received" titleStyle={styles.titleStyle}/>
                        {purchase_candidates.map(purchase_candidate => [
                            <Divider key={`divider.not_yet_received.${purchase_candidate.purchase_id}`}/>,
                            <PurchaseCandidate
                                key={`not_yet_received.${purchase_candidate.purchase_id}`}
                                purchase_candidate={purchase_candidate}
                            />,
                        ])}
                    </Card>
                )
            }
            {
                purchase_receipts.length === 0 ? null : (
                    <Card>
                        <CardHeader title="Received" titleStyle={styles.titleStyle}/>
                        {
                            purchase_receipts.map(purchase_receipt => [
                                <Divider key={`divider.receipt.purchase.${purchase_receipt.purchase_id}`}/>,
                                <PurchaseReceipt
                                    key={`receipt.purchase.${purchase_receipt.purchase_id}`}
                                    purchase_receipt={purchase_receipt}
                                />,
                            ])
                        }
                    </Card>
                )
            }
            {noInbound}
        </div>
    )
}

Inbound.propTypes = {
    inbound_receipts: PropTypes.array,
    inbound_candidates: PropTypes.array,
    order_number: PropTypes.string,
}

Inbound.contextTypes = {
    router: PropTypes.object,
}

export default Inbound
