import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import reactUpdate from 'react-addons-update'
import { AutoComplete } from 'material-ui'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'

import DialogLineItems from './../DialogLineItems'
import SwitchFilter from './../../shared/SwitchFilter'

import { ReturnReasons } from '../../../utils/returns'

const style = {
    datePicker: {
        cursor: 'pointer',
    },
    labelAmount: {
        minWidth: 300,
    },
    stepper: {
        padding: 0,
    },
    stepActions: {
        marginTop: 10,
    },
}

const config = {
    text: 'text',
    value: 'mage_id',
}

const initialState = {
    stepIndex: 0,
    finished: false,
    value: '',
    labelAmount: 1,
    isOrderFilter: true,
    shippingLabels: false,
    disableRefund: false,
    selectedRows: [],
    order: {
        line_items: [],
    },
}

class AddRmaDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }

        this.handleLabelAmountChange = this.handleLabelAmountChange.bind(this)
        this.handleShippingLabelsChange = this.handleShippingLabelsChange.bind(this)
        this.handleDisableRefundChange = this.handleDisableRefundChange.bind(this)
        this.handleSwitchChange = this.handleSwitchChange.bind(this)
        this.handlePrev = this.handlePrev.bind(this)
    }

    handleNext() {
        const { stepIndex } = this.state
        this.setState({
            stepIndex: stepIndex + 1,
            finished: stepIndex + 1 === 2,
        })
    }

    handlePrev() {
        const { stepIndex } = this.state
        if (stepIndex > 1) {
            this.setState({ stepIndex: stepIndex - 1, finished: false })
        }
    }

    handleSubmit() {
        const state = this.state
        this.setState(initialState, () => {
            const order = {
                order_id: state.order.mage_id,
                line_items: _.filter(_.map(state.order.line_items, l => {
                    return {
                        supplier_sku: l.supplier_sku,
                        ean: l.ean,
                        name: l.name,
                        supplier: l.supplier,
                        quantity: l.returned_quantity,
                        return_reason: l.return_reason,
                    }
                }), l => l.quantity > 0),
                customer: state.order.customer,
                requested_on: new Date().toISOString(),
                payment_info: state.order.payment_info,
                sender_address: state.order.billing_address,
                disable_refund: !!state.disableRefund,
                shipping_labels: !!state.shippingLabels,
                label_amount: state.labelAmount,
            }

            this.props.onSubmit(order)
        })
    }

    handleQuantityChange(index, quantity) {
        const update = {
            order: {
                line_items: {},
            },
        }
        update.order.line_items[index] = { returned_quantity: { $set: quantity } }
        if (+quantity > 0 && this.state.selectedRows.indexOf(index) === -1) {
            update.selectedRows = { $push: [index] }
        } else if (+quantity === 0 && this.state.selectedRows.indexOf(index) !== -1) {
            update.selectedRows = {
                $apply: arr => {
                    return _.filter(arr, a => a !== index)
                },
            }
        }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleReasonChange(index, reason) {
        const update = {
            order: {
                line_items: {},
            },
        }
        update.order.line_items[index] = { return_reason: { $set: reason } }
        const newState = reactUpdate(this.state, update)
        this.setState(newState)
    }

    handleRowSelection(selectedRows, items) {
        const return_items = items.filter((li, index) => {
            return selectedRows.indexOf(index) !== -1 && li.returned_quantity === 0
        })
        const not_return_items = items.filter((li, index) => {
            return selectedRows.indexOf(index) === -1 && li.returned_quantity > 0
        })
        const update = {
            order: {
                line_items: {},
            },
            selectedRows: { $set: selectedRows },
        }
        _.each(return_items, li => {
            update.order.line_items[li.index] = {
                returned_quantity: { $set: li.quantity },
            }
        })
        _.each(not_return_items, li => {
            update.order.line_items[li.index] = {
                returned_quantity: { $set: 0 },
            }
        })

        const newState = reactUpdate(this.state, update)
        this.setState(newState)
        return selectedRows
    }

    handleInputChange(value) {
        this.setState({
            value,
            order: { line_items: [] },
        })
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.props.onSearchSalesOrders({
                order: this.state.isOrderFilter ? value : null,
                value: !this.state.isOrderFilter ? value : null,
                fields: 'mage_id,customer,line_items,billing_address,notes',
                page: 1,
                limit: 5,
            })
        }, 300)
    }

    handleNewRequest(chosen, index) {
        const order = this.props.sales_orders[index]
        order.line_items = order.line_items.filter(i => {
            const sku = i.product.sku.toLowerCase().trim()
            return sku !== 'bedrukken123'
                && sku !== 'bedrukken-1234'
                && sku !== 'borduren123'
                && sku !== 'borduren1'
                && sku !== 'borduurkaart'
                && sku !== '1234-1 maat'
        }).map(li => {
            return {
                name: li.product.name,
                supplier_sku: li.product.sku,
                ean: li.product.ean,
                quantity: li.quantity,
                supplier: li.product.supplier,
                returned_quantity: 0,
                price: li.product.price,
            }
        })
        this.setState({
            order,
            value: chosen,
            selectedRows: [],
        })
    }

    handleChange(property, value) {
        const updateState = {}
        updateState[property] = value
        this.setState(updateState)
    }

    handleSwitchChange({ value }) {
        this.setState({ isOrderFilter: !value })
    }

    handleShippingLabelsChange() {
        this.setState({ shippingLabels: !this.state.shippingLabels })
    }

    handleLabelAmountChange(e) {
        this.setState({ labelAmount: e.target.value })
    }

    handleDisableRefundChange() {
        this.setState({ disableRefund: !this.state.disableRefund })
    }

    canProceed(stepIndex) {
        switch (stepIndex) {
            case 0:
                return Boolean(this.state.order.mage_id)
            case 1:
                return this.state.order.line_items.some(l => !!(+l.returned_quantity))
            case 2:
                return true
            default:
                return false
        }
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                const orders = this.props.sales_orders.map(s => {
                    return `${s.mage_id} - ${s.customer.name} - ${s.billing_address.postal_code}`
                })
                return (
                    <Fragment>
                        <AutoComplete
                            dataSource={orders}
                            dataSourceConfig={config}
                            searchText={this.state.value}
                            fullWidth={true}
                            onUpdateInput={this.handleInputChange.bind(this)}
                            onNewRequest={this.handleNewRequest.bind(this)}
                            filter={AutoComplete.caseInsensitiveFilter}
                            floatingLabelText={this.state.isOrderFilter
                                ? 'Search for sales order by order number'
                                : 'Search for sales order by customer name/email/postal code'
                            }
                        />
                        <SwitchFilter
                            filterLabel="Filter op klant naam/email/postcode"
                            filterProperty="isOrderFilter"
                            onFilterChange={this.handleSwitchChange}
                            filterValue={!this.state.isOrderFilter}
                        />
                    </Fragment>
                )
            case 1:
                return (
                    <DialogLineItems
                        type="rma"
                        return_reasons={ReturnReasons}
                        line_items={this.state.order.line_items}
                        notes={this.state.order.notes || []}
                        selectedRows={this.state.selectedRows}
                        onQuantityChange={this.handleQuantityChange.bind(this)}
                        onReasonChange={this.handleReasonChange.bind(this)}
                        onRowSelection={this.handleRowSelection.bind(this)}
                    />
                )
            case 2:
                return (
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={this.handleShippingLabelsChange}
                                    value={this.state.shippingLabels}
                                />
                            }
                            label="Shipping labels"
                        />
                        <br />
                        {this.state.shippingLabels && (
                            <Fragment>
                                <TextField
                                    select
                                    value={this.state.labelAmount}
                                    style={style.labelAmount}
                                    label="How many labels (max 4)?"
                                    onChange={this.handleLabelAmountChange}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </TextField>
                                <br />
                            </Fragment>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={this.handleDisableRefundChange}
                                    value={this.state.disableRefund}
                                />
                            }
                            label="Disable refund"
                        />
                    </div>
                )
            default:
                return null
        }
    }

    getStepActions(finished) {
        const nextLabel = finished ? 'Finish' : 'Next'
        const nextClickHandler = finished ? this.handleSubmit.bind(this) : this.handleNext.bind(this)
        return [
            <Button
                key="back"
                onClick={this.handlePrev}
            >
                Back
            </Button>,
            <Button
                key="next"
                style={{ marginLeft: 20, color: 'white' }}
                variant="contained"
                color="primary"
                disabled={!this.canProceed(this.state.stepIndex)}
                onClick={nextClickHandler}
            >
                {nextLabel}
            </Button>,
        ]
    }

    render() {
        const { stepIndex } = this.state
        return (
            <Dialog open={true} fullWidth={true} maxWidth="lg">
                <DialogTitle>
                    Create new RMA by sales order
                    <IconButton
                        onClick={this.props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={stepIndex} style={style.stepper}>
                        <Step active={stepIndex === 0}>
                            <StepLabel>
                                Select a sales order
                            </StepLabel>
                        </Step>
                        <Step active={stepIndex === 1}>
                            <StepLabel>
                                Select returned items
                            </StepLabel>
                        </Step>
                        <Step active={stepIndex === 2}>
                            <StepLabel>
                                Select options
                            </StepLabel>
                        </Step>
                    </Stepper>
                    <DialogContentText>
                        {this.getStepContent(stepIndex)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.getStepActions(this.state.finished)}
                </DialogActions>
            </Dialog>
        )
    }
}

AddRmaDialog.propTypes = {
    sales_orders: PropTypes.array,
    visible: PropTypes.bool,
    isUpdated: PropTypes.bool,
    onSearchSalesOrders: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

AddRmaDialog.defaultProps = {
    isUpdated: false,
    sales_orders: [],
}

export default AddRmaDialog
