import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import { Paper, ListSubheader, List, ListItem, ListItemText, TextField, IconButton, Divider } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

const style = {
    textField: { width: '80%' },
}

class RtvSpecifications extends Component {

    constructor(props) {
        super(props)
        const value = props.value || ''
        this.state = {
            adding: false,
            value,
        }

        autoBind(this)
    }

    handleChange(e) {
        this.setState({ value: e.target.value })
    }

    handleSave(e) {
        e.preventDefault()
        if (this.props.value !== this.state.value) {
            this.props.onSave(this.state.value)
        }
    }

    render() {
        const submitted_at = !this.props.submitted_at ? null : (
            <ListItem
                disabled={true}
            >
                <ListItemText
                    primary="Submitted to vendor at"
                    secondary={this.props.submitted_at}
                />
            </ListItem>
        )
        return (
            <Paper>
                <List>
                    <ListSubheader>Rtv specifications</ListSubheader>
                    <ListItem>
                        <ListItemText
                            primary="Type"
                            secondary={this.props.type}
                        />
                    </ListItem>
                    {submitted_at}
                    <Divider />
                    <ListItem
                        disabled={true}
                    >
                        <TextField
                            style={style.textField}
                            onChange={this.handleChange}
                            disabled={this.state.disabled}
                            value={this.state.value}
                            label="Vendor reference"
                            placeholder="Reference.."
                        />
                        <IconButton
                            disabled={this.state.value === '' || this.props.value === this.state.value}
                            color="black"
                            onClick={this.handleSave}
                        >
                            <DoneIcon />
                        </IconButton>
                    </ListItem>
                </List>
            </Paper>
        )
    }
}

RtvSpecifications.propTypes = {
    value: PropTypes.string,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submitted_at: PropTypes.string,
    onSave: PropTypes.func,
}

RtvSpecifications.defaultProps = {
    canAdd: true,
    notes: [],
    style: {},
}

export default RtvSpecifications
