import React from 'react'
import PropTypes from 'prop-types'
import {
    Badge, Drawer, MenuItem, AppBar, RefreshIndicator, Avatar, IconButton, IconMenu, List, ListItem, Snackbar,
} from 'material-ui'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { destroySession } from './../../actions/sessions'
import { hideNotification } from './../../actions/notifications'

import Colors from './../styles/colors'
import {ReactComponent as ProfortoolLogo} from './../media/profortool-logo.svg'
import browser from 'bowser'
import autobind from 'react-autobind'

const style = {
    appBar: {
        boxShadow: 'none',
        position: 'fixed',
        zIndex: 20,
        height: 64,
    },
    appBarButton: { lineHeight: 'inherit', width: 54, height: 54 },
    appBarIcon: { color: '#FFFFFF', fontSize: 30 },

    tabs: { height: 64, fontFamily: 'Roboto' },
    tab: { height: 64, width: 124 },

    warning: {
        position: 'relative',
        width: '100%',
        top: 64,
        backgroundColor: 'rgba(255, 204, 0, 0.05)',
        color: '#000000',
        lineHeight: '40px',
        padding: 0,
        textAlign: 'center',
    },
    error: {
        marginTop: 64,
        backgroundColor: '#ff4b4b',
        color: '#ffffff',
        lineHeight: 3,
        textAlign: 'center',
    },
    badge: {
        padding: '20px 20px 12px 12px',
    },
    badgeContentStyle: {
        right: 2,
        top: 2,
        width: 26,
        height: 26,
        fontSize: 14,
        color: Colors.white,
        backgroundColor: Colors.tertiaryColor100,
    },
    body: {
        maxWidth: '1024px',
    },
}

class ActionBar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showNotification: false,
            activeTab: this.props.location.pathname,
            loading: false,
            menuOpen: false,
        }
        autobind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {}
        if (prevState.activeTab !== nextProps.location.pathname) {
            newState.activeTab = nextProps.location.pathname
        }
        if (Boolean(nextProps.notification.message) !== prevState.showNotification) {
            newState.showNotification = Boolean(nextProps.notification.message)
        }
        if (Object.keys(newState).length > 0) {
            return newState
        }
        return null
    }

    pushHistoryState(val) {
        this.context.router.push(val || this.state.activeTab)
    }

    handleTabChange(value) {
        this.setState({
            activeTab: value,
        }, this.pushHistoryState)
    }

    handleTabClick(e) {
        const dataSet = e.currentTarget.dataset
        if (dataSet.value) {
            this.pushHistoryState(dataSet.value)
        }
    }

    handleHelpClick() {
        this.pushHistoryState('/help')
    }

    handleSettingsClick() {
        this.pushHistoryState('/settings')
    }

    handleSessionDestroy() {
        this.props.destroySession()
    }

    /**g
     * Show a warning for unsupported browsers. We only support Chrome, Firefox
     * and Safari for now. Every other browser gets this warning.
     * @returns {JSX} Warning for unsupported browser based on current browser
     */
    _getUnsupportedBrowserWarning() {
        /*
         * FIXME: Ignoring the following line because of: https://github.com/ded/bowser/issues/126
         * const is_supported_platform = browser.mac || browser.windows || browser.linux;
         */

        if (!browser.chrome && !browser.firefox && !browser.safari) {
            return (
                <div style={style.warning}>
                    <p>
                        You are using a unsupported browser! Please use one of the recommended browsers: Chrome, Firefox
                        or Safari.
                    </p>
                </div>
            )
        }
        return null
    }

    handleLeftIconButtonTouch() {
        this.setState({
            menuOpen: !this.state.menuOpen,
            showNotification: false,
        })
    }

    handleListItemClick(route) {
        this.setState({
            menuOpen: false,
        }, () => {
            setTimeout(() => {
                this.context.router.push(route)
            }, 150)
        })
    }

    hasPermissions(permissions) {
        return permissions.some(permission => this.props.permissions?.includes(permission))
    }

    hasRoles(roles) {
        return roles.some(role => this.props.roles?.includes(role))
    }

    isAuthorized(roles, permissions) {
        return this.hasRoles(roles) || this.hasPermissions(permissions)
    }

    getListItems() {
        const listItems = []
        if (this.hasPermissions(['supply'])) {
            listItems.push(
                <ListItem
                    primaryText="Shipments"
                    onClick={() => { this.handleListItemClick('/sales/shipments/') }}
                />
            )
            if (this.props.features.purchaseOrderOutboundTriage === true && this.context.role === 'supplier') {
                listItems.push(
                    <ListItem
                        primaryText="Purchase orders"
                        onClick={() => { this.handleListItemClick('/purchases/purchase-orders/outbound/') }}
                    />
                )
            }
        }

        if (this.hasPermissions(['sales'])) {
            listItems.push(
                <ListItem
                    key={'Sales'}
                    initiallyOpen={true}
                    primaryText="Sales"
                    primaryTogglesNestedList={true}
                    nestedItems={[
                        <ListItem
                            key={'Sales Orders'}
                            onClick={() => { this.handleListItemClick('/sales/sales-orders/') }}
                            primaryText="Sales Orders"
                        />,
                        <ListItem
                            key={'Shipments'}
                            onClick={() => { this.handleListItemClick('/sales/shipments/') }}
                            primaryText="Shipments"
                        />,
                    ]}
                />
            )
            listItems.push(
                <ListItem
                    key={'Suppliers'}
                    onClick={() => { this.handleListItemClick('/suppliers/') }}
                    primaryText="Suppliers"
                />
            )
        }
        if (this.hasPermissions(['production'])) {
            listItems.push(
                <ListItem
                    key={'Production'}
                    initiallyOpen={true}
                    primaryText="Production"
                    primaryTogglesNestedList={true}
                    nestedItems={[
                        <ListItem
                            key={'Production'}
                            onClick={() => { this.handleListItemClick('/production/production-orders') }}
                            primaryText="Production Orders"
                        />,
                    ]}
                />

            )
        }

        if (this.hasPermissions(['returns']) ) {
            listItems.push(
                <ListItem
                    key={'Returns'}
                    initiallyOpen={true}
                    primaryText="Returns"
                    primaryTogglesNestedList={true}
                    nestedItems={[
                        <ListItem
                            key={'RMA'}
                            onClick={() => { this.handleListItemClick('/returns/rma/') }}
                            primaryText="RMA"
                        />,
                        <ListItem
                            key={'RTV'}
                            onClick={() => { this.handleListItemClick('/returns/rtv/') }}
                            primaryText="RTV"
                        />,
                    ]}
                />
            )
        }
        if (this.hasPermissions(['purchase']) || this.hasRoles(['external-supply-chain'])) {
            const purchasesNestedItems = [
                <ListItem
                    key={'Purchase Orders'}
                    onClick={() => { this.handleListItemClick('/purchases/purchase-orders/') }}
                    primaryText="Purchase Orders"
                />,
            ]
            if (this.hasPermissions(['purchase'])) {
                purchasesNestedItems.push(
                    <ListItem
                        key={'Purchase Requisitions'}
                        onClick={() => { this.handleListItemClick('/purchases/requisitions/') }}
                        primaryText="Purchase Requisitions"
                    />
                )
            }

            listItems.push(
                <ListItem
                    key={'Purchases'}
                    initiallyOpen={true}
                    primaryTogglesNestedList={true}
                    primaryText="Purchases"
                    nestedItems={purchasesNestedItems}
                />
            )
        }

        if (this.hasPermissions(['inbound'])) {
            listItems.push(
                <ListItem
                    key={'Logistics'}
                    initiallyOpen={true}
                    primaryTogglesNestedList={true}
                    primaryText="Logistics"
                    nestedItems={[
                        <ListItem
                            key={'Outbound'}
                            initiallyOpen={false}
                            primaryTogglesNestedList={true}
                            primaryText="Outbound"
                            nestedItems={[
                                <ListItem
                                    key={'Pack-from-wall'}
                                    onClick={() => { this.handleListItemClick('/logistics/outbound/pack-from-wall/') }}
                                    primaryText="Pack from wall"
                                />,
                                <ListItem
                                    key={'Pack-by-shipment'}
                                    onClick={() => {
                                        this.handleListItemClick('/logistics/outbound/pack-by-shipment/')
                                    }}
                                    primaryText="Pack by shipment"
                                />,
                                <ListItem
                                    key={'Delivery-orders'}
                                    onClick={() => {
                                        this.handleListItemClick('/logistics/outbound/delivery-orders/')
                                    }}
                                    primaryText="Delivery orders"
                                />,
                                <ListItem
                                    key={'Pack-single-pick'}
                                    onClick={() => {
                                        this.handleListItemClick('/logistics/outbound/pack-single-pick/')
                                    }}
                                    primaryText="Pack single pick"
                                />,
                            ]}
                        />,
                        <ListItem
                            key={'Picking'}
                            initiallyOpen={false}
                            primaryTogglesNestedList={true}
                            primaryText="Picking"
                            nestedItems={[
                                <ListItem
                                    key={'Pick-lists'}
                                    onClick={() => {
                                        this.handleListItemClick('/logistics/picking/pick-lists/')
                                    }}
                                    primaryText="Pick lists"
                                />,
                            ]}
                        />,
                    ]}
                />
            )
        }

        if (this.isAuthorized(['external-supply-chain'], ['inventory'])) {
            listItems.push(
                <ListItem
                    key={'Inventory'}
                    onClick={() => { this.handleListItemClick('/inventory/') }}
                    primaryText="Inventory"
                />
            )
        }

        if (this.hasPermissions(['finance'])) {
            listItems.push(
                <ListItem
                    key={'Finance'}
                    initiallyOpen={true}
                    primaryTogglesNestedList={true}
                    primaryText="Finance"
                    nestedItems={[
                        <ListItem
                            key={'Credits'}
                            onClick={() => { this.handleListItemClick('/finance/credit-memos/') }}
                            primaryText="Credits"
                        />,
                        <ListItem
                            key={'Debits'}
                            onClick={() => { this.handleListItemClick('/finance/debit-memos/') }}
                            primaryText="Debits"
                        />,
                    ]}
                />
            )
        }
        return listItems
    }

    handleRequestClose() {
        this.props.hideNotification()
    }

    getMessage() {
        if (!this.props.notification.message) {
            return ''
        }
        if (this.props.notification.message instanceof Array) { // Array of strings
            return (
                <ul style={style.notificationList}>
                    {this.props.notification.message.map(message_line => (
                        <li key={message_line}>{this.context.translate(message_line)}</li>
                    ))}
                </ul>
            )
        }
        return typeof this.props.notification.message === 'string'
            ? this.context.translate(this.props.notification.message)
            : this.props.notification.message
    }

    render() {
        const height = Array.isArray(this.props.notification.message)
            ? `${Math.ceil(this.props.notification.message.length * 48)}px`
            : '48px'

        const notification = (
            <Snackbar
                message={this.getMessage()}
                open={!!this.props.notification.message}
                bodyStyle={{ ...style.body, height }}
                action={this.props.notification.action || undefined}
                autoHideDuration={this.props.notification.duration || 3000}
                onActionClick={this.props.notification.onActionClick || undefined}
                onRequestClose={this.handleRequestClose}
            />
        )
        const listItems = this.getListItems()

        const options = (
            <IconButton
                iconClassName="material-icons"
                tooltipPosition="bottom-center"
                tooltip="More"
                style={style.appBarButton}
                iconStyle={style.appBarIcon}
            >
                more_vert
            </IconButton>
        )
        const unsupportedBrowserWarning = this._getUnsupportedBrowserWarning()
        let menu = null

        if (this.props.permissions?.length > 0) {
            menu = [
                ...this.props.menu_items.map((mi, idx) => {
                    const badge = mi.type !== 'badge' ? null : (
                        <Badge
                            style={style.badge}
                            badgeContent={mi.badgeContent}
                            badgeStyle={style.badgeContentStyle}
                        >
                            <Avatar
                                size={30}
                                backgroundColor="transparent"
                            >
                                {mi.tooltip.charAt(0).toUpperCase()}
                            </Avatar>
                        </Badge>
                    )
                    return (
                        <IconMenu
                            key={`${mi.badgeIcon}_${idx}`}
                            tooltipPosition="bottom-center"
                            tooltip={mi.tooltip}
                            iconButtonElement={
                                badge
                            }
                            open={false}
                        />
                    )
                }),
                <IconMenu key="options" iconButtonElement={options}>
                    <MenuItem primaryText="Help" onClick={this.handleHelpClick.bind(this)} />
                    <MenuItem primaryText="Settings" onClick={this.handleSettingsClick.bind(this)} />
                    <MenuItem primaryText="Sign out" onClick={this.handleSessionDestroy.bind(this)} />
                </IconMenu>,
            ]
        }

        const refreshIndicator = (
            <RefreshIndicator
                size={40}
                left={290}
                top={10}
                percentage={80}
                status={this.props.loading ? 'loading' : 'hide'}
                style={{
                    backgroundColor: Colors.primaryColor100,
                    boxShadow: 'none',
                    marginLeft: '5px',
                }}
                loadingColor={'#FFFFFF'}
            />
        )



        return (
            <div>
                <AppBar
                    onLeftIconButtonClick={this.handleLeftIconButtonTouch}
                    showMenuIconButton={Boolean(this.props.authenticated)}
                    iconElementRight={<div>{menu}</div>}
                    style={style.appBar}
                >
                    <ProfortoolLogo
                        style={{ marginTop: 7, marginLeft: 30, position: 'absolute', width: 200, height: 50 }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            left: 257,
                            top: 29,
                            color: Colors.darkWhite,
                            fontFamily: 'Allotrope',
                        }}
                        title={`${window.PROFORTOOL_APP_GIT_BRANCH}/${window.PROFORTOOL_APP_GIT_COMMIT_HASH}`}
                    >
                        {window.PROFORTOOL_APP_VERSION}
                    </span>
                    {refreshIndicator}
                </AppBar>
                {unsupportedBrowserWarning}
                <Drawer
                    docked={false}
                    open={this.state.menuOpen}
                    onRequestChange={menuOpen => this.setState({ menuOpen })}
                >
                    <AppBar
                        title={<span>Menu</span>}
                        showMenuIconButton={false}
                    />
                    <List style={{ width: 256 }}>
                        {listItems}
                    </List>
                </Drawer>
                {notification}
            </div>
        )
    }
}

ActionBar.propTypes = {
    features: PropTypes.object,
    authenticated: PropTypes.bool,
    notification: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    menu_items: PropTypes.array,
    permissions: PropTypes.array,
    destroySession: PropTypes.func.isRequired,
    hideNotification: PropTypes.func.isRequired,
}

ActionBar.defaultProps = {
    notification: { message: '' },
    loading: false,
    menu_items: [],
    authenticated: false,
}

ActionBar.contextTypes = {
    role: PropTypes.string,
    translate: PropTypes.func,
    router: PropTypes.object,
    device: PropTypes.string,
}

export default connect(({ loading, notification, menu_items = [] }) => {
    return {
        loading,
        notification,
        menu_items,
    }
}, dispatch => {
    const actions = { destroySession, hideNotification }
    return bindActionCreators(actions, dispatch)
})(ActionBar)
