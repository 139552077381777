import React, { Component, Fragment } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

const styles = {
    actionsStyle: { justifyContent: 'space-between', margin: 8 },
    supplierFilter: { width: 400, marginRight: 15, marginLeft: 15, overflow: 'hidden' },
    finalizeButton: { marginLeft: 20 },
}

const initialState = {
    submitEmail: false,
    email: '',
}

class FinalizeDraftDialog extends Component {

    constructor(props) {
        super(props)
        this.state = initialState

        autoBind(this)
    }

    handleSubmit() {
        const state = this.state
        this.setState(initialState, () => {
            this.props.onSubmit(state)
        })
    }

    handleClose() {
        this.setState(initialState, () => {
            return this.props.onClose && this.props.onClose()
        })
    }

    handleSubmitEmailChange(e) {
        const newState = { submitEmail: e.target.checked }
        if (e.target.checked && !this.state.email) {
            newState.email = this.getPurchaseOrderEmail(this.props.purchase_order.supplier)
        }
        this.setState(newState)
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }

    getPurchaseOrderEmail(supplier) {
        if (supplier.contact &&
            supplier.contact.purchase &&
            supplier.contact.purchase.to &&
            supplier.contact.purchase.to.length
        ) {
            const purchase_contact = supplier.contact.purchase.to[0]
            return purchase_contact.email
        }
        return supplier.email || ''
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={this.props.open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this.handleClose}
                aria-labelledby="finalize-draft-dialog-title"
            >
                <DialogTitle id="finalize-draft-dialog-title">
                    {`Finalize draft purchase order ${this.props.purchase_order.order_id}
                    of ${this.props.purchase_order.supplier.name}`
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Submit PDF email/Transus message automatically?</DialogContentText>
                    <FormControl variant="outlined" margin="none">
                        <FormControlLabel
                            label={this.state.submitEmail ? 'Yes' : 'No'}
                            control={
                                <Checkbox
                                    id="submit-email"
                                    checked={this.state.submitEmail}
                                    onChange={this.handleSubmitEmailChange}
                                />
                            }
                        />

                    </FormControl>
                    {this.state.submitEmail && (
                        <Fragment>
                            <DialogContentText>E-mail to send to</DialogContentText>
                            <FormControl margin="none">
                                <TextField
                                    variant="outlined"
                                    type="email"
                                    placeholder="foo@bar.com"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                />
                            </FormControl>
                        </Fragment>
                    )}
                </DialogContent>
                <DialogActions style={styles.actionsStyle}>
                    <Button onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button
                        style={styles.finalizeButton}
                        color="primary"
                        variant="contained"
                        onClick={this.handleSubmit}
                    >
                        Finalize
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

FinalizeDraftDialog.propTypes = {
    open: PropTypes.bool,
    purchase_order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default FinalizeDraftDialog
