import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FlatButton, SelectField, MenuItem} from 'material-ui'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

class DeliverDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {date: null, reason: null}
    }

    handleSubmit() {
        const state = this.state
        this.setState({date: null, reason: null}, () => {
            state.date = moment(state.date).toDate()
            this.props.onSubmit(this.props.status, this.props.items, state)
        })
    }

    handleDatePickerChange({target}) {
        this.setState({date: target.value})
    }

    handleSelectFieldChange(e, index, reason) {
        this.setState({reason})
    }

    getTitle(status) {
        switch (status) {
            case 'approve':
                return ''
            case 'postpone':
                return 'On which date will these product(s) be delivered to Proforto?'
            case 'reject':
                return 'Why are the product(s) undeliverable?'
            default:
                return ''
        }
    }

    getContents(status) {
        switch (status) {
            case 'postpone':
                return (
                    <TextField
                        label="Pick a date"
                        type="date"
                        InputLabelProps={{shrink: true}}
                        value={this.state.date}
                        onChange={this.handleDatePickerChange.bind(this)}
                    />
                )
            case 'reject':
                return (
                    <SelectField
                        onChange={this.handleSelectFieldChange.bind(this)}
                        value={this.state.reason}
                        floatingLabelText="Select a reason"
                    >
                        <MenuItem value="out-of-assortment" primaryText="Out of assortment"/>
                        <MenuItem value="outstanding-invoices" primaryText="Outstanding invoices"/>
                        <MenuItem value="other" primaryText="Other"/>
                    </SelectField>
                )
            default: return null
        }
    }

    canSubmit(status, state) {
        switch (status) {
            case 'postpone':
                return state.date
            case 'reject':
                return state.reason
            default:
                return false
        }
    }

    render() {
        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={this.props.open}
            >
                <DialogTitle>
                    {this.getTitle(this.props.status)}
                </DialogTitle>
                <DialogContent>
                    {this.getContents(this.props.status)}
                </DialogContent>
                <DialogActions>
                    <FlatButton
                        key="submit"
                        disabled={!this.canSubmit(this.props.status, this.state)}
                        onClick={this.handleSubmit.bind(this)}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>
                    <FlatButton key="close" onClick={this.props.onClose}>Close</FlatButton>
                </DialogActions>
            </Dialog>
        )
    }
}

DeliverDialog.propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
DeliverDialog.defaultProps = {
    open: false,
    items: [],
}

export default DeliverDialog
