import React from 'react'
import PropTypes from 'prop-types'
import {FontIcon, List, ListItem} from 'material-ui'
import {get} from 'lodash'

import {stringifyAddress} from '../../../../../utils/stringify'

const styles = {
    listItemInnerDivStyle: {
        padding: '8px 16px',
    },
}

function ShippingInfo(props, context) {
    const translate = context.translate
    return (
        <div style={props.style}>
            <List>
                <ListItem
                    innerDivStyle={styles.listItemInnerDivStyle}
                    primaryText={translate('Customer')}
                    secondaryText={get(props, 'shipment.shipping_address.name', '-')}
                />
                {!get(props, 'shipment.shipping_address.company', null) ? null : (
                    <ListItem
                        innerDivStyle={styles.listItemInnerDivStyle}
                        primaryText={translate('Company')}
                        secondaryText={props.shipment.shipping_address.company}
                    />
                )}
                <ListItem
                    innerDivStyle={styles.listItemInnerDivStyle}
                    primaryText={translate('Shipping address')}
                    secondaryText={stringifyAddress(get(props, 'shipment.shipping_address', {}))}
                    secondaryTextLines={2}
                />
                <ListItem
                    innerDivStyle={styles.listItemInnerDivStyle}
                    primaryText={translate('Fulfillment flow')}
                    secondaryText={props.shipment ? translate(props.shipment.source) : '-'}
                    rightIcon={get(props, 'shipment.source', null) !== 'personalisation' ? null : (
                        <FontIcon className="material-icons">format_color_fill</FontIcon>
                    )}
                />
                <ListItem
                    innerDivStyle={styles.listItemInnerDivStyle}
                    primaryText={translate('Will be shipped to')}
                    secondaryText={props.shipment && props.shipment.shipping_address ?
                        translate(props.shipment.shipping_address.type)
                        : '-'
                    }
                    rightIcon={(
                        <FontIcon className="material-icons">
                            {get(props, 'shipment.shipping_address.type', 'customer') === 'manufacturer'
                                ? 'business'
                                : 'person'}
                        </FontIcon>
                    )}
                />
            </List>
        </div>
    )
}

ShippingInfo.contextTypes = {
    translate: PropTypes.func.isRequired,
}
ShippingInfo.propTypes = {
    style: PropTypes.object,
    headerStyle: PropTypes.object,
    packing_item: PropTypes.object,
    shipment: PropTypes.object,
}

export default ShippingInfo
