import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getDeliveryOrderByShipmentRef } from '../../../../../../commands/sockets/delivery_orders'

/* eslint-disable-next-line max-len */
import DeliveryOrderLineItems from '../../../../logistics/outbound/delivery-orders/delivery-order-detail/DeliveryOrderLineItems'

class DeliveryOrder extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        if (!this.props.delivery_order.increment_id) {
            this.props.getDeliveryOrderByShipmentRef(this.props.shipment_ref)
        }
    }

    render() {
        const delivery_order = this.props.delivery_order
        if (!delivery_order.increment_id) {
            return null
        }
        return (
            <Dialog open={this.props.open} maxWidth="lg">
                <DialogTitle>Delivery order</DialogTitle>
                <DialogContent>
                    <DeliveryOrderLineItems
                        line_items={delivery_order.line_items}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

DeliveryOrder.propTypes = {
    open: PropTypes.bool,
    shipment_ref: PropTypes.string.isRequired,
    delivery_order: PropTypes.object,
    getDeliveryOrderByShipmentRef: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
DeliveryOrder.defaultProps = {
    open: true,
}

export default connect(({ delivery_order = {} }) => {
    return {
        delivery_order,
    }
}, dispatch => {
    return bindActionCreators({ getDeliveryOrderByShipmentRef }, dispatch)
})(DeliveryOrder)

