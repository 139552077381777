import store from '../../store'

export default function initCreditMemosSockets(socket) {
    socket.on('credits/search-result', data => {
        store.dispatch({type: 'REFRESH_CREDIT_MEMOS', credit_memos: data})
        // store.dispatch({type: 'REFRESH_PAGINATION_CREDIT_MEMOS', pagination: meta});
    })

    socket.on('credits', data => {
        store.dispatch({type: 'REFRESH_CREDIT_MEMOS', credit_memos: data})
        // store.dispatch({type: 'REFRESH_PAGINATION_CREDIT_MEMOS', pagination: meta});
    })

    socket.on('credit', credit_memo => {
        store.dispatch({type: 'REFRESH_CREDIT_MEMO', credit_memo})
    })
}
