import store from '../../store'

export default function initSuppliersSockets(socket) {
    socket.on('suppliers/search-result', suppliers => {
        store.dispatch({type: 'REFRESH_SUPPLIERS', suppliers})
    })

    socket.on('supplier', supplier => {
        store.dispatch({type: 'REFRESH_SUPPLIER', supplier})
    })

    socket.on('supplier/update/ok', supplier => {
        store.dispatch({type: 'REFRESH_SUPPLIER', supplier})
    })
}
