import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableRow,
    TableCell,
    TablePagination,
} from '@material-ui/core'

import _ from 'lodash'

import { formatDateTime } from '../../../../utils/format'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
}

class PickListTable extends Component {
    constructor(...args) {
        super(...args)

        this.handleTableRowClick = this.handleTableRowClick.bind(this)
        this.renderPickListRows = this.renderPickListRows.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handlePageDecrementClick = this.handlePageDecrementClick.bind(this)
        this.handlePageIncrementClick = this.handlePageIncrementClick.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    handleTableRowClick(index) {
        return this.props.onPickListClick && this.props.onPickListClick(this.props.pick_lists[index])
    }

    handleChangePage() {
        // TablePagination - onChangePage
        // Method sometimes fires when no click on a page increment/decrement has been done
        // Paging didn't work when using the 'onChangePage' event
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    handlePageDecrementClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handlePageIncrementClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    renderPickListRows(pick_lists) {
        if (pick_lists.length > 0) {
            return pick_lists.map((pick_list, index) => (
                <TableRow
                    onClick={this.handleTableRowClick.bind(this, index)}
                    key={index}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: pick_list.status === 'draft' ? '#FFEFD5' : 'inherit',
                    }}
                >
                    {
                        this.props.headerCols.map(({ style, property, formatter }) => {
                            let value = typeof property === 'function'
                                ? property(pick_list)
                                : _.get(pick_list, property, '')
                            if (formatter) value = formatter(value)

                            return (
                                <TableCell key={property} style={style}>{value}</TableCell>
                            )
                        })
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>
                    No pick lists found.
                </TableCell>
            </TableRow>
        )
    }

    renderNavigation(pick_lists) {
        if (pick_lists.length > 0) {
            const { page, limit, headerCols } = this.props
            const from = Math.max(0, (limit * page))
            const to = Math.min(from + limit, from + pick_lists.length)
            return (
                <TableFooter style={{ height: 60 }}>
                    <TableRow>
                        <TableCell colSpan={headerCols.length}>
                            <TablePagination
                                component="div"
                                count={pick_lists.length}
                                rowsPerPage={limit}
                                page={page} // 0 is first page
                                labelDisplayedRows={() => `${from + 1} - ${to}`}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    disabled: page === 0,
                                    onClick: this.handlePageDecrementClick,
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    disabled: pick_lists.length < limit,
                                    onClick: this.handlePageIncrementClick,
                                }}
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const pick_lists = this.props.pick_lists
        const orderRows = this.renderPickListRows(pick_lists)
        const navigation = this.renderNavigation(pick_lists)

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            this.props.headerCols.map((column, index) => {
                                return (
                                    <TableCell
                                        style={{ ...column.style, fontWeight: 'bold' }}
                                        key={index}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

PickListTable.propTypes = {
    headerCols: PropTypes.array,
    pick_lists: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,

    onPickListClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

PickListTable.defaultProps = {
    headerCols: [{
        content: 'ID',
        tooltip: 'The pick list ID',
        style: { width: '10%' },
        property: 'increment_id',
    }, {
        content: 'Status',
        tooltip: 'Status',
        style: { width: '10%' },
        property: 'status',
    }, {
        content: 'Process',
        tooltip: 'Process of pick list',
        style: { width: '15%' },
        property: 'process',
    }, {
        content: 'Supplier',
        tooltip: 'Supplier we are picking for excess inventory',
        style: { width: '15%' },
        property: 'supplier.name',
    }, {
        content: 'Creation',
        tooltip: 'Creation Date',
        style: { width: '20%' },
        property: 'created_at',
        formatter: formatDateTime,
    },
    {
        content: 'Completed',
        tooltip: 'Completed Date',
        style: { width: '20%' },
        property: 'completed_at',
        formatter: formatDateTime,
    }, {
        content: 'List Type',
        tooltip: 'Type of the picklist',
        style: { width: '10%' },
        property: 'pick_list_type',
    }, {
        content: 'Pick count',
        tooltip: 'The amount of pick rows in this pick list',
        style: { width: '20%' },
        property: pick_list => {
            const total_to_pick = pick_list.picks.reduce((acc, pick) => acc + pick.qty_to_pick, 0)
            if (pick_list.status === 'draft') return total_to_pick

            const total_picked = pick_list.picks.reduce((acc, pick) => acc + pick.qty_picked, 0)
            return `${total_picked}/${total_to_pick}`
        },
    }],
}

export default PickListTable
