export function searchTypedOrders(filters) {
    return {type: 'SEARCH_TYPED_ORDERS', filters}
}

export function refreshTypedOrders(typed_orders) {
    return {type: 'REFRESH_TYPED_ORDERS', typed_orders}
}

export function getTypedOrder(order_id, source, supplier_id) {
    return {type: 'GET_TYPED_ORDER', order_id, source, supplier_id}
}

export function refreshTypedOrder(typed_order) {
    return {type: 'REFRESH_TYPED_ORDER', typed_order}
}
