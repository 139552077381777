import React from 'react'
import PropTypes from 'prop-types'

function PersonalisationConfigImage(props) {
    return !props.src ? null : (
        <figure><img alt="" src={props.src}/></figure>
    )
}

PersonalisationConfigImage.propTypes = {src: PropTypes.string}
PersonalisationConfigImage.defaultProps = {src: null}
export default PersonalisationConfigImage
