import store from '../../store'
import { getPromoItemsByMageId } from '../../commands-sync/order'

function isSameStation(station) {
    return station === store.getState().packing_station
}

export default function initPackingStationsSockets(socket) {
    socket.on('packing-stations/assigned', data => {
        if (isSameStation(data.station_id)) {
            socket.emit('shipment/get', {shipment_id: data.packing_item.shipment_id})

            store.dispatch({type: 'REFRESH_PACKING_ITEM', data: data.packing_item})
            store.dispatch({type: 'REFRESH_COMPARTMENT', data: data.name})

            getPromoItemsByMageId(data.packing_item.mage_id).catch(e => {
                store.dispatch({type: 'NOTIFICATION_ERROR',
                    notification: 'Couldn\'t retrieve promo items' + e.message })
            })
        }
    })

    socket.on('packing-stations/un-assigned', station_id => {
        if (isSameStation(station_id)) {
            store.dispatch({type: 'REFRESH_PACKING_ITEM', data: {}})
            store.dispatch({type: 'REFRESH_COMPARTMENT', data: ''})
        }
    })

    socket.on('packing-stations/registered', data => {
        store.dispatch({
            type: 'REFRESH_PACKING_STATION',
            data,
        })
    })

    socket.on('packing-stations/unregistered', () => {
        store.dispatch({
            type: 'REFRESH_PACKING_STATION',
            data: -1,
        })
        store.dispatch({type: 'REFRESH_PACKING_ITEM', data: {}})
        store.dispatch({type: 'REFRESH_COMPARTMENT', data: ''})
    })
}
