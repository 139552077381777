import store from '../../store'
import { refreshStock } from '../../actions/stocks'
import { showNotification } from '../../actions/notifications'

export default function initInventorySockets(socket) {

    socket.on('stock/updated', stock => {
        store.dispatch(refreshStock(stock))
    })

    socket.on('stocks/warehouse/existing-uploaded', ({ updated, error_lines }) => {
        store.dispatch({type: 'SET_ACTION_DIALOG', actionPayload: {
            title: 'Inventory Update Result',
            type: 'upload_inventory_info',
            message: {
                updated,
                error_lines,
            },
        }})
    })

    socket.on('stocks/warehouse/phased-out', () => {
        store.dispatch({ type: 'REFRESH' })
    })

    socket.on('stocks/adjusted', notification => {
        store.dispatch(showNotification(notification))
    })
}
