import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'

import EmailIcon from '@material-ui/icons/Email'
import DateRangeIcon from '@material-ui/icons/DateRange'
import UndoIcon from '@material-ui/icons/Undo'
import CancelIcon from '@material-ui/icons/Cancel'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

function getIcon(action) {
    if (action.match(/postponed/)) {
        return <DateRangeIcon />
    } else if (action.match(/order_confirmation_sent|invoice_sent|mails/)) {
        return <EmailIcon />
    } else if (action.match(/rma/)) {
        return <UndoIcon />
    } else if (action.match(/cancellations/)) {
        return <CancelIcon />
    } else if (action === 'shipments.send') {
        return <LocalShippingIcon />
    }
    return null
}

function ActionLogDialog(props) {
    return (
        <Dialog
            onClose={props.onClose}
            aria-labelledby="action-log-dialog-title"
            onRequestClose={props.onClose}
            open={true}
        >
            <DialogTitle id="action-log-dialog-title">Action log</DialogTitle>
            <DialogContent>
                <List>
                    {
                        props.logs.map(log => (
                            <ListItem key={log._id}>
                                {getIcon(log.action)}
                                <ListItemText
                                    primary={log.description}
                                    secondary={moment(log.date).format('DD-MM-YYYY HH:mm')}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ActionLogDialog.propTypes = {
    logs: PropTypes.array,
    onClose: PropTypes.func.isRequired,
}

ActionLogDialog.defaultProps = {
    logs: [],
}

export default ActionLogDialog
