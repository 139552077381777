import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { ProductionOrderLocation } from '../../../types/production/production-orders'

const style = {
    width: 200,
}

const locationOptions = Object.values(ProductionOrderLocation).map(location => {
    return {
        value: location,
        text: location.replace(/_/g, ' ').replace(/([a-z])/, '$1'.toUpperCase()),
    }
})

function PersonalisationLocationSelect(props) {
    const locations = props.locations instanceof Array && props.locations.length
        ? locationOptions.filter(d => props.locations.includes(d.value))
        : locationOptions
    return (
        <TextField
            select
            style={{ ...style, ...props.style }}
            onChange={props.onChange}
            value={props.value}
            label="Select location"
            SelectProps={{ MenuProps: { style: { zIndex: 1600, maxHeight: 800 }, disableAutoFocusItem: true } }}
            InputLabelProps={{ shrink: true }}
        >
            {!props.allowAll ? null : (
                <MenuItem
                    value="all"
                >
                    All
                </MenuItem>
            )}
            {locations.map(({ text, value }) => (
                <MenuItem
                    key={text}
                    value={value}
                >
                    {text}
                </MenuItem>
            ))}
        </TextField>
    )
}

PersonalisationLocationSelect.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string,
    allowAll: PropTypes.bool,
    locations: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}
PersonalisationLocationSelect.defaultProps = {
    style: {},
    locations: [],
    allowAll: false,
    value: '',
}

export default PersonalisationLocationSelect
