import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import _ from 'lodash'
import moment from 'moment'

import { downloadBase64AsPdf } from '../../../../utils/download'

import {
    Avatar,
    Button,
    Card,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanelActions,
    Divider,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
    Zoom,
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DateRangeIcon from '@material-ui/icons/DateRange'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPackingSlip } from '../../../../../commands/sockets/packing_slips'
import {
    createShipment, markShipmentAsDelivered, postponeShipment, updateTracking,
} from '../../../../../actions/shipments'
import { submitCancellation } from '../../../../../actions/cancellations'

import PostponeDialog from '../../PostponeDialog'
import CancelDialog from '../../CancelDialog'
import TrackingDialog from '../../TrackingDialog'
import CreateShipmentDialog from '../../CreateShipmentDialog'
import DeliveryOrderDialog from './outbound/DeliveryOrderDialog'

import { ShipmentStatus } from '../../../../utils/status'
import { DeliveryTypes } from '../../../../types/logistics/delivery-orders'

import Colors from '../../../../styles/colors'

const styles = {
    avatar: {
        float: 'left',
        marginRight: 8,
    },
    addButton: {
        position: 'absolute',
        right: 20,
        bottom: -35,
        zIndex: 2,
    },
    iconButton: {
        padding: '0 12px',
    },
    container: {
        width: '100%',
    },
    expansionPanel: {
        margin: 0,
        width: '100%',
        boxShadow: 'none',
    },
    expansionPanelDetails: {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        flexFlow: 'column',
        padding: '8px 0 8px',
    },
    expansionPanelActions: {
        width: '100%',
        display: 'block',
    },
    expansionPanelAction: {
        marginRight: 30,
        textTransform: 'none',
        paddingLeft: 8,
        paddingRight: 8,
    },
    title: {
        height: 40,
        paddingLeft: 24,
        lineHeight: '40px',
        display: 'inline-block',
        color: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Roboto',
        fontWeight: 'bolder',
        textTransform: 'capitalize',
    },
    actionRight: {
        float: 'right',
        lineHeight: '36px',
    },
    sourceCard: {
        marginBottom: 40,
        position: 'relative',
        overflow: 'visible',
    },
    destination: {
        float: 'right',
        lineHeight: '36px',
        paddingRight: 20,
    },
}

class Outbound extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadedShipment: {},
            postponeShipment: null,
            postponeOpen: false,
            trackingOpen: false,
            trackingShipment: null,
            createShipmentOpen: false,
            createShipmentFrom: null,
            cancelShipment: null,
            cancelOpen: false,
        }

        autoBind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.downloadedShipment &&
            prevState.downloadedShipment._id &&
            nextProps.packing_slip.shipment_id === prevState.downloadedShipment._id) {
            return Outbound.downloadPackingSlip(nextProps.packing_slip, nextProps, prevState)
        }
        return prevState
    }

    static downloadPackingSlip(data, props, state) {
        if (data.error) {
            return {
                noPackingSlipFound: true,
                downloadedShipment: {},
            }
        } else {
            const shipment = state.downloadedShipment
            downloadBase64AsPdf(data.blob,
                `${props.mage_id}_${shipment.supplier.name || shipment.source}_${shipment.position}`)
            return {
                downloadedShipment: {},
            }
        }
    }

    handlePackingSlipClick(s) {
        this.setState({
            downloadedShipment: s,
        }, () => {
            this.props.getPackingSlip(s._id, s.source)
        })
    }

    handleViewLocationsClick(shipment) {
        this.setState({
            deliveryOrderOpen: true,
            deliveryOrderShipment: shipment,
        })
    }

    getDownloadAction(s) {
        return (
            <Tooltip key="download" title="Download packing slip">
                <Button
                    color="primary"
                    onClick={() => {
                        this.handlePackingSlipClick(s)
                    }}
                    style={styles.expansionPanelAction}
                >
                    <CloudDownloadIcon />&nbsp;Download
                </Button>
            </Tooltip>
        )
    }

    getTrackAndTraceUrl(shipment) {
        const { carrier_slug, tracking_code, tracking_postal_code } = shipment.shipping_label
        const { country, postal_code } = shipment.shipping_address
        const standard = `https://jouw.postnl.nl/track-and-trace/${tracking_code}-${country}-${postal_code}`
        switch (`${carrier_slug}:${country.toLowerCase()}`) {
            case 'postnl-3s:be':
                return `https://jouw.postnl.be/track-and-trace/${tracking_code}-${country}-${postal_code}`
            case 'postnl-3s:de':
                return 'https://www.internationalparceltracking.com/Main.aspx#/track/' +
                    `${tracking_code}/${country}/${tracking_postal_code}`
            default: return standard
        }
    }

    getTrackingAction(s, being_processed) {
        const tracking_code = s.shipping_label.tracking_code
        const cannot_edit_or_add = !['requested', 'pending', 'info_received', 'pre_transit'].includes(s.status)
        if (tracking_code) {
            const url = this.getTrackAndTraceUrl(s)
            return (
                <div key="edit_tracking" style={styles.actionRight}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{tracking_code}</a>
                    <IconButton
                        style={styles.iconButton}
                        disabled={being_processed || cannot_edit_or_add}
                        onClick={() => { this.handleEditTrackingClick(s) }}
                        aria-label="edit"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
            )
        } else {
            return (
                <div key="add_tracking" style={styles.actionRight}>
                    <Tooltip key="add-tracking" title="Add tracking">
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handleEditTrackingClick(s)
                            }}
                            disabled={cannot_edit_or_add}
                            style={styles.expansionPanelAction}
                        >
                            <TrackChangesIcon />&nbsp;Add tracking
                        </Button>
                    </Tooltip>
                </div>
            )
        }
    }

    getPostponeAction(shipment) {
        return shipment.status !== 'cancelled' && (
            <Tooltip key="postpone" title="Postpone item or shipment">
                <Button
                    onClick={() => {
                        this.setState({
                            postponeOpen: true,
                            postponeShipment: shipment,
                        })
                    }}
                    style={{ color: Colors.yellow900, ...styles.expansionPanelAction }}
                >
                    <DateRangeIcon />&nbsp;Postpone
                </Button>
            </Tooltip>
        )
    }

    getMarkAsDeliveredAction(shipment, being_processed, statuses) {
        const canMarkAsDelivered = (this.context.features || {}).canMarkShipmentsAsDelivered
        if (statuses && statuses.length > 0 && !statuses.includes(shipment.status)) {
            return null
        }
        return !shipment.shipping_label.tracking_code || !canMarkAsDelivered || being_processed ? null : (
            <Tooltip key="mark-as-delivered" title="Mark shipment as delivered">
                <Button
                    onClick={() => {
                        this.props.markShipmentAsDelivered(shipment, this.props.email)
                    }}
                    style={{ color: Colors.secondaryColor100, ...styles.expansionPanelAction }}
                >
                    <LocalShippingIcon />&nbsp;Mark as delivered
                </Button>
            </Tooltip>
        )
    }

    getViewDeliveryStatusAction(shipment) {
        return (
            <Tooltip key="view-delivery-status" title="View delivery status">
                <Button
                    onClick={() => {
                        this.handleViewLocationsClick(shipment)
                    }}
                    color="primary"
                    style={{ ...styles.expansionPanelAction, float: 'right' }}
                >
                    <AllInboxIcon />&nbsp;View delivery status
                </Button>
            </Tooltip>
        )
    }

    getAllActions(shipment, being_processed) {
        if (shipment.shipping_address.type === DeliveryTypes.CUSTOMER) {
            return [
                this.getDownloadAction(shipment),
                this.getPostponeAction(shipment),
                being_processed ? null : (
                    <Tooltip key="cancel" title="Cancel item or shipment">
                        <Button
                            onClick={() => {
                                this.setState({
                                    cancelOpen: true,
                                    cancelShipment: shipment,
                                })
                            }}
                            style={{ color: Colors.red100, ...styles.expansionPanelAction }}
                        >
                            <LocalShippingIcon />&nbsp;Cancel
                        </Button>
                    </Tooltip>
                ),
                this.getMarkAsDeliveredAction(shipment, being_processed),
                this.getTrackingAction(shipment, being_processed),
            ]
        }
        return [
            this.getDownloadAction(shipment),
            this.getPostponeAction(shipment),
            being_processed ? null : (
                <Tooltip key="cancel" title="Cancel item or shipment">
                    <Button
                        onClick={() => {
                            this.setState({
                                cancelOpen: true,
                                cancelShipment: shipment,
                            })
                        }}
                        style={{ color: Colors.red100, ...styles.expansionPanelAction }}
                    >
                        <LocalShippingIcon />&nbsp;Cancel
                    </Button>
                </Tooltip>
            ),
            this.getViewDeliveryStatusAction(shipment, being_processed),
        ]
    }

    getFooterActions(s, being_processed) {
        if (['requested', 'pending', 'info_received'].includes(s.status)) {
            return this.getAllActions(s, being_processed)
        } else {
            return [
                this.getDownloadAction(s),
                this.getPostponeAction(s),
                this.getMarkAsDeliveredAction(s, being_processed, ['pre_transit']),
                this.getTrackingAction(s, being_processed),
            ]
        }
    }

    mapSourceShipments(s) {
        // Don't display shipments to manufacturer any more. They are no longer used.
        if (s.shipping_address?.type === 'manufacturer') {
            return []
        }
        const processing_shipments = this.props.compartments.reduce((acc, compartment) => {
            if (compartment.packing_item) {
                acc[compartment.packing_item.shipment_id] = true
            }
            return acc
        }, {})
        const being_processed = !!processing_shipments[s._id]
        const footerActions = this.getFooterActions(s, being_processed)
        const statusIcon = ShipmentStatus[being_processed ? 'being_processed' : s.status]
        const open = Object.values(this.props.shipments).reduce((acc, sh) => acc + sh.length, 0) <= 4
        const addressType = s.shipping_address.type
        const has_promos = s.line_items.some(li => !!li.is_promo)
        const showSource = s.line_items.some(li => !!li.type)
        if (showSource) {
            s.line_items = s.line_items.sort((a, b) => {
                if (a.type > b.type) {
                    return 1
                } else if (a.type < b.type) {
                    return -1
                }
                return 0
            })
        }

        const primaryText = s.status !== 'delivered'
            ? <span><b>Expected delivery date:</b> {moment(s.estimated_for).format('DD-MM-YYYY')}</span>
            : (
                <span>
                    <b>Delivered at:</b> {moment(s.delivered_at).format('DD-MM-YYYY HH:mm')}
                    <span style={{ fontWeight: 'normal' }}>
                        &nbsp; (was expected on: {moment(s.estimated_for).format('DD-MM-YYYY')})
                    </span>
                </span>
            )
        const Icon = statusIcon.icon
        return [
            <ExpansionPanel key={`shipment-panel-${s._id}`} defaultExpanded={open} style={styles.expansionPanel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`shipment-content-${s._id}`}
                    id={`shipment-header-${s._id}`}
                >
                    <Avatar style={{ ...styles.avatar, backgroundColor: statusIcon.color }}>
                        <Icon />
                    </Avatar>
                    <span>
                        {primaryText} <br />
                        <b>Current status:</b> {being_processed ? 'In outbound process' : s.status.replace(/_/g, ' ')}
                    </span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={styles.expansionPanelDetails}>
                    <Table size="small">
                        <TableHead>
                            {has_promos && <TableCell />}
                            <TableCell>Name</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Status</TableCell>
                            {showSource && <TableCell>Source</TableCell>}
                        </TableHead>
                        <TableBody>
                            {
                                s.line_items.length === 0
                                    ? <TableRow><TableCell colSpan="5">Empty shipment</TableCell></TableRow>
                                    : s.line_items.map(li => {
                                        if (li.status === 'postponed') {
                                            return null
                                        }
                                        return (
                                            <TableRow key={li.product.sku + li.status}>
                                                {has_promos && (
                                                    <TableCell>
                                                        {li.is_promo && (
                                                            <Tooltip title="Giveaway item">
                                                                <MoneyOffIcon color="secondary" />
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    {li.product.name}
                                                </TableCell>
                                                <TableCell>{li.product.sku}</TableCell>
                                                <TableCell>{li.product.ean}</TableCell>
                                                <TableCell>{li.quantity}</TableCell>
                                                <TableCell>{li.status}</TableCell>
                                                {showSource && <TableCell>{li.type}</TableCell>}
                                            </TableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </ExpansionPanelDetails>
                <ExpansionPanelActions key={`shipment-actions-${s._id}`} style={styles.expansionPanelActions}>
                    {footerActions}
                    <span style={styles.destination}>
                        Destination: <strong>{addressType}</strong>
                    </span>
                </ExpansionPanelActions>
                <Divider />
            </ExpansionPanel>,
        ]
    }

    handleAddShipmentClick(shipments) {
        const shipment = shipments.find(s => s.shipping_address.type !== 'manufacturer')
        this.setState({
            createShipmentOpen: true,
            createShipmentFrom: shipment,
        })
    }

    handleEditTrackingClick(shipment) {
        this.setState({
            trackingOpen: true,
            trackingShipment: shipment,
        })
    }

    handleDialogClose() {
        this.setState({
            deliveryOrderOpen: false,
            deliveryOrderShipment: null,
            createShipmentOpen: false,
            createShipmentFrom: null,
            trackingOpen: false,
            trackingShipment: {},
            postponeOpen: false,
            postponeShipment: {},
            cancelOpen: false,
            cancelShipment: {},
        })
    }

    handleCreateShipmentSubmit(estimated_for, shipment) {
        this.props.createShipment({
            ...shipment,
            shipping_address: this.props.currentShippingAddress || shipment.shipping_address,
            line_items: [],
            estimated_for,
        }, this.props.email)
        this.handleDialogClose()
    }

    handlePostponeSubmit(shipment, postpone_state) {
        const data = {
            order_id: shipment.order_id,
            fulfilled: postpone_state.fulfilled,
            reason: postpone_state.reason,
            estimated_for: postpone_state.estimatedFor,
            shipment_id: shipment._id,
        }
        const onlyOneItem = _.filter(shipment.line_items, li => {
            return li.status !== 'cancelled' && li.status !== 'postponed' && !li.is_promo
        }).length === 1
        const fullQuantitySelected = _.find(shipment.line_items, li => {
            return li.product.sku === postpone_state.lineItem.product.sku
                && +li.quantity === +postpone_state.quantity
                && li.status !== 'cancelled'
                && li.status !== 'postponed'
        })
        if (postpone_state.lineItem.product.name !== 'All' &&
            (!onlyOneItem || (onlyOneItem && !fullQuantitySelected) || data.fulfilled)) {
            data.line_item = {
                product: {
                    sku: postpone_state.lineItem.product.sku,
                    supplier: {
                        mage_id: postpone_state.lineItem.product.supplier.mage_id,
                    },
                },
                quantity: postpone_state.quantity,
            }
        }
        this.props.postponeShipment(data)
        this.handleDialogClose()
    }

    handleCancelSubmit(shipment, cancellation_state) {
        this.handleDialogClose()
        const data = {
            order_id: shipment.order_id,
            fulfillment_id: shipment.fulfillment_id,
            note: `${cancellation_state.reason} - (${this.props.email})`,
            author: 'servicedesk',
            shipment_id: shipment._id,
        }
        if (cancellation_state.lineItem.product.name !== 'All') {
            data.line_item = {
                product: {
                    sku: cancellation_state.lineItem.product.sku,
                },
                quantity: cancellation_state.quantity,
            }
        }
        this.props.submitCancellation(data)
    }

    handleTrackingSubmit(shipment, tracking_code, slug, generate_new_tracking_code) {
        this.props.updateTracking({
            _id: shipment._id,
            order_id: shipment.order_id,
            shipping_label: {
                ...shipment.shipping_label,
                tracking_code,
                active_tracking: false,
                carrier_slug: slug || shipment.shipping_label.carrier_slug || shipment.shipping_label.slug,
            },
            generate_new_tracking_code,
        })
        this.handleDialogClose()
    }

    render() {
        const postponeDialog = (
            <PostponeDialog
                onRequestClose={this.handleDialogClose}
                onClosePostponeDialog={this.handleDialogClose}
                onPostponeSubmit={this.handlePostponeSubmit}
                shipment={this.state.postponeShipment}
                open={this.state.postponeOpen}
            />
        )

        const cancelDialog = (
            <CancelDialog
                onRequestClose={this.handleDialogClose}
                onCloseCancelDialog={this.handleDialogClose}
                onCancelSubmit={this.handleCancelSubmit}
                shipment={this.state.cancelShipment}
                open={this.state.cancelOpen}
            />
        )

        const trackingDialog = (
            <TrackingDialog
                onRequestClose={this.handleDialogClose}
                onClose={this.handleDialogClose}
                onTrackingSubmit={this.handleTrackingSubmit}
                shipment={this.state.trackingShipment}
                open={this.state.trackingOpen}
            />
        )

        const createShipmentDialog = (
            <CreateShipmentDialog
                reference={this.state.createShipmentFrom}
                open={this.state.createShipmentOpen}
                onSubmit={this.handleCreateShipmentSubmit}
                onClose={this.handleDialogClose}
            />
        )
        const deliveryOrderDialog = this.state.deliveryOrderOpen && (
            <DeliveryOrderDialog
                shipment_ref={this.state.deliveryOrderShipment && this.state.deliveryOrderShipment._id}
                onClose={this.handleDialogClose}
            />
        )
        return (
            <div style={styles.container}>
                {
                    Object.values(this.props.shipments).map(shipments => {
                        const source = shipments[0].source
                        if (source !== 'dropship' && source !== 'personalisation') {
                            shipments = _.orderBy(shipments,
                                [ 'shipping_address.type', 'estimated_for' ],
                                [ 'desc', 'asc' ]
                            )
                            return (
                                <Card key={`${source}`} style={styles.sourceCard}>
                                    <Typography style={styles.title}>
                                        {source}
                                    </Typography>
                                    {
                                        shipments.map(this.mapSourceShipments)
                                    }
                                    <Zoom in={true} timeout={200} unmountOnExit>
                                        <Tooltip style={styles.addButton} title="Add empty shipment">
                                            <Fab
                                                color="primary"
                                                onClick={() => { this.handleAddShipmentClick(shipments) }}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Tooltip>
                                    </Zoom>
                                    <Divider />
                                </Card>
                            )
                        } else {
                            const grouped_shipments = _.groupBy(shipments, 'fulfillment_id')
                            return Object.values(grouped_shipments).map(s => {
                                s = _.orderBy(s,
                                    [ 'shipping_address.type', 'estimated_for' ],
                                    [ 'desc', 'asc' ]
                                )
                                const name = s[0].supplier.name || '' // there should be only one per fulfillment_id

                                return (
                                    <Card key={`${source}${name}`} style={styles.sourceCard}>
                                        <Typography style={styles.title}>
                                            {`${source}${name ? ` - ${name}` : ''}`}
                                        </Typography>
                                        {
                                            s.map(this.mapSourceShipments)
                                        }
                                        <Zoom in={true} timeout={200} unmountOnExit>
                                            <Tooltip style={styles.addButton} title="Add empty shipment">
                                                <Fab
                                                    color="primary"
                                                    onClick={() => { this.handleAddShipmentClick(shipments) }}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Tooltip>
                                        </Zoom>
                                        <Divider />
                                    </Card>
                                )
                            })
                        }
                    })
                }
                {postponeDialog}
                {cancelDialog}
                {trackingDialog}
                {createShipmentDialog}
                {deliveryOrderDialog}
            </div>
        )
    }
}

Outbound.contextTypes = {
    features: PropTypes.object,
}

Outbound.propTypes = {
    packing_slip: PropTypes.object,
    shipments: PropTypes.array,
    compartments: PropTypes.array,
    mage_id: PropTypes.string,
    currentShippingAddress: PropTypes.object,
    email: PropTypes.string,
    getPackingSlip: PropTypes.func,
    markShipmentAsDelivered: PropTypes.func,
    createShipment: PropTypes.func,
    postponeShipment: PropTypes.func,
    submitCancellation: PropTypes.func,
    updateTracking: PropTypes.func,
}

export default connect(({ packing_slip, compartments }) => {
    return { packing_slip, compartments }
}, dispatch => {
    return bindActionCreators({
        getPackingSlip,
        markShipmentAsDelivered,
        postponeShipment,
        submitCancellation,
        updateTracking,
        createShipment,
    }, dispatch)
})(Outbound)
