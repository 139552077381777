import store from '../../store'
import {push} from 'react-router-redux'

import {refreshPickList, refreshPickLists} from '../../actions/pick_lists'
import {showNotification} from '../../actions/notifications'

import {listPickLists} from '../../commands/sockets/pick_lists'

export default function initPickListsSockets(socket) {
    socket.on('pick-list', data => {
        store.dispatch(refreshPickList(data))
    })

    socket.on('pick-lists', data => {
        store.dispatch(refreshPickLists(data))
    })

    socket.on('pick-list/created', (data = {}) => {
        store.dispatch(push(`/logistics/picking/pick-lists/${data.increment_id}`))
        store.dispatch(showNotification({
            message: `Pick lijst is ${data.increment_id} is nu aangemaakt, voeg picks toe.`,
        }))
    })

    socket.on('pick-list/picks-added', (data = {}) => {
        store.dispatch(refreshPickList(data))
        store.dispatch(showNotification({
            message: 'Pick is toegevoegd',
        }))
    })

    socket.on('pick-list/picks-removed', (data = {}) => {
        store.dispatch(refreshPickList(data))
        store.dispatch(showNotification({
            message: 'Pick is verwijderd',
        }))
    })

    socket.on('pick-list/finalised', (data = {}) => {
        store.dispatch(refreshPickList(data))
        store.dispatch(showNotification({
            message: 'Pick list is nu definitief en klaar op gepicked te worden',
        }))
    })

    socket.on('pick-list/revoked', (data = {}) => {
        store.dispatch(refreshPickList(data))
        store.dispatch(showNotification({
            message: 'De pick list is gerevoked en kan niet meer gepicked worden.',
        }))
    })

    socket.on('pick-lists/generated', data => {
        store.dispatch(listPickLists({limit: 20, page: 1}))
        const pick_lists = data.map(d => d.increment_id)
        const first = pick_lists[0]
        const last = pick_lists.length > 1 ? pick_lists[pick_lists.length - 1] : null
        store.dispatch(showNotification({
            message: `Pick lists ${first}${last ? ` - ${last}` : ''} zijn gegenereerd`,
            duration: 5000,
        }))
    })
}
