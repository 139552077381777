import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'

import PersonalisationLineItem from './PersonalisationLineItem'
import PersonalisationConfiguration from './PersonalisationConfiguration'

const style = {
    content: {
        display: 'flex',
        height: 'auto',
    },
    leftContent: {
        width: '50%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
    },
    card: {
        marginBottom: 24,
    },
    avatar: {
        marginLeft: 8,
    },
    toolbar: {
        padding: 24,
        backgroundColor: '',
        borderTop: '1px solid #ccc',
    },
    toggle: {
        width: 30,
    },
    table: {
        width: '100%',
        padding: 16,
        borderCollapse: 'separate',
        borderSpacing: 8,
    },
    status: {
        padding: 24,
    },
    borderBottom: {
        borderBottom: '1px solid #ccc',
    },
    borderRight: {
        borderRight: '1px solid #ccc',
    },
}

function PersonalisationLineItems(props) {
    const typed_order = props.typed_order
    const personalisation = props.personalisation
    return (
        <Paper style={style.card}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <table style={style.table}>
                        <thead>
                            <tr>
                                <th width="63%">Name</th>
                                <th width="27%">SKU</th>
                                <th width="10%">Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typed_order.line_items.map((li, index) => (
                                    <PersonalisationLineItem key={li.product.sku + index} line_item={li} />
                                ))
                            }
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={6}>
                    <PersonalisationConfiguration
                        order_number={typed_order.mage_id}
                        channel={typed_order.channel}
                        personalisation={personalisation}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

PersonalisationLineItems.propTypes = {
    typed_order: PropTypes.object.isRequired,
    personalisation: PropTypes.object.isRequired,
    onTogglePersonalisationStatus: PropTypes.func,
}
PersonalisationLineItems.contextTypes = {
    colors: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
}

export default PersonalisationLineItems
