import React from 'react'
import PropTypes from 'prop-types'

import Setting from './Setting'
import { changeFeatureStatus } from '../../../commands/sockets/settings'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Create an array function to check if the object values are only null
// This can happen when no roles are assigned to a user
function objectOnlyContainsNulls(array) {
    return array.every(v => !v)
}

function handleChange(name, value) {
    changeFeatureStatus(name, value)
}

function formatFeatureText(text) {
    const textWithSpacesBeforeCapitalLetter = text.replace(/([A-Z])/g, ' $1').trim()
    return textWithSpacesBeforeCapitalLetter
}

function renderSettings(props) {
    return props.feature_toggles
        .filter(feature => {
            return feature.department === props.department
        })
        .map(feature => {
            if (
                !props.featuresByRole ||
                typeof props.featuresByRole[feature.name] === 'undefined'
            ) {
                return null
            }
            //Redis saves booleans as strings, hence the string is converted to boolean
            if (feature.value === 'true' || feature.value === 'false') {
                feature.value = (feature.value === 'true')
            }
            return (
                <Setting
                    key={feature.name}
                    value={feature.value}
                    onChange={handleChange}
                    name={feature.name}
                    variant={feature.variant}
                    color="primary"
                    labelPlacement="start"
                    label={formatFeatureText(feature.name)}
                    authenticated={props.featuresByRole[feature.name]}
                    description={feature.description}
                />
            )
        })
}

function renderSettingsFromAllDepartments(props) {
    const featuresFromDepartment = renderSettings(props)
    if (objectOnlyContainsNulls(featuresFromDepartment)) {
        return null
    }
    return (
        // It says it doesn't have prop validation even tough it is cleary defined in PropTypes
        // eslint-disable-next-line react/prop-types
        <div key={props.department}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography component={'span'} variant={'body2'}><h3>{
                        // eslint-disable-next-line react/prop-types
                        props.department
                    }</h3></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography component={'span'} variant={'body2'}>
                        {featuresFromDepartment}
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>)
}

function SettingDepartment(props) {
    return (
        renderSettingsFromAllDepartments(props)
    )
}

SettingDepartment.propTypes = {
    featuresByRole: PropTypes.object,
    department: PropTypes.string,
    feature_toggles: PropTypes.array,
}

export default SettingDepartment
