import socket from './../socket'
import listeners from '../listeners/sockets/purchase_orders.js'
import _ from 'lodash'

import { Statuses } from '../app/types/purchase/purchase_order'

listeners(socket)

export function purchase_orders(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'REFRESH_PURCHASE_ORDERS':
            return action.purchase_orders

        default:
            return state
    }
}

export function purchase_order(state = {}, action) {
    state.pdf = undefined
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'CREATE_PURCHASE_ORDER':
            socket.emit('purchase-order/create', action.purchase_order)
            return state

        case 'GET_PURCHASE_ORDER':
            socket.emit('purchase-order/get', { _id: action._id })
            return state

        case 'MARK_PURCHASE_ORDER_AS_SUBMITTED':
            socket.emit('purchase-order/submit', { _id: action._id })
            return state

        case 'GET_PURCHASE_ORDER_AS_PDF':
            socket.emit('purchase-order/pdf/get', { _id: action._id })
            return state

        case 'ADD_PURCHASE_ORDER_NOTE':
            socket.emit('purchase-order/note/add', action.data)
            return state

        case 'ADD_PURCHASE_ORDER_ITEM':
            socket.emit('purchase-order/line-item/add', action.data)
            return state

        case 'UPDATE_PURCHASE_ORDER_ITEMS':
            socket.emit('purchase-order/line-items/update', action.data)
            return state

        case 'REMOVE_PURCHASE_ORDER_ITEMS':
            socket.emit('purchase-order/line-items/remove', action.data)
            return state

        case 'REFRESH_PURCHASE_ORDER_PDF':
            return _.merge({ pdf: action.pdf }, state)

        case 'REFRESH_PURCHASE_ORDER':
            const order = action.purchase_order
            return {
                _id: order._id,
                order_id: order.order_id,
                created_at: order.created_at,
                processed_at: order.processed_at,
                finalised_at: order.finalised_at,
                supplier: order.supplier,
                notes: order.notes,
                pricing: order.pricing,
                type: order.type,
                custom_ref: order.custom_ref,
                initial_expected_delivery_date: order.initial_expected_delivery_date,
                line_items: order.status === Statuses.DRAFT
                    ? order.line_items
                    : _.sortBy(order.line_items, 'supplier_sku'),
                status: order.status,
            }

        default:
            return state
    }
}
