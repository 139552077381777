import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Paper, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import red from '@material-ui/core/colors/red'

import {getInboundCandidatesBySku} from '../../../../commands/sockets/candidates'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

const styles = {
    paper: {width: '100%'},
    cancelledCandidate: {backgroundColor: red[200]},
}

class ProductInboundCandidates extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.props.getInboundCandidatesBySku(this.props.variant.supplier_sku)
    }

    getCandidateRows() {
        if (this.props.candidates.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={4}>Geen openstaande inbound</TableCell>
                </TableRow>
            )
        }
        const reduced_candidates = this.props.candidates.reduce((acc, item) => {
            if (!item.purchase_id) { return acc }
            const found = acc.find(i => i.purchase_id === item.purchase_id)
            if (found) {
                found.quantity++
            } else {
                acc.push({...item, quantity: item.quantity || 1})
            }
            return acc
        }, [])
        return reduced_candidates.sort((a, b) => a.created_at > b.created_at ? -1 : 1).map(candidate => (
            <TableRow
                key={candidate.purchase_id || candidate.rma_id || candidate.transport_id}
                style={candidate.status === 'cancelled' ? styles.cancelledCandidate : null}
            >
                <TableCell>{candidate.name}</TableCell>
                <TableCell>{candidate.sku}</TableCell>
                <TableCell>{candidate.ean}</TableCell>
                <TableCell>{candidate.quantity}</TableCell>
                <TableCell>{candidate.purchase_id}</TableCell>
            </TableRow>
        ))
    }

    render() {
        return (
            <Paper style={styles.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Purchase order</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getCandidateRows()}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

ProductInboundCandidates.contextTypes = {}
ProductInboundCandidates.propTypes = {
    variant: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    getInboundCandidatesBySku: PropTypes.func.isRequired,
}
ProductInboundCandidates.defaultProps = {
    candidates: [],
}


export default connect(({candidates}) => {
    return {
        candidates,
    }
}, dispatch => {
    const actions = {getInboundCandidatesBySku}
    return bindActionCreators(actions, dispatch)
})(ProductInboundCandidates)
