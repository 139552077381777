import socket from './../socket'
import listeners from '../listeners/sockets/labels'

listeners(socket)

export function label(state = [], action) {
    switch (action.type) {
        case 'GET_LABEL':
            socket.emit('label/get', {
                shipment_id: action.shipment_id,
                pdf: action.pdf || false,
            })
            return state

        case 'CREATE_LABELS':
            socket.emit('labels/create', { ...action })
            return state

        case 'REFRESH_LABEL':
            return action.label

        default:
            return state
    }
}

export function labels(state = [], action) {
    switch (action.type) {
        case 'REFRESH_LABELS':
            return action.labels

        default:
            return state
    }
}
