import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {SelectField, MenuItem} from 'material-ui'

const sourceOptions = [{
    text: 'All',
    value: 'all',
}, {
    text: 'Warehouse',
    value: 'warehouse',
}, {
    text: 'Crossdock',
    value: 'crossdock',
}, {
    text: 'Dropship',
    value: 'dropship',
}, {
    text: 'Personalisation',
    value: 'personalisation',
}]

class SourceFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const options = ['all', ...this.props.sources].map(source => {
            return sourceOptions.find(option => option.value === source)
        })
        return (
            <SelectField
                style={this.props.style}
                onChange={this.props.onChange}
                value={this.props.value}
                floatingLabelText={this.props.label}
            >
                {
                    options.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            primaryText={option.text}
                        />
                    ))
                }
            </SelectField>
        )
    }
}

SourceFilter.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    sources: PropTypes.array.isRequired,
    style: PropTypes.object,
}
SourceFilter.defaultProps = {
    style: {},
    value: 'all',
    label: 'Select source',
}

export default SourceFilter
