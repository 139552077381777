import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {FlatButton} from 'material-ui'

class Pagination extends Component {

    handleNextClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.onNextPageClick()
    }

    handlePreviousClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.onPreviousPageClick()
    }

    renderPages(pages, current) {
        if (!pages) {
            return (<span>&nbsp;{current}&nbsp;</span>)
        }
        return (<span>&nbsp;{current}/{pages}</span>)
    }

    render() {
        let hasNextPage = false
        if (_.has(this.props, 'pagination.total_pages')) {
            hasNextPage = +this.props.page < +this.props.pagination.total_pages
        } else {
            hasNextPage = this.props.items instanceof Array && this.props.items.length === this.props.limit
        }
        const hasPreviousPage = +this.props.page > 1

        const previousPageButton = (
            <FlatButton
                disabled={!hasPreviousPage}
                label="Previous page"
                onClick={this.handlePreviousClick.bind(this)}
            />
        )

        const nextPageButton = (
            <FlatButton
                disabled={!hasNextPage}
                label="Next page"
                onClick={this.handleNextClick.bind(this)}
            />
        )

        const pages = this.renderPages(_.get(this.props, 'pagination.total_pages', 0), this.props.page)

        return (
            <div>
                {previousPageButton}
                {pages}
                {nextPageButton}
            </div>
        )
    }
}

Pagination.propTypes = {
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    pagination: PropTypes.object,
    onPreviousPageClick: PropTypes.func.isRequired,
    onNextPageClick: PropTypes.func.isRequired,
}

export default Pagination
