import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'


import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { searchLocations, clearLocation } from '../../../../../commands/sockets/locations'


const initialState = {
    stockLocationsToClear: [],
}

class ClearStockLocationsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }
        this.timeout = 0

        autoBind(this)
    }

    handleLocationToggled(e) {
        if (e.target.checked) {
            this.setState({ stockLocationsToClear: [e.target.value, ...this.state.stockLocationsToClear] })
        } else {
            this.setState({
                stockLocationsToClear: this.state.stockLocationsToClear.filter(location =>
                    location !== e.target.value
                ),
            })
        }
    }

    handleSubmit() {
        this.state.stockLocationsToClear.forEach(location => {
            clearLocation(location)
        })
        this.props.onClose()
    }

    getOrderIdFromShipment() {
        return this.props.shipment.fulfillment_id.split('_').shift()
    }

    componentDidMount() {
        const orderNumber = this.getOrderIdFromShipment()
        const prefix = this.props.shipment.personalisation === 'printing'
            ? 'PP' // printing
            : this.props.shipment.personalisation === 'embroidery'
                ? 'PE' // embroidery
                : 'P' // legacy
        this.props.searchLocations({ value: `/${prefix}${orderNumber}.`, limit: 200 })
    }

    render() {
        const canSubmit = !this.state.stockLocationsToClear.length

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>Clear stock locations</DialogTitle>
                <DialogContent>
                    <Typography>Please select the stock locations that should be cleared.</Typography>
                    <FormControl margin="dense" fullWidth>
                        {this.props.locations.length ? (
                            this.props.locations.map(location => (
                                <FormControlLabel
                                    key={location.location}
                                    label={location.location}
                                    control={
                                        <Checkbox
                                            value={location.location}
                                            checked={this.state.stockLocationsToClear.indexOf(location.location) >= 0}
                                            onChange={this.handleLocationToggled}
                                        />
                                    }
                                />
                            ))
                        ) : (
                            <Typography>Couldn't find any locations related to this order.</Typography>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={canSubmit}
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ClearStockLocationsDialog.propTypes = {
    shipment: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    searchLocations: PropTypes.func,
    locations: PropTypes.array,
}

ClearStockLocationsDialog.defaultProps = {
    locations: [],
    open: false,
}

export default connect(({ shipment, locations }) => {
    return { shipment, locations }
}, dispatch => {
    return bindActionCreators({
        searchLocations,
    }, dispatch)
})(ClearStockLocationsDialog)
