import React, {useState} from 'react'
import PropTypes from 'prop-types'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import { Divider, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'


const AUTHENTICATION_STATES = {
    READWRITE: 'readwrite',
    READ: 'readonly',
}

/*
The current implementation to check which roles have what access for features uses auth0 metadata
It will only work with this format in metadata:
  "features": {
    ...
    "departmentOne": {
      "featureOne": "readwrite",
      "featureTwo": "readonly",
    },
    "departmentTwo": {
      "featureOne": "readonly"
    },
    ...
  },
  "roles": [
    "departmentOne"
    ...
  ]
*/

const featuresBusinessRules = {
    scheduledRefund: {
        title: 'Wanneer wordt een creditmemo automatisch terugbetaald?',
        rules: {
            minimumAmount: 'Het bedrag om terug te betalen is minstens 1 cent',
            maximumAmount: 'Het bedrag om terug te betalen is minder dan 1000 euro',
            manual: 'De creditmemo is niet van het type \'Manual\'',
            services: 'Automatische terugbetalingen gelden alleen voor MultiSafePay en Billink betaalmethodes',
            deadline: 'Creditmemo\'s worden alleen terugbetaald als de refund deadline overschreden is',
            status: 'De status van een creditmemo moet \'invoiced\' zijn om automatisch terugbetaald te worden',
            period: 'De automatische terugbetalingen gelden alleen voor creditmemo\'s die maximaal 30 dagen oud zijn',
        },
    },
    scheduledDraftAccept: {
        title: 'Wanneer wordt een creditmemo automatisch geaccepteerd?',
        rules: {
            minimumAmount: 'Het bedrag om terug te betalen is minstens 1 cent',
            maximumAmount: 'Het bedrag om terug te betalen is minder dan 1000 euro',
            manual: 'De creditmemo is niet van het type \'Manual\'',
            services: 'Automatisch accepteren geldt voor alle betaalmethodes behalve \'purchaseorder\'',
            age: 'Creditmemo\'s worden alleen automatisch geaccepteerd als ze minimaal 24 uur oud zijn',
            status: 'De status van een creditmemo moet \'draft\' zijn om automatisch geaccepteerd te worden',
            period: 'De automatische acceptatie geldt alleen voor creditmemo\'s die maximaal 30 dagen oud zijn',
        },
    },
}

const style = {
    switch: {
    },
    text: {
        flexDirection: 'row',
    },
    helperText: {
        marginBottom: '25px',
        textTransform: 'none',
    },
    fab: {
    },
    rules: {
        paddingLeft: '12px',
    },
}

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}))

function Setting(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [textVariantValue, setTextVariantValue] = useState(null)
    const [newOptionValue, setNewOptionValue] = useState('')
    const [removeOptionValue, setRemoveOptionValue] = useState(null)
    const popoverOpen = Boolean(anchorEl)

    function handleClickOpen(new_action) {
        if (props.authenticated === AUTHENTICATION_STATES.READWRITE) {
            setOpen(true)
        }
        if (props.variant === 'list') {
            setAction(new_action)
        }
    }

    function handleClose() {
        setOpen(false)
    }

    function handlePopoverOpen(event) {
        setAnchorEl(event.currentTarget)
    }

    function handlePopoverClose() {
        setAnchorEl(null)
    }

    function handleChange() {
        switch (props.variant) {
            case 'toggle':
                props.onChange(props.name, !props.value)
                break

            case 'text':
                if (!textVariantValue) {
                    break
                }
                props.onChange(props.name, textVariantValue)
                break

            case 'list':
                if (action === 'add') {
                    if (!newOptionValue) {
                        break
                    }
                    props.onChange(props.name, `${props.value}|${newOptionValue}`)
                    setNewOptionValue('')
                } else if (action === 'remove') {
                    const newValue = props.value.split('|').filter(v => v !== removeOptionValue).join('|')
                    props.onChange(props.name, newValue)
                    setRemoveOptionValue(null)
                }
                break

            default:
                break
        }
    }

    function handleAccept() {
        handleChange()
        handleClose()
    }

    function handleTextVariantChange(event) {
        setTextVariantValue(event.target.value)
    }

    function handleNewOptionChange(event) {
        setNewOptionValue(event.target.value.replace('|', ''))
    }

    function getFeatureRules(featureRules) {
        return (
            <ul style={style.rules}>
                {featureRules.map(rule => {
                    return (
                        <li key={rule}>{rule}</li>
                    )
                })}
            </ul>
        )
    }

    function getPopover() {
        if (typeof featuresBusinessRules[props.name] !== 'undefined') {
            const featureRules = featuresBusinessRules[props.name]
            return (
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <h5>{featureRules.title}</h5>
                    {getFeatureRules(Object.values(featureRules.rules))}
                </Popover>
            )
        }
        return ''
    }

    function renderInputVariant() {
        switch (props.variant) {
            case 'toggle':
                return (
                    <div>
                        <Typography
                            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <FormControlLabel
                                style={style.switch}
                                disabled={(props.authenticated === AUTHENTICATION_STATES.READ)}
                                control={
                                    <Switch
                                        checked={props.value}
                                        value={props.name}
                                        color={props.color}
                                    />
                                }
                                labelPlacement={props.labelPlacement}
                                label={props.label}
                                onClick={handleClickOpen}
                            />
                        </Typography>
                        {getPopover()}
                    </div>
                )

            case 'text':
                return (
                    <div>
                        <Typography
                            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <FormControl
                                style={style.text}
                            >
                                <TextField
                                    disabled={(props.authenticated === AUTHENTICATION_STATES.READ)}
                                    id={props.name}
                                    label={props.label}
                                    defaultValue={props.value}
                                    className={props.variant}
                                    onChange={handleTextVariantChange}
                                    variant="outlined"
                                />
                                <IconButton
                                    style={style.fab}
                                    disabled={(props.authenticated === AUTHENTICATION_STATES.READ)}
                                    color="primary"
                                    onClick={handleClickOpen}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </FormControl>
                        </Typography>
                        {getPopover()}
                    </div>
                )

            case 'list':
                const valueSplitted = props.value.split('|').filter(v => !!v)
                return (
                    <div>
                        <Typography
                            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <List style={{border: '1px solid #ccc'}}>
                                {valueSplitted.map(value => (
                                    <React.Fragment key={value}>
                                        <ListItem>
                                            <ListItemText
                                                primary={value}
                                            />

                                            {props.authenticated === AUTHENTICATION_STATES.READWRITE && (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        disabled={(props.authenticated === AUTHENTICATION_STATES.READ)}
                                                        onClick={() => {
                                                            setRemoveOptionValue(value)
                                                            handleClickOpen('remove')
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                        <Divider/>
                                    </React.Fragment>
                                ))}
                                {props.authenticated === AUTHENTICATION_STATES.READWRITE && (
                                    <ListItem>
                                        <TextField
                                            id={`${props.name}.new-option`}
                                            label="Add option"
                                            className={props.variant}
                                            value={newOptionValue}
                                            onChange={handleNewOptionChange}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                disabled={!newOptionValue || valueSplitted.includes(newOptionValue)}
                                                onClick={() => handleClickOpen('add')}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </Typography>
                        {getPopover()}
                    </div>
                )

            default:
                return null
        }
    }

    return (
        <React.Fragment>
            {renderInputVariant()}
            <FormHelperText style={style.helperText}>{props.description}</FormHelperText>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Je verandert mogelijk een belangrijke optie</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Weet je zeker dat je deze optie aan wil passen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={props.color}>
                        Nee
                    </Button>
                    <Button onClick={handleAccept} color={props.color} autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

Setting.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    color: PropTypes.string,
    labelPlacement: PropTypes.string,
    label: PropTypes.string.isRequired,
    authenticated: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default Setting
