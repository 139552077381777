import socket from './../../socket'
import { getUserEmail } from '../../store'

export function getProductVariant(variant_id) {
    socket.emit('product/variant/get', { variant_id }, getUserEmail())
    return { type: 'GET_PRODUCT_VARIANT', data: { variant_id } }
}

export function phaseOutProductVariant(variant, { option, all_variants }) {
    socket.emit('product/variant/phase-out', { variant, option, all_variants }, getUserEmail())
    return { type: 'PHASE_OUT_PRODUCT_VARIANT', data: { variant, option, all_variants } }
}

export function undoPhaseOutProductVariant(variant) {
    socket.emit('product/variant/undo-phase-out', { variant }, getUserEmail())
    return { type: 'UNDO_PHASE_OUT_PRODUCT_VARIANT', data: { variant } }
}

export function updateProductVariant(data) {
    socket.emit('product/variant/update', data, getUserEmail())
    return { type: 'UPDATE_PRODUCT_VARIANT', data }
}

export function updatePurchaseable(data) {
    socket.emit('product/variants/update-purchaseable', data, getUserEmail())
    return { type: 'UPDATE_PURCHASEABLE', data }
}

export function deltaPurchaseable(data) {
    socket.emit('product/variants/delta-purchaseable', data, getUserEmail())
    return { type: 'DELTA_PURCHASEABLE', data }
}

export function searchAutoCompleteProductVariants(filters) {
    socket.emit('product/variants/autocomplete', filters)
    return { type: 'SEARCH_AUTOCOMPLETE_PRODUCT_VARIANTS', filters }
}
