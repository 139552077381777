import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const styles = {
    table: {

    },
    icon: {
        fontSize: 32,
        cursor: 'pointer',
    },
}

class PickListPicks extends Component {

    constructor(props) {
        super(props)
        this.state = {}

        this.handlePickRemoveClick = this.handlePickRemoveClick.bind(this)
    }

    handlePickRemoveClick(pick) {
        return this.props.onPickRemoveClick(pick)
    }

    render() {
        const {picks = [], status = 'draft'} = this.props

        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell component="th">SKU</TableCell>
                        <TableCell component="th">Name</TableCell>
                        {status === 'draft'
                            ? <TableCell component="th" align="right">Pick amount</TableCell>
                            : <TableCell component="th" align="right">Amount picked</TableCell>
                        }
                        {status === 'processing' || status === 'completed'
                            ? <TableCell component="th">Location</TableCell>
                            : null
                        }
                        <TableCell component="th">Color</TableCell>
                        <TableCell component="th">Size</TableCell>
                        {status === 'draft' ? <TableCell component="th">&nbsp;</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {picks.length
                        ? picks.map(pick => (
                            <TableRow key={`${pick.sku}-${pick.color}-${pick.size}-${pick.location}`}>
                                <TableCell>{pick.sku}</TableCell>
                                <TableCell>{pick.name}</TableCell>
                                {status === 'draft'
                                    ? <TableCell align="right">{pick.qty_to_pick}</TableCell>
                                    : <TableCell align="right">{pick.qty_picked}/{pick.qty_to_pick}</TableCell>
                                }
                                {status === 'processing' || status === 'completed'
                                    ? <TableCell>{pick.location}</TableCell>
                                    : null
                                }
                                <TableCell>{pick.color}</TableCell>
                                <TableCell>{pick.size}</TableCell>
                                {status === 'draft'
                                    ? (<TableCell>
                                        <Tooltip title="Delete pick from picklist">
                                            <IconButton
                                                aria-label="Delete pick from picklist"
                                                onClick={this.handlePickRemoveClick.bind(this, pick)}
                                                style={styles.icon}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>)
                                    : null
                                }
                            </TableRow>
                        ))
                        : (<TableRow><TableCell colSpan="7">Picklist has no picks.</TableCell></TableRow>)
                    }
                </TableBody>
            </Table>
        )
    }
}

PickListPicks.contextTypes = {}
PickListPicks.propTypes = {
    picks: PropTypes.array.isRequired,
    status: PropTypes.string.isRequired,
    onPickRemoveClick: PropTypes.func.isRequired,
}
PickListPicks.defaultProps = {
    picks: [],
}

export default PickListPicks
