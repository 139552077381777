import store from '../../store'

import { getOutboundChecklist } from '../../commands/sockets/outbound_checklists'

export default function initOutboundChecklistsSockets(socket) {
    socket.on('outbound-checklists', outbound_checklists => {
        store.dispatch({ type: 'REFRESH_OUTBOUND_CHECKLISTS', outbound_checklists })
    })

    socket.on('outbound-checklist', outbound_checklist => {
        store.dispatch({ type: 'REFRESH_OUTBOUND_CHECKLIST', outbound_checklist })
    })

    socket.on('outbound-checklist/triaged', items => {
        const id = items.shift().purchase_id
        getOutboundChecklist(id)
    })
}
