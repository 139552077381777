import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardTitle, Dialog, FlatButton, SelectField, MenuItem} from 'material-ui'
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import moment from 'moment'

import LineItemSelect from '../shared/forms/LineItemSelect'
import QuantityField from '../shared/forms/QuantityField'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '50%',
        display: 'inline-block',
    },
    datePicker: {
        cursor: 'pointer',
    },
    datePickerTextField: {
        fontSize: 14,
    },
    dateErrorMessage: {
        fontSize: 14,
    },
}

const FULFILLED = /pre_transit|in_transit|out_for_delivery|attempt_fail|delivered|exception/

class PostponeDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lineItem: null,
            quantity: 1,
            reason: null,
            estimatedFor: props.shipment ? props.shipment.estimated_for : null,
        }
    }

    handleLineItemChange(lineItem) {
        this.setState({
            lineItem,
        })
    }

    handleQuantityChange(quantity) {
        this.setState({
            quantity,
        })
    }

    handleReasonChange(e, index, reason) {
        this.setState({
            reason,
        })
    }

    handleEstimatedDateChange({target}) {
        this.setState({
            estimatedFor: target.value,
        })
    }

    resetState(cb) {
        this.setState({
            lineItem: null,
            quantity: 1,
            reason: null,
            estimatedFor: null,
        }, cb)
    }

    handleSubmit() {
        const state = this.state
        this.resetState(() => {
            const fulfilled = FULFILLED.test(this.props.shipment.status)
            const estimatedFor = moment(state.estimatedFor).toDate()
            this.props.onPostponeSubmit(this.props.shipment, {...state, estimatedFor, fulfilled})
        })
    }

    handleClose() {
        this.resetState(() => {
            this.props.onClosePostponeDialog()
        })
    }

    render() {
        const shipment = this.props.shipment || PostponeDialog.defaultProps.shipment
        const shipment_date = shipment ? shipment.estimated_for : null

        const estimatedForDate = new Date(this.state.estimatedFor)
        const hasEstimatedForChanged = this.state.estimatedFor !== null && this.state.estimatedFor !== shipment_date
        const isValidEstimatedFor = !isNaN(estimatedForDate.getTime())
            && estimatedForDate.getTime() <= (new Date().getTime() + (2 * 365 * 24 * 60 * 60 * 1000)) // estimated_for should be max 2 years from now
            && estimatedForDate.getTime() > new Date().getTime() // estimated_for should be in the future

        const cannotSubmit = this.state.lineItem === null
            || !hasEstimatedForChanged
            || !isValidEstimatedFor
            || this.state.reason === null
            || this.props.isUpdated

        let quantityField = null

        if (_.get(this.state, 'lineItem.product.name', 'All') !== 'All') {
            quantityField = (
                <QuantityField
                    onFilterChange={this.handleQuantityChange.bind(this)}
                    quantity={_.get(this.state, 'lineItem.quantity', '1')}
                    filterValue={_.get(this.state, 'quantity', '1')}
                    label="Quantity"
                />
            )
        }

        const isFulfilled = FULFILLED.test(shipment.status)

        return (
            <Dialog
                onRequestClose={this.handleClose.bind(this)}
                open={this.props.open}
                actions={[
                    <FlatButton
                        key="postpone_dialog.submit"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmit.bind(this)}
                        secondary={true}
                    >
                        Submit
                    </FlatButton>,
                    <FlatButton
                        key="postpone_dialog.close"
                        onClick={this.handleClose.bind(this)}
                    >
                        Close
                    </FlatButton>,
                ]}
            >
                <CardTitle style={style.title} subtitleStyle={style.subtitleStyle} subtitle="Postpone"/>
                <LineItemSelect
                    source={shipment.source}
                    initialValues={isFulfilled ? [] : [{
                        product: {
                            name: 'All',
                        },
                    }]}
                    onFilterChange={this.handleLineItemChange.bind(this)}
                    filterValue={this.state.lineItem}
                    lineItems={shipment.line_items}
                />
                {quantityField}
                <SelectField
                    style={{width: '100%', fontSize: 14}}
                    onChange={this.handleReasonChange.bind(this)}
                    value={this.state.reason}
                    floatingLabelText="Select a reason for postponing"
                >
                    {
                        isFulfilled
                            ? <MenuItem key="not-delivered" value="not-delivered" primaryText="Is not fulfilled"/>
                            : [
                                <MenuItem
                                    key="out-of-stock-supplier"
                                    value="out-of-stock-supplier"
                                    primaryText="Out of stock (supplier)"
                                />,
                                <MenuItem
                                    key="out-of-stock-warehouse"
                                    value="out-of-stock-warehouse"
                                    primaryText="Out of stock (warehouse)"
                                />,
                                <MenuItem
                                    key="checked-in-different-customer"
                                    value="checked-in-different-customer"
                                    primaryText="Checked-in for other customer"
                                />,
                                <MenuItem
                                    key="product-issue"
                                    value="product-issue"
                                    primaryText="Issue with product"
                                />,
                                <MenuItem
                                    key="vendor-late-delivery"
                                    value="vendor-late-delivery"
                                    primaryText="Late delivery by supplier"
                                />,
                            ]

                    }
                </SelectField>
                <TextField
                    id="from"
                    textFieldStyle={style.datePickerTextField}
                    required={true}
                    label="Select new delivery date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={this.state.estimatedFor}
                    onChange={this.handleEstimatedDateChange.bind(this)}
                    margin="normal"
                />
                {isValidEstimatedFor || !hasEstimatedForChanged ? null
                    : <Alert severity="error" style={style.dateErrorMessage}>
                        Delivery date should be in the future, and not more than 2 years from now.
                    </Alert>
                }
            </Dialog>
        )
    }
}

PostponeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    shipment: PropTypes.object,
    isUpdated: PropTypes.bool,
    onClosePostponeDialog: PropTypes.func.isRequired,
    onPostponeSubmit: PropTypes.func.isRequired,
}

PostponeDialog.defaultProps = {
    shipment: {
        line_items: [],
    },
    isUpdated: false,
}

export default PostponeDialog
