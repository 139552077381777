import React, { Component } from 'react'
import autoBind from 'react-autobind'

import PropTypes from 'prop-types'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    IconButton,
    Paper,
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    TextField,
} from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import EditIcon from '@material-ui/icons/Edit'
import ReplayIcon from '@material-ui/icons/Replay'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import ProductRequisitionSettings from './ProductRequisitionSettings'


import _ from 'lodash'

const style = {
    warningIcon: { color: 'red' },
    infoIcon: { color: orange[500] },
    undoButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        padding: '5px 8px',
        backgroundColor: grey[300],
        borderRadius: '3px',
    },
    undoButtonLoading: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'not-allowed',
        padding: '5px 8px',
        backgroundColor: grey[300],
        borderRadius: '3px',
        opacity: '0.5',
    },
}

const updateableVariantProperties = [
    {
        description: 'Update purchase amount',
        type: 'number',
        attribute: 'purchasing_scale_quantity',
        default: 1,
    },
    {
        description: 'Update whether variant is purchaseable at supplier',
        type: 'boolean',
        attribute: 'is_purchaseable',
        default: true,
    },
]

class VariantSpecifications extends Component {

    constructor(props) {
        super(props)

        // Initialize state objects for all updateable attributes
        this.state = {
            ...(Object.fromEntries(updateableVariantProperties.map(p => [
                p.attribute,
                { value: props?.variant?.[p.attribute] ?? null, editing: false } ,
            ]))),
            undoPhaseOutLoading: false,
        }

        autoBind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const entries = updateableVariantProperties
            .filter(p => nextProps?.variant?.[p.attribute] != null && prevState[p.attribute].value === null)
            .map(p => [ p.attribute, { value: nextProps.variant[p.attribute], editing: false } ])

        if (!entries.length) return null

        return Object.fromEntries(entries)
    }

    /**
     * @param {string} state_prop
     * @param {'value' | 'editing'} property
     * @param {any} value
     */
    updateState(state_prop, property, value) {
        const state = { ...this.state }
        state[state_prop][property] = value
        this.setState(state)
    }

    /**
     * @param {string} property
     * @param {any} value
     */
    handleTextFieldChange(property, value) {
        this.updateState(property, 'value', value)
    }

    /**
     * @param {string} property
     */
    handleEditClick(property) {
        this.updateState(property, 'editing', true)
    }

    handleSubmitClick(property) {
        const hasChanged = this.props.variant[property] !== this.state[property].value

        if (hasChanged) {
            const update = {
                _id: this.props.variant._id,
            }

            update[property] = this.state[property].value
            this.props.onVariantUpdate(update)
        }

        this.handleCloseClick()
    }

    handleCloseClick() {
        this.setState(Object.fromEntries(updateableVariantProperties.map(p => [
            p.attribute,
            { value: this.state?.[p.attribute]?.value ?? p.default, editing: false } ,
        ])))
    }

    handleRequisitionStatus(requisition_status) {
        const updated_variant = {
            ...this.props.variant,
            requisition_disabled: requisition_status,
        }

        this.props.onVariantUpdate(updated_variant)
    }

    handleUndoPhaseOutClick() {
        this.setState({ undoPhaseOutLoading: true })
        this.props.undoPhaseOutProductVariant()
    }

    /**
     *
     * @param {{ value: any, editing: boolean }} stateAttribute
     * @param {string} property
     * @param {string} header
     * @param {'number' | 'boolean'} type
     * @param {any} def
     */
    getDialog({ editing, value }, property, header, type = 'number', def = 0) {
        return (
            <Dialog key={property} open={editing}>
                {header && <DialogTitle>{header}</DialogTitle>}
                <DialogContent>
                    {(() => {
                        switch(type) {
                            case 'boolean':
                                return <FormControlLabel
                                    value="end"
                                    control={<Switch
                                        checked={typeof value === 'boolean' ? value : Boolean(def)}
                                        onChange={({ target }) => {
                                            return this.handleTextFieldChange(property, Boolean(target.checked))
                                        }}
                                    />}
                                    label={(typeof value === 'boolean' ? value : Boolean(def)) ? 'Yes' : 'No'}
                                    labelPlacement="end"
                                />

                            case 'number': // eslint-disable-line no-fallthrough
                            default:
                                return <TextField
                                    type="number"
                                    value={typeof value === 'number' ? value : def}
                                    onChange={({ target }) => this.handleTextFieldChange(property, target.value)}
                                />
                        }
                    })()}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleSubmitClick(property)}
                        color="primary"
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.handleCloseClick}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { variant } = this.props

        return (
            <Paper style={{ width: '32%' }}>
                <List>
                    <ListSubheader>Variant specifications</ListSubheader>
                    <ListItem>
                        <ListItemText
                            primary="Name"
                            secondary={variant.name}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary="SKU"
                            secondary={variant.supplier_sku}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="EAN"
                            secondary={variant.ean || ''}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Color"
                            secondary={variant.color || '-'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Size"
                            secondary={variant.size || '-'}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            primary="Supplier"
                            secondary={_.get(variant, 'supplier.name', 'Unknown')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Source"
                            secondary={variant.fulfillment_source}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Purchaseable with vendor"
                            secondary={variant.is_purchaseable ? 'Yes' : 'No'}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.handleEditClick('is_purchaseable')}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Purchasing scale quantity"
                            secondary={variant.purchasing_scale_quantity}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.handleEditClick('purchasing_scale_quantity')}>
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Vendor lead time"
                            secondary={`${_.get(variant, 'lead_times.vendor', '?')} working days`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Requisition Settings"
                            secondary={
                                <ProductRequisitionSettings
                                    onSubmit={this.handleRequisitionStatus}
                                    variant={this.props.variant}
                                    state={Boolean(this.props.features.canEditProductRequisitions)}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem>
                        {!variant.end_of_life ? null : (
                            <ListItemIcon style={style.warningIcon}><WarningIcon /></ListItemIcon>
                        )}
                        <ListItemText
                            primary="End of life"
                            secondary={variant.end_of_life ? 'Yes' : 'No'}
                        />
                        {variant.end_of_life && (
                            <div
                                style={this.state.undoPhaseOutLoading ? style.undoButtonLoading : style.undoButton}
                                onClick={this.handleUndoPhaseOutClick}
                            >
                                <ReplayIcon />
                                Undo
                            </div>
                        )}
                    </ListItem>
                    <ListItem>
                        {!variant.migrating_to_crossdock ? null : (
                            <ListItemIcon style={style.infoIcon}><InfoIcon /></ListItemIcon>
                        )}
                        <ListItemText
                            primary="Migrating to crossdock"
                            secondary={variant.migrating_to_crossdock ? 'Yes' : 'No'}
                        />
                        {variant.migrating_to_crossdock && (
                            <div
                                style={this.state.undoPhaseOutLoading ? style.undoButtonLoading : style.undoButton}
                                onClick={this.handleUndoPhaseOutClick}
                            >
                                <ReplayIcon />
                                Undo
                            </div>
                        )}
                    </ListItem>
                </List>

                {/* Render dialog boxes */}
                {updateableVariantProperties.map(p => {
                    return this.getDialog(
                        this.state[p.attribute],
                        p.attribute,
                        p.description,
                        p.type,
                        p.default
                    )
                })}
            </Paper>
        )
    }
}

VariantSpecifications.propTypes = {
    variant: PropTypes.object,
    onVariantUpdate: PropTypes.func.isRequired,
    features: PropTypes.object,
    undoPhaseOutProductVariant: PropTypes.func.isRequired,
}
VariantSpecifications.defaultProps = {
    variant: {
        supplier_sku: '',
        ean: '',
        color: '',
        size: '',
    },
}

export default VariantSpecifications
