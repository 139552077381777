import store from '../store'
import { syncSocketCall } from '../socket'
import { createLogger } from '../app/utils/logger'

const sendLog = createLogger('commands-sync/order')

/**
 * Send command to get any promo items if they need to be packed
 * @param {string} mage_id mage_id of the order
 * @param {object} options Options object
 * @return {object} Action
 */
export async function getPromoItemsByMageId(mage_id, { timeout = 30000 } = {}) {
    try {
        sendLog(`[getPromoItemsByMageId] Fetching promo items for order ${mage_id}`)
        const promo_items = await syncSocketCall('order/get-promo-items', { mage_id }, { timeout })
        sendLog(
            `[getPromoItemsByMageId] Received promo items for order ${mage_id}: `,
            JSON.stringify(promo_items)
        )
        store.dispatch({type: 'REFRESH_PROMO_ITEMS', data: promo_items})
    } catch (e) {
        sendLog(`[getPromoItemsByMageId] Error while fetching promo items for order ${mage_id}`)
        store.dispatch({type: 'CLEAR_PROMO_ITEMS'})
        throw e
    }
}
