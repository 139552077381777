import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Zoom, Fab, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import { createDraftRtv, searchRtvs } from './../../../../commands/sockets/rtvs'
import { searchSuppliers } from './../../../../commands/sockets/suppliers'

import { Statuses } from '../../../types/returns/rtv'

import CreateRtvDialog from './CreateRtvDialog'
import RtvTable from './RtvTable'
import RtvFilters from './RtvFilters'

const FIELDS = 'rtv_id,vendor_ref,created_at,supplier,status,type,pricing'
const STATUSES = Object.values(Statuses)
const STORAGE_KEY = 'rtv-filter'

const style = {
    container: {
        width: '100%',
        position: 'relative',
    },
    addButton: {
        position: 'fixed',
        zIndex: 5,
        left: 20,
        bottom: 20,
    },
}

class RtvList extends Component {
    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            isUpdated: false,
            createOpen: false,
            filters: {
                criteria: filters.criteria || '',
                supplier_id: filters.supplier_id || 'all',
                status: filters.status || 'all',
                type: filters.type || 'all',
                page: filters.page || 1,
                limit: filters.limit || 20,
            },
        }
        autoBind(this)
    }

    componentDidMount() {
        this.searchRtvs()
        this.props.searchSuppliers({})
    }

    getFilterState() {
        const filters = { ...this.state.filters }
        filters.fields = FIELDS.split(',')
        if (filters.status === 'all') {
            delete filters.status
        }

        if (filters.type === 'all') {
            delete filters.type
        }

        if (filters.supplier_id === 'all') {
            delete filters.supplier_id
        }
        return filters
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    searchRtvs() {
        const filters = this.getFilterState()
        this.storeFilters(filters)
        this.props.searchRtvs(filters)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchRtvs, 500)
    }

    setStateWithoutRender(state, timeout) {
        this.setState({
            shouldRender: false,
            filters: {
                ...this.state.filters,
                ...state.filters,
            },
        }, timeout ? this.setSearchTimeout : this.searchRtvs)
    }

    handleCriteriaChange(value) {
        this.setStateWithoutRender({
            filters: {
                criteria: value,
                page: 1,
            },
        }, true)
    }

    handleSupplierChange(supplier_id) {
        this.setStateWithoutRender({
            filters: {
                supplier_id,
                page: 1,
            },
        }, false)
    }

    handleStatusChange(e) {
        this.setStateWithoutRender({
            filters: {
                status: e.target.value,
                page: 1,
            },
        }, false)
    }

    handleTypeChange(e) {
        this.setStateWithoutRender({
            filters: {
                type: e.target.value,
                page: 1,
            },
        }, false)
    }

    handleRtvClick(index) {
        const rtv = this.props.rtvs[index]
        this.context.router.push(`/returns/rtv/${rtv._id}`)
    }

    handleLimitChange(limit) {
        this.setStateWithoutRender({
            filters: { limit },
        }, false)
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            filters: { page: this.state.filters.page - 1 },
        }, false)
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            filters: { page: this.state.filters.page + 1 },
        }, false)
    }

    handleCreateDialogClose() {
        this.setState({
            createOpen: false,
        })
    }

    handleAddClick() {
        this.setState({
            createOpen: true,
        })
    }

    handleCreateDialogSubmit({ supplier_id, type }) {
        this.handleCreateDialogClose()
        const supplier = this.props.suppliers.find(s => s.mage_id === +supplier_id)
        this.props.createDraftRtv({
            mage_id: supplier.mage_id,
            name: supplier.name,
        }, type)
    }

    render() {
        const dialog = !this.state.createOpen ? null : (
            <CreateRtvDialog
                suppliers={this.props.suppliers}
                onClose={this.handleCreateDialogClose}
                onSubmit={this.handleCreateDialogSubmit}
            />
        )
        return (
            <div style={style.container}>
                <RtvFilters
                    onCriteriaChange={this.handleCriteriaChange}
                    onSupplierChange={this.handleSupplierChange}
                    onStatusChange={this.handleStatusChange}
                    onTypeChange={this.handleTypeChange}
                    suppliers={this.props.suppliers}
                    filters={this.state.filters}
                    statuses={STATUSES}
                />
                <RtvTable
                    page={this.state.filters.page - 1}
                    limit={this.state.filters.limit}
                    rtvs={this.props.rtvs}
                    onRtvClick={this.handleRtvClick}
                    onLimitChange={this.handleLimitChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip style={style.addButton} title="ADD RTV">
                        <Fab
                            color="primary"
                            onClick={this.handleAddClick}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Zoom>
                {dialog}
            </div>
        )
    }
}

RtvList.contextTypes = {
    router: PropTypes.object,
}

RtvList.propTypes = {
    rtvs: PropTypes.array.isRequired,
    suppliers: PropTypes.array,
    filters: PropTypes.object,
    createDraftRtv: PropTypes.func,
    searchRtvs: PropTypes.func.isRequired,
    searchSuppliers: PropTypes.func,
}

export default connect(({ rtvs, suppliers, filters }) => {
    return {
        rtvs,
        suppliers,
        filters: filters.rtvs || {},
    }
}, dispatch => {
    return bindActionCreators({ createDraftRtv, searchRtvs, searchSuppliers }, dispatch)
})(RtvList)
