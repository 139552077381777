import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Avatar, List, Subheader, Divider, ListItem} from 'material-ui'

class ExpectedDelivery extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filter: '',
        }
    }

    showItem(item, checklist) {
        if (item.status === 'postponed') {
            const found = checklist.find(checklist_item => {
                return !!checklist_item.origin_id
                    && (checklist_item.origin_id === item._id || checklist_item.origin_id === item.origin_id)
            })
            return !found
        }
        return item.status === 'approved'
    }

    groupDeliverableItemsByDeliveryDate(checklist) {
        return checklist.reduce((acc, original) => {
            if (!this.showItem(original, checklist)) {
                return acc
            }
            const item = {...original} // prevent props mutation
            item.delivery_date = moment(item.status === 'approved' ? item.estimated_for : item.postponed_to)
                .format('DD-MM-YY')
            acc[item.delivery_date] = acc[item.delivery_date] || []

            const found = acc[item.delivery_date].find(acc_item => {
                return item.supplier_sku === acc_item.supplier_sku
                    && item.mage_id === acc_item.mage_id
            })
            if (found) {
                found.quantity += item.quantity
            } else {
                acc[item.delivery_date].push(item)
            }
            return acc
        }, {})
    }

    getListItemStyle(li) {
        return li.origin_id ? { backgroundColor: this.context.colors.amber100 } : {}
    }

    render() {
        const grouped_items = this.groupDeliverableItemsByDeliveryDate(this.props.outbound_items)
        return (
            <div style={{maxHeight: 550, overflowY: 'scroll'}}>
                {Object.keys(grouped_items).map(delivery_date => (
                    <List key={delivery_date}>
                        <Subheader inset={true}>{delivery_date}</Subheader>
                        {grouped_items[delivery_date].map(item => (
                            <ListItem
                                style={this.getListItemStyle(item)}
                                key={`${item.mage_id}.${item.supplier_sku}`}
                                leftAvatar={<Avatar>{item.quantity}</Avatar>}
                                primaryText={item.name}
                                secondaryText={
                                    <span>
                                        SKU: {item.supplier_sku}{item.ean ? `, EAN: ${item.ean}` : ''}<br/>
                                        {item.mage_id}
                                    </span>
                                }
                                secondaryTextLines={2}
                            />
                        ))}
                        <Divider inset={true} />
                    </List>
                ))}
            </div>
        )
    }
}

ExpectedDelivery.contextTypes = {
    colors: PropTypes.object,
}
ExpectedDelivery.propTypes = {
    outbound_items: PropTypes.array,
}
ExpectedDelivery.defaultProps = {
    outbound_items: [],
}

export default ExpectedDelivery
