import socket from './../socket'
import listeners from '../listeners/sockets/cancellations.js'

listeners(socket)

export function cancellations(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            state = []
            break

        case 'HANDLE_CANCELLATION':
            socket.emit(`cancellation/${action.choice}`, action.params)
            return state

        case 'SUBMIT_CANCELLATION':
            socket.emit('cancellation/process', action.data)
            return state

        case 'GET_TYPED_ORDER_CANCELLATIONS':
            socket.emit('cancellations/fulfillment/get', {fulfillment_id: action.id})
            return state

        case 'GET_SHIPMENT_CANCELLATIONS':
            socket.emit('cancellations/shipment/get', {shipment_id: action.id})
            return state

        case 'REFRESH_DROPSHIP_ORDER_CANCELLATIONS':
            return action.cancellations

        default:
            return state
    }

    return state
}
