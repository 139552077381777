import store from '../../store'

export default function initLabelsSockets(socket) {
    socket.on('label', label => {
        store.dispatch({type: 'REFRESH_LABEL', label})
    })

    socket.on('labels', labels => {
        store.dispatch({type: 'REFRESH_LABELS', labels})
    })
}
