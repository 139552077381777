import socket from '../../socket'
import { getUserEmail } from '../../store'

export function updateProductionOrderStatus({ production_order_id, status }) {
    socket.emit('production-order/update-status', { production_order_id, status }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_STATUS', production_order_id, status }
}

export function updateProductionOrderLocation({ production_order_id, location }) {
    socket.emit('production-order/update-location', { production_order_id, location }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_LOCATION', production_order_id, location }
}

export function updateProductionOrderItemsReady({ production_order_id, items_ready }) {
    socket.emit('production-order/update-items-ready', { production_order_id, items_ready }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_ITEMS_READY', production_order_id, items_ready }
}

export function updateProductionOrderImpressionStatus({ production_order_id, impression_status }) {
    socket.emit('production-order/update-impression-status', { production_order_id, impression_status }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_IMPRESSION_STATUS', production_order_id, impression_status }
}

export function updateProductionOrderUsedPress({ production_order_id, used_press }) {
    socket.emit('production-order/update-used-press', { production_order_id, used_press }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_USED_PRESS', production_order_id, used_press }
}

export function updateProductionOrderPressWorker({ production_order_id, press_worker }) {
    socket.emit('production-order/update-press-worker', { production_order_id, press_worker }, getUserEmail())
    return { type: 'UPDATE_PRODUCTION_ORDER_PRESS_WORKER', production_order_id, press_worker }
}

export function getProductionOrderById(production_order_id) {
    socket.emit('production-order/get-by-id', production_order_id)
    return { type: 'GET_PRODUCTION_ORDER_BY_ID', production_order_id }
}

export function searchProductionOrders(params) {
    socket.emit('production-orders/search', params)
    return { type: 'SEARCH_PRODUCTION_ORDERS', params }
}
