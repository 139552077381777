import store from '../../store'

export default function initLocationsSockets(socket) {
    socket.on('locations/search-result', data => {
        store.dispatch({type: 'REFRESH_LOCATIONS', data})
    })

    socket.on('locations/free', data => {
        store.dispatch({type: 'REFRESH_LOCATIONS', data})
    })
}
