import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {List, ListItem} from 'material-ui'
import _ from 'lodash'
import moment from 'moment'

import Devices from './../../../../../styles/devices'

const style = {
    list: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexFlow: 'column',
    },
    primaryTextListItemStyle: {
        fontSize: 14,
    },
    innerDivStyle: {
        backgroundColor: 'transparent',
        paddingTop: 4,
        paddingBottom: 4,
    },
}

class ShipmentPackItemInfo extends Component {

    getStyles(device) {
        if ([Devices.DESKTOP, Devices.RETINA].indexOf(device) > -1) {
            return style
        }
        return _.merge(style, {
            list: {
                flexFlow: 'row',
            },
        })
    }

    reduceLineItemsByField(line_items, field) {
        if (!field) {
            return 0
        }
        return line_items.reduce((acc, li) => acc + li[field], 0)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.item._id !== this.props.item._id ||
            this.reduceLineItemsByField(nextProps.item.line_items, 'packed_quantity') !==
            this.reduceLineItemsByField(this.props.item.line_items, 'packed_quantity')
    }

    render() {
        const item = this.props.item
        const packed = this.reduceLineItemsByField(item.line_items, 'packed_quantity')
        const total = this.reduceLineItemsByField(item.line_items, 'quantity')
        const styles = this.getStyles(this.context.device)
        return (
            <List style={styles.list}>
                <ListItem
                    disabled={true}
                    innerDivStyle={styles.innerDivStyle}
                    primaryText={
                        <span style={style.primaryTextListItemStyle}>
                            <strong>Delivery date: </strong>
                            {moment(item.estimated_for).format('DD-MM-YY')}
                        </span>
                    }
                />
                <ListItem
                    disabled={true}
                    innerDivStyle={styles.innerDivStyle}
                    primaryText={
                        <span style={style.primaryTextListItemStyle}>
                            <strong>Packed: </strong>
                            {`${packed}/${total}`}
                        </span>
                    }
                />
                <ListItem
                    disabled={true}
                    innerDivStyle={styles.innerDivStyle}
                    primaryText={
                        <span style={style.primaryTextListItemStyle}>
                            <strong>Destination: </strong>
                            {item.destination_type}
                        </span>
                    }
                />
            </List>
        )
    }
}


ShipmentPackItemInfo.propTypes = {
    style: PropTypes.object,
    item: PropTypes.object,
}

ShipmentPackItemInfo.defaultProps = {
    item: {line_items: []},
}

ShipmentPackItemInfo.contextTypes = {
    device: PropTypes.string,
}

export default ShipmentPackItemInfo
