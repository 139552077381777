import socket from './../socket'
import listeners from '../listeners/sockets/sales_orders.js'
import _ from 'lodash'

listeners(socket)

export function sales_orders(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SEARCH_SALES_ORDERS':
            socket.emit('orders/search', action.filters)
            return state

        case 'REFRESH_SALES_ORDERS':
            return _.orderBy(action.sales_orders, ['created_at'], ['desc'])

        default:
            return state
    }
}

export function sales_order(state = {}, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {}

        case 'REFRESH_SALES_ORDER':
            const order = action.sales_order
            return {
                _id: order._id,
                channel: order.channel,
                payment_info: order.payment_info,
                created_at: order.created_at,
                personalised: order.personalised,
                mage_id: order.mage_id,
                customer: _.merge(order.customer, {
                    billing_address: order.billing_address,
                    shipping_address: _.last(order.shipping_address),
                    ref: order.refs.customer_ref,
                }),
                line_items: order.line_items,
                status: order.status,
                notes: order.notes,
                instructions: order.instructions,
                sales_representative: order.sales_representative,
                personalisation: order.personalisation,
                refs: order.refs,
            }

        default:
            return state
    }
}
