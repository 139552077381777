import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import { handleFileUpload } from '../../../utils/upload'
import { parseCsv } from '../../../utils/csv'
import { pluralize, stringToBoolean } from '../../../utils/string'
import { numberToOrdinal } from '../../../utils/number'
import {
    Card,
    CardActions,
    FlatButton,
    FontIcon,
    Tabs,
    Tab,
    TextField,
} from 'material-ui'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import GetAppIcon from '@material-ui/icons/GetApp'

import moment from 'moment'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { submitCancellation } from './../../../../actions/cancellations'
import { getSalesOrderByMageId } from './../../../../commands/sockets/sales_orders'
import {
    getPurchaseOrder,
    markAsSubmitted,
    getPurchaseOrderAsPdf,
    addItem,
    addNote,
    updateItems,
    removeItems,
} from './../../../../actions/purchase_orders'
import { showErrorNotification, showOkNotification } from '../../../../actions/notifications'

import {
    updatePurchaseOrderLineItems,
    updatePurchaseOrderDraft,
    finalizePurchaseOrderDraft,
} from './../../../../commands/sockets/purchase_orders'
import { getVariantsBySkuOrEan } from '../../../../commands-sync/variants'

import Header from './../../shared/Header'
import Notes from './../../shared/Notes'
import DraftLineItems from './purchase-order-detail/DraftLineItems'
import LineItems from './purchase-order-detail/LineItems'
import ExpectedDelivery from './purchase-order-detail/ExpectedDelivery'
import ReceiptLineItems from './purchase-order-detail/ReceiptLineItems'
import PurchaseOrderSpecifications from './purchase-order-detail/PurchaseOrderSpecifications'
import FinalizeDraftDialog from './purchase-order-detail/FinalizeDraftDialog'

import Colors from './../../../styles/colors'

import { Statuses } from '../../../types/purchase/purchase_order'
import { downloadAsCsv } from '../../../utils/download'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    left: {
        width: '20%',
    },
    filler: {
        height: 50,
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        padding: 10,
        justifyContent: 'flex-start',
    },
    finalizeButton: {
        color: 'white',
    },
    importCsvButton: {
        marginRight: '20px',
    },
    contents: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
    },
    tabs: {
        width: '78%',
        minWidth: 624,
        marginTop: 12,
        marginLeft: 12,
    },
    tabsContainer: {
        boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
        borderRadius: '2px',
    },
    textField: {
        marginLeft: 72,
    },
    iconButton: {
        float: 'right',
    },
    iconButtonStyle: {
        color: '#FFFFFF',
    },
    rightButtons: {
        float: 'right',
        display: 'flex',
        textAlign: 'right',
        flexFlow: 'column',
        marginTop: -14,
    },
    rightButtonsContainer: {
        flexDirection: 'row',
    },
}

const IMPORT_CSV_FILE_COLUMNS = [
    { name: 'sku', required: true, normalize: s => s.trim() },
    { name: 'quantity', required: true, normalize: s => Math.abs(parseInt((s || '1').trim(), 10)) },
    { name: 'personalised', required: true, normalize: s => stringToBoolean(s.trim()) },
    { name: 'ean', required: false, normalize: s => s && s.trim() },
    { name: 'salesorder', required: false, normalize: s => s && s.trim() },
]

const IMPORT_CSV_FILE_EXAMPLE_DATA = [
    [ 'POR-CS10-6XL-Navy', 3, 'yes' ],
    [ 'unknown-sku', 4, 'no', '8718326541931', '100301154' ],
]

class PurchaseOrderDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            removedIndexes: [],
            activeTab: 'original_order',
            outboundItemFilter: '',
            selected: false,
            selectedCount: 0,
            selectedRows: [],
            finalizing: false,
            outboundFilterValue: '',
            inboundReceiptValue: '',
            downloading: false,
            isAdding: false, // Is the component adding items to the purchase order via the CSV import?
        }

        autoBind(this)
    }

    componentDidMount() {
        this.props.getPurchaseOrder(this.props.params.id)
    }

    /* eslint-disable react/no-did-update-set-state */
    componentDidUpdate() {
        if (this.props.purchase_order.pdf && this.state.downloading) {
            this.setState({
                downloading: false,
            }, () => {
                this.downloadPurchaseOrderPdf(this.props.purchase_order.pdf)
            })
        }
    }
    /* eslint-enable react/no-did-update-set-state */

    downloadPurchaseOrderPdf(pdf_data) {
        const anchor = document.createElement('a')
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('download', `${this.props.purchase_order.order_id}.pdf`)
        anchor.setAttribute('href', `data:application/pdf;base64,${pdf_data}`)
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }

    handleMarkAsSubmittedClick() {
        this.props.markAsSubmitted(this.props.purchase_order._id)
    }

    handleDownloadAsPdfClick() {
        this.setState({ downloading: true }, () => {
            this.props.getPurchaseOrderAsPdf(this.props.purchase_order._id)
        })
    }

    handleCancelClick() {
        const data = {
            author: 'warehouse',
            order_id: this.props.purchase_order._id,
            source: 'purchase',
        }
        this.props.submitCancellation(data)
    }

    handleOutboundFilterChange({ target }) {
        this.setState({ outboundFilterValue: target.value })
        clearTimeout(this.outboundItemFilterTimeout)
        this.outboundItemFilterTimeout = setTimeout(() => {
            this.setState({ outboundItemFilter: this.state.outboundFilterValue })
        }, 500)
    }

    handleReceiptFilterChange({ target }) {
        this.setState({ inboundReceiptValue: target.value })
        clearTimeout(this.inboundReceiptFilterTimeout)
        this.inboundReceiptFilterTimeout = setTimeout(() => {
            this.setState({ inboundItemFilter: this.state.inboundReceiptValue })
        }, 500)
    }

    getDraftActions() {
        return <CardActions style={style.buttonContainer} />
    }

    getActions() {
        const { finalised_at, status } = this.props.purchase_order
        switch (status) {
            case Statuses.DRAFT:
                return this.getDraftActions()
            case Statuses.CLOSED:
                return null
            default:
                break
        }

        const finalised = finalised_at !== null
        const cancelled = status === Statuses.CANCELLED
        const submitted = status === Statuses.SUBMITTED
        const submittedText = !submitted && !finalised ? 'Mark as submitted' : 'This order is marked as submitted'
        const cancelledText = cancelled ? 'This order is cancelled' : 'Cancel'

        return (
            <CardActions style={style.buttonContainer}>
                <FlatButton
                    onClick={this.handleDownloadAsPdfClick}
                    hoverColor={Colors.indigo50}
                    primary={true}
                    labelPosition={'after'}
                    key="download"
                    label="Download"
                    icon={<FontIcon className="material-icons">file_download</FontIcon>}
                />
                <FlatButton
                    style={{
                        marginLeft: 30,
                        color: !submitted && !finalised ? Colors.green800 : Colors.green200,
                        display: cancelled ? 'none' : 'inherit',
                    }}
                    onClick={this.handleMarkAsSubmittedClick}
                    hoverColor={Colors.secondaryColor100}
                    disabled={submitted}
                    labelPosition={'after'}
                    key="submit"
                    label={submittedText}
                    icon={<FontIcon className="material-icons">done</FontIcon>}
                />
                <FlatButton
                    style={{
                        marginLeft: 30,
                        color: !cancelled ? Colors.red100 : Colors.red200,
                        display: submitted ? 'none' : 'inherit',
                    }}
                    onClick={this.handleCancelClick}
                    hoverColor={Colors.red50}
                    disabled={finalised}
                    labelPosition={'after'}
                    key="cancel"
                    label={cancelledText}
                    icon={<FontIcon className="material-icons">close</FontIcon>}
                />
            </CardActions>
        )
    }

    handleAddNote(note) {
        this.props.addNote(this.props.purchase_order._id, {
            author: this.props.email,
            value: note,
            date: new Date().toISOString(),
        })
    }

    getSelection() {
        const { selected, selectedCount, selectedRows } = this.state
        return {
            selected,
            selectedCount,
            selectedRows,
        }
    }

    handleRowSelection(id, data) {
        this.setState(data)
    }

    handleFinalizeDraftClick() {
        this.setState({
            finalizing: true,
        })
    }

    handleDownloadCsvExampleClick() {
        return downloadAsCsv(
            {
                headers: IMPORT_CSV_FILE_COLUMNS.map(c => c.name),
                rows: IMPORT_CSV_FILE_EXAMPLE_DATA,
            },
            'purchase-order-line-items'
        )
    }

    handleImportCsvClick() {
        if (this.state.isAdding) return

        new Promise(resolve => this.setState({ isAdding: true }, resolve))
            .then(handleFileUpload)
            .then(({ contents }) => {
                const { data } = parseCsv(contents)

                if (!data || !data.length) {
                    throw new Error('Csv file has no data rows')
                }

                // Remove last empty row
                const lastRow = data[data.length - 1]
                const isLastRowEmpty = Object.keys(lastRow).length <= 1 && !lastRow[Object.keys(lastRow)[0]]
                const dataRows = isLastRowEmpty ? data.slice(0, -1) : [ ...data ]

                if (!dataRows || !dataRows.length) {
                    throw new Error('Csv file has no data rows')
                }

                return dataRows
            })
            // Validate column headers
            .then(dataRows => {
                const headers = Object.keys(dataRows[0])

                for (let i = 0; i < IMPORT_CSV_FILE_COLUMNS.length; i++) {
                    const column = IMPORT_CSV_FILE_COLUMNS[i]

                    if (column.required && !headers[i]) {
                        throw new Error(
                            `Csv file should have a ${column.name} column as the ${numberToOrdinal(i + 1)} column.`
                        )
                    }

                    if (typeof headers[i] !== 'undefined' && headers[i] !== column.name) {
                        throw new Error(`The ${numberToOrdinal(i + 1)} column should be named '${column.name}'`)
                    }
                }

                return dataRows
            })
            // Validate row data
            .then(dataRows => {
                const errorRows = []

                for (let i = 0; i < dataRows.length; i++) {
                    const dataRow = dataRows[i]

                    const isValid =
                        dataRow.sku && !!dataRow.sku.length &&
                        dataRow.quantity && dataRow.quantity.length &&
                        !Number.isNaN(parseInt(dataRow.quantity, 10)) &&
                        dataRow.personalised && dataRow.personalised.length

                    if (!isValid) {
                        errorRows.push({ ...dataRow, _rowNumber: i + 2 })
                    }
                }

                if (errorRows.length) {
                    const error = new Error('CSV is invalid')
                    error.code = 'ValidationError'
                    error.rows = errorRows
                    throw error
                }

                return dataRows
            })
            // Normalize data of all rows
            .then(dataRows => {
                const headers = Object.keys(dataRows[0])

                return dataRows
                    .map(rowData => {
                        const newData = { ...rowData }

                        for (let i = 0; i < headers.length && i < IMPORT_CSV_FILE_COLUMNS.length; i++) {
                            const header = headers[i]
                            const column = IMPORT_CSV_FILE_COLUMNS[i]

                            newData[header] = column.normalize(rowData[header])
                        }

                        return newData
                    })
            })
            // Find variants for data-rows
            .then(dataRows => getVariantsBySkuOrEan(dataRows)
                .then(variants => ({ variants, dataRows }))
            )
            // Find a variant for each data-row
            .then(({ variants, dataRows }) => dataRows
                .map((dataRow, i) => {
                    let variant = variants.find(v => v.supplier_sku === dataRow.sku)
                    if (!variant) variant = variants.find(v => v.ean === dataRow.ean)
                    return { ...dataRow, _variant: variant, _rowNumber: i + 2 }
                })
            )
            // Throw a ValidationError if no variant could be found for a data-row
            // or the variant is for an other supplier
            .then(dataRows => {
                const errorRows = [
                    ...( // No variant could be found
                        dataRows
                            .filter(dataRow => !dataRow._variant)
                            .map(dataRow => ({
                                ...dataRow,
                                _errorMessage: `Row ${dataRow._rowNumber}: ` +
                                    `No Variant with sku: ${dataRow.sku}, ean: ${dataRow.ean}`,
                            }))
                    ),
                    ...( // Variant is for an other supplier
                        dataRows
                            .filter(dataRow => {
                                return dataRow._variant &&
                                    dataRow._variant.supplier.mage_id !== this.props.purchase_order.supplier.mage_id
                            })
                            .map(dataRow => ({
                                ...dataRow,
                                _errorMessage: `Row ${dataRow._rowNumber}: ` +
                                    `Variant with sku: ${dataRow.sku}, ean: ${dataRow.ean} ` +
                                    `is for supplier ${dataRow._variant.supplier.name} ` +
                                    `(${dataRow._variant.supplier.mage_id})`,
                            }))
                    ),
                    ...( // Variant has no purchase_price
                        dataRows
                            .filter(dataRow => {
                                return dataRow._variant &&
                                    (
                                        !dataRow._variant.pricing ||
                                        !dataRow._variant.pricing.purchase_price
                                    )
                            })
                            .map(dataRow => ({
                                ...dataRow,
                                _errorMessage: `Row ${dataRow._rowNumber}: ` +
                                    `Variant with sku: ${dataRow.sku}, ean: ${dataRow.ean} ` +
                                    'has no purchase price',
                            }))
                    ),
                ]

                if (errorRows.length) {
                    const error = new Error('CSV is invalid')
                    error.code = 'ValidationError'
                    error.rows = errorRows
                    throw error
                }

                return dataRows
            })
            // Create purchase_order.line_items from data-rows
            .then(dataRows => {
                return dataRows
                    .map(dataRow => {
                        const variant = dataRow._variant

                        return {
                            name: variant.name,
                            supplier_sku: variant.supplier_sku,
                            ean: variant.ean || '',
                            color: variant.color || '',
                            size: variant.size || '',
                            lead_times: variant.lead_times,
                            pricing: variant.pricing,
                            type: variant.fulfillment_source,
                            quantity: dataRow.quantity,
                            reason: '',
                            mage_id: dataRow.salesorder || '',
                            personalised: dataRow.personalised,
                            supplier: variant.supplier,
                        }
                    })
            })
            // Update purchase-order with new line_items
            .then(line_items => {
                if (this.isDraft()) {
                    // LineItems currently in the purchase-order
                    const props_line_items = [ ...this.props.purchase_order.line_items ]

                    // LineItems that are being added, currently already in the purchase-order
                    const existing_line_items = line_items.filter(({ supplier_sku }) => {
                        return props_line_items.find(l => l.supplier_sku === supplier_sku)
                    })

                    // LineItems that are being added, currently not in the purchase-order
                    const new_line_items = line_items.filter(({ supplier_sku }) => {
                        return !props_line_items.find(l => l.supplier_sku === supplier_sku)
                    })

                    for (const existing_line_item of existing_line_items) {
                        const line_item = props_line_items.find(l => {
                            return l.supplier_sku === existing_line_item.supplier_sku
                        })

                        // overwrite attributes with attributes from import
                        line_item.quantity = existing_line_item.quantity
                        line_item.personalised = existing_line_item.personalised
                        line_item.ean = existing_line_item.ean
                        line_item.mage_id = existing_line_item.mage_id
                    }

                    this.props.updatePurchaseOrderLineItems(
                        this.props.purchase_order.order_id,
                        [ ...props_line_items, ...new_line_items ]
                    )

                    this.props.showOkNotification({
                        message: `Succesfully added/changed ${line_items.length} ` +
                            `${pluralize('line-item', line_items)} ` +
                            `in purchase-order ${this.props.purchase_order.order_id}`,
                    })
                } else {
                    this.props.showErrorNotification({
                        error: `Status of purchase-order ${this.props.purchase_order.order_id} ` +
                            'should be DRAFT to import line-items via CSV.',
                    })
                }

                return new Promise(resolve => this.setState({ isAdding: false }, resolve))
            })
            .catch(error => {
                if (error.code === 'ValidationError') {
                    this.props.showErrorNotification({
                        message: [
                            `${error.message} in ${pluralize('row', error.rows)} ` +
                                `${[ ...new Set(error.rows.map(errorRow => errorRow._rowNumber)) ].join(', ')}`,
                            ...error.rows.filter(errorRow => errorRow._errorMessage)
                                .map(errorRow => errorRow._errorMessage),
                        ],
                        duration: 20000,
                    })
                } else {
                    this.props.showErrorNotification(error)
                }

                this.setState({ isAdding: false })
            })
    }

    handleNavigateToSalesOrderClick(sales_number) {
        this.props.getSalesOrderByMageId(sales_number)
    }

    handleNavigateToOutboundClick() {
        this.context.router.push(`/purchases/purchase-orders/outbound/${this.props.purchase_order.order_id}`)
    }

    getHeaderActions() {
        const actions = []
        const status = this.props.purchase_order.status
        if (
            [Statuses.SUBMITTED].includes(status)
            && (
                this.context.features?.purchaseOrderOutboundTriage
                || this.props.roles?.includes('external-supply-chain')
            )
        ) {
            actions.push(
                <Button
                    key="navigate-to-outbound"
                    variant="contained"
                    color="primary"
                    leftIcon={<FontIcon className="material-icons">exit_to_app</FontIcon>}
                    onClick={this.handleNavigateToOutboundClick}
                >
                    Navigate to outbound
                </Button>
            )
        }
        if (status === Statuses.DRAFT) {
            actions.push(
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="split
                    button"
                    style={style.importCsvButton}
                >
                    <Button
                        key="import-csv"
                        variant="contained"
                        color="primary"
                        onClick={this.handleImportCsvClick}
                    >
                        Import CSV
                    </Button>
                    <Button
                        title="Download example CSV"
                        color="primary"
                        size="small"
                        onClick={this.handleDownloadCsvExampleClick}
                    >
                        <GetAppIcon />
                    </Button>
                </ButtonGroup>
            )

            actions.push(
                <Button
                    key="finalize-draft"
                    variant="contained"
                    color="secondary"
                    disabled={this.props.purchase_order.line_items.length === 0}
                    style={style.finalizeButton}
                    onClick={this.handleFinalizeDraftClick}
                >
                    Finalize draft
                </Button>
            )
        }
        return actions
    }

    handleTabChange(activeTab) {
        this.setState({ activeTab })
    }

    isDraft() {
        return this.props.purchase_order.status === Statuses.DRAFT
    }

    handleUpdatePurchaseOrder(data) {
        if (this.isDraft()) {
            this.props.updatePurchaseOrderDraft(this.props.purchase_order.order_id, data)
        }
    }

    handleFinalizeDraftDialogClose() {
        this.setState({ finalizing: false })
    }

    handleFinalizeDraftDialogSubmit(options) {
        this.props.finalizePurchaseOrderDraft(this.props.purchase_order.order_id, {
            submit: options.submitEmail,
            email: options.email,
        })
    }

    handleAddLineItem(line_item) {
        if (this.isDraft()) {
            this.props.updatePurchaseOrderLineItems(
                this.props.purchase_order.order_id,
                [
                    ...this.props.purchase_order.line_items,
                    line_item,
                ]
            )
        }
    }

    handleUpdateLineItem(data, index) {
        if (this.isDraft()) {
            this.props.updatePurchaseOrderLineItems(
                this.props.purchase_order.order_id,
                this.props.purchase_order.line_items.map((item, idx) => {
                    if (idx === index) {
                        return {
                            ...item,
                            ...data,
                        }
                    }
                    return item
                })
            )
        }
    }

    handleDeleteLineItem(index) {
        if (this.isDraft()) {
            this.props.updatePurchaseOrderLineItems(
                this.props.purchase_order.order_id,
                this.props.purchase_order.line_items.filter((nil, idx) => idx !== index)
            )
        }
    }

    renderDraftLineItems() {
        const order = this.props.purchase_order
        return (
            <DraftLineItems
                id={order._id}
                supplier={order.supplier}
                line_items={order.line_items}
                pricing={order.pricing}
                addOpen={this.state.addOpen}
                onAddLineItem={this.handleAddLineItem}
                onUpdateLineItem={this.handleUpdateLineItem}
                onDeleteLineItem={this.handleDeleteLineItem}
                onNavigateToSalesOrderClick={this.handleNavigateToSalesOrderClick}
                height={550}
            />
        )
    }

    renderNonDraftLineItems() {
        const order = this.props.purchase_order
        return (
            <LineItems
                id={order._id}
                wrapper={false}
                line_items={order.line_items}
                pricing={order.pricing}
                selection={this.getSelection()}
                addOpen={this.state.addOpen}
                onRowSelection={this.handleRowSelection}
                onNavigateToSalesOrderClick={this.handleNavigateToSalesOrderClick}
                height={550}
            />
        )
    }

    renderOutboundChecklist(order) {
        const outboundItemFilterRegex = new RegExp(this.state.outboundItemFilter, 'i')
        return [Statuses.SUBMITTED].includes(order.status) && (
            <Tab
                icon={<FontIcon className="material-icons">date_range</FontIcon>}
                label="Expected delivery"
                value="delivery"
            >
                <Card>
                    <TextField
                        style={style.textField}
                        floatingLabelText="Search delivery list"
                        value={this.state.outboundFilterValue}
                        onChange={this.handleOutboundFilterChange}
                    />
                    <ExpectedDelivery
                        outbound_items={(this.props.outbound_checklist.outbound_items || [])
                            .filter(item => {
                                return outboundItemFilterRegex.test(item.mage_id)
                                    || outboundItemFilterRegex.test(item.ean)
                                    || outboundItemFilterRegex.test(item.name)
                                    || outboundItemFilterRegex.test(item.supplier_sku)

                            })}
                    />
                </Card>
            </Tab>
        )
    }

    reduceSummedPurchaseItemsFromReceipts() {
        const order_id = this.props.purchase_order.order_id
        return this.props.inbound_receipts.reduce((acc, receipt) => {
            const items = receipt.line_items.filter(li => {
                return li.purchase_id === order_id
            })
            if (items.length === 0) {
                return acc
            }
            const summed = items.reduce((acc_item, item) => {
                const found = acc_item.find(i => i.sku === item.sku)
                if (found) {
                    found.quantity += 1
                } else {
                    acc_item.push({
                        sku: item.sku,
                        ean: item.ean,
                        name: item.name,
                        quantity: 1, // receipt items are always quantity of 1;
                        receipt_created_at: receipt.created_at,
                        receipt_increment_id: receipt.increment_id,
                    })
                }
                return acc_item
            }, [])
            return [...acc, ...summed]
        }, [])
    }

    renderInboundReceipts(order) {
        if (![Statuses.SUBMITTED, Statuses.CLOSED].includes(order.status)) {
            return null
        }
        const inboundItemFilterRegex = new RegExp(this.state.inboundItemFilter, 'i')
        const receipt_items = this.reduceSummedPurchaseItemsFromReceipts()
        return (
            <Tab
                icon={<FontIcon className="material-icons">list</FontIcon>}
                label="Inbound receipts"
                value="receipts"
            >
                <Card>
                    <TextField
                        style={style.textField}
                        floatingLabelText="Search receipts for item"
                        value={this.state.inboundReceiptValue}
                        onChange={this.handleReceiptFilterChange}
                    />
                    <ReceiptLineItems
                        receipt_items={receipt_items.filter(item => {
                            return inboundItemFilterRegex.test(item.ean)
                                || inboundItemFilterRegex.test(item.name)
                                || inboundItemFilterRegex.test(item.sku)
                        })}
                    />
                </Card>
            </Tab>
        )
    }

    render() {
        let content = []
        const order = this.props.purchase_order

        if (order.order_id) {
            content = [
                <Header
                    key="header"
                    title={order.order_id}
                    subheader={order.supplier.name}
                    rightButtons={
                        <div style={style.rightButtons}>
                            {moment(order.created_at).format('DD-MM-YY')}
                            <br />
                            <div style={style.rightButtonsContainer}>
                                {this.getHeaderActions()}
                            </div>
                        </div>
                    }
                />,
                <div
                    key="contents"
                    style={style.contents}
                >
                    <div style={style.left}>
                        <PurchaseOrderSpecifications
                            editable={order.status === Statuses.DRAFT}
                            purchase_order={order}
                            onUpdatePurchaseOrder={this.handleUpdatePurchaseOrder}
                        />
                        <div style={style.filler} />
                        <Notes
                            notes={order.notes}
                            onAddNote={this.handleAddNote}
                        />
                    </div>
                    <Tabs
                        style={style.tabs}
                        tabItemContainerStyle={style.tabsContainer}
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                    >
                        <Tab
                            icon={<FontIcon className="material-icons">receipt</FontIcon>}
                            label="Original order"
                            value="original_order"
                        >
                            <Card>
                                {order.status !== Statuses.DRAFT && this.renderNonDraftLineItems()}
                                {order.status === Statuses.DRAFT && this.renderDraftLineItems()}
                                {this.getActions()}
                            </Card>
                        </Tab>
                        {this.renderOutboundChecklist(order)}
                        {this.renderInboundReceipts(order)}
                    </Tabs>
                </div>,
            ]
        }
        return (
            <div style={style.container}>
                {content}
                {this.props.purchase_order && this.props.purchase_order.status === Statuses.DRAFT && (
                    <FinalizeDraftDialog
                        open={this.state.finalizing}
                        purchase_order={this.props.purchase_order}
                        onClose={this.handleFinalizeDraftDialogClose}
                        onSubmit={this.handleFinalizeDraftDialogSubmit}
                    />
                )}
            </div>
        )
    }
}

PurchaseOrderDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    purchase_order: PropTypes.object.isRequired,
    outbound_checklist: PropTypes.object.isRequired,
    inbound_receipts: PropTypes.array.isRequired,
    submitCancellation: PropTypes.func.isRequired,
    getPurchaseOrder: PropTypes.func.isRequired,
    markAsSubmitted: PropTypes.func.isRequired,
    getPurchaseOrderAsPdf: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    updateItems: PropTypes.func.isRequired,
    removeItems: PropTypes.func.isRequired,
    getSalesOrderByMageId: PropTypes.func.isRequired,
    updatePurchaseOrderDraft: PropTypes.func.isRequired,
    updatePurchaseOrderLineItems: PropTypes.func.isRequired,
    finalizePurchaseOrderDraft: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
    showOkNotification: PropTypes.func.isRequired,
}
PurchaseOrderDetail.contextTypes = {
    router: PropTypes.object,
    features: PropTypes.object.isRequired,
}
export default connect(({ purchase_order, outbound_checklist, inbound_receipts, autocomplete_variants = [] }) => {
    return {
        purchase_order,
        inbound_receipts,
        outbound_checklist,
        found_variants: autocomplete_variants,
    }
}, dispatch => {
    const actions = {
        submitCancellation,
        getPurchaseOrder,
        markAsSubmitted,
        getPurchaseOrderAsPdf,
        addItem,
        addNote,
        updateItems,
        removeItems,
        getSalesOrderByMageId,
        updatePurchaseOrderDraft,
        updatePurchaseOrderLineItems,
        finalizePurchaseOrderDraft,
        showErrorNotification,
        showOkNotification,
    }
    return bindActionCreators(actions, dispatch)
})(PurchaseOrderDetail)
