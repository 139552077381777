import store from '../../store'
import {searchPurchaseRequisitions} from '../../commands/sockets/purchase_requisitions'

export default function initPurchaseRequisitionsSockets(socket) {
    socket.on('purchase-requisitions', ({meta, data}) => {
        store.dispatch({type: 'REFRESH_PURCHASE_REQUISITIONS', purchase_requisitions: data})
        store.dispatch({type: 'REFRESH_PAGINATION_PURCHASE_REQUISITIONS', pagination: meta})
    })

    socket.on('purchase-requisitions/added', result => {
        const not_created = result.filter(r => r._created === false)
        if (not_created.length > 0) {
            store.dispatch({type: 'NOTIFICATION_ERROR',
                notification: {
                    message: `${not_created.map(n => n.supplier_sku).join(', ')
                    }zijn niet langer bestelbaar en dus niet toegevoegd`,
                }})
        }
        searchPurchaseRequisitions(store.getState().filters.purchase_requisitions)
    })

    socket.on('purchase-requisition/revoked', () => {
        searchPurchaseRequisitions(store.getState().filters.purchase_requisitions)
    })
}
