import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableHeaderColumn,
    TableRowColumn,
    TableFooter,
    TextField,
    SelectField,
    MenuItem,
    FlatButton,
    IconButton,
} from 'material-ui'

import SearchProductAutoComplete from '../../shared/SearchProductAutoComplete'

const initialState = {
    name: '',
    supplier_sku: '',
    ean: '',
    color: '',
    size: '',
    quantity: 1,
    reason: '',
    mage_id: '',
    personalised: null,
    supplier_id: '',
    _addingRow: false,
}

const style = {
    table: {
        width: '100%',
        overflowY: 'scroll',
        padding: 0,
    },
    tableRowColumn: {
        whiteSpace: 'wrap',
        verticalAlign: 'middle',
    },
    tableBody: {height: 500},
    textField: {fontSize: 13},
    tableColumn: {paddingLeft: 12, paddingRight: 12},
    selectField: {fontSize: 13, display: 'block'},
    menuItem: {fontSize: 13},
}
const reasons = [{
    value: '',
    text: '',
}, {
    value: 'sample-order',
    text: 'Sample order',
}, {
    value: 'warranty',
    text: 'Warranty',
}, {
    value: 'not-delivered',
    text: 'Not delivered',
}, {
    value: 'consignment',
    text: 'Consignment',
}, {
    value: 'other',
    text: 'Other',
}]

const personalise_options = [{
    value: true,
    text: 'Yes',
}, {
    value: false,
    text: 'No',
}]

class AddPurchaseRequisitionTable extends Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    handleChange(property, value) {
        const update = {}
        update[property] = value
        this.setState(update)
    }

    handleSaveClick() {
        const state = this.state
        this.setState(initialState, () => {
            state.mage_id = state.mage_id.trim()
            state.ean = state.ean.trim()
            state.supplier_sku = state.supplier_sku.trim()
            state.supplier = this.props.suppliers.find(s => s.mage_id === state.supplier_id)
            this.props.onAddItem(state)
        })
    }

    handleAddRowClick() {
        this.setState({
            _addingRow: true,
        })
    }

    handleSuggestionSelected(variant) {
        this.setState({
            _addingRow: false,
        }, () => {
            this.props.onAddItem({
                name: variant.name,
                supplier_sku: variant.supplier_sku,
                ean: variant.ean || '',
                color: variant.color || '',
                size: variant.size || '',
                quantity: 1,
                reason: '',
                mage_id: '',
                personalised: false,
                supplier: variant.supplier,
            })
        })
    }

    handleQuantityChange(index) {
        return ({target}) => {
            return this.props.onUpdateItem && this.props.onUpdateItem(index, {quantity: +target.value})
        }
    }

    handleMageIdChange(index) {
        return ({target}) => {
            return this.props.onUpdateItem && this.props.onUpdateItem(index, {mage_id: target.value})
        }
    }

    handlePersonalisedChange(index) {
        return (e, i, value) => {
            return this.props.onUpdateItem && this.props.onUpdateItem(index, {personalised: value})
        }
    }

    handleReasonChange(index) {
        return (e, i, value) => {
            return this.props.onUpdateItem && this.props.onUpdateItem(index, {reason: value})
        }
    }

    render() {
        return (
            <Table
                style={{
                    ...style.table,
                    display: this.props.visible ? 'table' : 'none',
                }}
                bodyStyle={style.tableBody}
                selectable={false}
            >
                <TableHeader style={{width: '100%'}} adjustForCheckbox={false} displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn style={style.tableRowColumn} width="20%">Product name*</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn} width="15%">
                            Supplier SKU*/EAN
                        </TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn}>Color/Size</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn}>Qty*</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn} width="15%">Supplier</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn} width="12%">Reason*</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn} width="10%">Sales order</TableHeaderColumn>
                        <TableHeaderColumn style={style.tableRowColumn}>Personalise*</TableHeaderColumn>
                        <TableHeaderColumn />
                    </TableRow>
                </TableHeader>
                <TableBody style={{width: '100%'}} displayRowCheckbox={false}>
                    {
                        this.props.line_items.map((line_item, index) => {
                            return (
                                <TableRow key={`${line_item.supplier_sku}-${index}`} selectable={false}>
                                    <TableRowColumn style={style.tableRowColumn} width="20%">
                                        {line_item.name}
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn} width="15%">
                                        SKU: {line_item.supplier_sku}<br />
                                        EAN: {line_item.ean}
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn}>
                                        Color: {line_item.color}<br />
                                        Size: {line_item.size}
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn}>
                                        <TextField
                                            style={{width: '100%'}}
                                            fullWidth={false}
                                            name={`${line_item.quantity}-${index}`}
                                            value={line_item.quantity}
                                            min={1}
                                            type="number"
                                            onChange={this.handleQuantityChange(index)}
                                        />
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn} width="15%">
                                        {line_item.supplier.name}
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn} width="12%">
                                        <SelectField
                                            menuStyle={{width: 'auto'}}
                                            style={style.selectField}
                                            onChange={this.handleReasonChange(index)}
                                            value={line_item.reason}
                                        >
                                            {
                                                reasons.filter(r => !!r.value).map(reason => {
                                                    return (
                                                        <MenuItem
                                                            style={style.menuItem}
                                                            key={reason.value}
                                                            value={reason.value}
                                                            primaryText={reason.text}
                                                        />
                                                    )
                                                })
                                            }
                                        </SelectField>
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn} width="10%">
                                        <TextField
                                            style={style.textField}
                                            fullWidth={false}
                                            name={`${line_item.mage_id}-${index}`}
                                            value={line_item.mage_id}
                                            onChange={this.handleMageIdChange(index)}
                                        />
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn}>
                                        <SelectField
                                            style={style.selectField}
                                            onChange={this.handlePersonalisedChange(index)}
                                            value={line_item.personalised}
                                        >
                                            {
                                                personalise_options.map(opt => {
                                                    return (
                                                        <MenuItem
                                                            style={{fontSize: 13}}
                                                            key={opt.value}
                                                            value={opt.value}
                                                            primaryText={opt.text}
                                                        />
                                                    )
                                                })
                                            }
                                        </SelectField>
                                    </TableRowColumn>
                                    <TableRowColumn style={style.tableRowColumn}>
                                        <IconButton
                                            onClick={() => { this.props.onRemoveItem(index) }}
                                            iconClassName="material-icons"
                                        >
                                            delete
                                        </IconButton>
                                    </TableRowColumn>
                                </TableRow>
                            )
                        })
                    }
                    {!this.state._addingRow ? null : (
                        <TableRow>
                            <TableRowColumn colSpan={10}>
                                <SearchProductAutoComplete
                                    onSuggestionSelected={this.handleSuggestionSelected.bind(this)}
                                />
                            </TableRowColumn>
                        </TableRow>
                    )}
                    <TableRow selectable={false} style={{height: 144}}>
                        <TableRowColumn width="20%">
                            <TextField
                                fullWidth={false}
                                value={this.state.name}
                                hintText={'Name'}
                                style={style.textField}
                                onChange={e => {this.handleChange('name', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="15%">
                            <TextField
                                fullWidth={false}
                                value={this.state.supplier_sku}
                                hintText={'SKU'}
                                style={style.textField}
                                onChange={e => {this.handleChange('supplier_sku', e.target.value)}}
                            /><br/>
                            <TextField
                                fullWidth={false}
                                value={this.state.ean}
                                hintText={'EAN'}
                                style={style.textField}
                                onChange={e => {this.handleChange('ean', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn>
                            <TextField
                                fullWidth={false}
                                value={this.state.color}
                                hintText={'Color'}
                                style={style.textField}
                                onChange={e => {this.handleChange('color', e.target.value)}}
                            /><br/>
                            <TextField
                                fullWidth={false}
                                value={this.state.size}
                                hintText={'Size'}
                                style={style.textField}
                                onChange={e => {this.handleChange('size', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn>
                            <TextField
                                style={{width: '100%'}}
                                value={this.state.quantity}
                                min={1}
                                type="number"
                                onChange={e => {this.handleChange('quantity', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn width="15%">
                            <SelectField
                                style={{width: '100%', overflow: 'hidden', margin: 0, fontSize: 13}}
                                floatingLabelStyle={{lineHeight: '0px'}}
                                underlineStyle={{bottom: 18}}
                                iconStyle={{top: 0}}
                                labelStyle={{lineHeight: '40px'}}
                                onChange={(e, index, value) => { this.handleChange('supplier_id', value) }}
                                value={this.state.supplier_id}
                                floatingLabelText="Supplier"
                            >
                                {
                                    this.props.suppliers.map(supplier => {
                                        return (
                                            <MenuItem
                                                style={style.menuItem}
                                                key={supplier.mage_id}
                                                value={supplier.mage_id}
                                                primaryText={supplier.name}
                                            />
                                        )
                                    })
                                }
                            </SelectField>
                        </TableRowColumn>
                        <TableRowColumn width="12%">
                            <SelectField
                                style={{width: '100%', overflow: 'hidden', margin: 0, fontSize: 13}}
                                floatingLabelStyle={{lineHeight: '0px'}}
                                underlineStyle={{bottom: 18}}
                                iconStyle={{top: 0}}
                                labelStyle={{lineHeight: '40px'}}
                                onChange={(e, index, value) => { this.handleChange('reason', value) }}
                                value={this.state.reason}
                                floatingLabelText="Reason"
                            >
                                {
                                    reasons.filter(r => !!r.value).map(reason => {
                                        return (
                                            <MenuItem
                                                style={{fontSize: 13}}
                                                key={reason.value}
                                                value={reason.value}
                                                primaryText={reason.text}
                                            />
                                        )
                                    })
                                }
                            </SelectField>
                        </TableRowColumn>
                        <TableRowColumn width="10%">
                            <TextField
                                value={this.state.mage_id}
                                hintText="Order #"
                                style={style.textField}
                                onChange={e => {this.handleChange('mage_id', e.target.value)}}
                            />
                        </TableRowColumn>
                        <TableRowColumn>
                            <SelectField
                                style={{width: '100%', overflow: 'hidden', margin: 0, fontSize: 13, minWidth: 100}}
                                floatingLabelStyle={{lineHeight: '0px'}}
                                underlineStyle={{bottom: 18}}
                                iconStyle={{top: 0}}
                                labelStyle={{lineHeight: '40px'}}
                                onChange={(e, index, value) => { this.handleChange('personalised', value) }}
                                value={this.state.personalised}
                                floatingLabelText="Yes/No"
                            >
                                {
                                    personalise_options.map(opt => {
                                        return (
                                            <MenuItem
                                                style={{fontSize: 13}}
                                                key={opt.value}
                                                value={opt.value}
                                                primaryText={opt.text}
                                            />
                                        )
                                    })
                                }
                            </SelectField>
                        </TableRowColumn>
                        <TableRowColumn>
                            <IconButton
                                onClick={this.handleSaveClick.bind(this)}
                                iconClassName="material-icons"
                                disabled={!this.state.name
                                    || !this.state.supplier_sku
                                    || !this.state.reason
                                    || typeof this.state.personalised !== 'boolean'
                                }
                            >
                                done
                            </IconButton>
                        </TableRowColumn>
                    </TableRow>
                </TableBody>
                <TableFooter adjustForCheckbox={false}>
                    <TableRow>
                        <TableRowColumn colSpan={10}>
                            <FlatButton
                                primary={true}
                                label="Add auto-fill row"
                                onClick={this.handleAddRowClick.bind(this)}
                            />
                        </TableRowColumn>
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

AddPurchaseRequisitionTable.propTypes = {
    visible: PropTypes.bool,
    line_items: PropTypes.array,
    suppliers: PropTypes.array,
    onAddItem: PropTypes.func.isRequired,
    onUpdateItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
}

AddPurchaseRequisitionTable.defaultProps = {
    visible: false,
    line_items: [],
}


export default AddPurchaseRequisitionTable
