import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'

function PersonalisationInstructions(props) {
    return (
        <Paper>
            <List>
                <ListSubheader>Extra instructions</ListSubheader>
                <ListItem>
                    <ListItemText primary={props.instructions} />
                </ListItem>
            </List>
        </Paper>
    )
}

PersonalisationInstructions.propTypes = {instructions: PropTypes.string}
export default PersonalisationInstructions
