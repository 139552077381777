import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Switch,
    FormControlLabel,
    Snackbar,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

const style = {
    margin: '0',
}

class ProductRequisitionSettings extends Component {

    constructor(props) {
        super(props)
        this.handleRequisitionExclusion = this.handleRequisitionExclusion.bind(this)
    }

    handleRequisitionExclusion() {
        this.props.onSubmit(!this.props.variant.requisition_disabled)
    }

    render() {
        return (
            <div>
                <FormControlLabel
                    style={style}
                    labelPlacement="start"
                    label={this.props.variant.requisition_disabled ?
                        'Variant requisition is disabled' : 'Variant requisition is enabled'}
                    control={
                        <Switch
                            disabled={!this.props.state}
                            checked={!!this.props.variant.requisition_disabled}
                            onClick={this.handleRequisitionExclusion}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                />
                <Snackbar open={this.props.variant.requisition_disabled}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={5}
                >
                    <Alert severity="warning">Warning: product requisition is turned off.</Alert>
                </Snackbar>
            </div>
        )
    }
}

ProductRequisitionSettings.propTypes = {
    variant: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    state: PropTypes.bool.isRequired,
}
ProductRequisitionSettings.defaultProps = {}

export default ProductRequisitionSettings

