import socket from './../../socket'
import { getUserEmail } from '../../store'

export function createDraftRtv(supplier, type, line_items = []) {
    socket.emit('rtv/create-draft', { supplier, type, line_items }, getUserEmail())
    return { type: 'CREATE_DRAFT_RTV', supplier }
}

export function getRtv(_id) {
    socket.emit('rtv/get', { _id })
    return { type: 'GET_RTV', _id }
}

export function updateVendorReference(rtv_id, vendor_ref) {
    socket.emit('rtv/update-vendor-ref', { rtv_id, vendor_ref }, getUserEmail())
    return { type: 'UPDATE_RTV_VENDOR_REF', rtv_id, vendor_ref }
}

export function addNote(_id, note) {
    socket.emit('rtv/note/create', { _id, note }, getUserEmail())
    return { type: 'ADD_RTV_NOTE', _id, note }
}

export function updateRtvStatus(rtv, status) {
    socket.emit('rtv/update', {
        _id: rtv._id,
        status,
    }, getUserEmail())
    return { type: 'UPDATE_RTV_STATUS', rtv, status }
}

export function requestRtv(rtv_id) {
    socket.emit('rtv/request', { rtv_id }, getUserEmail())
    return { type: 'REQUEST_RTV', rtv_id }
}

export function approveRtv(rtv_id) {
    socket.emit('rtv/approve', { rtv_id }, getUserEmail())
    return { type: 'APPROVE_RTV', rtv_id }
}

export function revertRequestRtv(rtv_id) {
    socket.emit('rtv/revert-request', { rtv_id }, getUserEmail())
    return { type: 'REVERT_REQUEST_RTV', rtv_id }
}

export function searchRtvs(filters) {
    socket.emit('rtvs/search', filters)
    return { type: 'SEARCH_RTVS', filters }
}

export function getRtvAsPdf(_id) {
    socket.emit('rtv/pdf/get', { _id })
    return { type: 'GET_RTV_AS_PDF', _id }
}

export function rejectRtv(_id) {
    socket.emit('rtv/reject', { _id }, getUserEmail())
    return { type: 'REJECT_RTV', _id }
}

export function settleRtvLineItems(rtv_id, line_items) {
    socket.emit('rtv/settle-line-items', { rtv_id, line_items }, getUserEmail())
    return { type: 'SETTLE_RTV_LINE_ITEMS', rtv_id, line_items }
}
