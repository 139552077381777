import autoBind from 'react-autobind'
import { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableRow,
    TableHeaderColumn,
    TableRowColumn,
    IconButton,
} from 'material-ui'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    revokePurchaseRequisition,
    revokePurchaseRequisitions,
} from './../../../../commands/sockets/purchase_requisitions'

import Colors from '../../../styles/colors'
import Pagination from './../../shared/Pagination'
import RevokePurchaseRequisitionDialog from './RevokePurchaseRequisitionDialog'

const style = {
    toolbar: {
        backgroundColor: '',
    },
    acceptButton: {
        marginLeft: 0,
        color: 'white',
        backgroundColor: Colors.green700,
    },
    rejectButton: {
        marginLeft: 10,
        color: 'white',
        backgroundColor: Colors.red700,
    },
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    selectedItemsText: {
        color: '#FFFFFF',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    iconButton: {
        float: 'right',
    },
    iconButtonStyle: {
        color: '#FFFFFF',
    },
    table: {
        width: '100%',
        overflow: 'inherit',
    },
    tableBodyRow: {
        cursor: 'pointer',
    },
    tableFooter: {
        height: 60,
    },
}

class PurchaseRequisitionTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: false,
            selectedItems: [],

            revokeDialogOpen: false,
        }

        autoBind(this)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    handleRowSelection(selectedIndex) {
        let selectedItemsClone = [...this.state.selectedItems]
        const targetItem = this.props.items[selectedIndex]

        if (!selectedItemsClone.includes(targetItem)) {
            selectedItemsClone.push(targetItem)
        } else {
            selectedItemsClone.splice(selectedItemsClone.indexOf(targetItem), 1)
        }

        const selected = selectedItemsClone.length > 0
        const update = {
            selected,
            selectedItems: selectedItemsClone,
        }
        this.setState(update)

        return selectedItemsClone
    }

    handleRevokeClick() {
        this.setState({
            revokeDialogOpen: true,
        })
    }

    getTableRowColor(status) {
        return this.props.statusColors[status]
    }

    isRowSelectable(item) {
        const feature = this.context.features.canRevokeRequisitions
        return (
            (
                item.business_rule === 'manual' ||
                (feature && feature[item.channel ? item.channel.slug : 'proforto-web-nl'])
            )
            && item.status === 'approved'
        ) || item.status === 'pending'
    }

    handleRevokeDialogSubmit(items) {
        this.handleCloseDialogClick()

        if (items.length === 1) {
            this.props.revokePurchaseRequisition(items[0])
        } else {
            this.props.revokePurchaseRequisitions(items)
        }

        this.setState({
            selected: false,
            selectedItems: [],
        })
    }

    handleCloseDialogClick() {
        this.setState({
            revokeDialogOpen: false,
        })
    }

    renderTableRows(items) {
        if (items.length > 0) {
            return this.props.items.map((item, index) => {
                const selectable = this.isRowSelectable(item)
                const backgroundColor = this.getTableRowColor(item.status)
                item.personalise_string = item.personalised ? 'Yes' : 'No'

                return (
                    <TableRow
                        selectable={selectable}
                        className={!selectable ? 'notSelectable' : ''}
                        selected={this.state.selectedItems.includes(item)}
                        key={item._id}
                        style={{ ...style.tableBodyRow, backgroundColor }}
                    >
                        {
                            this.props.headerCols.map(column => (
                                <TableRowColumn
                                    key={`${column.property}.${index}`}
                                    style={{ ...column.style, whiteSpace: 'wrap' }}
                                >
                                    {_.get(item, column.property, '')}
                                </TableRowColumn>
                            ))
                        }
                    </TableRow>
                )
            })
        }
        return (
            <TableRow style={style.blankListStateMessage}>
                <TableRowColumn>No items found.</TableRowColumn>
            </TableRow>
        )
    }

    renderNavigation(items) {
        if (items.length > 0) {
            return (
                <TableFooter style={style.tableFooter}>
                    <tr>
                        <td>
                            <Pagination
                                pagination={this.props.pagination}
                                page={this.props.page}
                                limit={this.props.limit}
                                onPreviousPageClick={this.props.onPageDecrement}
                                onNextPageClick={this.props.onPageIncrement}
                                items={this.props.items}
                            />
                        </td>
                    </tr>
                </TableFooter>
            )
        }
        return null
    }

    renderSelectedActions() {
        const selectedAmount = this.state.selectedItems.length
        const text = `${selectedAmount} item${selectedAmount > 1 ? 's' : ''} selected`
        return (
            <TableHeader displaySelectAll={false}>
                <TableRow
                    displayBorder={false}
                    style={{ backgroundColor: Colors.primaryColor40, ...style.selectedHeaderRow }}
                >
                    <th style={style.selectedItemsText}><span style={{ paddingLeft: '20px' }}>{text}</span></th>
                    <th colSpan="8" />
                    <th style={style.selectedIconActions}>
                        <IconButton
                            onClick={this.handleRevokeClick.bind(this)}
                            style={style.iconButton}
                            iconStyle={style.iconButtonStyle}
                            iconClassName="material-icons"
                        >
                            undo
                        </IconButton>
                    </th>
                </TableRow>
            </TableHeader>
        )
    }

    renderUnselectedActions() {
        return (
            <TableHeader displaySelectAll={false}>
                <TableRow>
                    {this.props.headerCols.map(column => (
                        <TableHeaderColumn
                            key={column.content}
                            style={column.style}
                            tooltip={column.tooltip}
                        >
                            {column.content}
                        </TableHeaderColumn>
                    ))}
                </TableRow>
            </TableHeader>
        )
    }

    renderHeader(selected) {
        return selected ? this.renderSelectedActions() : this.renderUnselectedActions()
    }

    render() {
        const header = this.renderHeader(this.state.selected)
        const rows = this.renderTableRows(this.props.items)
        const navigation = this.renderNavigation(this.props.items)

        return (
            <div>
                <Table style={style.table} multiSelectable={true} onCellClick={this.handleRowSelection.bind(this)}>
                    {header}
                    <TableBody showRowHover={true} deselectOnClickaway={false}>
                        {rows}
                    </TableBody>
                    {navigation}
                </Table>

                <RevokePurchaseRequisitionDialog
                    items={this.state.selectedItems}
                    visible={this.state.revokeDialogOpen}
                    onClose={this.handleCloseDialogClick}
                    onSubmit={this.handleRevokeDialogSubmit}
                />
            </div>
        )
    }
}

PurchaseRequisitionTable.contextTypes = {
    features: PropTypes.object,
}

PurchaseRequisitionTable.propTypes = {
    items: PropTypes.array,
    statusColors: PropTypes.array,
    pagination: PropTypes.object,
    headerCols: PropTypes.array,
    page: PropTypes.number,
    limit: PropTypes.number,
    shouldRender: PropTypes.bool,
    onPageDecrement: PropTypes.func,
    onPageIncrement: PropTypes.func,
    revokePurchaseRequisition: PropTypes.func,
    revokePurchaseRequisitions: PropTypes.func,
}

PurchaseRequisitionTable.defaultProps = {
    headerCols: [{
        content: 'Name',
        style: { width: '15%' },
        property: 'name',
        tooltip: 'The name of the product',
    }, {
        content: 'Supplier SKU',
        property: 'supplier_sku',
        style: { width: '11%' },
        tooltip: 'The SKU of the supplier',
    }, {
        content: 'EAN',
        property: 'ean',
        style: { width: '10%' },
        tooltip: 'The International Article Number',
    }, {
        content: 'Size',
        property: 'size',
        style: { width: '5%' },
        tooltip: 'The size',
    }, {
        content: 'Color',
        property: 'color',
        style: { width: '9%' },
        tooltip: 'The color',
    }, {
        content: 'Quantity',
        property: 'quantity',
        style: { width: '5%' },
        tooltip: 'Quantity of the stock queue item',
    }, {
        content: 'Personalise',
        property: 'personalise_string',
        style: { width: '8%' },
        tooltip: 'Is the article meant to be personalised',
    }, {
        content: 'Supplier',
        property: 'supplier.name',
        style: { width: '14%' },
        tooltip: 'Supplier of the item',
    }, {
        content: 'Reason',
        property: 'reason',
        style: { width: '8%' },
        tooltip: 'Reason it has been added',
    }, {
        content: 'Sales order',
        property: 'mage_id',
        style: { width: '10%' },
        tooltip: 'Reason it has been added',
    }, {
        content: 'Rule',
        property: 'business_rule',
        style: { width: '7%' },
        tooltip: 'Business rule applied',
    }],
}

export default connect(null, dispatch => {
    const actions = {
        revokePurchaseRequisition,
        revokePurchaseRequisitions,
    }
    return bindActionCreators(actions, dispatch)
})(PurchaseRequisitionTable)
