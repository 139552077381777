import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import SupplierFilter from './../../shared/SupplierFilter'

const styles = {
    filtersContainer: {
        height: 60,
        width: '95%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
        paddingTop: 16,
    },
    searchField: {
        width: '30%',
        marginRight: 15,
    },
    supplierFilter: {
        width: 400,
        marginRight: 15,
    },
    statusFilter: {
        width: 200,
        marginRight: 15,
    },
}

const RtvTypes = {
    STANDARD: 'standard',
    EXCESS_INVENTORY: 'excess-inventory',
}

class RtvFilters extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    shouldComponentUpdate(nextProps) {
        return JSON.stringify(nextProps.filters) !== JSON.stringify(this.props.filters) ||
            nextProps.suppliers !== this.props.suppliers
    }

    handleSupplierChange(e) {
        this.props.onSupplierChange(e.target.value)
    }

    handleCriteriaChange(e) {
        this.props.onCriteriaChange(e.target.value)
    }

    render() {
        return (
            <div style={styles.filtersContainer}>
                <TextField
                    style={styles.searchField}
                    label="Search by rtv number or vendor reference"
                    value={this.props.filters.criteria}
                    onChange={this.handleCriteriaChange}
                    InputLabelProps={{ shrink: true }}
                />
                <SupplierFilter
                    style={styles.supplierFilter}
                    onChange={this.handleSupplierChange}
                    value={this.props.filters.supplier_id}
                    allowAll={true}
                />
                <TextField
                    select
                    style={styles.statusFilter}
                    onChange={this.props.onStatusChange}
                    value={this.props.filters.status}
                    label="Select status"
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    {
                        this.props.statuses.map(status => (
                            <MenuItem
                                key={status}
                                value={status}
                            >
                                {status.replace(/_/g, ' ').replace(/\w/, c => c.toUpperCase())}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    select
                    style={styles.statusFilter}
                    onChange={this.props.onTypeChange}
                    value={this.props.filters.type}
                    label="Select type"
                >
                    <MenuItem
                        value="all"
                    >
                        All
                    </MenuItem>
                    {
                        Object.values(RtvTypes).map(type => (
                            <MenuItem
                                key={type}
                                value={type}
                            >
                                {type.replace(/-/g, ' ').replace(/\w/, c => c.toUpperCase())}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </div>
        )
    }
}

RtvFilters.propTypes = {
    suppliers: PropTypes.array,
    statuses: PropTypes.array,
    filters: PropTypes.object,
    onCriteriaChange: PropTypes.func.isRequired,
    onSupplierChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
}


export default RtvFilters
