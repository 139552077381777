import socket from './../../socket'
import { getUserEmail } from '../../store'

export function getRtvShipments(rtv_id) {
    socket.emit('rtv/shipments/get', { rtv_id }, getUserEmail())
}

export function getShipment(shipment_id) {
    socket.emit('shipment/get', { shipment_id }, getUserEmail())
}

export function getShipmentByTrackingCode(tracking_code) {
    socket.emit('shipment/get/tracking', tracking_code, getUserEmail())
    return { type: 'GET_SHIPMENT_BY_TRACKING_CODE', data: tracking_code }
}

export function checkoutShipment(shipment) {
    socket.emit('shipment/checkout', shipment, getUserEmail())
    return { type: 'CHECKOUT_SHIPMENT', data: shipment }
}
