import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardTitle, Dialog, FlatButton, TextField} from 'material-ui'

const style = {
    title: {
        height: 30, padding: 0,
    },
    subtitleStyle: {
        fontFamily: 'Roboto',
        width: '100%',
        display: 'inline-block',
    },
}

class RequestLabelsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quantity: 1,
        }
    }

    handleSubmit() {
        const state = this.state
        this.setState({
            quantity: 1,
        }, () => {
            this.props.onSubmit(state.quantity)
        })
    }

    handleTextFieldChange({target}) {
        const quantity = +target.value > 3 ? 3 : +target.value
        this.setState({quantity})
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                actions={[
                    <FlatButton label="Submit" key="submit" secondary={true} onClick={this.handleSubmit.bind(this)}/>,
                    <FlatButton label="Close" key="close" onClick={this.props.onClose}/>,
                ]}
            >
                <CardTitle
                    style={style.title}
                    subtitleStyle={style.subtitleStyle}
                    subtitle="How many labels do you want?"
                />
                <TextField
                    type="number"
                    min={1}
                    max={10}
                    value={this.state.quantity}
                    onChange={this.handleTextFieldChange.bind(this)}
                    floatingLabelText="Specify amount of labels (max 3)"
                />
            </Dialog>
        )
    }
}

RequestLabelsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
RequestLabelsDialog.defaultProps = {
    open: false,
}

export default RequestLabelsDialog
