import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import autoBind from 'react-autobind'
import Colors from '../../../styles/colors'
import { barcodeScanner } from '../../../utils/barcodescanner'

const style = {
    input: {
        width: 200,
    },
    errorMessage: {
        color: Colors.red600,
    },
}

export default class RmaCartDialog extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            value: (props.rma_carts && props.rma_carts[props.typeOfCart]) || '',
            barcodeScanId: Math.floor(Math.random() * 10000000000000000),
        }
    }

    componentDidMount() {
        barcodeScanner.addListener(this.state.barcodeScanId, this.handleBarcodeScan)
    }

    componentWillUnmount() {
        barcodeScanner.removeListener(this.state.barcodeScanId)
    }

    handleBarcodeScan(scan) {
        const value = scan.toUpperCase()

        this.setState({ value }, () => {
            // When cart is valid immediately submit it
            if (this.isValidCart(value)) {
                this.handleSubmit()
            }
        })
    }

    handleChange(e) {
        this.setState({ value: String(e.target.value).toUpperCase() })
    }

    handleClose() {
        this.setState({ value: '' })
        if (typeof this.props.onClose === 'function') this.props.onClose()
    }

    handleSubmit(event = null) {
        if (event) event.preventDefault()

        if (this.isValidCart(this.state.value)) {
            const value = this.state.value
            this.setState({ value: '' })
            this.props.onSubmit(value, this.props.typeOfCart)
        }
    }

    /**
     * Check if input is a valid cart (K000)
     * @param {string} cart Cart string
     * @returns {boolean} Whether it is a valid cart string
     */
    isValidCart(cart) {
        return /^K[0-9]{3}$/.test(cart)
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleClose}>
                <DialogTitle>
                    Choose {this.props.typeOfCart} cart
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <TextField
                            autoFocus
                            style={style.input}
                            label={this.props.typeOfCart}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            value={this.state.value}
                        />
                        {!this.state.value || this.isValidCart(this.state.value)
                            ? <div>&nbsp;</div>
                            : <div style={style.errorMessage}>Wrong format (K000)</div>
                        }
                    </form>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        key="save"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={!this.isValidCart(this.state.value)}
                    >
                        <SaveIcon />
                    </IconButton>
                    <IconButton
                        key="close"
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
            </Dialog>
        )
    }
}

RmaCartDialog.propTypes = {
    typeOfCart: PropTypes.string.isRequired,
    rma_carts: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
}

RmaCartDialog.defaultProps = {
    open: false,
    onClose: null,
}
