import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Avatar,
    Paper,
    CardTitle,
    List,
    ListItem,
    RaisedButton,
    FontIcon,
} from 'material-ui'
import moment from 'moment'
import { Droppable } from '@proforto/react-drag-and-drop'

import Colors from './../../../styles/colors'

import ItemDateGroup from './ItemDateGroup'

const style = {
    title: {
        display: 'inline-block',
        height: 30,
        width: '50%',
        marginBottom: 20,
    },
    titleStyle: {
        fontFamily: 'Roboto',
        width: '100%',
        fontSize: 17,
        lineHeight: '5px',
        fontWeight: 'bold',
        display: 'inline-block',
    },
    primaryTextListItemStyle: {
        fontSize: 14,
        fontFamily: 'Roboto',
    },
}

const status_map = {
    approved: {
        icon: 'check',
        backgroundColor: Colors.green600,
    },
    approved_closed: {
        icon: 'local_shipping',
        backgroundColor: Colors.green600,
    },
    postponed: {
        icon: 'date_range',
        backgroundColor: Colors.amber600,
    },
    rejected: {
        icon: 'close',
        backgroundColor: Colors.red600,
    },
    rejected_closed: {
        icon: 'block',
        backgroundColor: Colors.red600,
    },
}

class ItemStatusGroup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            over: false,
            enableAction: false,
            selectedItems: [],
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDateGroup.status !== 'postponed' && prevProps.status === 'postponed') {
            return {
                enableAction: false,
            }
        }
        return null
    }

    handleDrop(data) {
        this.setState({ over: false }, () => {
            switch (this.props.status) {
                case 'approved':
                    this.props.onApproveItems(data)
                    break

                case 'postponed':
                    this.props.onPostponeItems(data)
                    break

                case 'rejected':
                    this.props.onRejectItems(data)
                    break

                default:
                    break
            }
        })
    }

    handleDragEnter() {
        if (!this.state.over) {
            this.setState({ over: true })
        }
    }

    handleDragLeave() {
        if (this.state.over) {
            this.setState({ over: false })
        }
    }

    handleSelectItems(status, date, selectedItems) {
        if (status === 'postponed') {
            this.setState({
                enableAction: true,
                selectedItems,
            })
        }
        this.props.onSelectItems(status, date)
    }

    shouldReset(props, date, status) {
        if (props.selectedDateGroup.date === null && props.selectedDateGroup.status === null) {
            return false
        }
        return moment(props.selectedDateGroup.date).format('DD-MM-YY') !== date
            || props.selectedDateGroup.status !== status
    }

    handleActionClick() {
        const data = {}
        data[this.props.status] = this.state.selectedItems

        switch (this.props.status) {
            case 'postponed':
                return this.handleDrop(data)

            default:
                break
        }

        return false
    }

    getAction(status) {
        if (['approved', 'rejected'].includes(status)) {
            return null
        }
        return (
            <RaisedButton
                style={{ height: 40 }}
                onClick={this.handleActionClick.bind(this)}
                disabled={!this.state.enableAction}
                labelColor="#FFF"
                backgroundColor={Colors.amber600}
                label="Postpone selected"
            />
        )
    }

    isAllClosedByStatus(status, check, items) {
        return status === check && items.every(({ closed_at }) => closed_at)
    }

    getAvatarByStatus(status, triage_items) {
        if (this.isAllClosedByStatus(status, 'approved', triage_items)) {
            status = 'approved_closed'
        } else if (this.isAllClosedByStatus(status, 'rejected', triage_items)) {
            status = 'rejected_closed'
        }
        const data = status_map[status]
        return (
            <Avatar
                size={40}
                icon={<FontIcon className="material-icons">{data.icon}</FontIcon>}
                color="#FFF"
                backgroundColor={data.backgroundColor}
            />
        )
    }

    render() {
        const listItems = this.props.triage_items.sort((curr, next) => {
            return new Date(curr.date) > new Date(next.date) ? 1 : -1
        }).map(item_group => {
            const formattedDate = moment(item_group.date).format('DD-MM-YY')
            return (
                <List key={item_group.date} style={{ backgroundColor: '#fff' }}>
                    <ListItem
                        initiallyOpen={true}
                        insetChildren={false}
                        leftAvatar={this.getAvatarByStatus(this.props.status, item_group.triage_items)}
                        primaryTogglesNestedList={true}
                        primaryText={
                            !item_group.date ? 'Unknown delivery date' : (
                                <div style={style.primaryTextListItemStyle}>
                                    {`Expected delivery date: ${formattedDate}`}
                                </div>
                            )
                        }
                        nestedItems={[
                            <ItemDateGroup
                                key="item_date_group"
                                draggable={this.props.draggable}
                                wrap={false}
                                status={item_group.status}
                                reset={this.shouldReset(this.props, formattedDate, item_group.status)}
                                triage_items={item_group.triage_items}
                                onSelectItems={this.handleSelectItems.bind(this)}
                            />,
                        ]}
                    />
                </List>
            )
        })
        const overlay = !this.state.over ? null : (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    backgroundColor: '#ebf2f9',
                    zIndex: 5,
                    opacity: 0.7,
                }}
            />
        )
        return (
            <div style={this.props.style || style.container}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle style={style.title} titleStyle={style.titleStyle} title={this.props.title} />
                    {this.getAction(this.props.status)}
                </div>
                <Paper style={{ height: '100%', backgroundColor: '#eee' }}>
                    <Droppable
                        style={{ position: 'relative', width: '100%', height: '100%' }}
                        types={this.props.types}
                        onDragEnter={this.handleDragEnter.bind(this)}
                        onDragLeave={this.handleDragLeave.bind(this)}
                        onDrop={this.handleDrop.bind(this)}
                    >
                        {overlay}
                        {listItems}
                    </Droppable>
                </Paper>
            </div>
        )
    }
}

ItemStatusGroup.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    types: PropTypes.array,
    triage_items: PropTypes.array,
    selectedDateGroup: PropTypes.array,
    onApproveItems: PropTypes.func,
    onPostponeItems: PropTypes.func,
    onRejectItems: PropTypes.func,
    onSelectItems: PropTypes.func,
}
ItemStatusGroup.defaultProps = {
    draggable: true,
}

export default ItemStatusGroup
