import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import Tooltip from '@material-ui/core/Tooltip'

import {
    Avatar,
    Paper,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ExpansionPanelActions,
    Button,
} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import PrintIcon from '@material-ui/icons/Print'
import SyncIcon from '@material-ui/icons/Sync'

import { downloadBase64AsPdf } from '../../../../../utils/download'

import { getInvoiceByDebitMemo } from '../../../../../../commands/sockets/invoices'

import Colors from './../../../../../styles/colors'
import LineItems from './../../../../finance/debits/debit-memo-detail/LineItems'

const styles = {
    expansionPanelSummary: {
        lineHeight: '40px',
    },
    expansionPanelDetails: {
        padding: '8px 0 16px',
    },
    expansionPanelFooter: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    listItemInner: {
        marginLeft: 0,
    },
}

const STATUSES = {
    draft: {
        icon: SyncProblemIcon,
        color: Colors.amber700,
        title: 'Nog niet geaccepteerd',
    },
    prepared: {
        icon: PrintIcon,
        color: Colors.teal300,
        title: 'In Exact Online, maar nog niet geprint',
    },
    invoiced: {
        icon: SyncIcon,
        color: Colors.secondaryColor100,
        title: 'Definitief in Exact Online',
    },
}

class DebitMemo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadedInvoice: null,
        }

        autoBind(this)
    }

    componentDidUpdate() {
        if (this.state.downloadedInvoice &&
            this.props.invoice.id === this.state.downloadedInvoice) {
            this.downloadInvoice(this.props.invoice)
        }
    }

    downloadInvoice(invoice) {
        if (!invoice.error) {
            this.setState({
                downloadedInvoice: null,
            }, () => {
                downloadBase64AsPdf(invoice.blob, this.props.memo.invoice_id)
            })
        }
    }

    getTotalPricing(debit) {
        return {
            version: debit.version,
            subtotal: debit.subtotal,
            discount: debit.discount_amount,
            fixed_discount_excl_tax: debit.fixed_discount_excl_tax || '0.0000', // allow v2 and v3 debits
            tax: debit.tax_amount,
            total: debit.grand_total,
        }
    }

    handleButtonClick() {
        const id = this.props.memo._id
        this.setState({
            downloadedInvoice: id,
        }, () => {
            this.props.getInvoiceByDebitMemo(id)
        })
    }

    render() {
        const memo = this.props.memo
        const Icon = STATUSES[memo.status].icon
        return (
            <Paper>
                <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary style={styles.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                        <Tooltip key={`debit-memo-status-${memo._id}`} title={STATUSES[memo.status].title}>
                            <Avatar
                                style={{ backgroundColor: STATUSES[memo.status].color }}
                            >
                                <Icon />
                            </Avatar>
                        </Tooltip>
                        &nbsp;
                        Debit Memo
                        {
                            memo.status === 'draft' ? <i> (Draft)</i> : (
                                <span>
                                    &nbsp;
                                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                        onClick={() => {
                                            this.context.router.push(`/finance/debit-memos/${memo.mage_id}`)
                                        }}
                                    >
                                        {memo.invoice_id}
                                    </a>
                                </span>
                            )
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={styles.expansionPanelDetails}>
                        <Divider />
                        <LineItems
                            key="debit_memo.line_items.1"
                            showTitle={false}
                            parent={props => (<div style={props.style}>{props.children}</div>)}
                            items={memo.line_items}
                            total_pricing={this.getTotalPricing(memo)}
                        />
                        <Divider />
                    </ExpansionPanelDetails>
                    {!memo.invoice_id ? null : (
                        <ExpansionPanelActions style={styles.expansionPanelFooter}>
                            <Button
                                color="primary"
                                onClick={this.handleButtonClick.bind(this)}
                            >
                                <CloudDownloadIcon />&nbsp;
                                Download
                            </Button>
                        </ExpansionPanelActions>
                    )}
                </ExpansionPanel>
            </Paper >
        )
    }
}

DebitMemo.propTypes = {
    memo: PropTypes.object,
    invoice: PropTypes.object,
    getInvoiceByDebitMemo: PropTypes.func.isRequired,
}
DebitMemo.defaultProps = {}
DebitMemo.contextTypes = {
    router: PropTypes.object.isRequired,
}

export default connect(({ invoice }) => {
    return { invoice }
}, dispatch => {
    return bindActionCreators({ getInvoiceByDebitMemo }, dispatch)
})(DebitMemo)
