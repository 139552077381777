import socket from '../socket'
import listeners from '../listeners/sockets/stock_location.js'

listeners(socket)

export function stock_location(state = [], action) {
    switch (action.type) {
        case 'REFRESH_STOCK_LOCATION':
            return action.data ? action.data : []

        default:
            return state
    }
}
