import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core'

const style = {
    name: {
        paddingRight: 12,
    },
}

class PersonalisationImpressionDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: null,
            type: null,
            data: null,
        }

        autoBind(this)
    }

    handleUploadClick() {
        const input = document.createElement('input')
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            this.setState({ data: reader.result })
        })
        input.setAttribute('type', 'file')
        input.style.display = 'none'
        input.onchange = () => {
            const file = input.files[0]
            this.setState({
                error: null,
                name: file.name,
                type: file.type,
            }, () => {
                reader.readAsDataURL(file)
            })
        }
        document.body.appendChild(input)
        input.click()
    }

    handleSubmitClick() {
        this.props.onSubmit(this.state, this.props.position)
    }

    render() {
        const impressions = this.props.impressions
        const lastUploadedImpression = impressions.length > 0
            ? impressions[this.props.impressions.length - 1]
            : {}
        const impression = this.state.name ? this.state : {
            name: lastUploadedImpression.file,
        }
        const cannotSubmit = !this.state.data
        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={this.props.open}
            >
                <DialogTitle>Upload new impression</DialogTitle>
                <DialogContent>
                    {impression.name
                        ? <strong style={style.name}>{impression.name}</strong>
                        : <i style={style.name}>No impression yet</i>
                    }
                    <Button
                        color="primary"
                        onClick={this.handleUploadClick}
                    >
                        {`${impression.name ? 'Change' : 'Upload'} impression`}
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={cannotSubmit}
                        onClick={this.handleSubmitClick}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.onClose}
                        label="Close"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PersonalisationImpressionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    position: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    impressions: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
PersonalisationImpressionDialog.defaultProps = {
    impressions: [],
}

export default PersonalisationImpressionDialog
