import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import { downloadStringArrayAsCsv } from './../../utils/download'

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
} from '@material-ui/core'

const styles = {
    dialogPaper: {
        minWidth: 840,
        maxWidth: 840,
    },
}

class LocationsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadingFreeLocations: false,
        }

        autoBind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.locations.length > 0 && prevState.downloadingFreeLocations) {
            downloadStringArrayAsCsv(nextProps.locations, 'location', 'free_locations')
            return {
                downloadingFreeLocations: false,
            }
        }
        return null
    }

    handleDownloadFreeLocationsClick() {
        this.setState({ downloadingFreeLocations: true }, () => {
            this.props.onDownloadFreeLocationsClick()
        })
    }

    handleClose() {
        return this.props.onClose && this.props.onClose()
    }

    render() {
        return (
            <Dialog open={true} PaperProps={{ style: styles.dialogPaper }}>
                <DialogTitle>Locations</DialogTitle>
                <DialogContent>
                    <Button color="primary" contained onClick={this.handleDownloadFreeLocationsClick}>
                        Download free locations
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button contained onClick={this.handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

LocationsDialog.propTypes = {
    locations: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onDownloadFreeLocationsClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default LocationsDialog
