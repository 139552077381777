import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField} from 'material-ui'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {searchSuppliers} from './../../../commands/sockets/suppliers'
import {getFeatures} from '../../../commands/sockets/settings'

import ShippingCarrierFilter from '../shared/ShippingCarrierFilter'
import SupplierTable from './SupplierTable'

const style = {
    filtersContainer: {
        height: 60,
        width: '95%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
    },
}

const fields = 'mage_id,name,email,phone,shipping_carrier'
const STORAGE_KEY = 'supplier-filters'

class SupplierList extends Component {
    constructor(props) {
        super(props)
        getFeatures()
        const filters = this.getFilters()
        this.filterSupplierCarrier = this.filterSupplierCarrier.bind(this)
        this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this)
        this.state = {
            shouldTableRender: false,
            searchValue: filters.searchValue || '',
            shippingCarrierFilter: 'All',
        }
    }

    static getDerivedStateFromProps() {
        return {
            shouldTableRender: true,
        }
    }

    componentDidMount() {
        this.searchSuppliers()
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters ||
                {}
        } catch (e) {
            return this.props.filters || {}
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    searchSuppliers() {
        const filters = {
            fields,
            searchValue: this.state.searchValue,
        }
        this.storeFilters(filters)
        this.props.searchSuppliers(filters)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchSuppliers.bind(this), 500)
    }

    handleSearchFieldChange(e) {
        this.setState({
            ...this.state,
            searchValue: e.target.value,
            shouldTableRender: false,
        }, this.setSearchTimeout.bind(this))
    }

    handleFilterFieldChange(value) {
        this.setState({
            ...this.state,
            shippingCarrierFilter: value,
        }, this.setSearchTimeout.bind(this))
    }

    filterSupplierCarrier(supplier) {
        if (this.state.shippingCarrierFilter === 'All') {
            return true
        }
        return supplier.shipping_carrier === this.state.shippingCarrierFilter
    }

    render() {
        return (
            <div>
                <div style={style.filtersContainer}>
                    <TextField
                        style={{height: 72, marginRight: 20, width: '100%'}}
                        inputStyle={{height: 30, lineHeight: '14px', bottom: 12, position: 'absolute'}}
                        hintStyle={{lineHeight: '20px', fontSize: 14, fontWeight: 'bold'}}
                        floatingLabelText="Search for name or Magento ID"
                        onChange={this.handleSearchFieldChange.bind(this)}
                        value={this.state.searchValue}
                    />
                    <ShippingCarrierFilter
                        value={this.state.shippingCarrierFilter}
                        onChange={(e, index, value) => { this.handleFilterFieldChange(value) }}
                        options={this.props.filterable_shipping_carriers}
                    />
                </div>
                <SupplierTable
                    shouldRender={this.state.shouldTableRender}
                    suppliers={this.props.suppliers.filter(this.filterSupplierCarrier)}
                />
            </div>
        )
    }
}

SupplierList.propTypes = {
    suppliers: PropTypes.array,
    filters: PropTypes.object,
    searchSuppliers: PropTypes.func.isRequired,
    session: PropTypes.object,
    filterable_shipping_carriers: PropTypes.array,
}

export default connect(({ suppliers, filters }) => {
    // Get available shipping carrier filter options based on suppliers "shipping_carrier attribute"
    const current_shipping_carriers = [...new Set(
        suppliers.map(item => item.shipping_carrier).filter(v => v)
    )]
    return {
        suppliers,
        filters: filters.suppliers,
        filterable_shipping_carriers: current_shipping_carriers ? current_shipping_carriers : [],
    }
}, dispatch => {
    const actions = { searchSuppliers, getFeatures }
    return bindActionCreators(actions, dispatch)
})(SupplierList)
