export function destroySession() {
    return {type: 'DESTROY_SESSION'}
}

export function createSession(err, profile, token) {
    return {type: 'CREATE_SESSION', session: {err, profile, token}}
}

export function setRmaViewPreference(preference) {
    return {type: 'SET_RMA_VIEW_PREFERENCE', preference}
}
