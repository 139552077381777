import store from '../../store'
import { push } from 'react-router-redux'

export default function initSalesOrdersSockets(socket) {
    socket.on('orders/search-result', sales_orders => {
        store.dispatch({type: 'REFRESH_SALES_ORDERS', sales_orders})
    })

    socket.on('order', sales_order => {
        store.dispatch({type: 'REFRESH_SALES_ORDER', sales_order})
    })

    socket.on('order/navigate', ({_id}) => {
        store.dispatch(push(`/sales/sales-orders/${_id}`))
    })
}
