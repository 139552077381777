import React from 'react'
import PropTypes from 'prop-types'
import ChannelAvatar from './ChannelAvatar'
import {InputAdornment, TextField, MenuItem, Divider} from '@material-ui/core'

function ChannelFilter(props) {
    return (
        <TextField
            select
            style={props.style || {width: 280, marginRight: 15}}
            onChange={props.onChange}
            value={props.channel}
            label="Select channel"
            SelectProps={{MenuProps: {style: {zIndex: 1600}}}}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: props.channel === 'all' ? null : (
                    <InputAdornment position="start">
                        <ChannelAvatar slug={props.channel} style={{width: 20, height: 20}} />
                    </InputAdornment>
                ),
            }}
        >
            <MenuItem
                value="all"
            >
                All
            </MenuItem>
            <Divider />
            <MenuItem
                value="proforto-web-nl"
            >
                Proforto.nl
            </MenuItem>
            <MenuItem
                value="proforto-web-be"
            >
                Proforto.be
            </MenuItem>
            <MenuItem
                value="proforto-sales"
            >
                Proforto Sales
            </MenuItem>
            <MenuItem
                value="proforto-sme"
            >
                Proforto SME
            </MenuItem>
            <MenuItem
                value="proforto-reseller"
            >
                Proforto Reseller
            </MenuItem>
            <MenuItem
                value="proforto-bol"
                primaryText="Proforto Bol"
            >
                Proforto Bol.com
            </MenuItem>
            <MenuItem
                value="proforto-amazon-de"
            >
                Proforto Amazon.de
            </MenuItem>
            <Divider />
            <MenuItem
                value="xerox"
            >
                Xerox
            </MenuItem>
            <Divider />
            <MenuItem
                value="emma-web-nl"
            >
                EmmaFootwear.nl
            </MenuItem>
            <MenuItem
                value="emma-web-de"
            >
                EmmaFootwear.de
            </MenuItem>
            <MenuItem
                value="emma-amazon-de"
            >
                EmmaFootwear Amazon.de
            </MenuItem>
            <Divider />
            <MenuItem value="tricorp-web-nl">
                Tricorpstore.com
            </MenuItem>
        </TextField>
    )
}

ChannelFilter.propTypes = {
    style: PropTypes.object,
    channel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}
ChannelFilter.defaultProps = {
    channel: 'all',
}

export default ChannelFilter
