import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import {
    AppBar, Button, Tabs, Tab, Typography, TableFooter, TableRow, TableCell, Tooltip, IconButton,
} from '@material-ui/core'


import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ListIcon from '@material-ui/icons/List'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import WarningIcon from '@material-ui/icons/Warning'
import SendIcon from '@material-ui/icons/Send'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'

import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    getRtv,
    requestRtv,
    revertRequestRtv,
    approveRtv,
    addNote,
    updateVendorReference,
    getRtvAsPdf,
    rejectRtv,
    settleRtvLineItems,
} from './../../../../commands/sockets/rtvs'
import Header from './../../shared/Header'
import Notes from './../../shared/Notes'
import LineItems from './../LineItems'
import RtvSpecifications from './rtv-detail/RtvSpecifications'
import Shipments from './../../sales/typed-orders/Shipments'

import { Statuses } from '../../../types/returns/rtv'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    actionButton: {
        marginRight: 20,
        color: 'white',
    },
    headerButtons: {
        float: 'right',
        marginTop: -14,
        textAlign: 'right',
    },
    rightButtons: {
        display: 'flex',
        flexFlow: 'row',
    },
    left: {
        display: 'flex',
        flexFlow: 'column',
        width: '30%',
        marginRight: 15,
    },
    lineItemsStyle: {
        card: {
            width: '100%',
        },
    },
    iconButton: {
        color: 'white',
    },
    tabs: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
    },
    contentWithIcon: {
        width: 'auto',
        float: 'left',
    },
    icon: {
        float: 'left',
        display: 'block',
        width: 14,
        height: 14,
    },
    tableFooterCell: {
        color: '#000000',
    },
    requiredRefWarning: {
        padding: 10,
        lineHeight: '32px',
    },
}

const lineItemColumnsUnsplitted = [{
    content: 'name',
    tooltip: 'The product name',
    style: { width: '35% ' },
    property: 'name',
}, {
    content: 'sku',
    tooltip: 'The product variant sku',
    style: { width: '24% ' },
    property: 'supplier_sku',
}, {
    content: 'EAN',
    tooltip: 'The product variant EAN',
    style: { width: '15% ' },
    property: 'ean',
}, {
    content: 'Purchase price',
    tooltip: 'The total purchase price of the line',
    style: { width: '12% ' },
    property: 'pricing.row_purchase_price',
}, {
    content: 'quantity',
    tooltip: 'The amount',
    style: { width: '10% ' },
    property: 'quantity',
}]

const lineItemColumnsSplitted = [{
    content: 'name',
    tooltip: 'The product name',
    style: { width: '35% ' },
    property: 'name',
}, {
    content: 'sku',
    tooltip: 'The product variant sku',
    style: { width: '24% ' },
    property: 'supplier_sku',
}, {
    content: 'EAN',
    tooltip: 'The product variant EAN',
    style: { width: '15% ' },
    property: 'ean',
}, {
    content: 'Purchase price',
    tooltip: 'The total purchase price of the line',
    style: { width: '12% ' },
    property: 'pricing.purchase_price',
}, {
    content: 'quantity',
    tooltip: 'The amount',
    style: { width: '10% ' },
    property: 'quantity',
}]

function TabContainer(props) {
    return (
        <Typography component="div" style={style.tabContainer}>
            {props.children}
        </Typography>
    )
}

TabContainer.propTypes = { children: PropTypes.node }

function VendorReferenceRequiredMessage({ color }) {
    if (!style.requiredRefWarning.color) {
        style.requiredRefWarning.color = color
    }
    return (
        <span style={style.requiredRefWarning}>
            <WarningIcon /> A vendor reference is required before approving the RTV.
        </span>
    )
}

VendorReferenceRequiredMessage.propTypes = {
    color: PropTypes.string.isRequired,
}

class RtvDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadingPdf: null,
            tab: 0,
            selectedChoices: {},
            requiredVendorRef: false,
        }

        autoBind(this)
    }

    componentDidMount() {
        if (!this.props.rtv._id || this.props.rtv._id !== this.props.params.id) {
            this.props.getRtv(this.props.params.id)
        }
    }

    componentDidUpdate() {
        if (this.props.rtv.pdf && this.props.rtv._id === this.state.downloadingPdf) {
            this.downloadRtvPdf(this.props.rtv.pdf)
        }
    }

    downloadRtvPdf(pdf_data) {
        this.setState({ downloadingPdf: null }, () => {
            const anchor = document.createElement('a')
            anchor.setAttribute('target', '_blank')
            anchor.setAttribute('download',
                `${this.props.rtv.type === 'standard' ? 'RTV' : 'EIR'}-${this.props.rtv.rtv_id}.pdf`)
            anchor.setAttribute('href', `data:application/pdf;base64,${pdf_data}`)
            anchor.style.display = 'none'
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        })
    }

    showRequiredVendorRef() {
        clearTimeout(this.showRequiredVendorRefTimeout)
        this.setState({ showRequiredVendorRef: true }, () => {
            this.showRequiredVendorRefTimeout = setTimeout(() => {
                this.setState({ showRequiredVendorRef: false })
            }, 5000)
        })
    }

    handleVendorReferenceSave(vendor_ref) {
        this.setState({ showRequiredVendorRef: false }, () => {
            this.props.updateVendorReference(this.props.rtv.rtv_id, vendor_ref)
        })
    }

    handleDownloadAsPdf() {
        this.setState({
            downloadingPdf: this.props.rtv._id,
        }, () => {
            this.props.getRtvAsPdf(this.props.rtv._id)
        })
    }

    handleRequest() {
        this.props.requestRtv(this.props.rtv.rtv_id)
    }

    handleRevertRequest() {
        this.props.revertRequestRtv(this.props.rtv.rtv_id)
    }

    handleSupplierApproval() {
        const supplier = this.props.rtv.supplier
        if ((!supplier.rtv_ref_required || supplier.rtv_ref_required) && this.props.rtv.vendor_ref) {
            this.props.approveRtv(this.props.rtv.rtv_id)
        } else {
            this.showRequiredVendorRef()
        }
    }

    handleTabChange(e, tab) {
        this.setState({ tab })
    }

    getActions(status, line_items) {
        const children = []
        if (status === Statuses.DRAFT) {
            const disabled = line_items.length === 0
            children.push(
                <Tooltip key="request" title="Update status to requested">
                    <Button
                        startIcon={<SendIcon />}
                        style={{
                            ...style.actionButton,
                            backgroundColor: disabled ? this.context.colors.green200 : this.context.colors.green600,
                        }}
                        variant="contained"
                        disabled={disabled}
                        onClick={this.handleRequest}
                    >
                        Request
                    </Button>
                </Tooltip>
            )
        } else if (status === Statuses.REQUESTED) {
            children.push(
                <Tooltip title="Supplier has approved RTV">
                    <Button
                        startIcon={<DoneIcon />}
                        style={style.actionButton}
                        color="secondary"
                        key="pre-approve"
                        variant="contained"
                        onClick={this.handleSupplierApproval}
                    >
                        Approved by supplier
                    </Button>
                </Tooltip>,
                <Tooltip title="Change status back to draft">
                    <Button
                        startIcon={<CancelScheduleSendIcon />}
                        style={{ ...style.actionButton, backgroundColor: this.context.colors.amber600 }}
                        key="revert_request"
                        variant="contained"
                        onClick={this.handleRevertRequest}
                    >
                        Revert request
                    </Button>
                </Tooltip>
            )
        }
        children.push(
            <Button
                key="download"
                variant="contained"
                onClick={this.handleDownloadAsPdf}
                color="primary"
            >
                <ArrowDownwardIcon />
                &nbsp;
                Download
            </Button>
        )
        return (
            <div style={style.rightButtons}>
                {children}
            </div>
        )
    }

    handleSupplierAccept(selectedRows, resetSelected) {
        const selectedChoices = selectedRows.reduce((acc, row) => {
            acc[row] = true
            return acc
        }, { ...this.state.selectedChoices })
        this.setState({ selectedChoices }, resetSelected)
    }

    handleSupplierDecline(selectedRows, resetSelected) {
        const selectedChoices = selectedRows.reduce((acc, row) => {
            acc[row] = false
            return acc
        }, { ...this.state.selectedChoices })
        this.setState({ selectedChoices }, resetSelected)
    }

    handleSelectionSave() {
        const selected = this.state.selectedChoices
        this.setState({ selectedChoices: {} }, () => {
            const items = this.getLineItemsPerOneQuantity(this.props.rtv.line_items).reduce((acc, li, index) => {
                if (li.status) {
                    return acc
                }
                const is_selected = typeof selected[index] === 'boolean'
                if (is_selected) {
                    acc.push({
                        ...li,
                        status: selected[index] ? 'accepted' : 'rejected',
                    })
                }
                return acc
            }, [])
            this.props.settleRtvLineItems(this.props.rtv.rtv_id, items)
        })
    }

    renderActionButtons(status, selectedRows, resetSelected) {
        switch (status) {
            case Statuses.GOODS_DELIVERED:
                return [
                    <IconButton
                        key="accept"
                        style={style.iconButton}
                        onClick={() => this.handleSupplierAccept(selectedRows, resetSelected)}
                    >
                        <DoneIcon />
                    </IconButton>,
                    <IconButton
                        key="decline"
                        style={style.iconButton}
                        onClick={() => this.handleSupplierDecline(selectedRows, resetSelected)}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]
            default: return null
        }
    }

    getLineItemsPerOneQuantity(items) {
        const unwinded_items = []
        items.forEach(item => {
            for (let i = 0; i < item.quantity; i++) {
                unwinded_items.push({
                    ...item,
                    quantity: 1,
                })
            }
        })
        return unwinded_items
    }

    getLineItems(items, rtv_status, allSelected) {
        if (rtv_status === Statuses.GOODS_DELIVERED && !allSelected) {
            items = this.getLineItemsPerOneQuantity(items)
        }
        return items.map((item, index) => {
            if (item.status) { // comes from prop so is from database, cannot be chosen.
                item.selectable = false
                return item
            }
            const choice = this.state.selectedChoices[index]
            const dup = { ...item }
            dup.selectable = true
            if (choice === true) {
                dup.status = 'accepted'
            } else if (choice === false) {
                dup.status = 'rejected'
            }
            return dup
        })
    }

    getLineItemContent(items, status, pricing) {
        const selectable = status === Statuses.GOODS_DELIVERED
        const allSelected = items.every(i => i.status)
        return (
            <LineItems
                style={style.lineItemsStyle}
                headerCols={selectable && allSelected ? lineItemColumnsSplitted : lineItemColumnsUnsplitted}
                status={status}
                type="rtv"
                selectable={selectable}
                displaySelectAll={selectable && !allSelected}
                line_items={this.getLineItems(items, status, allSelected)}
                footer={pricing && (
                    <TableFooter>
                        <TableRow>
                            {selectable && !allSelected && (
                                <TableCell padding="checkbox">
                                    <Button
                                        onClick={this.handleSelectionSave}
                                        variant="contained"
                                        color="secondary"
                                        style={style.actionButton}
                                        fullWidth={true}
                                        disabled={Object.keys(this.state.selectedChoices).length === 0}
                                    >
                                        Save
                                    </Button>
                                </TableCell>
                            )}
                            <TableCell colSpan={3} style={style.tableFooterCell}>
                                Total purchase price
                            </TableCell>
                            <TableCell style={style.tableFooterCell}>
                                {!pricing.missing_purchase_price
                                    ? pricing.total_purchase_price
                                    : [
                                        <div key="content" style={style.contentWithIcon}>
                                            {pricing.total_purchase_price} &nbsp;
                                        </div>,
                                        <WarningIcon
                                            key="icon"
                                            style={{ ...style.icon, color: this.context.colors.amber700 }}
                                        />,
                                    ]
                                }

                            </TableCell>
                            <TableCell style={style.tableFooterCell}>
                                {items.reduce((acc, i) => acc + i.quantity, 0)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
                renderActionButtons={this.renderActionButtons}
            />
        )
    }

    getPrefix(type) {
        switch (type) {
            case 'standard':
                return 'RTV'
            case 'excess-inventory':
                return 'EIR'
            default:
                return ''
        }
    }

    render() {
        const rtv = this.props.rtv
        if (!rtv._id) {
            return <div />
        }
        return (
            <div style={style.container}>
                <Header
                    title={`${this.getPrefix(rtv.type)} - ${rtv.rtv_id}`}
                    showSupplier={true}
                    supplier={rtv.supplier}
                    rightButtons={
                        <div style={style.headerButtons}>
                            {moment(rtv.created_at).format('DD-MM-YYYY')}
                            {this.getActions(rtv.status, rtv.line_items)}
                            {this.state.showRequiredVendorRef && (
                                <VendorReferenceRequiredMessage color={this.context.colors.red600} />
                            )}
                        </div>
                    }
                    subheader={`Status: ${rtv.status.replace('_', ' ')}`}
                />
                <div style={{ display: 'flex', flexFlow: 'row', paddingTop: 15 }}>
                    <div style={style.left}>
                        <RtvSpecifications
                            value={rtv.vendor_ref}
                            submitted_at={rtv.submitted_at}
                            status={rtv.status}
                            type={rtv.type}
                            onSave={this.handleVendorReferenceSave}
                        />
                        <Notes
                            style={{ paddingTop: 15 }}
                            onAddNote={note => {
                                this.props.addNote(rtv._id, {
                                    author: this.props.email,
                                    value: note,
                                    date: new Date().toISOString(),
                                })
                            }}
                            notes={rtv.notes}
                        />
                    </div>
                    <div style={style.tabs}>
                        <AppBar position="static" color="primary">
                            <Tabs variant="fullWidth" value={this.state.tab} onChange={this.handleTabChange}>
                                <Tab icon={<ListIcon />} label="Line items" />
                                {this.props.shipments.length === 0 ? null : (
                                    <Tab icon={<LocalShippingIcon />} label="Shipments" />
                                )}
                            </Tabs>
                        </AppBar>
                        {this.state.tab === 0 && (
                            <TabContainer>
                                {this.getLineItemContent(rtv.line_items, rtv.status, rtv.pricing)}
                            </TabContainer>
                        )}
                        {this.state.tab === 1 && (
                            <TabContainer>
                                <Shipments
                                    style={{
                                        width: '100%',
                                        float: 'right',
                                    }}
                                    type="rtv"
                                    date={false}
                                    actions={true}
                                    shipments={this.props.shipments}
                                    labelAsPdf={true}
                                />
                            </TabContainer>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

RtvDetail.contextTypes = {
    colors: PropTypes.object,
}

RtvDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    rtv: PropTypes.object.isRequired,
    shipments: PropTypes.array,
    addNote: PropTypes.func,
    getRtv: PropTypes.func,
    updateVendorReference: PropTypes.func,
    getRtvAsPdf: PropTypes.func,
    rejectRtv: PropTypes.func.isRequired,
    requestRtv: PropTypes.func.isRequired,
    revertRequestRtv: PropTypes.func.isRequired,
    approveRtv: PropTypes.func.isRequired,
    settleRtvLineItems: PropTypes.func.isRequired,
}

RtvDetail.defaultProps = {
    rtv: {},
    shipments: [],
}

export default connect(({ rtv, shipments }) => {
    return { rtv, shipments }
}, dispatch => {
    const actions = {
        addNote,
        getRtv,
        updateVendorReference,
        getRtvAsPdf,
        rejectRtv,
        requestRtv,
        revertRequestRtv,
        approveRtv,
        settleRtvLineItems,
    }
    return bindActionCreators(actions, dispatch)
})(RtvDetail)
