import socket from '../socket'
import listeners from '../listeners/sockets/invoices'

listeners(socket)

export function invoice(state = [], action) {
    switch (action.type) {
        case 'DOWNLOAD_INVOICE':
            return action.invoice

        default:
            return state
    }
}
