import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    MenuItem,
} from '@material-ui/core'

const style = {
    dialogPaper: {
        minWidth: 640,
    },
    dialogActions: {
        paddingRight: 16,
        paddingBottom: 16,
    },
}

class ProductPhaseOutDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            option: null,
            all_variants: false,
        }
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleAllVariantsCheck = this.handleAllVariantsCheck.bind(this)
    }

    handleOptionChange(e) {
        this.setState({option: e.target.value})
    }

    handleDialogSubmit() {
        const state = this.state
        this.setState({option: null, all_variants: false}, () => {
            this.props.onSubmit(state)
            this.handleDialogClose()
        })
    }

    handleDialogClose() {
        this.props.onClose()
    }

    handleAllVariantsCheck(e) {
        this.setState({all_variants: e.target.checked})
    }

    getOptions() {
        if (this.props.variant.fulfillment_source === 'warehouse') {
            return [
                <MenuItem key="end-of-life" value="end-of-life">
                    Niet meer bijbestellen, bij lege voorraad uitschakelen
                </MenuItem>,
                <MenuItem key="to-crossdock" value="to-crossdock">
                    Voortaan bestellen als crossdock en bij voorraad 0 omzetten naar crossdock
                </MenuItem>,
            ]
        } else if (this.props.variant.fulfillment_source === 'crossdock') {
            return (
                <MenuItem key="end-of-life" value="end-of-life">
                    Direct uitschakelen en niet meer bijbestellen
                </MenuItem>
            )
        }
        return []
    }

    render() {
        if (!this.props.variant) {
            return null
        }
        return (
            <Dialog PaperProps={{style: style.dialogPaper}} open={this.props.open}>
                <DialogTitle>Hoe wil je dit artikel uitfaseren?</DialogTitle>
                <DialogContent>
                    <TextField
                        id="option"
                        select
                        fullWidth
                        label="Kies optie"
                        value={this.state.option}
                        onChange={this.handleOptionChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{shrink: true}}
                        hintStyle="Selecteer een optie"
                    >
                        {this.getOptions()}
                    </TextField>
                </DialogContent>
                <DialogActions style={style.dialogActions}>
                    <Button
                        variant="contained"
                        onClick={this.handleDialogClose}
                    >
                        Sluiten
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogSubmit}
                        disabled={!this.state.option}
                    >
                        Uitfaseren
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ProductPhaseOutDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    variant: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
ProductPhaseOutDialog.defaultProps = {}

export default ProductPhaseOutDialog
