import React from 'react'
import PropTypes from 'prop-types'
import { Paper, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core'
import moment from 'moment'

function PersonalisationOrderDetailSpecs({ production_order, customer_delivery_date, style = {} }) {
    return (
        <Paper style={style}>
            <List>
                <ListSubheader>Production specs</ListSubheader>
                <ListItem>
                    <ListItemText
                        primary="Created at"
                        secondary={moment(production_order.created_at).format('DD-MM-YYYY HH:mm')}
                    />
                </ListItem>
                {production_order.impression_accepted_at && (
                    <ListItem>
                        <ListItemText
                            primary="Accepted at"
                            secondary={moment(production_order.impression_accepted_at).format('DD-MM-YYYY HH:mm')}
                        />
                    </ListItem>
                )}
                {production_order.produced_at && (
                    <ListItem>
                        <ListItemText
                            primary="Produced at"
                            secondary={moment(production_order.produced_at).format('DD-MM-YYYY HH:mm')}
                        />
                    </ListItem>
                )}

                {customer_delivery_date && (
                    <ListItem>
                        <ListItemText
                            primary="Customer delivery date"
                            secondary={moment(customer_delivery_date).format('DD-MM-YYYY')}
                        />
                    </ListItem>
                )}
            </List>
        </Paper>
    )
}

PersonalisationOrderDetailSpecs.propTypes = {
    style: PropTypes.object,
    production_order: PropTypes.object.isRequired,
    customer_delivery_date: PropTypes.string,
}

PersonalisationOrderDetailSpecs.defaultProps = {
    style: {},
    production_order: {
        created_at: moment().toISOString(),
    },
}

export default PersonalisationOrderDetailSpecs
