import socket from './../socket'
import listeners from '../listeners/sockets/wall.js'

listeners(socket)

export function compartments(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            state = []
            break
        case 'REFRESH_COMPARTMENTS':
            return action.data
        default:
            return state
    }

    return state
}

export function compartment(state = '', action) {
    switch (action.type) {
        case 'REFRESH_COMPARTMENT':
            return action.data
        case 'UNREGISTER_PACKING_STATION':
            return ''
        default:
            return state
    }
}
