import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

function Payment(props) {
    const payment_info = props.payment_info || {}

    return !payment_info.method ? null : (
        <Paper style={{ position: 'relative', ...props.style }}>
            <List subheader={<ListSubheader>Payment info</ListSubheader>}>
                <ListItem>
                    <ListItemText
                        primary="Payment method"
                        secondary={payment_info.method}
                    />
                </ListItem>
                <ListItem>
                    {props.bol_ref && (
                        <ListItemText
                            primary="Bol ref"
                            secondary={props.bol_ref}
                        />
                    )}
                </ListItem>
            </List>
        </Paper>
    )
}

Payment.propTypes = {
    payment_info: PropTypes.object,
    bol_ref: PropTypes.string,
    style: PropTypes.object,
}

Payment.defaultProps = {
    payment_info: {},
    bol_ref: '',
    style: {},
}

export default Payment
