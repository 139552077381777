import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autobind from 'react-autobind'

import reactUpdate from 'react-addons-update'
import moment from 'moment'
import get from 'lodash/get'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { searchTypedOrders } from './../../../../actions/typed_orders'
import { searchSuppliers } from './../../../../commands/sockets/suppliers'

import TypedOrderTable from './TypedOrderTable.js'
import TypedOrderFilters from './TypedOrderFilters.js'

const style = {
    container: {
        flex: '1 1 auto',
    },
    textField: {
        marginLeft: '50px',
    },
}

const fields = `
mage_id,order_id,type,supplier,fulfillment_id,created_at,status,shipments.estimated_for,shipments.line_items`

class TypedOrderList extends Component {

    constructor(props) {
        super(props)
        const filters = props.filters || {}
        const date = new Date()
        this.state = {
            filters: {
                search: filters.value || '',
                lateDelivery: filters.late_delivery || false,
                ignoreDelivery: filters.ignoreDelivery || true,
                unfulfilled: filters.unfulfilled || false,
                fromDate: moment(new Date(date.setHours(0, 0, 0, 0))).format('YYYY-MM-DD'),
                tillDate: moment(new Date(date.setHours(0, 0, 0, 0))).format('YYYY-MM-DD'),
                source: filters.source || 'all',
                destination: filters.destination || 'customer',
                supplier_id: get(filters, 'supplier.mage_id', 'all') || 'all',
            },
            page: filters.page || 1,
            limit: filters.limit || 20,
            isUpdated: false,
            dialogOpen: false,
        }

        autobind(this)
    }

    componentDidMount() {
        this.searchTypedOrders(this.state.filters)
        if (!this.props.supplier_id) {
            this.props.searchSuppliers({})
        }
    }

    searchTypedOrders() {
        const storageFilters = this.state.filters
        const filters = this.state.filters
        const mage_id = this.getMagentoSupplierId()
        const page = storageFilters.supplier_id === mage_id || mage_id === 'all' ?
            this.state.page : 1
        this.setState({ page }, () => {
            const parameters = this.buildSearchParameters(filters, page, mage_id)
            this.props.searchTypedOrders(parameters)
        })
    }

    getMagentoSupplierId() {
        return this.props.supplier_id || this.state.filters.supplier_id || null
    }

    getSources(source) {
        if (this.props.role === 'supplier') {
            return ['dropship']
        }
        return source === 'all' ? ['warehouse', 'crossdock', 'dropship', 'personalisation'] : [source]
    }

    buildSearchParameters(filters, page) {
        const mage_id = this.getMagentoSupplierId()
        const ignoreDelivery = filters.ignoreDelivery
        return {
            fields,
            value: filters.search,
            from_date: filters.fromDate && !ignoreDelivery ? moment(filters.fromDate).startOf('day').toISOString() : '',
            till_date: filters.tillDate && !ignoreDelivery ? moment(filters.tillDate).endOf('day').toISOString() : '',
            late_delivery: filters.lateDelivery,
            ignoreDelivery,
            unfulfilled: filters.unfulfilled,
            page,
            source: filters.source,
            sources: this.getSources(filters.source),
            destinations: [filters.destination],
            limit: this.state.limit,
            supplier: { mage_id: mage_id !== 'all' ? +mage_id : null },
            inSupplierView: this.props.supplier_id && this.props.role === 'servicedesk',
        }
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchTypedOrders.bind(this), 500)
    }

    setFilterStateWithoutRender(filters, timeout) {
        const newState = reactUpdate(this.state, {
            filters,
            page: { $set: 1 },
            shouldTableRender: { $set: false },
        })
        this.setState(newState, timeout ? this.setSearchTimeout.bind(this) : this.searchTypedOrders.bind(this))
    }

    handleSearchFieldChange(e) {
        this.setFilterStateWithoutRender({
            search: { $set: e.target.value },
        }, true)
    }

    handleLateDeliveryChange(lateDelivery) {
        this.setFilterStateWithoutRender({
            lateDelivery: { $set: lateDelivery },
            unfulfilled: { $set: lateDelivery },
            ignoreDelivery: { $set: lateDelivery },
        }, false)
    }

    handleIgnoreDeliveryChange(ignoreDelivery) {
        this.setFilterStateWithoutRender({
            ignoreDelivery: { $set: ignoreDelivery },
        }, false)
    }

    handleSupplierChange(e) {
        this.setFilterStateWithoutRender({
            supplier_id: { $set: e.target.value },
        }, false)
    }

    handleSourceChange(source) {
        this.setFilterStateWithoutRender({
            source: { $set: source },
            page: { $set: 1 },
        }, false)
    }

    handleDestinationChange(destination) {
        this.setFilterStateWithoutRender({
            destination: { $set: destination },
            page: { $set: 1 },
        }, false)
    }

    handleUnfulfilledChange(unfulfilled) {
        this.setFilterStateWithoutRender({
            unfulfilled: { $set: unfulfilled },
        }, false)
    }

    handleEstimatedDateChange({ target }) {
        const date = moment(target.value).format('YYYY-MM-DD')
        this.setFilterStateWithoutRender({
            tillDate: { $set: date },
            fromDate: { $set: date },
        }, false)
    }

    handlePageDecrement() {
        this.setState({
            page: this.state.page - 1,
            shouldTableRender: false,
        }, this.searchTypedOrders.bind(this))
    }

    handlePageIncrement() {
        this.setState({
            page: this.state.page + 1,
            shouldTableRender: false,
        }, this.searchTypedOrders.bind(this))
    }

    handleLimitChange(limit) {
        this.setState({
            limit,
            page: 1,
        }, this.searchTypedOrders.bind(this))
    }

    handleTableRowClick(index) {
        if (this.props.typed_orders[index]) {
            const typed_order = this.props.typed_orders[index]
            const id = typed_order.order_id
            const supplier = typed_order.type === 'dropship' ? typed_order.supplier.mage_id : ''
            this.context.router.push(this.props.role === 'supplier' ?
                `/sales/shipments/${id}` :
                `/sales/sales-orders/${id}/${typed_order.type}/${supplier}`)
        }
    }

    handleFloatingActionButtonClick() {
        this.setState({ dialogOpen: true })
    }

    render() {
        let containerStyle = style.container
        if (typeof this.props.style === 'object') {
            containerStyle = { ...style.container, ...this.props.style }
        }

        const extended = !this.props.supplier_id &&
            (this.props.role === 'warehouse' || this.props.role === 'servicedesk' || this.props.role === 'finance')
        return (
            <div style={containerStyle}>
                <TypedOrderFilters
                    extendedFilters={extended}
                    suppliers={this.props.suppliers}
                    filters={this.state.filters}
                    onIgnoreDeliveryChange={this.handleIgnoreDeliveryChange}
                    onUnfulfilledChange={this.handleUnfulfilledChange}
                    onEstimatedDateChange={this.handleEstimatedDateChange}
                    onLateDeliveryChange={this.handleLateDeliveryChange}
                    onSearchFieldChange={this.handleSearchFieldChange}
                    onSourceChange={this.handleSourceChange}
                    onDestinationChange={this.handleDestinationChange}
                    onSupplierChange={this.handleSupplierChange}
                />
                <TypedOrderTable
                    typed_orders={this.props.typed_orders}
                    page={this.state.page - 1}
                    limit={this.state.limit}
                    extendedTable={extended}
                    onTableRowClick={this.handleTableRowClick}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                    onLimitChange={this.handleLimitChange}
                />
            </div>
        )
    }
}

TypedOrderList.propTypes = {
    filters: PropTypes.object,
    suppliers: PropTypes.array,
    typed_orders: PropTypes.array,
    role: PropTypes.string,
    supplier_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onLoaded: PropTypes.func,
    style: PropTypes.object,
    searchTypedOrders: PropTypes.func,
    searchSuppliers: PropTypes.func,
}

TypedOrderList.contextTypes = {
    router: PropTypes.object,
}

export default connect(({ typed_orders, suppliers, filters }) => {
    return {
        typed_orders,
        suppliers,
        filters: filters.typed_orders || {},
    }
}, dispatch => {
    const actions = { searchSuppliers, searchTypedOrders }
    return bindActionCreators(actions, dispatch)
})(TypedOrderList)
