import React from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import {
    IconButton,
    Paper,
    List,
    ListItem,
    MenuItem,
    ListItemText,
    ListItemSecondaryAction,
    ListSubheader,
    Zoom,
    Fab,
    TextField,
    Tooltip,
    Divider,
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

import moment from 'moment'

import { Types } from '../../../../types/purchase/purchase_order'

const style = {
    container: {
        width: '100%',
        position: 'relative',
    },
    list: {
        paddingBottom: 30,
    },
    singleFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
    firstFab: {
        position: 'absolute',
        bottom: -26,
        right: 90,
    },
    secondFab: {
        position: 'absolute',
        bottom: -26,
        right: 20,
    },
    datePicker: {
        cursor: 'pointer',
        marginBottom: 15,
        width: '80%',
    },
}

const ZOOM_TIMEOUT = 200

class PurchaseOrderSpecifications extends React.Component {

    constructor(props) {
        super(props)
        this.state = { editing: false, data: {} }

        autoBind(this)
    }

    handleCloseClick() {
        this.setState({ editing: false, data: {} })
    }

    handleTypeChange(e) {
        this.setState({
            data: {
                ...this.state.data,
                type: e.target.value,
            },
        })
    }

    handleReferenceChange(e) {
        this.setState({
            data: {
                ...this.state.data,
                custom_ref: e.target.value,
            },
        })
    }

    handleInitialExpectedDeliveryDateChange(e) {
        this.setState({
            data: {
                ...this.state.data,
                initial_expected_delivery_date: e.target.value,
            },
        })
    }

    handleClearExpectedDeliveryDate () {
        this.setState({
            data: {
                ...this.state.data,
                initial_expected_delivery_date: '',
            },
        })
    }

    handleSaveClick() {
        const data = this.state.data
        const update = {
            type: data.type,
            custom_ref: data.custom_ref,
            initial_expected_delivery_date: data.initial_expected_delivery_date
                ? moment(data.initial_expected_delivery_date).toISOString()
                : null,
        }

        this.handleCloseClick()
        this.props.onUpdatePurchaseOrder(update)
    }

    handleEditClick() {
        const purchase_order = this.props.purchase_order

        this.setState({
            editing: true,
            data: {
                type: purchase_order.type,
                custom_ref: purchase_order.custom_ref,
                initial_expected_delivery_date: moment(
                    purchase_order.initial_expected_delivery_date
                ).format('YYYY-MM-DD'),
            },
        })
    }

    renderActions(editing) {
        return editing
            ? [
                <Zoom key="close" in={true} timeout={ZOOM_TIMEOUT} unmountOnExit>
                    <Fab
                        aria-label="Close"
                        onClick={this.handleCloseClick}
                        style={style.firstFab}
                    >
                        <CloseIcon />
                    </Fab>
                </Zoom>,
                <Zoom key="save" in={true} timeout={ZOOM_TIMEOUT} unmountOnExit>
                    <Fab
                        color="primary"
                        aria-label="Done"
                        onClick={this.handleSaveClick}
                        style={style.secondFab}
                    >
                        <DoneIcon />
                    </Fab>
                </Zoom>,
            ]
            : (
                <Zoom in={true} timeout={ZOOM_TIMEOUT} unmountOnExit>
                    <Fab
                        color="primary"
                        aria-label="Edit"
                        disabled={!this.props.editable}
                        onClick={this.handleEditClick}
                        style={style.singleFab}
                    >
                        <EditIcon />
                    </Fab>
                </Zoom>
            )
    }

    render() {
        const purchase_order = this.props.purchase_order
        const editing = this.state.editing
        return (
            <div style={style.container}>
                <Paper>
                    <List style={style.list}>
                        <ListSubheader>Order information</ListSubheader>
                        <Divider />
                        <ListItem>
                            <ListItemText
                                primary="Status"
                                secondary={purchase_order.status}
                            />
                        </ListItem>
                        <ListItem>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        select
                                        fullWidth
                                        onChange={this.handleTypeChange}
                                        value={this.state.data.type}
                                        label="Select type"
                                    >
                                        {
                                            Object.values(Types).map(type => (
                                                <MenuItem
                                                    key={type}
                                                    value={type.toLowerCase()}
                                                >
                                                    {type.replace(/_/g, ' ').replace(/\w/, c => c.toUpperCase())}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Type"
                                    secondary={purchase_order.type}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            {editing ? (
                                <ListItemText>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        onChange={this.handleReferenceChange}
                                        value={this.state.data.custom_ref}
                                        label="Custom reference"
                                    />
                                </ListItemText>
                            ) : (
                                <ListItemText
                                    primary="Custom reference"
                                    secondary={purchase_order.custom_ref}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Free of payment treshold"
                                secondary={purchase_order.supplier.free_of_payment_treshold}
                            />
                        </ListItem>
                        <ListItem>
                            {editing ? (
                                <>
                                    <ListItemText>
                                        <TextField
                                            style={style.datePicker}
                                            label="Initial expected delivery date"
                                            type="date"
                                            value={this.state.data.initial_expected_delivery_date}
                                            onChange={this.handleInitialExpectedDeliveryDateChange}
                                        />
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <Tooltip style={style.addButton} title="Clear initial expected delivery date">
                                            <IconButton onClick={this.handleClearExpectedDeliveryDate}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </>
                            ) : (
                                <ListItemText
                                    primary="Initial expected delivery date"
                                    secondary={purchase_order.initial_expected_delivery_date
                                        ? moment(purchase_order.initial_expected_delivery_date).format('DD-MM-YYYY')
                                        : '' }
                                />
                            )}
                        </ListItem>
                    </List>
                </Paper>
                {this.renderActions(editing)}
            </div>
        )
    }
}

PurchaseOrderSpecifications.propTypes = {
    editable: PropTypes.bool,
    purchase_order: PropTypes.object,
    features: PropTypes.object,
    onUpdatePurchaseOrder: PropTypes.func.isRequired,
}

export default PurchaseOrderSpecifications
