import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Divider} from '@material-ui/core'

class Header extends Component {

    render() {
        const style = this.props.style ? {...this.props.style} : {}
        const supplier = !(this.props.showSupplier && this.props.supplier) ? '' : ` - ${this.props.supplier.name}`
        const subheader = !this.props.subheader ? null : (
            <h4 style={{fontFamily: 'Roboto'}}>{this.props.subheader}</h4>
        )
        const avatar = this.props.avatar || null

        if (this.props.textTransform) {
            style.textTransform = this.props.textTransform
        }

        return (
            <header style={style}>
                {this.props.rightButtons}
                <h1>
                    {avatar}
                    {`${this.props.title}${supplier}`}
                </h1>
                {subheader}
                <Divider/>
            </header>
        )
    }
}

Header.propTypes = {
    subheader: PropTypes.node,
    style: PropTypes.object,
    avatar: PropTypes.node,
    textTransform: PropTypes.string,
    title: PropTypes.string.isRequired,
    rightButtons: PropTypes.node,
    showSupplier: PropTypes.bool,
    supplier: PropTypes.object,
}

Header.defaultProps = {
    textTransform: 'capitalize',
    title: '',
    style: {},
    avatar: null,
    supplier: null,
    showSupplier: false,
    subheader: null,
    rightButtons: null,
}

export default Header
