import socket from '../socket'
import listeners from '../listeners/sockets/inventory'
import _ from 'lodash'

listeners(socket)

function enrichStock(stock) {
    stock.available_quantity = stock.on_hand_quantity - stock.reserved_quantity
    stock.locations_joined = stock.locations.length === 0 ? '' : stock.locations.join(', ')
    return stock
}

function reduceStocksToStockTypeObject(stks) {
    return stks.reduce((acc, stock) => {
        acc[stock.type] = enrichStock(stock)
        return acc
    }, {})
}

export function stocks(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'UPDATE_STOCK':
            socket.emit('stock/update', action.data)
            return state

        case 'REFRESH_PRODUCT_VARIANT':
            return action.variant.stocks
                ? reduceStocksToStockTypeObject(action.variant.stocks)
                : state

        case 'REFRESH_STOCK':
            return {
                ...state,
                warehouse: enrichStock(action.data),
            }

        case 'UPDATE_PRODUCT_VARIANTS':
            return _.map(action.data, variant => {
                return variant['stocks.warehouse.quantity'] || variant?.stocks?.warehouse?.quantity
            })

        case 'REFRESH_PRODUCT_VARIANTS':
            return action.variants.map(variant => variant.hasOwnProperty('stocks')
                ? reduceStocksToStockTypeObject(variant.stocks)
                : null)

        default:
            return state
    }
}
