import Colors from './../colors'
import {fade} from 'material-ui/utils/colorManipulator'
import Spacing from 'material-ui/styles/spacing'

const profortoBaseThemev0 = {
    spacing: Spacing,
    fontFamily: 'Roboto, sans-serif',
    palette: {
        primary1Color: Colors.primaryColor100,
        primary2Color: Colors.primaryColor80,
        primary3Color: Colors.lightBlack,
        accent1Color: Colors.secondaryColor100,
        accent2Color: Colors.grey100,
        accent3Color: Colors.grey500,
        textColor: Colors.black,
        alternateTextColor: Colors.white,
        canvasColor: Colors.white,
        borderColor: Colors.grey300,
        disabledColor: fade(Colors.darkBlack, 0.3),
    },
    listItem: {
        secondaryTextColor: 'rgba(0, 0, 0, 0.65)',
    },
}

export default profortoBaseThemev0
