import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'

import { DeliveryOrderStatuses, DeliveryTypes } from '../../../../types/logistics/delivery-orders'
import { FulfillmentSources } from '../../../../types/logistics/fulfillment'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const styles = {
    formGroup: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
    },
    formItem: {
        marginRight: 12,
    },
    // makes text fields correctly align with select fields that contain Chip components as render value.
    formControlTextField: {
        paddingTop: 3,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
}

class DeliveryOrderFilters extends Component {

    constructor(props) {
        super(props)

        autoBind(this)
    }

    handleTextFieldChange(property) {
        return e => {
            return this.props.onTextFieldChange && this.props.onTextFieldChange(e.target.value, property)
        }
    }

    handleSelectChange(property) {
        return e => {
            return this.props.onSelectChange && this.props.onSelectChange(e.target.value, property)
        }
    }

    render() {
        const filters = this.props.filters
        return (
            <FormGroup row style={styles.formGroup}>
                <FormControl style={{ ...styles.formItem, ...styles.formControlTextField }} margin="dense">
                    <TextField
                        label="Increment ID"
                        id="increment-id-filter"
                        value={filters.increment_id}
                        onChange={this.handleTextFieldChange('increment_id')}
                    />
                </FormControl>
                <FormControl style={{ ...styles.formItem, ...styles.formControlTextField }} margin="dense">
                    <TextField
                        label="Order"
                        id="order-filter"
                        value={filters.order_ref}
                        onChange={this.handleTextFieldChange('order_ref')}
                    />
                </FormControl>
                <FormControl style={{ ...styles.formItem, ...styles.formControlTextField }} margin="dense">
                    <TextField
                        label="SKU"
                        id="sku-filter"
                        value={filters.sku}
                        onChange={this.handleTextFieldChange('sku')}
                    />
                </FormControl>
                <FormControl style={styles.formItem} margin="dense">
                    <InputLabel id="status-filter-label">Statuses</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        id="status-filter"
                        multiple
                        value={filters.statuses}
                        onChange={this.handleSelectChange('statuses')}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip size="small" key={value} label={value.replace('_', ' ')} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.values(DeliveryOrderStatuses).map(value => (
                            <MenuItem key={value} value={value} style={styles.menuItem}>
                                {value.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={styles.formItem} margin="dense">
                    <InputLabel id="fulfillment-source-filter-label">Fulfillment sources</InputLabel>
                    <Select
                        labelId="fulfillment-source-filter-label"
                        id="fulfillment-source-filter"
                        multiple
                        value={filters.fulfillment_sources}
                        onChange={this.handleSelectChange('fulfillment_sources')}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip size="small" key={value} label={value.replace('_', ' ')} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.values(FulfillmentSources).map(value => (
                            <MenuItem key={value} value={value} style={styles.menuItem}>
                                {value.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={styles.formItem} margin="dense">
                    <InputLabel id="delivery-to-filter-label">Delivery to</InputLabel>
                    <Select
                        labelId="delivery-to-filter-label"
                        id="delivery-to-filter"
                        multiple
                        value={filters.delivery_to}
                        onChange={this.handleSelectChange('delivery_to')}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip size="small" key={value} label={value.replace('_', ' ')} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.values(DeliveryTypes).map(value => (
                            <MenuItem key={value} value={value} style={styles.menuItem}>
                                {value.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormGroup>
        )
    }
}

DeliveryOrderFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onTextFieldChange: PropTypes.func.isRequired,
    onSelectChange: PropTypes.func.isRequired,
}

export default DeliveryOrderFilters
