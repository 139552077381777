export const CART_TYPES = Object.freeze({
    RTV: 'rtv',
    STOCK: 'stock',
})

export function getRma(id) {
    return {type: 'GET_RMA', id}
}

export function addNote(id, note) {
    return {type: 'ADD_RMA_NOTE', id, note}
}

export function authorizeRma(id, authorized) {
    return {type: 'AUTHORIZE_RMA', id, authorized}
}

export function settleRma(id, line_items) {
    return {type: 'SETTLE_RMA', id, line_items}
}

export function deliverRma(id) {
    return {type: 'DELIVER_RMA', id}
}

// eslint-disable-next-line max-params
export function triageRmaItem(rma_id, line_item, location, decision) {
    return { type: 'TRIAGE_RMA_ITEM', data: { rma_id, line_item, location, decision } }
}

export function refreshRmas(rmas) {
    return {type: 'REFRESH_RMAS', rmas}
}

export function setRmaStockCart(cart) {
    return { type: 'SET_RMA_STOCK_CART', cart }
}

export function setRmaRtvCart(cart) {
    return { type: 'SET_RMA_RTV_CART', cart }
}

export function loadRmaProduct(skuOrEan) {
    return { type: 'LOADING_RMA_PRODUCT', skuOrEan }
}

export function receiveRmaProduct(data) {
    return { type: 'FETCHED_RMA_PRODUCT', data }
}

export function clearRmaProduct() {
    return { type: 'CLEAR_RMA_PRODUCT' }
}
