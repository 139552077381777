export function searchSalesOrders(filters) {
    return {type: 'SEARCH_SALES_ORDERS', filters}
}

export function refreshSalesOrders(sales_orders) {
    return {type: 'REFRESH_SALES_ORDERS', sales_orders}
}

export function refreshSalesOrder(sales_order) {
    return {type: 'REFRESH_SALES_ORDER', sales_order}
}
