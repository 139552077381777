export function searchCreditMemos(filters) {
    return { type: 'SEARCH_CREDIT_MEMOS', filters }
}

export function getCreditMemo(id) {
    return { type: 'GET_CREDIT_MEMO', id }
}

export function addNote(id, note) {
    return { type: 'ADD_CREDIT_MEMO_NOTE', id, note }
}

export function markAsRejected(id) {
    return { type: 'REJECT_CREDIT_MEMO', id }
}

export function markAsAccepted(id) {
    return { type: 'ACCEPT_CREDIT_MEMO', id }
}

export function markAsToRefund(id) {
    return { type: 'REFUND_CREDIT_MEMO', id }
}

export function markAsRefunded(id) {
    return { type: 'REFUNDED_CREDIT_MEMO', id }
}

export function markAsOnHold(id) {
    return { type: 'ON_HOLD_CREDIT_MEMO', id }
}

export function addCreditMemo(memo) {
    return { type: 'ADD_CREDIT_MEMO', memo }
}

export function synchronizeCreditMemo(id) {
    return { type: 'SYNCHRONIZE_CREDIT_MEMO', id }
}
