import store from '../../store'

export default function initPersonalistationsSockets(socket) {
    socket.on('personalisation', personalisation => {
        store.dispatch({type: 'REFRESH_PERSONALISATION', personalisation})
    })

    socket.on('personalisations', personalisations => {
        store.dispatch({type: 'REFRESH_PERSONALISATIONS', personalisations})
    })
}
