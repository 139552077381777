import socket from './../../socket'
import {getUserEmail} from '../../store'

export function registerPackingStation(constraint) {
    socket.emit('packing-stations/register', { constraint }, getUserEmail())
    return {type: 'REGISTER_PACKING_STATION'}
}

export function unregisterPackingStation(station) {
    socket.emit('packing-stations/unregister', station, getUserEmail())
    return {type: 'UNREGISTER_PACKING_STATION'}
}

export function doResetPackingStations() {
    socket.emit('packing-stations/do-reset')
    return {type: 'DO_RESET_PACKING_STATIONS'}
}
