import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { addNote, getSalesOrder } from '../../../../commands/sockets/sales_orders'
import { getProductionOrderById } from '../../../../commands/sockets/production_orders'
import { getDeliveryOrderByShipmentRef } from '../../../../commands/sockets/delivery_orders'
import {
    updateProductionOrderStatus,
    updateProductionOrderLocation,
    updateProductionOrderItemsReady,
    updateProductionOrderImpressionStatus,
    updateProductionOrderUsedPress,
    updateProductionOrderPressWorker,
} from '../../../../commands/sockets/production_orders'

import { showErrorNotification } from '../../../../actions/notifications'
import { getWorkSlip } from '../../../../actions/work_slips'
import { markPersonalisationsProcessed, updatePersonalisationStatus } from '../../../../actions/personalisations'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { WAREHOUSE_PREFIX } from '../../../types/logistics/warehouses/prefixes'

import Header from '../../shared/Header'
import Customer from '../../sales/Customer'
import Notes from '../../shared/Notes'
import PersonalisationOrderDetailSpecs from './PersonalisationOrderDetailSpecs'
import PersonalisationLineItems from './PersonalisationLineItems'
import PersonalisationStatusSelect from './PersonalisationStatusSelect'
import PersonalisationLocationSelect from './PersonalisationLocationSelect'
import PersonalisationImpressionStatusSelect from './PersonalisationImpressionStatusSelect'
import PersonalisationItemsReadySwitch from './PersonalisationItemsReadySwitch'
import PersonalisationPressSelect from './PersonalisationPressSelect'
import PersonalisationPressWorkerSelect from './PersonalisationPressWorkerSelect'

// eslint-disable-next-line max-len
import DeliveryOrderLocation from '../../logistics/outbound/delivery-orders/delivery-order-detail/DeliveryOrderLocation'

const style = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    topRightAction: {
        float: 'right',
        marginLeft: 10,
    },
    raisedButton: {
        color: 'white',
    },
    content: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        marginTop: 12,
    },
    specs: {
        marginBottom: 12,
    },
    customer: {
        marginBottom: 12,
        width: '100%',
    },
    block: {
        width: '27%',
        minWidth: 350,
        marginRight: 12,
        display: 'flex',
        flexFlow: 'column',
    },
    line_items: {
        minWidth: 600,
        width: '73%',
        marginLeft: 12,
    },
}

class PersonalisationOrderDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadedOrder: null,
            fetchedDeliveryOrder: false,
        }

        autoBind(this)
    }

    componentDidMount() {
        this.props.getProductionOrderById(this.props.params.production_order_id)
    }

    componentDidUpdate() {
        const work_slip = this.props.work_slip
        if (work_slip.production_order_id === this.state.downloadedOrder) {
            /* eslint-disable react/no-did-update-set-state */
            // below is allowed if wrapped within an if-statement like this.
            this.setState({
                downloadedOrder: null,
            }, () => {
                this.downloadWorkSlip(work_slip)
            })
        }

        if (this.props.shipments && this.props.shipments.personalisation) {
            const personalisationType = this.props.shipments.personalisation.find(shipment =>
                shipment._id === this.props.production_order.refs.shipment_ref)?.personalisation
            const manufacturer_shipment = this.props.shipments.personalisation.find(shipment =>
                shipment.shipping_address.type === 'manufacturer'
                && (!personalisationType || shipment.personalisation === personalisationType))

            if (manufacturer_shipment && !this.state.fetchedDeliveryOrder) {
                this.setState({
                    fetchedDeliveryOrder: true,
                }, () => {
                    this.props.getDeliveryOrderByShipmentRef(manufacturer_shipment._id)
                })
            }
        }
    }

    handleDownloadButtonClick() {
        this.setState({
            downloadedOrder: this.props.params.production_order_id,
        }, () => {
            this.props.getWorkSlip(this.state.downloadedOrder)
        })
    }

    handleProcessedButtonClick() {
        this.props.markPersonalisationsProcessed(this.props.sales_order._id)
    }

    downloadWorkSlip(data) {
        if (!data.error) {
            const anchor = document.createElement('a')
            anchor.setAttribute('target', '_blank')
            anchor.setAttribute('download',
                `${this.props.sales_order.mage_id}_work_slip.pdf`)
            anchor.setAttribute('href', `data:application/pdf;base64,${data.blob}`)
            anchor.style.display = 'none'
            document.body.appendChild(anchor)
            this.setState({
                downloadedOrder: null,
            }, () => {
                anchor.click()
                document.body.removeChild(anchor)
            })
        } else {
            this.props.showErrorNotification({
                message: 'Er is een probleem opgetreden tijdens het download van de werkbon, contact development',
            })
        }
    }

    handleAddNote(value) {
        this.props.addNote(this.props.sales_order._id, {
            author: `Manufacturer: ${this.props.email}`,
            value,
            date: new Date().toISOString(),
        })
    }

    handleStatusChange(event) {
        const status = event.target.value
        this.props.updateProductionOrderStatus({
            production_order_id: this.props.production_order._id,
            status,
        })
    }

    handleLocationChange(event) {
        const location = event.target.value
        this.props.updateProductionOrderLocation({
            production_order_id: this.props.production_order._id, location,
        })
    }

    handleItemsReadyChange(event) {
        const items_ready = event.target.checked
        this.props.updateProductionOrderItemsReady({
            production_order_id: this.props.production_order._id,
            items_ready,
        })
    }

    handleImpressionStatusChange(event) {
        const impression_status = event.target.value
        this.props.updateProductionOrderImpressionStatus({
            production_order_id: this.props.production_order._id,
            impression_status,
        })
    }

    handleUsedPressChange(event) {
        const used_press = event.target.value
        this.props.updateProductionOrderUsedPress({
            production_order_id: this.props.production_order._id,
            used_press,
        })
    }

    handlePressWorkerChange(event) {
        const press_worker = event.target.value
        this.props.updateProductionOrderPressWorker({
            production_order_id: this.props.production_order._id,
            press_worker,
        })
    }

    getCustomerDeliveryDate(personalisation_shipments = []) {
        if (!personalisation_shipments || personalisation_shipments.length === 0) {
            return ''
        }
        const customer_shipment = personalisation_shipments.find(shipment =>
            shipment.shipping_address.type === 'customer')

        return moment(customer_shipment.estimated_for)
    }

    getHeaderActions() {
        const {
            status,
            location,
            items_ready,
            impression_status,
            used_press = [],
            press_worker = [],
        } = this.props.production_order

        return [
            <Button
                style={{ ...style.raisedButton, ...style.topRightAction }}
                key="download"
                color="primary"
                variant="contained"
                disabled={this.state.downloadedOrder}
                onClick={this.handleDownloadButtonClick}
            >
                Download work slip
            </Button>,
            <PersonalisationStatusSelect
                style={style.topRightAction}
                key="status"
                value={status}
                allowAll={false}
                onChange={this.handleStatusChange}
            />,
            <PersonalisationLocationSelect
                style={style.topRightAction}
                key="location"
                value={location}
                allowAll={false}
                onChange={this.handleLocationChange}
            />,
            <PersonalisationImpressionStatusSelect
                style={style.topRightAction}
                key="impression_status"
                value={impression_status}
                allowAll={false}
                onChange={this.handleImpressionStatusChange}
            />,
            <PersonalisationPressSelect
                style={style.topRightAction}
                key="used_press"
                allowAll={false}
                value={used_press}
                onChange={this.handleUsedPressChange}
            />,
            <PersonalisationPressWorkerSelect
                style={style.topRightAction}
                key="press_worker"
                allowAll={false}
                value={press_worker}
                onChange={this.handlePressWorkerChange}
            />,
            <PersonalisationItemsReadySwitch
                key="items_ready"
                style={style.topRightAction}
                value={items_ready}
                onChange={this.handleItemsReadyChange}
            />,
        ]
    }

    getCancelledItems(shipments) {
        const personalisation_shipments = shipments && shipments.personalisation ? shipments.personalisation : []
        return personalisation_shipments.reduce((cancelled_items, shipment) => {
            if (shipment.shipping_address.type !== 'customer') {
                return cancelled_items
            }
            return [...cancelled_items, shipment.line_items.filter(item => item.status === 'cancelled')]
        }, [])
    }

    render() {
        const production_order = this.props.production_order
        const sales_order = this.props.sales_order
        const delivery_order = this.props.delivery_order
        // const cancelled_items = this.getCancelledItems(this.props.shipments);

        const personalisationType = this.props.shipments.personalisation
            ?.find(s => s._id === production_order.refs.shipment_ref)
            .personalisation

        const shipments = this.props.shipments.personalisation
            ?.filter(s => (personalisationType === undefined ? true : s.personalisation === personalisationType)
                && s.shipping_address.type === 'customer')

        const typed_orders = this.props.typed_orders
            ?.filter(o => personalisationType === undefined ? true : o.personalisation[personalisationType])

        const customer_delivery_date = this.getCustomerDeliveryDate(shipments)
        return !production_order._id || !sales_order._id ? null : (
            <Grid container spacing={3} style={style.container}>
                <Grid item xs={12}>
                    <Header
                        title={production_order.production_order_id ?? production_order.refs.order_ref}
                        showSupplier={false}
                        rightButtons={
                            this.getHeaderActions()
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <PersonalisationOrderDetailSpecs
                        production_order={production_order}
                        customer_delivery_date={customer_delivery_date}
                        style={style.specs}
                    />
                    <Customer
                        editable={false}
                        style={style.customer}
                        order_id={production_order.refs.order_id_ref}
                        status={production_order.status}
                        customer={{ ...sales_order.customer, ref: sales_order.customer_ref }}
                        salesRepresentative={sales_order.sales_representative}
                    />
                    <Notes
                        notes={sales_order.notes}
                        title="Notes"
                        wrap={true}
                        onAddNote={this.handleAddNote}
                        canAdd={true}
                    />
                </Grid>
                <Grid item xs={9}>
                    {typed_orders.map(to => {
                        return (
                            <Grid item xs={12} key={to.fulfillment_id}>
                                <PersonalisationLineItems
                                    typed_order={to}
                                    personalisation={this.props.personalisations
                                        .find(p => p.fulfillment_id === to.fulfillment_id)}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    {delivery_order && delivery_order.refs && delivery_order.refs.location_refs.map(ref => {
                        const location = delivery_order.locations.find(l => {
                            return l.location.replace(WAREHOUSE_PREFIX, '') === ref
                        })
                        const checked_out = delivery_order.refs.checked_out_refs.includes(ref)
                        return (
                            <Grid item xs={3} key={ref}>
                                <DeliveryOrderLocation
                                    location_ref={ref}
                                    location={location}
                                    checked_out={checked_out}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        )
    }
}

PersonalisationOrderDetail.propTypes = {
    email: PropTypes.string,
    role: PropTypes.string,
    work_slip: PropTypes.object,
    delivery_order: PropTypes.object,
    production_order: PropTypes.object,
    shipments: PropTypes.object,
    sales_order: PropTypes.object,
    typed_orders: PropTypes.array,
    personalisations: PropTypes.array,
    params: PropTypes.object,
    addNote: PropTypes.func.isRequired,
    getProductionOrderById: PropTypes.func.isRequired,
    updatePersonalisationStatus: PropTypes.func.isRequired,
    updateProductionOrderLocation: PropTypes.func.isRequired,
    updateProductionOrderStatus: PropTypes.func.isRequired,
    updateProductionOrderItemsReady: PropTypes.func.isRequired,
    updateProductionOrderImpressionStatus: PropTypes.func.isRequired,
    updateProductionOrderUsedPress: PropTypes.func.isRequired,
    updateProductionOrderPressWorker: PropTypes.func.isRequired,
    markPersonalisationsProcessed: PropTypes.func.isRequired,
    getSalesOrder: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
    getDeliveryOrderByShipmentRef: PropTypes.func.isRequired,
    getWorkSlip: PropTypes.func.isRequired,
}
PersonalisationOrderDetail.defaultProps = {
    typed_orders: [],
    personalisations: [],
}

export default connect(({
    sales_order, shipments = {}, production_order, delivery_order, typed_orders = [], work_slip, personalisations,
}) => {
    return {
        sales_order,
        delivery_order,
        production_order,
        shipments,
        typed_orders: typed_orders.filter(f => f.type === 'personalisation'),
        work_slip,
        personalisations,
    }
}, dispatch => {
    return bindActionCreators({
        getSalesOrder,
        addNote,
        getWorkSlip,
        updatePersonalisationStatus,
        updateProductionOrderStatus,
        updateProductionOrderLocation,
        updateProductionOrderItemsReady,
        updateProductionOrderImpressionStatus,
        updateProductionOrderUsedPress,
        updateProductionOrderPressWorker,
        getDeliveryOrderByShipmentRef,
        getProductionOrderById,
        showErrorNotification,
        markPersonalisationsProcessed,
    }, dispatch)
})(PersonalisationOrderDetail)
