import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
}

class DeliveryOrderTable extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    handleTableRowClick(index) {
        return this.props.onDeliveryOrderClick && this.props.onDeliveryOrderClick(this.props.delivery_orders[index])
    }

    renderDeliveryOrderRows(delivery_orders) {
        if (delivery_orders.length > 0) {
            return delivery_orders.map((delivery_order, index) => (
                <TableRow
                    onClick={this.handleTableRowClick.bind(this, index)}
                    key={index}
                    style={{ cursor: 'pointer' }}
                >
                    {
                        this.props.headerCols.map(column => {
                            const value = _.get(delivery_order, column.property)
                            return (
                                <TableCell
                                    key={column.property}
                                    style={column.style}
                                >
                                    {column.format
                                        ? column.format(value)
                                        : value}
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableCell colSpan={this.props.headerCols.length}>
                    No delivery orders found.
                </TableCell>
            </TableRow>
        )
    }

    handleNextPageClick() {
        return this.props.onPageIncrement && this.props.onPageIncrement()
    }

    handlePreviousPageClick() {
        return this.props.onPageDecrement && this.props.onPageDecrement()
    }

    handleChangeRowsPerPage(event, element) {
        return this.props.onLimitChange && this.props.onLimitChange(element.props.value)
    }

    renderNavigation(delivery_orders) {
        if (delivery_orders.length > 0) {
            const { page, limit, headerCols } = this.props
            const from = Math.max(0, (limit * page))
            const to = Math.min(from + 20, from + delivery_orders.length)
            return (
                <TableFooter style={{ height: 60 }}>
                    <TableRow>
                        <TableCell colSpan={headerCols.length}>
                            <TablePagination
                                component="div"
                                count={delivery_orders.length}
                                rowsPerPage={limit}
                                page={page}
                                labelDisplayedRows={() => `${from + 1} - ${to}`}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    onClick: this.handlePreviousPageClick.bind(this),
                                    disabled: page === 0,
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    onClick: this.handleNextPageClick.bind(this),
                                    disabled: delivery_orders.length < limit,
                                }}
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                onChangePage={() => { }}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const delivery_orders = this.props.delivery_orders
        const orderRows = this.renderDeliveryOrderRows(delivery_orders)
        const navigation = this.renderNavigation(delivery_orders)
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            this.props.headerCols.map((column, index) => {
                                return (
                                    <TableCell
                                        variant="th"
                                        key={index}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

DeliveryOrderTable.propTypes = {
    headerCols: PropTypes.array,
    delivery_orders: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool,

    onDeliveryOrderClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

DeliveryOrderTable.defaultProps = {
    headerCols: [{
        content: 'ID',
        tooltip: 'The Delivery order ID',
        property: 'increment_id',
    }, {
        content: 'Creation',
        tooltip: 'Creation Date',
        property: 'created_at',
        format: date => moment(date).format('DD-MM-YYYY'),
    }, {
        content: 'Fulfillment source',
        tooltip: 'The fulfillment flow of the delivery order',
        property: 'fulfillment_source',
    }, {
        content: 'Linked sales order',
        tooltip: 'The sales order that is linked to the delivery order',
        property: 'refs.order_ref',
    }, {
        content: 'Item quantity total',
        tooltip: 'The amount of items in this delivery order',
        property: 'total_quantity',
    }, {
        content: 'Total fulfilled',
        tooltip: 'The amount of items in the boxes linked to the delivery order',
        property: 'total_quantity_fulfilled',
    }, {
        content: 'Total checked out',
        tooltip: 'The amount of items in the boxes that are checked out',
        property: 'total_quantity_checked_out',
    }, {
        content: 'Status',
        tooltip: 'status',
        property: 'status',
    }],
}


export default DeliveryOrderTable
