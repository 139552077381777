import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField, SelectField, MenuItem} from 'material-ui'

const style = {
    textField: {height: 72, marginRight: 20, width: '35%'},
    textFieldInput: {height: 30, lineHeight: '14px', bottom: 12, position: 'absolute'},
    textFieldHint: {lineHeight: '20px', fontSize: 14, fontWeight: 'bold'},
    selectField: {width: 200, marginRight: 15},
    filtersContainer: {
        height: 60,
        width: '95%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        margin: 'auto',
    },
}

const statuses = [{
    value: 'draft',
    text: 'Draft',
}, {
    value: 'prepared',
    text: 'Prepared',
}, {
    value: 'invoiced',
    text: 'Invoiced',
}]

class DebitMemoFilters extends Component {

    handleSearchFieldChange(e) {
        this.props.onSearchChange(e.target.value)
    }

    handleStatusChange(e, index, value) {
        this.props.onStatusChange(value)
    }

    render() {
        return (
            <div style={style.filtersContainer}>
                <TextField
                    style={style.textField}
                    inputStyle={style.textFieldInput}
                    hintStyle={style.textFieldHint}
                    floatingLabelText="Search by order number or invoice number."
                    value={this.props.filters.criteria}
                    onChange={this.handleSearchFieldChange.bind(this)}
                />
                <SelectField
                    style={style.selectField}
                    onChange={this.handleStatusChange.bind(this)}
                    value={this.props.filters.status}
                    floatingLabelText="Select status"
                >
                    <MenuItem
                        value="all"
                        primaryText="All"
                    />
                    {
                        statuses.map(status => (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                                primaryText={status.text}
                            />
                        ))
                    }
                </SelectField>
            </div>
        )
    }
}

DebitMemoFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
}

export default DebitMemoFilters
