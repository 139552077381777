import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, TableHeader, TableBody, TableFooter, TableRow, TableRowColumn } from 'material-ui'
import _ from 'lodash'

import Pagination from './../../shared/Pagination'
import ChannelAvatar from './../../shared/ChannelAvatar'
import SortableTableHeaderColumn from './../../shared/SortableTableHeaderColumn'

const styles = {
    blankListStateMessage: {
        backgroundColor: '#fcf8e3',
    },
    tableRow: { cursor: 'pointer' },
}

class CreditMemoTable extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRender
    }

    navigate(rowNumber) {
        if (this.props.items[rowNumber]) {
            const id = this.props.items[rowNumber]._id
            this.context.router.push(`/finance/credit-memos/${id}`)
        }
    }

    handleCellClick(rowNumber, columnId) {
        if (columnId !== -1) {
            this.navigate(rowNumber)
        }
    }

    renderTableRows(items) {
        if (items.length > 0) {
            return items.map(memo => (
                <TableRow
                    key={memo._id}
                    style={styles.tableRow}
                >
                    <TableRowColumn style={{ paddingRight: 0, paddingLeft: '12px' }} width="3%">
                        <ChannelAvatar style={{ width: 20, height: 20 }} slug={_.get(memo, 'channel.slug', '')} />
                    </TableRowColumn>
                    {
                        this.props.headerCols.map((column, i) => {
                            return (
                                <TableRowColumn
                                    style={{ ...column.style, padding: '0 12px' }}
                                    key={i}
                                >
                                    {memo[column.property]}
                                </TableRowColumn>
                            )
                        })
                    }
                </TableRow>
            ))
        }
        return (
            <TableRow style={styles.blankListStateMessage}>
                <TableRowColumn>No items found.</TableRowColumn>
            </TableRow>
        )
    }

    renderNavigation(items) {
        if (items.length > 0) {
            return (
                <TableFooter style={{ height: 60 }}>
                    <TableRow>
                        <TableRowColumn>
                            <Pagination
                                page={this.props.page}
                                limit={this.props.limit}
                                onPreviousPageClick={this.props.onPageDecrement}
                                onNextPageClick={this.props.onPageIncrement}
                                items={this.props.items}
                            />
                        </TableRowColumn>
                    </TableRow>
                </TableFooter>
            )
        }
        return null
    }

    render() {
        const items = this.props.items
        const tableRows = this.renderTableRows(items)
        const navigation = this.renderNavigation(items)

        return (
            <Table
                onCellClick={this.handleCellClick.bind(this)}
                multiSelectable={true}
            >
                <TableHeader displaySelectAll={false}>
                    <TableRow>
                        {
                            this.props.headerCols.map(column => {
                                return (
                                    <SortableTableHeaderColumn
                                        onSortChange={this.props.onSortChange}
                                        style={{ ...column.style, fontWeight: 'bold', padding: '0 12px' }}
                                        sortable={!!column.sortable}
                                        initialSort={this.props.sorting[column.property]}
                                        value={this.props.sorting[column.property]}
                                        controlled={true}
                                        key={column.property}
                                        property={column.property}
                                        width={column.width}
                                        tooltip={column.tooltip}
                                    >
                                        {column.content}
                                    </SortableTableHeaderColumn>
                                )
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} showRowHover={true}>
                    {tableRows}
                </TableBody>
                {navigation}
            </Table>
        )
    }
}

CreditMemoTable.propTypes = {
    headerCols: PropTypes.array,
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    sorting: PropTypes.object.isRequired,
    shouldRender: PropTypes.bool,
    onSortChange: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
}

CreditMemoTable.contextTypes = {
    router: PropTypes.object,
}


CreditMemoTable.defaultProps = {
    headerCols: [{
        content: 'Sales order number',
        tooltip: 'The sales order number',
        style: { width: '13%', textAlign: 'left' },
        property: 'mage_id',
    }, {
        content: 'Customer',
        tooltip: 'The customer to which this credit belongs',
        style: { width: '13%', textAlign: 'left' },
        property: 'customer_name',
    }, {
        content: 'Invoice number',
        tooltip: 'Invoice number of the crediting',
        style: { width: '12%', textAlign: 'left' },
        property: 'invoice_id',
    }, {
        content: 'Prior Action',
        tooltip: 'The action that is prior to the memo',
        style: { width: '13%', textAlign: 'left' },
        property: 'prior_action',
    }, {
        content: 'Creation Date',
        tooltip: 'Credit memo creation date',
        style: { width: '10%', textAlign: 'left' },
        property: 'created_at',
    }, {
        content: 'RMA Creation Date',
        tooltip: 'RMA creation date',
        style: { width: '10%', textAlign: 'left' },
        property: 'rma_created_at',
    }, {
        content: 'Refund Date',
        tooltip: 'refund deadline',
        style: { width: '8%', textAlign: 'left' },
        property: 'refund_deadline',
        sortable: true,
    }, {
        content: 'Grand Total',
        tooltip: 'Total value of the credit memo',
        style: { width: '10%', textAlign: 'right' },
        property: 'grand_total',
    }, {
        content: 'Status',
        tooltip: 'Status of the memo',
        style: { width: '10%', textAlign: 'left' },
        property: 'status',
    }],
}

export default CreditMemoTable
