import React from 'react'
import PropTypes from 'prop-types'
import {Avatar} from '@material-ui/core'

const white = {backgroundColor: '#FFF', display: 'inline-flex', width: 40, height: 40}

const profortoAvatar = {style: white, src: '/logo-p.svg'}
const profortoResellerAvatar = {style: white, src: '/reseller-logo-r.png'}
const profortoSMEAvatar = {style: white, src: '/sme-logo.png'}
const xeroxAvatar = {style: white, src: '/xerox-logo.png'}
const bolAvatar = {style: white, src: '/bol-logo.png'}
const emmaAvatar = {
    style: {backgroundColor: '#000', display: 'inline-flex', width: 40, height: 40},
    src: '/emma-logo.svg',
}
const amazonAvatar = {style: white, src: '/amazon-logo.png'}
const tricorpAvatar = {style: {...white, borderRadius: 0}, src: '/tricorp.svg'}

const avatarMapping = {
    '': profortoAvatar,
    'proforto-web-nl': profortoAvatar,
    'proforto-sales': profortoAvatar,
    'proforto-sme': profortoSMEAvatar,
    'proforto-reseller': profortoResellerAvatar,
    'proforto-web-be': profortoAvatar,
    'proforto-amazon-de': amazonAvatar,
    'proforto-bol': bolAvatar,
    xerox: xeroxAvatar,
    'emma-web-nl': emmaAvatar,
    'emma-web-de': emmaAvatar,
    'emma-amazon-de': amazonAvatar,
    'tricorp-web-nl': tricorpAvatar,
    'tricorp-web-be': tricorpAvatar,
    'tricorp-web-de': tricorpAvatar,
}

function ChannelAvatar(props) {
    const branding = avatarMapping[props.slug] || white
    branding.style = {
        ...branding.style,
        ...props.style,
        ...(props.size === 'large' ? {width: 80, height: 80} : {}),
    }
    return <Avatar {...branding} />
}

ChannelAvatar.propTypes = {
    slug: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
}
ChannelAvatar.defaultProps = {
    style: {},
}

export default ChannelAvatar
