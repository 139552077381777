import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CardHeader, FontIcon, GridTile} from 'material-ui'
import _ from 'lodash'

import Colors from '../../../../styles/colors'

import PackItemInfo from './shipment-pack-item/ShipmentPackItemInfo'
import ChannelAvatar from './../../../shared/ChannelAvatar'

const style = {
    pickItem: {
        height: 250,
        boxShadow: '0 1px 6px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.12)',
        cursor: 'pointer',
        paddingTop: 20,
    },
    titleStyle: {
        fontFamily: 'Roboto',
        color: Colors.grey800,
    },
    fontIcon: {
        marginRight: 20,
        float: 'right',
        display: 'block',
    },
    headerButtonIcon: {
        paddingTop: 0,
        marginTop: 0,
        paddingBottom: 16,
        marginLeft: 20,
        marginRight: 20,
        display: 'block',
        float: 'left',
        textAlign: 'left',
    },
    headerButtonIconStyle: {
        fontSize: 24,
        color: 'white',
    },
    pickItemTableHeading: {
        display: 'block',
        float: 'left',
        marginLeft: 20,
        marginRight: 20,
    },
}

class PackItem extends Component {

    handleClick() {
        this.props.onClick(this.props.item)
    }

    shouldComponentUpdate(nextProps) {
        return this.props.isSelected !== nextProps.isSelected
            || JSON.stringify(nextProps.item) !== JSON.stringify(this.props.item)
    }

    getInstructionIndicator(instructions) {
        return !(instructions instanceof Array) || !instructions.length ? null : (
            <FontIcon
                style={style.fontIcon}
                className="material-icons"
                color={this.context.colors.primaryColor60}
            >
                build
            </FontIcon>
        )
    }

    render() {
        const item = this.props.item
        if (!(item.line_items instanceof Array)) {
            item.line_items = []
        }
        const packed = item.packed
        const icon = packed ? 'done' : 'create'
        const backgroundColor = icon === 'done' ?
            Colors[`green${this.props.isSelected ? '400' : '100'}`] :
            Colors[`amber${this.props.isSelected ? '400' : '100'}`]
        return (
            <GridTile
                key={item._id}
                style={_.merge({backgroundColor}, this.props.style)}
                onClick={this.handleClick.bind(this)}
            >
                <CardHeader
                    avatar={<ChannelAvatar slug={_.get(item, 'channel.slug', 'proforto-web-nl')}/>}
                    title={item.mage_id}
                    subtitle={item.fulfillment_source}
                    titleStyle={style.titleStyle}
                />
                <PackItemInfo item={item}/>
                {this.getInstructionIndicator(item.instructions)}
            </GridTile>
        )
    }
}

PackItem.propTypes = {
    item: PropTypes.object,
    isSelected: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
}

PackItem.defaultProps = {
    isSelected: false,
    item: {
        fulfillment_id: '',
        line_items: [],
    },
}

PackItem.contextTypes = {
    colors: PropTypes.object.isRequired,
}

export default PackItem
