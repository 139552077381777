import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Avatar,
    Card,
    FontIcon,
    ListItem,
    Divider,
} from 'material-ui'
import ReactTooltip from 'react-tooltip'
import { Draggable } from '@proforto/react-drag-and-drop'

import Colors from './../../../styles/colors'

const style = {
    container: {
        marginBottom: 15,
        width: '100%',
        position: 'relative',
    },
}

const inbound_status_map = {
    approved: {
        icon: 'check',
        color: Colors.green600,
        tip: 'The item has been received by Proforto DC',
    },
    postponed: {
        icon: 'date_range',
        color: Colors.amber600,
        tip: 'The item has been marked as postponed delivery by Proforto DC',
    },
    rejected: {
        icon: 'close',
        color: Colors.red600,
        tip: 'The item has not been received by Proforto DC',
    },
}

class ItemDateGroup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: [],
            inboundAvatar: {
                target: null,
                hovered: false,
            },
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps.reset && prevState.selected.length > 0 ? { selected: [] } : null
    }

    handleMouseUp(event, triage_item, index) {
        if (triage_item.closed_at && triage_item.status === 'rejected') {
            return
        }

        let newState = {}
        if (event.shiftKey === true) {

            const rootItem = this.state.selected.find(({ root }) => root)
            if (rootItem?.index === index) {
                return
            }

            const selected = this.props.triage_items
                .filter((item, i) => {
                    item.index = i
                    if (index > rootItem?.index) {
                        return i > rootItem?.index && i <= index
                    } else if (index < rootItem?.index) {
                        return i < rootItem?.index && i >= index
                    }
                    return true
                })
                .map(item => ({ triage_item: item, index: item.index }))

            newState = { selected: rootItem ? [ rootItem, ...selected ] : selected }
        } else {
            newState = { selected: [{ triage_item, index, root: true }] }
        }

        this.setState(newState, () => {
            this.props.onSelectItems(triage_item.status, triage_item.date, this.state.selected.map(sel => {
                return sel.triage_item
            }))
        })
    }

    setEventData(event) {
        event.dataTransfer.setData(
            this.props.status,
            JSON.stringify(this.state.selected.map(({ triage_item }) => triage_item))
        )
    }

    handleDragStart(event, triage_item, idx) {
        event.persist()
        const found = this.state.selected.find(({ index }) => idx === index)
        if (!found) {
            this.setState({ selected: [{ triage_item, index: idx, root: true }] }, () => {
                this.setEventData(event)
            })
        } else {
            this.setEventData(event)
        }
    }

    getInboundAvatar(status) {
        const avatarStyle = inbound_status_map[status]

        return (
            <Avatar
                size={40}
                style={{ cursor: 'help' }}
                icon={<FontIcon className="material-icons">{avatarStyle.icon}</FontIcon>}
                color={avatarStyle.color}
                backgroundColor="#FFF"
                data-tip={avatarStyle.tip}
            >
                <ReactTooltip effect="solid" />
            </Avatar>
        )
    }

    render() {
        const contents = this.props.triage_items.map((triage_item, idx) => {
            const backgroundColor = this.state.selected.map(({ index }) => index).includes(idx) ? '#bbb' : '#fff'
            let additionalText = null

            if (triage_item.size || triage_item.color) {
                const size = triage_item.size ? `Size: ${triage_item.size}${triage_item.color ? ' ' : ''}` : ''
                const color = triage_item.color ? `Color: ${triage_item.color}` : ''
                additionalText = `(${size}${color})`
            }

            if (triage_item.closed_at && triage_item.status !== 'approved') {
                return [
                    <ListItem
                        style={{ backgroundColor }}
                        disabled={true}
                        innerDivStyle={{ marginLeft: 0, backgroundColor }}
                        key={triage_item.supplier_sku}
                        primaryText={
                            <div style={style.primaryTextSubListItemStyle}>
                                {triage_item.name} {additionalText}
                            </div>
                        }
                        secondaryTextLines={2}
                        secondaryText={
                            <div>
                                <div style={style.primaryTextSubListItemStyle}>
                                    {`SKU: ${triage_item.supplier_sku}`}
                                </div>
                                <div style={style.primaryTextSubListItemStyle}>
                                    {`Order: ${triage_item.mage_id}`}, {`Quantity: ${triage_item.quantity}`}
                                </div>
                            </div>
                        }
                        rightAvatar={!triage_item.inbound_item || triage_item.inbound_item.status === 'pending'
                            ? null
                            : this.getInboundAvatar(triage_item.inbound_item.status)
                        }
                    />,
                    <Divider key="divider.2" />,
                ]
            }

            return (
                <Draggable
                    key={`${triage_item}.${idx}`}
                    type={triage_item.status}
                    data={triage_item}
                    style={{ cursor: '-webkit-grab' }}
                    onDragStart={e => this.handleDragStart(e, triage_item, idx)}
                >
                    <ListItem
                        style={{ backgroundColor }}
                        disabled={true}
                        onMouseUp={event => {
                            this.handleMouseUp(event, triage_item, idx)
                        }}
                        innerDivStyle={{ marginLeft: 0, backgroundColor }}
                        key={triage_item.supplier_sku}
                        primaryText={
                            <div style={style.primaryTextSubListItemStyle}>
                                {triage_item.name} {additionalText}
                            </div>
                        }
                        leftAvatar={
                            <Avatar
                                size={40}
                                icon={<FontIcon className="material-icons">menu</FontIcon>}
                                color="#000"
                                backgroundColor="transparent"
                            />
                        }
                        secondaryTextLines={2}
                        secondaryText={
                            <div>
                                <div style={style.primaryTextSubListItemStyle}>
                                    {`SKU: ${triage_item.supplier_sku}`}
                                </div>
                                <div style={style.primaryTextSubListItemStyle}>
                                    {`Order: ${triage_item.mage_id}`}, {`Quantity: ${triage_item.quantity}`}
                                </div>
                            </div>}
                    />
                    <Divider key="divider.2" />
                </Draggable>
            )
        })

        const containerStyle = { ...style.container, ...this.props.style }
        return this.props.wrap
            ? <Card style={containerStyle}>{contents}</Card>
            : <div style={containerStyle}>{contents}</div>
    }
}

ItemDateGroup.propTypes = {
    reset: PropTypes.bool,
    status: PropTypes.string,
    style: PropTypes.object,
    wrap: PropTypes.bool,
    triage_items: PropTypes.array,
    onSelectItems: PropTypes.func,
}
ItemDateGroup.defaultProps = {
    reset: false,
    style: {},
    wrap: true,
    triage_items: [],
}

export default ItemDateGroup
