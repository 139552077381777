import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'

import { useQuery} from 'react-query'
import { syncSocketCall } from '../../../socket'

export interface SyncStatusDialogProps {
    isOpen: boolean
    onClose: () => void
}

export default function SyncStatusDialog(props: SyncStatusDialogProps) {

    const [ startVariantCount, setStartVariantCount ] = React.useState(0)
    const [ startTime, setStartTime ] = React.useState(0)

    const { data, error, isFetching } = useQuery(
        'sync-status/profortool-to-pim',
        async () => {
            return await syncSocketCall('sync-status/profortool-to-pim', null, { timeout: 10000 })
        },
        { refetchInterval: 3000, enabled: props.isOpen }
    )

    const onClose = () => {
        setStartTime(0)
        props.onClose()
    }

    const formatHoursMinutes =
        (n: number) => (n === Infinity || isNaN(n) || n < 0)
            ? '?'
            : `${Math.floor(n / 60)}:${n < 60 ? '0' : ''}${Math.floor(n % 60)}`

    const getContent = () => {
        if (data) {
            const nowTime = Date.now()
            const nowVariantCount = data.magento1?.variants ?? 0
            if (!startTime) {
                setStartTime(nowTime - 1) // minus one millisecond to prevent division by zero
                setStartVariantCount(nowVariantCount)
            }
            const minutesElapsed = (nowTime - startTime) / 1000 / 60
            const variantsPerMinute = (startVariantCount - nowVariantCount) / minutesElapsed
            const eta = nowVariantCount / variantsPerMinute

            return (
                <>
                    {error ? 'ERROR: ' + error : null}
                    <TableContainer component={Paper}>
                        <Table aria-label="sync-status-table">
                            <TableBody>
                                <TableRow key='magento1-variants'>
                                    <TableCell component="th" scope="row">Profortool Variants -&gt; Magento1</TableCell>
                                    <TableCell align="right">{nowVariantCount}</TableCell>
                                    <TableCell>(ETA {formatHoursMinutes(eta)})</TableCell>
                                </TableRow>
                                <TableRow key='magento1-stocks'>
                                    <TableCell component="th" scope="row">Profortool Stocks -&gt; Magento1</TableCell>
                                    <TableCell align="right">{data?.magento1?.stocks ?? 0}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        <Box sx={{ width: '100%', height: '4px' }}>{
                            isFetching ? <LinearProgress /> : null
                        }</Box>
                    }
                </>
            )
        }

        if (error) return 'ERROR: ' + error
        if (isFetching) return 'Loading...'
    }

    return (
        <Dialog maxWidth='sm' onClose={onClose} open={props.isOpen ?? false}>
            <DialogTitle id="sync-status-dialog-title">
                {'Inventory sync status'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getContent()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button key="close" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
