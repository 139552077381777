import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {SelectField, MenuItem} from 'material-ui'

const style = {
    width: '100%',
    marginRight: '20px',
    fontSize: 14,
}

class LineItemSelect extends Component {

    constructor(props) {
        super(props)
        const initialValues = props.initialValues || [{
            product: {
                name: 'All',
            },
        }]
        this.state = {
            filterValues: [...initialValues].concat(LineItemSelect.filterPostponeableItems(props)),
        }
    }

    static filterPostponeableItems(props) {
        const itemValues =
            props.lineItems.filter(li => li.status !== 'cancelled' && li.status !== 'postponed' && !li.is_promo)

        const initialValues = props.initialValues || [{
            product: {
                name: 'All',
            },
        }]

        return [...initialValues, ...itemValues].map((v, i) => {
            v._index = i
            return v
        })
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.lineItems !== this.props.lineItems
            || nextProps.filterValue !== this.props.filterValue
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps) {
            return {
                ...prevState,
                filterValues: LineItemSelect.filterPostponeableItems(nextProps),
            }
        }
        return prevState
    }

    handleFilterChange(e, index, value) {
        this.props.onFilterChange(this.state.filterValues[value])
    }

    render() {
        return (
            <SelectField
                style={style}
                floatingLabelText="Select line item"
                hintText="Select line item"
                value={this.props.filterValue
                    ? this.state.filterValues.findIndex((v, i) => i === this.props.filterValue._index)
                    : -1}
                onChange={this.handleFilterChange.bind(this)}
            >
                {
                    this.state.filterValues.map(value => (
                        <MenuItem
                            key={`${value.product.name}.${value._index}`}
                            value={value._index}
                            primaryText={value.product.name}
                        />
                    ))
                }
            </SelectField>
        )
    }
}

LineItemSelect.propTypes = {
    source: PropTypes.string,
    initialValues: PropTypes.array,
    filterValue: PropTypes.object,
    lineItems: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
}

LineItemSelect.defaultProps = {
    source: '',
    lineItems: [],
}

export default LineItemSelect
