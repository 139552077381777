import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardActions,
    CardHeader,
    Dialog,
    Divider,
    FontIcon,
    FlatButton,
    Table,
    TableBody,
    TableRow,
    TableHeader,
    TableHeaderColumn,
    TableRowColumn,
    TextField,
} from 'material-ui'

import _ from 'lodash'

import {downloadBase64AsPdf} from '../../../../utils/download'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getPackingSlip} from './../../../../../commands/sockets/packing_slips'
import {submitPackedLineItems, updatePackingLineItems} from './../../../../../actions/packing_items'
import {getShipmentCancellations} from './../../../../../actions/cancellations'

import Devices from './../../../../styles/devices'
import Colors from './../../../../styles/colors'

import PackItemInfo from './shipment-pack-item/ShipmentPackItemInfo'
import ChannelAvatar from './../../../shared/ChannelAvatar'
import Instructions from './../../../shared/Instructions'

const style = {
    arrowContainer: {
        width: '5%',
        flex: '1 1 auto',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-around',
    },
    arrowIcon: {
        fontSize: 32,
    },
    card: {
        width: '100%',
        flex: '1 1 auto',
        position: 'relative',
    },
    cardTitleStyle: {
        fontFamily: 'Roboto',
        color: Colors.grey800,
    },
    tableHeaderColumn: {
        textTransform: 'uppercase',
    },
    cardActions: {
        marginTop: 20,
        width: '75%',
    },
    instructions: {
        marginTop: 10,
    },
}

class ShipmentPackingItemDetail extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            packing_item: {},
            downloadedShipment: {},
            pending_cancellations: false,
            order_id: '',
            fulfillment: {},
            selectedItems: [],
            client_items: [],
            allRowsSelected: false,
        }
    }

    componentDidMount() {
        if (this.props.item._id) {
            this.props.getShipmentCancellations(this.props.item.shipment_id)
        }
    }

    componentDidUpdate() {
        if (this.state.downloadedShipment._id &&
            this.props.packing_slip.shipment_id &&
            this.state.downloadedShipment._id === this.props.packing_slip.shipment_id) {
            this.downloadPackingSlip(this.props.packing_slip)
        }
    }

    getPackedIndexes(packing_item) {
        return _.map(_.filter(packing_item.line_items, li => Boolean(li.packed_at)), li => {
            return packing_item.line_items.indexOf(li)
        })
    }

    downloadPackingSlip(data) {
        if (data.error) {
            this.setState({
                noPackingSlipFound: true,
            })
        } else {
            this.setState({
                downloadedShipment: {},
            }, () => {
                downloadBase64AsPdf(data.blob, this.props.item.mage_id)
            })
        }
    }

    handleDownloadPackingSlipClick() {
        const item = this.props.item
        const shipment_id = item.shipment_id
        this.setState({
            downloadedShipment: {
                _id: shipment_id,
            },
        }, () => {
            this.props.getPackingSlip(shipment_id, item.fulfillment_source)
        })
    }

    handleCellClick(row) {
        const newState = {}
        const packedItem = {...this.props.client_items[row]}
        const packed = packedItem.packed_quantity > 0
        packedItem.index = row
        if (packedItem.quantity > 1) {
            newState.packedItem = packedItem
            newState.packedItemQuantity = newState.packedItem.quantity
            this.setState(newState)
        } else {
            packedItem.packed_quantity = packed ? 0 : 1
            this.updateItems(packedItem)
        }
    }

    handlePackedItemQuantityChange({target}) {
        const value = +target.value
        if (value >= 0 && this.state.packedItem.quantity >= value) {
            this.setState({packedItemQuantity: value})
        }
    }

    handleDialogRequestClose() {
        this.setState({
            packedItem: null,
            packedItemQuantity: 0,
        })
    }

    handleDialogSubmit() {
        const packItem = this.state.packedItem
        packItem.packed_quantity = this.state.packedItemQuantity
        this.updateItems(packItem)
        this.handleDialogRequestClose()
    }

    updateItems(packItem) {
        const line_items = [...this.props.client_items.filter((li, index) => packItem.index !== index), packItem]
        this.props.updatePackingLineItems({
            ...this.props.item,
            line_items,
        })
    }

    handlePackedAtSaveClick(e) {
        e.preventDefault()
        e.stopPropagation()
        const packing_item = this.props.item
        const line_items = _.map(packing_item.line_items, item => {
            if (item.packed_quantity > 0 && !item.packed_at) {
                item.packed_at = new Date().toISOString()
            } else if (item.packed_quantity === 0 && item.packed_at) {
                item.packed_at = null
            }
            return item
        })
        const packed = line_items.every(li => li.packed_at !== null && li.quantity === li.packed_quantity)
        this.props.submitPackedLineItems(packing_item._id, {line_items, packed})
    }

    getInstructions(instructions) {
        return !(instructions instanceof Array) || !instructions.length ? null : (
            <Instructions
                style={style.instructions}
                instructions={instructions}
                wrap={false}
                canAdd={false}
            />
        )
    }

    getContent() {
        const isTablet = this.context.device === Devices.TABLET
        if (this.state.pending_cancellations) {
            return (
                <div style={{paddingLeft: 15, paddingRight: 15}}>
                    <span style={{fontStyle: 'italic'}}>
                        {'The order for this packing item has one or more pending cancellations. Click '}
                        <a href={`#/shipments/${this.state.order_id}`} target="_blank" rel="noreferrer">here</a>
                        {' to accept or reject the cancellation'}
                    </span>
                </div>
            )
        }

        const showType = this.props.client_items.some(li => !!li.type)
        const client_items = this.props.client_items
        const header = isTablet ? null : (
            <TableHeader displaySelectAll={false} enableSelectAll={false}>
                <TableRow>
                    <TableHeaderColumn style={style.tableHeaderColumn}>
                        SKU
                    </TableHeaderColumn>
                    <TableHeaderColumn style={{...style.tableHeaderColumn, width: '20%'}}>
                        Quantity
                    </TableHeaderColumn>
                    {!showType ? null : (
                        <TableHeaderColumn style={style.tableHeaderColumn}>
                            Source
                        </TableHeaderColumn>
                    )}
                </TableRow>
            </TableHeader>
        )
        const instructions = this.getInstructions(this.props.item.instructions)
        return (
            <div>
                <Table
                    onCellClick={this.handleCellClick.bind(this)}
                    multiSelectable={true}
                    wrapperStyle={!isTablet ? null : {maxHeight: '208px', overflowY: 'scroll'}}
                >
                    {header}
                    <TableBody
                        deselectOnClickaway={false}
                        showRowHover={true}
                    >
                        {
                            client_items.map((li, index) => {
                                return (
                                    <TableRow
                                        key={`${li.sku}-${li.client_packed}-${index}`}
                                        selected={li.client_packed}
                                    >
                                        <TableRowColumn style={style.tableHeaderColumn}>{li.sku}</TableRowColumn>
                                        <TableRowColumn style={{...style.tableHeaderColumn, width: '20%'}}>
                                            {li.quantity}
                                        </TableRowColumn>
                                        {
                                            !showType ? null : (
                                                <TableRowColumn style={style.tableHeaderColumn}>
                                                    {li.type}
                                                </TableRowColumn>
                                            )
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <Divider/>
                {instructions}
            </div>
        )
    }

    render() {
        const item = this.props.item
        const content = this.getContent()
        return (
            <div style={this.props.style}>
                <div style={style.arrowContainer}>
                    <FontIcon style={style.arrowIcon} className="material-icons">keyboard_arrow_right</FontIcon>
                </div>
                <Card style={style.card}>
                    <CardHeader
                        avatar={<ChannelAvatar slug={_.get(item, 'channel.slug', 'proforto-web-nl')}/>}
                        titleStyle={style.cardTitleStyle}
                        title={item.mage_id}
                    />
                    <PackItemInfo item={item}/>
                    {content}
                    <CardActions style={style.cardActions}>
                        <FlatButton
                            style={{width: '31%'}}
                            onClick={this.handlePackedAtSaveClick.bind(this)}
                            hoverColor={Colors.indigo50}
                            disabled={!this.props.item.changed}
                            primary={true}
                            labelPosition={'before'}
                            icon={<FontIcon className="material-icons">done</FontIcon>}
                        >
                            &nbsp;Save
                        </FlatButton>
                        <FlatButton
                            onClick={this.handleDownloadPackingSlipClick.bind(this)}
                            style={{width: '31%'}}
                            hoverColor={Colors.indigo50}
                            primary={true}
                            labelPosition={'before'}
                            icon={<FontIcon className="material-icons">file_download</FontIcon>}
                        >
                            &nbsp;Download
                        </FlatButton>
                    </CardActions>
                </Card>
                <Dialog
                    contentStyle={{width: 480, maxWidth: 'none'}}
                    onRequestClose={this.handleDialogRequestClose.bind(this)}
                    open={!!this.state.packedItem}
                    actions={[
                        <FlatButton
                            key="purchase_requisitions_submit"
                            onClick={this.handleDialogSubmit.bind(this)}
                            secondary={true}
                            label="Submit"
                        />,
                        <FlatButton
                            key="purchase_requisitions_close"
                            onClick={this.handleDialogRequestClose.bind(this)}
                            label="Close"
                        />,
                    ]}
                >
                    <TextField
                        value={this.state.packedItemQuantity}
                        floatingLabelText="How many are packed?"
                        min={1}
                        type="number"
                        onChange={this.handlePackedItemQuantityChange.bind(this)}
                    />
                </Dialog>
            </div>
        )
    }
}

ShipmentPackingItemDetail.propTypes = {
    packing_slip: PropTypes.object,
    item: PropTypes.object,
    client_items: PropTypes.array,
    style: PropTypes.object,
    getPackingSlip: PropTypes.func.isRequired,
    getShipmentCancellations: PropTypes.func.isRequired,
    submitPackedLineItems: PropTypes.func.isRequired,
    updatePackingLineItems: PropTypes.func.isRequired,
}

ShipmentPackingItemDetail.contextTypes = {
    device: PropTypes.string,
}

ShipmentPackingItemDetail.defaultProps = {
    item: {
        line_items: [],
    },
    style: {},
}

export default connect(({packing_slip, cancellations}) => {
    return {
        packing_slip,
        cancellations,
    }
}, dispatch => {
    return bindActionCreators({
        getPackingSlip, getShipmentCancellations, submitPackedLineItems, updatePackingLineItems,
    }, dispatch)
})(ShipmentPackingItemDetail)
