import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FloatingActionButton, FontIcon } from 'material-ui'

import Colors from './../../../styles/colors'
import { searchCreditMemos, addCreditMemo } from './../../../../actions/credit_memos'
import AddCreditMemoDialog from './AddCreditMemoDialog'

import CreditMemoTable from './CreditMemoTable'
import CreditMemoFilters from './CreditMemoFilters'

const fields = ''
const STORAGE_KEY = 'credit-memos-filter'

const style = {
    floatingActionButtonStyle: {
        position: 'fixed',
        zIndex: 5,
        right: 20,
        bottom: 20,
    },
}

class CreditMemoList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            shouldRender: false,
            isUpdated: false,
            criteria: filters.criteria || '',
            status: filters.status || 'all',
            prior_action: filters.prior_action || 'all',
            payment_method: filters.payment_method || 'all',
            page: filters.page || 1,
            limit: 20,
            sorting: filters.sorting || { created_at: -1 },
            addDialogOpen: false,
        }

        autoBind(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.shouldRender === false) {
            return {
                ...prevState,
                shouldRender: true,
            }
        }
        return null
    }

    componentDidMount() {
        this.searchCreditMemos()
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    getFiltersState() {
        return {
            criteria: this.state.criteria,
            status: this.state.status,
            prior_action: this.state.prior_action,
            payment_method: this.state.payment_method,
            fields,
            page: this.state.page,
            limit: this.state.limit,
            sorting: this.state.sorting,
        }
    }

    searchCreditMemos() {
        const state = this.getFiltersState()
        this.storeFilters(state)
        if (state.status === 'all') {
            delete state.status
        }
        if (state.prior_action === 'all') {
            delete state.prior_action
        }
        if (state.payment_method === 'all') {
            delete state.payment_method
        }
        this.props.searchCreditMemos(state)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchCreditMemos, 500)
    }

    setStateWithoutRender(state, timeout) {
        this.setState({
            ...state,
            shouldRender: false,
        }, timeout ? this.setSearchTimeout : this.searchCreditMemos)
    }

    handleSearchChange(criteria) {
        this.setStateWithoutRender({
            criteria,
            sorting: { created_at: -1 },
            page: 1,
        }, true)
    }

    handleStatusChange(status) {
        this.setStateWithoutRender({
            status,
            sorting: { created_at: -1 },
            page: 1,
        })
    }

    handleActionChange(prior_action) {
        this.setStateWithoutRender({
            prior_action,
            sorting: { created_at: -1 },
            page: 1,
        })
    }

    handlePaymentMethodChange(payment_method) {
        this.setStateWithoutRender({
            payment_method,
            sorting: { created_at: -1 },
            page: 1,
        })
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            page: this.state.page + 1,
        })
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            page: this.state.page - 1,
        })
    }

    handleFloatingActionButtonClick() {
        this.setState({
            addDialogOpen: true,
        })
    }

    handleCloseDialogClick() {
        this.setState({
            addDialogOpen: false,
        })
    }

    handleAddDialogSubmit(state) {
        this.handleCloseDialogClick()
        this.props.addCreditMemo(state)
    }

    handleSortChange(value, property) {
        const newSorting = {}
        newSorting[property] = value
        const newState = {
            page: 1,
            sorting: newSorting,
        }
        this.setState(newState, this.searchCreditMemos)
    }

    render() {
        return (
            <div>
                <CreditMemoFilters
                    filters={this.getFiltersState()}
                    onSearchChange={this.handleSearchChange}
                    onStatusChange={this.handleStatusChange}
                    onActionChange={this.handleActionChange}
                    onPaymentMethodChange={this.handlePaymentMethodChange}
                />
                <CreditMemoTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.page}
                    limit={this.state.limit}
                    items={this.props.credit_memos}
                    sorting={this.state.sorting}
                    onSortChange={this.handleSortChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                <FloatingActionButton
                    onClick={this.handleFloatingActionButtonClick}
                    backgroundColor={Colors.primaryColor100}
                    style={style.floatingActionButtonStyle}
                >
                    <FontIcon className="material-icons">add</FontIcon>
                </FloatingActionButton>

                <AddCreditMemoDialog
                    visible={this.state.addDialogOpen}
                    onClose={this.handleCloseDialogClick}
                    onSubmit={this.handleAddDialogSubmit}
                />
            </div>
        )
    }
}

CreditMemoList.propTypes = {
    credit_memos: PropTypes.array.isRequired,
    filters: PropTypes.object,
    searchCreditMemos: PropTypes.func.isRequired,
    addCreditMemo: PropTypes.func.isRequired,
}

export default connect(({ credit_memos, pagination, filters }) => {
    return {
        credit_memos,
        pagination,
        filters: filters.credit_memos || {},
    }
}, dispatch => {
    return bindActionCreators({ searchCreditMemos, addCreditMemo }, dispatch)
})(CreditMemoList)

