import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table, TableHeader, TableBody, TableRow, TableHeaderColumn, TableRowColumn} from 'material-ui'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import TableRowWrapper from './../shared/TableRowWrapper'
import TableRowColumnWrapper from './../shared/TableRowColumnWrapper'
import Notes from '../shared/Notes'

const style = {
    table: {overflowY: 'scroll'},
    tableBody: {height: 300},
    searchField: {fontWeight: 'normal', marginLeft: 24},
}

const widths = {
    rma: {
        name: '30%',
        sku: '15%',
        ean: '15%',
        quantity: '10%',
        return: '10%',
        return_reason: '20%',
    },
    rtv: {
        name: '40%',
        sku: '20%',
        ean: '20%',
        quantity: '10%',
        return: '10%',
    },
}

class DialogLineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: '',
        }
    }

    handleTextFieldChange({target}) {
        this.setState({
            search: target.value,
        })
    }

    getNotes(notes) {
        if (!notes || notes.length === 0) {
            return null
        }
        return (
            <Notes
                canAdd={false}
                notes={this.props.notes}
                title="Order notes"
                wrap={false}
            />
        )
    }

    render() {
        if (this.props.line_items.length === 0) {
            return this.props.noItemsMessage || null
        }

        const filterRegExp = new RegExp(this.state.search, 'i')
        const items = this.props.line_items.map((li, index) => {
            li.selected = li.returned_quantity > 0
            li.index = index
            return li
        }).filter(({name, supplier_sku, ean}) => {
            return this.state.search === ''
                || filterRegExp.test(name)
                || filterRegExp.test(supplier_sku)
                || filterRegExp.test(ean)
        })

        const notes = this.getNotes(this.props.notes)
        const width = widths[this.props.type]
        return (
            <div>
                <TextField
                    floatingLabelText="Search items by name, sku or ean"
                    style={style.searchField}
                    value={this.state.search}
                    onChange={this.handleTextFieldChange.bind(this)}
                />
                <Table
                    style={style.table}
                    bodyStyle={style.tableBody}
                    multiSelectable={true}
                    onRowSelection={selectedRows => { this.props.onRowSelection(selectedRows, items) }}
                >
                    <TableHeader adjustForCheckbox={true} displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn style={{width: width.name}}><span>Name</span></TableHeaderColumn>
                            <TableHeaderColumn style={{width: width.sku}}><span>SKU</span></TableHeaderColumn>
                            <TableHeaderColumn style={{width: width.ean}}><span>EAN</span></TableHeaderColumn>
                            <TableHeaderColumn style={{width: width.quantity}}><span>Quantity</span></TableHeaderColumn>
                            <TableHeaderColumn style={{width: width.return}}><span>Return</span></TableHeaderColumn>
                            {this.props.type === 'rma' && (
                                <TableHeaderColumn
                                    style={{width: width.return_reason}}
                                >
                                    <span>Reason</span>
                                </TableHeaderColumn>
                            )}
                        </TableRow>
                    </TableHeader>
                    <TableBody deselectOnClickaway={false}>
                        {
                            items.map(li => {
                                return (
                                    <TableRowWrapper
                                        selectable={true}
                                        selected={li.selected}
                                        key={`${li.index}.${li.supplier_sku}`}
                                    >
                                        <TableRowColumn style={{width: width.name}}>{li.name}</TableRowColumn>
                                        <TableRowColumn style={{width: width.sku}}>{li.supplier_sku}</TableRowColumn>
                                        <TableRowColumn style={{width: width.ean}}>{li.ean}</TableRowColumn>
                                        <TableRowColumn style={{width: width.quantity}}>{li.quantity}</TableRowColumn>
                                        <TableRowColumnWrapper style={{width: width.return}}>
                                            <TextField
                                                id={`${li.index}.${li.supplier_sku}-return-quantity`}
                                                style={{width: 70}}
                                                type="number"
                                                value={li.returned_quantity}
                                                onChange={e => {
                                                    const quantity = +e.target.value
                                                    if (quantity > 0 || quantity < li.quantity) {
                                                        this.props.onQuantityChange(li.index, e.target.value)
                                                    }
                                                }}
                                            />
                                        </TableRowColumnWrapper>
                                        {this.props.type === 'rma' && (
                                            <TableRowColumnWrapper style={{width: width.return_reason}}>
                                                <TextField
                                                    select
                                                    id={`${li.index}.${li.supplier_sku}-return-reason`}
                                                    value={li.return_reason}
                                                    onChange={e => {
                                                        this.props.onReasonChange(li.index, e.target.value)
                                                    }}
                                                    fullWidth={true}
                                                >
                                                    {Object.keys(this.props.return_reasons).map(key => (
                                                        <MenuItem value={key} key={key}>
                                                            {this.props.return_reasons[key]}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableRowColumnWrapper>
                                        )}
                                    </TableRowWrapper>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {notes}
            </div>
        )
    }
}

DialogLineItems.propTypes = {
    type: PropTypes.string.isRequired,
    return_reasons: PropTypes.object,
    line_items: PropTypes.array,
    noItemsMessage: PropTypes.node,
    onRowSelection: PropTypes.func.isRequired,
    onQuantityChange: PropTypes.func.isRequired,
    onReasonChange: PropTypes.func,
    notes: PropTypes.array,
}

DialogLineItems.defaultProps = {
    line_items: [],
    selectedRows: [],
    notes: [],
}
export default DialogLineItems
