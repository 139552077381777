import socket from './../socket'
import listeners from '../listeners/sockets/credit_memos.js'
import moment from 'moment'

listeners(socket)

function getPriorAction(c) {
    if (c.trigger === 'manual') {
        return 'Manual'
    }

    if (c.rma_id) {
        return `RMA (${c.rma_id})`
    }

    if (c.discount_id) {
        return `Discount (${c.discount_id})`
    }

    return 'Cancellation'
}

export function credit_memos(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SEARCH_CREDIT_MEMOS':
            socket.emit('credits/search', action.filters)
            return state

        case 'REFRESH_CREDIT_MEMOS':
            return action.credit_memos.map(c => {
                const rma_created_at = c.rma_created_at ? moment(c.rma_created_at).format('DD-MM-YY HH:mm') : ''
                const refund_deadline =
                    c.refund_deadline ? moment(c.refund_deadline).format('DD-MM-YY') : ''

                return {
                    ...c,
                    customer_name: c.customer.name,
                    prior_action: getPriorAction(c),
                    created_at: moment(c.created_at).format('DD-MM-YY HH:mm'),
                    rma_created_at,
                    refund_deadline,
                    grand_total: parseFloat(c.grand_total).toFixed(2),
                }
            })

        default:
            return state
    }
}

export function credit_memo(state = null, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return null

        case 'GET_CREDIT_MEMO':
            socket.emit('credit/get', action.id)
            return state

        case 'REJECT_CREDIT_MEMO':
            socket.emit('credit/reject', action.id)
            return state

        case 'ACCEPT_CREDIT_MEMO':
            socket.emit('credit/accept', action.id)
            return state

        case 'REFUND_CREDIT_MEMO':
            socket.emit('credit/refund', action.id)
            return state

        case 'REFUNDED_CREDIT_MEMO':
            socket.emit('credit/refunded', action.id)
            return state

        case 'ON_HOLD_CREDIT_MEMO':
            socket.emit('credit/put_on_hold', action.id)
            return state

        case 'ADD_CREDIT_MEMO':
            socket.emit('credit/add', action.memo)
            return state

        case 'ADD_CREDIT_MEMO_NOTE':
            socket.emit('credit/add_note', { _id: action.id, note: action.note })
            return state

        case 'SYNCHRONIZE_CREDIT_MEMO':
            socket.emit('credit/synchronize', action.id)
            return state

        case 'REFRESH_CREDIT_MEMO':
            if (!action.credit_memo) { return null }

            return {
                ...action.credit_memo,
                prior_action: getPriorAction(action.credit_memo),
            }

        default:
            return state
    }
}
