import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { searchProductionOrders } from '../../../../commands/sockets/production_orders'

import DataTable from '../../shared/DataTable'
import PersonalisationOrderFilters from './PersonalisationOrderFilters'

const styles = {
    container: {
        width: '100%',
    },
}
const STORAGE_KEY = 'production-filters'
const headerCols = [{
    content: 'Production order',
    tooltip: 'The sales order reference with a production prefix',
    property: 'production_order_id',
}, {
    content: 'Status',
    tooltip: 'Status of the production order',
    property: 'status',
}, {
    content: 'Produce at',
    tooltip: 'Location where the order will be produced',
    property: 'location',
}, {
    content: 'Date',
    tooltip: 'Order date',
    property: 'created_at',
}, {
    content: 'Email',
    tooltip: 'Customer email',
    property: 'email',
}, {
    content: 'Press',
    tooltip: 'Used press for production',
    property: 'used_press',
}, {
    content: 'Press worker',
    tooltip: 'Press worker that executes the production',
    property: 'press_worker',
}, {
    content: 'Unique',
    tooltip: 'Unique skus count',
    property: 'unique',
}, {
    content: 'Total',
    tooltip: 'Total item count',
    property: 'total',
}]

class PersonalisationOrderList extends Component {

    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            filters: {
                production_order_id: filters.production_order_id || '',
                email: filters.email || '',
                statuses: filters.statuses || ['open'],
                locations: filters.locations || ['internal'],
                used_press: filters.used_press || [],
                press_worker: filters.press_worker || [],
                items_ready: !!filters.items_ready || false,
                page: filters.page || 1,
                limit: filters.limit || 20,
                sorting: { created_at: -1 },
            },
        }

        autoBind(this)
    }

    componentDidMount() {
        this.searchProductionOrders()
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(filters) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(filters))
    }

    getFiltersState() {
        return { ...this.state.filters }
    }

    searchProductionOrders() {
        const filters = this.getFiltersState()
        this.storeFilters(filters)
        if (filters.status === 'all') {
            delete filters.status
        }
        this.props.searchProductionOrders(filters)
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchProductionOrders, 500)
    }

    setStateWithoutRender(state, timeout) {
        this.setState({
            ...state,
            shouldRender: false,
        }, timeout ? this.setSearchTimeout : this.searchProductionOrders)
    }

    handleTextFieldChange(value, prop) {
        const state = { shouldRender: false, filters: { ...this.state.filters, page: 1 } }
        state.filters[prop] = value
        this.setState(state, this.setSearchTimeout)
    }

    handleSelectChange(value, prop) {
        const state = { shouldRender: false, filters: { ...this.state.filters, page: 1 } }
        state.filters[prop] = value
        this.setState(state, this.searchProductionOrders)
    }

    handleSwitchChange(value, prop) {
        const state = { shouldRender: false, filters: { ...this.state.filters, page: 1 } }
        state.filters[prop] = value
        this.setState(state, this.searchProductionOrders)
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            filters: { ...this.state.filters, page: this.state.filters.page + 1 },
        }, false)
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            filters: { ...this.state.filters, page: this.state.filters.page - 1 },
        }, false)
    }

    handleTableRowClick(rowNumber) {
        if (this.props.production_orders[rowNumber]) {
            const id = this.props.production_orders[rowNumber]._id
            this.context.router.push(`/production/production-orders/${id}`)
        }
    }

    handleLimitChange(limit) {
        this.setStateWithoutRender({
            filters: { ...this.state.filters, limit },
        }, false)
    }

    mapProductionOrder(production_order) {
        return !production_order ? {} : {
            production_order_id: production_order.production_order_id ?? production_order.refs.order_ref,
            created_at: moment(production_order.created_at).format('DD-MM-YY'),
            email: (production_order.customer.email || ''),
            status: production_order.status.replace(/_/g, ' '),
            location: production_order.location,
            total: production_order.counts.total,
            unique: production_order.counts.unique,
            used_press: production_order.used_press instanceof Array && production_order.used_press.join(' / '),
            press_worker: production_order.press_worker instanceof Array && production_order.press_worker.join(' / '),
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <PersonalisationOrderFilters
                    onTextFieldChange={this.handleTextFieldChange}
                    onSelectChange={this.handleSelectChange}
                    onSwitchChange={this.handleSwitchChange}
                    filters={this.state.filters}
                />
                <DataTable
                    page={this.state.filters.page - 1}
                    limit={this.state.filters.limit}
                    rowKeyField="production_order_id"
                    emptyListMessage="No production orders found"
                    rows={this.props.production_orders}
                    mapRow={this.mapProductionOrder}
                    onTableRowClick={this.handleTableRowClick}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                    onLimitChange={this.handleLimitChange}
                    headerCols={headerCols}
                />
            </div>
        )
    }
}

PersonalisationOrderList.propTypes = {
    production_orders: PropTypes.array,
    filters: PropTypes.object.isRequired,
    searchProductionOrders: PropTypes.func.isRequired,
}
PersonalisationOrderList.defaultProps = {
    production_orders: [],
}
PersonalisationOrderList.contextTypes = {
    router: PropTypes.object,
}

export default connect(({ production_orders, filters }) => {
    return {
        production_orders,
        filters: filters.production_orders || {},
    }
}, dispatch => {
    return bindActionCreators({ searchProductionOrders }, dispatch)
})(PersonalisationOrderList)
