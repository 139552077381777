import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Divider, FlatButton, Subheader } from 'material-ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateWallQuantity, unassignWallCompartment } from '../../../../../commands/sockets/wall'

import ChannelAvatar from './../../../shared/ChannelAvatar'
import LoadingIcon from './../../../shared/LoadingIcon'
import PackingLineItems from './PackingLineItems'
import { get } from 'lodash'
import { splitPackingItem, getPickablePackingLineItems } from '../../../../../commands-sync/packing-items'
import _ from 'lodash'

const styles = {
    subheader: { paddingLeft: 0 },
    dialogContent: { width: 1024 },
    compartmentUnselected: {
        height: 50,
        margin: 1,
        color: 'white',
        fontWeight: 'bold',
        padding: 5,
    },
    compartmentSelected: {
        height: 50,
        margin: 1,
        color: 'white',
        fontWeight: 'bold',
        padding: 2,
        border: '3px solid black',
    },
    headerStyle: { fontSize: 24, paddingLeft: 0 },
    grid: { display: 'flex', flexFlow: 'column', marginBottom: 15 },
    row: { display: 'flex', flexFlow: 'row' },
    unassignButton: {
        color: 'red',
        float: 'right',
    },
    splitButton: {
        color: 'orange',
        float: 'right',
    },
    cancelSplitButton: {
        color: 'red',
        float: 'right',
    },
    confirmSplitButton: {
        color: 'blue',
        float: 'right',
    },
    loading: {
        cursor: 'wait',
        opacity: 0.3,
    },
    loadingIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 0',
    },
}

class WallDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedCompartment: null,
            confirmSplitWindowOpen: false,
            loading: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selectedCompartment) {
            const compartment = nextProps.compartments.find(c => c.name === prevState.selectedCompartment.name)
            if (!compartment || !compartment.occupied) {
                return {
                    selectedCompartment: null,
                }
            }

            try {
                return {
                    selectedCompartment: {
                        ...prevState.selectedCompartment,
                        packable: compartment.packable,
                        occupied: compartment.occupied,
                        packing_item: !compartment.packing_item ? null : {
                            ...compartment.packing_item,
                            line_items: prevState.selectedCompartment.packing_item.line_items
                                ?.map(li => {
                                    const newLineItem = compartment.packing_item.line_items.find(a => a.sku === li.sku)
                                    return {
                                        ...li,
                                        ...newLineItem,
                                    }
                                }) ?? compartment.packing_item.line_items,
                        },
                    },
                }
            }
            catch (e) {
                console.log(e)
            }
        }
        return null
    }

    getPackingItemSplittable(compartment) {
        const uncompletedLineItems = compartment.packing_item.line_items.filter(li => li.quantity > li.wall_quantity)
        getPickablePackingLineItems({
            line_items: uncompletedLineItems,
        }).then(pickableLineItems => {
            let splittable = true

            const lineItems = _.cloneDeep(compartment.packing_item.line_items)
                .map(li => {
                    const lineItemFilled = li.quantity === li.wall_quantity
                    const missingStock = !pickableLineItems.find(a => a.sku === li.sku)

                    if (!missingStock && !lineItemFilled) {
                        splittable = false
                    }

                    return {
                        ...li,
                        missingStock: missingStock && !lineItemFilled,
                    }
                })

            this.setState({
                selectedCompartment: {
                    ...this.state.selectedCompartment,
                    packing_item: {
                        ...this.state.selectedCompartment.packing_item,
                        line_items: lineItems,
                    },
                    splittable,
                },
            })
        })
    }

    getBackgroundColorByCompartmentState(compartment) {
        if (compartment.packable) { return this.context.colors.secondaryColor100 }
        if (compartment.occupied) { return this.context.colors.amber600 }
        return this.context.colors.primaryColor100
    }

    handleCompartmentClick(selectedCompartment) {
        this.getPackingItemSplittable(selectedCompartment)

        this.setState({
            selectedCompartment: selectedCompartment.occupied
                ? selectedCompartment
                : null,
        })
    }

    handleWallQuantityChange(quantity, index) {
        if (this.state.selectedCompartment.processing
            || this.state.selectedCompartment.assigning) {
            return false
        }
        const item = this.state.selectedCompartment.packing_item.line_items[index]
        return this.props.updateWallQuantity(this.state.selectedCompartment.name, item, quantity)
    }

    handleRequestClose() {
        return this.props.onClose && this.props.onClose()
    }

    handleRaisedButtonClick() {
        const compartment = this.state.selectedCompartment
        this.setState({ selectedCompartment: null }, () => {
            this.props.unassignWallCompartment(compartment)
        })
    }

    handleSplitButtonClick() {
        this.setState({
            confirmSplitWindowOpen: true,
        })
    }

    handleCancelSplitButtonClick() {
        this.setState({
            confirmSplitWindowOpen: false,
        })
    }

    async handleConfirmSplitButtonClick() {
        this.setState({
            loading: true,
        })
        await splitPackingItem({
            packing_item_id: this.state.selectedCompartment.packing_item._id,
        }).catch(console.error)
        this.setState({
            confirmSplitWindowOpen: false,
            loading: false,
        })
    }

    render() {
        const translate = this.context.translate
        const compartment_tree = this.props.compartments.map(compartment => {
            const id_simplified = compartment.name
                .replace(`${this.props.wall}-`, '')
            return {
                row: id_simplified.replace(/[0-9]/g, ''),
                column: id_simplified.replace(/[A-Z]/gi, ''),
            }
        }).reduce((acc, compartment) => {
            const row = acc.find(r => r.name === compartment.row)
            if (row) {
                row.columns.push(compartment.column)
            } else {
                acc.push({
                    name: compartment.row,
                    columns: [compartment.column],
                })
            }
            return acc
        }, [])

        return (
            <Dialog
                open={this.props.open}
                contentStyle={styles.dialogContent}
                modal={false}
                bodyStyle={{ overflowY: 'scroll' }}
                onRequestClose={this.handleRequestClose.bind(this)}
            >
                <Subheader style={styles.subheader}>{translate('Wall')}&nbsp;{this.props.wall}</Subheader>
                <div style={styles.grid}>
                    {compartment_tree.reverse().map((row, index) => (
                        <div style={styles.row} key={`${row.name}.${index}`}>
                            {row.columns.reverse().map(column => {
                                const compartment = this.props.compartments.find(c => {
                                    return c.name === `${this.props.wall}-${column.padStart(2, '0')}${row.name}`
                                })
                                const backgroundColor = this.getBackgroundColorByCompartmentState(compartment)
                                return (
                                    <div
                                        key={`${column}.${row.name}`}
                                        style={{
                                            width: 100,
                                            backgroundColor,
                                            ...(
                                                !this.state.selectedCompartment ||
                                                    this.state.selectedCompartment.name !== compartment.name
                                                    ? styles.compartmentUnselected
                                                    : styles.compartmentSelected
                                            ),
                                        }}
                                        onClick={() => { this.handleCompartmentClick(compartment) }}
                                    >
                                        {column + row.name}
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
                {!this.state.selectedCompartment ? null : [
                    <Divider key="divider" />,
                    <Subheader style={styles.headerStyle} key="subheader">
                        <ChannelAvatar slug={get(this.state.selectedCompartment, 'packing_item.channel.slug', '')} />
                        &nbsp;
                        {get(this.state.selectedCompartment, 'packing_item.mage_id', '')}
                        <FlatButton
                            key="unassign"
                            style={styles.unassignButton}
                            label={translate('Un-assign')}
                            primary={true}
                            disabled={this.state.selectedCompartment.processing
                                || this.state.selectedCompartment.assigning}
                            onClick={this.handleRaisedButtonClick.bind(this)}
                        />
                        {(!this.state.selectedCompartment.packable && this.state.selectedCompartment.splittable) ? (
                            <FlatButton
                                key="split"
                                style={styles.splitButton}
                                label={translate('Split shipment')}
                                primary={true}
                                disabled={this.state.selectedCompartment.processing
                                    || this.state.selectedCompartment.assigning}
                                onClick={this.handleSplitButtonClick.bind(this)}
                            />
                        ) : null}
                    </Subheader>,
                    <PackingLineItems
                        key="packing_line_items"
                        line_items={get(this.state.selectedCompartment, 'packing_item.line_items', '')}
                        editable={{
                            label: 'Wall quantity',
                            property: 'wall_quantity',
                        }}
                        onEditableQuantityChange={this.handleWallQuantityChange.bind(this)}
                    />,
                ]}

                <Dialog
                    open={this.state.confirmSplitWindowOpen}
                >
                    <p>Are you sure you want to split this shipment?</p>
                    {this.state.loading && (
                        <div style={styles.loadingIconWrapper}>
                            <LoadingIcon />
                            Loading...
                        </div>
                    )}
                    <FlatButton
                        key="confirm"
                        style={{ ...styles.confirmSplitButton, ...(this.state.loading && styles.loading) }}
                        onClick={this.handleConfirmSplitButtonClick.bind(this)}
                        primary={true}
                        disabled={this.state.loading}
                    >
                        Confirm
                    </FlatButton>
                    <FlatButton
                        key="Cancel"
                        style={{ ...styles.cancelSplitButton, ...(this.state.loading && styles.loading) }}
                        onClick={this.handleCancelSplitButtonClick.bind(this)}
                        primary={true}
                        disabled={this.state.loading}
                    >
                        Cancel
                    </FlatButton>
                </Dialog>
            </Dialog>
        )
    }
}

WallDialog.contextTypes = {
    colors: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
}
WallDialog.propTypes = {
    compartments: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    wall: PropTypes.string,
    updateWallQuantity: PropTypes.func.isRequired,
    unassignWallCompartment: PropTypes.func.isRequired,
    onClose: PropTypes.func,
}
WallDialog.defaultProps = {
    wall: '',
}

export default connect(() => {
    return {}
}, dispatch => {
    return bindActionCreators({ updateWallQuantity, unassignWallCompartment }, dispatch)
})(WallDialog)
