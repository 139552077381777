import { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
} from '@material-ui/core'

import { parseCsv } from '../../../utils/csv'
import Colors from '../../../styles/colors'

const style = {
    fileButton: {
        marginRight: 20,
    },
    formatHint: {
        marginTop: 15,
    },
}

const COLUMN_PO = 0
const COLUMN_SKU = 1
const COLUMN_EAN = 2
const COLUMN_QTY = 3
const COLUMN_DATE = 4
const NUM_COLUMNS = 5

const example_csv_data = encodeURI(`
17162,DUN-DW10492701012830,7340093605380,99,4-7-22
17162,DUN-DW10492701012830,,99,4-7-22
17162,,7340093605380,99,4-7-22
17162,DUN-DW10492701012830,,99,04-07-2022
17162,DUN-DW10492701012830,,,04-07-2022
17162,DUN-DW10492701012830,,,4-7-2022
17162,DUN-DW10492701012830,,,24-8-42
17162,DUN-DW10492701012830,,,31-12-22
`)

/**
 * @param {string} num - string representing number to format
 * @return {string} - string containing num, padded to two digits
 */
function to2digits(num) {
    return +num < 10 ? `0${+num}` : num
}

/**
 * @param {string} year - string representing year to format
 * @return {string} - string containing year, completed with leading digits
 */
function to4digits(year) {
    // Unfortunately, the requirement is to be able to provide years with only 2 digits.
    // Assume 21st century
    return +year < 100 ? `20${+year}` : year
}

class PurchaseOrderPostponeViaCsvDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file_data: [],
            file_name: '',
        }

        autoBind(this)
    }

    handleSubmit() {
        this.props.onSubmit(this.state.file_data)
        return this.props.closeOnSubmit && this.props.onClose()
    }

    /**
     * @param {array} row - field data: an array of fields
     *
     * @return {object} - result object:
     *   if not valid:
     *     - error: {String} - reason why the record is invalid
     *   if valid:
     *     - ok: {Object} - object with named fields
     */
    validateRow(row) {
        if (row.length !== NUM_COLUMNS) {
            return { error: `Has ${row.length} fields, should have ${NUM_COLUMNS} fields` }
        }

        if (!row[COLUMN_PO]) {
            return { error: `Field ${COLUMN_PO + 1} (PO number) should be provided` }
        } else if (row[COLUMN_PO].match(/\D/)) {
            return { error: `Field ${COLUMN_PO + 1} (PO number) should only contain digits` }
        }

        if (row[COLUMN_EAN].match(/\D/)) {
            return { error: `Field ${COLUMN_EAN + 1} (EAN) should only contain digits` }
        }
        if (!row[COLUMN_SKU] && !row[COLUMN_EAN]) {
            return { error: `Either field ${COLUMN_SKU + 1} (SKU) or field ${COLUMN_EAN + 1} (EAN) should be provided` }
        }

        if (!row[COLUMN_QTY].match(/^-?\d*$/)) {
            return { error: `Field ${COLUMN_QTY} (quantity) should only contain digits` }
        }
        const quantity = (row[COLUMN_QTY] === '') ? -1 : +row[COLUMN_QTY]

        const date_fields = row[COLUMN_DATE].match(/^([0123]?\d)-([01]?\d)-(([23]\d)?\d\d)$/)
        if (!date_fields) {
            return {
                error: `Field ${COLUMN_DATE} (expected delivery date) is not a valid date, expected (d)d-(m)m-(yy)yy`,
            }
        }
        const delivery_date = `${to4digits(date_fields[3])}-${to2digits(date_fields[2])}-${to2digits(date_fields[1])}`

        return {
            ok: {
                purchase_order_number: row[COLUMN_PO],
                sku: row[COLUMN_SKU],
                ean: row[COLUMN_EAN],
                quantity,
                delivery_date,
            },
        }
    }

    /**
     * @param {array} data - row data: an array of rows, each an array of columns
     * @param {string|void} - if not valid, returns string with reason.
     *
     * Note that the original data will be replaced with 'sanitized' data (e.g. dates formatted, etc.)
     */
    validateData(data) {
        for (const index in data) {
            const result = this.validateRow(data[index])
            if (result.error) {
                return `Line ${+index + 1}: ${result.error}`
            } else {
                data[index] = result.ok
            }
        }
        return
    }

    getReaderOnLoad(input) {
        return event => {
            const csvString = event.target.result
                .replace(/\r/g, '')
                .replace(/"/g, '')

            const csvResult = parseCsv(csvString, {
                header: false,
                skipEmptyLines: 'greedy',
            })

            if (csvResult.errors?.length) {
                this.setState({
                    file_data: [],
                    error: csvResult.errors.join('; '),
                })
                return
            }

            const invalidReason = this.validateData(csvResult.data)
            if (invalidReason) {
                this.setState({
                    file_data: [],
                    error: invalidReason,
                })
                return
            }

            this.setState({
                file_data: csvResult.data,
                error: '',
            })
            document.body.removeChild(input)
        }
    }

    handleUploadCsvClick() {
        const input = document.createElement('input')
        const reader = new FileReader()
        reader.onload = this.getReaderOnLoad(input)
        input.setAttribute('type', 'file')
        input.style.display = 'none'
        input.onchange = () => {
            const file = input.files[0]
            if (file && !file.name.endsWith('.csv')) {
                this.setState({
                    file_name: '',
                    file_data: [],
                    error: 'Only csv is supported as file format',
                }, () => {
                    document.body.removeChild(input)
                })
            } else {
                this.setState({
                    file_name: file.name,
                    file_data: [],
                    error: null,
                }, () => {
                    reader.readAsText(file)
                })
            }
        }
        document.body.appendChild(input)
        input.click()
    }

    handleDownloadCsvTemplateClick() {
        const anchor = document.createElement('a')
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('download', 'example_upload.csv')
        anchor.setAttribute('href', `data:text/csv;charset=utf-8,${example_csv_data}`)
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }

    getImportDialogContents() {
        return <>
            <Button
                key="download"
                style={style.fileButton}
                variant="contained"
                onClick={this.handleDownloadCsvTemplateClick}
            >
                Download CSV example
            </Button>
            <Button
                key="upload"
                variant="contained"
                style={style.fileButton}
                color="primary"
                onClick={this.handleUploadCsvClick}
            >
                Upload CSV
            </Button>
            <span key="file_name">{this.state.file_name}</span>
            <p style={style.formatHint} key="double_quote_hint">
                <b>Hint:</b> The CSV file should have no header line.
                <br/>The field separator should be comma or semicolon.
                <br/>Fields containing the separator character should be enclosed in double quotes.
            </p>
            <div key="instructions">
                Fields, in this order:
                <ul style={{ paddingLeft: 20 }}>
                    <li>
                        Purchase order number (mandatory)
                    </li>
                    <li>
                        SKU (optional if EAN is provided)
                    </li>
                    <li>
                        EAN (optional if SKU is provided, but EAN has priority)
                    </li>
                    <li>
                        Quantity to postpone (optional: if blank, then <i>all</i> lines with that item
                        are postponed)
                    </li>
                    <li>
                        New expected delivery date (mandatory; format dd-mm-yyyy)
                    </li>
                </ul>
                <span style={{ color: Colors.red800, fontWeight: 'bold' }}>
                    {this.state.error}
                </span>
            </div>
        </>
    }

    showCsvResults() {
        return <div style={{ marginTop: 30 }}>
            {this.props.csvResult.successResults?.length > 0 &&
                <span style={{ color: Colors.secondaryColor80, fontWeight: 'bold' }}>
                    {this.props.csvResult.successResults.length} lines were successfully imported.
                </span>}

            {this.props.csvResult.failureResults?.length > 0 && <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Failure reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.csvResult.failureResults.map((row, index) => {
                        return <TableRow key={index}>
                            <TableCell>{row.line.sku}</TableCell>
                            <TableCell>{row.line.ean}</TableCell>
                            <TableCell>{row.line.quantity}</TableCell>
                            <TableCell>{row.failureReason}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>}
        </div>
    }

    cannotSubmit() {
        return this.state.file_data?.length < 1
    }

    render() {
        const refreshIndicator = (
            <CircularProgress
                size={30}
            />
        )

        return (
            <Dialog
                onRequestClose={this.props.onClose}
                open={this.props.open}
            >
                <DialogTitle>Postpone purchase orders</DialogTitle>
                <DialogContent>
                    {this.getImportDialogContents()}
                    {this.props.csvResult && this.showCsvResults()}
                </DialogContent>
                <DialogActions>
                    {this.props.isFetchingCsvResult && refreshIndicator}
                    <Button
                        key="import_dialog.close"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        key="import_dialog.submit"
                        variant="outlined"
                        disabled={this.cannotSubmit()}
                        onClick={this.handleSubmit}
                        color="secondary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PurchaseOrderPostponeViaCsvDialog.propTypes = {
    closeOnSubmit: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    csvResult: PropTypes.object,
    isFetchingCsvResult: PropTypes.bool,
}

PurchaseOrderPostponeViaCsvDialog.defaultProps = {
    closeOnSubmit: false,
}

export default PurchaseOrderPostponeViaCsvDialog
