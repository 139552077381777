import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { downloadBase64AsPdf, downloadBase64AsPng } from '../../../utils/download'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/Info'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import NearMeIcon from '@material-ui/icons/NearMe'
import TimerIcon from '@material-ui/icons/Timer'
import RefreshIcon from '@material-ui/icons/Refresh'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import WarningIcon from '@material-ui/icons/Warning'

import Colors from './../../../styles/colors'

import PostponeDialog from './../PostponeDialog'
import CancelDialog from './../CancelDialog'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPackingSlip } from './../../../../commands/sockets/packing_slips'
import { getLabel } from './../../../../actions/labels'
import { markTrackingCodeAsSent, postponeShipment, markShipmentAsDelivered } from './../../../../actions/shipments'
import { submitCancellation, handleCancellation } from './../../../../actions/cancellations'

const style = {
    container: {
        width: '73%',
        float: 'right',
        paddingTop: 30,
    },
    avatar: {
        float: 'left',
        marginRight: 8,
    },
    card: {
        marginBottom: 15,
    },
    title: {
        height: 30, padding: 6,
    },
    list: {
        width: '100%',
        padding: '0 12px',
    },
    expansionPanel: {
        margin: 0,
        boxShadow: 'none',
    },
    expansionPanelDetails: {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        padding: '0',
    },
    cardActions: {
        paddingLeft: 12,
    },
    cardAction: {
        textTransform: 'none',
        marginRight: 30,
        paddingLeft: 8,
        paddingRight: 8,
    },
}

const statusIcons = {
    'cancellation requested': {
        color: Colors.amber800,
        icon: WarningIcon,
    },
    cancelled: {
        color: Colors.red600,
        icon: CloseIcon,
    },
    requested: {
        color: Colors.brown400,
        icon: TimerIcon,
    },
    pending: {
        color: Colors.blueGrey400,
        icon: RefreshIcon,
    },
    info_received: {
        color: Colors.grey800,
        icon: InfoIcon,
    },
    pre_transit: {
        color: Colors.tertiaryColor100,
        icon: TurnedInIcon,
    },
    in_transit: {
        color: Colors.primaryColor100,
        icon: LocalShippingIcon,
    },
    out_for_delivery: {
        color: Colors.secondaryColor100,
        icon: NearMeIcon,
    },
    delivered: {
        color: Colors.green600,
        icon: HomeIcon,
    },
    attempt_fail: {
        color: Colors.purple400,
        icon: FlashOnIcon,
    },
    exception: {
        color: Colors.red700,
        icon: ErrorOutlineIcon,
    },
}

const NOT_FULFILLED_STATUSES = ['requested', 'pending', 'info_received']

class Shipments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            downloadedShipment: {},
            postponeDialogOpen: false,
            postponeShipment: {},
            cancelDialogOpen: false,
            cancelShipment: {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.downloadedShipment && prevState.downloadedShipment._id) {
            if (nextProps.packing_slip.shipment_id === prevState.downloadedShipment._id) {
                return Shipments.download(nextProps.packing_slip, prevState.downloadedShipment, 'pdf')
            } else if (nextProps.label.shipment_id === prevState.downloadedShipment._id) {
                return Shipments.download(nextProps.label,
                    prevState.downloadedShipment, nextProps.labelAsPdf ? 'pdf' : 'png')
            }
        }
        return prevState
    }

    getTitle() {
        const length = this.props.shipments.length || 0
        if (length === 0) {
            return null
        }
        return (
            <Typography style={style.title}>
                Shipments ({length})
            </Typography>
        )
    }

    handlePostponeClick(shipment) {
        this.setState({
            postponeDialogOpen: true,
            postponeShipment: shipment,
        })
    }

    handleCancelClick(shipment) {
        this.setState({
            cancelDialogOpen: true,
            cancelShipment: shipment,
        })
    }

    handleClosePostponeDialog() {
        this.setState({
            postponeDialogOpen: false,
            postponeShipment: {},
        })
    }

    handleCloseCancelDialog() {
        this.setState({
            cancelDialogOpen: false,
            cancelShipment: {},
        })
    }

    handlePostponeSubmit(shipment, postpone_state) {
        this.handleClosePostponeDialog()
        const data = {
            order_id: shipment.order_id,
            source: shipment.source,
            reason: postpone_state.reason,
            estimated_for: postpone_state.estimatedFor,
            shipment_id: shipment._id,
            supplier_id: shipment.supplier.mage_id,
        }
        const onlyOneItem = shipment.line_items.filter(li => {
            return li.status !== 'cancelled' && li.status !== 'postponed'
        }).length === 1
        const fullQuantitySelected = shipment.line_items.find(li => {
            return li.product.sku === postpone_state.lineItem.product.sku
                && +li.quantity === +postpone_state.quantity
                && li.status !== 'cancelled'
                && li.status !== 'postponed'
        })
        if (postpone_state.lineItem.product.name !== 'All' &&
            (!onlyOneItem || (onlyOneItem && !fullQuantitySelected))) {
            data.line_item = {
                product: {
                    sku: postpone_state.lineItem.product.sku,
                    supplier: {
                        mage_id: postpone_state.lineItem.product.supplier.mage_id,
                    },
                },
                quantity: postpone_state.quantity,
            }
        }
        this.props.postponeShipment(data)
    }

    handleCancelSubmit(shipment, cancellation_state) {
        this.handleCloseCancelDialog()
        const data = {
            note: cancellation_state.reason,
            author: 'supplier',
            source: shipment.source,
            shipment_id: shipment._id,
            supplier_id: shipment.supplier.mage_id,
        }
        if (cancellation_state.lineItem.product.name !== 'All') {
            data.line_item = {
                product: {
                    sku: cancellation_state.lineItem.product.sku,
                },
                quantity: cancellation_state.quantity,
            }
        }
        this.props.submitCancellation(data)
    }

    handleCancellation(choice, shipment, pending_cancellations) {
        if (pending_cancellations.length > 0 && (choice === 'reject' || choice === 'accept')) {
            pending_cancellations.forEach(can => {
                this.props.handleCancellation(choice, {
                    supplier_id: shipment.supplier.mage_id,
                    source: shipment.source,
                    cancellation_id: can._id,
                })
            })
        }
    }

    getCancellationForm(shipment, pending_cancellations = []) {
        const cancellationWithoutItem = pending_cancellations.find(can => !can.line_item)
        const actions = (
            <CardActions style={{ marginLeft: 4, paddingTop: 0, ...style.cardActions }}>
                <Tooltip title="Accept this cancellation">
                    <Button
                        style={{ backgroundColor: Colors.green800, color: '#fff', ...style.cardAction }}
                        onClick={() => { this.handleCancellation('accept', shipment, pending_cancellations) }}
                    >
                        Accept
                    </Button>
                </Tooltip>
                <Tooltip title="Reject this cancellation">
                    <Button
                        disabled={this.props.isUpdated}
                        onClick={() => { this.handleCancellation('reject', shipment, pending_cancellations) }}
                        style={{ backgroundColor: Colors.red100, color: '#fff', ...style.cardAction }}
                    >
                        Reject
                    </Button>
                </Tooltip>
            </CardActions>
        )

        if (cancellationWithoutItem) {
            return [
                <CardContent key="pending_cancellation">There is a pending cancellation for this shipment</CardContent>,
                actions,
            ]
        } else {
            const items = pending_cancellations.map(can => {
                const item = shipment.line_items.find(li => li.product.sku === can.line_item.product.sku)
                can.line_item.product.name = item.product.name
                return can.line_item
            })
            return [
                <CardContent key="pending_cancellation_items">
                    There is a pending cancellation for the following item(s)
                    <ul style={{ paddingLeft: 16 }}>
                        {
                            items.map((item, index) => (
                                <li key={`${item.product.sku}.${index}`}>
                                    <b>Name</b>: {item.product.name}<br />
                                    <b>SKU</b>: {item.product.sku}<br />
                                    <b>Quantity</b>: {item.quantity}
                                </li>
                            ))
                        }
                    </ul>
                </CardContent>,
                actions,
            ]
        }
    }

    handleShipmentSent(shipment) {
        this.props.markTrackingCodeAsSent(shipment, this.props.email, shipment.supplier.mage_id)
    }

    handleDownloadPackingSlipClick(shipment_id) {
        const shipment = this.props.shipments.find(s => s._id === shipment_id) || {}
        this.setState({
            downloadedShipment: shipment,
        }, () => {
            this.props.getPackingSlip(shipment_id, shipment.source)
        })
    }

    handleDownloadLabelClick(shipment_id) {
        const shipment = this.props.shipments.find(s => s._id === shipment_id) || {}
        this.setState({
            downloadedShipment: shipment,
        }, () => {
            this.props.getLabel(shipment_id, this.props.labelAsPdf)
        })
    }

    static download(data, downloadedShipment, type) {
        if (!data.error) {
            if (type === 'pdf') {
                downloadBase64AsPdf(data.blob, downloadedShipment.shipping_label.label_reference)
            } else {
                downloadBase64AsPng(data.blob, downloadedShipment.shipping_label.label_reference)
            }
        }
        return {
            downloadedShipment: {},
        }
    }

    getTrackAndTraceUrl(shipment) {
        const { carrier_slug, tracking_code, tracking_postal_code } = shipment.shipping_label
        const { country, postal_code } = shipment.shipping_address
        const standard = `https://jouw.postnl.nl/track-and-trace/${tracking_code}-${country}-${postal_code}`
        switch (`${carrier_slug}:${country.toLowerCase()}`) {
            case 'postnl-3s:be':
                return `https://jouw.postnl.be/track-and-trace/${tracking_code}-${country}-${postal_code}`
            case 'postnl-3s:de':
                return 'https://www.internationalparceltracking.com/Main.aspx#/track/' +
                    `${tracking_code}/${country}/${tracking_postal_code}`
            default: return standard
        }
    }

    getTrackingAction(shipment) {
        const tracking_code = shipment.shipping_label.tracking_code
        if (tracking_code) {
            const url = this.getTrackAndTraceUrl(shipment)
            return (
                <a style={{ float: 'right' }} href={url} target="_blank" rel="noopener noreferrer">{tracking_code}</a>
            )
        } else {
            return null
        }
    }

    getPostponeAction(shipment) {
        return (
            <Tooltip title="Postpone item or shipment">
                <Button
                    onClick={() => {
                        this.handlePostponeClick(shipment)
                    }}
                    style={{ color: Colors.yellow900, ...style.cardAction }}
                >
                    <DateRangeIcon /> Postpone
                </Button>
            </Tooltip>
        )
    }

    getAllActions(shipment) {
        return (
            <CardActions style={style.cardActions}>
                <Tooltip title="Download packing slip">
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleDownloadPackingSlipClick(shipment._id)
                        }}
                        style={style.cardAction}
                    >
                        <CloudDownloadIcon />&nbsp;Download
                    </Button>
                </Tooltip>
                {this.getPostponeAction(shipment)}
                <Tooltip title="Cancel shipment or item">
                    <Button
                        onClick={() => {
                            this.handleCancelClick(shipment)
                        }}
                        style={{ color: Colors.red100, ...style.cardAction }}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Tooltip>
                <Tooltip title="Mark shipment as sent">
                    <Button
                        color="secondary"
                        onClick={() => {
                            this.handleShipmentSent(shipment)
                        }}
                        style={style.cardAction}
                    >
                        <LocalShippingIcon />&nbsp;Mark as sent
                    </Button>
                </Tooltip>
                {this.getTrackingAction(shipment)}
                <div style={style.destination}>
                    Destination: <strong>{shipment.shipping_address.type}</strong>
                </div>
            </CardActions>
        )
    }

    getDownloadPackingSlipAction(shipment) {
        return (
            <Tooltip title="Download packing slip">
                <Button
                    color="primary"
                    onClick={() => {
                        this.handleDownloadPackingSlipClick(shipment._id)
                    }}
                    style={style.cardAction}
                >
                    <CloudDownloadIcon />&nbsp;Download
                </Button>
            </Tooltip>
        )
    }

    getDownloadLabelAction(shipment) {
        return (
            <Tooltip title="Download shipping label">
                <Button
                    color="primary"
                    onClick={() => {
                        this.handleDownloadLabelClick(shipment._id)
                    }}
                    style={style.cardAction}
                >
                    <CloudDownloadIcon />&nbsp;Download
                </Button>
            </Tooltip>
        )
    }

    getMarkAsDeliveredAction(shipment) {
        return shipment.status === 'delivered' ? null : (
            <Tooltip key="mark-as-delivered" title="Mark shipment as delivered">
                <Button
                    onClick={() => {
                        this.props.markShipmentAsDelivered(shipment, this.props.email)
                    }}
                    style={{ color: Colors.secondaryColor100, ...style.cardAction }}
                >
                    <LocalShippingIcon />&nbsp;Mark as delivered
                </Button>
            </Tooltip>
        )
    }

    getFooter(shipment) {
        if (!this.props.actions) {
            return (
                <CardActions style={style.cardActions}>
                    {this.getTrackingAction(shipment)}
                </CardActions>
            )
        }
        if (this.props.type === 'rtv' && shipment.shipping_label.label_reference) {
            return (
                <CardActions style={style.cardActions}>
                    {this.getDownloadLabelAction(shipment)}
                    {this.getMarkAsDeliveredAction(shipment)}
                    {this.getTrackingAction(shipment)}
                </CardActions>
            )
        } else if (shipment.status === 'cancellation requested' &&
            shipment.cancellations.filter(c => c.status === 'pending').length > 0) {
            return this.getCancellationForm(shipment, this.props.cancellations.filter(c => {
                return c.shipment_id === shipment._id && c.status === 'pending'
            }))
        } else if (this.props.type !== 'rtv' && NOT_FULFILLED_STATUSES.includes(shipment.status)) {
            return this.getAllActions(shipment)
        } else {
            return (
                <CardActions style={style.cardActions}>
                    {this.getDownloadPackingSlipAction(shipment)}
                    {this.getPostponeAction(shipment)}
                    {this.getTrackingAction(shipment)}
                </CardActions>
            )
        }
    }

    renderShipmentItem(line_item, index) {
        let backgroundColor = ''
        let additionalText = null
        if (line_item.status === 'cancelled') {
            backgroundColor = Colors.red100
            additionalText = (
                <i>(This item is cancelled for this shipment and should not be shipped)</i>
            )
        } else if (line_item.status === 'postponed') {
            backgroundColor = Colors.yellow100
            additionalText = (
                <i>
                    (This item has been postponed into an other shipment and should no
                    longer be shipped with this shipment)
                </i>
            )
        }
        return (
            <ListItem
                key={`${line_item.product.sku}.${index}`}
                style={backgroundColor ? { backgroundColor } : null}
                dense={true}
                divider={index !== this.length - 1}
            >
                {!line_item.is_promo ? null : (
                    <ListItemIcon>
                        <Tooltip title="Giveaway item">
                            <MoneyOffIcon color="secondary" />
                        </Tooltip>
                    </ListItemIcon>
                )}
                <ListItemText
                    primary={`${line_item.product.name} ${additionalText || ''}`}
                    secondary={
                        <span>
                            SKU: {line_item.product.sku} <br />
                            Quantity {line_item.quantity}
                        </span>
                    }
                />
            </ListItem>
        )
    }

    render() {
        const title = this.props.showTitle ? this.getTitle() : null
        const listItems = this.props.shipments.map(shipment => {
            const footer = this.getFooter(shipment)
            const statusIcon = statusIcons[shipment.status]
            const primaryText = shipment.status !== 'delivered'
                ? (
                    <span>
                        <b>Expected delivery date:</b> {moment(shipment.estimated_for).format('DD-MM-YYYY')}
                    </span>
                )
                : <span><b>Delivered at: </b>{moment(shipment.delivered_at).format('DD-MM-YYYY HH:mm')}</span>
            const Icon = statusIcon.icon
            return (
                <Card key={shipment._id} style={style.card}>
                    <ExpansionPanel defaultExpanded={true} style={style.expansionPanel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`shipment-content-${shipment._id}`}
                            id={`shipment-header-${shipment._id}`}
                        >
                            <Avatar style={{ ...style.avatar, backgroundColor: statusIcon.color }}>
                                <Icon />
                            </Avatar>
                            <span>
                                {primaryText} <br />
                                <b>Current status: </b> {shipment.status.replace('_', ' ')}
                            </span>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={style.expansionPanelDetails}>
                            <List style={style.list}>
                                {shipment.line_items.map(this.renderShipmentItem.bind(shipment.line_items))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {footer}
                </Card>
            )
        })

        const postponeDialog = (
            <PostponeDialog
                onRequestClose={this.handleClosePostponeDialog.bind(this)}
                onClosePostponeDialog={this.handleClosePostponeDialog.bind(this)}
                onPostponeSubmit={this.handlePostponeSubmit.bind(this)}
                shipment={this.state.postponeShipment}
                open={this.state.postponeDialogOpen}
            />
        )

        const cancelDialog = (
            <CancelDialog
                onRequestClose={this.handleCloseCancelDialog.bind(this)}
                onCloseCancelDialog={this.handleCloseCancelDialog.bind(this)}
                onCancelSubmit={this.handleCancelSubmit.bind(this)}
                shipment={this.state.cancelShipment}
                open={this.state.cancelDialogOpen}
            />
        )

        return (
            <div style={this.props.style || style.container}>
                {postponeDialog}
                {cancelDialog}
                {title}
                {listItems}
            </div>
        )
    }
}

Shipments.propTypes = {
    style: PropTypes.object,
    type: PropTypes.string,
    email: PropTypes.string,
    actions: PropTypes.bool,
    date: PropTypes.bool,
    label: PropTypes.object,
    typed_order: PropTypes.object,
    isUpdated: PropTypes.bool,
    shipments: PropTypes.array,
    packing_slip: PropTypes.object,
    cancellations: PropTypes.array,
    showTitle: PropTypes.bool,
    getLabel: PropTypes.func,
    getPackingSlip: PropTypes.func,
    markTrackingCodeAsSent: PropTypes.func,
    postponeShipment: PropTypes.func,
    submitCancellation: PropTypes.func,
    handleCancellation: PropTypes.func,
    markShipmentAsDelivered: PropTypes.func,
    labelAsPdf: PropTypes.bool,
}

Shipments.defaultProps = {
    isUpdated: false,
    actions: true,
    date: true,
    shipments: [],
    cancellations: [],
    showTitle: true,
    labelAsPdf: false,
}

export default connect(({ packing_slip, label }) => {
    return { packing_slip, label }
}, dispatch => {
    return bindActionCreators({
        getLabel,
        getPackingSlip,
        markTrackingCodeAsSent,
        postponeShipment,
        submitCancellation,
        markShipmentAsDelivered,
        handleCancellation,
    }, dispatch)
})(Shipments)
