import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core'

class DeliveryOrderLineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Fulfilled</TableCell>
                        <TableCell>Checked out</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.line_items.map((line_item, index) => (
                        <TableRow key={`${line_item.sku}.${index}`}>
                            <TableCell>{line_item.name}</TableCell>
                            <TableCell>{line_item.sku}</TableCell>
                            <TableCell>{line_item.ean}</TableCell>
                            <TableCell>{line_item.quantity}</TableCell>
                            <TableCell>{line_item.fulfilled_quantity}</TableCell>
                            <TableCell>{line_item.checked_out_quantity}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }
}

DeliveryOrderLineItems.contextTypes = {}
DeliveryOrderLineItems.propTypes = {
    line_items: PropTypes.array,
}
DeliveryOrderLineItems.defaultProps = {
    line_items: [],
}

export default DeliveryOrderLineItems
