import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import reactUpdate from 'react-addons-update'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import AddIcon from '@material-ui/icons/Add'

import { searchRmas, createRma, getRmaByTrackingCode } from './../../../../commands/sockets/rmas'
import { searchSalesOrders } from './../../../../actions/sales_orders'

import RmaTable from './RmaTable'
import RmaFilters from './RmaFilters'
import AddRmaDialog from './AddRmaDialog'

const FIELDS = 'ID,customer,created_at,status,order_id,payment_info.method'
const STATUSES = ['open', 'to be settled', 'closed']
const PAYMENT_METHODS = [{
    method: 'Achteraf betalen (0,99)',
    code: 'billink',
}, {
    method: 'iDeal',
    code: 'msp_ideal',
}, {
    method: 'Purchase order',
    code: 'purchaseorder',
}, {
    method: 'Mistercash',
    code: 'msp_mistercash',
}, {
    method: 'Mastercard',
    code: 'msp_mastercard',
}, {
    method: 'Bank transfer',
    code: 'msp_banktransfer',
}, {
    method: 'PayPal',
    code: 'msp_paypal',
}, {
    method: 'Visa',
    code: 'msp_visa',
}, {
    method: 'EffectConnect payment',
    code: 'effectconnect_payment',
}]

const STORAGE_KEY = 'rma-filters'

const style = {
    container: {
        width: '100%',
        position: 'relative',
    },
    addButton: {
        position: 'fixed',
        zIndex: 5,
        left: 20,
        bottom: 20,
    },
}

class RmaList extends Component {
    constructor(props) {
        super(props)
        const filters = this.getFilters()
        this.state = {
            isUpdated: false,
            addOpen: false,
            filters: {
                criteria: filters.criteria || '',
                status: filters.status || 'all',
                payment_code: filters.payment_code || 'all',
                page: filters.page || 1,
                limit: filters.limit || 20,
            },
        }

        autoBind(this)
    }

    componentDidMount() {
        this.mounted = true
        this.scan = ''
        this.scanTimer = 0
        document.addEventListener('keypress', this.handleKeyPress)
        this.searchRmas()
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress)
        this.mounted = false
    }

    getFilters() {
        try {
            return JSON.parse(localStorage.getItem(STORAGE_KEY)) ||
                this.props.filters
        } catch (e) {
            return this.props.filters
        }
    }

    storeFilters(state) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
    }

    getFilterState() {
        const filters = { ...this.state.filters }
        filters.fields = FIELDS
        if (filters.status === 'all') {
            delete filters.status
        }
        if (filters.payment_code === 'all') {
            delete filters.payment_code
        }
        return filters
    }

    searchRmas() {
        const state = this.getFilterState()
        this.storeFilters(state)
        this.props.searchRmas(state)
    }

    handleSearchSalesOrders(state) {
        this.setState({
            shouldRender: false,
        }, () => {
            this.props.searchSalesOrders(state)
        })
    }

    /* eslint-disable complexity, max-statements, no-console */
    /* Logging below is enabled in case hand-scanning issues happen */
    handleKeyPress(e) {
        if (e.target.tagName !== 'BODY') {
            console.log('handleKeyPress', 'Exiting early, because its not body')
            return false
        }
        if (!this.mounted) {
            console.log('handleKeyPress', 'Exiting early', this.mounted)
            return false
        }
        clearTimeout(this.timeout)
        if (this.scanTimer === 0) {
            console.log('handleKeyPress', 'Resetting scan timer')
            this.scanTimer = Date.now()
        }
        const is_enter = e.keyCode === 13 || e.which === 13
        const inp = String.fromCharCode(e.keyCode || e.which)
        if (this.state.readyForCheckout && /[a-zA-Z0-9]/.test(inp)) {
            this.scan += inp
        } else if (!this.state.readyForCheckout && !is_enter) {
            this.scan += inp
        }
        console.log('handleKeyPress', 'Current scan so far', this.scan)
        if (is_enter) {
            console.log('handleKeyPress', 'Is enter', this.scan, this.scanTimer, Date.now())
            const scan = this.scan
            this.scan = ''
            this.scanTimer = 0
            if (scan.length > 3) {
                console.log('handleKeyPress', 'Scannning finished, proceeding', scan)
                this.props.getRmaByTrackingCode(scan)
            }
        }
        this.timeout = setTimeout(() => {
            this.scan = ''
            this.scanTimer = 0
        }, 1000)
        return true
    }

    setSearchTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.searchRmas, 500)
    }

    setStateWithoutRender(state, timeout) {
        const newState = reactUpdate({ ...this.state }, {
            filters: state.filters,
        })
        this.setState({
            ...newState,
            shouldRender: false,
        }, timeout ? this.setSearchTimeout : this.searchRmas)
    }

    handleCriteriaChange(criteria) {
        this.setStateWithoutRender({
            filters: {
                criteria: { $set: criteria },
                page: { $set: 1 },
            },
        }, true)
    }

    handleStatusChange(e) {
        this.setStateWithoutRender({
            filters: {
                status: { $set: e.target.value },
                page: { $set: 1 },
            },
        }, false)
    }

    handlePaymentMethodChange(e) {
        this.setStateWithoutRender({
            filters: {
                payment_code: { $set: e.target.value },
                page: { $set: 1 },
            },
        }, false)
    }

    handleLimitChange(limit) {
        this.setStateWithoutRender({
            filters: { limit: { $set: limit } },
        }, false)
    }

    handlePageDecrement() {
        this.setStateWithoutRender({
            filters: { page: { $set: this.state.filters.page - 1 } },
        }, false)
    }

    handlePageIncrement() {
        this.setStateWithoutRender({
            filters: { page: { $set: this.state.filters.page + 1 } },
        }, false)
    }

    handleRmaClick(index) {
        const rma = this.props.rmas[index]
        this.context.router.push(`/returns/rma/${rma._id}`)
    }

    handleAddClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            addOpen: true,
        })
    }

    handleCloseAddRmaDialog() {
        this.setState({
            addOpen: false,
        })
    }

    render() {
        const addRmaDialog = !this.state.addOpen ? null : (
            <AddRmaDialog
                sales_orders={this.props.sales_orders}
                onClose={this.handleCloseAddRmaDialog}
                onSearchSalesOrders={this.handleSearchSalesOrders}
                onSubmit={rma => {
                    this.handleCloseAddRmaDialog()
                    this.props.createRma(rma)
                }}
            />
        )
        return (
            <div style={style.container}>
                <RmaFilters
                    onCriteriaChange={this.handleCriteriaChange}
                    onStatusChange={this.handleStatusChange}
                    onPaymentMethodChange={this.handlePaymentMethodChange}
                    filters={this.state.filters}
                    statuses={STATUSES}
                    payment_methods={PAYMENT_METHODS}
                />
                <RmaTable
                    shouldRender={this.state.shouldRender}
                    page={this.state.filters.page - 1}
                    limit={this.state.filters.limit}
                    rmas={this.props.rmas.map(rma => {
                        if (rma.status) {
                            rma.status = rma.status.replace(/_/gi, ' ')
                        }
                        return rma
                    })}
                    statuses={STATUSES}
                    pagination={this.props.pagination}
                    onRmaClick={this.handleRmaClick}
                    onLimitChange={this.handleLimitChange}
                    onPageIncrement={this.handlePageIncrement}
                    onPageDecrement={this.handlePageDecrement}
                />
                <Zoom in={true} timeout={200} unmountOnExit>
                    <Tooltip style={style.addButton} title="CREATE RMA">
                        <Fab
                            color="primary"
                            onClick={this.handleAddClick}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Zoom>
                {addRmaDialog}
            </div>
        )
    }
}

RmaList.propTypes = {
    rmas: PropTypes.array.isRequired,
    sales_orders: PropTypes.array,
    filters: PropTypes.object,
    pagination: PropTypes.object,
    searchRmas: PropTypes.func.isRequired,
    createRma: PropTypes.func.isRequired,
    getRmaByTrackingCode: PropTypes.func.isRequired,
    searchSalesOrders: PropTypes.func.isRequired,
}

RmaList.contextTypes = {
    router: PropTypes.object,
}

RmaList.defaultProps = {
    rmas: [],
    sales_orders: [],
}

export default connect(({ rmas, sales_orders, filters, pagination = {} }) => {
    return {
        rmas,
        sales_orders,
        filters: filters.rmas || {},
        pagination: pagination.rmas || { current_page: 1 },
    }
}, dispatch => {
    return bindActionCreators({ searchRmas, createRma, searchSalesOrders, getRmaByTrackingCode }, dispatch)
})(RmaList)
