import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import {
    AutoComplete,
    TextField,
} from 'material-ui'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'

import TextFieldV1 from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import moment from 'moment'

import ChannelFilter from './../../../shared/ChannelFilter'
import DestinationFilter from './../../../shared/DestinationFilter'
import InstructionFilter from './../../../shared/InstructionFilter'
import PackedFilter from './filters/PackedFilter'
import SourceFilter from './filters/SourceFilter'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { searchPurchaseOrders } from './../../../../../commands/sockets/purchase_orders'
import { searchLocations } from './../../../../../commands/sockets/locations'


const purchase_config = {
    text: 'text',
    value: 'order_id',
}

const location_config = {
    text: 'text',
    value: '_id',
}

const style = {
    field: {
        width: '100%',
    },
    datePickers: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    datePicker: {
        width: 180,
    },
    subheaderStyle: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.298039)',
        fontSize: 12,
    },
    title: {
        height: 20, padding: 0,
    },
}

const initialState = {
    purchase_search: '',
    purchase_auto_complete_focus: false,
    location_search: '',
    location_auto_complete_focus: false,
    channel: 'all',
    fulfillment_source: 'all',
    destination_type: 'all',
    instructions: 'all',
    pack: -1,
    from_date: null,
    to_date: null,
    purchase_id: null,
    location_id: null,
    limit: 50,
}

const CART_REGEX = /^[K][0-9]+$/i
const PURCHASE_REGEX = /^[0-9]+$/

class PrintPackingSlipsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = { ...initialState }

        autoBind(this)
    }

    handleChange(property, value) {
        const update = {}
        update[property] = value
        this.setState(update)
    }

    isSelectedPurchaseSearch(search) {
        return !(PURCHASE_REGEX.test(search))
    }

    isSelectedLocationSearch(search) {
        return !(CART_REGEX.test(search))
    }

    handlePurchaseAutoCompleteFocus() {
        this.setState({ purchase_auto_complete_focus: true })
    }

    handlePurchaseAutoCompleteBlur() {
        this.setState({ purchase_auto_complete_focus: false })
    }

    handlePurchaseAutoCompleteInputChange(purchase_search) {
        if (purchase_search && this.isSelectedPurchaseSearch(purchase_search)) {
            return false
        }
        this.setState({ purchase_search })
        clearTimeout(this.purchase_search_timeout)
        this.setPurchaseSearchTimeout(purchase_search)
        return true
    }

    handleLocationAutoCompleteFocus() {
        this.setState({ location_auto_complete_focus: true })
    }

    handleLocationAutoCompleteBlur() {
        this.setState({ location_auto_complete_focus: false })
    }

    handleLocationAutoCompleteInputChange(location_search) {
        if (location_search && this.isSelectedLocationSearch(location_search)) {
            return false
        }
        if (!location_search) {
            return false
        }
        this.setState({ location_search })
        clearTimeout(this.location_search_timeout)
        this.setLocationSearchTimeout(location_search)
        return true
    }

    setPurchaseSearchTimeout(search) {
        this.purchase_search_timeout = setTimeout(() => {
            this.props.searchPurchaseOrders({
                value: search,
                fields: '_id,order_id,supplier,created_at',
                page: 1,
                limit: 5,
            })
        }, 300)
    }

    setLocationSearchTimeout(search) {
        this.location_search_timeout = setTimeout(() => {
            this.props.searchLocations({
                value: `warehouse/1/${search}`,
                fields: 'location',
                page: 1,
                limit: 5,
            })
        }, 300)
    }

    handlePurchaseAutoCompleteNewRequest(chosen, index) {
        const purchase_order = this.props.purchase_orders[index]
        this.setState({
            purchase_id: purchase_order._id,
            purchase_search: chosen,
        })
    }

    handleLocationAutoCompleteNewRequest(chosen, index) {
        const location = this.props.locations[index]
        this.setState({
            location_id: location.location,
            location_search: chosen,
        })
    }

    handleSubmit() {
        const state = this.state
        this.setState({ ...initialState }, () => {
            delete state.purchase_auto_complete_focus
            if (state.fulfillment_source === 'all') {
                delete state.fulfillment_source
            }
            if (state.fulfillment_source !== 'personalisation' || state.item_type === 'all') {
                delete state.item_type
            }
            if (state.destination_type === 'all') {
                delete state.destination_type
            }
            if (state.instructions === 'all') {
                delete state.instructions
            }
            if (state.channel === 'all') {
                delete state.channel
            }
            if (state.from_date) {
                state.from_date = moment(state.from_date).toDate()
            }
            if (state.to_date) {
                state.to_date = moment(state.to_date).toDate()
            }
            this.props.onSubmit({
                ...state,
            })
        })
    }

    getInvalidCartSearchString() {
        if (this.state.location_auto_complete_focus || this.state.location_id) {
            return null
        }
        if (this.state.location_search && !this.isSelectedLocationSearch(this.state.location_search)) {
            return 'Invalid format, carts are formatted like KXXX, with X representing a number'
        }
        return null
    }

    render() {
        const orders = this.props.purchase_orders.map(p => {
            return `${p.order_id} - ${p.supplier.name} - ${moment(p.created_at).format('DD-MM-YY')}`
        })
        const locations = this.props.locations.map(l => {
            return l.location
        })
        const invalidPurchaseSelection = this.state.purchase_search
            && !this.isSelectedPurchaseSearch(this.state.purchase_search)

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>Print packing slips</DialogTitle>
                <DialogContent>
                    <ChannelFilter
                        style={style.field}
                        onChange={e => { this.handleChange('channel', e.target.value) }}
                        channel={this.state.channel}
                    />
                    <PackedFilter
                        style={style.field}
                        onChange={(e, index, value) => { this.handleChange('pack', +value) }}
                        value={this.state.pack}
                    />
                    <SourceFilter
                        style={style.field}
                        sources={['warehouse', 'crossdock', 'personalisation']}
                        onChange={(e, index, value) => { this.handleChange('fulfillment_source', value) }}
                        value={this.state.fulfillment_source}
                    />
                    <InstructionFilter
                        style={style.field}
                        types={['with', 'without']}
                        onChange={(e, index, value) => { this.handleChange('instructions', value) }}
                        value={this.state.instructions}
                    />
                    <DestinationFilter
                        style={style.field}
                        destinations={['customer', 'manufacturer']}
                        onChange={e => { this.handleChange('destination_type', e.target.value) }}
                        value={this.state.destination_type}
                    />
                    <InputLabel>Delivery date range</InputLabel>
                    <FormControl variant="outlined" style={style.datePickers}>
                        <TextFieldV1
                            style={style.datePicker}
                            textFieldStyle={{ cursor: 'pointer', ...style.datePicker }}
                            required={false}
                            label="Select start"
                            type="date"
                            inputProps={{ max: this.state.to_date || null }}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.from_date}
                            onChange={({ target }) => this.handleChange('from_date', target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextFieldV1
                            style={style.datePicker}
                            textFieldStyle={{ cursor: 'pointer', ...style.datePicker }}
                            required={false}
                            label="Select end"
                            type="date"
                            inputProps={{ min: this.state.from_date || null }}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.to_date}
                            onChange={({ target }) => this.handleChange('to_date', target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>
                    <AutoComplete
                        dataSource={orders}
                        dataSourceConfig={purchase_config}
                        searchText={this.state.purchase_search}
                        fullWidth={true}
                        onFocus={this.handlePurchaseAutoCompleteFocus}
                        onBlur={this.handlePurchaseAutoCompleteBlur}
                        errorText={invalidPurchaseSelection && !this.state.purchase_auto_complete_focus
                            ? 'Select a result or leave blank'
                            : null}
                        onUpdateInput={this.handlePurchaseAutoCompleteInputChange}
                        onNewRequest={this.handlePurchaseAutoCompleteNewRequest}
                        filter={AutoComplete.caseInsensitiveFilter}
                        floatingLabelText="Search for purchase order by number"
                    />
                    <AutoComplete
                        dataSource={locations}
                        dataSourceConfig={location_config}
                        searchText={this.state.location_search}
                        fullWidth={true}
                        onFocus={this.handleLocationAutoCompleteFocus}
                        onBlur={this.handleLocationAutoCompleteBlur}
                        errorText={this.getInvalidCartSearchString()}
                        onUpdateInput={this.handleLocationAutoCompleteInputChange}
                        onNewRequest={this.handleLocationAutoCompleteNewRequest}
                        filter={AutoComplete.caseInsensitiveFilter}
                        floatingLabelText="Search by cart (like K006)"
                    />
                    <TextField
                        style={style.field}
                        type="number"
                        min={1}
                        max={100}
                        value={this.state.limit}
                        onChange={({ target }) => { this.handleChange('limit', +target.value) }}
                        floatingLabelText="Max packing slips (max 100)"
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        disabled={!!invalidPurchaseSelection}
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PrintPackingSlipsDialog.propTypes = {
    locations: PropTypes.array,
    purchase_orders: PropTypes.array,
    open: PropTypes.bool.isRequired,
    couriers: PropTypes.array,
    shipment: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    searchPurchaseOrders: PropTypes.func,
    searchLocations: PropTypes.func,
}

PrintPackingSlipsDialog.defaultProps = {
    purchase_orders: [],
    locations: [],
    open: false,
    shipment: {
        shipping_label: {},
        shipping_address: {},
    },
}

export default connect(({ purchase_orders, locations }) => {
    return { purchase_orders, locations }
}, dispatch => {
    return bindActionCreators({ searchPurchaseOrders, searchLocations }, dispatch)
})(PrintPackingSlipsDialog)
