export const ProductionOrderStatus = Object.freeze({
    // Order is open
    OPEN: 'open',

    // The order is ready for production
    READY_FOR_PRODUCTION: 'ready_for_production',

    // The order is in production
    IN_PRODUCTION: 'in_production',

    // The production is finished
    PRODUCTION_READY: 'production_ready',

    // The production is cancelled
    PRODUCTION_CANCELLED: 'production_cancelled',
})

export const ProductionOrderLocation = Object.freeze({
    // Order is produced internal
    INTERNAL: 'internal',

    // The order is produced external
    EXTERNAL: 'external',
})


export const ProductionOrderPress = Object.freeze({
    // Order press is unknown, but from dressme
    DRESSME: 'DressMe',

    AIRPRESS: 'Airpress',

    SCHULZE: 'Schulze',
})
