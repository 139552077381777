import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'

import PersonalisationItemsReadySwitch from './PersonalisationItemsReadySwitch'
import PersonalisationPressSelect from './PersonalisationPressSelect'
import PersonalisationPressWorkerSelect from './PersonalisationPressWorkerSelect'

import { ProductionOrderStatus, ProductionOrderLocation } from '../../../types/production/production-orders'

const styles = {
    formGroup: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
    },
    formItem: {
        marginRight: 20,
    },
    // makes text fields correctly align with select fields that contain Chip components as render value.
    formControlTextField: {
        paddingTop: 3,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

class PersonalisationOrderFilters extends Component {

    handleTextFieldChange(property) {
        return e => {
            return this.props.onTextFieldChange && this.props.onTextFieldChange(e.target.value, property)
        }
    }

    handleSelectChange(property) {
        return e => {
            return this.props.onSelectChange && this.props.onSelectChange(e.target.value, property)
        }
    }

    handleSwitchChange(property) {
        return e => {
            return this.props.onSwitchChange && this.props.onSwitchChange(e.target.checked, property)
        }
    }

    render() {
        const filters = this.props.filters
        return (
            <FormGroup row style={styles.formGroup}>
                <FormControl style={{ ...styles.formItem, ...styles.formControlTextField }} margin="dense">
                    <TextField
                        label="Production Order"
                        id="production-order-id-filter"
                        value={filters.production_order_id}
                        onChange={this.handleTextFieldChange('production_order_id')}
                    />
                </FormControl>
                <FormControl style={{ ...styles.formItem, ...styles.formControlTextField }} margin="dense">
                    <TextField
                        label="Email"
                        id="email-filter"
                        value={filters.email}
                        onChange={this.handleTextFieldChange('email')}
                    />
                </FormControl>
                <FormControl style={styles.formItem} margin="dense">
                    <InputLabel id="status-filter-label">Statuses</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        id="status-filter"
                        multiple
                        value={filters.statuses}
                        onChange={this.handleSelectChange('statuses')}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip size="small" key={value} label={value.replace(/_/g, ' ')} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.values(ProductionOrderStatus).map(value => (
                            <MenuItem key={value} value={value} style={styles.menuItem}>
                                {value.replace(/_/g, ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={styles.formItem} margin="dense">
                    <InputLabel id="location-filter-label">Location</InputLabel>
                    <Select
                        labelId="location-filter-label"
                        id="location-filter"
                        multiple
                        value={filters.locations}
                        onChange={this.handleSelectChange('locations')}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip size="small" key={value} label={value.replace(/_/g, ' ')} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Object.values(ProductionOrderLocation).map(value => (
                            <MenuItem key={value} value={value} style={styles.menuItem}>
                                {value.replace(/_/g, ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <PersonalisationPressSelect
                    margin="dense"
                    style={styles.formItem}
                    value={filters.used_press}
                    allowAll={false}
                    onChange={this.handleSelectChange('used_press')}
                />
                <PersonalisationPressWorkerSelect
                    margin="dense"
                    style={styles.formItem}
                    value={filters.press_worker}
                    allowAll={false}
                    onChange={this.handleSelectChange('press_worker')}
                />
                <FormControl style={styles.formItem} margin="dense">
                    <PersonalisationItemsReadySwitch
                        value={!!filters.items_ready}
                        onChange={this.handleSwitchChange('items_ready')}
                    />
                </FormControl>
            </FormGroup>
        )
    }
}

PersonalisationOrderFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onTextFieldChange: PropTypes.func.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    onSwitchChange: PropTypes.func.isRequired,
}

export default PersonalisationOrderFilters
