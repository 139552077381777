import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import _ from 'lodash'

function ProductBulkUpdateDelta(props) {
    return (
        <div>
            <Typography variant="h6" component="h6">
                Update delta ({props.delta.length})
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head">Name</TableCell>
                        <TableCell variant="head">Brand</TableCell>
                        <TableCell variant="head">Supplier</TableCell>
                        <TableCell variant="head">SKU</TableCell>
                        <TableCell variant="head">Source</TableCell>
                        <TableCell variant="head">Lead time</TableCell>
                        <TableCell variant="head">Purchase Scale Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.delta.map((item, index) => {
                            let supplier = _.cloneDeep(item.supplier)
                            if (supplier !== null && typeof supplier === 'object' && supplier.new) {
                                supplier.new = supplier.new.name
                                if (supplier.old) {
                                    supplier.old = supplier.old.name
                                }
                            } else {
                                supplier = supplier ? supplier.name : 'Unknown'
                            }
                            item = {
                                name: item.variant.name,
                                brand: item.brand,
                                supplier,
                                supplier_sku: item.variant.supplier_sku,
                                source: item.variant.fulfillment_source,
                                lead_time: item.variant['lead_times.vendor'] || '',
                                purchasing_scale_quantity: item.variant.purchasing_scale_quantity || '',
                            }
                            const keys = Object.keys(item)
                            return (
                                <TableRow key={`${item.supplier_sku}.${index}`}>
                                    {
                                        keys.map(key => {
                                            const value = item[key]
                                            if (value !== null && typeof value === 'object' && value.new) {
                                                const old = !value.old ? null : (
                                                    <span
                                                        style={{ color: 'red', textDecoration: 'line-through' }}
                                                    >
                                                        {value.old}
                                                    </span>
                                                )

                                                return (
                                                    <TableCell key={`${item.supplier_sku}.${key}`}>
                                                        {old}
                                                        {old ? <br /> : null}
                                                        <span style={{ color: 'green' }}>{value.new}</span>
                                                    </TableCell>
                                                )
                                            }
                                            return (
                                                <TableCell key={`${item.supplier_sku}.${key}`}>
                                                    {value}
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </div>
    )
}

ProductBulkUpdateDelta.propTypes = {
    delta: PropTypes.array,
}
ProductBulkUpdateDelta.defaultProps = {
    delta: [],
}

export default ProductBulkUpdateDelta
