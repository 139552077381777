import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DebitMemo from './memos/DebitMemo'
import CreditMemo from './memos/CreditMemo'

const styles = {
    container: {
        width: '100%',
    },
}


class Memos extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    getDebitMemos(debit_memos) {
        if (debit_memos.length === 0) {
            return null
        }
        return debit_memos.map(memo => (
            <DebitMemo
                key={memo.mage_id}
                memo={memo}
            />
        ))
    }

    getCreditMemos(credit_memos) {
        if (credit_memos.length === 0) {
            return null
        }
        return credit_memos.map(memo => (
            <CreditMemo
                key={memo._id}
                memo={memo}
            />
        ))
    }

    render() {
        const debit_memos = this.getDebitMemos(this.props.debit_memos)
        const credit_memos = this.getCreditMemos(this.props.credit_memos)
        return (
            <div style={styles.container}>
                {debit_memos}
                {credit_memos}
            </div>
        )
    }
}

Memos.propTypes = {
    debit_memos: PropTypes.array,
    credit_memos: PropTypes.array,
}
Memos.defaultProps = {
    debit_memos: [],
    credit_memos: [],
}

export default Memos
