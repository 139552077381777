export function menu_items(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return []

        case 'SET_MENU_ITEMS':
            return action.menu_items

        default:
            return state
    }
}
