import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import csv from 'papaparse'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    MenuItem,
} from '@material-ui/core'

import IdentifierFieldSet from './import-vendor-stocks/IdentifierFieldSet'
import StockFieldSet from './import-vendor-stocks/StockFieldSet'

const styles = {
    textField: {
        minWidth: 240,
        marginRight: 20,
    },
    dialogPaper: {
        minWidth: 840,
        maxWidth: 840,
    },
}

class ImportVendorStocksDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifier_type: '',
            identifier_column: '',
            headers: [],
            data: [],
            in_stock_column: '',
            in_stock_column_menu_items: [],
            in_stock_column_comparison: '',
            in_stock_value_helper_text: '',
            out_stock_column: '',
            in_stock_column_value: '',
            out_stock_column_value: '',
            out_stock_column_menu_items: [],
            out_stock_column_comparison: '',
            out_stock_value_helper_text: '',
        }

        this.handleUploadCsvClick = this.handleUploadCsvClick.bind(this)
        this.handleColumnChange = this.handleStockColumnChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handlePreview = this.handlePreview.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    countMatching(column, state_value, comparison) {
        return this.state.data.filter(d => {
            const value = (d[column] || '').trim()
            if (value === '' && comparison === 'lte') {
                return true
            }

            if (comparison === 'eq') {
                return value == state_value
            }

            const numeric = +value
            if (`${numeric}` !== 'NaN') {
                return comparison === 'lte'
                    ? numeric <= state_value
                    : numeric >= state_value
            }

            return false
        }).length
    }

    handleChange(name, type = 'string') {
        clearTimeout(this.helperTextTimeout)

        return event => {
            const value = event.target.value
            this.setState(
                { [name]: type === 'number' ? +value : value },
                () => {
                    this.helperTextTimeout = setTimeout(() => {
                        if (name.startsWith('in_stock')) {
                            this.setState({
                                in_stock_value_helper_text: this.getNewHelperText({
                                    comparison: this.state.in_stock_column_comparison,
                                    state_value: this.state.in_stock_column_value,
                                    column: this.state.in_stock_column,
                                }),
                            })
                        } else if (name.startsWith('out_stock')) {
                            this.setState({
                                out_stock_value_helper_text: this.getNewHelperText({
                                    comparison: this.state.out_stock_column_comparison,
                                    state_value: this.state.out_stock_column_value,
                                    column: this.state.out_stock_column,
                                }),
                            })
                        }
                    }, 750)
                }
            )
        }
    }

    getNewHelperText({column, state_value, comparison}) {
        let text = ''
        if (comparison) {
            const count = this.countMatching(column, state_value, comparison)
            text = `${count} regels gematcht`
        }
        return text
    }

    handleClose() {
        return this.props.onClose && this.props.onClose()
    }

    handlePreview() {
        const result = this.getSubmissionState()
        if (this.props.onPreview) {
            this.props.onPreview(result)
        }
    }

    handleSubmit() {
        const result = this.getSubmissionState()
        if (this.props.onSubmit) {
            this.props.onSubmit(result)
        }
        this.props.onClose()
    }

    getSubmissionState() {
        const state = this.state

        return state.data
            .filter(d => d[state.identifier_column])
            .map(d => ({
                identifier: {
                    type: state.identifier_type,
                    value: d[state.identifier_column],
                },
                in_stock: this.isInStock(d),
                out_of_stock: this.isOutOfStock(d),
            }))
    }

    isInStock(row) {
        const state = this.state
        const comparison = this.state.in_stock_column_comparison
        const value = row[state.in_stock_column]
        const state_value = state.in_stock_column_value

        if (comparison && comparison !== 'eq') {
            const numeric = +value

            if (`${numeric}` !== 'NaN') {
                return comparison === 'lte'
                    ? numeric <= state_value
                    : numeric >= state_value
            }
        } else if (comparison === 'eq') {
            return value == state_value
        }

        return false
    }

    isOutOfStock(row) {
        const state = this.state
        const comparison = this.state.out_stock_column_comparison
        const value = row[state.out_stock_column]
        const state_value = state.out_stock_column_value

        if (comparison && comparison !== 'eq') {
            const numeric = +value

            if (`${numeric}` !== 'NaN') {
                return comparison === 'lte'
                    ? numeric <= state_value
                    : numeric >= state_value
            }
        } else if (comparison === 'eq') {
            return value == state_value
        }

        return false
    }

    handleStockColumnChange(column_key, menu_items_key) {
        return event => {
            const column = event.target.value
            const column_menu_items = this.state.data
                .reduce((acc, d) => {
                    const value = (d[column] || '').trim()
                    const numeric = +value

                    if (`${numeric}` !== 'NaN') {
                        return acc.includes(numeric) ? acc : acc.concat(numeric)
                    }

                    return acc.includes(value) ? acc : acc.concat(value)
                }, [])
                .sort((a, b) => {
                    if (a > b) {
                        return 1
                    } else if (a < b) {
                        return -1
                    }
                    return 0
                })
                .map(value => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))

            this.setState({
                [column_key]: column,
                [menu_items_key]: column_menu_items,
            })
        }
    }

    getReaderOnLoad() {
        return e => {
            const result = csv.parse(e.target.result, { header: true })

            this.setState({
                headers: Object.keys(result.data[0]),
                data: result.data,
            })
        }
    }

    handleUploadCsvClick() {
        const input = document.createElement('input')
        const reader = new FileReader()
        reader.onload = this.getReaderOnLoad(input)
        input.setAttribute('type', 'file')
        input.style.display = 'none'

        input.onchange = () => {
            const file = input.files[0]
            if (file && !file.name.endsWith('.csv')) {
                this.setState(
                    { error: 'Only csv is supported as file format' },
                    () => { document.body.removeChild(input) }
                )
            } else {
                this.setState(
                    { error: null, file_name: file.name },
                    () => { reader.readAsText(file) }
                )
            }
        }

        document.body.appendChild(input)
        input.click()
    }

    getDeltaMessage(message) {
        if (
            message &&
            message.hasOwnProperty('purchaseable') &&
            message.hasOwnProperty('not_purchaseable') &&
            message.hasOwnProperty('unchanged')
        ) {
            return (
                <span>
                    {`Worden nu inkoopbaar: ${message.purchaseable}, `}
                    {`Worden nu niet inkoopbaar: ${message.not_purchaseable}, `}
                    {`Blijven onveranderd/zijn niet gevonden: ${message.unchanged}`}
                </span>
            )
        }

        return null
    }

    renderFormFields() {
        if (!this.state.file_name || this.state.headers.length === 0) {
            return null
        }

        return (
            <Fragment>
                <IdentifierFieldSet
                    column={this.state.identifier_column}
                    type={this.state.identifier_type}
                    columns={this.state.headers}
                    labels={{
                        column: 'Selecteer identifier kolom',
                        type: 'Selecteer identifier type',
                    }}
                    onColumnChange={this.handleChange('identifier_column')}
                    onIdentifierTypeChange={this.handleChange('identifier_type')}
                />
                <StockFieldSet
                    column={this.state.in_stock_column}
                    comparison={this.state.in_stock_column_comparison}
                    value={this.state.in_stock_column_value}
                    columns={this.state.headers}
                    helperText={this.state.in_stock_value_helper_text}
                    valueMenuItems={this.state.in_stock_column_menu_items}
                    labels={{
                        column: 'In-voorraad kolom',
                        comparison: 'Vergelijking',
                        value: 'In-voorraad waarde',
                    }}
                    onColumnChange={this.handleColumnChange('in_stock_column', 'in_stock_column_menu_items')}
                    onComparisonChange={this.handleChange('in_stock_column_comparison')}
                    onValueChange={this.handleChange('in_stock_column_value')}
                />
                <StockFieldSet
                    column={this.state.out_stock_column}
                    comparison={this.state.out_stock_column_comparison}
                    value={this.state.out_stock_column_value}
                    columns={this.state.headers}
                    helperText={this.state.out_stock_value_helper_text}
                    valueMenuItems={this.state.out_stock_column_menu_items}
                    labels={{
                        column: 'Uit-voorraad kolom',
                        comparison: 'Vergelijking',
                        value: 'Uit-voorraad waarde',
                    }}
                    onColumnChange={this.handleColumnChange('out_stock_column', 'out_stock_column_menu_items')}
                    onComparisonChange={this.handleChange('out_stock_column_comparison')}
                    onValueChange={this.handleChange('out_stock_column_value')}
                />
                {this.getDeltaMessage(this.props.message)}
            </Fragment>
        )
    }

    render() {
        return (
            <Dialog open={true} PaperProps={{style: styles.dialogPaper}}>
                <DialogTitle>Import vendor stocks</DialogTitle>
                <DialogContent>
                    <div>
                        <Button contained color="primary" onClick={this.handleUploadCsvClick}>
                            Upload je csv
                        </Button>
                        <span key="file_name">{this.state.file_name}</span>
                    </div>
                    {this.renderFormFields()}
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={this.handlePreview}
                        >
                            Preview
                        </Button>
                    </div>
                    <div>
                        <Button contained onClick={this.handleClose}>Close</Button>
                        <Button color="primary" contained onClick={this.handleSubmit}>Submit</Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

ImportVendorStocksDialog.propTypes = {
    message: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onPreview: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ImportVendorStocksDialog
