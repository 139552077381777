import socket from '../../socket'
import { getUserEmail } from '../../store'

export function searchPurchaseRequisitions(filters) {
    socket.emit('purchase-requisitions/search', filters, getUserEmail())
    return { type: 'SEARCH_PURCHASE_REQUISITIONS', filters }
}
export function addPurchaseRequisitions(line_items) {
    socket.emit('purchase-requisitions/add', { line_items }, getUserEmail())
    return { type: 'ADD_PURCHASE_REQUISITIONS', line_items }
}

export function revokePurchaseRequisition(requisition) {
    socket.emit('purchase-requisition/revoke', { requisition }, getUserEmail())
    return { type: 'REVOKE_PURCHASE_REQUISITION', requisition }
}

export function revokePurchaseRequisitions(requisitions) {
    socket.emit('purchase-requisitions/revoke', { requisitions }, getUserEmail())
    return { type: 'REVOKE_PURCHASE_REQUISITIONS', requisitions }
}
