import React from 'react'
import PropTypes from 'prop-types'
import {Paper, Subheader, ListItem} from 'material-ui'

function MemoInfo({list, style}) {
    return (
        <Paper style={style}>
            <Subheader>Info</Subheader>
            {
                list.map(r => (
                    <ListItem
                        key={r.text}
                        primaryText={r.text}
                        secondaryText={r.value}
                    />
                ))
            }

        </Paper>
    )
}

MemoInfo.propTypes = {
    list: PropTypes.array,
    style: PropTypes.object,
}

MemoInfo.defaultProps = {
    list: [],
}

export default MemoInfo
