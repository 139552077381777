import store from '../../store'
import { refreshFeature, refreshFeatures } from './../../actions/settings'

export default function initSettingsSockets(socket) {
    socket.on('feature', body => {
        store.dispatch(refreshFeature(body))
    })

    socket.on('features', body => {
        store.dispatch(refreshFeatures(body))
    })
}
