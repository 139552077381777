import React from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showNotification } from '../../../../actions/notifications'

import _ from 'lodash'

import Colors from '../../../styles/colors'
import { barcodeScanner } from '../../../utils/barcodescanner'

const style = {
    card: {
        marginBottom: 15,
        width: '78%',
    },
    selectedHeaderRow: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: 64,
        transition: 'all 0.25s ease',
    },
    selectedItemsText: {
        color: '#FFFFFF',
        paddingLeft: '36px',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        textTransform: 'uppercase',
    },
    selectedIconActions: {
        paddingRight: '20px',
    },
    iconButton: {
        float: 'right',
        color: '#FFFFFF',
    },
}

class RmaLineItems extends React.Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = { barcodeScanId: Math.floor(Math.random() * 10000000000000000) }
    }

    componentDidMount() {
        if (this.props.useBarcodeScanner) {
            barcodeScanner.addListener(this.state.barcodeScanId, this.handleBarcodeScan)
        }
    }

    componentWillUnmount() {
        barcodeScanner.removeListener(this.state.barcodeScanId)
    }

    /**
     * Allow scanning of the EAN of SKU of a product to start determine dialog
     * @param {string} value The value of the scanned barcode
     * @returns {void}
     */
    handleBarcodeScan(value) {
        const processable = this.canProcessItem()
        if (!processable) {
            this.props.showNotification({
                message: 'RMA is not processable, is already processed or has no goods received yet',
            })
            return
        }

        const line_item = this.props.line_items.find(({ supplier_sku, ean }) => {
            return [supplier_sku, ean].includes(value)
        })

        if (!line_item) {
            this.props.showNotification({ message: `No line-item row with sku/ean ${value}` })
            return
        }

        this.props.onLineItemSelect(line_item)
    }

    handleRowClick(event, index) {
        const line_item = this.props.line_items[index]

        if (line_item) {
            this.props.onLineItemSelect(line_item)
        }
    }

    canProcessItem() {
        const isPartiallyAccepted = [
            'accepted',
            'partially_accepted',
        ].includes(this.props.status)

        return isPartiallyAccepted || this.props.status === 'goods_received'
    }

    render() {
        if (this.props.line_items.length === 0) {
            return null
        }

        const processable = this.canProcessItem()
        const card_style = { ...style.card, ..._.get(this.props, 'style.card', {}) }

        const content = (
            <Table
                style={{ maxHeight: window.innerHeight - (this.props.margin) }}
            >
                <TableHead>
                    <TableRow style={{ height: 64 }}>
                        {
                            this.props.headerCols.map(c => (
                                <TableCell
                                    key={c.property}
                                    tooltip={c.tooltip}
                                >
                                    {c.content}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.line_items.map((line_item, index) => {
                            const rowStyle = {}
                            let isRowClickable = processable

                            if (line_item.status === 'rejected') {
                                rowStyle.backgroundColor = Colors.red200
                                isRowClickable = false
                            } else if (line_item.status === 'accepted') {
                                rowStyle.backgroundColor = Colors.green200
                                isRowClickable = false
                            } else if (line_item.status === 'credited') {
                                rowStyle.backgroundColor = Colors.yellow700
                                isRowClickable = false
                            }

                            if (isRowClickable) rowStyle.cursor = 'pointer'

                            return (
                                <TableRow
                                    key={`${line_item.supplier_sku}-${index}`}
                                    style={rowStyle}
                                    onClick={!isRowClickable ? () => { } : event => this.handleRowClick(event, index)}
                                    role="tr"
                                >
                                    {
                                        this.props.headerCols.map(c => {
                                            const value = _.get(line_item, c.property, '')
                                            return (
                                                <TableCell
                                                    key={c.property}
                                                >
                                                    {c.transform ? c.transform(value) : value}
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                {this.props.footer || null}
            </Table>
        )

        return (
            <React.Fragment>
                {!this.props.showPaper ? <div style={card_style}>{content}</div> : (
                    <Card style={card_style}>
                        <CardHeader
                            title={this.props.title}
                            titleTypographyProps={{
                                variant: 'h6',
                                component: 'h6',
                            }}
                        />
                        {content}
                    </Card>)}
            </React.Fragment>
        )
    }
}

RmaLineItems.propTypes = {
    noWrapper: PropTypes.bool,
    title: PropTypes.string,
    status: PropTypes.string,
    headerCols: PropTypes.array,
    footer: PropTypes.node,
    margin: PropTypes.number,
    showPaper: PropTypes.bool,
    line_items: PropTypes.array,
    onLineItemSelect: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    useBarcodeScanner: PropTypes.bool,
}

RmaLineItems.defaultProps = {
    title: 'Line items',
    showPaper: true,
    margin: 300,
    onLineItemSelect: () => { },
    useBarcodeScanner: false,
}

export default connect(() => {
    return {}
}, dispatch => {
    const actions = {
        showNotification,
    }
    return bindActionCreators(actions, dispatch)
})(RmaLineItems)
