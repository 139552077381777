import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem } from '@material-ui/core'

const style = {
    textField: { width: 280, marginRight: 15 },
    selectMenu: { zIndex: 1600, maxHeight: 800 },
}
const selectProps = {
    MenuProps: {
        style: {
            zIndex: 1600,
            maxHeight: 800,
        },
        disableAutoFocusItem: true,
    },
}

const values = [
    'Jelle Gudden',
    'Kevin Rutten',
    'Leslee Schoofs',
    'Marcel van der Zon',
    'Martijn Leenders',
    'Tjitske Simons',
    'Danique Nijenhof',
]

function SalesRepresentativeSelect(props) {
    const value = props.value ? props.value : ''
    const variant = props.variant || 'standard'
    return (
        <TextField
            select
            variant={variant}
            style={props.style || style.textField}
            onChange={props.onChange}
            value={value}
            placeholder="Select sales representative.."
            label={!props.showLabel ? '' : 'Select sales representative'}
            SelectProps={selectProps}
            InputLabelProps={{ shrink: true }}
        >
            {values.map(sales_rep => (
                <MenuItem
                    key={sales_rep}
                    value={sales_rep}

                >
                    {sales_rep}
                </MenuItem>
            ))}
        </TextField>
    )
}

SalesRepresentativeSelect.propTypes = {
    style: PropTypes.object,
    variant: PropTypes.string,
    value: PropTypes.string,
    showLabel: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
SalesRepresentativeSelect.defaultProps = {
    showLabel: true,
    channel: '',
}

export default SalesRepresentativeSelect
