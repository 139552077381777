import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Switch,
    Paper,
    Fab,
    Zoom,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import ArchiveIcon from '@material-ui/icons/Archive'
import CloseIcon from '@material-ui/icons/Close'

import _ from 'lodash'
import moment from 'moment'

const style = {
    list: {
        paddingBottom: 24,
    },
    textField: {
        width: '100%',
        color: 'black',
    },
    firstFab: {
        float: 'right',
        marginRight: '3%',
        marginTop: -26,
    },
    secondFab: {
        float: 'right',
        marginTop: -26,
        marginRight: '3%',
    },
    archived: {
        backgroundColor: 'rgba(255,0,0,0.2)',
    },
}

class Notes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            adding: false,
            editing: null,
            newNote: '',
            selected: null,
            showArchived: false,
        }

        this.handleNewNoteChange = this.handleNewNoteChange.bind(this)
        this.handleEditNoteChange = this.handleEditNoteChange.bind(this)
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
        this.handleAddClick = this.handleAddClick.bind(this)
        this.handleEditClick = this.handleEditClick.bind(this)
        this.handleArchiveClick = this.handleArchiveClick.bind(this)
        this.handleSwitchChange = this.handleSwitchChange.bind(this)
    }

    handleAddClick() {
        this.setState({
            adding: true,
            selected: null,
        })
    }

    handleArchiveClick() {
        const selected = this.state.selected
        const note = this.props.notes[selected]
        this.props.onArchiveNote(note, selected)
    }

    handleEditClick(note, index) {
        this.setState({
            editing: {...note, index},
            adding: false,
            selected: null,
        })
    }

    handleSaveClick() {
        if (this.state.adding) {
            const note = this.state.newNote // string
            this.props.onAddNote(note)
            this.handleCloseClick()
        } else if (this.state.editing) {
            const note = this.state.editing // object
            this.props.onUpdateNote(note, note.index)
            this.handleCloseClick()
        }
    }

    handleCloseClick() {
        this.setState({
            newNote: '',
            editing: null,
            adding: false,
        })
    }

    handleNewNoteChange(e) {
        this.setState({
            newNote: e.target.value,
        })
    }

    handleEditNoteChange(e) {
        const editing = this.state.editing
        this.setState({
            editing: {
                ...editing,
                value: e.target.value,
            },
        })
    }

    handleNoteClick(index) {
        this.setState({selected: this.state.selected === index ? null : index})
    }

    handleSwitchChange() {
        this.setState({showArchived: !this.state.showArchived})
    }

    getRegularButtons() {
        return !this.props.canAdd || this.state.adding || this.state.editing ? null : [
            <Zoom key="add" in={true} timeout={200} unmountOnExit>
                <Fab
                    color="primary"
                    aria-label="Add"
                    onClick={this.handleAddClick}
                    style={this.props.secondFab || style.secondFab}
                >
                    <AddIcon/>
                </Fab>
            </Zoom>,
            <Zoom key="archive" in={true} timeout={200} unmountOnExit>
                <Fab
                    disabled={this.state.selected === null || !this.props.canArchive}
                    aria-label="Archive"
                    onClick={this.handleArchiveClick}
                    style={this.props.firstFab || style.firstFab}
                >
                    <ArchiveIcon/>
                </Fab>
            </Zoom>,
        ]
    }

    getAddingButtons() {
        return !this.state.adding ? null : [
            <Zoom key="save" in={true} timeout={200} unmountOnExit>
                <Fab
                    disabled={!this.state.newNote}
                    color="primary"
                    aria-label="Done"
                    onClick={this.handleSaveClick}
                    style={this.props.secondFab || style.secondFab}
                >
                    <DoneIcon/>
                </Fab>
            </Zoom>,
            <Zoom key="close" in={true} timeout={200} unmountOnExit>
                <Fab
                    aria-label="Close"
                    onClick={this.handleCloseClick}
                    style={this.props.firstFab || style.firstFab}
                >
                    <CloseIcon/>
                </Fab>
            </Zoom>,
        ]
    }

    getEditingButtons() {
        return !this.state.editing ? null : [
            <Zoom key="save" in={true} timeout={200} unmountOnExit>
                <Fab
                    disabled={!this.state.editing.value}
                    color="primary"
                    aria-label="Done"
                    onClick={this.handleSaveClick}
                    style={this.props.secondFab || style.secondFab}
                >
                    <DoneIcon/>
                </Fab>
            </Zoom>,
            <Zoom key="close" in={true} timeout={200} unmountOnExit>
                <Fab
                    aria-label="Close"
                    onClick={this.handleCloseClick}
                    style={this.props.firstFab || style.firstFab}
                >
                    <CloseIcon/>
                </Fab>
            </Zoom>,
        ]
    }

    render() {
        const regularButtons = this.getRegularButtons()
        const addingButtons = this.getAddingButtons()
        const editingButtons = this.getEditingButtons()
        const notes = this.props.notes
        const noteItems = notes.map((note, index) => {
            const isAuthor = note.author === this.context.email
            if (note.archived && !this.state.showArchived) {
                return null
            }
            if (this.state.editing && this.state.editing.index === index) {
                return (
                    <ListItem key={note.value + index}>
                        <TextField
                            style={style.textField}
                            InputLabelProps={{shrink: true}}
                            onChange={this.handleEditNoteChange}
                            multiline
                            rowsMax="5"
                            value={this.state.editing.value}
                            label="Note"
                        />
                    </ListItem>
                )
            }
            if (note.archived) {
                return (
                    <ListItem key={note.value + index} style={style.archived}>
                        <ListItemText
                            primary={note.value}
                            secondary={`${note.author} ` +
                            `(archived on ${moment(note.updated_at).format('DD-MM-YY')})`}
                        />
                    </ListItem>
                )
            }
            return (
                <ListItem
                    button
                    selected={this.state.selected === index}
                    key={note.value + index}
                    onClick={isAuthor ? this.handleNoteClick.bind(this, index) : null}
                >
                    <ListItemText
                        primary={note.value}
                        secondary={`${note.author} ` +
                        `(${note.updated_at === note.created_at ? '' : 'edited on '}` +
                        // some older rmas use "note.date"
                        `${moment(note.updated_at || note.created_at || note.date).format('DD-MM-YY')})`}
                    />
                    {!isAuthor || !this.props.canUpdate ? null : (
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Edit" onClick={this.handleEditClick.bind(this, note, index)}>
                                <EditIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            )
        })

        if (noteItems.length === 0 && !this.state.adding) {
            noteItems.push(
                <ListItem key="no_notes">
                    <ListItemText
                        primary={<i>No notes yet</i>}
                    />
                </ListItem>
            )
        }

        if (this.state.adding) {
            noteItems.push(
                <ListItem
                    key="newNote"
                    disabled={true}
                >
                    <TextField
                        style={style.textField}
                        InputLabelProps={{shrink: true}}
                        onChange={this.handleNewNoteChange}
                        multiline
                        rowsMax="5"
                        value={this.state.newNote}
                        label="Note"
                    />
                </ListItem>
            )
        }
        const header = (
            <ListSubheader>
                {this.props.title || 'Notes'}
                {!this.props.canArchive ? null : (
                    <ListItemSecondaryAction>
                        Show archived
                        <Switch
                            color="primary"
                            onChange={this.handleSwitchChange}
                            checked={this.state.showArchived}
                        />
                    </ListItemSecondaryAction>
                )}
            </ListSubheader>
        )
        const list = (
            <List style={style.list}>
                {noteItems}
            </List>
        )
        const wrapped = this.props.wrap ? (<Paper>{header}{list}</Paper>)
            : (<div>{header}{list}</div>)
        return (
            <div style={_.merge({position: 'relative'}, this.props.style)}>
                {wrapped}
                {regularButtons}
                {addingButtons}
                {editingButtons}
            </div>
        )
    }
}

Notes.contextTypes = {
    email: PropTypes.string,
}

Notes.propTypes = {
    firstFab: PropTypes.object,
    secondFab: PropTypes.object,
    notes: PropTypes.array.isRequired,
    canAdd: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canArchive: PropTypes.bool,
    onAddNote: PropTypes.func,
    onUpdateNote: PropTypes.func,
    onArchiveNote: PropTypes.func,
    style: PropTypes.object,
    floatingActionButtonStyle: PropTypes.object,
    title: PropTypes.string,
    wrap: PropTypes.bool,
}

Notes.defaultProps = {
    canAdd: true,
    canArchive: false,
    canUpdate: false,
    notes: [],
    style: {},
    floatingActionButtonStyle: null,
    title: 'Notes',
    wrap: true,
}

export default Notes
